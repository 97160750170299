import { IRoute } from '../interfaces/routing';
import SettingsPage from '../pages/settings/Settings';
import TestingEvent from '../pages/testingEvents/TestingEvent';
import EmployeeList from '../pages/Employees/EmployeeList';
import ExitEmployeeList from '../pages/Employees/ExitEmployeeList';
import AddTestingEvent from '../pages/testingEvents/AddTestingEvent';
//import LocationPage from '../pages/dashboards/locations/locations';
import DashboardPage from '../pages/dashboards/dashboard/Dashboard';
import AppointmentsPage from '../pages/dashboards/appointments/AppointmentsPage';
import DoctorsPage from '../pages/dashboards/doctors/Doctors';
import TestingDetailViewFurther from '../pages/detailsOfTesting/testingViewDetailFurther';
import AlertsPage from '../pages/components/AlertsPage';
import AutocompletePage from '../pages/components/AutocompletePage';
import BadgesPage from '../pages/components/BadgesPage';
import ContactsPage from '../pages/components/ContactsPage';
import InputsPage from '../pages/components/InputsPage';
import RatingPage from '../pages/components/RatingsPage';
import ModalsPage from '../pages/components/ModalsPage';
import SelectsPage from '../pages/components/SelectsPage';
import SwitchersPage from '../pages/components/SwitchersPage';
import CheckboxesPage from '../pages/components/CheckboxesPage';
import RadiosPage from '../pages/components/RadiosPage';
import TimelinePage from '../pages/components/TimlinesPage';
import CardsPage from '../pages/components/CardsPage';
import ButtonsPage from '../pages/components/ButtonsPage';
import TextareasPage from '../pages/components/TextAreaPage';
import DoctorProfilePage from '../pages/medic/DoctorsProfilePage';
import PatientProfilePage from '../pages/medic/PatientProfilePage';
import PricingPage from '../pages/services/PricingPage';
import EventsTimelinePage from '../pages/services/events-timeline/EventsTimeline';
import UserProfilePage from '../pages/services/UserProfilePage';
import EditAccountPage from '../pages/services/EditAccounPage';
import EventsCalendarPage from '../pages/services/EventsCalendarPage';
import EchartsPage from '../pages/charts/echarts/EchartsPage';
import TypographyPage from '../pages/typography/TypographyPage';
import RechartsPage from '../pages/charts/recharts/Rechart';
import ChartJsPage from '../pages/charts/chart-js/ChartJs';
import GoogleMapsPage from '../pages/maps/GoogleMapPage';
import PageVectorMaps from '../pages/maps/VectorMapsPage';
import WorldMapPage from '../pages/maps/WorldMapPage';
import TablesPage from '../pages/tables/TablesPage';
import FormElementsPage from '../pages/forms/FormElementsPage';
import FormLayoutsPage from '../pages/forms/FormLayoutsPage';
import FormValidationPage from '../pages/forms/FormValidationPage';
import AntdIconsPage from '../pages/icons/AntdIconsPage';
import IconsOptionsPage from '../pages/icons/IconsOptionsPage';
import IcofontIconsPage from '../pages/icons/IcofontIconsPage';
import TestingDetails from '../pages/forms/TestingDetails';
import EditTestingDetails from '../pages/testingEvents/EditTestingEvents';
import CovidTesting from '../pages/covidTesting/covidTesting';
import CovidVaccination from '../pages/covidVaccination/covidVaccination';
import LocationList from '../pages/locationList/locationList';
import ClinicList from '../pages/clinicList/clinicList';
import ClinicDetail from '../pages/clinicList/ClinicDetail';
import DrugAndAlcoholList from '../pages/DrugAndAlcoholTesting/DrugAndAlcoholList';
import DrugAndAlcoholDetail from '../pages/DrugAndAlcoholTesting/DrugAndAlcoholDetail';
import Training from '../pages/Training/Training';
import AddCoursesEmployee from '../pages/Training/AddCoursesEmployee';
import AddLocation from '../pages/locationList/addLocation';
import EditAddEmployee from '../pages/forms/EditAddEmployee';
import EditLocationEmployee from '../pages/locationList/editLocation';
import IntakeForm from '../pages/forms/intakeForm';
import policy from '../pages/Policy/policy';
import Background_screenings from '../pages/background_screenings/background_screenings';
import EAP_Programs from '../pages/EAP_Programs/EAP_Programs';
import EAP_Programs_Details from '../pages/EAP_Programs/EAP_Programs_Details';
import Medical_surveillance from '../pages/medical_surveillance/medical_surveillance';
import Invoices from '../pages/Invoices/Invoices';
import Order_supplies from '../pages/order_supplies/order_supplies';
import Random_testing_pools from '../pages/random_testing_pools/random_testing_pools';
import CovidCompliance from '../pages/covidCompliance/covidCompliance';
import covidExemption from '../pages/covidExemption/covidExemption';
import RandomPoolDetails from '../pages/random_testing_pools/randomPoolDetails';
import ModuleNotActivated from './modulenotfound';
import CovidVulnerable from '../pages/covidVulnerable/covidVulnerable';
import Notifications from '../pages/notification/notification';
import DriverQualification from '../pages/driver_qualification/driverQualification';
import AudiogramList from '../pages/audiogram/audiogramList';
import AudiogramDetails from '../pages/audiogram/audiogramDetail';
import AudiogramTestDetailForm from '../pages/audiogram/audiogramTestDetailForm';
import NewEmployeeInstruction from '../pages/forms/newEmployeeInst';
import AddBackgroundScreening from '../pages/background_screenings/addBackgroundScreening';
import Profile from '../layout/components/actions/Profile';
import FitTest from '../pages/fitTest/fitTestList';
import OshaQuesTestDetail from '../pages/audiogram/medicalSurvellianceScreens/OshaQuesTestDetail';
import PFTtestDetail from '../pages/audiogram/medicalSurvellianceScreens/pftTestDetail';
import PftTestDetail from '../pages/audiogram/medicalSurvellianceScreens/pftTestDetail';
import NewOrderForm from '../pages/components/NewOrderForm';

export const defaultRoutes: IRoute[] = [
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'default-dashboard',
    component: DashboardPage
  },
  {
    path: 'testingDetailViewFurther',
    component: TestingDetailViewFurther
  },
  {
    path: 'editTestingDetails',
    component: EditTestingDetails
  },
  {
    path: 'EAP_Programs_Details',
    component: EAP_Programs_Details
  },
  {
    path: 'covidVulnerable',
    component: CovidVulnerable
  },
  {
    path: 'background_screenings',
    component: Background_screenings
  },

  {
    path: 'audiogramList',
    component: AudiogramList
  },
  {
    path: 'audiogramDetails',
    component: AudiogramDetails
  },
  {
    path: 'audiogramTestDetailForm',
    component: AudiogramTestDetailForm
  },
  
  {
    path: 'EAP_Programs',
    component: EAP_Programs
  },
  {
    path: 'driverQualification',
    component: DriverQualification
  },
  {
    path: 'Invoices',
    component: Invoices
  },
  {
    path: 'medical_surveillance',
    component: Medical_surveillance
  },
  {
    path: 'order_supplies',
    component: Order_supplies
  },
  {
    path: 'random_testing_pools',
    component: Random_testing_pools
  },
  {
    path: 'addLocation',
    component: AddLocation
  },
  {
    path: 'locationList',
    component: LocationList
  },
  {
    path: 'Policy',
    component: policy
  },
  {
    path: 'addCoursesEmployee',
    component: AddCoursesEmployee
  },
  {
    path: 'drugAndAlcoholList',
    component: DrugAndAlcoholList
  },
  {
    path: 'drugAndAlcoholDetail',
    component: DrugAndAlcoholDetail
  },
  {
    path: 'clinicList',
    component: ClinicList
  },
  {
    path: 'clinicdetails',
    component: ClinicDetail
  },
  {
    path: 'covidTesting',
    component: CovidTesting
  },
  {
    path: 'covidVaccination',
    component: CovidVaccination
  },
  {
    path: 'covidCompliance',
    component: CovidCompliance
  },
  {
    path: 'covidExemption',
    component: covidExemption
  },
  {
    path: 'testingDetails',
    component: TestingDetails
  },
  {
    path: 'employeeList',
    component: EmployeeList
  },
  {
    path: 'locationList',
    component: LocationList
  },
  {
    path: 'default-dashboard',
    component: DashboardPage
  },
  {
    path: 'appointments',
    component: AppointmentsPage
  },
  {
    path: 'customerList',
    component: DoctorsPage
  },
  {
    path: 'customerDetails',
    component: DoctorProfilePage
  },
  {
    path: 'alerts',
    component: AlertsPage
  },
  {
    path: 'autocompletes',
    component: AutocompletePage
  },
  {
    path: 'badges',
    component: BadgesPage
  },
  {
    path: 'contacts',
    component: ContactsPage
  },
  {
    path: 'inputs',
    component: InputsPage
  },
  {
    path: 'ratings',
    component: RatingPage
  },
  {
    path: 'modal-windows',
    component: ModalsPage
  },
  {
    path: 'selects',
    component: SelectsPage
  },
  {
    path: 'switchers',
    component: SwitchersPage
  },
  {
    path: 'checkboxes',
    component: CheckboxesPage
  },
  {
    path: 'radio-buttons',
    component: RadiosPage
  },
  {
    path: 'v-timeline',
    component: TimelinePage
  },
  {
    path: 'cards',
    component: CardsPage
  },
  {
    path: 'buttons',
    component: ButtonsPage
  },
  {
    path: 'textareas',
    component: TextareasPage
  },
  {
    path: 'employeeDetails',
    component: PatientProfilePage
  },
  {
    path: 'randomPoolDetails',
    component: RandomPoolDetails
  },
  {
    path: 'testingEvents',
    // component: Payments
    component: TestingEvent
  },
  {
    path: 'notifications',
    component: Notifications
  },
  {
    path: 'pricing',
    component: PricingPage
  },
  {
    path: 'events-timeline',
    component: EventsTimelinePage
  },
  {
    path: 'user-profile',
    component: UserProfilePage
  },
  {
    path: 'edit-account',
    component: EditAccountPage
  },
  {
    path: 'events-calendar',
    component: EventsCalendarPage
  },
  {
    path: 'echarts',
    component: EchartsPage
  },
  {
    path: 'recharts',
    component: RechartsPage
  },
  {
    path: 'chart-js',
    component: ChartJsPage
  },
  {
    path: 'typography',
    component: TypographyPage
  },
  {
    path: 'google-map',
    component: GoogleMapsPage
  },
  {
    path: 'vector-map',
    component: PageVectorMaps
  },
  {
    path: 'world-map',
    component: WorldMapPage
  },
  {
    path: 'tables',
    component: TablesPage
  },
  {
    path: 'addEmployee',
    component: FormElementsPage
  },
  {
    path: 'addTestEvent',
    component: AddTestingEvent
  },
  {
    path: 'employeeTestHistory',
    component: FormValidationPage
  },
  {
    path: 'ant-icons',
    component: AntdIconsPage
  },
  {
    path: 'icons-options',
    component: IconsOptionsPage
  },
  {
    path: 'icofont-icons',
    component: IcofontIconsPage
  },
  {
    path: 'editaddemployee',
    component: EditAddEmployee
  },
  {
    path: 'editlocationemployee',
    component: EditLocationEmployee
  },
  {
    path: 'newEmployeeInstruction',
    component: NewEmployeeInstruction
  },
  {
    path: 'addBackgroundScreening',
    component: AddBackgroundScreening
  },
  {
    path: 'profile',
    component: Profile
  },
  {
    path: 'fit_test',
    component: FitTest
  },
  {
    path:'osha_questionnaire',
    component: OshaQuesTestDetail 

  },
  {
    path:"pft_testdetail",
    component: PftTestDetail  
  },
  {
    path:"new_orderform",
    component: NewOrderForm 
  },
  {
    path:"exitemployeeList",
    component: ExitEmployeeList 
  },
];
