import React, { useEffect, useState } from 'react';
import { Modal, Table, Tooltip, Button, Input, Space } from 'antd';
import axios from 'axios';
import _ from 'underscore';
import setting from '../../../config/setting';
import { LoginOutlined } from '@ant-design/icons/lib';
import { history } from '../../../redux/store';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
var accountItems = '';

const SwitchCompany = () => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchColumn, setSearchColumn] = useState('');

  useEffect(() => {
    let accountItem = JSON.parse(localStorage.getItem('customerCompanies'))
      ? JSON.parse(localStorage.getItem('customerCompanies'))
      : null;
    accountItems = accountItem;
  }, []);

  async function getAccountDetails(id, name) {
    await axios
      .post(setting.serverUrl + '/api/CustomerDataAppById', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          compId: id,
          compName: name
        }
      })
      .then((response) => {
        if (response.status === 200) {
          getTypeCheckId(id);
          localStorage.setItem('customerData', JSON.stringify(response.data[0]));
          window.location.reload(false);
        }
      });
  }
  function getTypeCheckId(companyId) {
    axios
      .post(setting.serverUrl + '/api/getIdCheckType', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyId
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          let result = response && response.data && response.data.rows;
          let typevalue = result.map((val) => {
            return val.id_type;
          });
          localStorage.setItem('IdtypeCheckValue', JSON.stringify(typevalue));
        }
      });
  }
  function getNavTabsCheck(customerId) {
    axios
      .post(setting.serverUrl + '/api/getNavbarTabs', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: customerId
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          let result = response && response.data;
          localStorage.setItem('NavTabCheck', JSON.stringify(result));
        }
      });
  }

  const updateCompany = (data) => {
    localStorage.setItem('customerId', data.id);
    let id = localStorage.getItem('customerId');

    localStorage.setItem('displayName', data.name);

    let name = localStorage.getItem('displayName');

    getTypeCheckId(id);
    getAccountDetails(id, name);
    getNavTabsCheck(id);
    history.push({ pathname: '/vertical/default-dashboard' });
  };
  const actions = (data) => (
    <div className='buttons-list nowrap'>
      <Tooltip title='Go to '>
        <Button
          onClick={() => updateCompany(data)}
          type='primary'
          style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            color: 'rgb(27,57,105)'
          }}
        >
          <span>
            <LoginOutlined style={{ fontSize: '1.3rem' }} />
          </span>
        </Button>
      </Tooltip>
    </div>
  );
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name')
    },
    {
      title: 'Action',
      key: 'action',
      render: actions
    }
  ];
  return (
    <>
      {accountItems && accountItems.length > 1 && (
        // <Dropdown overlay={accountMenu} trigger={['click']} placement='bottomRight'  className='mr-3'>
        //   <div className='item'>
        //     <span className='icofont-exchange' style={{fontSize: 23, color:"#fff",opacity:1 }} />
        //   </div>

        // </Dropdown>
        <>
          <Button
            style={{ backgroundColor: 'rgb(66,90,129)', border: 'none' }}
            onClick={() => setOpen(true)}
          >
            <span
              className='icofont-exchange'
              style={{ fontSize: 23, color: '#fff', opacity: 1 }}
            />
          </Button>
          <Modal
            title='Switch Company'
            centered
            visible={open}
            maskClosable={false}
            //onOk={() => setOpen(false)}
            keyboard={false}
            onCancel={() => setOpen(false)}
            footer={null}
            width={600}
          >
            <>
              <Table
                columns={columns}
                dataSource={accountItems}
                pagination={{
                  // pageSize: 6,
                  hideOnSinglePage: true,
                  showSizeChanger: true
                }}
              />
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default SwitchCompany;
