import React, { useEffect, useState, useRef } from 'react';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { Select, Button,Row,Col } from 'antd';
//let columnPicker = [];
let flexes = '';
let showGrid = '';
//let dragOver = '';
let listBoxes = '';

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
      },
      'EmployeeSelected.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'PoolEmployeeSelected.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

export default function EmployeePoolSelectList({ infoRecordNew }) {
  console.log('infoRecord', infoRecordNew);
  const [view, setView] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [pagging, setPagging] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);

  useEffect(() => {
    console.log({ infoRecordNew });
    setWijmoFlexGrid(infoRecordNew, 5);
  }, []);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }

  const paggination = (value) => {
    console.log('page value', value);

    setTimeout(() => {
      setWijmoFlexGrid(infoRecordNew, value);
    }, 500);
  };

  const setWijmoFlexGrid = (data, page) => {
    console.log('pagging', data, page);
    let views = new wijmo.CollectionView(data, { pageSize: Number(page) });
    console.log('views', views);
    setView(views);
    setViewIndex(views.pageIndex + 1);
    setViewCount(views.pageCount);
    //pagination records
    setTimeout(() => {
      setPagginationNumRecord({
        currentRecord: document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
        totalRecord: data.length
      });
    }, 2000);
  };
  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    flexes = ctl;
    setListboxColumns(ctl.columns);
  };

  const updateViewPager = (target) => {
    console.log('target', target);
    let btn = wijmo.closest(target, 'button'),
      id = btn ? btn.id : '';
    switch (id) {
      case 'btnFirst':
        view.moveToFirstPage();
        break;
      case 'btnPrev':
        view.moveToPreviousPage();
        break;
      case 'btnNext':
        view.moveToNextPage();
        break;
      case 'btnLast':
        view.moveToLastPage();
        break;
    }
    setViewIndex(view.pageIndex + 1);
    setViewCount(view.pageCount);
  };

  const showCompany = (item) => {
    return <span className='nowrap'>{item.item.compname}</span>;
  };
  const showPoolCheck = (item) => {
    return (
      <span className='nowrap'>
        <input type='checkbox' checked={item.item.pool_checkbox} />
      </span>
    );
  };

  return (
    <>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={10}>
          <FlexGridSearch
            ref={theSearch}
            placeholder='Search'
            cssMatch=''
            style={{ width: '70%', height: '40px' }}
          />
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
          <div ref={fixedContainerRef}>
            <Select
              // showSearch
              style={{ width: 180 }}
              placeholder={`Items per page: ${pagging}`}
              // optionFilterProp='children'
              onChange={paggination}
              options={[
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
              ]}
              getPopupContainer={() => fixedContainerRef.current}
            />
          </div>
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={10}>
          <Button
            type='primary'
            style={{ backgroundColor: '#3d586a', float: 'right' }}
            disabled={isExcelPreparing}
            onClick={exportToExcel}
          >
            {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
          </Button>
        </Col>
      </Row>

      <FlexGrid
        ref={theGrid}
        id='theGridTallRows'
        alternatingRowStep={1}
        // autoRowHeights={true}
        itemsSource={view}
        initialized={gridInitialized}
        formatItem={formatItem}
        itemsPerPage={10}
        isReadOnly={true}
      >
        <div style={{ display: 'none' }}>
          <wjInput.ListBox
            className='column-picker'
            itemsSource={listboxColumns}
            checkedMemberPath='visible'
            displayMemberPath='header'
            lostFocus={hidePicker}
            initialized={initializedListBox}
          ></wjInput.ListBox>
        </div>
        <FlexGridColumn binding='compname' header='Company' minWidth={250} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showCompany} />
        </FlexGridColumn>

        <FlexGridColumn binding='com_loc_name' header='Location' minWidth={200} width='*' />

        <FlexGridColumn binding='fullName' header='Name' minWidth={200} width='*' />

        <FlexGridColumn binding='pkgname' header='Package' width='*' minWidth={250} />

        <FlexGridColumn binding='test_type' header='Test Type' minWidth={250} width='*' />

        <FlexGridColumn binding='pool_checkbox' header='Pool' minWidth={100} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showPoolCheck} />
        </FlexGridColumn>
        <FlexGridColumn binding='pool_notes' header='Pool Notes' minWidth={300} width='*' />
        <FlexGridFilter
          filterColumns={[
            'compname',
            'com_loc_name',
            'fullName',
            'pkgname',
            'test_type',
            'pool_notes'
          ]}
        />
      </FlexGrid>

      <div style={{ display: 'flex' }}>
        <div
          onClick={(e) => updateViewPager(e.target)}
          style={{
            backgroundColor: '#425a81',
            color: '#fff',
            display: 'inline',
            borderRadius: '5px',
            paddingTop: '5px'
          }}
        >
          <button
            id='btnFirst'
            style={{ color: '#fff', backgroundColor: '#425a81' }}
            className='btn'
          >
            <span className='wj-glyph-step-backward'></span>
          </button>
          <button
            id='btnPrev'
            className='btn'
            style={{ color: '#fff', backgroundColor: '#425a81' }}
          >
            <span className='wj-glyph-left'></span>
          </button>
          &nbsp;&nbsp;&nbsp;Page {viewIndex} of {viewCount}&nbsp;&nbsp;&nbsp;
          <button
            id='btnNext'
            className='btn'
            style={{ color: '#fff', backgroundColor: '#425a81' }}
          >
            <span className='wj-glyph-right'></span>
          </button>
          <button
            id='btnLast'
            className='btn'
            style={{ color: '#fff', backgroundColor: '#425a81' }}
          >
            <span className='wj-glyph-step-forward'></span>
          </button>
        </div>
        {/* {pagginationNumRecord.currentRecord !== undefined ? (
          <p
            style={{ marginTop: '2%', marginLeft: '2%', fontWeight: 'bold' }}
          >{`${pagginationNumRecord.currentRecord} out of ${pagginationNumRecord.totalRecord}`}</p>
        ) : (
          ''
        )} */}
      </div>
    </>
  );
}
