import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  Space,
  Row,
  Col,
  Modal,
  Input,
  Select,
  Button,
  Collapse,
  notification,
  DatePicker,
  Card
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from '../../../config/setting';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';

const config = require('../../keyFront');
const { Panel } = Collapse;
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;
let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'fit_test_doc.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const OshaQuesTestDetail = () => {
  const [fitTestResult, setFitTestResult] = useState([]);
  const [change, setChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found');
  const [LoadMoreVal, setLoadMore] = useState(1);
  const [fullNameValue, setFullNameValue] = useState('');
  const [locValue, setLocValue] = useState('');
  const [reasonValue, setReasonValue] = useState('');
  const [einValue, setEinValue] = useState('');
  const [ssnValue, setSsnValue] = useState('');
  const [dlValue, setDLValue] = useState('');
  const [panelValue, setPanelValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [collectionDate, setCollectionDate] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [reportingDate, setReportingDate] = useState('');
  const [caseValue, setCaseValue] = useState('');
  const [count, setCount] = useState();
  const [loadMoreHide, setLoadMoreHide] = useState(false);
  const [tableLoaderVal, settableLoaderVal] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [reasonsCount, setReasonsCount] = useState([]);
  const [resultCount, setResultCount] = useState([]);
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);
  const theGrid = useRef();
  const { RangePicker } = DatePicker;
  const theSearch = useRef();
  const gridRef = useRef(null);
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();

  const pagesize = 25;

  useEffect(() => {
    getData(LoadMoreVal, true);
    getData(LoadMoreVal);
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, [pageSize]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }
  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };
  async function getEapIdCheckValue(empId) {
    const res = await axios.post(setting.serverUrl + '/api/getIdTypeCheckValue', {
      params: {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        compId: JSON.parse(localStorage.getItem('customerData')).id,
        employeeId: empId
      }
    });

    const response = await res;
    const result = response.data.rows;
    return result;
  }
  async function getData(
    LoadMoreVal,
    getcount = false,
    check = false,
    advanceFilter = false,
    resetFilter = false
  ) {
    i = 1;
    settableLoaderVal(true);
    setChange(true);
    setIsLoading(true);
    if (!advanceFilter && !resetFilter) {
      setIsLoadMoreLoading(true);
    }
    const url = getcount ? '/api/getTestDetailsOsha_count' : '/api/getTestDetailsOsha';

    await axios
      .post(setting.serverUrl + url, {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          LoadMoreVal: LoadMoreVal,
          fullNameValue: check ? '' : fullNameValue,
          locValue: check ? '' : locValue,
          statusValue: check ? '' : statusValue,
          panelValue: check ? '' : panelValue,
          reasonValue: check ? '' : reasonValue,
          ssnValue: check ? '' : ssnValue,
          caseValue: check ? '' : caseValue,
          einValue: check ? '' : einValue,
          dlValue: check ? '' : dlValue,
          collectionDate: check ? '' : collectionDate,
          getcount: advanceFilter ? true : getcount,
          companyId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then(async (response) => {
        setIsLoading(false);
        setChange(false);

        if (response && response.status == 200) {
          var arrayEmpIds = [];
          var resultIds;
          arrayEmpIds.push(
            response &&
              response.data &&
              response.data.length > 0 &&
              response.data.map((k) => k.emp_selected[0])
          );
          console.log({ arrayEmpIds });

          if (getcount == false) {
            settableLoaderVal(false);
            setNoRecordFound(false);

            setFitTestResult(response && response.data);

            if (advanceFilter) {
              setCount(response.data.length);
            }
            if (response.data.length !== LoadMoreVal * pagesize) {
              setLoadMoreHide(true);
            } else {
              setLoadMoreHide(false);
            }
            setHasPermission(true);
          } else {
            setCount(response.data.count);
          }
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setNoRecordMessage('Access permission not allowed.');
          setErrMsg('Access permission not allowed.');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
          setNoRecordFound(true);
        }

        if (response && response.status == 200) {
          var resss = [];
          console.log('array empiid', arrayEmpIds);
          if (arrayEmpIds && arrayEmpIds[0] && arrayEmpIds[0].length > 0) {
            const idResult = await getEapIdCheckValue(arrayEmpIds);
            const ab = response.data.map((value) => {
              return idResult.filter((val, index) => {
                if (value.emp_selected[0] === val.id) {
                  resss.push({ ...value, idType: idResult[index] });
                }
              });
            });
          } else {
            setIsLoading(false);
          }

          if (getcount == false) {
            settableLoaderVal(false);
            setNoRecordFound(false);
            console.log("ress in osha ques test detail=========", resss)
            const newResponse =
              resss &&
              resss.length > 0 &&
              resss.map((k) => {
                return {
                  ...k,
                  fullName: k.first_name + ' ' + k.last_name,
                  testing_status: k.testing_status != false ? k.testing_status : '',
                  newLocation:
                    k.child_ids.length > 0 && k.child_ids[1].includes('|')
                      ? k.child_ids[1].split('|')[1]
                      : k.child_ids[1].split(',')[1],
                  // newCollected: k.collected && moment(k.collected).format('MM/DD/YYYY hh:mm'),
                  newCollected:  k.testing_date != false ? k.testing_date && moment(k.testing_date).format('MM/DD/YYYY hh:mm'): "",
                  newCcf: k.ccf == false ? ' ' : k.ccf,
                  fitTestPdf: k.fitTestPdf != false ? k.fitTestPdf : '',
                  fitTestCard: k.fitTestCard != false ? k.fitTestCard : '',
                  fitTestType: k.fit_Test_Type != false ? k.fit_Test_Type : '',
                  fitManufacturer: k.fit_Manufacturer != false ? k.fit_Manufacturer : '',
                  finalResult: k.final_result != false ? k.final_result : '',
                  newDL: k.idType.DL != false ? k.idType.DL : '',
                  newEmpEIN: k.idType.emp_ein != false ? k.idType.emp_ein : '',
                  newEmpSSN: k.idType.emp_ssn != false ? k.idType.emp_ssn : '',
                  jobTitle: k.emp_data[0].Job_Title != false ? k.emp_data[0].Job_Title : '',
                  departments: k.emp_data[0].department != false ? k.emp_data[0].department : '',
                  program: k.emp_data[0].program != false ? k.emp_data[0].program : '',
                  mode: k.emp_data[0].mode != false ? k.emp_data[0].mode : '',
                  attachment:
                    k.attachments != false && k.attachments.length > 0 ? k.attachments : ''
                };
              });
            setFitTestResult(newResponse);

if(newResponse.length > 0){
              const collectionView1 = new CollectionView(newResponse, {
              pageSize: newResponse.length
            });
            setViewData(collectionView1);
            const collectionView2 = new CollectionView(newResponse, {
              pageSize: pageSize,
              collectionChanged: (s) => {
                filterRefCounter.current = s.totalItemCount;
                var filterCounter = document.getElementById('filterCounter');
                if (filterCounter) {
                  filterCounter.textContent = s.totalItemCount;
                }
              }
            });
            setView(collectionView2);
          }
            setIsLoadMoreLoading(false);

            if (advanceFilter) {
              setCount(response.data.length);
            }
            if (response.data.length !== LoadMoreVal * pagesize) {
              setLoadMoreHide(true);
            } else {
              setLoadMoreHide(false);
            }
            setHasPermission(true);
          } else {
            setCount(response.data.count);
          }
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setNoRecordMessage('Access permission not allowed.');
          setErrMsg('Access permission not allowed.');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
          setNoRecordFound(true);
        }
      });
  }

  function download(e, attachment) {
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        console.log('Respone from getData testEventReports', response.data);
        if (response && response.data && response.data.length > 0) {
          setIsLoaderActive(false);
          setIsModalVisible(true);
          setImageUrls(response.data);
        }
      });
  }

  const handleReset = () => {
    setCount();
    setFullNameValue('');
    setLocValue('');
    setSsnValue('');
    setEinValue('');
    setPanelValue('');
    setReasonValue('');
    setStatusValue('');
    setCaseValue('');
    setCollectionDate('');
    getData(LoadMoreVal, false, true, false, true);
    getData(LoadMoreVal, true, true, false, true);
  };

  const advanceDateFilter = (filterType, value) => {
    if (filterType == 'collectiondate') {
      setCollectionDate(value);
    } else if (filterType === 'reportingdate') {
      setReportingDate(value);
    }
  };

  const showAdvanceFilterModal = () => {
    setIsAdvanceFilterModalOpen(true);
  };

  const handleAdvanceFilterCancel = () => {
    setIsAdvanceFilterModalOpen(false);
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterModalOpen(false);
    getData(1, false, false, true);
  };
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };
  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };
  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showResult = (item) => {
    return (
      <span>
        {item.item.finalResult === 'NEGATIVE' ? (
          <p style={{ color: 'green' }}>{item.item.finalResult}</p>
        ) : item.item.finalResult === 'POSITIVE' ? (
          <p style={{ color: 'red' }}>{item.item.finalResult}</p>
        ) : item.item.finalResult !== 'POSITIVE' && item.item.finalResult !== 'NEGATIVE' ? (
          <p style={{ color: 'orange' }}>{item.item.finalResult}</p>
        ) : (
          ''
        )}
      </span>
    );
  };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showAttachment = (item) => {
    return (
      <span>
        {item && item.item && item.item.attachment && item.item.attachment.length > 0 ? (
          <button
            style={{ margin: '-3px 0px' }}
            onClick={() => downloadAttachmentMultiple(item.item.attachment[0])}
          >
            View Doc
          </button>
        ) : (
          <></>
        )}
      </span>
    );
  };

  function downloadAttachmentMultiple(attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        setChange(!change);
        window.open(response.data);
      });
  }

  const showSSN = (item) => {
    if (item.item.newEmpSSN !== false && item.item.newEmpSSN !== null) {
      return <>{'***-**-' + item.item.newEmpSSN.substr(-4)}</>;
    } else {
      return <>{''}</>;
    }
  };
  const showEIN = (item) => {
    return <>{item.item.newEmpEIN != false ? item.item.newEmpEIN : ''}</>;
  };
  const showDL = (item) => {
    return <>{item.item.newDL != false ? item.item.newDL : ''}</>;
  };

  const renderCountCard = (data) => {
    return (
      <>
        {data.count !== 0 ? (
          <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {data.count}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    {data && data.reasons && data.reasons.toUpperCase()}
                    {data && data.final_result && data.final_result.toUpperCase()}
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderDashboardCards = () => {
    let mergeStatus = reasonsCount.concat(resultCount);
    console.log('mergeStatus', mergeStatus);
    let cards = mergeStatus.map((data) => {
      return renderCountCard(data);
    });
    return cards;
  };
  const flexGridRef = React.useRef(null);

  const onFilterApplied = () => {
    const filter = flexGridRef.current.collectionView.filterDefinition;
    filter.clear(); // clear any existing filters
    filter.conditionFilter('emp_ein', '==', true); // filter for isActive == true
  };
  const showNewLocation = (item) => {
    return item.item.newLocation;
  };
  return hasPermission ? (
    <div style={{ padding: 20 }}>
      <Row style={{ alignItems: 'center', textAlign: 'center' }}>
        <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 9 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <h4 style={{ textAlign: 'left', color: '#1C3A6A' }}>OSHA Questionnaire</h4>
        </Col>

        <Col
          xl={{ span: 18 }}
          lg={{ span: 18 }}
          md={{ span: 15 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ textAlign: 'right' }}
        >
          {fitTestResult && fitTestResult.length > 0 && (
            <>
              {!loadMoreHide && (
                <Button
                  size='small'
                  style={{
                    backgroundColor: '#247',
                    color: 'white',
                    border: 'none',
                    width: '80px',
                    marginBottom: 10,
                    marginRight: '10px'
                  }}
                  onClick={() => {
                    setLoadMore(LoadMoreVal + 1);
                    getData(LoadMoreVal + 1, false, false);
                  }}
                >
                  {isLoadMoreLoading ? (
                    <Space size='middle'>
                      <Spin size='small' />
                    </Space>
                  ) : (
                    ' Load More'
                  )}
                </Button>
              )}

              <Button
                size='small'
                style={{
                  marginBottom: 10,
                  backgroundColor: '#247',
                  border: 'none',
                  marginRight: '10px'
                }}
                onClick={showAdvanceFilterModal}
              >
                Advanced Search Filter
              </Button>
              <Modal
                width={1000}
                title='Advanced Search Filter'
                visible={isAdvanceFilterModalOpen}
                onCancel={handleAdvanceFilterCancel}
                footer={[
                  <Button
                    size='small'
                    style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                    onClick={() => handleAdvanceFilter()}
                  >
                    Search
                  </Button>
                ]}
              >
                <Row gutter={[24, 24]}>
                  <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                    <Input
                      value={fullNameValue}
                      placeholder='Full Name :'
                      suffix={<span className='icofont icofont-search' />}
                      style={{ width: '80%', backgroundColor: 'white' }}
                      onChange={(event) => setFullNameValue(event.target.value)}
                    />
                  </Col>
                  <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                    <Input
                      value={locValue}
                      placeholder='Location :'
                      suffix={<span className='icofont icofont-search' />}
                      style={{ width: '80%', backgroundColor: 'white' }}
                      onChange={(event) => setLocValue(event.target.value)}
                    />
                  </Col>

                  <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                    <Input
                      value={reasonValue}
                      placeholder='Reason :'
                      suffix={<span className='icofont icofont-search' />}
                      style={{ width: '80%', backgroundColor: 'white' }}
                      onChange={(event) => setReasonValue(event.target.value)}
                    />
                  </Col>

                  <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                    <Input
                      value={panelValue}
                      placeholder='Panel :'
                      suffix={<span className='icofont icofont-search' />}
                      style={{ width: '80%', backgroundColor: 'white' }}
                      onChange={(event) => setPanelValue(event.target.value)}
                    />
                  </Col>

                  <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                    <Input
                      value={statusValue}
                      placeholder='Result :'
                      suffix={<span className='icofont icofont-search' />}
                      style={{ width: '80%', backgroundColor: 'white' }}
                      onChange={(event) => setStatusValue(event.target.value)}
                    />
                  </Col>

                  {typecheckValue &&
                    typecheckValue.length > 0 &&
                    typecheckValue.map((value) => {
                      if (value === 'SSN') {
                        return (
                          <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                            <Input
                              value={ssnValue}
                              placeholder='SSN :'
                              suffix={<span className='icofont icofont-search' />}
                              style={{ width: '80%', backgroundColor: 'white' }}
                              onChange={(event) => setSsnValue(event.target.value)}
                            />
                          </Col>
                        );
                      } else if (value === 'Driver License') {
                        return (
                          <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
                            <Input
                              value={dlValue}
                              placeholder='Driver License:'
                              suffix={<span className='icofont icofont-search' />}
                              style={{ width: '80%', backgroundColor: 'white' }}
                              onChange={(event) => setDLValue(event.target.value)}
                            />
                          </Col>
                        );
                      } else if (value === 'EIN') {
                        return (
                          <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                            <Input
                              value={einValue}
                              placeholder='EIN :'
                              suffix={<span className='icofont icofont-search' />}
                              style={{ width: '80%', backgroundColor: 'white' }}
                              onChange={(event) => setEinValue(event.target.value)}
                            />
                          </Col>
                        );
                      } else {
                      }
                    })}

                  <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                    <RangePicker
                      //value={collectionDate}
                      placeholder={['Start Test Date', 'End Test Date']}
                      allowClear={true}
                      style={{ borderRadius: 20, width: '80%', height: 40 }}
                      onChange={(value, dateString) => {
                        advanceDateFilter('collectiondate', dateString);
                      }}
                    />
                  </Col>
                </Row>
              </Modal>
              <Button
                size='small'
                style={{
                  backgroundColor: '#247',
                  marginBottom: 10,
                  border: 'none',
                  marginRight: '10px'
                }}
                onClick={() => handleReset()}
              >
                Reset Filter
              </Button>
            </>
          )}
        </Col>
      </Row>

      <div className='row'>{renderDashboardCards()}</div>

      {(isLoadMoreLoading || isLoading) && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      {!noRecordFound ? (
        fitTestResult.length > 0 ? (
          <div>
            <h6>
              Total records :{' '}
              <span style={{ marginLeft: '10px' }}>{!count ? <Spin size='small' /> : count}</span>
            </h6>
            <div className='row'>
              <div className='col-12 col-md-12 col-xl-12'>
                <Row gutter={[32, 32]}>
                  <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                    <FlexGridSearch
                      ref={theSearch}
                      placeholder='Search'
                      cssMatch=''
                      style={{ width: '70%', height: '40px' }}
                    />
                  </Col>

                  <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                    <div ref={fixedContainerRef}>
                      <Select
                        style={{ width: 170 }}
                        placeholder={`Items per page : ${pageSize}`}
                        onChange={paggination}
                        options={[
                          { label: 'Items per page : 10', value: 10 },
                          { label: 'Items per page : 20', value: 20 },
                          { label: 'Items per page : 50', value: 50 }
                        ]}
                        getPopupContainer={() => fixedContainerRef.current}
                      />
                    </div>
                  </Col>

                  <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                    <Button
                      type='primary'
                      style={{ backgroundColor: '#3d586a', float: 'right' }}
                      disabled={isExcelPreparing}
                      onClick={exportToExcel}
                      size='small'
                    >
                      {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                    </Button>
                  </Col>
                </Row>

                {/* to export entire data of table */}
                <FlexGrid
                  ref={theGrid}
                  style={{ display: 'none' }}
                  className='dummyWijmoData'
                  itemsSource={viewData}
                  initialized={gridInitializedData}
                  formatItem={formatItem}
                  isReadOnly={true}
                >
                  <FlexGridColumn
                    binding='fullName'
                    header='Employee Name'
                    minWidth={160}
                    width='*'
                  />
                  <FlexGridColumn binding='newLocation' header='Location' minWidth={250} width='*'>
                    <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='newCollected'
                    header='Test Date/Time'
                    minWidth={200}
                    width='*'
                  />
                  {typecheckValue &&
                    typecheckValue.length > 0 &&
                    typecheckValue.map((value) => {
                      return (
                        <FlexGridColumn
                          binding={
                            value === 'SSN'
                              ? 'newEmpSSN'
                              : value === 'EIN'
                              ? 'newEmpEIN'
                              : value === 'Driver License'
                              ? 'newDL'
                              : ''
                          }
                          header={
                            value === 'SSN'
                              ? 'SSN'
                              : value === 'EIN'
                              ? 'EIN'
                              : value === 'Driver License'
                              ? 'Driver License'
                              : ''
                          }
                          // width='12*'

                          minWidth={160}
                          width='*'
                        >
                          <FlexGridCellTemplate
                            cellType='Cell'
                            template={
                              value === 'SSN'
                                ? showSSN
                                : value === 'EIN'
                                ? showEIN
                                : value === 'Driver License'
                                ? showDL
                                : ''
                            }
                          />
                        </FlexGridColumn>
                      );
                    })}

                  <FlexGridColumn binding='reasons' header='Reason for Test' minWidth={200} />

                  <FlexGridColumn binding='finalResult' header='Result'>
                    <FlexGridCellTemplate cellType='Cell' template={showResult} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='jobTitle'
                    header='Job Title'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  />
                  {/* <FlexGridColumn
                    binding='departments'
                    header='Job Position'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}

                  <FlexGridColumn
                    binding='program'
                    header='DOT Mode'
                    // width='10*'
                    minWidth={160}
                    width='*'
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='fitManufacturer'
                    header='Fit Manufacturer'
                  ></FlexGridColumn>
                  <FlexGridColumn binding='fitTestType' header='Fit Test Type'></FlexGridColumn>
                </FlexGrid>

                <FlexGrid
                  ref={theGrid}
                  //ref={gridRef}
                  id='theGridTallRows'
                  className="cliniclisttable"
                  alternatingRowStep={1}
                  // autoRowHeights={true}
                  itemsSource={view}
                  initialized={gridInitialized}
                  formatItem={formatItem}
                  itemsPerPage={10}
                  isReadOnly={true}
                  onFilterApplied={onFilterApplied}
                  //autoGenerateColumns={false}
                  scrollMode="Virtual" // Add this line for smooth scrolling
                >
                  <div style={{ display: 'none' }}>
                    <wjInput.ListBox
                      className='column-picker'
                      itemsSource={listboxColumns}
                      checkedMemberPath='visible'
                      displayMemberPath='header'
                      lostFocus={hidePicker}
                      initialized={initializedListBox}
                    ></wjInput.ListBox>
                  </div>
                  <FlexGridColumn
                    binding='fullName'
                    header='Employee Name'
                    // minWidth={160}
                    // width='*'
                    width={135}
                  />
                  <FlexGridColumn binding='newLocation' header='Location' width={215}>
                    <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='newCollected'
                    header='Test Date/Time'
                    // minWidth={200}
                    // width='*'
                    width={155}
                  />
                  {typecheckValue &&
                    typecheckValue.length > 0 &&
                    typecheckValue.map((value) => {
                      return (
                        <FlexGridColumn
                          binding={
                            value === 'SSN'
                              ? 'newEmpSSN'
                              : value === 'EIN'
                              ? 'newEmpEIN'
                              : value === 'Driver License'
                              ? 'newDL'
                              : ''
                          }
                          header={
                            value === 'SSN'
                              ? 'SSN'
                              : value === 'EIN'
                              ? 'EIN'
                              : value === 'Driver License'
                              ? 'Driver License'
                              : ''
                          }
                          // width='12*'

                          // minWidth={160}
                          // width='*'
                          width={120}
                        >
                          <FlexGridCellTemplate
                            cellType='Cell'
                            template={
                              value === 'SSN'
                                ? showSSN
                                : value === 'EIN'
                                ? showEIN
                                : value === 'Driver License'
                                ? showDL
                                : ''
                            }
                          />
                        </FlexGridColumn>
                      );
                    })}

                  <FlexGridColumn binding='reasons' header='Reason for Test' width={145} />
                  <FlexGridColumn binding='testing_status' header='Status' width={80}/>

                  <FlexGridColumn binding='finalResult' header='Result' width={95}>
                    <FlexGridCellTemplate cellType='Cell' template={showResult} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='jobTitle'
                    header='Job Title'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  />
                  <FlexGridColumn
                    binding='program'
                    header='DOT Mode'
                    // width='10*'
                    // minWidth={160}
                    // width='*'
                    width={105}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn>

                  <FlexGridColumn binding='attachment' header='Documents' width={100}>
                    <FlexGridCellTemplate cellType='Cell' template={showAttachment} />
                  </FlexGridColumn>

                  <FlexGridFilter
                    filterColumns={[
                      'fullName',
                      'newLocation',
                      'newCollected',
                      'newEmpEIN',
                      'newEmpSSN',
                      'newDL',
                      'reasons',
                      'fitTestType',
                      'fitManufacturer',
                      'testing_status',
                      'finalResult',
                      'jobTitle',
                      'departments',
                      'program'
                    ]}
                  />
                </FlexGrid>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <div
                    style={{
                      backgroundColor: '#425a81',
                      color: '#fff',
                      display: 'inline',
                      borderRadius: '5px',
                      height: '30px'
                    }}
                  >
                    <CollectionViewNavigator
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                      headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                      byPage={true}
                      cv={view}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <p
                    style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                    ref={filterRefCounter}
                    id='filterCounter'
                  >
                    ({Globalize.format(filterRefCounter.current, 'n0')} )
                  </p>
                  &nbsp;
                  <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                    {' '}
                    out of {fitTestResult.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50 }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
            )}
          </>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <Modal
        width={1000}
        title='Fit Test Document'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls && imageUrls.length > 0 && (
          <p>
            <embed src={imageUrls} width='800px' height='900px' />
          </p>
        )}
      </Modal>
      <DarkBackground disappear={isLoaderActive}>
        <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
      </DarkBackground>
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        &nbsp;&nbsp; {errMsg}
      </p>
    </div>
  );
};

export default OshaQuesTestDetail;
