import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spin, Space, Row, Col, Button, Form, Divider, Checkbox } from 'antd';
import moment from 'moment';
import RightEarTestForm from './rightEarTestForm';
import LeftEarTestForm from './leftEarTestForm';
import ShowHisGraph from './leftEarGraph';
var propsFromList;
const AudiogramTestDetailForm = () => {
  const history = useHistory()
  const location = useLocation();

  useEffect(() => {
    if (location && location.state) {
      propsFromList = location.state;
    } else {
      history.goBack();
    }
  }, []);

  const splitText = location?.state?.review?.split('\n');

  return (
    <div>
      <Button
        size='small'
        style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <br />
      <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Audiogram History</h3>
      {location && location.state ? (
        <Form layout='vertical' style={{ padding: 15, borderRadius: 20, backgroundColor: '#fff' }}>
          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
              <p>
                <span style={{ width: '30%', display: 'inline-block', fontWeight: 'bold' }}>
                  Name :
                </span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {' '}
                  {location && location.state && location.state.fullName}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block', fontWeight: 'bold' }}>
                  Date of Birth :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.dob != null
                    ? moment(location.state.dob).format('MM/DD/YYYY')
                    : ''}
                </span>
              </p>
            </div>

            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5'>
              <p>
                <span style={{ width: '30%', display: 'inline-block', fontWeight: 'bold' }}>
                  Location :{' '}
                </span>
                <span style={{ width: '70%', display: 'inline-block' }}>
                  {location && location.state && location.state.com_loc_name
                    ? location.state.com_loc_name
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block', fontWeight: 'bold' }}>
                  Department :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.department != null
                    ? location.state.department
                    : ''}
                </span>
              </p>

              <p>
                <span style={{ width: '30%', display: 'inline-block', fontWeight: 'bold' }}>
                  Job Title :{' '}
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.Job_Title != null
                    ? location.state.Job_Title
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block', fontWeight: 'bold' }}>
                  Hire Date :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.Hire_Rehire_Date
                    ? moment(location.state.Hire_Rehire_Date).format('MM/DD/YYYY')
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <Divider />
          <h5 style={{ color: 'rgb(195,95,78)' }}>Right Ear Results</h5>

          <div className='row'>
            <div className='col-md-12 col-sm-12'>
              <RightEarTestForm id={location && location.state.id} />
              {location.state.test_name == 'STS Report' && location.state.is_baseline && (
                <p>
                  Established Baseline(
                  {location.state.testing_date_basline &&
                    moment(location.state.testing_date_basline).format('MM/DD/YYYY')}
                  )
                </p>
              )}
            </div>
          </div>
          <Divider />
          <h5 style={{ color: 'rgb(60,107,246)' }}>Left Ear Results</h5>

          <div className='row'>
            <div className='col-md-12 col-sm-12'>
              <LeftEarTestForm id={location && location.state.id} />
              {location.state.test_name == 'STS Report' && location.state.is_baseline && (
                <p>
                  Established Baseline(
                  {location.state.testing_date_basline &&
                    moment(location.state.testing_date_basline).format('MM/DD/YYYY')}
                  )
                </p>
              )}
            </div>
          </div>
          <br />
          <div className='row'>
            <div className='col-md-12 col-sm-12'>
              <ShowHisGraph />
            </div>
          </div>
        </Form>
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 15,marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AudiogramTestDetailForm;
