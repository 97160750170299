import React, { useEffect } from 'react';
import { Form } from 'antd';
import _ from 'underscore';
import { useHistory } from 'react-router-dom';

const CcfParkingTab = (propsData) => {
  const history = useHistory();
  useEffect(() => {
    if (propsData == undefined) {
      history.goBack();
    }
  }, []);
  let propsValues = propsData && propsData.propsData;
  const { eccf_info, parking_service } = propsValues || {};

  return (
    <>
      <Form layout='vertical' style={{ padding: 20, borderRadius: 20, backgroundColor: '#fff' }}>
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '350%', fontWeight: 900 }}>
                {' '}
                Is Clinic Set-up for e-CCF with FormFox{' '}
              </span>{' '}
              <span style={{ flexBasis: '100%', textAlign:'center' }}>{eccf_info ? eccf_info : ''}</span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}

          <div className='col-md-6 col-sm-5' style={{paddingLeft:'25px'}}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '350%', fontWeight: 900 }}>
                {' '}
                Is there 18-wheeler parking available
              </span>{' '}
              <span style={{ flexBasis: '60%' }}>{parking_service ? parking_service : ''}</span>
            </p>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CcfParkingTab;
