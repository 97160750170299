import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import MaskedInput from 'antd-mask-input';
import axios from "axios";
import _ from 'underscore';
import setting from '../../config/setting'
import { history } from '../../redux/store';
import {
  Alert,
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Input,
  Radio,
  Rate,
  Select,
  Switch,
  Tag,
  Form,
  DatePicker,
  Spin, Space, Row, Col, Divider, Tooltip, Collapse
} from 'antd';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import { BookOutlined, UserOutlined, MailOutlined } from '@ant-design/icons/lib';

const { Option } = Select;
const { Panel } = Collapse;
const options = [
  { label: 'Checkbox 1', value: '1' },
  { label: 'Checkbox 2', value: '2' },
  { label: 'Checkbox 3', value: '3' }
];
const FormItem = Form.Item;
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
};

// const pageData: IPageData = {
//   title: 'Form elements',
//   fulFilled: true,
//   breadcrumbs: [
//     {
//       title: 'Home',
//       route: 'default-dashboard'
//     },
//     {
//       title: 'UI Kit ',
//       route: 'default-dashboard'
//     },
//     {
//       title: 'Form elements'
//     }
//   ]
// };

const CreateEmployee = ({ setData,sendDataToParent }) => {
  //usePageData(pageData);
  console.log("setData::", setData)

  const [msg, setMsg] = useState('');
  const [ResponseVal, setResponse] = useState(false);
  const [suffixNew, setSuffix] = useState('');
  const [firstNameNew, setFirstName] = useState('');
  const [middleNameNew, setmiddleName] = useState('');
  const [lastNameNew, setlastName] = useState('');
  const [sexNew, setsex] = useState('');
  const [statusNew, setstatus] = useState('');
  const [dotNew, setdot] = useState('');
  const [jobTitleNew, setjobTitle] = useState('');
  const [mobileNew, setaltMobile] = useState('');
  const [emailNew, setemail] = useState('');
  const [workEmailNew, setworkEmail] = useState('');
  const [streetNew, setstreet] = useState('');
  const [program, setProgram] = useState('');
  const [street2New, setstreet2] = useState('');
  const [stateNewA, setstateNew] = useState('');
  const [cityNewA, setcityNew] = useState('');
  const [zipNew, setzip] = useState('');
  const [ssnNew, setssn] = useState('');
  const [locationNewA, setlocationNew] = useState('');
  const [dlNew, setdl] = useState('');
  const [countrNew, setCountr] = useState('');
  const [comNew, setCom] = useState('');
  const [dateNew, setDate] = useState('');
  const [countName, setCountrName] = useState('');
  const [stateNewId, setstateNewID] = useState('');
  const [stateLocal, setStateLocal] = useState('');

  const [PassportNN, setPassportNN] = useState('');
  const [NationalityNN, setNationalityNN] = useState('');
  
  const [errorVal, setError] = useState(false);
  const [bool, setBool] = useState(false);
  const [boolNew, setBoolNew] = useState(false);
  const [boolN, setBoolN] = useState(false);
  const [boolNe, setBoolNe] = useState(false);

  const [age, setAge] = useState(0);
  const [mobilePhone, setMobilePhone] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [sendData, setSendData] = useState({});
  const [change, setChange] = useState(false);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [loc, setLoc] = useState([]);
  const [locNew, setLocNew] = useState([]);
  const [comp, setcomp] = useState([]);
  const [Res, setRes] = useState([]);
  const [CompDataNew, setCompDataNew] = useState([]);
  const [CompDataNewTrue, setCompDataNewTrue] = useState(false);

  const location = useLocation();
  const data = location.state;

  const handleSearch = (value) =>
    setDataSource(!value ? [] : [value, value + value, value + value + value]);

  const [radioValue, setRadioValue] = useState(1);

  const handleRadioChange = (e) => setRadioValue(e.target.value);

  useEffect(() => {
    console.log("use")
    
    console.log("this.props", data);
    setSendData(data)
    setChange(true)
    // getAllData()
    getCountry()
    getAllDataComp()
    // getResPArt()

  }, [])

  console.log("sendData", sendData);




  function getAllDataComp() {
    let custIdArr = [];
    axios.post(setting.serverUrl + '/api/CustomerData', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from getData ", response.data)

      if (response && response.status == 200) {

        _.each(response.data, (res) => {

          if (res.is_company) {
            custIdArr.push(res);
          }

        })

        setCompDataNew(custIdArr)

        setCompDataNewTrue(!CompDataNewTrue)

        setTimeout(() => {

          //getCust(custIdArr);
          getResPArt(custIdArr)

        }, 2000)

        setcomp(response.data)
        setBoolNew(true)

      }
    })

  }
  console.log("CompDataNew", CompDataNew)
  console.log("comp", comp)

  function getResPArt(custId) {
    axios.post(setting.serverUrl + '/api/Res', {
      // axios.get('http://localhost:3001/api/Res', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        id: setData.id
      }
    }).then(response => {
      console.log("Respone from getResPArt ", response.data)

      if (response && response.status == 200) {

        setRes(response.data)
        setBool(true)

      }
    })

  }

  console.log("Res", Res)

  function getAllData() {
    axios.post(setting.serverUrl + '/api/CustomerData', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from res.partner", response.data)


      if (response && response.status == 200) {
        // this.setState({ allData: response.data, change: true });
        setBoolN(true)
      }
    })

  }

  function getCountry() {

    let countryArr = [];
    axios.post(setting.serverUrl + '/api/getCountry', {
      // axios.get('http://localhost:3001/api/getCountry', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from getCountry", response.data)
      setCountry(response.data)

      if (response && response.status == 200) {

        setBoolNe(true)

      }

    })

  }


  function stateSelect(event) {

    console.log("event country", JSON.parse(event));

    setStateLocal(JSON.parse(event).name)

    var parseData = JSON.parse(event);

    setCountr(parseData.id);
    setCountrName(parseData.name);
    axios.post(setting.serverUrl + '/api/getState', {
      // axios.get('http://localhost:3001/api/getState', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        event: parseData.id
      }
    }).then(response => {
      console.log("Respone from getState", response.data)


      if (response && response.status == 200) {
        // this.setState({ allData: response.data, change: true });
        setState(response.data)
      }


    })

  }


  function locSel(event) {

    console.log("event locSel", event);

    //var parseDataNew = JSON.parse(event)

    setCom(event);

    let dataArr = [];


    _.each(Res, (ResData) => {

      console.log("ResData.commercial_partner_id", ResData.commercial_partner_id);

      if (ResData.commercial_partner_id
        && ResData.commercial_partner_id.length > 0
        && ResData.commercial_partner_id[0] == event) {

        dataArr.push(ResData)

      }
    })

    console.log("dataArrone", dataArr)
    setLoc(dataArr);

  }

  console.log("loc::::", loc);

  // function locationData(dataArr) {
  //   console.log("dataArr::",dataArr)
  //   let dataArrNew = [];

  //   _.each(dataArr[0].child_ids, (child) => {

  //     _.each(Res, (allCustList) => {

  //       if (child == allCustList.id) {

  //         dataArrNew.push(allCustList)

  //       }
  //     })

  //   })

  //   setLocNew(dataArrNew)

  // }

  // console.log("locNew", locNew);

  function onChangeDate(date, dateString) {

    console.log("dob", date, dateString);
    setDate(dateString)

    //var date = moment().format()

    var today = new Date();
    var birthDate = new Date(dateString);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log("age_now", age_now);
    setAge(age_now)

  }

  console.log("age", age)


  function mobile(event) {
    console.log("event", event.target.value);

    setMobilePhone(event.target.value);

  }

  console.log("mobilePhone", mobilePhone)

  function suffix(event) {
    console.log("suffix", event);
    setSuffix(event)

  }

  function firstName(event) {
    console.log("firstName", event.target.value);
    setFirstName(event.target.value)
  }

  function middleName(event) {
    console.log("middleName", event.target.value);
    setmiddleName(event.target.value)
  }

  function lastName(event) {
    console.log("lastName", event.target.value);
    setlastName(event.target.value)
  }

  function sex(event) {
    console.log("sex", event);
    setsex(event)
  }

  function status(event) {
    console.log("status", event);
    setstatus(event)
  }

  function dot(event) {
    console.log("dot", event);
    setdot(event)
  }

  function jobTitle(event) {
    console.log("jobTitle", event.target.value);
    setjobTitle(event.target.value)
  }

  function altMobile(event) {
    console.log("altMobile", event.target.value);
    setaltMobile(event.target.value)
  }

  function email(event) {
    console.log("email", event.target.value);
    setemail(event.target.value)
  }

  function workEmail(event) {
    console.log("workEmail", event.target.value);
    setworkEmail(event.target.value)
  }

  function street(event) {
    console.log("street", event.target.value);
    setstreet(event.target.value)
  }

  function street2(event) {
    console.log("street2", event.target.value);
    setstreet2(event.target.value)
  }

  function stateNew(event) {
    console.log("stateNew", JSON.parse(event));
    var parseDataState = JSON.parse(event);
    setstateNew(parseDataState.name)
    setstateNewID(parseDataState.id)
  }

  console.log("state", stateNewA, stateNewId)

  function cityNew(event) {
    console.log("cityNew", event.target.value);
    setcityNew(event.target.value)
  }

  function zip(event) {
    console.log("zip", event.target.value);
    setzip(event.target.value)
  }

  function ssn(event) {
    console.log("ssn", event.target.value);
    setssn(event.target.value)
  }

  function locationNew(event) {
    console.log("locationNew", event);
    setlocationNew(event)
  }

  function dl(event) {
    console.log("dl", event.target.value);
    setdl(event.target.value)
  }
  function setPassport(event) {
    console.log("setPassport", event.target.value);
    setPassportNN(event.target.value)
  }
  function setNationality(event) {
    console.log("setNationality", event.target.value);
    setNationalityNN(event.target.value)
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  function onSearchState(val) {
    console.log('search:', val);
  }

  function saveEmployee() {
    setResponse(true)
    console.log("stateLocal", stateLocal)

    if (firstNameNew && lastNameNew && sexNew
      && dateNew && locationNewA) {

      console.log("saveEmployee else");
      axios.post(setting.serverUrl + '/api/saveEmployee', {
        // axios.get('http://localhost:3001/api/saveEmployee', {
        params:
        {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),

          suffixNew: suffixNew,
          firstNameNew: firstNameNew,
          middleNameNew: middleNameNew,
          lastNameNew: lastNameNew,
          sexNew: sexNew,

          statusNew: statusNew,
          dotNew: dotNew,
          program:program,
          jobTitleNew: jobTitleNew,
          mobileNew: mobileNew,
          emailNew: emailNew,

          workEmailNew: workEmailNew,
          streetNew: streetNew,
          street2New: street2New,
          stateNewA: [parseInt(stateNewId), stateNewA],
          cityNewA: cityNewA,

          zipNew: zipNew,
          ssnNew: ssnNew,
          locationNewA: parseInt(locationNewA),
          dlNew: dlNew,
          countrNew: [parseInt(countrNew), countName],

          comNew: parseInt(setData.id),
          dateNew: dateNew,
          mobilePhone: parseInt(mobilePhone),
          age: age,
          passport:PassportNN,
          nationality:NationalityNN

        }
      }).then(response => {
        console.log("Respone from saveEmployee ", response.data)
        setResponse(false)

        if (response && response.status == 200) {

          // setMsg("Employee Added Successfully!")
          sendDataToParent("Added");
          setFirstName('')
          setlastName('')
          setsex(null)
          setstatus(null)
          setdot(null)
          setProgram(null)
          setjobTitle('')
          setaltMobile('')
          setemail('')
          setworkEmail('')
          setstreet('')
          setstreet2('')
          setstateNew(null)
          setcityNew('')
          setAge(0)
          setzip('')
          setssn('')
          setlocationNew(null)
          setdl('')
          setDate("")
          setCountrName('')
          setMobilePhone('')
          setStateLocal(null)
          setPassportNN('')
          setNationalityNN('')
          // history.push({ pathname: `/vertical/employeeList` });

          //setRes(response.data)
          // ResponseValData(false)

        }
      })

    } else {
      setResponse(false)
      console.log("saveEmployee if");
      alert("please fill all required fields!");
      setError(true);
    }

  }


  return (
    <div style={{ padding: 20 }}>
      <span style={{ textAlign: 'left',fontSize:24,fontWeight:900 }}>Add Employee</span>&nbsp;&nbsp;
      <span style={{fontSize:12}}>* Denotes Required Fields</span>
      {bool
        //&& boolNew
        //&& boolN && boolNe
        ?
        <Card style={{ backgroundColor: "#fff", borderRadius: 20 }}>
          <Form layout='vertical' style={{ padding: 0 }}>
          <div className='row'>
              <div className='col-md-4 col-sm-12'>
                <FormItem label={errorVal ? <p>Company Name <span style={{color:'red'}}>*(Required field)</span></p> : <p>Company Name *</p>}>
                  <Input
                    style={{ height: '30px' }}
                    value={setData && setData.name} readOnly />

                </FormItem>
              </div>
              <div className='col-md-2'></div>
              <div className='col-md-4 col-sm-12'>
                <FormItem label={errorVal ? <p>Employee Location <span style={{color:'red'}}>*(Required field)</span></p> : <p>Employee Location *</p>}>
                  <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                  }
                  value={locationNewA}
                    size="small"
                    onChange={(event) => locationNew(event)}
                  >
                    {Res.length > 0 && Res.map((loc, index) => (

                      <Option value={loc.id}>{loc.name}</Option>


                    ))}

                    {/* <Option value="Active">Active</Option>
      <Option value="Teminated">Teminated</Option> */}
                  </Select>
                </FormItem>
              </div>
              <div className='col-md-2'></div>
              {/* <div className='col-md-2'> </div> */}

              {/* <div className='col-md-2'> </div> */}
            </div>
          <div className='row'>
              {/* <div className='col-md-2 col-sm-12'>
                <FormItem label='Suffix'>
                  <Select defaultValue=""
                    onChange={(event) => suffix(event)}
                  >
                    <Option value="Mr">Mr</Option>
                    <Option value="Mrs">Mrs</Option>
                  </Select>
                </FormItem>
              </div> */}
              {/* <div className='col-md-1 col-sm-12'></div> */}
              {/* <div className='col-md-2'> </div> */}
              <div className='col-md-4 col-sm-12'>
                <FormItem label={errorVal ? <p>First Name <span style={{color:'red'}}>*(Required field)</span></p> : <p>First Name *</p>}>
                  <Input
                    style={{ height: '30px' }}
                    onChange={(event) => firstName(event)}
                    value={firstNameNew}
                    placeholder='First Name'
                  //prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  />

                </FormItem>
              </div>
              <div className='col-md-2'></div>
              {/* <div className='col-md-3 col-sm-12'>
                <FormItem label='Middle Name'>
                  <Input placeholder='Middle Name' onChange={(event) => middleName(event)} />
                </FormItem>
              </div> */}
              {/* <div className='col-md-2'> </div> */}
              <div className='col-md-4 col-sm-12'>
                <FormItem label={errorVal ? <p>Last Name <span style={{color:'red'}}>*(Required field)</span></p> : <p>Last Name *</p>}>
                  <Input
                    style={{ height: '30px' }}
                    placeholder='Last Name'
                    onChange={(event) => lastName(event)}
                    value={lastNameNew}
                  //prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  />
                </FormItem>
              </div>
              <div className='col-md-2'></div>
              {/* <div className='col-md-2'> </div> */}
            </div>

            {/* <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <FormItem label='Middle Name'>
                  <Input placeholder='Middle Name' onChange={(event) => middleName(event)} />
                </FormItem>
              </div>
             
              <div className='col-md-6 col-sm-12'>
                <FormItem label={errorVal ? 'Last Name *(Required field)' : 'Last Name *'}>
                  <Input

                    placeholder='Last Name'
                    onChange={(event) => lastName(event)}
                  //prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  />
                </FormItem>
              </div>
              
            </div> */}

            <div className='row'>

              <div className='col-md-4 col-sm-12'>
                <FormItem label={errorVal ? <p>Date Of Birth <span style={{color:'red'}}>*(Required field)</span></p> : <p>Date Of Birth *</p>}>
                  <DatePicker
                    style={{ width: '100%', height: '30px', borderRadius: 25 }}
                    // value={moment(dateNew)}
                    onChange={(date, dateString) => onChangeDate(date, dateString)}
                  //onChange={onChangeDate}
                  />
                </FormItem>
              </div>
              {/* <div className='col-md-2'> </div> */}
              <div className='col-md-2 col-sm-12'>
                <FormItem label='Age (in Years)'>
                  {age}
                  {/* <Input

                    //placeholder='Age'
                    readOnly
                    disabled
                    //defaultValue={age}
                    prefix={age}
                  /> */}
                </FormItem>
              </div>
              <div className='col-md-4 col-sm-12'>
                <FormItem label={errorVal ? <p>Gender <span style={{color:'red'}}>*(Required field)</span></p> : <p>Gender *</p>}>
                  <Select defaultValue=""
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                  }
                    size="small"
                    value={sexNew}
                    onChange={(event) => sex(event)}
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    {/* <Option value="Other">Other</Option> */}
                  </Select>
                </FormItem>
              </div>
              <div className='col-md-2'></div>
              {/* <div className='col-md-2'> </div> */}
            </div>
            <div className='row'>
              <div className='col-md-4 col-sm-12'>
                <FormItem label='SSN/TIN'>
                  <MaskedInput
                    style={{ height: '30px' }}
                    value={ssnNew}
                    mask="111-11-1111" onChange={(event) => ssn(event)} />
                  {/* <Input  placeholder='SSN/TIN' /> */}
                </FormItem>
              </div>
              <div className='col-md-2'></div>
              <div className='col-md-4 col-sm-12'>
                <FormItem label='Driving License'>
                  <Input
                    style={{ height: '30px' }}
                    value={dlNew}
                    placeholder='Driving License' onChange={(event) => dl(event)} />
                </FormItem>
              </div>
              <div className='col-md-2'></div>
            </div>
            <div className='row'>
              <div className='col-md-4 col-sm-12'>
                <FormItem label='Passport'>
                <Input
                    style={{ height: '30px' }}
                    value={PassportNN}
                    placeholder='Passport' 
                    onChange={(event) => setPassport(event)}
                     />
                </FormItem>
              </div>
              <div className='col-md-2'></div>
              <div className='col-md-4 col-sm-12'>
                <FormItem label='Nationality'>
                  <Input
                    style={{ height: '30px' }}
                    value={NationalityNN}
                    placeholder='Nationality' 
                    onChange={(event) => setNationality(event)}
                     />
                </FormItem>
              </div>
              <div className='col-md-2'></div>
            </div>
            <br/>
          <Collapse accordion>
        
        <Panel
          style={{ background: '#1C3A6A' }}
          header={
            <span style={{ color: 'white', fontSize: '140%' }}>
             Employee Contact Information
            </span>
          }
          key='1'
          // extra={
          //   vaccineStatus === 'exemption' ? (
          //     <span style={{ color: 'white' }}>
          //       Last updated: {moment(lastUpdate).format('MM-DD-YYYY hh:mm a')}{' '}
          //       <CheckCircleOutlined />
          //     </span>
          //   ) : null
          // }
        >
          <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label='Street'>
                      <Input
                        style={{ height: '30px' }}
                        value={streetNew}
                        placeholder='Street' onChange={(event) => street(event)} />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  {/* <div className='col-md-2'> </div> */}
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label='Street 2'>
                      <Input
                      value={street2New}
                        style={{ height: '30px' }}
                        placeholder='Street 2'
                        onChange={(event) => street2(event)}
                      //prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'> </div>
                </div>


                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label='Country'>
                      {/* <Select
                  onChange={(event) => stateSelect(event)}
                > */}

                      <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                        size="small"
                       // showSearch
                        //multiple
                        //style={{ width: 200 }}
                        //placeholder="Select Country"
                       // optionFilterProp="children"
                        value={stateLocal}
                        onChange={(event) => stateSelect(event)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        // filterOption={(input, option) =>
                        //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        // }
                      >
                        {country.length > 0 && country.map((count, index) => (

                          <Option value={JSON.stringify(count)}>{count.name}</Option>


                        ))}

                      </Select>
                    </FormItem>
                  </div>
                  <div className='col-md-2'> </div>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label='State'>

                      <Select
                        size="small"
                        showSearch
                        value={stateNewA}
                        //style={{ width: 200 }}
                        //placeholder="Select Country"
                        optionFilterProp="children"
                        onChange={(event) => stateNew(event)}
                        // onFocus={onFocus}
                        //onBlur={onBlur}
                        onSearch={onSearchState}
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                      >

                        {state.length > 0 && state.map((state, index) => (

                          <Option value={JSON.stringify(state)}>{state.name}</Option>


                        ))}

                      </Select>
                    </FormItem>
                  </div>
                  <div className='col-md-2'> </div>
                </div>


                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label='City'>
                      <Input
                        style={{ height: '30px' }}
                        value={cityNewA}
                        onChange={(event) => cityNew(event)}
                        placeholder='City'
                      //prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  {/* <div className='col-md-2'> </div> */}
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label='Zip Code'>
                      <Input
                      value={zipNew}
                        style={{ height: '30px' }}
                        placeholder='Zip Code' onChange={(event) => zip(event)} />
                    </FormItem>
                  </div>
                  <div className='col-md-2'> </div>
                </div>
                <div className='row'>

              <div className='col-md-4 col-sm-12'>
                <FormItem label='Mobile Number'>
                  <Input
                    style={{ height: '30px' }}
                    value={mobilePhone}
                    placeholder='Mobile Number'
                    onChange={(event) => mobile(event)}
                  //pattern="[1-9]{1}[0-9]{9}"
                  //prefix={<MailOutlined  style={{ color: 'rgba(0,0,0,.25)' }} />}
                  />
                </FormItem>
              </div>
              <div className='col-md-2'> </div>
              <div className='col-md-4 col-sm-12'>
                <FormItem label='Alternate Phone Number'>
                  <Input
                    style={{ height: '30px' }}
                    value={mobileNew}
                    onChange={(event) => altMobile(event)}
                    placeholder='Alternate Phone Number'
                  //prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  />
                </FormItem>
              </div>
              <div className='col-md-2'> </div>
            </div>
                <div className='row'>
              <div className='col-md-4 col-sm-12'>
                <FormItem label='Email'>
                  <Input
                    style={{ height: '30px' }}
                    value={emailNew}
                    placeholder='Email' onChange={(event) => email(event)} />
                </FormItem>
              </div>
              <div className='col-md-2'></div>
              {/* <div className='col-md-2'> </div> */}
              <div className='col-md-4 col-sm-12'>
                <FormItem label='Work Email'>
                  <Input
                    style={{ height: '30px' }}
                    value={workEmailNew}
                    onChange={(event) => workEmail(event)}
                    //prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder='Work Email'
                  //prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  />
                </FormItem>
              </div>
              <div className='col-md-2'></div>
              {/* <div className='col-md-2'> </div> */}
            </div>
            
        </Panel>
        <Panel
          style={{ background: '#EF6828', borderRadius: '0px 0px 10px 10px' }}
          header={
            <span style={{ color: 'white', fontSize: '140%' }}>
              Company Contact Information
            </span>
          }
          key='2'
          // extra={
          //   vaccineStatus === 'unvaccinated' ? (
          //     <span style={{ color: 'white' }}>
          //       Last updated: {moment(lastUpdate).format('MM-DD-YYYY hh:mm a')}{' '}
          //       <CheckCircleOutlined />
          //     </span>
          //   ) : null
          // }
        >
         
            <div className='row'>

              {/* <div className='col-md-2'> </div> */}
              <div className='col-md-4 col-sm-12'>
                <FormItem label='Job Title'>
                  <Input placeholder='Job Title'
                    style={{ height: '30px' }}
                    value={jobTitleNew}
                    onChange={(event) => jobTitle(event)} />
                </FormItem>
              </div>
              <div className='col-md-2'></div>
              <div className='col-md-4 col-sm-12'>
              <FormItem label='Status'>
                  <Select defaultValue=""
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                  }
                    size="small"
                    value={statusNew}
                    onChange={(event) => status(event)}
                  >
                    <Option value="leave of absence">Leave of absence</Option>
                    <Option value="deceased">Deceased</Option>
                    <Option value="Pre Employment">Pre Employment</Option>
                    <Option value="Active">Active</Option>
                    <Option value="Teminated">Teminated</Option>
                  </Select>
                </FormItem>
                </div>
              <div className='col-md-2'> </div>
            </div>
            <div className='row'>
              <div className='col-md-4 col-sm-12'>
              <FormItem label='DT Program'>

<Select defaultValue=""
showSearch
optionFilterProp="children"
filterOption={(input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
}
value={program}
size="small"
    onChange={(event) => setProgram(event)}
>
    {/* <Option value="All">All</Option> */}
    <Option value="DOT">DOT</Option>
    <Option value="NON - DOT">NON - DOT</Option>
    <Option value="Both">BOTH</Option>

</Select>

</FormItem>
                
              </div>
              <div className='col-md-2'></div>
              {/* <div className='col-md-2'> </div> */}
              <div className='col-md-4 col-sm-12'>
              <FormItem label='DT Mode'>
                  <Select defaultValue=""
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                  }
                    size="small"
                    value={dotNew}
                    onChange={(event) => dot(event)}
                  >
                   {program == 'NON - DOT' ?
        <>
        <Option value="Non - DOT">Non - DOT</Option>
        </>
:<>
        {/* <Option value="All">All</Option> */}
        <Option value="FMCSA">FMCSA</Option>
        <Option value="FAA">FAA</Option>
        <Option value="PHMSA">PHMSA</Option>
        <Option value="FTA">FTA</Option>
        <Option value="FRA">FRA</Option>
        <Option value="USCG">USCG</Option>
        </>
}
                    {/* <Option value="Active">Active</Option>
      <Option value="Teminated">Teminated</Option> */}
                  </Select>
                </FormItem>
              </div>
              <div className='col-md-2'></div>
              {/* <div className='col-md-2'> </div> */}
            </div>
        </Panel>
      </Collapse>
            
           
            {/* <h4 style={{ padding: 10, textAlign: 'left' }}>Home Address</h4> */}
            
            <br />
            {/* <h4 style={{ padding: 10, textAlign: 'left' }}>Add Company Info</h4> */}

            <div className='row'>
              <div className='col-md-4 col-sm-4'>

              </div>
              <div className='col-md-5 col-sm-5'>
                <Button type='primary' style={{ width: '70%',backgroundColor:"#1b3969" }} onClick={saveEmployee}>Save</Button><br /><br />
                {ResponseVal &&

                  <Space size="middle" style={{ marginTop: 3, marginLeft: 110 }}>

                    <Spin size="large" />
                  </Space>

                }
                <span style={{ fontSize: 15, color: 'green', fontWeight: 700, marginLeft: 25 }}>{msg}</span>
              </div>


            </div>


          </Form>
        </Card>
        :
        <Row>
          <Col span={10}>
          </Col>
          <Col span={8}>
            <Space size="middle" style={{ marginTop: 10 }}>

              <Spin size="large" />
            </Space>
          </Col>
        </Row>
      }

    </div>
  );
};

export default CreateEmployee;
