import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import setting from '../../config/setting';
import { Spin, Space, Row, Col, Select, Button, notification } from 'antd';
import { FlexGridDetail } from '@grapecity/wijmo.react.grid.detail';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';

let columnPicker = [];
let flexes = '';
let showGrid = '';
let dragOver = '';
let listBoxes = '';
let i = 1;
var totalRecords;
class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
      },
      'packages.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const PackageList = () => {
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [changeP, setChangeP] = useState(false);
  const [packages, setPackages] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [foundP, setFoundP] = useState(false);
  const [noRecordMessageP, setNoRecordMessageP] = useState('No Record Found !');
  const [errMsg, setErrMsg] = useState();
  const [pkgNameFilter, setPkgNameFilter] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const theGrid = useRef();
  const theSearch = useRef();
  const gridRef = useRef(null);
  const fixedContainerRef = useRef(null);
  let theGrids = theGrid.current;
  const filterRefCounterPkg = useRef();

  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }

  useEffect(() => {
    getPackages();
  }, [pageSize]);

  const getPackages = () => {
    axios
      .post(setting.serverUrl + '/api/hospital_customers_lines', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          compId: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          let pkgNames = {};
          response &&
            response.data
              .filter((x) => x.display_name && x.display_name.length > 0 && x.display_name != false)
              .map((data) => (pkgNames[data.display_name] = 'here'));
           console.log("response data in pkg", response.data)
          setPackages(response.data);
          const collectionView1 = new CollectionView(response.data, {
            pageSize: response.data.length
          });
          setViewData(collectionView1);
          const collectionView2 = new CollectionView(response.data, {
            pageSize: pageSize,
            collectionChanged: (s) => {
              filterRefCounterPkg.current = s.totalItemCount;
              var filterCounter = document.getElementById('filterCounterPkg');
              if (filterCounter) {
                filterCounter.textContent = s.totalItemCount;
              }
            }
          });
          setView(collectionView2);
          totalRecords = response.data.length;
          //setWijmoFlexGrid(response.data, 10);
          setPkgNameFilter(pkgNames);
          setFoundP(false);
          setChangeP(true);
          setHasPermission(true);
        } else if (response.status === 201) {
          setFoundP(true);
          setNoRecordMessageP('Access permission not allowed !');
          setErrMsg('Access permission not allowed !');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();

    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;

    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };
  const getSubItemSource = (item) => {
    const res = packages.filter((value) => {
      if (value.id === item.item.id) {
        return value.test_name;
      }
    });

    let dataArr = res[0].test_name
      .split('\n')
      .filter(Boolean)
      .map((element) => {
        return {
          test_name: element.replace("- Non-DOT", "")

        };
      });

    return dataArr;
  };

  return (
    <div style={{ padding: 30 }}>
      <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Packages</h3>
      {!foundP && changeP ? (
        packages.length > 0 ? (
          <>
            <Row gutter={[32, 32]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div ref={fixedContainerRef}>
                  <Select
                    style={{ width: 170 }}
                    placeholder={`Items per page : ${pageSize}`}
                    onChange={paggination}
                    options={[
                      { label: 'Items per page : 10', value: 10 },
                      { label: 'Items per page : 20', value: 20 },
                      { label: 'Items per page : 50', value: 50 }
                    ]}
                    getPopupContainer={() => fixedContainerRef.current}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                  size='small'
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>
            <br />

            {/* to export entire data of table */}
            <FlexGrid
              ref={theGrid}
              style={{ display: 'none' }}
              className='dummyWijmoData'
              itemsSource={viewData}
              initialized={gridInitializedData}
              formatItem={formatItem}
              isReadOnly={true}
            >
              <FlexGridColumn binding='display_name' header='Name' minWidth={1200} width='*' />
              <FlexGridDetail
                template={(ctx) => (
                  <FlexGrid
                    id='theGridTestingEvent'
                    autoGenerateColumns={false}
                    headersVisibility='Column'
                    alternatingRowStep={1}
                    // autoRowHeights={true}
                    // loadedRows={onloadedRows.bind(}
                    itemsSource={getSubItemSource(ctx)}
                    itemsPerPage={10}
                    isReadOnly={true}
                  >
                    <FlexGridColumn binding='test_name' header='Test Name' width='*' />
                    {/* <FlexGridCellTemplate cellType='Cell' template={showItemType} />
                    </FlexGridColumn> */}

                    <FlexGridFilter />
                  </FlexGrid>
                )}
              />
            </FlexGrid>

            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              className='packagelist cliniclisttable'
              itemsSource={view}
              initialized={gridInitialized}
              formatItem={formatItem}
              itemsPerPage={10}
              isReadOnly={true}
              scrollMode="Virtual" // Add this line for smooth scrolling
            >
              <div style={{ display: 'none' }}>
                <wjInput.ListBox
                  className='column-picker'
                  itemsSource={listboxColumns}
                  checkedMemberPath='visible'
                  displayMemberPath='header'
                  lostFocus={hidePicker}
                  initialized={initializedListBox}
                ></wjInput.ListBox>
              </div>
              <FlexGridColumn binding='display_name' header='Name' width={1161} />
              <FlexGridDetail
                template={(ctx) => (
                  <div style={{ overflowX: 'auto', transition: 'width 0.5s ease' }}>
                  <FlexGrid
                    id='theGridTestingEvent'
                    autoGenerateColumns={false}
                    headersVisibility='Column'
                    alternatingRowStep={1}
                    // autoRowHeights={true}
                    // loadedRows={onloadedRows.bind(}
                    itemsSource={getSubItemSource(ctx)}
                    itemsPerPage={10}
                    isReadOnly={true}
                    scrollMode="Virtual" // Add this line for smooth scrolling
                  >
                    <FlexGridColumn binding='test_name' header='Test Name' width='*' />
                    {/* <FlexGridCellTemplate cellType='Cell' template={showItemType} />
                    </FlexGridColumn> */}

                    <FlexGridFilter />
                  </FlexGrid>
                  </div>
                )}
                isAnimated={true} // Add this if you want to animate expansion
              />

              <FlexGridFilter
                showFilterIcons={true}
                placeholder='Filter data...'
                defaultFilterType='Value'
                filterColumns={['display_name']}
              />
            </FlexGrid>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                ref={filterRefCounterPkg}
                id='filterCounterPkg'
              >
                {Globalize.format(filterRefCounterPkg.current, 'n0')}
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                {' '}
                out of {totalRecords}
              </p>
            </div>
          </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessageP}</h5>
        )
      ) : (
        <>
          {!foundP ? (
            <Row>
              <Col span={10}></Col>
              <Col span={8}>
                <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
                  <Spin size='large' />
                </Space>
              </Col>
            </Row>
          ) : (
            <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessageP}</h5>
          )}
        </>
      )}
    </div>
  );
};

export default PackageList;
