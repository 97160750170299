import React, { useEffect } from 'react';
import { Form } from 'antd';
import _ from 'underscore';
import { useHistory } from 'react-router-dom';
const EbtTwicTab = (propsData) => {
  const history = useHistory();
  useEffect(() => {
    if (propsData == undefined) {
      history.goBack();
    }
  }, []);
  let propsValues = propsData && propsData.propsData;
  const { clinic_use, ebt_device_nhsa, clinic_collector_twic } = propsValues || {};
  return (
    <>
      <Form layout='vertical' style={{ paddingLeft: 20,marginTop:-15, borderRadius: 20, backgroundColor: '#fff' }}>
        <h5 style={{ color: '#247' }}>Evidential Breath Alcohol Devices(EBT)</h5>

        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px', marginLeft:'-8px' }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '40%', fontWeight: 900, paddingLeft:8 }}>
                {' '}
                Teh Clinic Uses The EBT Device
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 15 }}>
                {' '}
                {clinic_use ? clinic_use : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5'>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '100%', fontWeight: 900, paddingLeft:10 }}>
                {' '}
                The EBT device is NHTSA Approved and listed on the CPL
              </span>{' '}
              <span style={{ flexBasis: '30%', paddingLeft: 15 }}>
                {' '}
                {ebt_device_nhsa ? ebt_device_nhsa : ''}
              </span>
            </p>
          </div>
        </div>

        <p style={{ paddingTop: 40, fontWeight: 900, color: 'grey'}}>
          <i>
            {' '}
            For All Positive Alcohol Tests Results WSS Requires the following to be provided <br />
            1. The most recent celibration, prior to the positive alcohol test result
            <br />
            2. The initial test result,
            <br />
            3. The confirmation test, and
            <br />
            4. The calibration to be completed after the testing event.
          </i>
        </p>
        <h5 style={{ color: '#247', paddingTop:10 }}> TWIC</h5>
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px', margin:'-5px' }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '45%', fontWeight: 900, paddingLeft:7 }}>
                {' '}
                Does the clinic collectors have TWIC cards for entry into plants, parts, etc
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 160, textAlign:'center',  }}>
                {' '}
                {clinic_collector_twic ? clinic_collector_twic : ''}
              </span>
            </p>
          </div>

          <div className='col-md-1 col-sm-1'></div>
          <div className='col-md-5 col-sm-5'></div>
        </div>
      </Form>
    </>
  );
};

export default EbtTwicTab;
