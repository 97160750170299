import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import MaskedInput from 'antd-mask-input';
import axios from 'axios';
import _ from 'underscore';
import setting from '../../config/setting';
import { history } from '../../redux/store';
import {
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Spin,
  Space,
  Row,
  Col,
  Collapse,
  InputNumber,
  notification
} from 'antd';
import moment from 'moment';

const { Option } = Select;

const { Panel } = Collapse;
const FormItem = Form.Item;
const EditAddEmployee = ({ setData, sendDataToParentNN }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  let data;
  if (setData == 'undefined' || setData === undefined) {
    console.log('if');
    data = location.state ? location.state.data : null;
  } else {
    console.log('else');
    data = setData;
  }
  const [dtProgram, setDtProgram] = useState('DOT');
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [dtMode, setDtMode] = useState('');
  const [suffixNew, setSuffix] = useState('');
  const [firstNameNew, setFirstName] = useState('');
  const [middleNameNew, setmiddleName] = useState('');
  const [lastNameNew, setlastName] = useState('');
  const [sexNew, setsex] = useState('');
  const [statusNew, setstatus] = useState('');
  const [dotNew, setdot] = useState('');
  const [jobTitleNew, setjobTitle] = useState('');
  const [mobileNew, setaltMobile] = useState('');
  const [emailNew, setemail] = useState('');
  const [workEmailNew, setworkEmail] = useState('');
  const [streetNew, setstreet] = useState('');
  const [street2New, setstreet2] = useState('');
  const [stateNewA, setstateNew] = useState('');
  const [cityNewA, setcityNew] = useState('');
  const [zipNew, setzip] = useState('');
  const [ssnNew, setssn] = useState('');
  const [locationNewA, setlocationNew] = useState('');
  const [dlNew, setdl] = useState('');
  const [ppNew, setPp] = useState('');
  const [countrNew, setCountr] = useState('');
  const [comNew, setCom] = useState('');
  const [dateNew, setDate] = useState('');
  const [countName, setCountrName] = useState('');
  const [stateNewId, setstateNewID] = useState('');
  const [stateLocal, setStateLocal] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [errorVal, setError] = useState('');
  const [bool, setBool] = useState(false);
  const [boolNew, setBoolNew] = useState(false);
  const [boolN, setBoolN] = useState(false);
  const [boolNe, setBoolNe] = useState(false);
  const [RefreshData, setRefreshData] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [age, setAge] = useState();
  const [mobilePhone, setMobilePhone] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [sendData, setSendData] = useState({});
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [loc, setLoc] = useState([]);
  const [locNew, setLocNew] = useState([]);
  const [comp, setcomp] = useState([]);
  const [Res, setRes] = useState([]);
  const [CompDataNew, setCompDataNew] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dtProgramFields = {
    DOT: ['FMCSA', 'USCG', 'PHMSA', 'FTA', 'FAA', 'FRA', 'HHS'],
    'NON - DOT': ['Non - DOT'],
    Both: ['FMCSA', 'USCG', 'PHMSA', 'FTA', 'FAA', 'FRA', 'HHS']
  };

  useEffect(() => {
    console.log('edit employee this.props', data);
    if (data !== null) {
      const customerData = JSON.parse(localStorage.getItem('customerData'));
      let companyRecords = [];

      companyRecords.push({
        id: customerData.id,
        name: customerData.display_name
      });

      setSelectedCompany(customerData.id);
      setCompDataNew(companyRecords);

      setTimeout(() => {
        getResPArt(customerData.id);
        getCountry();
      }, 1000);

      if (data == undefined) {
        data = JSON.parse(localStorage.getItem('employeeData'));
      }

      localStorage.setItem('employeeData', JSON.stringify(data));
      updateValues();

      form.setFieldsValue({
        firstName: formatValue(data['first_name']),
        middleName: formatValue(data['middle_name']),
        lastName: formatValue(data['last_name']),
        company: formatValue(data['company'][1]),
        Sex: formatValue(data['Gender']),
        dob: data['dob'].length > 0 ? moment(data['dob']) : '',
        //empLocation: data['emp_location'][1].split(',')[1]
        empLocation: data['emp_location']
      });
      getCountry();

      if (data['country_id']) {
        getStates(data['country_id'][0]);
      }
    } else {
      history.goBack();
    }
  }, [setData]);

  function getReasons() {
    axios
      .post(setting.serverUrl + '/api/getCompReasons', {
        // axios.get('http://localhost:3001/api/getReasons', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        console.log('Respone from getCompReasons ', response.data);

        if (response && response.status == 200) {
          setRes(response.data.location);
          setBool(true);
          // setReason(response.data.reason)
          // setLocations(response.data.location)
          //setBool(true)
          // setBoolN(true)
        }
      });
  }

  function getResPArt(custId) {
    setLoadingLocations(true);
    axios
      .post(setting.serverUrl + '/api/Res', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          id: custId
        }
      })
      .then((response) => {
        console.log('Respone from getResPArt ', response.data);

        if (response && response.status == 200) {
          setRes(response.data);
          setBool(true);
        }
      });
  }

  // console.log('Res', Res);

  // function getAllData() {
  //     axios
  //         .post(setting.serverUrl + '/api/CustomerData', {
  //             params: {
  //                 email: localStorage.getItem('myData'),
  //                 user: localStorage.getItem('myData'),
  //                 pass: localStorage.getItem('myPass')
  //             }
  //         })
  //         .then((response) => {
  //             console.log('Respone from res.partner', response.data);

  //             if (response && response.status == 200) {
  //                 // this.setState({ allData: response.data, change: true });
  //                 setBoolN(true);
  //             }
  //         });
  // }

  function getCountry() {
    let countryArr = [];
    axios
      .post(setting.serverUrl + '/api/getCountry', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass')
        }
      })
      .then((response) => {
        console.log('Respone from getCountry', response.data);
        setCountry(response.data);

        if (response && response.status == 200) {
          setBoolNe(true);
        }
      });
  }

  function stateSelect(event) {
    console.log('event country', JSON.parse(event));

    setStateLocal(JSON.parse(event).name);

    var parseData = JSON.parse(event);

    setCountr(parseData.id);
    setCountrName(parseData.name);
    getStates(parseData.id);
  }

  function getStates(countryId) {
    axios
      .post(setting.serverUrl + '/api/getState', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          event: countryId
        }
      })
      .then((response) => {
        console.log('Respone from getState', response.data);

        if (response && response.status == 200) {
          setState(response.data);
        }
      });
  }

  function onChangeDate(date, dateString) {
    var newDateObj = new Date(dateString);
    setDate(newDateObj);

    var today = new Date();
    var birthDate = new Date(dateString); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    setAge(age_now);
  }

  function mobile(event) {
    console.log('event', event.target.value);
    const reg = /^\d+$/;
    if (reg.test(event.target.value)) {
      setMobilePhone(event.target.value);
    }
  }

  function compny(event) {
    console.log('company', event);
    setRes([]);
    setSelectedCompany(event);
    getResPArt(event);
    form.setFieldsValue({
      empLocation: ''
    });
  }

  function getCompanyData() {
    axios
      .post(setting.serverUrl + '/api/allCustomerData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass')
        }
      })
      .then((response) => {
        console.log('Respone from allCustomerData ', response.data);
        if (response && response.status == 200) {
          let fetchedRecords = response.data;
          let companyRecords = [];
          fetchedRecords.forEach((element) => {
            console.log('element', element);
            let exist = companyRecords.filter((company) => company.id == element.id);
            console.log('exist recod', exist);
            if (exist.length == 0) {
              companyRecords.push({
                id: element.id,
                name: element.display_name
              });
            }
          });
          setSelectedCompany(data['company'][0]);
          setCompDataNew(companyRecords);
          setBool(true);
          setTimeout(() => {
            getReasons();
            // getResPArt(data['company'][0]);
            getCountry();
          }, 1000);
        }
      });
  }

  function firstName(event) {
    console.log('firstName', event.target.value);
    setFirstName(event.target.value);
  }

  function middleName(event) {
    console.log('middleName', event.target.value);
    setmiddleName(event.target.value);
  }

  function lastName(event) {
    console.log('lastName', event.target.value);
    setlastName(event.target.value);
  }

  function sex(event) {
    console.log('sex', event);
    setsex(event);
  }

  function status(event) {
    console.log('status', event);
    setstatus(event);
  }

  function dot(event) {
    console.log('dot', event);
    setdot(event);
  }

  function jobTitle(event) {
    console.log('jobTitle', event.target.value);
    setjobTitle(event.target.value);
  }

  function altMobile(event) {
    console.log('altMobile', event.target.value);
    const reg = /^\d+$/;
    if (reg.test(event.target.value)) {
      setaltMobile(event.target.value);
    }
  }

  function email(event) {
    console.log('email', event.target.value);
    setemail(event.target.value);
  }

  function workEmail(event) {
    console.log('workEmail', event.target.value);
    setworkEmail(event.target.value);
  }

  function street(event) {
    console.log('street', event.target.value);
    setstreet(event.target.value);
  }

  function street2(event) {
    console.log('street2', event.target.value);
    setstreet2(event.target.value);
  }

  function stateNew(event) {
    console.log('stateNew', JSON.parse(event));
    var parseDataState = JSON.parse(event);
    setstateNew(parseDataState.name);
    setstateNewID(parseDataState.id);
  }

  function cityNew(event) {
    console.log('cityNew', event.target.value);
    setcityNew(event.target.value);
  }

  function zip(event) {
    console.log('zip', event.target.value);
    setzip(event.target.value);
  }

  function ssn(event) {
    console.log('ssn', event.target.value);
    setssn(event.target.value);
  }

  function locationNew(event) {
    console.log('locationNew', event);
    setlocationNew(event);
  }

  function dl(event) {
    console.log('dl', event.target.value);
    setdl(event.target.value);
  }
  function pp(event) {
    console.log('pp', event.target.value);
    setPp(event.target.value);
  }
  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  function onSearchState(val) {
    console.log('search:', val);
  }

  const updateValues = () => {
    setFirstName(data['first_name']);
    setmiddleName(data['middle_name']);
    setlastName(data['last_name']);
    setsex(data['Gender']);
    setstatus(data['emp_status']);
    setjobTitle(data['Job_Title']);
    setaltMobile(data['Alternate_Phones']);
    setemail(data['email']);
    setworkEmail(data['Email_work']);
    setstreet(data['street']);
    setstreet2(data['street2']);
    setstateNewID(data['state_id'][0]);
    setcityNew(data['city']);
    setzip(data['zip']);
    setssn(data['newSSN']);
    setlocationNew(data['empLocId']);
    setdl(data['DL']);
    setPp(data['passport']);
    // setCountr(data['country_id']);
    setCountr(data['country_id'][0]);
    //setCountrName()
    setDate(data['dob']);
    setSelectedCompany(data['company']);
    setAge(data['age']);
    let dobString = data['dob'].length > 0 ? moment(data['dob']) : '';
    onChangeDate(moment(dobString), dobString);
    setMobilePhone(formatValue(data['Mobile']));
    setDtProgram(data['program']);
    setDtMode(data['mode']);
  };
  function updateSaveEmployee() {
    setEnableUpdate(true);

    if (firstNameNew && lastNameNew && dateNew && selectedCompany && locationNewA) {
      const params = {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        id: data['id'],
        suffixNew: suffixNew,
        firstNameNew: firstNameNew,
        middleNameNew: middleNameNew,
        lastNameNew: lastNameNew,
        sexNew: sexNew,
        statusNew: statusNew,
        dotNew: dotNew,
        jobTitleNew: jobTitleNew,
        mobileNew: mobileNew,
        emailNew: emailNew,
        workEmailNew: workEmailNew,
        streetNew: streetNew,
        street2New: street2New,
        stateNewA: [parseInt(stateNewId), stateNewA],
        cityNewA: cityNewA,
        zipNew: zipNew,
        ssnNew: ssnNew,
        locationNewA: parseInt(locationNewA),
        dlNew: dlNew,
        ppNew: ppNew,
        countrNew: [parseInt(countrNew), countName],
        comNew: parseInt(selectedCompany),
        //comNew: parseInt(CompDataNew[0].id),
        dateNew: moment(dateNew).format('YYYY-MM-DD'),
        mobilePhone: mobilePhone,
        age: age,
        dtProgram: dtProgram,
        mode: dtProgram != 'NON - DOT' ? dtMode : '',
        dot_mode: dtProgram == 'NON - DOT' ? dtMode : '',
        dtMode: dtProgram != 'NON - DOT' ? dtMode : ''
        //dtMode: dtMode
      };
      setIsLoading(true);
      axios
        .post(setting.serverUrl + '/api/updatesaveEmployee', {
          params: params
        })
        .then((response) => {
          if (response && response.status == 200 && response.data == 'Updated Employee!') {
            notification['success']({
              message: 'Updated Successfully'
            });
            if (response && response.status == 200 && response.data.error == 'Technical Issue') {
              notification['success']({
                message: 'Technical Issue. Please try again later!'
              });
            }
            setEnableUpdate(false);
            if (setData == 'undefined' || setData === undefined) {
              history.push({ pathname: `/vertical/employeeList` });
              setRes(response.data);
            } else {
              // setRes(response.data);
              axios
                .post(setting.serverUrl + '/api/readUpdatedEmnployee', {
                  params: {
                    email: localStorage.getItem('myData'),
                    user: localStorage.getItem('myData'),
                    pass: localStorage.getItem('myPass'),
                    id: data['id']
                  }
                })
                .then((response) => {
                  console.log('Respone from readUpdatedEmnployee ', response.data);
                  setIsLoading(false);

                  if (response && response.status == 200) {
                    sendDataToParentNN('Added', response.data[0]);
                  }
                });
            }
          }
        });
    } else {
      notification['success']({
        message: 'Please fill required fields.'
      });
    }
  }
  const formatValue = (value) => {
    return value ? value : '';
  };

  const validatePhoneNumber = (rule, value, callback) => {
    const phoneNumberRegex = /^[\d()+-]*$/;

    if (!value) {
      // If the value is empty, consider it as a valid input (you can change this behavior)
      callback();
    } else if (!phoneNumberRegex.test(value)) {
      callback('Invalid phone number.');
    } else if (value.length !== 10) {
      callback('Phone number must be 10 digits long.');
    } else {
      callback(); // Validation passed
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <div>
        {setData == 'undefined' || setData === undefined ? (
          <Button
            size='small'
            style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        ) : (
          <></>
        )}
      </div>
      {setData == 'undefined' || setData === undefined ? (
        <h5 style={{ textAlign: 'left', color: '#1C3A6A' }}>Update Employee</h5>
      ) : (
        <></>
      )}
      <h3 style={{ marginLeft: 25, color: '#1C3A6A' }}>
        Employee Information &nbsp;&nbsp;
        <b style={{ color: 'grey', fontSize: '13px' }}>*Denotes Required Fields</b>
      </h3>

      {bool ? (
        <div>
          <Form form={form} layout='vertical' onFinish={() => updateSaveEmployee()}>
            <div style={{ marginLeft: 25 }}>
              <div className='row'>
                <div className='col-md-6'>
                  <FormItem
                    name='company'
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <Input
                      style={{ backgroundColor: 'white' }}
                      disabled={true}
                      initialValues={data['company'][1]}
                      placeholder='Company Name'
                    />
                  </FormItem>
                </div>

                <div className='col-md-6'>
                  <FormItem name='empLocation'>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                      defaultValue={data['emp_location'][1].split(',')[1]}
                      placeholder='Employee Location*'
                      onChange={(event) => locationNew(event)}
                    >
                      {Res.length > 0 &&
                        Res.map((loc, index) => <Option value={loc.id}>{loc.name}</Option>)}
                    </Select>
                  </FormItem>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-5'>
                  <FormItem
                    defaultValue={formatValue(data['first_name'])}
                    name='firstName'
                    //value={firstNameNew}
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <Input
                      style={{ backgroundColor: 'white' }}
                      onChange={(event) => firstName(event)}
                      placeholder='First Name* data'
                    />
                  </FormItem>
                </div>
                <div className='col-md-2'>
                  <FormItem name='middleName'>
                    <Input
                      style={{ backgroundColor: 'white' }}
                      placeholder='Middle Name'
                      defaultValue={formatValue(data['middle_name'])}
                      onChange={(event) => middleName(event)}
                    />
                  </FormItem>
                </div>
                <div className='col-md-5'>
                  <FormItem
                    name='lastName'
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <Input
                      style={{ backgroundColor: 'white' }}
                      placeholder='Last Name*'
                      defaultValue={formatValue(data['last_name'])}
                      //value={lastNameNew}
                      onChange={(event) => lastName(event)}
                    />
                  </FormItem>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <FormItem
                    name='dob'
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <DatePicker
                      style={{ width: '100%', padding: 10, borderRadius: 20 }}
                      placeholder='Date of Birth*'
                      onChange={(date, dateString) => onChangeDate(date, dateString)}
                    />
                  </FormItem>
                </div>
                <div className='col-md-4'>
                  <FormItem>
                    <Input
                      style={{ backgroundColor: 'white' }}
                      placeholder={age == 0 ? 'Age' : ''}
                      readOnly
                      disabled
                      prefix={age > 0 ? <h6> Age : {age} years</h6> : ''}
                    />
                  </FormItem>
                </div>
                <div className='col-md-4'>
                  <FormItem
                    name='Sex'
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                      placeholder='Sex*'
                      onChange={(event) => sex(event)}
                    >
                      <Option value='Male'>Male</Option>
                      <Option value='Female'>Female</Option>
                    </Select>
                  </FormItem>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <FormItem>
                    <MaskedInput
                      mask='111-11-1111'
                      defaultValue={ssnNew}
                      placeholder='SSN _ _ _/_ _/_ _ _ _'
                      onChange={(event) => ssn(event)}
                      style={{ backgroundColor: 'white' }}
                    />
                  </FormItem>
                </div>
                <div className='col-md-4'>
                  <FormItem>
                    <Input
                      defaultValue={formatValue(data['DL'])}
                      style={{ backgroundColor: 'white' }}
                      placeholder='Drivers License #'
                      onChange={(event) => dl(event)}
                    />
                  </FormItem>
                </div>
                <div className='col-md-4'>
                  <FormItem>
                    <Input
                      defaultValue={formatValue(data['passport'])}
                      style={{ backgroundColor: 'white' }}
                      placeholder='Passport #'
                      onChange={(event) => pp(event)}
                    />
                  </FormItem>
                </div>
              </div>
            </div>
            <br />
            <Collapse accordion>
              <Panel
                style={{ background: '#2F7197', borderRadius: '10px 10px 0px 0px', color: 'white' }}
                className='ant-collapse-header'
                header={
                  <span style={{ color: 'white', fontSize: '140%' }}>
                    Employee Contact Information
                  </span>
                }
                key='1'
              >
                {
                  <div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <FormItem>
                          <Input
                            defaultValue={formatValue(data['street'])}
                            style={{ backgroundColor: 'white' }}
                            placeholder='Street Address 1'
                            onChange={(event) => street(event)}
                          />
                        </FormItem>
                      </div>

                      <div className='col-md-6'>
                        <FormItem>
                          <Input
                            defaultValue={formatValue(data['street2'])}
                            placeholder='Street Address 2'
                            style={{ backgroundColor: 'white' }}
                            onChange={(event) => street2(event)}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <FormItem>
                          <Input
                            defaultValue={formatValue(data['city'])}
                            onChange={(event) => cityNew(event)}
                            placeholder='City'
                            style={{ backgroundColor: 'white' }}
                          />
                        </FormItem>
                      </div>

                      <div className='col-md-4'>
                        <FormItem>
                          <Select
                            defaultValue={formatValue(
                              data['country_id'] ? data['country_id'][1] : ''
                            )}
                            showSearch
                            placeholder='Country'
                            optionFilterProp='children'
                            onChange={(event) => stateSelect(event)}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toString().toLowerCase()) >= 0
                            }
                          >
                            {country.length > 0 &&
                              country.map((count, index) => (
                                <Option value={JSON.stringify(count)}>{count.name}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>
                      <div className='col-md-2'>
                        <FormItem>
                          <Select
                            defaultValue={formatValue(data['state_id'] ? data['state_id'][1] : '')}
                            showSearch
                            placeholder='State'
                            optionFilterProp='children'
                            onChange={(event) => stateNew(event)}
                            onSearch={onSearchState}
                            filterOption={(input, option) =>
                              option.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {state.length > 0 &&
                              state.map((state, index) => (
                                <Option value={JSON.stringify(state)}>{state.name}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>
                      <div className='col-md-2'>
                        <FormItem>
                          <Input
                            defaultValue={formatValue(data['zip'])}
                            style={{ backgroundColor: 'white' }}
                            placeholder='Zip Code'
                            onChange={(event) => zip(event)}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <FormItem
                          name='mobilePhone'
                          label='Mobile Number'
                          rules={[
                            {
                              validator: validatePhoneNumber
                            }
                          ]}
                        >
                          <Input
                            value={mobilePhone}
                            defaultValue={formatValue(data['Mobile'])}
                            placeholder='Mobile Number'
                            maxLength={10}
                            //pattern='^\d+$'
                            style={{ backgroundColor: 'white' }}
                            onChange={(event) => mobile(event)}
                          />
                        </FormItem>
                      </div>

                      <div className='col-md-4'>
                        <FormItem>
                          <Input
                            defaultValue={formatValue(data['Alternate_Phones'])}
                            onChange={(event) => altMobile(event)}
                            placeholder='Alternate Phone Number'
                            maxLength={10}
                            pattern='[+-]?\d+(?:[.,]\d+)?'
                            style={{ backgroundColor: 'white' }}
                          />
                        </FormItem>
                      </div>
                      <div className='col-md-4'>
                        <FormItem name='Email'>
                          <Input
                            defaultValue={formatValue(data['email'])}
                            style={{ backgroundColor: 'white' }}
                            placeholder='Email'
                            onChange={(event) => email(event)}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <i style={{ color: 'grey' }}>
                      * By adding this Information, the employee will automatically be added to the
                      Employee Portal.
                    </i>
                  </div>
                }
              </Panel>

              <Panel
                style={{ background: '#EF6828', borderRadius: '0px 0px 10px 10px' }}
                header={
                  <span style={{ color: 'white', fontSize: '140%' }}>
                    Company Contact Information
                  </span>
                }
                key='2'
                extra={''}
              >
                {
                  <div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <FormItem>
                          <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                            defaultValue={data['program']}
                            placeholder='DOT Program'
                            onChange={(event) => setDtProgram('' + event)}
                          >
                            {Object.keys(dtProgramFields).length > 0 &&
                              Object.keys(dtProgramFields).map((state, index) => (
                                <Option value={state}>{state}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>

                      <div className='col-md-6'>
                        <FormItem>
                          <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                            defaultValue={formatValue(data['mode'])}
                            placeholder='DT Mode'
                            onChange={(event) => setDtMode('' + event)}
                          >
                            {dtProgramFields[dtProgram] &&
                              dtProgramFields[dtProgram].length > 0 &&
                              dtProgramFields[dtProgram].map((state, index) => (
                                <Option value={state}>{state}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <FormItem>
                          <Input
                            defaultValue={formatValue(data['Job_Title'])}
                            style={{ backgroundColor: 'white' }}
                            placeholder='Job Title'
                            onChange={(event) => jobTitle(event)}
                          />
                        </FormItem>
                      </div>

                      <div className='col-md-6'>
                        <FormItem>
                          <Input
                            defaultValue={formatValue(data['Email_work'])}
                            onChange={(event) => workEmail(event)}
                            style={{ backgroundColor: 'white' }}
                            placeholder='Work Email Address'
                          />
                        </FormItem>
                      </div>
                    </div>
                  </div>
                }
                <div className='row'>
                  <div className='col-md-6'>
                    <FormItem>
                      <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                        defaultValue={data['emp_status']}
                        placeholder='Status'
                        onChange={(event) => status(event)}
                      >
                        <Option value='Pre Employment'>Pre Employment</Option>
                        <Option value='Active'>Active</Option>
                        <Option value='Teminated'>Terminated</Option>
                        <Option value='leave of absence'>Leave Of Absence</Option>
                        <Option value='deceased'>Deceased</Option>
                        <Option value='Suspended'>Suspended</Option>
                      </Select>
                    </FormItem>
                  </div>

                  <div className='col-md-6'></div>
                </div>
              </Panel>
            </Collapse>

            <div className='row' style={{ marginTop: 50, marginLeft:85 }}>
              <div className='col-md-4 col-sm-4'></div>
              <div className='col-md-5 col-sm-5'>
                <Button
                  type='submit'
                  size='small'
                  style={{ width: '40%', backgroundColor: '#1b3969', fontSize:14 }}
                  htmlType={'submit'}
                  disabled={enableUpdate}
                >
                  Update
                </Button>
                {isLoading && (
                  <Space size='middle' style={{ marginTop: 10, marginLeft: 85 }}>
                    <Spin size='large' />
                  </Space>
                )}
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 10, marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EditAddEmployee;
