import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';

import BaseLayout from '../base/BaseLayout';
import { Spin, Space, Row, Col, Modal, Typography, Table } from 'antd';
import Logo from '../components/logo/Logo';
import Navbar from '../components/navbar/Navbar';
import LogoSvg from './../../assets/img/company.png';
import Menu from '../components/menu/Menu';
import Search from '../components/search/Search';
import NavLoader from '../components/navbar/NavLoader';
import AddPatient from '../components/patients/AddPatient';

import Actions from '../components/actions/Actions';
import { toggleSidebar } from '../../redux/settings/actions';
import lineBackground from '../../assets/img/bg.png';
import { useSearchData } from '../../hooks/useSearchData';
import { useDispatch, useSelector } from 'react-redux';

import { IAppState } from '../../interfaces/app-state';
import setting from '../../config/setting';

import './Vertical.scss';
import { Console } from 'console';

type Props = {
  children: any;
};

const VerticalLayout = ({ children }: Props) => {
  const dispatch = useDispatch();

  const settings = useSelector((state: IAppState) => state.settings);
  const pageData = useSelector((state: IAppState) => state.pageData);

  const searchData = useSearchData();

  const onSidebarToggle = () => dispatch(toggleSidebar());

  const [menuData, setMenuData] = useState([]);
  const [loginCustomerData, setloginCustomerData] = useState({ id: '', name: '' });
  const [loginCustomerDataTrue, setloginCustomerDataTrue] = useState(false);
  const [logoVal, setLogo] = useState('');

  useEffect(() => {
    // console.log("vertical tsx",JSON.parse(localStorage.getItem('customerData')));
    // setTimeout(() =>{
    getCompanyLogo();
    getLocalStorageData();
    // },2000)
    //setTimeout(() =>{
    // let loginCustomerData = JSON.parse(localStorage.getItem('customerData'));
    // },2000)

    async function fetchMenuData() {
      const result = await axios('/data/menu.json');
      const filterData = result.data.filter((element) => isActiveMenu(element.title));

      setMenuData(filterData);
      //setMenuData(result.data);
    }
    fetchMenuData().catch((err) => console.log('Server Error', err));
  },[]);

  function getCompanyLogo() {
    // setClinicList([])
    // setClinicListTrue(false)

    console.log(
      'getCompanyLogo :: ',
      JSON.parse(localStorage.getItem('customerData')).id,
      localStorage.getItem('myData'),
      localStorage.getItem('myPass'),
    );
    //console.log("setting.serverUrl",setting.serverUrl)
    axios
      .post(setting.serverUrl + '/api/getCompLogo', {
        // axios.get('http://localhost:3001/api/getClinics', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId:JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          if (response.data) {
            console.log("response.data.rows[0].url ", response.data)
            setLogo(response.data);
          }
          // setClinicList(response.data.length)
          // setChange(!change)
        }
      });
  }

  const isActiveMenu = (menuTitle) => {
    console.log('menu title ', menuTitle);
    let customerData = JSON.parse(localStorage.getItem('customerData'));

    if (menuTitle === 'Covid Testing') {
      return customerData['covid_test'] == true;
    } else if (menuTitle === 'Covid Vaccinations') {
      return customerData['covid_vac'] == true;
    } else if (menuTitle === 'Covid Report') {
      return customerData['covid_vac'] == true;
    } else if (menuTitle === 'Covid Exemption') {
      return customerData['covid_vac'] == true;
    } else if (menuTitle === 'Covid Vulnerable') {
      return customerData['covid_vac'] == true;
    } else if (menuTitle === 'Drug & Alcohol') {
      return customerData['drug_test'] == true;
    } else if (menuTitle === 'EAP Program') {
      return customerData['eap_program'] == true;
    } else if (menuTitle === 'Occ.Health') {
      return customerData['eap_program'] == true;
    } else if (menuTitle === 'Pool Config') {
      return customerData['random_pool'] == true;
    } else if (menuTitle === 'Schedule Event') {
      return customerData['drug_test'] == true;
    }
    return true;
  };
//  const customerData = JSON.parse(localStorage.getItem('customerData'));
  const getLocalStorageData = async () => {
    let customerData = await localStorage.getItem('customerData');
    console.log('vertical tsx', JSON.parse(customerData));
    // setTimeout(() =>{
    setloginCustomerData(JSON.parse(customerData));
    setloginCustomerDataTrue(!loginCustomerDataTrue);
    // },2000)
  };
  console.log("login customer dtaa", loginCustomerData)
  // function companyLogo() {
  //   const companyId = localStorage.getItem('customerId');
  //   const logos = JSON.parse(localStorage.getItem('logos'));
  //   if (logos) {
  //     return logos[companyId];
  //   } else {
  //     return null;
  //   }
  // }
  const nav = (
    <Navbar
    
      //boxed={settings.boxed}
      color={settings.topbarColor}
      background={'#425A81'}
      orientation='horizontal'
     
    >
      <div style={{ backgroundColor: 'white', padding: "var(--particular-padd)", marginLeft:"-5px", float:"left",width:"440px",height:"60px", boxSizing:"border-box"}}>
        <Link to='/vertical/default-dashboard'>
          {logoVal ? (
            <>
              <img
                src={logoVal}
                //style={{ width: '4vh', }}
                style={{ height: '16px'}}
                alt='Company Logo'
              />
              <span style={{ color: 'black', marginLeft: 10, fontWeight: 900, fontSize: 'var(--comp-name-font)' }}>
                {/* {JSON.parse(localStorage.getItem('customerData')).name}  */}
                {loginCustomerData && loginCustomerData.name ? loginCustomerData.name : ""}
              </span>
            </>
          ) : (
            localStorage.getItem('customerData') ?
            <span style={{ color: 'black', fontWeight: 900, fontSize: '16px' }}>
              {/* {JSON.parse(localStorage.getItem('customerData')).name} */}
              {loginCustomerData && loginCustomerData.name ? loginCustomerData.name : ""}
            </span>
            : null
          
          )}
        </Link>
      </div>
            <div className='triangle'></div>
      {/* <img src={metlifeIcon} /> */}
      {/* </Col>
          </Row> */}
      <button className='no-style navbar-toggle d-lg-none' onClick={onSidebarToggle}>
        <span style={{background:"#fff"}}></span>
        <span style={{background:"#fff"}}></span>
        <span style={{background:"#fff"}}></span>
      </button>

      {/* <Search layout='vertical' data={searchData} /> */}

      <Actions />

      <NavLoader loaded={pageData.loaded} type={'top-bar'} />
    </Navbar>
  );

  const sideNav = (
    <Navbar
      onClickOutside={onSidebarToggle}
      opened={settings.sidebarOpened}
      color={settings.sidebarColor}
      background={settings.sidebarBg}
      orientation='vertical'
    >
      <Link to='/vertical/default-dashboard'>
        <Logo src={LogoSvg} />
      </Link>
      <Menu
        onCloseSidebar={onSidebarToggle}
        opened={settings.sidebarOpened}
        orientation='vertical'
        data={menuData}
      />

      {/* <AddPatient /> */}

      <Menu className='assistant-menu' orientation='vertical'>
        {/* <NavLink className='link' to='/vertical/settings' activeClassName='active' replace>
          <span className='link-icon icofont icofont-ui-settings' />

          <span className='link-text'>Settings</span>
        </NavLink> */}

        {/* <NavLink className='link' to='/vertical/default-dashboard' activeClassName='active' replace>
          <span className='link-icon icofont icofont-question-square' />

          <span className='link-text'>FAQ & Support</span>
        </NavLink> */}

        {}
      </Menu>

      <NavLoader loaded={pageData.loaded} type={'nav-bar'} />
    </Navbar>
  );

  return (
    <>
      <BaseLayout orientation='vertical' nav={nav} sideNav={sideNav}>
       {/* <div
          className='col-12 col-md-12 col-xl-12'
          style={{
            backgroundColor: '#425A81',
            position: 'fixed',
            top: '60px',
            zIndex: 1,
            color: '#fff',
            fontSize: '1.0em',
            right: '0px',
            textAlign: 'right',
            fontWeight: 700,
            padding: 5
          }}
        >
           Client Name / ID : &nbsp; 
          {customerData && customerData.name} / {customerData && customerData.id} 
        </div>*/}
        <div className='col-12 col-md-12 col-xl-12' style={{ marginTop: 50 }}>
          {children}
        </div>
      </BaseLayout>
    </>
  );
};

export default VerticalLayout;
