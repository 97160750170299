import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import setting from '../../../config/setting';
import {
  Spin,
  Space,
  Row,
  Col,
  Input,
  Select,
  Modal,
  Button,
  notification,
  DatePicker
} from 'antd';
import moment from 'moment';
import _ from 'underscore';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import '../../covidVaccination/covidVaccination.css';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FolderOpenTwoTone } from '@ant-design/icons';

const config = require('../../keyFront');
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

const { RangePicker } = DatePicker;

let flexes = '';
let showGrid = '';
let filter = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'covid_vaccination.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const CovidVaccination = ({ empId }) => {
  const [companyName, setCompanyName] = useState('');
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [NoRecFound, setNoRecFound] = useState(false);
  const [tableType, setTableType] = useState('all');
  const [dateRange, setDateRange] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [locationRecords, setLocationRecords] = useState({});
  const [emailRecords, setEmailrecords] = useState({});
  const [latestEntryDates, setLatestEntryDates] = useState({});
  const [employeeNameRecords, setEmployeeNameRecords] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchColumn, setSearchColumn] = useState('');
  const [CovidVaccinationData, setCovidVaccinationData] = useState([]);
  const [CovidVaccinationDataCounts, setCovidVaccinationDataCounts] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [approvedVacc, setApprovedVacc] = useState([]);
  const [declinedVacc, setDeclinedVacc] = useState([]);
  const [pendingVacc, setPendingVacc] = useState([]);
  const [filterFlag, setFilterFlag] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [vaccineTable, setTable] = useState([]);
  const [vaccineTableBKP, setTableBKP] = useState([]);
  const [change, setChange] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [docPermission, setDocPermission] = useState(false);
  const [view, setView] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [pageApply, setPageApply] = useState(false);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [covidVaccFinalResult, setCovidVaccFinalResult] = useState([]);
  const [covidVaccFinalBkup, setCovidVaccFinalBkup] = useState([]);
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();

  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.CovidVaccinationDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };

  const getVaccination = () => {
    setNoRecFound(false);
    setChange(true);
    setIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/getCovidVaccinationCustomerPortalByEmpId', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id,
          empId: empId
        }
      })
      .then((response) => {
        console.log(response, 'covidVaccinations');
        setIsLoading(false);
        if (response.data == 'Blocked') {
          console.log('Covid Vaccination data blocked: ', response);
        } else {
          console.log('Covid Vaccination data: ', response);
          setChange(false);
          if (response && response.data && response.data.row && response.data.rows.length === 0) {
            setNoRecFound(true);
          }
          if (response && response.data) {
            if (response.data.error) {
              alert('Some technical error occurred. Please try again in a bit.');
            }
            if (response.status === 200) {
              setCovidVaccinationData(response.data.rows);
              setHasPermission(true);
            } else if (response.status === 201) {
              setNoRecFound(true);
              setNoRecordMessage('Access permission not allowed.');
              setErrMsg('Access permission not allowed.');
            } else {
              notification.error({
                message: 'Failed to retrieve data at the moment. Please try again in a bit.'
              });
            }
          }
        }
      });
  };

  const updateTableFilters = (records) => {
    let locationNames = {};
    let employeeNames = {};
    let emailNames = {};
    let entryDates = {};
    records.forEach((element) => {
      const name = element.name; // element.first_name + ' ' + element.last_name;
      if (!locationNames[element.location]) {
        locationNames[element.location] = element.location;
      }
      if (!employeeNames[name]) {
        employeeNames[name] = name;
      }
      if (!emailNames[element.email]) {
        emailNames[element.email] = element.email;
      }
      if (!entryDates[element.create_date]) {
        entryDates[moment(element.create_date).format('MMMM DD YYYY')] = moment(
          element.create_date
        ).format('MMMM DD YYYY');
      }
    });
    setLatestEntryDates(entryDates);
    setEmailrecords(emailNames);
    setLocationRecords(locationNames);
    setEmployeeNameRecords(employeeNames);
  };

  const getVaccinationCounts = () => {
    setChange(true);
    axios
      .post(setting.serverUrl + '/api/getCovidVaccinationCounts', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        // console.log('Covid Vaccination counts data: ', response);
        setChange(false);
        if (response && response.data) {
          if (response.data.error) {
            alert('Some technical error occurred. Please try again in a bit.');
          }
          if (response.status === 200) {
            setCovidVaccinationDataCounts(response.data);
          } else if (response.status === 201) {
            setNoRecFound(true);
            setNoRecordMessage('Access permission not allowed.');
          } else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
          }
        }
      });
  };

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const createTable = () => {
    // setChange(true);
    setTableType('all');
    let custId = localStorage.getItem('customerId');
    setCompanyName(' of ' + localStorage.getItem('displayName'));
    console.log('CovidVaccinationData', CovidVaccinationData);
    if (CovidVaccinationData?.length > 0) {
      let arr = [];
      let companyEmp = CovidVaccinationData.map((vac) => {
        vac.empname = vac.first_name + ' ' + vac.last_name;
        arr.push(vac);
      });
      let dataGroupByEmployee = _.groupBy(arr, 'empname');
      let tableData = [];
      let pendingData = [];
      let declinedData = [];
      console.log('dataGroupByEmployee', dataGroupByEmployee);
      Object.keys(dataGroupByEmployee).map((emp) => {
        let vaccs = dataGroupByEmployee[emp]; //vaccines of the employee

        let record = {
          empId: vaccs[0].employee,
          name: emp,
          location: vaccs[0].location_name,
          create_date: vaccs[0].create_date,
          email: vaccs[0].email,
          newDL: vaccs[0].DL != false ? vaccs[0].DL : '',
          newEmpEIN: vaccs[0].emp_ein != false ? vaccs[0].emp_ein : '',
          newEmpSSN:
            vaccs[0].emp_ssn != false && vaccs[0].emp_ssn
              ? '***-**-' + vaccs[0].emp_ssn?.substr(-4)
              : '',
          jobTitle: vaccs[0].Job_Title != false ? vaccs[0].Job_Title : '',
          departments: vaccs[0].department != false ? vaccs[0].department : '',
          program: vaccs[0].program != false ? vaccs[0].program : '',
          mode: vaccs[0].mode != false ? vaccs[0].mode : ''
        };

        let empVaccines = [];
        let csvRecord = [];
        vaccs.map((vac) => {
          let vaccine = {
            vacData: (
              <p>
                {' '}
                {vac.test_type} | <b>{vac.dose}</b> | {moment(vac.test_date).format('MMMM DD YYYY')}{' '}
                |{' '}
                {vac.testing_status === 'approved' ? (
                  <b style={{ color: 'green' }}>Approved</b>
                ) : vac.testing_status === 'pending' ? (
                  <b style={{ color: 'orange' }}>Pending</b>
                ) : vac.testing_status === 'declined' ? (
                  <b style={{ color: 'red' }}>Declined</b>
                ) : (
                  ''
                )}{' '}
              </p>
            ),
            attachment_url: vac.attachment_url,
            attachment_id: vac.attachment_id
          };

          empVaccines.push(vaccine);
          csvRecord.push(
            `${vac.test_type} | ${vac.dose} | ${moment(vac.test_date).format('MMMM DD YYYY')} | ${
              vac.testing_status
            }`
          );
          record['status'] = vac.testing_status;
          record['conducted_by'] = vac.conducted_by;
          if (vac.testing_status === 'pending') {
            record['vaccination'] = empVaccines;
            pendingData.push(record);
          } else if (vac.testing_status === 'declined') {
            record['vaccination'] = empVaccines;
            declinedData.push(record);
          }
        });

        record['vaccination'] = empVaccines;
        record['csvVaccincation'] = csvRecord;

        tableData.push(record);
      });
      // console.log('sdkjlf', tableData);
      tableData.sort((a,b)=>{
        const dateA = new Date(a.create_date);
        const dateB = new Date(b.create_date);
        return dateB - dateA;
      })
      var ress = tableData.map((val) => {
        return {
          ...val,
          newLatestEntryDate: val.create_date ? val.create_date :'',
          newLatestEntryDateForExcel: val.create_date ? moment(val.create_date).format('MM/DD/YYYY') :'',
          newEmpId: ''
        };
      });

      console.log(ress, 'varRess');
      const collectionView1 = new CollectionView(ress, {
        pageSize: ress.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(ress, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      collectionView2.filters.push((item) => {
        // setTableType('all')
        const startDate = dateRange && dateRange.length > 0 && moment(dateRange[0]).startOf('day');
        const endDate = dateRange && dateRange.length > 0 && moment(dateRange[1]).endOf('day');
        const itemTestDate =
          item.create_date != false && moment(item.create_date).format('MM-DD-YYYY');
        return (
          (!startDate || moment(itemTestDate, 'MM-DD-YYYY').isSameOrAfter(startDate)) &&
          (!endDate || moment(itemTestDate, 'MM-DD-YYYY').isSameOrBefore(endDate))
        );
      });
      if (tableType === 'approved') {
        collectionView2.filters.push((item) => {
          return item.status == 'approved';
        });
      } else if (tableType === 'declined') {
        collectionView2.filters.push((item) => {
          return item.status == 'declined';
        });
      } else if (tableType === 'pending') {
        collectionView2.filters.push((item) => {
          return item.status == 'pending';
        });
      } else if (tableType === 'all') {
        collectionView2.filters.push((item) => {
          return item;
        });
      }
      setView(collectionView2);
      const appCount = ress.filter((k) => {
        return k.testing_status == 'approved';
      });
      setApprovedVacc(appCount);
      const decCount = ress.filter((k) => {
        return k.testing_status == 'declined';
      });
      setDeclinedVacc(decCount);
      const penCount = ress.filter((k) => {
        return k.testing_status == 'pending';
      });
      setDeclinedVacc(penCount);

      //setWijmoFlexGrid(ress, 10);
      setCovidVaccFinalResult(ress);
      setCovidVaccFinalBkup(ress);
      setTable(ress);
      setTableBKP(tableData);
      updateTableFilters(tableData);
      const approvedV = tableData.filter((emp) => emp.status === 'approved');
      setApprovedVacc(approvedV);
      const pendingV = tableData.filter((emp) => emp.status === 'pending');
      setPendingVacc(pendingV);
      const declinedV = tableData.filter((emp) => emp.status === 'declined');
      console.log('sdkjlf=======', declinedV);

      setDeclinedVacc(declinedV);
    } else {
      setNoRecFound(false);
      //      setWijmoFlexGrid([], 10);
      setNoRecordMessage('No Record Found.');
    }
  };

  console.log(viewData, 'viewData');

  function download(e, empId) {
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificateForEmployee', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          empId: empId
        }
      })
      .then((response) => {
        setChange(false);
        setIsLoaderActive(false);
        if (Array.isArray(response.data)) {
          setIsModalVisible(true);
          setImageUrls(response.data);
        } else {
          window.open(response.data);
        }
      });
  }
  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };
  useEffect(() => {
    // getEmployees();
    getVaccination();
    getVaccinationCounts();
    getDocPermission();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, []);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }

  useEffect(() => {
    createTable();
  }, [CovidVaccinationData, totalEmployees, pageSize, dateRange]);

  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);

      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showLatestEntryDate = (item) => {
    return (
        <p>{item.item.create_date ? moment(item.item.create_date).format('MM/DD/YYYY') : ''}</p>
    );
  };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showVaccination = (item) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
        {item.item.vaccination.map((v, i) => (
          <p key={i} style={{ paddingRight: '25px' }}>
            {v.vacData}
          </p>
        ))}
      </div>
    );
  };

  // const showDoc = (item) => {
  //   return (
  //     <span className='nowrap'>
  //       {docPermission && item.item.empId ? (
  //         <button
  //           className='doclink'
  //           onClick={(e) => download(e, item.item.empId)}
  //           style={{
  //             border: 'none',
  //             textAlign: 'center',
  //             borderRadius: 5,
  //             backgroundColor: '#abc',
  //             fontweight: 'bold'
  //           }}
  //         >
  //           View Docs
  //         </button>
  //       ) : (
  //         item.item.empId && <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
  //       )}
  //     </span>
  //   );
  // };

  const showDoc = (item) => {
    return (
      <span className='nowrap'>
        {docPermission && item.item.empId ? (
          // <p style={{ color: 'blue' }}>View Docs
            <FolderOpenTwoTone
              twoToneColor='#cc7a00'
              style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
              onClick={(e) => download(e, item.item.empId)}
            />
          // </p>
        ) : (
          item.item.empId && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
        )}
      </span>
    );
  };

  const filterInitialized = (f) => {
    filter = f;
  };

  const showJob = (item) => {
    return <p>{item.item.jobTitle ? item.item.jobTitle : ''}</p>;
  };

  return hasPermission ? (
    <div style={{ padding: 20, marginTop: 20 }}>
      {!NoRecFound ? (
        vaccineTable.length > 0 || filterFlag ? (
          <>
            <div>
              <Row gutter={[32, 32]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                  <FlexGridSearch
                    ref={theSearch}
                    placeholder='Search'
                    cssMatch=''
                    style={{ width: '70%', height: '40px' }}
                  />
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                  <div ref={fixedContainerRef}>
                    <Select
                      style={{ width: 170 }}
                      placeholder={`Items per page : ${pageSize}`}
                      onChange={paggination}
                      options={[
                        { label: 'Items per page : 10', value: 10 },
                        { label: 'Items per page : 20', value: 20 },
                        { label: 'Items per page : 30', value: 50 }
                      ]}
                      getPopupContainer={() => fixedContainerRef.current}
                    />
                  </div>
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                  <Button
                    type='primary'
                    size='small'
                    style={{ backgroundColor: '#3d586a', float: 'right' }}
                    disabled={isExcelPreparing}
                    onClick={exportToExcel}
                  >
                    {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                  </Button>
                </Col>
              </Row>

              {/* to export entire data of table */}
              <FlexGrid
                ref={theGrid}
                style={{ display: 'none' }}
                className='dummyWijmoData'
                itemsSource={viewData}
                initialized={gridInitializedData}
                formatItem={formatItem}
                isReadOnly={true}
              >
                <FlexGridColumn binding='location' header='Location' minWidth={150} width='*' />
                <FlexGridColumn
                  binding='newLatestEntryDateForExcel'
                  header='Latest Entry Date'
                  // width='12*'
                  minWidth={140}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showLatestEntryDate} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='csvVaccincation'
                  header='Vaccinations/Dose/Docs/Status'
                  // width='11*'
                  // minWidth={400}
                  // width='*'
                  width={400}
                />

                <FlexGridColumn
                  binding='jobTitle'
                  header='Job Title'
                  // width='10*'
                  // minWidth={160}
                  // width='*'
                  width={225}
                />
                {/* <FlexGridCellTemplate cellType='Cell' template={showJob} /> */}
                {/* </FlexGridColumn> */}

                <FlexGridColumn
                  binding='program'
                  header='Dot Mode'
                  // width='10*'
                  minWidth={160}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                </FlexGridColumn>
              </FlexGrid>

              <FlexGrid
                autoGenerateColumns={false}
                ref={theGrid}
                id='theGridTallRows'
                className='cliniclisttable'
                alternatingRowStep={1}
                itemsSource={view}
                initialized={gridInitialized}
                formatItem={formatItem}
                itemsPerPage={10}
                isReadOnly={true}
                scrollMode='Virtual' // Add this line for smooth scrolling
                // loadedRows={(s) => s.autoSizeColumns()}
                // rowEditEnded={(s) => s.autoSizeColumns()}
                // cellEditEnded={(s, e) => s.autoSizeColumn(e.col)}
                style={{ minwidth: '100%' }}
              >
                <div style={{ display: 'none' }}>
                  <wjInput.ListBox
                    className='column-picker'
                    itemsSource={listboxColumns}
                    checkedMemberPath='visible'
                    displayMemberPath='header'
                    lostFocus={hidePicker}
                    initialized={initializedListBox}
                  ></wjInput.ListBox>
                </div>
                <FlexGridColumn binding='location' header='Location' width={260} />
                <FlexGridColumn binding='newLatestEntryDate' header='Latest Entry Date' width={150}>
                  <FlexGridCellTemplate cellType='Cell' template={showLatestEntryDate} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='vaccination'
                  header='Vaccinations/Dose/Docs/Status'
                  // width='11*'
                  // minWidth={500}
                  // width='*'
                  width={1600}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showVaccination} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='JobTitle'
                  header='Job Title'
                  // width='10*'
                  // minWidth={160}
                  // width='*'
                  width={225}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showJob} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='program'
                  header='Dot Mode'
                  // width='10*'
                  // minWidth={160}
                  // width='*'
                  width={110}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding={docPermission ? 'empId' : 'newEmpId'}
                  header='View Docs'
                  // minWidth={150}
                  // width='*'
                  width={100}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showDoc} />
                </FlexGridColumn>
                <FlexGridFilter
                  filterColumns={['location', 'newLatestEntryDate', 'program']}
                  initialized={filterInitialized}
                />
              </FlexGrid>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div
                  style={{
                    backgroundColor: '#425a81',
                    color: '#fff',
                    display: 'inline',
                    borderRadius: '5px',
                    height: '30px'
                  }}
                >
                  <CollectionViewNavigator
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                    byPage={true}
                    cv={view}
                  />
                </div>
                &nbsp;&nbsp;&nbsp;
                <p
                  style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                  ref={filterRefCounter}
                  id='filterCounter'
                >
                  {Globalize.format(filterRefCounter.current, 'n0')} 
                </p>
                &nbsp;
                <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                  {' '}
                  out of {covidVaccFinalResult.length}
                </p>
              </div>
            </div>
          </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50,marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      <Modal
        width={1000}
        title='COVID Vaccination Document'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls && imageUrls.length > 0 ? (
          imageUrls.map((img, i) => {
            console.log('img :: ', img);
            return (
              <p key={i}>
                {img.fileType &&
                (img.fileType == 'png' ||
                  img.fileType == 'PNG' ||
                  img.fileType == 'jpg' ||
                  img.fileType == 'JPG' ||
                  img.fileType == 'jpeg' ||
                  img.fileType == 'JPEG') ? (
                  <img src={img.url} />
                ) : (
                  <embed src={img.url} width='900px' height='900px' />
                )}
              </p>
            );
          })
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )}
      </Modal>
      <DarkBackground disappear={isLoaderActive}>
        <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
      </DarkBackground>
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className="icofont-exclamation-tringle" style={{color:'red',fontSize:20}}></i>&nbsp;&nbsp; */}
        {errMsg}
      </p>
    </div>
  );
};

export default CovidVaccination;
