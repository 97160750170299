// import React, { useState } from 'react';
import React, { Component } from 'react';
import setting from '../../../config/setting'
import moment from 'moment';
import axios from "axios";
import { Modal } from 'antd';
import _ from 'underscore';
import className from '../../../utils/class-names';
import { Spin, Space, Row, Col, Divider } from 'antd';

import Contact from '../../../layout/components/doctor/Contact';
import PageAction from '../../../layout/components/page-action/PageAction';
import DoctorForm from './DoctorForm';

import { useFetchPageData, usePageData } from '../../../hooks/usePage';

import { IUser } from '../../../interfaces/user';
import { IPageData } from '../../../interfaces/page';

class DoctorsPage extends Component {

  constructor(props) {
    super(props);

    this.state = {

      list: [],
      allData: [],
      allChilds: [],
      //change:false,


    }

  };

  componentDidMount() {

    this.getCustomerData();

  }

  getCustomerData() {
    this.setState({ change: false });
    console.log("getCustomerData :: ")
    axios.post(setting.serverUrl + '/api/CustomerData', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from getData ", response.data)


      if (response && response.status == 200) {
        this.setState({ allData: response.data, change: true });

      }
    })
  }

  getCustomerChilds(item) {
    console.log("getCustomerChilds :: ", item)

    if (item.child_ids.length > 0) {

      this.setState({ allChilds: item.child_ids, change: true })

    }

  }

  getClass = (index, length) =>
    className({
      'mb-0': index === length - 1,
      'mb-md-0': index === length - 2 || index === length - 3
    });
  render() {
    console.log("localStorage.getItem('myData');", localStorage.getItem('myData'));
    console.log("this.state", this.state);
    return (

      <div>
        <h3 style={{ padding: 10, textAlign: 'left' }}>Company Information</h3>
        {this.state.change ?
          <div className='row'>



            {this.state.allData.map((customer, index) => {

              if (customer.child_ids.length > 0 || customer.is_company == true) {
                return (

                  <div key={index} className='col-sm-12 col-md-6 col-lg-4'>

                    <Contact className={this.getClass(index, this.state.allData.length)} {...customer} />

                  </div>

                )

              }

            })}


          </div>
          :
          <Row>
            <Col span={10}>
            </Col>
            <Col span={8}>
              <Space size="middle" style={{ marginTop: 10 }}>

                <Spin size="large" />
              </Space>
            </Col>
          </Row>
        }
      </div>
    );
  }
};

export default DoctorsPage;
