import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  Space,
  Row,
  Col,
  Card,
  Modal,
  notification,
  DatePicker,
  Tooltip,
  Select,
  message,
  Button
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting';
import { history } from '../../redux/store';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { filter } from 'jszip';
import { FolderOpenTwoTone } from '@ant-design/icons/lib';

const config = require('../keyFront');
const { RangePicker } = DatePicker;
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'background_screenings.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const Background_screenings = () => {
  const [backgroundScreeningData, setBackgroundScreeningData] = useState([]);
  const [change, setChange] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found!');
  const [imageUrls, setImageUrls] = useState('');
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [EventBGS, setEventBGS] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [docPermission, setDocPermission] = useState(false);
  const [completedCount, setCompletedCount] = useState(0);
  const [progressCount, setProgressCount] = useState(0);
  const [scheduledCount, setScheduledCount] = useState(0);
  const [view, setView] = useState();
  const [tableType, setTableType] = useState('all');
  const [viewData, setViewData] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [searchDateRange, setSearchDateRange] = useState('');
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const theGrid = useRef();
  const theSearch = useRef();
  const gridRef = useRef(null);
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();

  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.BackgroundScreeningDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };
  const getEmployeeBackgroundScreening = () => {
    setNoRecordFound(false);
    setChange(true);
    setIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/getBackgroundScreenings', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          company: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        console.log(response, 'backgroundResponse');
        setIsLoading(false);
        if (response.status === 202) {
          setBackgroundScreeningData([]);
          setNoRecordFound(false);
          setHasPermission(true);
          setNoRecordMessage('No record found!');
        } else if (response.status === 200 && response.data) {
          let result = response.data;
          result.sort((a,b)=>{
            const dateA = new Date(a.create_date);
            const dateB = new Date(b.create_date);
            return dateB - dateA;
          })
          let responseResult = [];
          if (result.length > 0) {
            responseResult = result.map((k) => {
              return {
                ...k,
                newEmpName:
                  k.first_name != '' || k.last_name != '' ? k.first_name + ' ' + k.last_name : '',
                newEmail: k.email !== false ? k.email : '',
                //newLocation: k.display_name != false ? k.display_name : '',
                newLocation:
                  k.display_name &&
                  k.display_name.length > 0 &&
                  !k.display_name.includes('|') &&
                  !k.display_name.includes(',')
                    ? k.display_name
                    : k.display_name?.split('|')[1] || k.display_name?.split(',')[1],

                newCreateDate: k.create_date ? k.create_date : '',
                newCreateDateforExcel: k.create_date ? moment(k.create_date).format('MM/DD/YYYY') : '',
                newPkg: k.package_name != false ? k.package_name : '',
                newStatus: k.status != false ? k.status : '',
                attachment_id: k.attachment_id != false ? k.attachment_id : '',
                result_attach: k.result_attach != false ? k.result_attach : '',
                result_url: k.result_url != false ? k.result_url : '',
                jobTitle: k.Job_Title != false ? k.Job_Title : '',
                departments: k.department != false ? k.department : '',
                program: k.program != false ? k.program : '',
                mode: k.mode != false ? k.mode : '',
                newEin: k.emp_ein != false ? k.emp_ein : '',
                newSsn:
                  k.emp_ssn != false && k.emp_ssn != null ? '***-**-' + k.emp_ssn?.substr(-4) : ''
              };
            });
          } else {
            setBackgroundScreeningData([]);
          }
          setBackgroundScreeningData(responseResult);
          const scheduledRecords = responseResult.filter((k) => {
            return k.newStatus == 'Scheduled';
          });
          setScheduledCount(scheduledRecords.length);
          const progressRecords = responseResult.filter((k) => {
            return k.newStatus == 'Progress';
          });
          setProgressCount(progressRecords.length);
          const completedRecords = responseResult.filter((k) => {
            return k.newStatus == 'Completed';
          });
          setCompletedCount(completedRecords.length);
          console.log('completed----------', completedRecords);
          //setWijmoFlexGrid(responseResult, 10);
          setChange(false);
          setEventBGS(responseResult);
          setNoRecordFound(false);
          setHasPermission(true);
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setErrMsg('Access permission not allowed.');
          setNoRecordMessage('Access permission not allowed.');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };
  function downloadUrl(e, attachment) {
    setLoader(true);
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        setIsLoaderActive(false);
        setLoader(false);
        setChange(false);
        setIsModalVisible(true);
        if (response.data && response.data.length > 0) {
          setImageUrls(response.data);
        } else {
          setErrMsg('No Document Found.');
        }
      });
  }

  function download(e, attachment) {
    setLoader(true);
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadAuthorizationBgScreening', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        setIsLoaderActive(false);
        setLoader(false);
        setChange(false);
        setIsModalVisible(true);
        if (response.data && response.data.length > 0) {
          console.log('response.url------------- ', response.data);
          setImageUrls(response.data[0].url);
        } else {
          setImageUrls();
          setErrMsg('No Document Found.');
        }
      });
  }
  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls('');
  };
  const filterTable = (type) => {
    setTableType(type);
  };

  useEffect(() => {
    getEmployeeBackgroundScreening();
    getDocPermission();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, []);

  useEffect(() => {
    if (backgroundScreeningData.length > 0) {
      const collectionView1 = new CollectionView(backgroundScreeningData, {
        pageSize: backgroundScreeningData.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(backgroundScreeningData, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      collectionView2.filters.push((item) => {
        const startDate = dateRange && dateRange.length > 0 && moment(dateRange[0]);
        const endDate = dateRange && dateRange.length > 1 && moment(dateRange[1]);
        const orderDate = moment(item.create_date);
        return (
          (!startDate || orderDate.isSameOrAfter(startDate, 'day')) &&
          (!endDate || orderDate.isSameOrBefore(endDate, 'day'))
        );
      });
      if (tableType === 'totalRecords') {
        collectionView2.filters.push((item) => {
          return item;
        });
      } else if (tableType === 'totalScheduled') {
        collectionView2.filters.push((item) => {
          return item.newStatus === 'Scheduled';
        });
      } else if (tableType === 'totalProgress') {
        collectionView2.filters.push((item) => {
          return item.newStatus === 'Progress';
        });
      } else if (tableType === 'totalCompleted') {
        collectionView2.filters.push((item) => {
          return item.newStatus == 'Completed';
        });
      }

      setView(collectionView2);
    }
  }, [backgroundScreeningData, dateRange, pageSize, tableType]);

  /* Filter to set date range */
  const setDateRangeFilter = (dates) => {
    if (dates !== null) {
      setDateRange(dates.map((date) => date.format('MM-DD-YYYY')));
    } else {
      setDateRange([]);
    }
  };

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }
  function addBackgrounds() {
    history.push({ pathname: `/vertical/addBackgroundScreening`, state: {} });
  }
  const exportToExcel = () => {
    const exportService = new ExportService();

    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          //console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          //console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      //console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        // console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        //console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showNewLocation = (item) => {
    return <span>{item.item.newLocation != false ? item.item.newLocation : ''}</span>;
  };

  const showOrderedDate = (item)=>{
    console.log(item,'itemsss')
    return <span>{item.item.newCreateDate ? moment(item.item.newCreateDate).format('MM/DD/YYYY'):''}</span>
  }
  const showSSN = (item) => {
    return <span>{item.item.newSsn}</span>;
  };

  const showEIN = (item) => {
    return <span>{item.item.newEin}</span>;
  };

  const showDL = (item) => {
    return <span>{item.item.DL}</span>;
  };
  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };
  const showAuthForm = (item) => {
    return (
      <span className='nowrap'>
        {console.log('item.item.attachment_id', item.item.attachment_id)}
        {docPermission && item.item.attachment_id ? (
          // <button
          //   className='doclink'
          //   style={{
          //     border: 'none',
          //     textAlign: 'center',
          //     borderRadius: 5,
          //     backgroundColor: '#abc',
          //     fontweight: 'bold'
          //   }}
          //   onClick={(e) => download(e, item.item.attachment_id)}
          // >
          //   Preview
          // </button>
          // <p style={{ color: 'blue' }}>
          //   {' '}
          //   Preview
          <FolderOpenTwoTone
            twoToneColor='#cc7a00'
            style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
            onClick={(e) => download(e, item.item.attachment_id)}
          />
        ) : (
          // </p>
          item.item.attachment_id && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
        )}
      </span>
    );
  };
  const showResultForm = (item) => {
    return (
      <span className='nowrap'>
        {docPermission && item.item.result_attach ? (
          // <button
          //   className='doclink'
          //   style={{
          //     border: 'none',
          //     textAlign: 'center',
          //     borderRadius: 5,
          //     backgroundColor: '#abc',
          //     fontweight: 'bold'
          //   }}
          //   onClick={(e) => downloadUrl(e, item.item.result_attach)}
          // >
          //   Preview
          // </button>
          // <p style={{ color: 'blue' }}>
          //   {' '}
          //   Preview
          <FolderOpenTwoTone
            twoToneColor='#cc7a00'
            style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
            onClick={(e) => downloadUrl(e, item.item.result_attach)}
          />
        ) : (
          // </p>
          item.item.result_attach && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
        )}
      </span>
    );
  };
  const showResultUrl = (item) => {
    return (
      <span>
        {docPermission && item.item.result_url && item.item.result_url.length > 0 ? (
          <a target='_blank' href={item.item.result_url}>
            Result
          </a>
        ) : (
          <p>NA</p>
        )}
      </span>
    );
  };
  return hasPermission ? (
    <div style={{ padding: 20 }}>
      <div style={{ display: 'flex' }}>
        <div>
          <h4 style={{ color: '#1C3A6A' }}>Background Screenings</h4>
        </div>

        <div>
          <Tooltip placement='top' title='Add Background Screening'>
            <PlusCircleTwoTone
              style={{ fontSize: 25, marginTop: 25, marginLeft: 10, cursor: 'pointer' }}
              onClick={addBackgrounds}
            />
          </Tooltip>
        </div>
        <div></div>
      </div>
      <br />
      {!noRecordFound ? (
        backgroundScreeningData.length > 0 ? (
          <>
            <div className='row'>
              {backgroundScreeningData.length != 0 && backgroundScreeningData.length != undefined && (
                <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                  <Card
                    style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                    className='animated with-shadow'
                    onClick={() => filterTable('totalRecords')}
                  >
                    <div className='row'>
                      <div className='col-12' style={{ textAlign: 'center' }}>
                        <div
                          className='count'
                          style={{
                            fontSize: 40,
                            color: '#EF6827',
                            lineHeight: 1.4,
                            fontWeight: 'bold'
                          }}
                        >
                          {backgroundScreeningData.length}
                        </div>
                        <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                          Total <br /> Background Screenings
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              {scheduledCount != 0 && scheduledCount != undefined && (
                <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                  <Card
                    style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                    className='animated with-shadow'
                    onClick={() => filterTable('totalScheduled')}
                  >
                    <div className='row'>
                      <div className='col-12' style={{ textAlign: 'center' }}>
                        <div
                          className='count'
                          style={{
                            fontSize: 40,
                            color: '#EF6827',
                            lineHeight: 1.4,
                            fontWeight: 'bold'
                          }}
                        >
                          {scheduledCount}
                        </div>
                        <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                          Employees with Scheduled Status
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              {progressCount != 0 && progressCount != undefined && (
                <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                  <Card
                    style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                    className='animated with-shadow'
                    onClick={() => filterTable('totalProgress')}
                  >
                    <div className='row'>
                      <div className='col-12' style={{ textAlign: 'center' }}>
                        <div
                          className='count'
                          style={{
                            fontSize: 40,
                            color: '#EF6827',
                            lineHeight: 1.4,
                            fontWeight: 'bold'
                          }}
                        >
                          {progressCount}
                        </div>
                        <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                          Employees with Progress Status
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              {completedCount != 0 && completedCount != undefined && (
                <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                  <Card
                    style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                    className='animated with-shadow'
                    onClick={() => filterTable('totalCompleted')}
                  >
                    <div className='row'>
                      <div className='col-12' style={{ textAlign: 'center' }}>
                        <div
                          className='count'
                          style={{
                            fontSize: 40,
                            color: '#EF6827',
                            lineHeight: 1.4,
                            fontWeight: 'bold'
                          }}
                        >
                          {completedCount}
                        </div>
                        <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                          Employees with Completed Status
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
            </div>
            <br />
            <Row>
              <Col span={8}>
                <RangePicker
                  placeholder={['Start Ordered Date', 'End Ordered Date']}
                  allowClear={true}
                  style={{ width: '100%', height: 40 }}
                  format='MM-DD-YYYY'
                  onChange={(dates) => {
                    setDateRangeFilter(dates);
                  }}
                />
              </Col>
            </Row>
            <br />

            <Row gutter={[32, 32]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div ref={fixedContainerRef}>
                  <Select
                    style={{ width: 160 }}
                    placeholder={`Items per page : ${pageSize}`}
                    onChange={paggination}
                    options={[
                      { label: 'Items per page : 10', value: 10 },
                      { label: 'Items per page : 20', value: 20 },
                      { label: 'Items per page : 50', value: 50 }
                    ]}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                  size='small'
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>

            <FlexGrid
              ref={theGrid}
              style={{ display: 'none' }}
              className='dummyWijmoData'
              itemsSource={viewData}
              initialized={gridInitializedData}
              formatItem={formatItem}
              isReadOnly={true}
            >
              <FlexGridColumn
                binding='newEmpName'
                header='Employee Name'
                minWidth={200}
                width='*'
              />
              {/* <FlexGridColumn binding='newEmail' header='Email' minWidth={300} width='*' /> */}

              {typecheckValue &&
                typecheckValue.length > 0 &&
                typecheckValue.map((value) => {
                  return (
                    <FlexGridColumn
                      binding={
                        value === 'SSN'
                          ? 'newSsn'
                          : value === 'EIN'
                          ? 'newEin'
                          : value === 'Driver License'
                          ? 'DL'
                          : ''
                      }
                      header={
                        value === 'SSN'
                          ? 'SSN'
                          : value === 'EIN'
                          ? 'EIN'
                          : value === 'Driver License'
                          ? 'Driver License'
                          : ''
                      }
                      // width='12*'
                      minWidth={150}
                      width='*'
                    >
                      <FlexGridCellTemplate
                        cellType='Cell'
                        template={
                          value === 'SSN'
                            ? showSSN
                            : value === 'EIN'
                            ? showEIN
                            : value === 'Driver License'
                            ? showDL
                            : ''
                        }
                      />
                    </FlexGridColumn>
                  );
                })}
              <FlexGridColumn binding='newLocation' header='Location' minWidth={150} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
              </FlexGridColumn>
              <FlexGridColumn binding='newCreateDateforExcel' header='Ordered Date' minWidth={150} width='*'/>

              <FlexGridColumn
                binding='newStatus'
                header='Status'
                // width='10*'
                minWidth={120}
                width='*'
              />

              <FlexGridColumn binding='newPkg' header='Package Name' minWidth={300} width='*' />

              {/* <FlexGridColumn
                binding='jobTitle'
                header='Job Title'
                // width='10*'
                minWidth={160}
                width='*'
              /> */}
              {/* <FlexGridColumn
                binding='departments'
                header='Job Position'
                // width='10*'
                minWidth={160}
                width='*'
              /> */}

              {/* <FlexGridColumn
                binding='program'
                header='DOT Mode'
                // width='10*'
                minWidth={160}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
              </FlexGridColumn> */}

              <FlexGridColumn
                binding='attachment_id'
                header='Authorization'
                // width='10*'
                minWidth={120}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showAuthForm} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='result_attach'
                header='Result'
                // width='10*'
                minWidth={120}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showResultForm} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='result_url'
                header='Result URL'
                // width='10*'
                minWidth={200}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showResultUrl} />
              </FlexGridColumn>
            </FlexGrid>

            <FlexGrid
              ref={theGrid}
              //ref={gridRef}
              id='theGridTallRows'
              className='cliniclisttable'
              alternatingRowStep={0}
              // autoRowHeights={true}
              headersVisibility='Column'
              itemsSource={view}
              initialized={gridInitialized}
              formatItem={formatItem}
              itemsPerPage={10}
              isReadOnly={true}
              scrollMode='Virtual' // Add this line for smooth scrolling
              // loadedRows={(s) => s.autoSizeColumns()}
              // rowEditEnded={(s) => s.autoSizeColumns()}
              // cellEditEnded={(s, e) => s.autoSizeColumn(e.col)}
              style={{ minwidth: '100%' }}
            >
              <div style={{ display: 'none' }}>
                <wjInput.ListBox
                  className='column-picker'
                  itemsSource={listboxColumns}
                  checkedMemberPath='visible'
                  displayMemberPath='header'
                  lostFocus={hidePicker}
                  initialized={initializedListBox}
                ></wjInput.ListBox>
              </div>
              <FlexGridColumn
                binding='newEmpName'
                header='Employee Name'
                // minWidth={180}
                // width='*'
                width={240}
              />
              {/* <FlexGridColumn binding='newEmail' header='Email' minWidth={220} width='*' /> */}

              {typecheckValue &&
                typecheckValue.length > 0 &&
                typecheckValue.map((value) => {
                  return (
                    <FlexGridColumn
                      binding={
                        value === 'SSN'
                          ? 'newSsn'
                          : value === 'EIN'
                          ? 'newEin'
                          : value === 'Driver License'
                          ? 'DL'
                          : ''
                      }
                      header={
                        value === 'SSN'
                          ? 'SSN'
                          : value === 'EIN'
                          ? 'EIN'
                          : value === 'Driver License'
                          ? 'Driver License'
                          : ''
                      }
                      // width='12*'
                      // minWidth={150}
                      // width='*'
                      width={128}
                    >
                      <FlexGridCellTemplate
                        cellType='Cell'
                        template={
                          value === 'SSN'
                            ? showSSN
                            : value === 'EIN'
                            ? showEIN
                            : value === 'Driver License'
                            ? showDL
                            : ''
                        }
                      />
                    </FlexGridColumn>
                  );
                })}
              <FlexGridColumn binding='newLocation' header='Location' width={275}>
                <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
              </FlexGridColumn>
              <FlexGridColumn binding='newCreateDate' header='Ordered Date' width={128}>
                <FlexGridCellTemplate cellType='Cell' template={showOrderedDate} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='newStatus'
                header='Status'
                // width='10*'
                // minWidth={150}
                // width='*'
                width={128}
              />

              <FlexGridColumn
                binding='newPkg'
                header='Package Name'
                // minWidth={200}
                // width='*'
                width={230}
                // wordWrap={true}
              />

              {/* <FlexGridColumn
                binding='jobTitle'
                header='Job Title'
                // width='10*'
                minWidth={160}
                width='*'
              /> */}
              {/* <FlexGridColumn
                binding='departments'
                header='Job Position'
                // width='10*'
                minWidth={160}
                width='*'
              /> */}

              {/* <FlexGridColumn
                binding='program'
                header='DOT Mode'
                // width='10*'
                minWidth={160}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
              </FlexGridColumn>
              */}

              <FlexGridColumn
                binding='attachment_id'
                header='Authorization'
                // width='10*'
                // minWidth={120}
                // width='*'
                width={95}
              >
                <FlexGridCellTemplate cellType='Cell' template={showAuthForm} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='result_attach'
                header='Result'
                // width='10*'
                // minWidth={120}
                // width='*'
                width={55}
              >
                <FlexGridCellTemplate cellType='Cell' template={showResultForm} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='result_url'
                header='Result URL'
                // width='10*'
                // minWidth={200}
                // width='*'
                width={101}
              >
                <FlexGridCellTemplate cellType='Cell' template={showResultUrl} />
              </FlexGridColumn>

              <FlexGridFilter
                showFilterIcons={true}
                placeholder='Filter data...'
                defaultFilterType='Value'
                filterColumns={[
                  'newEmpName',
                  'newEmail',
                  'newLocation',
                  'newPkg',
                  'newCreateDate',
                  'newStatus',
                  'newSsn',
                  'newEin',
                  'DL',
                  'jobTitle',
                  'departments',
                  'program'
                ]}
              />
            </FlexGrid>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                ref={filterRefCounter}
                id='filterCounter'
              >
                ({Globalize.format(filterRefCounter.current, 'n0')} )
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                {' '}
                out of {EventBGS.length}
              </p>
            </div>
          </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      <Modal
        width={1000}
        title='Backgrounds Screening Data '
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {loader && (
          <Space size='middle' style={{ marginLeft: 200 }}>
            <Spin size='large' style={{ height: 100, width: 100 }} />
          </Space>
        )}

        {!loader && imageUrls && (
          <p>
            <embed src={imageUrls} width='800px' height='2100px' />
          </p>
        )}
      </Modal>
      <DarkBackground disappear={isLoaderActive}>
        <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
      </DarkBackground>
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className='icofont-exclamation-tringle' style={{ color: 'red', fontSize: 20 }}></i> */}
        &nbsp;&nbsp; {errMsg}
      </p>
    </div>
  );
};

export default Background_screenings;
