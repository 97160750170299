import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Table, Button, Card, Form, Input, Tooltip, Modal, DatePicker,Collapse, Select,Checkbox, notification } from 'antd';
import { Rule } from 'antd/es/form';
import { Spin, Space, Row, Col, Divider } from 'antd';
import { usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'underscore';
import axios from "axios";
import CreateEmployee from './createEmployee';
import EditAddEmployee from '../forms/EditAddEmployee' 
import { history } from './../../redux/store';
import setting from '../../config/setting';
import differenceBy from 'lodash/differenceBy'

const FormItem = Form.Item;
const { Option } = Select;
const { Panel } = Collapse;
const handleClinicHours = (data) => {
  console.log('data handleClinicHours::', data);

  history.push({ pathname: `/vertical/testingDetailViewFurther`, state: data });
};

const vaccines = [
  'Pfizer',
  'Moderna',
  'Janssen',
  'Comirnaty',
  'Tozinameran',
  'Covishield',
  'Vaxzevria',
  'AstraZenca',
  'Takeda',
  'Spikevax',
  'Sinopharm-BIBP',
  'Sinovac-CoronaVac',
  'Covaxin',
  'Covovax',
  'Nuvaxovid'
];
const actions = (data) => (
  <div className='buttons-list nowrap'>
    <Tooltip title='Test Information'>
      <Button
        shape='circle'
        onClick={() => handleClinicHours(data)}
        type='primary'
        style={{
          background: 'none',
          border: 'none',
          boxShadow: 'none',
          color: '#336cfb'
        }}
      >
        <span className='icofont icofont-info-circle' style={{ fontSize: 25 }} />
      </Button>
    </Tooltip>
  </div>
);



const TestDetails = ({ protValue, RecordsArr,RecordArrTrue,SelectedEmpRecordsDuplicateVal }) => {

  const [compNameN, setcompName] = useState('');
  const [eventTypeN, seteventType] = useState('');
  const [reasonN, setreason] = useState('');
  const [serviceN, setservice] = useState('');
  const [urgencyLevelN, seturgencyLevel] = useState('');
  const [testNameN, settestName] = useState('');
  const [locationEmpN, setlocationEmp] = useState('');
  const [clinicNameN, setclinicName] = useState('');
  const [statusN, setstatus] = useState('');
  const [assignToN, setassignTo] = useState([]);
  const [PackageValue, setPackageValue] = useState('');
  const [LoaderAllEmployee, setLoaderAllEmployee] = useState(false);
  
  const [PropsDataEmp, setPropsDataEmp] = useState({});
  const [EmailsOptionCaptain, setEmailsOptionCaptain] = useState(false);
  const [EmailsOptionRecords, setEmailsOptionRecords] = useState(false);
  const [EmailsOptionGatelist, setEmailsOptionGatelist] = useState(false);
  const [EmailsOptionSchedule, setEmailsOptionSchedule] = useState(false);

  const [IsModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [DataTrueCondition, setDataTrueCondition] = useState(false);
  
  const [eta_time_val, eta_time] = useState(moment());
  const [etd_time_val, etd_time] = useState(moment());
  const [billToVal, billTo] = useState('');
  const [LoaderNN, setLoaderNN] = useState(false);
  const [LoaderEventVal, setLoaderEvent] = useState(false);
  const [EvenyComp, setEvenyComp] = useState('');
  const [comp, setComp] = useState([]);
  const [SelectedEmpRecordsDuplicate, setSelectedEmpRecordsDuplicate] = useState([]);
  

  const [deleteItems, setDeleteItems] = useState([]);

  const [locations, setLocations] = useState([]);
  const [loc, setLoc] = useState([]);
  const [locNew, setLocNew] = useState([]);
  const [PatArrTrue, setPatArrTrue] = useState(false);
  const [errorVal, setError] = useState(false);
  const [bool, setBool] = useState(false);
  const [boolNew, setBoolNew] = useState(false);
  const [boolN, setBoolN] = useState(false);
  const [boolNe, setBoolNe] = useState(false);
  const [boolNN, setBoolNN] = useState(false);
  const [NewChangeOneN, setNewChangeOneN] = useState(false);
  const [DateScheduled, setDateScheduled] = useState(moment());
  const [reasonNew, setReason] = useState([]);
  const [productTemplate, setProductTemplate] = useState([]);
  const [clinic, setclinic] = useState([]);
  const [testNames, setestName] = useState([]);

  const [firstNameValue, firstNameValueSearch] = useState('');
  const [lastNameValue, lastNameValueSearch] = useState('');

  const [comIDD, setCompId] = useState([]);
  const [serviceNew, setService] = useState([]);
  const [serviceNewBKP, setServiceBKP] = useState([]);
  const [testRegister, setTestRegister] = useState([]);
  const [patArr, setPatArr] = useState([]);
  const [CompNameTreeView, setCompNameTreeView] = useState('');
  const [reasonTreeValue, setreasonTreeValue] = useState('');
  const [serviceTreeValue, setserviceTreeValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleCE, setIsModalVisibleCE] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [EmpChange, setEmpChange] = useState(false);
  const [SelectedEmpRecords, setSelectedEmpRecords] = useState([]);
  const [EmpRecordsTrue, SelectedEmpRecordsTrue] = useState(false);

  const [EmployeeSearchListTrue, setEmployeeSearchListTrue] = useState(false);
  const [EmployeeSearchList, setEmployeeSearchList] = useState([]);
  const [CompDataNew, setCompDataNew] = useState([]);
  const [CompDataNewTrue, setCompDataNewTrue] = useState(false);
  const [selectCompanyId, setSelectCompanyId] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("")
  const [companies, setCompanies] = useState([])

  const [collectionDateValue, collectionDate] = useState("");
  const [covidTestDateValue, covidTestDate] = useState("");
  const [sampleValue, sample] = useState("");
  const [resultValue, result] = useState("");
  const [vaccineNameValue, vaccineName] = useState("");
  const [vaccineDateValue, vaccineDate] = useState("");
  const [doseValue, dose] = useState("");
  const [lotNumberValue, lotNumber] = useState("");
  const [packageCode, setPackageCode] = useState("");

  const [ChangeNN, setChangeNN] = useState(false);

  const [vesselNameVal, vesselName] = useState('');
  const [imoVal, imo] = useState('');
  const [locationsNameVal, locationsName] = useState('');
  const [staffVal, staff] = useState('');

  const [crewTypeVal, crewType] = useState('');
  const [vesselStatusVal, vesselStatus] = useState('');
  const [vacc_req_Val, vacc_req] = useState('');
  const [agencyVal, agency] = useState('');
  const [agentNameVal, agentName] = useState('');

  const [phoneVal, phone] = useState('');
  const [agencyEmailVal, agencyEmail] = useState('');
  const [poVal, po] = useState('');
  const [mileageVal, mileage] = useState('');
  const [captainVal, captain] = useState('');

  const [captainEmailVal, captainEmail] = useState('');
  const [schedule_name_Val, schedule_name] = useState('');
  const [schedule_email_Val, schedule_email] = useState('');
  const [schedule_phone_Val, schedule_phone] = useState('');
  const [eventNotesVal, eventNotes] = useState('');
  // const [emailsOptionVal, emailsOption] = useState('');

  const [vacc_completed_val, vacc_completed] = useState('');
  const [test_completed_val, test_completed] = useState('');
  const [test_request_val, test_request] = useState('');

  const [EmployeeIds, setEmployeeIds] = useState([]);
  const [LoadMoreVal, setLoadMore] = useState(1);
  const [currentPageNumberVal, currentPageNumber] = useState(1);
  const [pageLimitVal, pageLimit] = useState(25);

  useEffect(() => {

    if (protValue == undefined) {
      history.goBack();
    }
    else {
      console.log("this.props", protValue);
      console.log("RecordsArr one::",RecordsArr)
      getCompanyData('');
      setSelectedCompanyName(protValue.company[1])
      setlocationEmp(protValue.child_ids[1].split(',')[1])
      if (protValue.urgency_level == 'rtpcr') {
        seturgencyLevel('rtpcr')
      }
      if (protValue.urgency_level == 'screening' || protValue.urgency_level == 'Screening') {
        seturgencyLevel('Screening')
      }
      if (protValue.urgency_level == 'background screening' || protValue.urgency_level == 'Background Screening') {
        seturgencyLevel('Background Screening')
      }
      if (protValue.urgency_level == 'antigen') {
        seturgencyLevel('antigen')
      }
      if (protValue.urgency_level == 'vaccination') {
        seturgencyLevel('vaccination')
      }
      setreason(protValue.reasons)
      setservice(protValue.service_multi_select[1])
      setestName(protValue.test_name.split('\n'))
      // getEmployeeData(protValue.multi_select_employee)
      setEvenyComp(protValue.company[0])
      setDataSelectedEmployee(RecordsArr)
      if (protValue.type_of_events == false) {
        seteventType('')
      } else {
        seteventType(protValue.type_of_events)
      }
      if (protValue.clinic_name == false) {
        setclinicName('')
      } else {
        setclinicName(protValue.clinic_name[1])
      }
      if (protValue.event_status == false) {
        setstatus('')
      } else {
        setstatus(protValue.event_status)
      }
      // if (protValue.scheduled_date == false) {
      //   setDateScheduled(moment())
      // } else {
      //   setDateScheduled(protValue.scheduled_date == false?moment():protValue.scheduled_date)
      // }

  vesselName(protValue.vessel == false ? '' : protValue.vessel)
  imo(protValue.imo == false ? '' : protValue.imo)
  locationsName(protValue.agent_loc == false ? '' : protValue.agent_loc)
  staff(protValue.staff == false ? '' : protValue.staff)

  crewType(protValue.crew == false ? '' : protValue.crew)
  vesselStatus(protValue.vessels_status == false ? '' : protValue.vessels_status)
  vacc_req(protValue.vacc_request == false ? '' : protValue.vacc_request)
  vacc_completed(protValue.vacc_completed == false ? '' : protValue.vacc_completed)
  agency(protValue.agency == false ? '' : protValue.agency[0])
  agentName(protValue.agent_name == false ? '' : protValue.agent_name)

  phone(protValue.agent_num == false ? '' : protValue.agent_num)
  agencyEmail(protValue.agent_email == false ? '' : protValue.agent_email)
  po(protValue.po_num == false ? '' : protValue.po_num)
  mileage(protValue.mileage == false ? '' : protValue.mileage) 
  captain(protValue.captain == false ? '' : protValue.captain)

  captainEmail(protValue.captain_email == false ? '' : protValue.captain_email)
  schedule_name(protValue.sched_by_name == false ? '' : protValue.sched_by_name) 
  schedule_email(protValue.sched_by_email == false ? '' : protValue.sched_by_email) 
  schedule_phone(protValue.sched_by_phone == false ? '' : protValue.sched_by_phone)
  eventNotes(protValue.event_notes == false ? '' : protValue.event_notes)
  setEmailsOptionRecords(protValue.email_records)
  setEmailsOptionSchedule(protValue.email_schedule)
  setEmailsOptionGatelist(protValue.email_gatelist)
  setEmailsOptionCaptain(protValue.email_captian)
  test_request(protValue.test_request == false ? '' : protValue.test_request)
  test_completed(protValue.test_completed == false ? '' : protValue.test_completed)
  eta_time(protValue.eta_time == false ? moment():protValue.eta_time)
  etd_time(protValue.etd_time == false ? moment():protValue.etd_time)
  setDateScheduled(protValue.scheduled_date == false ? moment():protValue.scheduled_date)

  billTo(protValue.bill_to == false ? false:protValue.bill_to[0])

      getReasons();
      getproduct_template() 
      getClinics();
      getTestRegister();
    }

  }, [RecordsArr])


  console.log("billl:",billToVal)

  console.log("protValue.email_captian::",protValue.email_captian)

  console.log("urgencyLevelN:::", urgencyLevelN)
  console.log("EvenyComp::", EvenyComp)
  console.log("SelectedEmpRecords new:::::", SelectedEmpRecords)

  const setDataSelectedEmployee = (RecordsArr) => {
    console.log("arr one::", RecordsArr)
    if (protValue.urgency_level == 'screening' || protValue.urgency_level == 'Screening'|| protValue.urgency_level == 'Background Screening') {
      RecordsArr.map((item) => {
        item.id = item.emp_selected[0]
        // item.employee = [,item.display_name]
      })
    } else {
      RecordsArr.map((item) => {
        item.id = item.employee[0]
        // item.employee = [,item.display_name]
      })
    }
    let RecordsArrData = RecordsArr.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id ))
    
    console.log("RecordsArr inside:",RecordsArrData)
    setSelectedEmpRecords(RecordsArrData)
  }
  const getCompanyData = (datVal) => {
    console.log("datVal::", datVal)
    console.log("EvenyComp::", EvenyComp)
    setIsModalVisibleCE(false);
    console.log("getData :: ", localStorage.getItem('myData'), localStorage.getItem('myPass'))
    let custIdArr = [];
    axios.post(setting.serverUrl + '/api/allCustomerDataCustomerPortal', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        custId: JSON.parse(localStorage.getItem('customerData')).id
      }
    }).then(async response => {
      console.log("Respone from allCustomerData res", response.data)

      if (response && response.status == 200) {
        setCompanies(response.data);
        
        let selectedCmp =await response.data.filter(cmp => cmp.id == JSON.parse(localStorage.getItem('customerData')).id) 
        console.log("selectedCmp loc",selectedCmp)
        setEmployeeIds(selectedCmp[0].employee_multi_select)
        await getEmployeeData(selectedCmp[0].employee_multi_select);
        await setLoaderAllEmployee(false)

      }
    })
  }

  console.log("EmployeeIds::",EmployeeIds)
  const showTotal = (total) => {
    return (
      <p >Total {total} items</p>
    )
  }

  const filterCompanies = (event) => {
    console.log("filterCompanies event :: ", event)
    setEvenyComp(event)

    let selectedCmp = companies.filter(cmp => cmp.id == event)
    setCompDataNew(selectedCmp)
    setSelectedCompany(selectedCmp[0].id);
    setSelectedCompanyName(selectedCmp[0].display_name)
    getLocationData(selectedCmp[0].id)
    getServicesData(selectedCmp[0].id)
    getEmployeeData(selectedCmp[0].employee_multi_select);

  };

  console.log("CompDataNew only:::", CompDataNew)

  //   interface DataType {
  //     //key: 
  //     id: React.Key;
  //     name: string;
  //   }

 
  function getTestRegister() {

    axios.post(setting.serverUrl + '/api/getTestRegister', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from getTestRegister ", response.data)


      if (response && response.status == 200) {

        setTestRegister(response.data)

      }
    })

  }

  console.log("testRegister", testRegister)



  function getClinics() {
    setBool(true)
    axios.post(setting.serverUrl + '/api/getClinics', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from getClinics ", response.data)

      response.data.sort((a, b) =>
        a.clinic_name.localeCompare(b.clinic_name) //using String.prototype.localCompare()
      );
      if (response && response.status == 200) {

        setclinic(response.data)
        //setBool(true)
        setBool(true)

      }
    })

  }



  function getproduct_template() {

    axios.post(setting.serverUrl + '/api/product_template', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from product_template ", response)

      if (response && response.status == 200) {

        setProductTemplate(response.data)
        //setBool(true)
        setBoolNew(true)

      }
    })

  }



  function getReasons() {
    axios.post(setting.serverUrl + '/api/getReasons', {
      // axios.get('http://localhost:3001/api/getReasons', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from getReasons ", response.data)


      if (response && response.status == 200) {

        setReason(response.data)
        //setBool(true)
        setBoolN(true)

      }
    })

  }

  const handleAdvanceFilter = () => {
    // this.currentPageNumber = 1;
    getCompanyData('')
  };
  
  const handleReset=()=> {
    firstNameValueSearch('')
    lastNameValueSearch('')
    setPatArr([])
  getDataFromcallback()
  }

  const getDataFromcallback = ()=>{
    getCompanyDataReset('reset') 
  }

  const getCompanyDataReset = (datVal)=> {
    // setLoaderAllEmployee(true)
    setLoaderAllEmployee(true)
    setIsModalVisibleCE(false);
    console.log("getData :: ", localStorage.getItem('myData'), localStorage.getItem('myPass'))
    let custIdArr = [];
    axios.post(setting.serverUrl + '/api/allCustomerDataCustomerPortal', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        custId: JSON.parse(localStorage.getItem('customerData')).id
      }
    }).then(async response => {
      console.log("Respone from allCustomerData res ", response.data)
      // setCompanies(response.data);
      if (response && response.status == 200) {
  
        // if(datVal == 'reset'){
         
        //   setCompanies(response.data);
        
        //   let selectedCmp =await response.data.filter(cmp => cmp.id == JSON.parse(localStorage.getItem('customerData')).id) 
        //   console.log("selectedCmp loc",selectedCmp)
        //   setEmployeeIds(selectedCmp[0].employee_multi_select)
        //   await getEmployeeData(selectedCmp[0].employee_multi_select);
        //   await setLoaderAllEmployee(false)
        // }
            setCompanies(response.data);
        
            let selectedCmp =await response.data.filter(cmp => cmp.id == JSON.parse(localStorage.getItem('customerData')).id) 
            console.log("selectedCmp loc",selectedCmp)
            setEmployeeIds(selectedCmp[0].employee_multi_select)
            await getEmployeeDataReset(selectedCmp[0].employee_multi_select);
            await setLoaderAllEmployee(false)
        
        
      }
    })
  }
  
  const getEmployeeDataReset = async (custIdArr) => {
    console.log("ddd getEmployeeDataReset",firstNameValue,lastNameValue)
    // setLoaderAllEmployee(true)
    console.log("getEmployeeDataReset ::  ", custIdArr);
    let dat = [];
    await axios.post(setting.serverUrl + '/api/employeeCustCustomerPortal', {
      //  axios.get('http://localhost:3001/api/employee',{
      params:
      {
        //email:localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        custIdArr: custIdArr,
        offset: 0,
        limit: 25,
        first_name: '',
        last_name: '',
        compId: JSON.parse(localStorage.getItem('customerData')).id
      }
    }).then(response => {
      console.log("Respone from getData getEmployeeData", response.data)
      // setLoaderAllEmployee(false)
      response.data.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
      if (response && response.status == 200) {
        _.each(response.data, (resp) => {
          resp.key = resp.id
        })
  
        currentPageNumber(currentPageNumberVal + 1);
  
        console.log("dat", dat);
        setPatArr(response.data);
        setPatArrTrue(!PatArrTrue)
  
      }
    })
  
  }

  const getLocationData = (company) => {
    console.log("getLocationData :: ", company);
    setBoolNe(true)
    axios.post(setting.serverUrl + '/api/Res', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        id: company
      }
    }).then(response => {
      console.log("Respone from getLocationData ", response.data)


      if (response && response.status == 200) {

        setLocations(response.data)
        setBoolNe(true)

      }
    })

  }



  const getEmployeeData = async (custIdArr) => {
     setLoaderAllEmployee(true)
    console.log("getEmployeeData ::  ", custIdArr);
    let dat = [];
    await axios.post(setting.serverUrl + '/api/employeeCustCustomerPortal', {
      //  axios.get('http://localhost:3001/api/employee',{
      params:
      {
        //email:localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        custIdArr: custIdArr,
        offset: 0,
        limit: pageLimitVal * currentPageNumberVal,
        first_name: firstNameValue,
        last_name: lastNameValue,
        compId: JSON.parse(localStorage.getItem('customerData')).id
      }
    }).then(response => {
      console.log("Respone from getData getEmployeeData", response.data)
      response.data.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
      if (response && response.status == 200) {
        _.each(response.data, (resp) => {
          resp.key = resp.id
        })
        setLoaderAllEmployee(false)
        currentPageNumber(currentPageNumberVal + 1);

        console.log("dat", dat);
        setPatArr(response.data);
        setPatArrTrue(!PatArrTrue)

      }
    })

  }

  const columns = [
    {
      title: 'Gender',
      key: 'Gender',
      dataIndex: 'Gender',
      sorter: (a, b) => a.Gender.length - b.Gender.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (Gender) => (
        <span className='nowrap'>
    {Gender}
        </span>
      )
    },
    {
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      title: 'First Name',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (first_name) => <span>{first_name}</span>
    },
    {
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: (a, b) => a.last_name.length - b.last_name.length,
      title: 'Last Name',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (last_name) => <span>{last_name}</span>
    },
    {
      title: 'SSN',
      key: 'emp_ssn',
      dataIndex: 'emp_ssn',
      sorter: (a, b) => a.emp_ssn.length - b.emp_ssn.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (emp_ssn) => (
        <span className='nowrap'>
          {emp_ssn}
        </span>
      )
    },


    {
      key: 'dob',
      dataIndex: 'dob',
      title: 'DOB',
      // sorter: (a, b) => a.dob.length - b.dob.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (dob) => <span>{moment(dob).format("MM/DD/YYYY")}</span>
    },
    {
      title: 'Status',
      key: 'emp_status',
      dataIndex: 'emp_status',
      sorter: (a, b) => a.emp_status.length - b.emp_status.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (emp_status) => (
        <span className='nowrap'>
          {emp_status}
        </span>
      )
    },




  ];

  function eventType(event) {
    // compName();
    console.log("eventType", event);
    seteventType(event)
  }

  const getServicesData = (company) => {

    setLoaderNN(true)
    console.log("dataNewArr::", company)

    axios.post(setting.serverUrl + '/api/hospital_customers_lines', {
      // axios.get('http://localhost:3001/api/employee',{
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        compId: company
      }
    }).then(response => {
      console.log("Respone from hospital_customers_lines ", response.data)


      if (response && response.status == 200) {
        setService(response.data)
        setServiceBKP(response.data)
        setLoaderNN(false)
      }

    })




  }



  const service = (event) => {
    // compName();
    console.log("productTemplate :: ", productTemplate);
    let newTestArr = [];
    let selectedPackage = JSON.parse(event);
    console.log("service", selectedPackage);
    let pcode = ''
    if (selectedPackage) {
      pcode = selectedPackage.package_code == false ? 'False' : selectedPackage.package_code
    }
    setPackageCode(pcode)
    let prodId = selectedPackage.product_id[0]
    let testId = productTemplate.length > 0 && productTemplate.filter(x => x.id == prodId)[0].multi_select
    testId.map(dat => {
      let testName = testRegister.filter(test => test.id === dat)[0].test_name
      newTestArr.push(testName)
    })
    console.log("newTestArr :: ", newTestArr)
    setserviceTreeValue(selectedPackage.display_name)

    const dataArr = selectedPackage.test_name.split('\n');
    console.log("dataArr::", dataArr.splice(-1))
    setestName(newTestArr);
    setservice(selectedPackage.product_id[0])

  }

  console.log("testNames:::", testNames)

  function urgencyLevel(event) {

    console.log("urgencyLevel", event);
    // setService([])
    seturgencyLevel(event)
    if (event == 'Screening' || event == 'Background Screening' ) {

      setService(serviceNewBKP)

    }
    if (event == 'vaccination') {
      // setPackageValue("")

      let selectedCmp = serviceNewBKP.filter(cmp => cmp.display_name == 'Covid Vaccination')
      console.log("vaccination::", selectedCmp)
      setService(selectedCmp)


    }
    if (event == 'rtpcr') {

      let selectedCmp1 = serviceNewBKP.filter(cmp => cmp.display_name == 'Covid RT-PCR Test')
      console.log("rtpcr::", selectedCmp1)
      setService(selectedCmp1)

    }
    if (event == 'antigen') {

      let selectedCmp2 = serviceNewBKP.filter(cmp => cmp.display_name == 'Covid Antigen Test')
      console.log("antigen::", selectedCmp2)
      setService(selectedCmp2)

    }

  }
  console.log("urgencyLevelN::", urgencyLevelN)
  function testName(event) {
    console.log("testName", event);
    settestName(event)
  }

  function locationEmp(event) {
    console.log("locationEmp", event);
    setlocationEmp(event)
  }

  function clinicName(event) {
    console.log("clinicName", event);
    setclinicName(event)
  }
  function status(event) {
    console.log("status", event);
    setstatus(event)
  }
  async function assignTo(event) {

    let empArrD = [];
    console.log("assignTo", event[0]);

    await _.each(event, (empEve) => {

      empArrD.push(JSON.parse(empEve).id);

    })

    setassignTo(empArrD)
  }

  console.log("assignToN::", assignToN)

  async function saveTest() {

    // if (SelectedEmpRecords && SelectedEmpRecords.length > 0) {

      setLoaderEvent(true)
      // console.log("CompDataNew save::::::", CompDataNew[0].id)
      console.log("CompDataNew save::::::", protValue.urgency_level)
      // console.log("CompDataNew save::::::", protValue.emp_location[1])
      // console.log("CompDataNew save::::::", protValue.company[1])
      // console.log("CompDataNew save::::::", CompDataNew[0].name + ' | ' + moment().format('MM/DD/YYYY HH:mm:ss') + ' | ' + serviceTreeValue + ' | ' + reasonTreeValue)
      // console.log("CompDataNew save::::::", locationEmpN)
      // console.log("CompDataNew save::::::", clinicNameN)
      // console.log("CompDataNew save::::::",CompDataNew[0].id)
      console.log("SelectedEmpRecords::", SelectedEmpRecords)
      let resultEmpIdsArray = await SelectedEmpRecords.map(a => a.id);
      // console.log("::nn",CompNameTreeView + '|' + moment().format('MM/DD/YYYY HH:mm:ss') + '|' + serviceTreeValue + '|' + reasonTreeValue)
      console.log("resultEmpIdsArray::", resultEmpIdsArray)

      console.log("saveTest");
      if(deleteItems.length>0){
        await axios.post(setting.serverUrl + '/api/deleteTestEventEmployee', {
          params:
          {
            id: protValue.id,
            deleteItems: deleteItems,
            urgencyLevel: protValue.urgency_level
      }
    }).then(response => {
      console.log("Respone from deleteTestEventEmployee ", response)
    })
  }
      await axios.post(setting.serverUrl + '/api/updateTestEvent', {
        params:
        {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),

          compNameN: protValue.company[0],
          // compNameN: CompDataNew[0].id,

          reasonN: protValue.reasons,
          serviceN: protValue.service_multi_select[0],
          id: protValue.id,
          urgencyLevelN: protValue.urgency_level,
          // tree_field: CompDataNew[0].name + ' | ' + moment().format('MM/DD/YYYY HH:mm:ss') + ' | ' + serviceTreeValue + ' | ' + reasonTreeValue,
          // testNameN: testNameN,
          locationEmpN: protValue.child_ids[0],
          eventTypeN: protValue.type_of_events,
          clinicNameN: protValue.clinic_name.length>0 ? protValue.clinic_name[0]: false,
          statusN: statusN,
          DateScheduled: DateScheduled,
          assignToN: resultEmpIdsArray,
          SelectedEmpRecords: SelectedEmpRecords,
          package_code: packageCode,

          vesselNameVal:vesselNameVal,
            imoVal:imoVal,
            locationsNameVal:locationsNameVal,
            staffVal:staffVal,
            crewTypeVal:crewTypeVal,
            vesselStatusVal:vesselStatusVal,
            vacc_req_Val:vacc_req_Val,
            agencyVal:agencyVal,
            agentNameVal:agentNameVal,
            phoneVal:phoneVal,
            agencyEmailVal:agencyEmailVal,
            poVal:poVal,
            mileageVal:mileageVal,
            captainVal:captainVal,
            captainEmailVal:captainEmailVal,
            schedule_name_Val:schedule_name_Val,
            schedule_email_Val:schedule_email_Val,
            schedule_phone_Val:schedule_phone_Val,
            eventNotesVal:eventNotesVal,
            vacc_completed_val:vacc_completed_val,
            test_completed_val:test_completed_val,
            test_request_val:test_request_val,
            EmailsOptionCaptain:EmailsOptionCaptain,
            EmailsOptionSchedule:EmailsOptionSchedule,
            EmailsOptionRecords:EmailsOptionRecords,
            EmailsOptionGatelist:EmailsOptionGatelist,
            eta_time:eta_time_val,
            etd_time:etd_time_val,
            billToVal:billToVal,
            deleteItems:deleteItems     
          
        }
      }).then(response => {
        console.log("Respone from saveTest ", response.data)

        setLoaderEvent(false)
        if (response && response.status == 200) {
          notification['success']({
            message: 'Updated Successfully',
        
          })
          setError(false)
          history.goBack();
          // history.push({ pathname: `/vertical/testingEvents` });
        }
      })
    // } else {
    //   // alert("Please fill required fields")
    //   setError(true);
    // }

  }

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const displayEmpList = () => {
    console.log("onclick")
    setIsModalVisible(true);

  }

  const displayEmpListNN = async (data) => {
    setDataTrueCondition(true)
    // setSubstituteObj({});
    axios.post(setting.serverUrl + '/api/getEmployeeEditPopUp', {
      // axios.get('http://localhost:3001/api/employee',{
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        empId: data.id
      }
    }).then(response => {
      console.log("Respone from employee popup", response.data)
      if (response && response.status == 200) {
        setPropsDataEmp({})
        // console.log('onclick', response.data[0]);
        setPropsDataEmp(response.data[0])
        setDataTrueCondition(false)
        // setSubstituteObj(data);
        setIsModalVisibleEdit(true);
      }

    })
    
  };

console.log("PropsDataEmp::",PropsDataEmp)

  const rowSelection = {

    selectedRowKeys: SelectedEmpRecords.map(item => item.id),
    onChange: async (selectedRowKeys, selectedRows) => {

      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      await _.each(selectedRows, (resp) => {
        let filterArr = SelectedEmpRecords.filter(dat => dat.id == resp.id)
        if (filterArr.length == 0) {
          if (resp.employee && resp.employee.length > 0) {

          } else {
            resp.employee = [resp.id, resp.display_name]
            resp.child_ids = resp.emp_location
          }
          SelectedEmpRecords.push(resp)
        }
        // resp.employee = [resp.id,resp.display_name]

      })
      setSelectedEmpRecords(SelectedEmpRecords)
      setSelectedEmpRecordsDuplicate(SelectedEmpRecords)
      setEmpChange(!EmpChange)
    },
    getCheckboxProps: (record) => ({
      disabled: record.first_name === 'Disabled User',
      // Column configuration not to be checked
      first_name: record.first_name,
    }),
  };
  console.log("SelectedEmpRecordsDuplicate::",SelectedEmpRecordsDuplicate)
  const handleOk = () => {
    setIsModalVisible(false);
    console.log("SelectedEmpRecords:::", SelectedEmpRecords)
  };

  const handleCreateEmployee = () => {
    console.log("handleCreateEmployee");
    setIsModalVisibleCE(true);
  };

  const sendDataToParentNN = async (type,data) => {
    let empArr = []; // the callback. Use a better name
    console.log("index NN::", type);
    console.log("empData::",data)
    console.log("SelectedEmpRecordsDuplica::",SelectedEmpRecordsDuplicate)
    console.log("RecordsArr update::",RecordsArr)
    SelectedEmpRecordsDuplicate.map(obj => {
      if(obj.employee[0] == data.id){
        data.employee = [data.id,data.first_name + data.last_name]
        empArr.push(data)
      }else{
        empArr.push(obj)
      }
    });
    
    // SelectedEmpRecordsDuplicate.map(x=>{
      
    //   if(x.employee[0] == data.id){
    //     console.log("test data::",x.employee[0],data.id)
    //     return data
    //       // x.suffixNew=data.suffixNew,
    //       // firstNameNew: firstNameNew,
    //       // middleNameNew: middleNameNew,
    //       // lastNameNew: lastNameNew,
    //       // sexNew: sexNew,
    //       // statusNew: statusNew,
    //       // dotNew: dotNew,
    //       // jobTitleNew: jobTitleNew,
    //       // mobileNew: mobileNew,
    //       // emailNew: emailNew,

    //       // workEmailNew: workEmailNew,
    //       // streetNew: streetNew,
    //       // street2New: street2New,
    //       // stateNewA: [parseInt(stateNewId), stateNewA],
    //       // cityNewA: cityNewA,

    //       // zipNew: zipNew,
    //       // ssnNew: ssnNew,
    //       // locationNewA: parseInt(locationNewA),
    //       // dlNew: dlNew,
    //       // ppNew: ppNew,
    //       // countrNew: [parseInt(countrNew), countName],
    //       // comNew: parseInt(selectedCompany),
    //       // //comNew: parseInt(CompDataNew[0].id),
    //       // dateNew: dateNew,
    //       // mobilePhone: parseInt(mobilePhone),
    //       // age: age,
    //       // dtProgram: dtProgram,
    //       // mode: dtProgram != "NON - DOT" ? dtMode : "",
    //       // dot_mode: dtProgram == "NON - DOT" ? dtMode : "",
    //       // dtMode: dtMode
      
    //   }
    // })
    console.log("SelectedEmpRecordsDuplicate after:",empArr)
    // setDrive(index);
    if (type == "Added") {
      setIsModalVisibleEdit(false);
      getCompanyData('close');
      // setDataSelectedEmployee(RecordsArr);
      SelectedEmpRecordsDuplicateVal(empArr)
    }
  };

  const handleCancelCEdit = () => {
    setPropsDataEmp({})
    // console.log("EvenyComp", EvenyComp)
    // getCompanyData('close');
    setIsModalVisibleEdit(false)
  }

  const handleCancelCE = () => {
    console.log("EvenyComp", EvenyComp)
    getCompanyData('close');
    // let selectedCmp =await companies.filter(cmp => cmp.id == EvenyComp) 
    // console.log("selectedCmp",selectedCmp)
    // await setIsModalVisibleCE(false);
    // await getEmployeeData(selectedCmp[0].employee_multi_select);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };



  const searchEmployee = (event) => {

    console.log("search emp::", event.target.value)

    let employeeSearch = patArr.filter(function (e) {
      return e.first_name.toLowerCase().includes(event.target.value.toLowerCase())
        || e.last_name.toLowerCase().includes(event.target.value.toLowerCase())
        || (e.emp_status && e.emp_status.toLowerCase().includes(event.target.value.toLowerCase()));
    });
    console.log("employeeSearch:::", employeeSearch);
    // setTimeout(() => {
    setEmployeeSearchList(employeeSearch);
    setEmployeeSearchListTrue(!EmployeeSearchListTrue)


  }


  console.log("EmployeeSearchList", EmployeeSearchList);

  const deleteRow = (idx) => {

    console.log("idx::", idx)
    console.log("selectedEmployee::", SelectedEmpRecords)
  let newArr = [];
    let deleteItem = SelectedEmpRecords[idx]
    deleteItems.push(deleteItem.id)
    // console.log("deleteItem",deleteItem)
    // SelectedEmpRecords.map((dat)=>{
    //   if(dat.id != deleteItem.id){
    //     newArr.push(dat);
    //   }
    // })
    // console.log("newwArr",newArr)
    SelectedEmpRecords.splice(idx, 1);
  
    setSelectedEmpRecords(SelectedEmpRecords)
    setSelectedEmpRecordsDuplicate(SelectedEmpRecords)
    setNewChangeOneN(!NewChangeOneN);
  }
  console.log("deleteItems::",deleteItems)
  console.log("SelectedEmpRecords2", SelectedEmpRecords)
  const ResponseValData = (data) => {
    console.log("dddd:", data)
  }

  console.log("patArr::", patArr)
  function download(e, attachment_url) {

    axios.post(setting.serverUrl + '/api/downloadTestingCertificate', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        attachment_url: attachment_url

      }
    }).then(response => {
      console.log("Respone from getData Covid_Vaccination", response.data)
      setChangeNN(!ChangeNN)
      window.open(response.data);
    })
  }

  console.log("eta update",moment(eta_time_val).format('MM/DD/YYYY h:mm a'))
console.log("etd update",moment(etd_time_val).format('MM/DD/YYYY h:mm a'))

const sendDataToParent = (data) => { // the callback. Use a better name
  console.log("index::",data);
  // setDrive(index);
  if(data == "Added"){
    setIsModalVisibleCE(false);
    getCompanyData('close');
  }
};

  return (
    <div style={{ backgroundColor: 'white', padding: 20 }}>
      {/* <h4>Job Summary</h4>
        <Row>
            <Col span={12}>
           <p>Refrence ID : {protValue.refrence_id}</p>
           <p>Company Name : {protValue.company[1]}</p>
           <p>Account Number : {protValue.acc_number}</p>
           <p>Location : {protValue.child_ids[1]}</p>
           <p>Type of Event : {protValue.urgency_level}</p>
           <p>Mode : {protValue.mode_dot}</p>
           <p>Schedule Type : {protValue.schedule_type}</p>
           <p>Gender(s) : {protValue.gender_choose}</p>
           
            </Col>
            <Col span={12}>
            <p>Reason : {protValue.reasons}</p>
            <p>Scheduled Date : {protValue.scheduled_date}</p>
            <p>Collection Site : {protValue.type_of_events}</p>
            <p>Bill to : {protValue.bill_to[1]}</p>
            <p>Frequency : {protValue.frequency}</p>
            <p>Number To Be Tested : {protValue.no_to_be_tested}</p>
            <p>Status : {protValue.event_status}</p>
           
            </Col>

        </Row>
         */}

      <div className='row' style={{ padding: 20, backgroundColor: "#fff", borderRadius: 20 }}>


        <div className='col-md-12 col-sm-12'>
          <Spin spinning={LoaderNN}>
            <Card>
              <Form layout='vertical'>
                <div className='row'>
                 
                  {/* <div className='col-md-2'></div> */}
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label={errorVal ? <p>Location <span style={{ color: 'red' }}>*(Required field)</span></p> : <p>Location *</p>}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                        disabled={true}
                        value={locationEmpN}
                        onChange={(event) => locationEmp(event)}
                      >
                        {locations.length > 0 && locations.map((loc, index) => (

                          <Option value={loc.id}>{loc.name}</Option>


                        ))}
                      </Select>
                    </FormItem>
                  </div>


                  <div className='col-md-2'></div>
                </div>


                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label={errorVal ? <p>Type of Event <span style={{ color: 'red' }}>*(Required field)</span></p> : <p>Type of Event *</p>}>
                      <Select
                        disabled={true}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                        value={urgencyLevelN}
                        onChange={(event) => urgencyLevel(event)}
                      >
                        <Option value="Screening">Screening</Option>
                        <Option value="Background Screening">Background Screening</Option>
                        {/* <Option value="Emergency">Emergency</Option> */}
                        <Option value="rtpcr">RT-PCR</Option>
                        <Option value="antigen">Antigen</Option>
                        <Option value="vaccination">Vaccination</Option>
                      </Select>
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label='Reason'>
                      <Select
                         showSearch
                         optionFilterProp="children"
                         filterOption={(input, option) =>
                           option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                         }
                        disabled={true}
                        value={reasonN}
                        onChange={(event) => setTimeout(() => {
                          setreason(event)

                        }, 500)
                        }
                      >
                        {/* {reasonNew.length > 0 && reasonNew.map((reason, index) => ( */}

                        <Option value="ANNUAL">ANNUAL</Option>
                        <Option value="AUDIOGRAM">AUDIOGRAM</Option>
                        <Option value="BLIND SAMPLE">BLIND SAMPLE</Option>
                        <Option value="COURT ORDERED">COURT ORDERED</Option>
                        <Option value="FITNESS FOR DUTY">FITNESS FOR DUTY</Option>
                        <Option value="FOLLOW-UP">FOLLOW-UP</Option>
                        <Option value="JOB TRANSFER">JOB TRANSFER</Option>
                        <Option value="POST-ACCIDENT">POST-ACCIDENT</Option>
                        <Option value="PRE-EMPLOYMENT">PRE-EMPLOYMENT</Option>
                        <Option value="PRE-SITE ACCESS">PRE-SITE ACCESS</Option>
                        <Option value="PROBATION">PROBATION</Option>
                        <Option value="PROMOTION">PROMOTION</Option>
                        <Option value="RANDOM">RANDOM</Option>
                        <Option value="RE-CERTIFICATION">RE-CERTIFICATION</Option>
                        <Option value="REASONABLE SUSPICION/CAUSE">REASONABLE SUSPICION/CAUSE</Option>
                        <Option value="RETURN TO DUTY">RETURN TO DUTY</Option>
                        <Option value="SWEEP">SWEEP</Option>
                        <Option value="Transfer Medicals">Transfer Medicals</Option>
                        <Option value="OTHER">OTHER</Option>
                        <Option value="CONTRACTUAL">CONTRACTUAL</Option>
                        <Option value="PERIODIC">PERIODIC</Option>

                        {/* ))} */}
                      </Select>
                    </FormItem>
                  </div>


                  <div className='col-md-2'></div>
                </div>


                <div className="row">
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label={errorVal ? <p>Package <span style={{ color: 'red' }}>*(Required field)</span></p> : <p>Package *</p>}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                        disabled={true}
                        value={serviceN}
                        onChange={(event) => service(event)}
                      >

                        {serviceNew.length > 0 && serviceNew.map((serv, index) => (
                          <Option value={JSON.stringify(serv)}>{serv.display_name}</Option>
                        ))}

                      </Select>
                    </FormItem>

                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label={testNames.length > 0 ? 'Tests Name' : ''}>

                      {testNames.length > 0 && testNames.map((test, index) => (

                        <p style={{ fontSize: 12 }}>{test}</p>

                      ))}

                    </FormItem>


                  </div>
                </div>
                <div className="row">
                  <div className='col-md-4 col-sm-12'>
                <FormItem label='Status'>
                        <Select value={statusN}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                          }
                          disabled={false}
                          onChange={(event) => status(event)}
                        >

                          {/* <Option value="New">New</Option> */}
                          <Option value="Scheduled">Scheduled</Option>
                          <Option value="Cancelled">Cancelled</Option>
                          <Option value="Completed">Completed</Option>

                        </Select>
                      </FormItem>
                      </div>
                      </div>

                {(protValue.urgency_level == 'rtpcr' || protValue.urgency_level == 'antigen' || protValue.urgency_level == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Vessel Name'>
                      <Input 
                      value={vesselNameVal}
                      onChange={(event) => vesselName(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='IMO'>
                      <Input 
                      value={imoVal}
                      onChange={(event) => imo(event.target.value)}
                      />
                    </FormItem>
                  
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(protValue.urgency_level == 'rtpcr' || protValue.urgency_level == 'antigen' || protValue.urgency_level == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Location'>
                      <Input 
                      value={locationsNameVal}
                      onChange={(event) => locationsName(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Staff'>
                      <Input 
                      value={staffVal}
                      onChange={(event) => staff(event.target.value)}
                      />
                    </FormItem>
                  
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }
                        {(protValue.urgency_level == 'rtpcr' || protValue.urgency_level == 'antigen') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Crew Type'>
                      <Input 
                      value={crewTypeVal}
                      onChange={(event) => crewType(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Mileage'>
                      <Input 
                      value={mileageVal}
                      onChange={(event) => mileage(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(protValue.urgency_level == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Vessel Status'>
                  <Input 
                      value={vesselStatusVal}
                      onChange={(e) => vesselStatus(e.target.value)}
                      />
                  
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Mileage'>
                      <Input 
                      value={mileageVal}
                      onChange={(event) => mileage(event.target.value)}
                      />
                    </FormItem>
                  
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(protValue.urgency_level == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Vaccination Requested'>
                      <Input 
                      value={vacc_req_Val}
                      onChange={(event) => vacc_req(event.target.value)}
                      />
                    </FormItem>
                  
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Vaccination Completed'>
                  <Input 
                      value={vacc_completed_val}
                      onChange={(e) => vacc_completed(e.target.value)}
                      />
                  
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }
                {(protValue.urgency_level == 'Screening'|| protValue.urgency_level == 'Background Screening' || protValue.urgency_level == 'screening') &&
                  <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                      <FormItem label='Collection Site'>
                        <Select value={eventTypeN}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                          disabled={true}
                          onChange={(event) => eventType(event)}
                        >
                          <Option value="Onsite">Onsite</Option>
                          <Option value="clinic">clinic</Option>
                        </Select>
                      </FormItem>
                    </div>
                    <div className='col-md-2'></div>
                    <div className='col-md-4 col-sm-12' >
                      <FormItem label='Clinic Name'>
                        <Select value={clinicNameN}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                          disabled={true}
                          onChange={(event) => clinicName(event)}
                        >
                          {clinic.length > 0 && clinic.map((cClinic, index) => (

                            <Option value={cClinic.id}>{cClinic.clinic_name}</Option>

                          ))}
                        </Select>
                      </FormItem>
                    </div>
                    <div className='col-md-2'></div>
                  </div>

                }

                {(protValue.urgency_level == 'Screening' || protValue.urgency_level == 'Background Screening' || protValue.urgency_level == 'screening' || 
                protValue.urgency_level == 'antigen' || protValue.urgency_level == 'rtpcr'
                || protValue.urgency_level == 'vaccination') &&
                  <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                      <FormItem label='Scheduled Date'>
                        <DatePicker style={{ width: '100%' }}
                          disabled={false}
                          showTime={true}
                        // defaultValue=""
                          format="MM/DD/YYYY HH:mm:ss"
                          //  value={moment(SelectedEmpRecords[idx].test_date)}
                          //  onChange={(date, dateString) => {SelectedEmpRecords[idx].test_date = dateString;

                          value={DateScheduled ? moment(DateScheduled):DateScheduled}
                          onChange={(date, dateString) => setDateScheduled(date)}
                        />
                      </FormItem>
                    </div>
                    <div className='col-md-2'></div>
                    <div className='col-md-4 col-sm-12' >
                    <FormItem label='Bill To' style={{display:'none'}}>
                  <Select
                       
                       showSearch
                      //disabled={location.state?true:false}
                       optionFilterProp="children"
                       value={billToVal}
                     onChange={(event) => billTo(event)}
                       
                       filterOption={(input, option) =>
                         option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                       }
                     >
                      {companies && companies.length>0 &&
                       companies.map((companyObj) => (
                         <Option value={companyObj['id']}>{companyObj.name}</Option>
                       ))}                 

                     </Select>
                     </FormItem>
                    </div>
                    <div className='col-md-2'></div>
                  </div>

                }
                {(protValue.urgency_level == 'rtpcr' || protValue.urgency_level == 'antigen' 
                || protValue.urgency_level == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Agency'>

                  <Select
                       
                       showSearch
                      //disabled={location.state?true:false}
                       optionFilterProp="children"
                       value={agencyVal}
                     onChange={(event) => agency(event)}
                       
                       filterOption={(input, option) =>
                         option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                       }
                     >
                      {companies && companies.length>0 &&
                       companies.map((companyObj) => (
                         <Option value={companyObj['id']}>{companyObj.name}</Option>
                       ))}                 

                     </Select>
                      {/* <Input 
                      value={agencyVal}
                      onChange={(event) => agency(event.target.value)}
                      /> */}
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Agent Name'>
                      <Input 
                      value={agentNameVal}
                      onChange={(event) => agentName(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(protValue.urgency_level == 'rtpcr' || protValue.urgency_level == 'antigen' || protValue.urgency_level == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Agent Phone'>
                      <Input 
                      value={phoneVal}
                      onChange={(event) => phone(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Agent Email'>
                      <Input 
                      value={agencyEmailVal}
                      onChange={(event) => agencyEmail(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(protValue.urgency_level == 'rtpcr' || protValue.urgency_level == 'antigen' || protValue.urgency_level == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='PO#'>
                      <Input 
                      value={poVal}
                      onChange={(event) => po(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  {/* <FormItem label='Mileage'>
                      <Input 
                      value={mileageVal}
                      onChange={(event) => mileage(event.target.value)}
                      />
                    </FormItem> */}
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(protValue.urgency_level == 'rtpcr' || protValue.urgency_level == 'antigen') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Test Requests'>
                      <Input 
                      value={test_request_val}
                      onChange={(event) => test_request(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Test Completed'>
                      <Input 
                      value={test_completed_val}
                      onChange={(event) => test_completed(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{protValue.urgency_level == 'vaccination' &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Captain'>
                      <Input 
                      value={captainVal}
                      onChange={(event) => captain(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Captain Email'>
                      <Input 
                      value={captainEmailVal}
                      onChange={(event) => captainEmail(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{protValue.urgency_level == 'vaccination' &&
                <div className='row'>
                <div className='col-md-4 col-sm-12'>
                <FormItem label='ETA'>
                <DatePicker
                style={{width:'100%'}}
                                allowClear={false}
                                showTime={true}
                                format="MM/DD/YYYY HH:mm:ss"
                                value={moment(eta_time_val)}
                                onChange={(date, dateString) => 
                                  eta_time(date)
                                }
                                 
                              />
                  </FormItem>
                </div>
                <div className='col-md-2'></div>
                <div className='col-md-4 col-sm-12'>
                <FormItem label='ETD'>
                <DatePicker
                style={{width:'100%'}}
                                allowClear={false}
                                showTime={true}
                                format="MM/DD/YYYY HH:mm:ss"
                                value={moment(etd_time_val)}
                                onChange={(date, dateString) => 
                                  etd_time(date)
                                }
                               
                              />
                  </FormItem>
                </div>
                <div className='col-md-2'></div>
              </div>

                        }

{protValue.urgency_level == 'vaccination' &&
<div>
<hr/>
  <span style={{
    display: 'inline-flex',
    alignItems: 'center',
    height: '32px',
    color: '#1C3A6A',
    fontSize: '15px',
    fontWeight: 700,
    textDecoration:'underline'}}>Scheduled By:</span>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Name'>
                      <Input 
                      value={schedule_name_Val}
                      onChange={(event) => schedule_name(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Phone'>
                      <Input 
                      value={schedule_phone_Val}
                      onChange={(event) => schedule_phone(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>
                </div>
                        }
                        {protValue.urgency_level == 'vaccination' &&
                        <div>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Email'>
                      <Input 
                      value={schedule_email_Val}
                      onChange={(event) => schedule_email(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  {/* <FormItem label='Phone'>
                      <Input 
                      //value={captainEmailVal}
                      //onChange={(event) => captainEmail(event.target.value)}
                      />
                    </FormItem> */}
                  </div>
                  <div className='col-md-2'></div>
                </div>
                <hr/>
                </div>

                        }
                        <br/>
                        {protValue.urgency_level == 'vaccination' &&
                        <div>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Emails-'>
                  <Checkbox name="Captain" defaultChecked={protValue.email_captian}
                  onChange={(e)=>{setEmailsOptionCaptain(e.target.checked);console.log("e",e.target.checked)}}
                  >Captain</Checkbox><br/>
                  <Checkbox name="Gatelist" defaultChecked={protValue.email_gatelist}
                  onChange={(e)=>{setEmailsOptionGatelist(e.target.checked);console.log("e",e.target.checked)}}
                  >Gatelist</Checkbox><br/>
                  <Checkbox name="Schedule" defaultChecked={protValue.email_schedule}
                  onChange={(e)=>{setEmailsOptionSchedule(e.target.checked);console.log("e",e.target.checked)}}
                  >Schedule</Checkbox><br/>
                  <Checkbox name="Records" defaultChecked={protValue.email_records}
                  onChange={(e)=>{setEmailsOptionRecords(e.target.checked);console.log("e",e.target.checked)}}
                  >Records</Checkbox>
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Event Notes'>
                      <TextArea
                      rows={3} 
                      value={eventNotesVal}
                      onChange={(event) => eventNotes(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>
                <hr/>
                </div>

                        }
                        <br/>
                <div className='row'>
                  {(protValue.urgency_level == 'antigen' || protValue.urgency_level == 'Screening' || protValue.urgency_level == 'Background Screening' || protValue.urgency_level == 'rtpcr' || protValue.urgency_level == 'vaccination') &&
                    <div className='col-md-4 col-sm-12'>
                      <FormItem label={<p>Select Employee </p>}>
                        <Button style={{ width: '50%' }}
                          //disabled={protValue.poolId?true:false}
                          onClick={displayEmpList}
                        >Select Employee</Button> &nbsp;
                        {DataTrueCondition && <Space size="small" style={{ marginTop: 0 }}>

<Spin size="large" />
</Space>}
                        {/* <Input onClick={displayEmpList} /> */}
                        {/* {SelectedEmpRecords.length > 0 && SelectedEmpRecords.map((resp, index) => (
                        <Tag
                          //onClose={()=>log(index)} 
                          style={{ backgroundColor: '#97abd3', marginBottom: 10 }}>{resp.first_name + " " + resp.last_name}</Tag>
                      ))} */}

                        <Modal
                          visible={isModalVisible}
                          width={'80%'}
                          // onOk={handleOk}
                          onCancel={handleCancel}
                          footer={[
                            <Button key="submit" style={{ backgroundColor: 'rgb(41 94 62)', }}
                              onClick={handleCreateEmployee}
                            >
                              Create Employee
                            </Button>,
                            <Button key="submit" style={{ backgroundColor: '#33b5e5', }} onClick={handleOk}>
                              Select
                            </Button>,
                            <Button key="submit" style={{ backgroundColor: '#ff4444' }} onClick={handleCancel}>
                              Cancel
                            </Button>
                          ]}

                        >
                          {patArr.length > 0 ?

                            <div>
                              <Card title={
                              <Row>
                                <Col span={18}>
                                  <span>Employee List</span>
                                  </Col>
                                  <Col span={2}>
            <Button
              size='small'
              style={{ backgroundColor: '#247', color: 'white',float:'left',marginTop:13, border:"none" }}
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
          </Col>
                                  <Col span={2}>
                                  <Button
                                  size="small"
            style={{ backgroundColor: '#247', color: 'white', border:"none" }}
            onClick={() => {

              //  let LoadMoreVal = {LoadMoreVal + 1;
              getCompanyData('close');

            }}
          >
            Load More
          </Button>
                                  </Col>
                                  </Row>
                                }>
                                <Input
                                  style={{ border: "1px solid #a72626", margin: "0 0 10px 0" }}
                                  placeholder="Search by name"
                                  //enterButton
                                  onChange={(event) => searchEmployee(event)}
                                />
                                 <Row>
          <Col>
            {/* <div style={{ position: "absolute", zIndex: "1" }}> */}
            <Collapse accordion>
              <Panel
                style={{ background: '#2F7197', borderRadius: '10px 10px 0px 0px', color: 'white' }}
                className='ant-collapse-header'
                header={
                  <span
                    style={{ fontSize: '110%', fontWeight: 700, width: '1100px', display: 'block' }}
                  >
                    Advanced Search Filter
                  </span>
                }
                key='1'

              >
                <Row>
                  <Col span={11}>
                    <Input
                      value={firstNameValue}
                      placeholder='First Name:'
                      suffix={<span className='icofont icofont-search' />}
                      style={{ width: '80%', backgroundColor: 'white' }}
                      onChange={(event) =>
                        firstNameValueSearch(event.target.value)
                      }
                    />
                  </Col>
                  <Col span={11}>
                    <Input
                      value={lastNameValue}
                      placeholder='Last Name:'
                      suffix={<span className='icofont icofont-search' />}
                      style={{ width: '80%', backgroundColor: 'white' }}
                      onChange={(event) =>
                        lastNameValueSearch(event.target.value)
                      }
                    />
                  </Col>
                  <Col 
                  //style={{ align: 'center' }} 
                  span={2}>
                    <Button
                      size='small'
                      style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                      onClick={() => handleAdvanceFilter()}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            {/* </div> */}
          </Col>
        </Row>
        <br />
                                <div className='row'>
                                  <div className='col-12 col-md-12 col-xl-12' >

                                    <Table
                                      loading={LoaderAllEmployee}
                                      dataSource={EmployeeSearchList.length > 0 ? EmployeeSearchList : patArr}
                                      columns={columns}
                                      // footer={}
                                      rowSelection={{
                                        type: 'checkbox',
                                        ...rowSelection,
                                      }}
                                      pagination={{
                                        pageSize: pageLimitVal, hideOnSinglePage: false, showSizeChanger: true, total: patArr.length,
                                        showTotal: showTotal
                                      }}
                                    />
<br />
          <Button
            style={{backgroundColor: '#247', color: 'white', border:"none" }}
            onClick={() => {

              //  let LoadMoreVal = {LoadMoreVal + 1;
              getCompanyData('close');

            }}
          >
            Load More
          </Button>
                                  </div>
                                </div>
                              </Card>
                            </div>
                            
                                      :
                            <Row>
                              <Col span={10}>
                              </Col>
                              <Col span={8}>
                                <Space size="large" style={{ marginTop: 30 }}>

                                  <Spin size="large" />

                                </Space>
                              </Col>
                            </Row>

                          }


                        </Modal>


                        <Modal
                          visible={isModalVisibleCE}
                          width={'70%'}
                          // onOk={handleOk}
                          onCancel={handleCancelCE}
                          footer={[
                            // <Button key="submit" style={{ backgroundColor: '#ff4444' }} 
                            // onClick={handleCancel}
                            // >
                            //   Cancel
                            // </Button>
                          ]}

                        >
                          <CreateEmployee setData={{ id: protValue.company[0], name: protValue.company[1] }} sendDataToParent={sendDataToParent} />


                        </Modal>

                      </FormItem>
                    </div>
                  }

                </div>
                <Modal
                          visible={IsModalVisibleEdit}
                          width={'70%'}
                          // onOk={handleOk}
                          onCancel={handleCancelCEdit}
                          footer={[
                            // <Button key="submit" style={{ backgroundColor: '#ff4444' }} 
                            // onClick={handleCancel}
                            // >
                            //   Cancel
                            // </Button>
                          ]}

                        >
                         <Card title="Edit Employee">
                         <EditAddEmployee 
                         setData={PropsDataEmp} 
                         sendDataToParentNN={sendDataToParentNN} 
                         />

    
                         </Card>
                        </Modal>

                {
                  (protValue.urgency_level == 'antigen' || protValue.urgency_level == 'rtpcr') && SelectedEmpRecords && SelectedEmpRecords.length > 0 ?
                    <div>
                      <div className='row'>

                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Employee Name
                        </div>
                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Collection Date
                        </div>
                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Covid Test Date
                        </div>
                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Sample Type
                        </div>
                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Result Type
                        </div>
                        {/* <div className='col-md-1 col-sm-12' style={{fontWeight:600}}>PDF</div>  */}
                        <div className='col-md-1 col-sm-12'></div>

                      </div>
                      {
                        SelectedEmpRecords.map((emp, idx) => {
                          SelectedEmpRecords[idx].id = emp.id;

                          return (
                            <div style={{ padding: 10 }}>
                              <div className='row'>
                                <div className='col-md-2 col-sm-12'>
                                  {emp.employee[1]} &nbsp; <i
              class='icofont-edit-alt'
              style={{ cursor: 'pointer' }}
              onClick={() => displayEmpListNN(emp)}
            ></i>

           
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                  <DatePicker
                                    allowClear={false}
                                    showTime={true}
                                    value={moment(SelectedEmpRecords[idx].collection_date)}
                                    onChange={(date, dateString) => {
                                      SelectedEmpRecords[idx].collection_date = moment(date).format('YYYY-MM-DD HH:mm:ss');
                                      SelectedEmpRecordsTrue(!EmpRecordsTrue)
                                    }}
                                    // onChange={(date, dateString) =>collectionDate(dateString)} 
                                    style={{ width: '100%', borderRadius: 50, padding: 7 }}
                                  />
                                  {/* <Select allowClear>
                                <Option value=''> Select Vaccine</Option>
                                {vaccines.map((vaccine, i) => (
                                  <Option key={i} value={vaccine}>
                                    {vaccine}
                                  </Option>
                                ))}
                              </Select> */}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                  <DatePicker
                                    allowClear={false}
                                    showTime={true}
                                    value={moment(SelectedEmpRecords[idx].test_date)}
                                    onChange={(date, dateString) => {
                                      SelectedEmpRecords[idx].test_date = moment(date).format('YYYY-MM-DD HH:mm:ss');
                                      SelectedEmpRecordsTrue(!EmpRecordsTrue)
                                    }}
                                    style={{ width: '100%', borderRadius: 50, padding: 7 }}
                                  />
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                  <Select allowClear
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                  }
                                    value={SelectedEmpRecords[idx].sample_type}
                                    onChange={(e) => { SelectedEmpRecords[idx].sample_type = e; SelectedEmpRecordsTrue(!EmpRecordsTrue) }}
                                  >
                                    {urgencyLevelN == 'antigen' &&
                                      <Option value='Nasal Swab'>Nasal Swab</Option>
                                    }
                                    {urgencyLevelN == 'rtpcr' &&
                                      <Option value='Nasopharyngeal Swab'>Nasopharyngeal Swab</Option>
                                    }
                                  </Select>
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                  <Select allowClear
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                  }
                                    value={SelectedEmpRecords[idx].test_result}
                                    onChange={(e) => { SelectedEmpRecords[idx].test_result = e; SelectedEmpRecordsTrue(!EmpRecordsTrue) }}
                                  >

                                    <Option value='Positive'>Positive</Option>
                                    <Option value='Negative'>Negative</Option>
                                  </Select>
                                </div>
                                {/* <div className='col-md-1 col-sm-12'> */}

                                {/* <span className='nowrap'>
                  {!(SelectedEmpRecords[idx].attachment_url) ? SelectedEmpRecords[idx].attachment_id.length>0 && 
                  <button  onClick={(e) => download(e, SelectedEmpRecords[idx].attachment_id)}>Preview</button> 
                  : <button  onClick={(e) => download(e, SelectedEmpRecords[idx].attachment_url)}>Preview</button> }
                </span> */}
                                {/* </div> */}

                                <div className='col-md-1 col-sm-12'>
                                  <i className="fa fa-times" style={{ cursor: 'pointer', fontSize: 18, marginTop: 10 }}
                                    onClick={() => deleteRow(idx)}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                    :
                    null
                }
                
{
  (protValue.urgency_level == 'Screening' || protValue.urgency_level == 'Background Screening' ) && SelectedEmpRecords && SelectedEmpRecords.length>0?
                     <div>
                     <div className='row'>    
                
                        <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                            Employee Name
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              DOB
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Status
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              SSN
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Location
                            </div>
                            {/* <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Location
                            </div> */}
                            <div className='col-md-2 col-sm-12'></div> 
                            
                   </div> 
                   {
                        SelectedEmpRecords.map((emp,idx) => {
                          return (
                            <div style={{padding:10}}>
                            <div className='row'>    
                                <div className='col-md-2 col-sm-12'>
                                {emp.first_name} {emp.last_name}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                {emp.dob}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                {emp.emp_status}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                {emp.emp_ssn}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                {(emp.emp_location && emp.emp_location.length>0) ? emp.emp_location[1]:''}
                                </div>
                                {/* <div className='col-md-2 col-sm-12'>
                                {emp.first_name} {emp.last_name}
                                </div>   */}
                                <div className='col-md-2 col-sm-12'>
                                  <i className="fa fa-times" style={{ cursor: 'pointer', fontSize: 18, marginTop: 10 }}
                                    onClick={() => deleteRow(idx)}
                                  />
                                </div>   
                            </div> 
                          </div>  
                          )
                        })
                   } 
                   </div>
                   :
                    null
                   }

                {
                  protValue.urgency_level == 'vaccination' && SelectedEmpRecords && SelectedEmpRecords.length > 0 ?
                    <div>
                      <div className='row'>

                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Employee Name
                        </div>
                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Vaccine Name
                        </div>
                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Vaccine Date
                        </div>
                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Dose
                        </div>
                        <div style={{ fontWeight: 600 }} className='col-md-2 col-sm-12'>
                          Lot No
                        </div>
                        {/* <div className='col-md-1 col-sm-12'style={{fontWeight:600}}>PDF</div>  */}
                        <div className='col-md-1 col-sm-12'></div>

                      </div>
                      {
                        SelectedEmpRecords.map((emp, idx) => {
                          return (
                            <div style={{ padding: 10 }}>
                              <div className='row'>
                                <div className='col-md-2 col-sm-12'>
                                  {emp.employee[1]} &nbsp; <i
              class='icofont-edit-alt'
              style={{ cursor: 'pointer' }}
              onClick={() => displayEmpListNN(emp)}
            ></i>
            
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                  <Select allowClear
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                  }
                                    value={SelectedEmpRecords[idx].test_type}
                                    onChange={(e) => { SelectedEmpRecords[idx].test_type = e; SelectedEmpRecordsTrue(!EmpRecordsTrue) }}
                                  >
                                    <Option value=''> Select Vaccine</Option>
                                    {vaccines.map((vaccine, i) => (
                                      <Option key={i} value={vaccine}>
                                        {vaccine}
                                      </Option>
                                    ))}
                                  </Select>
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                  <DatePicker
                                    allowClear={false}
                                    value={moment(SelectedEmpRecords[idx].test_date)}
                                    onChange={(date, dateString) => {
                                      SelectedEmpRecords[idx].test_date = dateString;
                                      SelectedEmpRecordsTrue(!EmpRecordsTrue)
                                    }}
                                    style={{ width: '100%', borderRadius: 50, padding: 7 }}
                                  />
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                  <Select allowClear
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                  }
                                    value={SelectedEmpRecords[idx].dose}
                                    onChange={(e) => { SelectedEmpRecords[idx].dose = e; SelectedEmpRecordsTrue(!EmpRecordsTrue) }}
                                  >
                                    <Option value=''>Select Dose</Option>
                                    <Option value='First Dose'>First Dose</Option>
                                    <Option value='Second Dose'>Second Dose</Option>
                                    <Option value='Booster Dose'>Booster Dose</Option>
                                    <Option value='Single Dose'>Single Dose</Option>
                                  </Select>
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                  <input
                                    value={SelectedEmpRecords[idx].lot_no}
                                    onChange={(e) => { SelectedEmpRecords[idx].lot_no = e.target.value; SelectedEmpRecordsTrue(!EmpRecordsTrue) }}
                                  />
                                </div>
                                {/* <div className='col-md-1 col-sm-12'> */}
                                {/* <span className='nowrap'>
                  {!(SelectedEmpRecords[idx].attachment_url) ? SelectedEmpRecords[idx].attachment_id.length>0 && 
                  <button  onClick={(e) => download(e, SelectedEmpRecords[idx].attachment_id)}>Preview</button> 
                  : <button  onClick={(e) => download(e, SelectedEmpRecords[idx].attachment_url)}>Preview</button> }
                </span> */}
                                {/* </div> */}

                                <div className='col-md-1 col-sm-12'>
                                  <i className="fa fa-times" style={{ cursor: 'pointer', fontSize: 18, marginTop: 10 }}
                                    onClick={() => deleteRow(idx)}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                    :
                    null
                }
                <div className='row' style={{ marginTop: 50 }}>
                  <div className='col-md-4 col-sm-4'>

                  </div>

                  <div className='col-md-5 col-sm-5'>
                    <Button type='primary' style={{ width: '50%',backgroundColor:"#1b3969" }}
                      onClick={saveTest}
                    >Update</Button>
                    {LoaderEventVal &&
                      <Space size="middle" style={{ marginTop: 10, marginLeft: 10 }}>
                        <Spin size="large" />
                      </Space>
                    }
                  </div>

                </div>

              </Form>
            </Card>
          </Spin>

        </div>



      </div>

    </div>
  )
}

const EditTestingDetails = () => {
  const location = useLocation();

  const {data, prevData} = location.state;

  const [imageUrls, setImageUrls] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [employeeName, setEmployeeName] = useState([]);
  const [testArr, setTestArr] = useState([]);
  const [obj, setObj] = useState({});
  const [change, setChange] = useState(false);
  const [responseTrueVal, setresponseTrue] = useState(false);
  const [selectedEmployeesDuplicateValue, selectedEmployeesDuplicate] = useState([]);
  const [ChangeNN, setChangeNN] = useState(false);
  const [firstName, setEmployeeFirstName] = useState('');
  const [RecordsArr, setRecords] = useState([]);

  useEffect(() => {
    if (data == undefined) {
      history.goBack();
    }
    else {

      console.log("this.props Test Event", data);
      setObj(data)
      getOpenNotifId(data,[])
    }
  }, [])

  console.log("obj", obj)

  const columnsNew = [
    {
      key: 'caseNumber',
      dataIndex: 'caseNumber',
      sorter: (a, b) => a.caseNumber.length - b.caseNumber.length,
      title: 'Case Number',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (caseNumber) => <span>{caseNumber}</span>
    },
    {
      key: 'url',
      dataIndex: 'url',
      sorter: (a, b) => a.url.length - b.url.length,
      title: 'Donor Pass Url',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (url) => <a href={url} target="_blank"><span>{url && url.substring(0, 22) + "..."}</span></a>
    },
    {
      key: 'emp_selected',
      dataIndex: 'emp_selected',
      sorter: (a, b) => a.emp_selected[1].length - b.emp_selected[1].length,
      title: 'Employee Name',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (emp_selected) => <span>{emp_selected[1]}</span>
    },
    {
      title: 'Item Name',
      key: 'test_name',
      dataIndex: 'test_name',
      //   sorter: (a, b) => a.dob.length - b.dob.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (test_name) => (
        <span className='nowrap'>
          {test_name == false ? '' : test_name}
        </span>
      )
    },

    {
      title: 'CCF Number',
      key: 'ccf_number',
      dataIndex: 'ccf_number',
      sorter: (a, b) => a.ccf_number.length - b.ccf_number.length,
      render: (ccf_number) => <div
      //onClick={()=>handleShowInfo} 
      >{ccf_number == false ? '' : ccf_number}</div>
    },
    {
      key: 'dot_classification',
      dataIndex: 'dot_classification',
      title: 'DOT Classification',
      sorter: (a, b) => a.dot_classification.length - b.dot_classification.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (dot_classification) => <span>{dot_classification == 'False' ? '' : dot_classification}</span>
    },
    {
      key: 'testing_status',
      dataIndex: 'testing_status',
      title: 'testing_status',
      sorter: (a, b) => a.testing_status.length - b.testing_status.length,
      render: (testing_status) => (
        <span className='nowrap'>
           {testing_status == false ? '' : testing_status}
        </span>
      )
    },
    {
      key: 'completed_date',
      dataIndex: 'completed_date',
      title: 'completed_date',
      // sorter: (a, b) => a.completed_date.length - b.completed_date.length,
      render: (completed_date) => (
        <span className='nowrap'>
          {completed_date == false ? '' : completed_date}
        </span>
      )
    },
    {
      key: 'comments',
      dataIndex: 'comments',
      title: 'comments',
      sorter: (a, b) => a.comments.length - b.comments.length,
      render: (comments) => (
        <span className='nowrap'>
          {comments == false ? '' : comments}
        </span>
      )
    },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }

  ];
  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };
  function downloadVaccination(e, attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        console.log('Respone from download images', response.data);
        if (response && response.status === 200) {
          setChange(!change);
          if (Array.isArray(response.data)) {
            setIsModalVisible(true);
            setImageUrls(response.data);
          } else {
            window.open(response.data);
          }
          // if (Array.isArray(response.data)) {
          //   window.open(response.data[0].url);
          // }
        }
      });
  }


  const columnsNewVaccination = [

    {
      key: 'employee',
      dataIndex: 'employee',
      sorter: (a, b) => a.employee[1].length - b.employee[1].length,
      title: 'Employee Name',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (employee) => <span>{employee[1]}</span>
    },
    {
      title: 'Gender',
      key: 'sex',
      dataIndex: 'sex',
      //   sorter: (a, b) => a.dob.length - b.dob.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (sex) => (
        <span className='nowrap'>
        {sex}
        </span>
      )
    },

    {
      title: 'Vaccine Name',
      key: 'test_type',
      dataIndex: 'test_type',
      sorter: (a, b) => a.test_type.length - b.test_type.length,
      render: (test_type) => <div
      //onClick={()=>handleShowInfo} 
      >{test_type}</div>
    },
    {
      key: 'test_date',
      dataIndex: 'test_date',
      title: 'Vaccination Date',
      //   sorter: (a, b) => a.test_date.length - b.emp_ssn.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (test_date) => <span>{moment(test_date).format('MM/DD/YYYY')}</span>
    },
    {
      key: 'dose',
      dataIndex: 'dose',
      title: 'Dose',
      sorter: (a, b) => a.dose.length - b.dose.length,
      render: (dose) => (
        <span className='nowrap'>
         {dose}
        </span>
      )
    },
    {
      key: 'lot_no',
      dataIndex: 'lot_no',
      title: 'Lot Number',
      sorter: (a, b) => a.lot_no.length - b.lot_no.length,
      render: (lot_no) => (
        <span className='nowrap'>
          {lot_no}
        </span>
      )
    },

    {
      key: 'attachment_url',
      dataIndex: 'attachment_url',
      title: 'Documents',
      render: (text, record) => (
        <span className='nowrap'>
          {!(record.attachment_url) ? record.attachment_id.length > 0 &&
            <button onClick={(e) => downloadVaccination(e, record.attachment_id)}>Preview</button>
            : <button onClick={(e) => downloadVaccination(e, record.attachment_url)}>Preview</button>}
        </span>
      )
    }

  ];

  function download(e, attachment_url) {

    axios.post(setting.serverUrl + '/api/downloadTestingCertificate', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        attachment_url: attachment_url

      }
    }).then(response => {
      console.log("Respone from getData Covid_Vaccination", response.data)
      setChangeNN(!ChangeNN)
      window.open(response.data);
    })
  }

  const columnsAntigenandrtpcr = [

    {
      key: 'employee',
      dataIndex: 'employee',
      sorter: (a, b) => a.employee[1].length - b.employee[1].length,
      title: 'Employee Name',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (employee) => <span>{employee[1]}</span>
    },
    {
      title: 'Gender',
      key: 'sex',
      dataIndex: 'sex',
      //   sorter: (a, b) => a.dob.length - b.dob.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (sex) => (
        <span className='nowrap'>
          {sex}
        </span>
      )
    },

    {
      key: 'collection_date',
      dataIndex: 'collection_date',
      title: 'Collection Date',
      //   sorter: (a, b) => a.test_date.length - b.emp_ssn.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (collection_date) => <span>{moment(collection_date).format('MM/DD/YYYY HH:mm:ss')}</span>
    },
    {
      key: 'test_date',
      dataIndex: 'test_date',
      title: 'Covid Test Date',
      //   sorter: (a, b) => a.test_date.length - b.emp_ssn.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (test_date) => <span>{moment(test_date).format('MM/DD/YYYY HH:mm:ss')}</span>
    },
    {
      key: 'sample_type',
      dataIndex: 'sample_type',
      title: 'Sample Type',
      sorter: (a, b) => a.sample_type.length - b.sample_type.length,
      render: (sample_type) => (
        <span className='nowrap'>
          {sample_type}
        </span>
      )
    },
    {
      key: 'test_result',
      dataIndex: 'test_result',
      title: 'Result Type',
      sorter: (a, b) => a.test_result.length - b.test_result.length,
      render: (test_result) => (
        <span className='nowrap'>
          {test_result}
        </span>
      )
    },

    {
      key: 'attachment_url',
      dataIndex: 'attachment_url',
      title: 'Documents',
      render: (text, record) => (
        <span className='nowrap'>
          {!(record.attachment_url) ? record.attachment_id.length > 0 &&
            <button onClick={(e) => download(e, record.attachment_id)}>Preview</button>
            : <button onClick={(e) => download(e, record.attachment_url)}>Preview</button>}
        </span>
      )
    }

  ];

  function SelectedEmpRecordsDuplicateVal (data){
    console.log("SelectedEmpRecordsDuplicate data",data)
    selectedEmployeesDuplicate(data)
    // let newDat = data.map(a=>a.id)
    // setTimeout(()=>{
      getOpenNotifId(obj,data)
    // },2000)
    
    }
    function goToPrevious() {
      if (prevData == undefined) {
        history.goBack();
      } else {
        history.replace({ pathname: `/vertical/testingDetails`, state: prevData });
        setTimeout(() => history.goBack());
      }
      //
    }
  function getOpenNotifId(obj,dataArr) {
    setresponseTrue(false)
    // let multiSelectId = obj.multi_select_employee;
    let api = ''
    if (obj.urgency_level == 'Screening' || obj.urgency_level == 'Background Screening') {
      api = 'appointment.register.tests'
    }
    if (obj.urgency_level == 'vaccination') {
      api = 'covid.vaccination'
    }
    if (obj.urgency_level == 'antigen' || obj.urgency_level == 'rtpcr') {
      api = 'covid.testing'
    }

    axios
      .post(setting.serverUrl + '/api/employeeTestEventsData', {

        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          test_field: obj.test_field,
          covid_test_event_id: obj.covid_test_event_id,
          covid_vacc_event_id: obj.covid_vacc_event_id,
          api: api,
          urgency_level: obj.urgency_level
        }
      })
      .then(async (response) => {
        if (response && response.data.error) {
          alert("Some Technical Error Occurred!")
        }
        console.log('Respone from employee details ', response.data);
        let result = response.data;
        // let newDat = result.map(a=>a.employee[0])

        if (response && response.data.error) {
          alert("Some Technical Error Occurred!")
        }
        if (response && response.status == 200) {
          if(response.data.length ==0){
            setresponseTrue(true)
          }

          console.log("selectedEmployeesDuplicate::",dataArr)
          // console.log("newDat",newDat)
          let blankArr = [];
          let diffArr=[]
          let diffArr1=[]
          let diffArr2=[]
          let diffArr3=[]
          // dataArr.map(x=>{
          //   if(!newDat.includes(x.id)) {
          //     console.log("x.id",x.id)
          //     blankArr.push(x)
          //   }
          // })

          if(dataArr.length>0 && dataArr.length > result.length){
            // diffArr = dataArr.filter(o1 => !result.some(o2 => o1.employee[0] === o2.employee[0]));
            diffArr1 = dataArr.filter(o1 => !result.some(o2 => o1.employee[0] === o2.employee[0]));
            // diffArr2 = result.filter(o1 => !dataArr.some(o2 => o1.employee[0] === o2.employee[0]));
            diffArr3 = dataArr.filter(o1 => !diffArr1.some(o2 => o1.employee[0] === o2.employee[0]));
            console.log("diffArr11::",diffArr1)
            // console.log("diffArr22::",diffArr2)
            console.log("diffArr33::",diffArr3)
            blankArr = [...diffArr3,...diffArr1]
            // diffArr = differenceBy(dataArr,result,'id')
          }else if(dataArr.length>0 && dataArr.length < result.length){
            // diffArr = result.filter(o1 => !dataArr.some(o2 => o1.employee[0] === o2.employee[0]));
            diffArr1 = result.filter(o1 => !dataArr.some(o2 => o1.employee[0] === o2.employee[0]));
            diffArr2 = dataArr.filter(o1 => !result.some(o2 => o1.employee[0] === o2.employee[0]));
            diffArr3 = dataArr.filter(o1 => !diffArr1.some(o2 => o1.employee[0] === o2.employee[0]));
            // diffArr = differenceBy(result,dataArr,'id')
            console.log("diffArr11::",diffArr1)
            console.log("diffArr22::",diffArr2)
            console.log("diffArr33::",diffArr3)
            blankArr = diffArr3
          }else if (dataArr.length===0){
            blankArr = result
          }else if(dataArr.length>0 && dataArr.length == result.length){
            diffArr1 = result.filter(o1 => !dataArr.some(o2 => o1.employee[0] === o2.employee[0]));
            diffArr2 = dataArr.filter(o1 => !result.some(o2 => o1.employee[0] === o2.employee[0]));
            diffArr3 = result.filter(o1 => !diffArr1.some(o2 => o1.employee[0] === o2.employee[0]));
            console.log("diffArr11::",diffArr1)
            console.log("diffArr22::",diffArr2)
            console.log("diffArr33::",diffArr3)
            blankArr = [...diffArr3,...diffArr2]
          }

          
          console.log("diffArr::",blankArr)
          setRecords(blankArr);
          console.log('final result openNotifId::::', result);
        }
      });
  }
  console.log("RecordsArr 2::", RecordsArr)

  
  
  return (
    <div>

      <Button
        size='small'
        style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 5, marginLeft: 12, padding: 12 }}
        //onClick={() => history.goBack()}
        onClick={() => goToPrevious()}
      >
        Back
      </Button>

      <h4 style={{ padding: 10, textAlign: 'left' }}>Test Event Details</h4>
      {Object.keys(obj).length > 0 
      && RecordsArr.length > 0
      ?
        <div>
          <TestDetails protValue={obj} 
          // getOpenNotifId ={getOpenNotifId}
           RecordsArr={RecordsArr} 
          SelectedEmpRecordsDuplicateVal={SelectedEmpRecordsDuplicateVal}
          />
          {(obj["urgency_level"] == 'Screening' || obj["urgency_level"] == 'Background Screening') &&
            <h4 style={{ padding: 10, textAlign: 'left' }}>Employee Details</h4>
          }

          {RecordsArr.length > 0 && (obj["urgency_level"] == 'Screening'|| obj["urgency_level"] == 'Background Screening') &&
            <Table
              columns={(obj["urgency_level"] == 'Screening' || obj["urgency_level"] == 'Background Screening') && columnsNew
                // ||
                // (obj["urgency_level"] == 'vaccination' && columnsNewVaccination) ||
                // ((obj["urgency_level"] == 'antigen' || obj["urgency_level"] == 'rtpcr') && columnsAntigenandrtpcr)
              }

              dataSource={RecordsArr}
            //onChange={onChange} 

            />
          }
          {/* <Row>
                    <Col span={10}>
                    </Col>
                    <Col span={8}>
                        <Space size="middle" style={{ marginTop: 0 }}>

                            <Spin size="large" />
                        </Space>
                    </Col>
                </Row>
                } */}
        </div>
        :
        RecordsArr.length == 0 && responseTrueVal ?
        <div>
          <TestDetails protValue={obj} RecordsArr={RecordsArr} RecordArrTrue={responseTrueVal}/>
        </div>
        :
        <Row>
          <Col span={10}>
          </Col>
          <Col span={8}>
            <Space size="middle" style={{ marginTop: 10 }}>

              <Spin size="large" />
            </Space>
          </Col>
        </Row>
      }
      <Modal
        width={1000}
        title='Documents Preview'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls &&
          imageUrls.length > 0 &&
          imageUrls.map((img) => {
            console.log('img :: ', img);
            return (
              <p>
                {img.fileType && img.fileType == 'application/pdf' ? (
                  <embed src={img.url} width='800px' height='2100px' />
                ) : (
                  <img src={img.url} />
                )}
              </p>
            );
          })}
      </Modal>

    </div>

  )

}

export default EditTestingDetails;