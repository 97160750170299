import React, { useEffect } from 'react';
import { Form } from 'antd';
import _ from 'underscore';

import { useHistory } from 'react-router-dom';
const AppointmentTab = (propsData) => {
  const history = useHistory();
  useEffect(() => {
    if (propsData == undefined) {
      history.goBack();
    }
  }, []);
  let propsValue = propsData.propsData;
  const {
    walk_in_welcome,
    drug_alcohol_tests_only,
    sun_appointment,
    sun_detail,
    physicals,
    sat_appointment,
    sat_detail,
    hazwoper_physicals
  } = propsValue || {};
  return (
    <>
      <Form layout='vertical' style={{ padding: 20, borderRadius: 20, backgroundColor: '#fff' }}>
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '77%', fontWeight: 900 }}>Walk-Ins Welcome</span>
              <span style={{ flexBasis: '37%', paddingLeft: 15 }}>
                {walk_in_welcome ? walk_in_welcome : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '77%', fontWeight: 900 }}>Drug & Alcohol Tests Only</span>
              <span style={{ flexBasis: '37%', paddingLeft: 15 }}>
                {drug_alcohol_tests_only ? drug_alcohol_tests_only : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{paddingLeft:25}}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '53%', fontWeight: 900 }}>Physicals</span>
              <span style={{ flexBasis: '37%', paddingLeft: 15 }}>
                {physicals ? physicals : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '53%', fontWeight: 900 }}>Hazwoper Physicals</span>
              <span style={{ flexBasis: '37%', paddingLeft: 15 }}>
                {hazwoper_physicals ? hazwoper_physicals : ''}
              </span>
            </p>
          </div>
        </div>
        <br />
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <h6></h6>
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '77%', fontWeight: 900 }}>Sat Appointment</span>
              <span style={{ flexBasis: '37%', paddingLeft: 15 }}>
                {sat_appointment ? sat_appointment : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '77%', fontWeight: 900 }}>Sat Details</span>
              <span style={{ flexBasis: '37%', paddingLeft: 15 }}>
                {sat_detail ? sat_detail : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div>
          <div className='col-md-5 col-sm-5'></div> */}
        </div>
        <br />
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}

          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '77%', fontWeight: 900 }}>Sun Appointment</span>
              <span style={{ flexBasis: '37%', paddingLeft: 15 }}>
                {sun_appointment ? sun_appointment : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '77%', fontWeight: 900 }}>Sun Details</span>
              <span style={{ flexBasis: '37%', paddingLeft: 15 }}>
                {sun_detail ? sun_detail : ''}
              </span>
            </p>
          </div>

          <div className='col-md-1 col-sm-1'></div>
          <div className='col-md-5 col-sm-5'></div>
        </div>
      </Form>
    </>
  );
};

export default AppointmentTab;
