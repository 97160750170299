import React, { useState } from 'react';
import BackgroundImage from '../../assets/img/Background-image.jpg';
import './Checkin.css';
import LogoNew from '../../assets/img/logonew.png';
import { useHistory } from 'react-router-dom';
import { date } from 'yup';

const Checkin = () => {
  const histroy = useHistory();
  let currentDate = new Date();
  let formattedDateForParams = currentDate.toISOString().slice(0, 19).replace('T', ' ');  
  // let formattedDateForInput = currentDate.toLocaleString('en-US', {
  //   month: 'numeric',
  //   day: '2-digit',
  //   year: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric',
  //   hour12: true
  // });

  const [values, setValues] = useState({
    // dateInput: formattedDateForInput,
    dateParams: formattedDateForParams
  });

  const pickupValues = () => {
    setValues(values);
    console.log(values, 'values');
  };

  const pageChange = () => {
    pickupValues();
    histroy.push({ pathname: '/public/checkdetails', state: values });
  };

  return (
    <>
      <img className='img' style={{ opacity: 0.6}} src={BackgroundImage} alt='background-image' />
      <div className='container checkin'>
      <img className='logoNew mt-5 mb-5' src={LogoNew} alt="logo"/>
        <div className="card mb-5 my-5">
          <h5>Read the following Instructions Carefully :</h5>
          <ol>
            <li>
              &nbsp; Click the "Start Check-In" button to begin the process.
            </li>
            <li>
            &nbsp; Enter required information and update if necessary.
            </li>
            <li>
            &nbsp; Complete required fields for the check-in process.
            </li>
            <li>
            &nbsp;  Click "Submit" to confirm check-in.
            </li>
          </ol>
          
        </div>
        <button type='submit' className='btn mt-5' onClick={() => pageChange()}>
            Check - In
          </button>
      </div>
    </>
  );
};

export default Checkin;
