import React, { useEffect } from 'react';
import { Form } from 'antd';
import _ from 'underscore';
import { useHistory } from 'react-router-dom';
const ClinicGeneralInfo = (propsData) => {
  const history = useHistory();
  useEffect(() => {
    if (propsData == undefined) {
      history.goBack();
    }
  }, []);
  let propsValues = propsData && propsData.propsData;
  const { clinic_manager_name, clinic_manager_email } = propsValues || {};
  return (
    <>
      <Form layout='vertical' style={{ padding: 20, borderRadius: 20, backgroundColor: '#fff' }}>
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '35%', fontWeight: 900 }}> CLINIC Manager Name</span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {' '}
                {clinic_manager_name ? clinic_manager_name : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '35%', fontWeight: 900 }}> CLINIC Manager Email</span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {' '}
                {clinic_manager_email ? clinic_manager_email : ''}
              </span>
            </p>
          </div>

          <div className='col-md-1 col-sm-1'></div>
          <div className='col-md-5 col-sm-5'></div>
        </div>
      </Form>
    </>
  );
};

export default ClinicGeneralInfo;
