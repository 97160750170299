import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  Space,
  Row,
  Col,
  notification,
  Modal,
  Input,
  Tooltip,
  Table,
  Button,
  Collapse,
  Select
} from 'antd';
import { history } from './../../redux/store';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting';
import * as wjcCore from '@grapecity/wijmo';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import * as wjFilter from '@grapecity/wijmo.react.grid.filter';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import '@grapecity/wijmo.styles/wijmo.css';

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'pool_config.xlsx',
      'PoolConfig.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const { Panel } = Collapse;
const Random_testing_pools = () => {
  const [state, setState] = useState(true);
  const [data, setData] = useState([]);
  const [change, setChange] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [prgFilter, setPrgFilter] = useState([]);
  const [nameFilter, setNameFilter] = useState([]);
  const [modeFilter, setModeFilter] = useState([]);
  const [LoadMoreVal, setLoadMore] = useState(1);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [poolNameValue, setPoolNameValue] = useState('');
  const [programValue, setProgramValue] = useState('');
  const [modeValue, setModeValue] = useState('');
  const [tableLoaderVal, settableLoaderVal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);

  //for wijmo table

  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [pagging, setPagging] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [pageApply, setPageApply] = useState(false);

  const theGrid = useRef();
  const fixedContainerRef = useRef(null);
  const theSearch = useRef();

  useEffect(() => {
    console.log('this.props customerId 1', JSON.parse(localStorage.getItem('customerData')));
    //getPoolData();
    getPoolData(LoadMoreVal);
  }, []);

  const handleShowInfo = (patient) => {
    console.log('patient', patient);
    history.push({ pathname: `/vertical/randomPoolDetails`, state: patient });
  };

  const actions = (patient) => (
    <div className='buttons-list nowrap'>
      <Tooltip title='Pools Config Info'>
        <Button
          shape='circle'
          onClick={() => handleShowInfo(patient)}
          danger
          style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            color: 'rgb(239,103,39)'
          }}
        >
          <span
            className='icofont icofont-info-circle'
            style={{ fontSize: 19, cursor: 'pointer' }}
          />
        </Button>
      </Tooltip>
    </div>
  );
  const columns = [
    {
      key: 'pool_name',
      dataIndex: 'pool_name',
      sorter: (a, b) => a.pool_name.length - b.pool_name.length,
      title: 'Pool Name',
      render: (pool_name) => <span>{pool_name}</span>,
      filters: Object.keys(nameFilter).map((element) => {
        return { text: element, value: element };
      }),
      onFilter: (value, record) => record.pool_name === value,
      filterSearch: true
    },

    {
      title: 'DOT Program',
      key: 'program',
      dataIndex: 'program',
      sorter: (a, b) => a.program.length - b.program.length,
      render: (program) => <span className='nowrap'>{program}</span>,
      filters: Object.keys(prgFilter).map((element) => {
        return { text: element, value: element };
      }),
      onFilter: (value, record) => record.program === value,
      filterSearch: true
    },
    {
      title: 'DOT Mode',
      key: 'Mode',
      dataIndex: 'Mode',
      sorter: (a, b) => a.Mode.length - b.Mode.length,
      render: (Mode) => <span className='nowrap'>{Mode}</span>,
      filters: Object.keys(modeFilter).map((element) => {
        return { text: element, value: element };
      }),
      onFilter: (value, record) => record.Mode === value,
      filterSearch: true
    },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }
  ];

  async function getPoolData(LoadMoreVal, resetFilter = false) {
    i = 1;
    settableLoaderVal(true);
    setState(false);
    setChange(true);
    setIsLoading(true);
    if (LoadMoreVal !== 1) {
      setIsLoadMoreLoading(true);
    }
    console.log('getPoolData :: ');

    await axios
      .post(setting.serverUrl + '/api/getPoolCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: JSON.parse(localStorage.getItem('customerData')).id,
          LoadMoreVal: LoadMoreVal,
          poolNameValue: resetFilter ? '' : poolNameValue,
          programValue: resetFilter ? '' : programValue,
          modeValue: resetFilter ? '' : modeValue
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response.status == '204') {
          console.log('customer pool data blocked: ', response.data);
          setNoRecordFound(true);
        } else {
          setChange(true);
          console.log('Respone from getData App tsx ', response.data);
          if (response && response.data.error) {
            alert('Some Technical Error Occurred!');
          }
          if (response && response.status == 200) {
            settableLoaderVal(false);

            console.log('customer pool data', response.data);
            let alldata = response.data;
            console.log(
              'pooldatSorted',
              alldata.map((element) => element.__last_update)
            );
            alldata.sort((a, b) => {
              let date1 = new Date(b.__last_update);
              let date2 = new Date(a.__last_update);
              return date1 > date2 ? 1 : -1;
            });
            // let displayName = localStorage.getItem('displayName');
            // let poolResult = result.filter((result) => result.display_name === displayName).map((record) => {
            //   return {
            //     poolName:record.pool_name,
            //     company:record.display_name,
            //     companyType: record.company_type,
            //     program:record.program,
            //     mode: record.Mode,
            //     selection_period:record.selection_period,
            //     completed_drug_test_year_date:record.completed_drug_test_year_date,
            //     completed_alcohol_test_year_date:record.completed_alcohol_test_year_date,
            //     average_no_of_employees:record.average_no_of_employees,
            //   }
            // })
            let prgNames = {};
            alldata
              .filter((x) => x.program && x.program.length > 0 && x.program != false)
              .map((data) => (prgNames[data.program] = 'here'));
            setPrgFilter(prgNames);
            let poolNames = {};
            alldata
              .filter((x) => x.pool_name && x.pool_name.length > 0 && x.pool_name != false)
              .map((data) => (poolNames[data.pool_name] = 'here'));
            setNameFilter(poolNames);
            let modeNames = {};
            alldata
              .filter((x) => x.Mode && x.Mode.length > 0 && x.Mode != false)
              .map((data) => (modeNames[data.Mode] = 'here'));
            setModeFilter(modeNames);

            console.log('customer pool data', alldata);
            let finalValues = alldata.map((k) => {
              return {
                ...k,
                newMode: k.Mode != false ? k.Mode : ''
              };
            });
            setData(finalValues);

            setFilterData(finalValues);
            setWijmoFlexGrid(finalValues, 10);
            setNoRecordFound(false);
            setHasPermission(true);

            //setErrMsg('Module not activated.');
          } else if (response.status === 201) {
            setNoRecordFound(true);
            setNoRecordMessage('Access permission not allowed.');
            setErrMsg('Access permission not allowed.');
          } else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
          }
        }
      });
  }
  // async function getPoolData() {
  //   settableLoaderVal(true);
  //   setState(false);
  //   setChange(true);
  //   setIsLoading(true);
  //   console.log('getPoolData :: ');

  //   await axios
  //     .post(setting.serverUrl + '/api/getNewRandomPoolList', {
  //       params: {
  //         email: localStorage.getItem('myData'),
  //         user: localStorage.getItem('myData'),
  //         pass: localStorage.getItem('myPass'),
  //         custId: JSON.parse(localStorage.getItem('customerData')).id,
  //         // LoadMoreVal: LoadMoreVal,
  //         // poolNameValue: resetFilter ? '' :poolNameValue,
  //         // programValue: resetFilter ? '' : programValue,
  //         // modeValue:resetFilter ? '' : modeValue
  //       }
  //     })
  //     .then((response) => {
  //       setIsLoading(false);
  //       if (response.status == '204') {
  //         console.log('customer pool data blocked: ', response.data);
  //         setNoRecordFound(true);
  //       } else {
  //         setChange(true);
  //         console.log('Respone from getData App tsx ', response.data);
  //         if (response && response.data.error) {
  //           alert('Some Technical Error Occurred!');
  //         }
  //         if (response && response.status == 200) {
  //           settableLoaderVal(false);

  //           console.log('customer pool data', response.data);
  //           let alldata = response.data;
  //           console.log(
  //             'pooldatSorted',
  //             alldata.map((element) => element.__last_update)
  //           );
  //           alldata.sort((a, b) => {
  //             let date1 = new Date(b.__last_update);
  //             let date2 = new Date(a.__last_update);
  //             return date1 > date2 ? 1 : -1;
  //           });
  //           // let displayName = localStorage.getItem('displayName');
  //           // let poolResult = result.filter((result) => result.display_name === displayName).map((record) => {
  //           //   return {
  //           //     poolName:record.pool_name,
  //           //     company:record.display_name,
  //           //     companyType: record.company_type,
  //           //     program:record.program,
  //           //     mode: record.Mode,
  //           //     selection_period:record.selection_period,
  //           //     completed_drug_test_year_date:record.completed_drug_test_year_date,
  //           //     completed_alcohol_test_year_date:record.completed_alcohol_test_year_date,
  //           //     average_no_of_employees:record.average_no_of_employees,
  //           //   }
  //           // })
  //           let prgNames = {};
  //           alldata.filter((x)=>x.program && x.program.length > 0 &&  x.program != false).map((data) => (prgNames[data.program] = 'here'));
  //           setPrgFilter(prgNames);
  //           let poolNames = {};
  //           alldata.filter((x)=>x.pool_name && x.pool_name.length > 0 &&  x.pool_name != false).map((data) => (poolNames[data.pool_name] = 'here'));
  //           setNameFilter(poolNames);
  //           let modeNames = {};
  //           alldata.filter((x)=>x.Mode && x.Mode.length > 0 &&  x.Mode != false).map((data) => (modeNames[data.Mode] = 'here'));
  //           setModeFilter(modeNames);
  //           setData(alldata);
  //           setFilterData(alldata);
  //           console.log('customer pool data', alldata);
  //           setWijmoFlexGrid(alldata,10);
  //           setNoRecordFound(false);
  //           setHasPermission(true);

  //           //setErrMsg('Module not activated.');
  //         } else if (response.status === 201) {
  //           setNoRecordFound(true);
  //           setNoRecordMessage('Access permission not allowed.');
  //           setErrMsg('Access permission not allowed.');
  //         } else {
  //           notification.error({
  //             message: 'Failed to retrieve data at the moment. Please try again in a bit.'
  //           });
  //         }
  //       }
  //     });
  // }
  // const search = (e) => {
  //   if (e.target.value.length > 0) {

  //     console.log("e", e.target.value, data);

  //     let datArr = data.filter(val =>
  //       val.pool_name && val.pool_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
  //       val.program && val.program.toLowerCase().includes(e.target.value.toLowerCase())||
  //       val.Mode && val.Mode.toLowerCase().includes(e.target.value.toLowerCase())
  //     );
  //     console.log("datArr", datArr);
  //     setFilterData(datArr);

  //   } else {
  //     let allResult=data;
  //     setFilterData(allResult);

  //   }

  // }
  const showTotal = (total) => {
    return <p>Total {total} items</p>;
  };

  const showAdvanceFilterModal = () => {
    console.log('insdie advance filter');
    setIsAdvanceFilterModalOpen(true);
  };

  const handleAdvanceFilterCancel = () => {
    setIsAdvanceFilterModalOpen(false);
  };

  const handleAdvanceFilter = () => {
    // setCurrentPageNumber(1);
    setIsAdvanceFilterModalOpen(false);
    getPoolData(1);
  };

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }

  const paggination = (value) => {
    console.log('page value', value);
    setItemPerPage(value);
    setPageApply(true);
    // setPagging(value);
    // this.setState({ pagging: e.target.value})
    setTimeout(() => {
      setWijmoFlexGrid(filterData, value);
    }, 500);
  };
  const setWijmoFlexGrid = (data, page) => {
    console.log('pagging', data, page);
    let views = new wijmo.CollectionView(data, { pageSize: Number(page) });
    let viewsData = new wijmo.CollectionView(data, { pageSize: Number(data && data.length) });
    setView(views);
    setViewData(viewsData);
    setViewIndex(views.pageIndex + 1);
    setViewCount(views.pageCount);
    setIsLoadMoreLoading(false);
    setTimeout(() => {
      setPagginationNumRecord({
        currentRecord: document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
        totalRecord: data.length
      });
    }, 2000);
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    // var self = this;
    // console.log("inside formatitem",e.panel)
    // console.log("sender.topLeftCells",sender.topLeftCells)
    // console.log("aaaaaaaaaaa",sender.topLeftCells===e.panel)
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
    col = flex.columns[e.col],
    row = flex.rows[e.row];
    if(row){
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    // var filter = new wjcGridFilter.FlexGridFilter(flexes);
    // console.log("filter",filter)
    // filter.getColumnFilter('create_date').valueFilter.maxValues = 3000;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showDotmode = (item) => {
    //  console.log("items items",item.item)
    if (item.item.mode === false) {
      return <span className='nowrap'></span>;
    } else {
      return <span className='nowrap'>{item.item.mode}</span>;
    }
  };
  const showActions = (item) => {
    return (
      <Button
        shape='circle'
        onClick={() => handleShowInfo(item.item)}
        danger
        style={{
          background: 'none',
          border: 'none',
          boxShadow: 'none',
          color: 'rgb(239,103,39)',
          margin:'-12px 0px'
        }}
      >
        <span className='icofont icofont-info-circle' style={{ fontSize: 16, cursor: 'pointer' }} />
      </Button>
    );
  };

  const updateViewPager = (target) => {
    console.log('target', target);
    let btn = wijmo.closest(target, 'button'),
      id = btn ? btn.id : '';
    switch (id) {
      case 'btnFirst':
        i = 1;
        view.moveToFirstPage();
        setTimeout(() => {
          setPagginationNumRecord({
            currentRecord:
              document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
            totalRecord: filterData.length
          });
        }, 1000);
        break;
      case 'btnPrev':
        if (i <= 1) {
          i = 1;
        } else {
          var a = Math.ceil(filterData.length / itemsPerPage);
          view.moveToPreviousPage();
          if (a === i) {
            setPagginationNumRecord({
              currentRecord:
                filterData.length -
                (document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1),
              totalRecord: filterData.length
            });
          } else {
            setPagginationNumRecord({
              currentRecord: pagginationNumRecord.currentRecord - itemsPerPage,
              totalRecord: filterData.length
            });
          }
          i--;
        }
        break;
      case 'btnNext':
        if (pageApply) {
          if (
            filterData.length ===
            document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1
          ) {
          } else {
            i = 2;
          }
          setPageApply(false);
        } else {
          if (i >= Math.ceil(filterData.length / itemsPerPage)) {
          } else {
            i++;
          }
        }
        var a = Math.ceil(filterData.length / itemsPerPage);
        if (a === i) {
          setPagginationNumRecord({
            currentRecord: filterData.length,
            totalRecord: filterData.length
          });
        } else {
          setPagginationNumRecord({
            currentRecord:
              (document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1) * i,
            totalRecord: filterData.length
          });
        }
        view.moveToNextPage();
        break;
      case 'btnLast':
        i = Math.ceil(filterData.length / itemsPerPage);
        view.moveToLastPage();
        setPagginationNumRecord({
          currentRecord: filterData.length,
          totalRecord: filterData.length
        });
        break;
    }
    setViewIndex(view.pageIndex + 1);
    setViewCount(view.pageCount);
  };

  return hasPermission ? (
    <div style={{ padding: 20, marginTop: 10 }}>
      <Row>
        <Col
          xl={{ span: 6 }}
          lg={{ span: 6 }}
          md={{ span: 9 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ padding: 10, textAlign: 'left', fontWeight: 500, fontSize: 27 }}
        >
          <h3 style={{ color: '#1C3A6A' }}>Pool Config</h3>
        </Col>
        <Col
          xl={{ span: 9 }}
          lg={{ span: 8 }}
          md={{ span: 4 }}
          sm={{ span: 0 }}
          xs={{ span: 0 }}
        ></Col>
        {/* <Col span={9}>
          {/* <Input
            placeholder='Type to search'
            suffix={<span className='icofont icofont-search' />}
            style={{ width: '60%', float: 'right', marginTop: 25, backgroundColor: 'ffff' }}
            onChange={(e) => search(e)}
          /> 
        </Col> */}

        {filterData && filterData.length > 0 ? (
          <Col
            xl={{ span: 2 }}
            lg={{ span: 3 }}
            md={{ span: 3 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <Button
              size='small'
              style={{ backgroundColor: '#247', color: 'white', border: 'none', marginTop: 30 }}
              onClick={showAdvanceFilterModal}
            >
              Advanced Search Filter
            </Button>
            <Modal
              width={1000}
              title='Advanced Search Filter'
              visible={isAdvanceFilterModalOpen}
              onCancel={handleAdvanceFilterCancel}
              footer={[
                <Button
                  size='small'
                  style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                  onClick={() => handleAdvanceFilter()}
                >
                  Search
                </Button>
              ]}
            >
              <Row gutter={[24, 24]}>
                <Col md={{ span: 8 }}>
                  <Input
                    value={poolNameValue}
                    placeholder='Pool Name :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setPoolNameValue(event.target.value)}
                  />
                </Col>

                <Col md={{ span: 8 }}>
                  <Input
                    value={programValue}
                    placeholder='DOT Program :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setProgramValue(event.target.value)}
                  />
                </Col>

                <Col md={{ span: 8 }}>
                  <Input
                    value={modeValue}
                    placeholder='DOT Mode :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setModeValue(event.target.value)}
                  />
                </Col>

                {/* <Col md={{span:6}}>
                <Button
                  size='small'
                  style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                  onClick={() => handleAdvanceFilter()}
                >
                  Search
                </Button>
              </Col> */}
              </Row>
            </Modal>
          </Col>
        ) : (
          ''
        )}

        <Col
          xl={{ span: 2 }}
          lg={{ span: 2 }}
          md={{ span: 2 }}
          sm={{ span: 0 }}
          xs={{ span: 0 }}
        ></Col>

        {filterData && filterData.length > 0 ? (
          <Col xl={{ span: 2 }} lg={{ span: 2 }} md={{ span: 2 }} sm={{ span: 5 }} xs={{ span: 5 }}>
            <Button
              size='small'
              style={{ marginTop: 30, backgroundColor: '#247', border: 'none' }}
              onClick={() => {
                setIsLoading(true);
                setProgramValue('');
                setModeValue('');
                setPoolNameValue('');
                getPoolData(1, true);
              }}
            >
              Reset Filter
            </Button>
          </Col>
        ) : (
          ''
        )}
        <Col
          xl={{ span: 1 }}
          lg={{ span: 2 }}
          md={{ span: 2 }}
          sm={{ span: 0 }}
          xs={{ span: 2 }}
        ></Col>

        {filterData && filterData.length > 0 ? (
          <Col xl={{ span: 2 }} lg={{ span: 2 }} md={{ span: 2 }} sm={{ span: 5 }} xs={{ span: 3 }}>
            <Button
              size='small'
              style={{ backgroundColor: '#247', color: 'white', border: 'none', marginTop: 30 }}
              onClick={() => {
                //  let LoadMoreVal = {LoadMoreVal + 1;
                setLoadMore(LoadMoreVal + 1);

                getPoolData(LoadMoreVal + 1);
              }}
            >
              {isLoadMoreLoading ? (
                <Space size='middle'>
                  <Spin size='small' />
                </Space>
              ) : (
                ' Load More'
              )}
            </Button>
          </Col>
        ) : (
          ''
        )}
      </Row>
      {/* <br /> */}
      {/* <Row>
        <Col>
          <Collapse accordion >
            <Panel
              
              style={{ borderRadius: 5, color: 'white', background: 'rgb(61 88 106)' }}
              className='ant-collapse-header'
              header={
                <span
                  style={{
                    fontSize: '110%',
                    fontWeight: 700,
                    width: '1100px',
                    display: 'block',
                    color: 'white'
                  }}
                >
                  Advanced Search Filter
                </span>
              }
              key='1'
            >
              <Row gutter={[24,24]}>
                <Col md={{span:6}}>
                  <Input
                    value={poolNameValue}
                    placeholder='Pool Name :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setPoolNameValue(event.target.value)}
                  />
                </Col>
                
                <Col md={{span:6}}>
                  <Input
                    value={programValue}
                    placeholder='DOT Program :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setProgramValue(event.target.value)}
                  />
                </Col>
                
                <Col md={{span:6}}>
                  <Input
                    value={modeValue}
                    placeholder='DOT Mode :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setModeValue(event.target.value)}
                  />
                </Col>
                
                <Col md={{span:6}}>
                  <Button
                    size='small'
                    style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                    onClick={() => handleAdvanceFilter()}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row> */}
      {/* <br /> */}

      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      {!noRecordFound ? (
        change && filterData.length > 0 ? (
          <div className='row' style={{ marginTop: 10 }}>
            <div className='col-12 col-md-12 col-xl-12'>
              {/* <Table
                loading={tableLoaderVal}
                dataSource={filterData}
                columns={columns}
                type='none'
                pagination={{
                  pageSize: 25,
                  hideOnSinglePage: false,
                  showSizeChanger: false,
                  total: filterData.length,
                  showTotal: showTotal
                }}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: event => {
                //       console.log("record",record);
                //       history.push({ pathname: `/vertical/testingDetails`, state:record });
                //     }
                //   }
                // }}
              /> */}
            </div>

            <Row gutter={[32, 32]} style={{ width: '100%' }}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div ref={fixedContainerRef}>
                  <Select
                    //showSearch
                    style={{ width: 160 }}
                    placeholder={`Items per page: ${pagging}`}
                    //optionFilterProp='children'
                    onChange={paggination}
                    options={[
                      { label: 10, value: 10 },
                      { label: 20, value: 20 },
                      { label: 50, value: 50 }
                    ]}
                    getPopupContainer={() => fixedContainerRef.current}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>

            {/* to export entire data of table */}
            <FlexGrid
              ref={theGrid}
              style={{ display: 'none' }}
              className='dummyWijmoData'
              itemsSource={viewData}
              initialized={gridInitializedData}
              formatItem={formatItem}
              isReadOnly={true}
            >
              <FlexGridColumn binding='pool_name' header='Pool Name' minWidth={140} width='*' />
              <FlexGridColumn binding='program' header='DOT Program' minWidth={120} width='*' />
              <FlexGridColumn binding='newMode' header='DOT Mode' minWidth={120} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showDotmode} width='*' />
              </FlexGridColumn>
            </FlexGrid>

            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              className="cliniclisttable"
              alternatingRowStep={1}
              // autoRowHeights={true}
              itemsSource={view}
              initialized={gridInitialized}
              formatItem={formatItem}
              itemsPerPage={10}
              isReadOnly={true}
            >
              <div style={{ display: 'none' }}>
                <wjInput.ListBox
                  className='column-picker'
                  itemsSource={listboxColumns}
                  checkedMemberPath='visible'
                  displayMemberPath='header'
                  lostFocus={hidePicker}
                  initialized={initializedListBox}
                ></wjInput.ListBox>
              </div>
              <FlexGridColumn binding='pool_name' header='Pool Name' minWidth={140} width='*' />
              <FlexGridColumn binding='program' header='DOT Program' minWidth={120} width='*' />
              <FlexGridColumn binding='newMode' header='DOT Mode' minWidth={120} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showDotmode} width='*' />
              </FlexGridColumn>
              <FlexGridColumn binding='actions' header='Actions' minWidth={120} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showActions} />
              </FlexGridColumn>

              <FlexGridFilter filterColumns={['pool_name', 'program', 'newMode']} />
            </FlexGrid>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                onClick={(e) => updateViewPager(e.target)}
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  // paddingTop: '4px',
                  height: '30px'
                }}
              >
                <button
                  id='btnFirst'
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  className='btn'
                >
                  <span className='wj-glyph-step-backward'></span>
                </button>
                <button
                  id='btnPrev'
                  className='btn'
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                >
                  <span className='wj-glyph-left'></span>
                </button>
                <p style={{ display: 'inline' }}>
                  &nbsp;&nbsp;&nbsp;Page {viewIndex} of {viewCount}&nbsp;&nbsp;&nbsp;
                </p>
                <button
                  id='btnNext'
                  className='btn'
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                >
                  <span className='wj-glyph-right'></span>
                </button>
                <button
                  id='btnLast'
                  className='btn'
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                >
                  <span className='wj-glyph-step-forward'></span>
                </button>
              </div>
              {pagginationNumRecord.currentRecord !== undefined ? (
                <p style={{ fontWeight: 'bold' }}>
                  {' '}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {`${pagginationNumRecord.currentRecord} out of ${pagginationNumRecord.totalRecord}`}
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
            )}
          </>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className="icofont-exclamation-tringle" style={{color:'red',fontSize:20}}></i>&nbsp;&nbsp; */}
        {errMsg}
      </p>
    </div>
  );
};

export default Random_testing_pools;
