import React, { Component } from 'react';
import axios from 'axios';
import _ from 'underscore';
import setting from '../../config/setting';
import { Spin, Space, Row, Col, Input, Tooltip, Table, Button } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { history } from '../../redux/store';

class LocationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custLocs: [],
      selectedCustomerList: ['All'],
      change: false,
      filterData: [],
      Loader: false,
      Count: [],
      companies: [],
      selectedCompany: '',
      loaderVal: false,
      locationBKP: [],
      totalRecords: 0,
      currentPageNumber: 1,
      companyIds: [],
      pageLimit: 25,
      searchText: ''
    };

    this.columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        render: (name) => (
          <span className='nowrap'>
            {name}
          </span>
        )
      },
      {
        key: 'Address',
        dataIndex: 'contact_address',
        title: 'Contact Address',
        render: (contact_address) => <span>{contact_address}</span>
      },
      {
        title: 'Phone Number',
        key: 'mobile',
        dataIndex: 'mobile',
        render: (mobile) => (
          <span className='nowrap'>
            
              {mobile && <i class='icofont-phone'></i>}&nbsp;{mobile}
          
          </span>
        )
      },

      {
        title: 'Contact Person',
        key: 'contact1_emp',
        dataIndex: 'contact1_emp',
        render: (contact1_emp) => (
          <span className='nowrap'>
            {contact1_emp == false ? '' : contact1_emp[1].split(',')[1]}
          </span>
        )
      }
    ];
  }

  componentDidMount() {
    this.setState({ change: false });
    this.fetchCompanyData();
  }

  fetchCompanyData = () => {
    const companyId = parseInt(localStorage.getItem('customerId'));
    this.getCustomerLocation([companyId]);
    this.getCustomerLocationCount([companyId]);
  };

  getCustomerLocation = (companyIds) => {
    console.log('inside getCustomerLocation:::');
    axios
      .post(setting.serverUrl + '/api/getCustomerLocationCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyIds, //this.state.companyIds,
          limit: this.state.pageLimit * this.state.currentPageNumber,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        console.log('Respone from getCustomerLocation ', response.data);

        this.setState({
          custLocs: response.data,
          filterData: response.data,
          locationBKP: response.data,
          loaderVal: false,
          change: true
        });
      });
  };

  getCustomerLocationCount = (companyIds) => {
    console.log('inside getCustomerLocationCount:::');
    axios
      .post(setting.serverUrl + '/api/getCustomerLocationCount', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyIds,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        console.log('Respone from getCustomerLocationCount::::::', response.data);

        this.setState({ totalRecords: response.data.value });
        let total = this.state.totalRecords;
        console.log('totalRecords-----', total);

        this.setState({ change: true });
      });
  };

  search = (value) => {
    if (value.length > 0) {
      console.log('e', value, this.state.custLocs);

      let datArr = this.state.custLocs.filter(
        (val) =>
          (val.name && val.name.toLowerCase().includes(value.toLowerCase())) ||
          (val.commercial_company_name &&
            val.commercial_company_name.toLowerCase().includes(value.toLowerCase())) ||
          (val.mobile && val.mobile.includes(value)) ||
          (val.contact_address && val.contact_address.toLowerCase().includes(value.toLowerCase()))
      );
      console.log('datArr', datArr);
      this.setState({ filterData: datArr, searchText: value });
    } else {
      this.setState({ filterData: this.state.custLocs, searchText: value });
    }
  };

  showTotal = (total) => {
    return <p>Total {total} items</p>;
  };

  handleLoadMore = () => {
    this.setState(
      { currentPageNumber: this.state.currentPageNumber + 1, loaderVal: true },
      function () {
        this.fetchCompanyData();
      }
    );
  };

  handleReset() {
    this.setState(
      {
        Value: '',
        lastNameValue: '',
        currentPageNumber: 1
      },
      () => {
        this.search('');
      }
    );
  }
  addLocation = () => {
    console.log('addLocation');

    history.push({ pathname: `/vertical/addLocation`, state: {} });
  };

  shouldShowLoadMore = () => {
    return this.state.currentPageNumber * this.state.pageLimit <= this.state.totalRecords;
  };
  render() {
    console.log('this.state', this.state);

    return (
      <div style={{ padding: 20 }}>
        <Row>
          <Col span={5}>
            <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Location List</h3>
          </Col>
          <Col span={1}>
            <Tooltip placement='top' title='Add mmmmmLocation'>
              <PlusCircleTwoTone
                twoToneColor="#247"
                style={{ fontSize: 25, marginTop: 40, cursor: 'pointer' }}
                onClick={this.addLocation}
              />
            </Tooltip>
          </Col>
          <Col span={7}></Col>
          <Col span={7}>
            <Input
              placeholder='Type to search'
              value={this.state.searchText}
              suffix={<span className='icofont icofont-search' />}
              style={{ width: '90%', marginTop: 35, backgroundColor: 'white' }}
              onChange={(event) => this.search(event.target.value)}
            />
          </Col>
          <Col span={2}>
            {this.shouldShowLoadMore() && (
              <Button
                size='small'
                style={{ marginTop: 40, backgroundColor: '#247', color: 'white', border:"none" }}
                onClick={() => this.handleLoadMore()}
              >
                Load More
              </Button>
            )}
          </Col>
          <Col span={2}>
            <Button
              size='small'
              style={{ marginTop: 40, backgroundColor: '#247', color: 'white', border:"none" }}
              onClick={() => this.handleReset()}
            >
              Reset Filter
            </Button>
          </Col>

         
        </Row>

        {this.state.custLocs.length > 0 && this.state.change ? (
          <>
            <Row>
              <Col style={{ fontSize: 15, fontWeight: 700 }}>
                {this.state.totalRecords > 0 ? 'Total Records : ' + this.state.totalRecords : ''}
              </Col>
            </Row>
            <br />
            <div className='row'>
              <div className='col-12 col-md-12 col-xl-12'>
                <Table
                  loading={this.state.loaderVal}
                  dataSource={this.state.filterData}
                  columns={this.columns}
                  //pageSize={this.pageLimit}
                  pagination={{
                    showSizeChanger: false,
                    pageSize: 25,
                    total: this.state.filterData.length,
                    showTotal: this.showTotal
                  }}
                />
                <br />
                {this.shouldShowLoadMore() && (
                  <Button
                    style={{ backgroundColor: '#247', color: 'white', border:"none" }}
                    onClick={this.handleLoadMore}
                  >
                    Load More
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          <Row>
            <Col span={10}></Col>
            <Col span={8}>
              <Space size='middle' style={{ marginTop: 10 }}>
                <Spin size='large' />
              </Space>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default LocationList;
