import React, { useEffect } from 'react';
import { Form } from 'antd';
import _ from 'underscore';
import { useHistory } from 'react-router-dom';
const CollectorTechTab = (propsData) => {
  let propsValues = propsData && propsData.propsData;
  const history = useHistory();
  useEffect(() => {
    if (propsData == undefined) {
      history.goBack();
    }
  }, []);
  return (
    <>
      <Form layout='vertical' style={{paddingLeft:20,marginTop:-20, borderRadius: 20, backgroundColor: '#fff' }}>
        <h5 style={{ color: '#247' }}>Collector Training</h5>

        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px',marginLeft:'-10px', paddingLeft:22 }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '69%', fontWeight: 900 }}>
                {' '}
                All CLINIC collectors have been trained and completed proficiency per DOT 49 part 40
                for urine collections
              </span>{' '}
              <span style={{ flexBasis: '35%', paddingLeft: 25, textAlign:'center' }}>
                {' '}
                {propsValues && propsValues.collector_trained_proficiency
                  ? propsValues.collector_trained_proficiency
                  : ''}{' '}
              </span>
            </p>

            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '85%', fontWeight: 900 }}>
                {' '}
                All CLINIC collectors are trained oral fluid collections
              </span>{' '}
              <span style={{ flexBasis: '31%', paddingLeft: 15 }}>
                {' '}
                {propsValues && propsValues.collector_trained_oral_fluid
                  ? propsValues.collector_trained_oral_fluid
                  : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{paddingLeft:30}}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>
                {' '}
                What type of training is used?
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 15 }}>
                {' '}
                {propsValues && propsValues.collector_trained_proficiency_text
                  ? propsValues.collector_trained_proficiency_text
                  : ''}
              </span>
            </p>
            <br />
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>
                {' '}
                What type of training is used?
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 15 }}>
                {' '}
                {propsValues && propsValues.collector_trained_oral_fluid_text
                  ? propsValues.collector_trained_oral_fluid_text
                  : ''}
              </span>
            </p>
          </div>
          <i style={{ fontWeight: 900, color: 'grey', marginTop:30, paddingLeft:11 }}>
            {' '}
            Scan all training certificates and attach to the CLINIC package.
          </i>
        </div>

        <h5 style={{ color: '#247', marginTop:20 }}>Male and Female Collectors</h5>
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px', marginLeft:'-10px' }}>
            <p style={{ display: 'flex', paddingLeft:10 }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>
                {' '}
                Are both male and female collectors available for direct observation
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 178, textAlign:'center' }}>
                {' '}
                {propsValues && propsValues.male_female_collector
                  ? propsValues.male_female_collector
                  : ''}{' '}
              </span>
            </p>
          </div>

          <div className='col-md-1 col-sm-1'></div>
          <div className='col-md-5 col-sm-5'></div>
        </div>

        <h5 style={{ color: '#247', marginTop:25}}>Technicians/Medical Assistant Training</h5>
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px', marginLeft:-10, paddingLeft:22 }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '45%', fontWeight: 900 }}>
                {' '}
                All CLINIC audiograms are completed by a tech with a OSHA training
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 160, textAlign:'center' }}>
                {' '}
                {propsValues && propsValues.clinic_audiograms ? propsValues.clinic_audiograms : ''}
              </span>
            </p>

            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>
                {' '}
                All CLINIC PFTaEs are completed by a tech with a OSHA training :
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 170, textAlign:'center' }}>
                {' '}
                {propsValues && propsValues.clinic_pftae ? propsValues.clinic_pftae : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>
                {' '}
                The CLINIC use the following machine for fitting test
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 15 }}>
                {' '}
                {propsValues && propsValues.clinic_fittingtext
                  ? propsValues.clinic_fittingtext
                  : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5'>
            <p style={{ display: 'flex', paddingLeft:20 }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>
                {' '}
                What type of training is used?
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 15 }}>
                {' '}
                {propsValues && propsValues.clinic_audiograms_text
                  ? propsValues.clinic_audiograms_text
                  : ''}
              </span>
            </p>
            <br />
            <p style={{ display: 'flex', paddingLeft:20 }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>
                {' '}
                What type of training is used?
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 15 }}>
                {' '}
                {propsValues && propsValues.clinic_pftae_text ? propsValues.clinic_pftae_text : ''}
              </span>
            </p>
          </div>
        </div>

        <h5 style={{ color: '#247',marginTop:20 }}>DOT FMCSA Medical Examiner</h5>
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px',marginLeft:'-10px', paddingLeft:'20px' }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '50%', fontWeight: 900 }}>
                {' '}
                DOT Examiners at your CLINIC currently certified under the National Registry ?
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 150, textAlign:'center' }}>
                {' '}
                {propsValues && propsValues.dot_examiners ? propsValues.dot_examiners : ''}
              </span>
            </p>
          </div>

          <div className='col-md-1 col-sm-1'></div>
          <div className='col-md-5 col-sm-5'></div>
        </div>
      </Form>
    </>
  );
};

export default CollectorTechTab;
