import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import setting from '../../../config/setting';
import {
  Spin,
  Space,
  Row,
  Col,
  Form,
  notification,
  DatePicker,
  Select,
  Card,
  Modal,
  Button
} from 'antd';
import moment from 'moment';
import _ from 'underscore';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FolderOpenTwoTone } from '@ant-design/icons/lib';

const config = require('../../keyFront');
const { RangePicker } = DatePicker;
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'covid_report.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const CovidReportOfEmployee = ({ empId }) => {
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMsg, setShowErrMsg] = useState('');
  const [noRecordFound, setNoRecFound] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [vaccinatedEmployees, setVaccinatedEmployees] = useState([]);
  const [covidLocation, setCovidLocation] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [unReportedEmployees, setUnReportedEmployees] = useState([]);
  const [unvaccinatedEmployees, setUnvaccinatedEmployees] = useState([]);
  const [exemptedEmployees, setExemptedEmployees] = useState([]);
  const [vulnerableEmployees, setVulnerableEmployees] = useState([]);
  const [covidReportData, setCovidReportData] = useState([]);
  const [covidReportCountData, setCovidReportCountData] = useState(0);
  const [employeeNameRecords, setEmployeeNameRecords] = useState({});
  const [fullyVaccinatedEmployees, setFullyVaccinatedEmployees] = useState([]);
  const [partiallyVaccinatedEmployees, setPartiallyVaccinatedEmployees] = useState([]);
  const [boostedEmployees, setBoostedEmployees] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [table, setTable] = useState([]);
  const [reportTableBKP, setTableBKP] = useState([]);
  const [activeEmpCount, setActiveEmployees] = useState(0);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [change, setChange] = useState(false);
  const [testingStatus, setTestingStatus] = useState('all');
  const [tableType, setTableType] = useState('all');
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [filterFlag, setFilterFlag] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [docPermission, setDocPermission] = useState(false);
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [covidReportResult, setCovidReportResult] = useState([]);
  const [covidReportResultBkup, setCovidReportResultBkup] = useState([]);
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();

  const getActiveEmployeeCount = () => {
    axios
      .post(setting.serverUrl + '/api/getActiveEmployeeCount', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custIdArr: JSON.parse(localStorage.getItem('customerData')).id,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          setActiveEmployees(response.data.value);
        }
      });
  };
  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.CovidTestingDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };
  const getCovidReports = () => {
    setNoRecFound(false);
    setChange(true);
    setIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/getCovidReportByEmpId', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id,
          empId: empId
        }
      })
      .then((response) => {
        // setIsLoading(false);
        if (response && response.data) {
          if (response.data.error) {
            alert('Some technical error occurred. Please try again in a bit.');
          }
          if (response.status === 200) {
            if (response.data == 'Blocked') {
              console.log('Covid Report data blocked: ', response);
            } else {
              if (response.data.rows && response.data.rows.length == 0) {
                setNoRecFound(true);
                return;
              }
              let records = response.data.rows;
              let locations = {};
              records.forEach((element) => {
                if (locations[element.location_name] == undefined) {
                  locations[element.location_name] = true;
                }
              });
              setCovidLocation(locations);
              setCovidReportData(records);
              let employeeNames = {};
              records.forEach((element) => {
                const name = element.first_name + ' ' + element.last_name;
                if (!employeeNames[name]) {
                  employeeNames[name] = name;
                }
              });
              setEmployeeNameRecords(employeeNames);
              setHasPermission(true);
              //setShowErrMsg('');
            }
          } else if (response.status === 201) {
            setNoRecFound(true);
            setShowErrMsg('Access permission not allowed.');
            setNoRecordMessage('Access permission not allowed.');

            // notification.error({
            // message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            // });
          } else if (response.status === 202) {
            setNoRecFound(false);
            setCovidReportData([]);
            setNoRecordMessage('No Record found.');
          } else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
          }
        }
      });
  };
  // const getCovidReportsCounts = () => {
  //   setChange(true);
  //   axios
  //     .post(setting.serverUrl + '/api/getCovidReportCount', {
  //       params: {
  //         email: localStorage.getItem('myData'),
  //         user: localStorage.getItem('myData'),
  //         pass: localStorage.getItem('myPass'),
  //         customerId: localStorage.getItem('customerId'),
  //         compId: JSON.parse(localStorage.getItem('customerData')).id
  //       }
  //     })
  //     .then((response) => {
  //       //console.log('response covid count>>>>>>', response);
  //       if (response && response.data) {
  //         if (response.data.error) {
  //           alert('Some technical error occurred. Please try again in a bit.');
  //         }
  //         if (response.status === 200) {
  //           setCovidReportCountData(response.data);
  //         }
  //       }
  //       else if(response.status === 202){
  //         setCovidReportCountData(0);

  //       }
  //     });
  // };

  const createTable = () => {
    setChange(true);
    setCompanyName(' of ' + localStorage.getItem('displayName'));
    if (covidReportData.length != 0) {
      let vaccinated = covidReportData.filter((data) => data.vaccination_name !== null);
      let unvaccinated = covidReportData.filter((data) => data.unvaccinated === 'Yes');
      let unreported = covidReportData.filter((data) => data.unreported === 'Yes');
      let exempted = covidReportData.filter((data) => data.exemption !== 'No');

      let fullyVaccinated = covidReportData.filter(
        (data) => data.dose_rank === '5' || data.dose_rank === '10'
      );
      let partiallyVaccinated = covidReportData.filter((data) => data.dose_rank === '1');
      let boosted = covidReportData.filter((data) => data.dose_rank === '10');
      covidReportData.map((x) => (x.name = x.first_name + ' ' + x.last_name));
      setExemptedEmployees(exempted);
      setUnvaccinatedEmployees(unvaccinated);
      setVaccinatedEmployees(vaccinated);
      setUnReportedEmployees(unreported);

      setFullyVaccinatedEmployees(fullyVaccinated);
      setPartiallyVaccinatedEmployees(partiallyVaccinated);
      setBoostedEmployees(boosted);
      let updatedRes = covidReportData.map((k) => {
        return {
          ...k,
          newVaccinationDate:
            k.vaccination_date != false ? moment(k.vaccination_date).format('MMMM DD YYYY') : '',
          customVaccKey: k.dose_rank
            ? (k.dose_rank === '1'
                ? 'Partial'
                : k.dose_rank === '5'
                ? 'Fully'
                : k.dose_rank === '10'
                ? 'Boosted'
                : '') +
              '|' +
              k.vaccination_name +
              '|' +
              moment(k.vaccination_date).format('MMMM DD YYYY')
            : '',
          customExemKey:
            k.exemption !== 'No'
              ? (k.exemption === 'medical'
                  ? 'Medical'
                  : k.exemption === 'religious'
                  ? 'Religious'
                  : '') +
                (k.exemption_date ? ' | ' + moment(k.exemption_date).format('MMMM DD YYYY') : '')
              : '',
          customUnvaccinated: k.unvaccinated !== 'No' ? 'Unvaccinated' : '',
          customUnreported: k.unreported !== 'No' ? 'Unreported' : '',
          customLocation:
            k.location_name != false && k.location_name.includes('|')
              ? k.location_name.split('|')[1].trim()
              : k.location_name,
          newDL: k.dl != false ? k.dl : '',
          newEmpEIN: k.emp_ein != false ? k.emp_ein : '',
          newEmpSSN: k.emp_ssn != false ? '***-**-' + k.emp_ssn?.substr(-4) : '',
          jobTitle: k.job_title != false ? k.job_title : '',
          departments: k.department != false ? k.department : '',
          program: k.program != false ? k.program : '',
          mode: k.mode != false ? k.mode : ''
        };
      });
      setTable(updatedRes);
      if (updatedRes.length > 0) {
        const collectionView1 = new CollectionView(updatedRes, {
          pageSize: updatedRes.length
        });
        setViewData(collectionView1);
        const collectionView2 = new CollectionView(updatedRes, {
          pageSize: pageSize,
          collectionChanged: (s) => {
            filterRefCounter.current = s.totalItemCount;
            var filterCounter = document.getElementById('filterCounter');
            if (filterCounter) {
              filterCounter.textContent = s.totalItemCount;
            }
          }
        });

        setView(collectionView2);
      }
      setCovidReportResult(updatedRes);
      setCovidReportResultBkup(updatedRes);
      setTableBKP(updatedRes);
    } else {
      setChange(false);
      setHasPermission(true);
      setNoRecFound(false);
      setTable([]);
      setNoRecordMessage('No Record found.');
    }
  };
  function download(e, empId) {
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificateForEmployee', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          empId: empId
        }
      })
      .then((response) => {
        console.log('Respone from download images', response.data);
        setIsLoaderActive(false);
        setChange(false);
        if (Array.isArray(response.data)) {
          setIsModalVisible(true);
          setImageUrls(response.data);
        } else {
          window.open(response.data);
        }
      });
  }
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    //setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };

  const filterTable = (type) => {
    setTableType(type);
    setTestingStatus('all');
    setFilterFlag(true);
    if (type === 'unreported') {
      setTable(unReportedEmployees);
    } else if (type === 'vaccinated') {
      setTable(vaccinatedEmployees);
    } else if (type === 'unvaccinated') {
      setTable(unvaccinatedEmployees);
    } else if (type === 'exempted') {
      setTable(exemptedEmployees);
    } else if (type === 'vulnerable') {
      setTable(vulnerableEmployees);
    } else if (type === 'fully') {
      setTable(fullyVaccinatedEmployees);
    } else if (type === 'partially') {
      setTable(partiallyVaccinatedEmployees);
    } else if (type === 'boosted') {
      setTable(boostedEmployees);
    } else {
      createTable();
    }
  };
  useEffect(() => {
    getActiveEmployeeCount();
    // getEmployees();
    getCovidReports();
    // getCovidReportsCounts();
    getDocPermission();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, []);

  useEffect(() => {
    createTable();
  }, [covidReportData, activeEmpCount, pageSize]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }
  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    //console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };
  const showVaccination = (item) => {
    return (
      <p style={{ color: 'grey' }}>
        {item.item.dose_rank ? (
          <b>
            {item.item.dose_rank === '1' ? (
              <b style={{ color: '#FFA500' }}>Partial</b>
            ) : item.item.dose_rank === '5' ? (
              <b style={{ color: '#008000' }}>Fully</b>
            ) : item.item.dose_rank === '10' ? (
              <b style={{ color: '#008000' }}>Boosted</b>
            ) : (
              ''
            )}{' '}
            | {item.item.vaccination_name} |{' '}
            {moment(item.item.vaccination_date).format('MMMM DD YYYY')}{' '}
          </b>
        ) : (
          <b></b>
        )}
      </p>
    );
  };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showSSN = (item) => {
    if (item.item.newEmpSSN !== false && item.item.newEmpSSN !== null) {
      return <>{'***-**-' + item.item.newEmpSSN.substr(-4)}</>;
    } else {
      return <>{''}</>;
    }
  };
  const showEIN = (item) => {
    return <>{item.item.newEmpEIN != false ? item.item.newEmpEIN : ''}</>;
  };
  const showDL = (item) => {
    return <>{item.item.newDL != false ? item.item.newDL : ''}</>;
  };

  const showExemption = (item) => {
    return (
      <p style={{ color: 'grey' }}>
        {item.item.exemption !== 'No' ? (
          <b>
            {item.item.exemption === 'medical' ? (
              <b style={{ color: '#0000FF' }}>Medical</b>
            ) : item.item.exemption === 'religious' ? (
              <b style={{ color: '#0000FF' }}>Religious</b>
            ) : (
              '-- '
            )}{' '}
            {item.item.exemption_date
              ? ' | ' + moment(item.item.exemption_date).format('MMMM DD YYYY')
              : ''}
          </b>
        ) : (
          <b style={{ color: 'red' }}></b>
        )}
      </p>
    );
  };

  const showUnvaccinated = (item) => {
    if (item.item.unvaccinated !== 'No') {
      return (
        <p style={{ color: '#FFA500' }}>
          <b>Unvaccinated</b>
        </p>
      );
    } else {
      return <p></p>;
    }
  };

  const showUnreported = (item) => {
    if (item.item.unreported !== 'No') {
      return (
        <p style={{ color: '#ef6727' }}>
          <b>Unreported</b>
        </p>
      );
    } else {
      return <p></p>;
    }
  };

  const showDoc = (item) => {
    return (
      <span className='nowrap'>
        {docPermission && item.item.emp_id ? (
          // <button
          //   onClick={(e) => download(e, item.item.emp_id)}
          //   style={{
          //     border: 'none',
          //     textAlign: 'center',
          //     borderRadius: 5,
          //     backgroundColor: '#abc',
          //     fontweight: 'bold'
          //   }}
          // >
          //   Preview
          // </button>
          // <p style={{ color: 'blue' }}>
          //   Preview
            <FolderOpenTwoTone
              twoToneColor='#cc7a00'
              style={{ fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
              onClick={(e) => download(e, item.item.emp_id)}
            />
          // </p>
        ) : (
          item.item.emp_id && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
        )}
      </span>
    );
  };

  return hasPermission ? (
    <div style={{ padding: 20, marginTop: 20 }}>
      {table.length > 0 || filterFlag ? (
        <div>
          <Row gutter={[32, 32]}>
            <Col xs={24} sm={8} md={8} lg={8} xl={10}>
              <FlexGridSearch
                ref={theSearch}
                placeholder='Search'
                cssMatch=''
                style={{ width: '70%', height: '40px' }}
              />
            </Col>

            <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
              <div ref={fixedContainerRef}>
                <Select
                  style={{ width: 170 }}
                  placeholder={`Items per page : ${pageSize}`}
                  onChange={paggination}
                  options={[
                    { label: 'Items per page : 10', value: 10 },
                    { label: 'Items per page : 20', value: 20 },
                    { label: 'Items per page : 30', value: 50 }
                  ]}
                  //getPopupContainer={() => fixedContainerRef.current}
                />
              </div>
            </Col>

            <Col xs={12} sm={8} md={8} lg={8} xl={10}>
              <Button
                type='primary'
                style={{ backgroundColor: '#3d586a', float: 'right' }}
                disabled={isExcelPreparing}
                onClick={exportToExcel}
                size='small'
              >
                {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
              </Button>
            </Col>
          </Row>

          {/* to export entire data of table */}
          <FlexGrid
            ref={theGrid}
            style={{ display: 'none' }}
            className='dummyWijmoData'
            itemsSource={viewData}
            initialized={gridInitializedData}
            formatItem={formatItem}
            isReadOnly={true}
          >
            <FlexGridColumn
              binding='customLocation'
              header='Location'
              minWidth={250}
              width='*'
            ></FlexGridColumn>
            <FlexGridColumn binding='customVaccKey' header='Vaccination' minWidth={250} width='*'>
              <FlexGridCellTemplate cellType='Cell' template={showVaccination} />
            </FlexGridColumn>
            <FlexGridColumn binding='customExemKey' header='Exemption' minWidth={110} width='*'>
              <FlexGridCellTemplate cellType='Cell' template={showExemption} />
            </FlexGridColumn>
            <FlexGridColumn
              binding='program'
              header='Dot Mode'
              // width='10*'
              minWidth={160}
              width='*'
            >
              <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
            </FlexGridColumn>
            <FlexGridColumn
              binding='customUnvaccinated'
              header='Unvaccinated'
              minWidth={120}
              width='*'
            >
              <FlexGridCellTemplate cellType='Cell' template={showUnvaccinated} />
            </FlexGridColumn>
            <FlexGridColumn binding='customUnreported' header='Unreported' minWidth={120} width='*'>
              <FlexGridCellTemplate cellType='Cell' template={showUnreported} />
            </FlexGridColumn>
          </FlexGrid>

          <FlexGrid
            ref={theGrid}
            id='theGridTallRows'
            className='cliniclisttable'
            alternatingRowStep={1}
            // autoRowHeights={true}
            itemsSource={view}
            initialized={gridInitialized}
            formatItem={formatItem}
            itemsPerPage={10}
            isReadOnly={true}
            scrollMode='Virtual' // Add this line for smooth scrolling
          >
            <div style={{ display: 'none' }}>
              <wjInput.ListBox
                className='column-picker'
                itemsSource={listboxColumns}
                checkedMemberPath='visible'
                displayMemberPath='header'
                lostFocus={hidePicker}
                initialized={initializedListBox}
              ></wjInput.ListBox>
            </div>
            <FlexGridColumn binding='customLocation' header='Location' width={160}>
              {/* <FlexGridCellTemplate cellType='Cell' template={showLocation} /> */}
            </FlexGridColumn>
            <FlexGridColumn
              binding='customVaccKey'
              header='Vaccination'
              // width='12*'
              minWidth={230}
              width='*'
            >
              <FlexGridCellTemplate cellType='Cell' template={showVaccination} />
            </FlexGridColumn>
            <FlexGridColumn
              binding='customExemKey'
              header='Exemption'
              // width='11*'
              // minWidth={110}
              // width='*'
              width={110}
            >
              <FlexGridCellTemplate cellType='Cell' template={showExemption} />
            </FlexGridColumn>

            <FlexGridColumn
              binding='customUnvaccinated'
              header='Unvaccinated'
              // minWidth={120}
              // width='*'
              width={120}
            >
              <FlexGridCellTemplate cellType='Cell' template={showUnvaccinated} />
            </FlexGridColumn>
            <FlexGridColumn
              binding='jobTitle'
              header='Job Title'
              // width='10*'
              // minWidth={250}
              // width='*'
              width={235}
            />

            <FlexGridColumn
              binding='program'
              header='Dot Mode'
              // width='10*'
              // minWidth={160}
              // width='*'
              width={100}
            >
              <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
            </FlexGridColumn>
            <FlexGridColumn binding='customUnreported' header='Unreported' width={110}>
              <FlexGridCellTemplate cellType='Cell' template={showUnreported} />
            </FlexGridColumn>
            <FlexGridColumn binding='emp_id' header='Documents' width={85}>
              <FlexGridCellTemplate cellType='Cell' template={showDoc} />
            </FlexGridColumn>
            <FlexGridFilter
              filterColumns={[
                'customVaccKey',
                'customLocation',
                'customExemKey',
                'customUnvaccinated',
                'customUnreported',
                'program'
              ]}
            />
          </FlexGrid>

          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <div
              style={{
                backgroundColor: '#425a81',
                color: '#fff',
                display: 'inline',
                borderRadius: '5px',
                height: '30px'
              }}
            >
              <CollectionViewNavigator
                style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                byPage={true}
                cv={view}
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <p
              style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}
              ref={filterRefCounter}
              id='filterCounter'
            >
              {Globalize.format(filterRefCounter.current, 'n0')}
            </p>
            &nbsp;
            <p style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}>
              {' '}
              out of {table.length}
            </p>
          </div>
        </div>
      ) : (
        <div>
          {noRecordFound ? (
            <p
              style={{
                fontSize: 18,
                fontWeight: 700,
                padding: 20,
                color: '#ef6727',
                textAlign: 'center'
              }}
            >
              {noRecordMessage}
            </p>
          ) : (
            <Row>
              <Col span={10}></Col>
              <Col span={8}>
                <Space size='large' style={{ marginTop: 30, marginLeft: 85 }}>
                  <Spin size='large' />
                </Space>
              </Col>
            </Row>
          )}
        </div>
      )}
      <Modal
        width={1000}
        title='COVID Report Document'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls && imageUrls.length > 0 ? (
          imageUrls.map((img) => {
            console.log('img :: ', img);
            return (
              <p>
                {img.fileType &&
                (img.fileType == 'png' ||
                  img.fileType == 'PNG' ||
                  img.fileType == 'jpg' ||
                  img.fileType == 'JPG' ||
                  img.fileType == 'jpeg' ||
                  img.fileType == 'JPEG') ? (
                  <img src={img.url} />
                ) : (
                  <embed src={img.url} width='800px' height='2100px' />
                )}
              </p>
            );
          })
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )}
      </Modal>
      <DarkBackground disappear={isLoaderActive}>
        <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
      </DarkBackground>
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        &nbsp;&nbsp; {showErrorMsg}
      </p>
    </div>
  );
};

export default CovidReportOfEmployee;
