import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Form, Select, Input, Card, Modal, Tooltip, Button } from 'antd';

import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from '../../config/setting';
// import { PlusCircleTwoTone } from '@ant-design/icons';
// import OpenNotificationId from './openNotificationId';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as wjcCore from '@grapecity/wijmo';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import EmployeePoolSelectList from './employeePoolSelectList';
import PoolRulesTable from './poolRulesTable';

let columnPicker = [];
let flexes = '';
let showGrid = '';
let dragOver = '';
let listBoxes = '';
var infoRecordd = [];
//const { Option } = Select;
//const FormItem = Form.Item;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'PoolGenerated.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

export default function RandomPoolDetails(patient) {
  console.log({ patient });
  const history = useHistory();
  const location = useLocation();
  const [isLoadingNNNNN, setIsLoadingNNNNN] = useState(false);
  const [isLoadingNNN, setIsLoadingNNN] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingN, setIsLoadingN] = useState(false);
  const [isLoadingNN, setIsLoadingNN] = useState(false);
  const [pooldat, setVPoolData] = useState([]);
  const [multiLocation, setMultiLocation] = useState([]);
  const [loaderValue, setloaderValue] = useState(false);
  const [RecordId, setRecordId] = useState('');
  const [totalEmp, settotalEmp] = useState('');
  const [selectedEmp, setselectedEmp] = useState('');
  const [generatedPooldate, setgeneratedPooldate] = useState('');
  const [DeleteData, setDeleteData] = useState({});
  // const [pdfgenerateDate, setpdfgenerateDate] = useState('');
  const [infoRecord, setInfoRecord] = useState([]);
  const [PoolRulesData, setPoolRulesData] = useState([]);
  const [SlectedEmployeeDetail, setSlectedEmployeeDetail] = useState([]);
  const [records, setRecords] = useState([]);
  const [RecordsLoc, setRecordsLoc] = useState([]);
  const [generatedPoolEmployee, setgeneratedPoolEmployee] = useState([]);
  const [poolRecord, setpoolRecord] = useState({});
  const [infoVisible, setInfoVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleDel, setVisibleDel] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [paraVal, setParameter] = useState('');
  const [compPackage, setcompPackage] = useState([]);
  const [boolPackage, setBoolNewPackage] = useState(false);
  const [loaderValGenerate, setloaderValGenerate] = useState(false);
  const [rowidVal, setRowid] = useState('');
  const [cycle, setCycle] = useState('');
  const [poolRulesArray, setpoolRulesArray] = useState([
    // {
    //   packageid:'',
    //   packagename: '',
    //   percentage_yearly: '',
    //   buffer_percentage: '',
    //   parameter: ''
    // }
  ]);
  const [newChangeOne, setNewChangeOne] = useState(false);
  const [NewChangeOneNN, setNewChangeOneNN] = useState(false);
  const [MedicationTrue, setMedicationTrue] = useState(false);
  const [multiCompanyNames, setMultiCompNames] = useState([]);
  const FormItem = Form.Item;
  const { Option } = Select;
  const [view, setView] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [pagging, setPagging] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const closeModal = () => setVisible(false);

  useEffect(() => {
    console.log('this.props customerId 1', localStorage.getItem('customerId'));
    if ((location && location.state != undefined) || (location && location.state != null)) {
      let props = location.state;
      // getViewPoolDetail(props.notification_event_id[0]);
      console.log('props===:::', props);
      setVPoolData([location.state]);
      getViewPoolDetail(props);
      getPoolLocations(props);
      poolList(props);
      addName(props);
      getPoolRules(props);
      getMutiCompName(props);
      getMultiLocation(props);
    }
    //if (props == undefined)
    else {
      history.goBack();
    }
  }, []);
  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }
  console.log('location.state', location.state);

  async function getPoolRules(props) {
    console.log('getPoolRules::::::', props);

    await axios
      .post(setting.serverUrl + '/api/getPoolRules', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          //poolid: props.id
          poolRuleIds: props.protocol_rules_id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        console.log('Respone from getPoolRules::::: ', response.data.rows);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          setPoolRulesData(response.data.rows);
          // setpoolRulesArray(response.data.rows)
        }
      });
  }

  console.log('PoolRulesData be::', PoolRulesData);

  async function getPoolRulesNew(props) {
    console.log('getPoolRulesNew::::::', props);

    await axios
      .post(setting.serverUrl + '/api/getPoolRules', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          poolid: props.id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        console.log('Respone from getPoolRulesNew::::: ', response.data.rows);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          // setPoolRulesData(response.data.rows)
          setpoolRulesArray(response.data.rows);
          setIsLoadingNNN(false);
        }
      });
  }
  console.log('poolRulesArray be::', poolRulesArray);
  async function getMutiCompName(props) {
    console.log('getMutiCompName::::::', props);

    await axios
      .post(setting.serverUrl + '/api/getMutiCompanyPool', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          // poolid: props.id,
          companyIds: props.multi_select_company.length > 0 && props.multi_select_company
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        console.log('Respone from getMutiCompName:::::: ', response.data);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          // setPoolRulesData(response.data.rows)
          setMultiCompNames(response.data);
          setIsLoadingNNN(false);
        }
      });
  }
  async function addName(props) {
    console.log('props::::::', props);
    // setLoadingPackage(true);
    // console.log("Company", JSON.parse(event));

    // await getLocationData(JSON.parse(event).id)

    await axios
      .post(setting.serverUrl + '/api/hospital_customers_lines', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          compId: props.company[0]
        }
      })
      .then((response) => {
        // setLoadingPackage(false);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        console.log('Respone from hospital_customers_lines::::: ', response.data);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          // setCompanyId(JSON.parse(event).id)
          setcompPackage(response.data);
          // setBoolNewPackage(true)
        }
      });
  }
  async function getMultiLocation(props) {
    console.log('props::::::', props);
    // setLoadingPackage(true);
    // console.log("Company", JSON.parse(event));

    // await getLocationData(JSON.parse(event).id)

    await axios
      .post(setting.serverUrl + '/api/getMultiPoolLoc', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          locIds: props.multi_child_ids
        }
      })
      .then((response) => {
        // setLoadingPackage(false);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        console.log('Respone from getMultiLocation::::: ', response.data);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          setMultiLocation(response.data);
          // setCompanyId(JSON.parse(event).id)
          // setcompPackage(response.data);
          // setBoolNewPackage(true)
        }
      });
  }
  console.log('compPackage::', compPackage);

  function poolList(props) {
    setgeneratedPoolEmployee([]);
    setIsLoadingNNN(true);
    axios
      .post(setting.serverUrl + '/api/poolList', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          //custId: custIdArr[0],
          notification_event_id: props.notification_event_id
          //poolID: props.id
        }
      })
      .then(async (response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        let result = response.data.rows;
        let allNotificationIds = await result.map((k) => k.id);
        console.log({ allNotificationIds });
        if (response && response.status == 200) {
          // if(response.data.length > 0)
          // {
          let responseDetail = await selectEmpDetail(allNotificationIds);
          console.log('selecetd em detail', responseDetail);
          //}
          const groupedEvents = responseDetail.reduce((acc, event) => {
            const eventId = event.event_id;
            if (!acc[eventId]) {
              acc[eventId] = [];
            }
            acc[eventId].push(event);
            return acc;
          }, {});
          console.log({ groupedEvents });
          const mappedEvents = result.map((event) => {
            const eventId = event.id;
            const matchingEvents = groupedEvents[eventId] || [];
            return { ...event, events: matchingEvents };
          });
          console.log({ mappedEvents });
          setIsLoadingNNN(false);
          mappedEvents.sort((a, b) => b.id - a.id);
          console.log('Respone from poolList ', response.data.rows);
          // let newResult = response.data.rows;
          let newUpdatedValues = mappedEvents.map((k) => {
            return {
              ...k,
              newCreateDate: k.create_date && moment(k.create_date).format('MM/DD/YYYY, HH:mm'),
              newCycleMonth: k.cycle_month && moment(k.create_date).format('MM/DD/YYYY')
            };
          });
          console.log({ newUpdatedValues });
          setgeneratedPoolEmployee(newUpdatedValues);
          setWijmoFlexGrid(newUpdatedValues, 5);
        }
      });
  }

  function generatePool() {
    setgeneratedPoolEmployee([]);
    console.log('getPoolLocations loc::', location.state);
    // let poolId = propsObj.id;
    console.log('RecordsLoc new', RecordsLoc);
    let newArrLoad = RecordsLoc.map((item) => item.id);
    console.log('newArrLoad', newArrLoad);
    setIsLoadingNNN(true);
    axios
      .post(setting.serverUrl + '/api/getEmployeesCustForPool', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          //custId: custIdArr[0],
          companyId: location.state.company[0],
          locationArr: newArrLoad,
          poolID: pooldat[0].id,
          cycle: pooldat[0].selection_period,
          package: pooldat[0].packages,
          Mode: pooldat[0].Mode,
          program: pooldat[0].program,
          PoolRulesData: PoolRulesData
          // buffer_percentage: pooldat[0].buffer_percentage,
          // percentage_yearly: pooldat[0].percentage_yearly
          // poolId: poolId
        }
      })
      .then(async (response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        let result = response.data;

        if (response && response.status == 200) {
          setIsLoadingNNN(false);
          response.data.rows.sort((a, b) => b.id - a.id);
          console.log('Respone from protocolPoolLocations ', response.data.rows);
          // let locIdArr = await response.data.rows.map(x => x.locationId);
          // console.log("locIdArr::", locIdArr)
          // getLocationData(locIdArr)
          setgeneratedPoolEmployee(response.data.rows);
          // settotalEmp(response.data.totalEmp);
          // setselectedEmp(response.data.selectedEmp);
          // setgeneratedPooldate(response.data.date);
        }
      });
  }

  function getPoolLocations(propsObj) {
    console.log('getPoolLocations::', propsObj);
    let poolId = propsObj.id;
    setIsLoadingN(true);
    axios
      .post(setting.serverUrl + '/api/protocolPoolLocations', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          //custId: custIdArr[0],
          poolId: poolId
        }
      })
      .then(async (response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        setIsLoadingN(false);

        let result = response.data;

        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          console.log('Respone from protocolPoolLocations ', response.data.rows);

          let locIdArr = await response.data.rows.map((x) => x.locationId);
          console.log('locIdArr::', locIdArr);
          getLocationData(locIdArr);
        }
      });
  }

  function getLocationData(locIdArr) {
    setIsLoadingNN(true);
    axios
      .post(setting.serverUrl + '/api/getCustomerLocationArr', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          //custId: custIdArr[0],
          locIdArr: locIdArr
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        setIsLoadingNN(false);
        let result = response.data;

        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          console.log('Respone from getLocationData::', response.data);
          setRecordsLoc(response.data);
        }
      });
  }

  console.log('RecordsLoc::', RecordsLoc);
  console.log('pooldat::', pooldat);
  //let pooldat = JSON.parse(localStorage.getItem('pdata'));
  console.log('i am pool dat in viewPooldata.tsx', pooldat);

  function getViewPoolDetail(propsObj) {
    let notificationId = propsObj.notification_event_id;
    setIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/viewPoolDetail', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          //custId: custIdArr[0],
          notification_event_id: notificationId
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        setIsLoading(false);
        console.log('Respone from viewPoolDetail ', response.data);
        let result = response.data;

        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          setRecords(response.data);
          console.log('final result::::', result);
        }
      });
  }

  // const columns = [
  //   {
  //     title: 'Company Name',
  //     key: 'company',
  //     dataIndex: 'company',
  //     render: (company) => <span className='nowrap'>{company[1]}</span>
  //   },

  //   {
  //     key: 'protocol_id',
  //     dataIndex: 'protocol_id',
  //     title: 'Protocol ID',
  //     render: (protocol_id) => <span>{protocol_id}</span>
  //   },
  //   {
  //     key: 'company',
  //     dataIndex: 'package',
  //     title: 'Package',
  //     render: () => <span>{pooldat.length > 0 ? pooldat[0].packages[1] : ''}</span>
  //   },
  //   {
  //     key: 'multi_select_employee',
  //     dataIndex: 'multi_select_employee',
  //     title: 'Selected Employees',
  //     render: (multi_select_employee) => (
  //       <span className='nowrap'>
  //         {multi_select_employee.length} {multi_select_employee.length > 1 ? 'records' : 'record'}
  //       </span>
  //     )
  //   }
  // ];

  // const selectedEmpOne = async (record) => {
  //   console.log('record', record);
  //   setRecordId(record.id);
  //   setpoolRecord(record);
  //   setIsLoadingNNN(true);
  //   await axios
  //     .post(setting.serverUrl + '/api/poolListEmployees', {
  //       params: {
  //         email: localStorage.getItem('myData'),
  //         user: localStorage.getItem('myData'),
  //         pass: localStorage.getItem('myPass'),
  //         //custId: custIdArr[0],

  //         poolID: record.id
  //       }
  //     })
  //     .then(async (response) => {
  //       if (response && response.data.error) {
  //         alert('Some Technical Error Occurred!');
  //       }

  //       if (response && response.status == 200) {
  //         setIsLoadingNNN(false);
  //         console.log('Respone from poolListEmployees ', response.data);
  //         // setgeneratedPoolEmployee(response.data.rows);
  //         setSlectedEmployeeDetail(response.data);
  //         setVisible(true);
  //       }
  //     });
  // };
  //   const selectEmpDetail = async (record) => {
  //     console.log('record====', record);
  //     // setRecordId(record.id);
  //     // setpoolRecord(record);
  //     setIsLoadingNNN(true);
  //     await axios
  //       .post(setting.serverUrl + '/api/getAllPoolSelectEmployees', {
  //         params: {
  //           email: localStorage.getItem('myData'),
  //           user: localStorage.getItem('myData'),
  //           pass: localStorage.getItem('myPass'),
  //           //poolID: record.id,
  //           notificationEvtIds: record
  //           //poolId: record
  //         }
  //       })
  //       .then(async (response) => {
  //         if (response && response.data.error) {
  //           alert('Some Technical Error Occurred!');
  //         }

  //         if (response && response.status == 200) {
  //           setIsLoadingNNN(false);
  //           console.log('Respone from getAllPoolSelectEmployees ', response.data);
  //           // setgeneratedPoolEmployee(response.data.rows);
  //           let finalresult = await response.data;
  //           let updatedData = await finalresult.map((k)=>{return {
  //             ...k,
  //             fullName : k.first_name && k.first_name + ' ' + k.last_name && k.last_name,

  //           }})
  // return updatedData;
  //           //setSlectedEmployeeDetail(updatedData);
  //          // setVisible(true);

  //         }
  //       });
  //   };
  async function selectEmpDetail(record) {
    // console.log("inside getEapIdCheckValue", result)
    const res = await axios.post(setting.serverUrl + '/api/getAllPoolSelectEmployees', {
      params: {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        notificationEvtIds: record,
        poolId: location.state.id
      }
    });

    const response = await res;
    console.log('aaaaaaa', response);
    const result = response.data;
    let finalresult = await result;
    let updatedData = await finalresult.map((k) => {
      return {
        ...k,
        fullName: k.first_name + ' ' + k.last_name
      };
    });
    console.log('aaaaaaa', updatedData);
    return updatedData;
  }
  const deletePoolGen = (data) => {
    setDeleteData({});
    console.log('dat::', data);
    setDeleteData(data);
    setVisibleDel(true);
  };
  console.log('DeleteData::', DeleteData);

  const editPoolGen = async (data) => {
    setCycle(pooldat[0].selection_period);
    setIsLoadingNNN(true);
    setDeleteData({});
    console.log('dat::', data);
    setDeleteData(data);
    await axios
      .post(setting.serverUrl + '/api/poolGenRuleData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          //custId: custIdArr[0],

          data: data
        }
      })
      .then(async (response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          setRowid('');
          if (response.data.rows.length > 0) {
            console.log('response rule data::', response.data.rows[0]);
            setRowid(response.data.rows[0].id);
            await setpoolRulesArray(JSON.parse(response.data.rows[0].json_data));
            setIsLoadingNNN(false);
          } else {
            await getPoolRulesNew(location.state);
          }
        }
      });

    // getPoolRulesNew(location.state)
    setVisibleEdit(true);
  };
  console.log('DeleteData edit::', DeleteData);
  console.log('rowidVal::', rowidVal);

  const deletePoolGenerated = async () => {
    setloaderValue(true);
    await axios
      .post(setting.serverUrl + '/api/deletePoolGenerated', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),

          poolGenID: DeleteData.id
        }
      })
      .then(async (response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          // setIsLoadingNNN(false);
          setloaderValue(false);
          console.log('Respone from pool del ', response);
          // // setgeneratedPoolEmployee(response.data.rows);
          // setSlectedEmployeeDetail(response.data)
          setVisibleDel(false);
          poolList(location.state);
        }
      });
  };

  const actions = (data) => (
    <div className='buttons-list nowrap'>
      <Tooltip title='Edit'>
        <Button
          shape='circle'
          onClick={() => editPoolGen(data)}
          danger
          style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            color: '#35A6FF'
          }}
        >
          <span className='icofont-edit' style={{ fontSize: 22 }} />
        </Button>
      </Tooltip>
      <Tooltip title='Delete'>
        <Button
          shape='circle'
          onClick={() => deletePoolGen(data)}
          danger
          style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            color: 'rgb(233 109 109)'
          }}
        >
          <span className='icofont-ui-delete' style={{ fontSize: 22 }} />
        </Button>
      </Tooltip>
    </div>
  );

  const columnsN = [
    {
      title: 'Pool Created Date',
      key: 'create_date',
      dataIndex: 'create_date',
      render: (create_date) => (
        <span className='nowrap'>{moment(create_date).format('MM/DD/YYYY, HH:mm')}</span>
      )
    },
    {
      key: 'cycle_month',
      dataIndex: 'cycle_month',
      title: 'Cycle Month',
      render: (cycle_month) => (
        <span>{cycle_month && moment(cycle_month).format('MM/DD/YYYY')}</span>
      )
    },
    // {
    //   key: 'create_date',
    //   dataIndex: 'create_date',
    //   title: 'Cycle Month',
    //   render: (text, record) => (
    //     <span>
    //       {record.cycle == 'Monthly' &&
    //         moment(record.create_date).add(1, 'months').format('MMMM,Y')}
    //       {record.cycle == 'Bimonthly' &&
    //         (moment(record.create_date).format('M') % 2 == 0
    //           ? moment(record.create_date).add(1, 'months').format('MMMM,Y') +
    //             ' - ' +
    //             moment(record.create_date).add(2, 'months').format('MMMM,Y')
    //           : moment(record.create_date).add(2, 'months').format('MMMM,Y') +
    //             ' - ' +
    //             moment(record.create_date).add(3, 'months').format('MMMM,Y'))}
    //       {record.cycle == 'Quarterly' &&
    //         (moment(record.create_date).format('M') == 1 ||
    //         moment(record.create_date).format('M') == 2 ||
    //         moment(record.create_date).format('M') == 3
    //           ? moment('4', 'M').format('MMMM,Y') + ' - ' + moment('6', 'M').format('MMMM,Y')
    //           : moment(record.create_date).format('M') == 4 ||
    //             moment(record.create_date).format('M') == 5 ||
    //             moment(record.create_date).format('M') == 6
    //           ? moment('7', 'M').format('MMMM,Y') + ' - ' + moment('9', 'M').format('MMMM,Y')
    //           : moment(record.create_date).format('M') == 7 ||
    //             moment(record.create_date).format('M') == 8 ||
    //             moment(record.create_date).format('M') == 9
    //           ? moment('10', 'M').format('MMMM,Y') + ' - ' + moment('12', 'M').format('MMMM,Y')
    //           : moment(record.create_date).format('M') == 10 ||
    //             moment(record.create_date).format('M') == 11 ||
    //             moment(record.create_date).format('M') == 12
    //           ? moment('1', 'M').format('MMMM,Y') + ' - ' + moment('3', 'M').format('MMMM,Y')
    //           : '')}
    //       {record.cycle == 'Annual' &&
    //         moment(record.create_date).format('MMMM,Y') +
    //           ' - ' +
    //           moment(record.create_date).add(1, 'years').format('MMMM,Y')}
    //     </span>
    //   )
    // },
    {
      key: 'all_emp',
      dataIndex: 'all_emp',
      title: 'Total Employees',
      render: (all_emp) => <span>{all_emp}</span>
    },
    {
      key: 'select_perc',
      dataIndex: 'select_perc',
      title: 'Per Cycle Employees',
      render: (select_perc) => <span>{select_perc}</span>
    },

    {
      key: 'drug_count',
      dataIndex: 'drug_count',
      title: 'Drug Employees',
      render: (drug_count) => <span>{drug_count}</span>
    },
    {
      key: 'drug_alcohol_count',
      dataIndex: 'drug_alcohol_count',
      title: 'Drug/Alcohol Employees',
      render: (drug_alcohol_count) => <span>{drug_alcohol_count}</span>
    },
    {
      key: 'buffer_perc',
      dataIndex: 'buffer_perc',
      title: 'Buffer Employees',
      render: (buffer_perc, record) => <span>{buffer_perc}</span>
    }
    // {
    //   key: 'selected_employees',
    //   dataIndex: 'selected_employees',
    //   title: 'Selected Employees',
    //   render: (text, record) => (
    //     <p style={{ cursor: 'pointer', color: 'green' }} onClick={() => selectedEmpOne(record)}>
    //      55
    //     </p>
    //   )
    // }
    // ,
    // {
    //   key: 'actions',
    //   title: 'Actions',
    //   render: actions
    // }
    // {
    //   key: 'Mobile',
    //   dataIndex: 'Mobile',
    //   title: 'Mobile',
    //   render: (Mobile) => <strong>{Mobile?Mobile:''}</strong>
    // },
  ];

  const updatePoolRules = () => {
    setloaderValGenerate(true);
    console.log('RecordsLoc new update::', RecordsLoc);
    let newArrLoad = RecordsLoc.map((item) => item.id);
    console.log('newArrLoad', newArrLoad);
    console.log('pool generated data::', DeleteData);
    console.log('pool generaetd rules::', poolRulesArray);

    //setloaderValue(true);
    axios
      .post(setting.serverUrl + '/api/updatePoolGeneratedRules', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          //custId: custIdArr[0],
          companyId: location.state.company[0],
          locationArr: newArrLoad,
          poolID: pooldat[0].id,
          cycle: pooldat[0].selection_period,
          package: pooldat[0].packages,
          Mode: pooldat[0].Mode,
          program: pooldat[0].program,
          poolGenID: DeleteData.id,
          poolRulesArray: poolRulesArray,
          updatedCycle: cycle,
          rowidVal: rowidVal
        }
      })
      .then(async (response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          //setloaderValue(false);
          console.log('Respone from pool update ', response);
          setloaderValGenerate(false);
          setVisibleEdit(false);
          poolList(location.state);
          // poolList(location.state);
        }
      });
  };

  // const handleAddPatient = (patient: IPatient) => {
  //   addPatient(patient);
  //   closeModal();
  // };
  const downloadDataFile = () => {
    // console.log("ispReqData::",ispReqData)
    let dummyArr = [];
    // let dataArr = dataN.length>0?dataN:parentData
    SlectedEmployeeDetail.map((item) => {
      dummyArr.push({
        Name: item.first_name + ' ' + item.last_name,
        //'Group': '',
        Division: item.emp_location.length > 0 ? item.emp_location[1].split(',')[1] : '',
        //'Supervisor': '',
        Drug: item.drug_alcohol == 'Drug' || item.drug_alcohol == 'Both' ? 'Yes' : 'No',
        Alcohol: item.drug_alcohol == 'Alcohol' || item.drug_alcohol == 'Both' ? 'Yes' : 'No'
      });
    });

    console.log('dummyArr', dummyArr);
    if (dummyArr.length > 0) {
      let csv = '';
      for (let row = 0; row <= dummyArr.length; row++) {
        let keysAmount = Object.keys(dummyArr[0]).length;
        let keysCounter = 0;
        if (row === 0) {
          for (let key in dummyArr[row]) {
            csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
            keysCounter++;
          }
        } else {
          for (let key in dummyArr[row - 1]) {
            csv += dummyArr[row - 1][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
            keysCounter++;
          }
        }
        keysCounter = 0;
      }
      // console.log("csv is  ",csv)

      let link = document.createElement('a');
      link.id = 'download-csv-data';
      link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
      link.setAttribute(
        'download',
        'Selected-Employee-Data-File' + moment().format('MM/DD/YYYY') + '.csv'
      );
      document.body.appendChild(link);
      document.querySelector('#download-csv-data').click();
      // window.location.reload()
    } else {
      alert('Sorry!!.. No data to download.');
    }
  };

  // const print = () => {
  //   // setpdfgenerateDate(moment().format('YYYY-MM-DD'))
  //   const input = document.getElementById('page');
  //   input.style.display = "block";
  //   html2canvas(input,{width: 2480,
  //     height: 3508}).then((canvas) => {
  //     console.log("canvas.height",canvas.height)
  //     console.log("canvas.width",canvas.width)
  //     let imgWidth = 400;
  //       let pageHeight = 480;
  //       let imgHeight =
  //         ((canvas.height * imgWidth) / 2454)*1.24;
  //       var heightLeft = imgHeight;

  //       const imgData = canvas.toDataURL("image/png");

  //       const pdf = new jsPDF({
  //         orientation: "p",
  //         unit: "mm",
  //         format: [400, 480],
  //       });
  //     var position = 0;

  //     // pdf.addPage();
  //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //     console.log("heightLeft",heightLeft)
  //     console.log("imgHeight",imgHeight)
  //     console.log("total",pageHeight)

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }

  //     pdf.save('POOL_SELECTED_EMPLOYEE_' + moment().format('MM/DD/YYYY') + '.pdf');
  //   });
  //   input.style.display = "none"
  // };
  const print = () => {
    // setpdfgenerateDate(moment().format('YYYY-MM-DD'))
    const input = document.getElementById('page');
    input.style.display = 'block';
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 180;
      var pageHeight = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 10;

      doc.addImage(imgData, 'PNG', 15, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      console.log('heightLeft', heightLeft);
      console.log('imgHeight', imgHeight);
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 15, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save('POOL_SELECTED_EMPLOYEE_' + moment().format('MM/DD/YYYY') + '.pdf');
    });
    input.style.display = 'none';
  };
  // console.log("pdfgenerateDate::",pdfgenerateDate)
  console.log('poolRecord', poolRecord);
  // if (isLoading || RecordsLoc.length == 0 || pooldat.length == 0) {
  //   return (
  //     <div>
  //       <Row>
  //         <Col span={10}></Col>
  //         <Col span={8}>
  //           <Space size='large' style={{ marginTop: 30 }}>
  //             <Spin size='large' />
  //           </Space>
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // }
  //console.log({ multiCompanyNames });
  // const displayEmployeeInfo = (event) => {
  //   setVisible(true);
  // };
  // const selectedEmpColumn = [
  //   {
  //     key: 'compname',
  //     dataIndex: 'compname',
  //     title: 'Company Name',
  //     render: (compname) => <span className='nowrap'>{compname ? compname : ''}</span>
  //   },
  //   {
  //     key: 'com_loc_name',
  //     dataIndex: 'com_loc_name',
  //     title: 'Location',
  //     render: (com_loc_name) => <span className='nowrap'>{com_loc_name ? com_loc_name : ''}</span>
  //   },
  //   {
  //     key: 'first_name',
  //     dataIndex: 'first_name',
  //     title: 'Employee',
  //     render: (first_name) => <span className='nowrap'>{first_name ? first_name : ''}</span>
  //   },
  //   {
  //     key: 'pkgname',
  //     dataIndex: 'pkgname',
  //     title: 'Package',
  //     render: (pkgname) => <span className='nowrap'>{pkgname ? pkgname : ''}</span>
  //   },
  //   {
  //     key: 'test_type',
  //     dataIndex: 'test_type',
  //     title: 'Test Type',
  //     render: (test_type) => <span className='nowrap'>{test_type ? test_type : ''}</span>
  //   },
  //   {
  //     key: 'pool_checkbox',
  //     dataIndex: 'pool_checkbox',
  //     title: 'Pool',
  //     render: (pool_checkbox) => (
  //       <span className='nowrap'>
  //         <input type='checkbox' checked={pool_checkbox} />
  //       </span>
  //     )
  //   },
  //   {
  //     key: 'pool_notes',
  //     dataIndex: 'pool_notes',
  //     title: 'Pool Notes',
  //     render: (pool_notes) => <span className='nowrap'>{pool_notes ? pool_notes : ''}</span>
  //   }
  // ];
  const paggination = (value) => {
    console.log('page value', value);
    // setPagging(value);
    // this.setState({ pagging: e.target.value})
    setTimeout(() => {
      setWijmoFlexGrid(generatedPoolEmployee, value);
    }, 500);
  };
  const setWijmoFlexGrid = (data, page) => {
    console.log('pagging', data, page);
    let views = new wijmo.CollectionView(data, { pageSize: Number(page) });
    console.log('views', views);
    setView(views);
    setViewIndex(views.pageIndex + 1);
    setViewCount(views.pageCount);
    //pagination records
    setTimeout(() => {
      setPagginationNumRecord({
        currentRecord: document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
        totalRecord: data.length
      });
    }, 2000);
  };
  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    // var self = this;
    // console.log("inside formatitem",e.panel)
    // console.log("sender.topLeftCells",sender.topLeftCells)
    // console.log("aaaaaaaaaaa",sender.topLeftCells===e.panel)
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    flexes = ctl;
    // var filter = new wjcGridFilter.FlexGridFilter(flexes);
    // console.log('filter', filter);
    // filter.getColumnFilter('create_date').valueFilter.maxValues = 3000;
    setListboxColumns(ctl.columns);

    const sender = ctl;
    sender.select(-1, -1);
    sender.addEventListener(sender.hostElement, 'click', (e) => {
      let ht = sender.hitTest(e);
      if (ht._p._rng._row !== -1) {
        let selectedRows = sender.cells._rows[ht.row]._data;
        if (ht.col < 7) {
          // if(ht.col > 0 && ht.col < 5){
          setVisible(true);
          // <EmployeePoolSelectList SlectedEmployeeDetail={SlectedEmployeeDetail}/>
          // setInfoVisible(true);
          console.log('selcted rows ==', selectedRows);
          infoRecordd = selectedRows.events;

          setInfoRecord(infoRecordd);

          console.log('selcted rows infoRecord', infoRecord);
        }
        // if(ht.col === 0){
        //   console.log("selcted rows",selectedRows)
        //   history.push({
        //     pathname: "employeeDetails",
        //     state: { employee_data: selectedRows}
        //   });
        // }
      }
    });
  };
  const updateViewPager = (target) => {
    console.log('target', target);
    let btn = wijmo.closest(target, 'button'),
      id = btn ? btn.id : '';
    switch (id) {
      case 'btnFirst':
        view.moveToFirstPage();
        break;
      case 'btnPrev':
        view.moveToPreviousPage();
        break;
      case 'btnNext':
        view.moveToNextPage();
        break;
      case 'btnLast':
        view.moveToLastPage();
        break;
    }
    setViewIndex(view.pageIndex + 1);
    setViewCount(view.pageCount);
  };
  console.log({ infoRecord });
  return (
    <div style={{ padding: 20 }}>
      <Modal
        width={1200}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        destroyOnClose
        title={
          <div>
            <Row>
              <Col span={7}>
                <span>
                  <h4 className='title'>Employee Selected List </h4>
                </span>
              </Col>
              <Col span={7} style={{ marginTop: 6 }}>
                <span>
                  <b>Pool Name : </b>
                  {location.state && location.state.pool_name}
                </span>
              </Col>
              <Col span={10} style={{ marginTop: 6 }}>
                <span>
                  <b> Cycle : &nbsp;</b>
                  {location.state && location.state.cycle}
                  {/* {location.state.cycle == 'Monthly' &&
                    moment(location.state.create_date).add(1, 'months').format('MMMM,Y')}
                  {location.state.cycle == 'Bimonthly' &&
                    (moment(location.state.create_date).format('M') % 2 == 0
                      ? moment(location.state.create_date).add(1, 'months').format('MMMM,Y') +
                        ' - ' +
                        moment(location.state.create_date).add(2, 'months').format('MMMM,Y')
                      : moment(location.state.create_date).add(2, 'months').format('MMMM,Y') +
                        ' - ' +
                        moment(location.state.create_date).add(3, 'months').format('MMMM,Y'))}
                  {location.state.cycle == 'Quarterly' &&
                    (moment(location.state.create_date).format('M') == 1 ||
                    moment(location.state.create_date).format('M') == 2 ||
                    moment(location.state.create_date).format('M') == 3
                      ? moment('4', 'M').format('MMMM,Y') +
                        ' - ' +
                        moment('6', 'M').format('MMMM,Y')
                      : moment(location.state.create_date).format('M') == 4 ||
                        moment(location.state.create_date).format('M') == 5 ||
                        moment(location.state.create_date).format('M') == 6
                      ? moment('7', 'M').format('MMMM,Y') +
                        ' - ' +
                        moment('9', 'M').format('MMMM,Y')
                      : moment(location.state.create_date).format('M') == 7 ||
                        moment(location.state.create_date).format('M') == 8 ||
                        moment(location.state.create_date).format('M') == 9
                      ? moment('10', 'M').format('MMMM,Y') +
                        ' - ' +
                        moment('12', 'M').format('MMMM,Y')
                      : moment(location.state.create_date).format('M') == 10 ||
                        moment(location.state.create_date).format('M') == 11 ||
                        moment(location.state.create_date).format('M') == 12
                      ? moment('1', 'M').format('MMMM,Y') +
                        ' - ' +
                        moment('3', 'M').format('MMMM,Y')
                      : '')}
                  {location.state.cycle == 'Annual' &&
                    moment(location.state.create_date).format('MMMM,Y') +
                      ' - ' +
                      moment(location.state.create_date).add(1, 'years').format('MMMM,Y')} */}
                </span>
              </Col>
              {/* <Col span={1} style={{ marginTop: 4 }}>
                <Tooltip title='Export List to csv'>
                  <span>
                    <i
                      class='icofont-download'
                      onClick={downloadDataFile}
                      style={{ fontSize: 22, color: 'red', cursor: 'pointer' }}
                    ></i>
                  </span>
                </Tooltip>
              </Col>
              <Col span={1} style={{ marginTop: 4 }}>
                <Tooltip title='Export List to PDF'>
                  <span>
                    <i
                      class='icofont-file-pdf'
                      onClick={() => print()}
                      style={{ fontSize: 22, color: 'green', cursor: 'pointer' }}
                    ></i>
                  </span>
                </Tooltip>
              </Col> */}
              {/* <Col span={2} style={{ marginTop: 4 }}>
              <Button 
      size='small'
      //onClick={displayEmpList}
      >All Logs</Button>
              </Col> */}
            </Row>
          </div>
        }
      >
        <p>
          {/* <Table
            dataSource={SlectedEmployeeDetail}
            columns={selectedEmpColumn}

          /> */}
          {console.log('infoRecord before comp', infoRecord)}
          {infoRecord && infoRecord.length > 0 && (
            <EmployeePoolSelectList infoRecordNew={infoRecord} />
          )}
          {/* <OpenNotificationId
            onCancel={closeModal}
            dataArr={SlectedEmployeeDetail}
            poolData={location.state}
            pool_gen_id={RecordId}
            poolRecord={poolRecord}
            RecordsLoc={RecordsLoc}
            multiLocation={multiLocation}
          /> */}
        </p>
      </Modal>
      {/* Heading Text Row */}
      <div>
        <Button
          size='small'
          style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>
      <Row>
        <Col span={8}>
          <h3>Pool Config Detail</h3>
        </Col>
      </Row>

      {/* Personel Info Section */}
      <Card style={{ backgroundColor: '#fff' }}>
        <div style={{ padding: '0, 20 ,20 ,20' }}>
          {/* Employee Information */}

          <div className='row' style={{ fontSize: 15, padding: 20 }}>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-8'>
                  <h4>
                    <u>General Pool Information</u>
                  </h4>
                </div>
              </div>
              {/* Row 1 */}
              <div className='row gy-20' style={{ padding: 0 }}>
                <div className='col-md-2 col-sm-3 col-6 ' style={{ maxWidth: '12%' }}>
                  <span style={{ fontWeight: 'bold', fontSize: 13 }}>Pool Name:</span>
                </div>
                <div className='col-md-2 col-sm-3 col-6'>
                  <p style={{ fontSize: 12 }}>{pooldat.length > 0 && pooldat[0].pool_name}</p>
                </div>
                {/* Parent Client Id */}
                <div className='col-md-2 col-sm-3 col-6' style={{ maxWidth: '12%' }}>
                  <span style={{ fontWeight: 'bold', fontSize: 13 }}>Company Type:</span>
                </div>
                <div className='col-md-2 col-sm-3 col-6'>
                  <p style={{ fontSize: 12 }}>{pooldat.length > 0 && pooldat[0].company_type} </p>
                </div>
                <div className='col-md-2 col-sm-3 col-6' style={{ maxWidth: '12%' }}>
                  <span style={{ fontWeight: 'bold', fontSize: 13 }}>Cycle:</span>
                </div>
                <div className='col-md-2 col-sm-3 col-6'>
                  <p style={{ fontSize: 12 }}>
                    {pooldat.length > 0 && pooldat[0].selection_period == 'Bimonthly'
                      ? 'Bimonthly'
                      : pooldat.length > 0 && pooldat[0].selection_period}
                  </p>
                </div>
              </div>
              <br />
              {/* row 2 */}
              <div className='row gy-20' style={{ padding: 0 }}>
                {/* Program id */}
                <div className='col-md-2 col-sm-3 col-6' style={{ maxWidth: '12%' }}>
                  <span style={{ fontWeight: 'bold', fontSize: 13 }}>Company:</span>
                </div>
                <div className='col-md-2 col-sm-3 col-6'>
                  <p style={{ fontSize: 12 }}>
                    {multiCompanyNames.length > 0
                      ? multiCompanyNames.map((k) => <p>{k.name}</p>)
                      : pooldat[0] && pooldat[0].company[1]}
                  </p>
                </div>
                {/* Parent Client Id */}

                {/* <div className='col-md-3'>
                  <b>Package:</b>
                </div>
                <div className='col-md-3'>
                  <p style={{ fontSize: 12 }}>{pooldat[0].packages[1]}</p>
                </div> */}
                <div className='col-md-2 col-sm-3 col-6' style={{ maxWidth: '12%' }}>
                  <span style={{ fontWeight: 'bold', fontSize: 13 }}>DT Program:</span>
                </div>
                <div className='col-md-2 col-sm-3 col-6'>
                  <p style={{ fontSize: 12 }}>{pooldat.length > 0 && pooldat[0].program}</p>
                </div>
                <div className='col-md-2 col-sm-3 col-6' style={{ maxWidth: '12%' }}>
                  <span style={{ fontWeight: 'bold', fontSize: 13 }}>DT Mode:</span>
                </div>
                <div className='col-md-2 col-sm-3 col-6'>
                  <p style={{ fontSize: 12 }}>{pooldat.length > 0 && pooldat[0].Mode}</p>
                </div>
              </div>

              <div className='row gy-20' style={{ padding: 0 }}>
                {/* Program id */}

                {/* Program Start Date */}
              </div>
              <br />
              <div className='row'>
                <div className='col-md-2 col-sm-3 col-6' style={{ maxWidth: '12%' }}>
                  <span style={{ fontWeight: 'bold', fontSize: 13 }}>Reason:</span>
                </div>
                <div className='col-md-2 col-sm-3 col-6'>
                  <p style={{ fontSize: 12 }}>{pooldat.length > 0 && pooldat[0].reasons}</p>
                </div>
              </div>
              <br />
              <div className='row'>
                {/* Program id */}
                <div className='col-md-2' style={{ maxWidth: '15%' }}>
                  <span style={{ fontWeight: 'bold', fontSize: 13 }}>Location:</span>
                </div>
                <div className='col-md-9'>
                  {/* {RecordsLoc.length > 0 &&
                    RecordsLoc.map((locObj, idx) => (
                      <span
                        style={{
                          fontSize: 12,
                          border: '1px solid #bdb5b5',
                          padding: 5,
                          marginRight: 12,
                          display: 'inline-block',
                          marginBottom: 5
                        }}
                      >
                        {locObj.name}
                      </span>
                    ))} */}
                  {multiLocation.length > 0 &&
                    multiLocation.map((k) => (
                      <span
                        style={{
                          fontSize: 12,
                          border: '1px solid #bdb5b5',
                          padding: 5,
                          marginRight: 12,
                          display: 'inline-block',
                          marginBottom: 5
                        }}
                      >
                        {k.name}
                      </span>
                    ))}
                </div>

                {/* Program Start Date */}
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card style={{ backgroundColor: '#fff' }}>
        <div style={{ padding: '0, 20 ,20 ,20' }}>
          <div className='row' style={{ fontSize: 13, padding: 20 }}>
            <div className='col-md-12'>
              <h4>
                <u>Rules</u>
              </h4>
              {PoolRulesData.length > 0 && <PoolRulesTable PoolRulesData={PoolRulesData} />}
              {/* {PoolRulesData.length > 0 &&
                PoolRulesData.map((dat) => (
                  <div className='row gy-20' style={{ marginBottom: 15 }}>
                    <div className='col-xl-1 col-lg-3 col-md-3 col-sm-3 col-6'>
                      <span style={{ fontWeight: 700 }}>Test:</span>
                    </div>
                    <div className='col-xl-1 col-lg-3 col-md-3 col-sm-3 col-6'>
                      <p style={{ fontSize: 13 }}>{dat.drug_alcohol}</p>
                    </div>
                    <div className='col-xl-1 col-lg-3 col-md-3 col-sm-3 col-6'>
                      <span style={{ fontWeight: 700 }}>Package:</span>
                    </div>
                    <div className='col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6'>
                      <p style={{ fontSize: 13 }}>
                        {dat.name ? dat.name : 'No package selected'}
                      </p>
                    </div>
                    <div className='col-xl-1 col-lg-3 col-md-3 col-sm-3 col-6'>
                      <span style={{ fontWeight: 700 }}>
                        {dat.num_per == 'Number' ? 'Cycle:' : 'Yearly:'}
                      </span>
                    </div>
                    <div className='col-xl-1 col-lg-3 col-md-3 col-sm-3 col-6'>
                      <p style={{ fontSize: 13 }}>{dat.percentage_yearly}</p>
                    </div>
                    <div className='col-xl-1 col-lg-3 col-md-3 col-sm-3 col-6'>
                      <span style={{ fontWeight: 700 }}>
                        {dat.num_per == 'Number' ? 'Buffer:' : 'Buffer:'}
                      </span>
                    </div>
                    <div className='col-xl-1 col-lg-3 col-md-3 col-sm-3 col-6'>
                      <p style={{ fontSize: 13 }}>{dat.buffer_percentage}</p>
                    </div>

                    <div
                      className='col-xl-1 col-lg-3 col-md-3 col-sm-3 col-6'
                      style={{ padding: '0px !important' }}
                    >
                      <span style={{ fontWeight: 700, whiteSpace: 'nowrap' }}>Parameter:</span>
                    </div>
                    <div className='col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6'>
                      <p style={{ fontSize: 13 }}>{dat.num_per}</p>
                    </div>
                  </div>
                ))} */}
              <br />
              {/* Row 2.2 */}

              <div className='row'>
                {/* Program id */}
                {/* <div className='col-md-3'>
                  <b>Cycle:</b>
                </div>
                <div className='col-md-3'>
                  <p style={{ fontSize: 12 }}>{pooldat[0].selection_period}</p>
                </div> */}
                {/* Parent Client Id */}
                {/* <div className='col-md-3'>
                  <b>Package:</b>
                </div>
                <div className='col-md-3'>
                <p  style={{fontSize: 12}}>{pooldat[0].packages[1]}</p>
                </div> */}
              </div>
            </div>
          </div>

          {/* </Card> */}
        </div>
      </Card>
      {/* <div className='row' style={{marginTop:0}}>
                  <div className='col-md-4 col-sm-4'>
                  <Button type='primary' style={{ width: '50%' }}
                      //onClick={generatePool}
                    >Generate Pool</Button>
                  </div>
                  <div className='col-md-5 col-sm-5'>
                  </div>
                </div> */}
      <Card style={{ backgroundColor: '#fff' }}>
        <div style={{ padding: '0, 20 ,20 ,20' }}>
          {/* <Card> */}
          <h4 style={{ padding: 20 }}>
            <u>Pool Generated</u>
            {/* <span>
              <Button
                size='small'
                style={{ marginLeft: 20, width: '12%', backgroundColor: '#1c3a6a' }}
                onClick={generatePool}
              >
                Generate Pool
              </Button>
            </span> */}
          </h4>
          <div className='row' style={{ padding: 20 }}>
            <div className='col-12 col-md-12 col-xl-12'>
              {/* <Table
                loading={isLoadingNNN}
                dataSource={generatedPoolEmployee}
                columns={columnsN}
                // dataSource={records}
                // columns={columns}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      //console.log('record', record);
                      setVisible(true);
                    }
                  };
                }}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: (event) => {
                //       displayEmployeeInfo(event);
                //       console.log('record', record);
                //       setInfoRecord(record);
                //       console.log('infoRecord', infoRecord);
                //     }
                //   };
                // }}
              /> */}
              <Row gutter={[32, 32]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                  <FlexGridSearch
                    ref={theSearch}
                    placeholder='Search'
                    cssMatch=''
                    style={{ width: '70%', height: '40px' }}
                  />
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                  <div ref={fixedContainerRef}>
                    <Select
                      // showSearch
                      style={{ width: 180 }}
                      placeholder={`Items per page: ${pagging}`}
                      // optionFilterProp='children'
                      onChange={paggination}
                      options={[
                        { label: 10, value: 10 },
                        { label: 20, value: 20 },
                        { label: 50, value: 50 }
                      ]}
                      getPopupContainer={() => fixedContainerRef.current}
                    />
                  </div>
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#3d586a', float: 'right' }}
                    disabled={isExcelPreparing}
                    onClick={exportToExcel}
                  >
                    {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                  </Button>
                </Col>
              </Row>

              <FlexGrid
                ref={theGrid}
                id='theGridTallRows'
                className='poolGenerated'
                alternatingRowStep={1}
                // autoRowHeights={true}
                itemsSource={view}
                initialized={gridInitialized}
                formatItem={formatItem}
                itemsPerPage={10}
                isReadOnly={true}
              >
                <div style={{ display: 'none' }}>
                  <wjInput.ListBox
                    className='column-picker'
                    itemsSource={listboxColumns}
                    checkedMemberPath='visible'
                    displayMemberPath='header'
                    lostFocus={hidePicker}
                    initialized={initializedListBox}
                  ></wjInput.ListBox>
                </div>
                <FlexGridColumn
                  binding='newCreateDate'
                  header='Pool Created Date'
                  minWidth={180}
                  width='*'
                />

                <FlexGridColumn
                  binding='newCycleMonth'
                  header='Cycle Month'
                  minWidth={100}
                  width='*'
                />

                <FlexGridColumn binding='all_emp' header='All Employees' minWidth={180} width='*' />

                <FlexGridColumn
                  binding='select_perc'
                  header='Per Cycle Employees'
                  width='*'
                  minWidth={180}
                />

                <FlexGridColumn
                  binding='drug_count'
                  header='Drug Employees'
                  minWidth={150}
                  width='*'
                />

                <FlexGridColumn
                  binding='drug_alcohol_count'
                  header='Drug/Alcohol Employees'
                  minWidth={200}
                  width='*'
                />
                <FlexGridColumn
                  binding='buffer_perc'
                  header='Buffer Employees'
                  minWidth={150}
                  width='*'
                />
                <FlexGridFilter
                  filterColumns={[
                    'newCreateDate',
                    'newCycleMonth',
                    'all_emp',
                    'select_perc',
                    'drug_count',
                    'drug_alcohol_count',
                    'buffer_perc'
                  ]}
                />
              </FlexGrid>

              <div style={{ display: 'flex' }}>
                <div
                  onClick={(e) => updateViewPager(e.target)}
                  style={{
                    backgroundColor: '#425a81',
                    color: '#fff',
                    display: 'inline',
                    // padding: '10px',
                    borderRadius: '5px',
                    paddingTop: '5px'
                  }}
                >
                  <button
                    id='btnFirst'
                    style={{ color: '#fff', backgroundColor: '#425a81' }}
                    className='btn'
                  >
                    <span className='wj-glyph-step-backward'></span>
                  </button>
                  <button
                    id='btnPrev'
                    className='btn'
                    style={{ color: '#fff', backgroundColor: '#425a81' }}
                  >
                    <span className='wj-glyph-left'></span>
                  </button>
                  &nbsp;&nbsp;&nbsp;Page {viewIndex} of {viewCount}&nbsp;&nbsp;&nbsp;
                  <button
                    id='btnNext'
                    className='btn'
                    style={{ color: '#fff', backgroundColor: '#425a81' }}
                  >
                    <span className='wj-glyph-right'></span>
                  </button>
                  <button
                    id='btnLast'
                    className='btn'
                    style={{ color: '#fff', backgroundColor: '#425a81' }}
                  >
                    <span className='wj-glyph-step-forward'></span>
                  </button>
                </div>
                {/* {pagginationNumRecord.currentRecord !== undefined ? (
              <p
                style={{ marginTop: '2%', marginLeft: '2%', fontWeight: 'bold' }}
              >{`${pagginationNumRecord.currentRecord} out of ${pagginationNumRecord.totalRecord}`}</p>
            ) : (
              ''
            )} */}
              </div>
              <Modal
                width={1000}
                visible={visibleEdit}
                footer={[
                  <Button
                    loading={loaderValGenerate}
                    style={{ backgroundColor: '#33b5e5' }}
                    //loading={loaderValue}
                    onClick={updatePoolRules}
                  >
                    Regenerate Pool
                  </Button>,
                  <Button
                    style={{ backgroundColor: '#ff4444' }}
                    onClick={() => setVisibleEdit(false)}
                  >
                    Cancel
                  </Button>
                ]}
                onCancel={() => setVisibleEdit(false)}
                destroyOnClose
                title={
                  <div>
                    <Row>
                      <Col span={24}>
                        <span>
                          <span className='title'>Edit Pool Rules</span>
                        </span>
                      </Col>
                    </Row>
                  </div>
                }
              >
                <>
                  {/* <div className='row'>
                    <div
                      className='col-md-4 col-sm-12'
                      style={{ fontWeight: 700, fontSize: 14, color: '#1C3A6A', marginLeft: 5 }}
                    >
                      <FormItem label='Cycle'>
                        <Select value={cycle} onChange={(event) => setCycle(event)}>
                          <Option value='by_Monthly'>By Monthly</Option>
                          <Option value='Monthly'>Monthly</Option>
                          <Option value='Quarterly'>Quarterly</Option>
                          <Option value='Annual'>Annual</Option>
                        </Select>
                      </FormItem>
                    </div>
                  </div> */}
                  <Row>
                    <Col span={2}>
                      <span style={{ fontSize: 19, padding: 5, fontWeight: 700 }}>
                        <u>Rules</u>:
                      </span>
                    </Col>
                    <Col span={2}>
                      <Button
                        //variant='contained'
                        //  disabled={!this.state.section4}
                        style={{
                          backgroundColor: 'rgba(255,122,90,0.90)',
                          color: 'white',
                          height: '30px',
                          marginTop: 0
                        }}
                        onClick={() => {
                          setNewChangeOne(false);
                          poolRulesArray.push({
                            drug_alcohol: '',
                            packageid: '',
                            packagename: '',
                            percentage_yearly: '',
                            buffer_percentage: '',
                            parameter: ''
                          });
                          setNewChangeOne(!newChangeOne);

                          // console.log('PoolRulesData::', PoolRulesData);
                          console.log('poolRulesArray::', poolRulesArray);
                          poolRulesArray.map((y) => {
                            y.parameter = paraVal;
                          });
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                    <Col span={4}>
                      <Select
                        defaultValue=''
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                        style={{ marginTop: -5 }}
                        value={paraVal}
                        onChange={(e) => {
                          setParameter(e);
                          poolRulesArray.map((x) => {
                            x.parameter = e;
                          });
                          // console.log("json::",JSON.parse(e).product_id[0])
                          // poolRulesArray[idx].drug_alcohol = e;
                          // setMedicationTrue(!MedicationTrue);
                        }}
                      >
                        <Option value=''>--Select Parameter--</Option>
                        <Option value='Number'>Number</Option>
                        <Option value='Percentage'>Percentage</Option>
                        {/* <Option value="Both">Both</Option> */}
                      </Select>
                    </Col>
                  </Row>
                  {poolRulesArray.length > 0 &&
                    poolRulesArray.map((data, idx) => (
                      <div
                        key={idx}
                        style={{
                          //border: '1px solid grey', borderRadius: 25,
                          padding: 5,
                          marginTop: 10
                        }}
                      >
                        <div className='row'>
                          <div className='col-md-2 col-sm-12'>
                            <FormItem label='Drug/Alcohol'>
                              <Select
                                defaultValue=''
                                showSearch
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) === 0
                                }
                                value={poolRulesArray[idx].drug_alcohol}
                                onChange={(e) => {
                                  // console.log("json::",JSON.parse(e).product_id[0])
                                  poolRulesArray[idx].drug_alcohol = e;
                                  setMedicationTrue(!MedicationTrue);
                                }}
                              >
                                <Option value='Drug'>Drug</Option>
                                <Option value='Alcohol'>Alcohol</Option>
                                {/* <Option value='Both'>Both</Option> */}
                              </Select>
                            </FormItem>
                          </div>
                          <div className='col-md-2 col-sm-12'>
                            <span
                              label='Package'
                              style={{ fontWeight: 700, fontSize: 14, color: '#1C3A6A' }}
                            >
                              Package:
                            </span>

                            <Select
                              style={{ marginTop: 16 }}
                              defaultValue=''
                              showSearch
                              optionFilterProp='children'
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) ===
                                0
                              }
                              value={poolRulesArray[idx].packagename}
                              onChange={(e) => {
                                console.log('json::', JSON.parse(e).product_id[0]);
                                poolRulesArray[idx].packageid = Number(JSON.parse(e).product_id[0]);
                                poolRulesArray[idx].packagename = JSON.parse(e).product_id[1];
                                setMedicationTrue(!MedicationTrue);
                              }}
                              //onChange={(e) => setPackageName(e)}
                              //loading={loadingPackage}
                            >
                              {/* <Option value="">--Select Package--</Option> */}
                              {compPackage.length > 0 &&
                                compPackage.map((compObj) => (
                                  <Option value={JSON.stringify(compObj)}>
                                    {compObj.display_name}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                          <div className='col-md-2 col-sm-12'>
                            <FormItem label='Number/Percentage'>
                              <Select
                                showSearch
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) === 0
                                }
                                defaultValue=''
                                value={poolRulesArray[idx].parameter}
                                onChange={(e) => {
                                  poolRulesArray[idx].parameter = paraVal;
                                  setMedicationTrue(!MedicationTrue);
                                }}
                                //onChange={(e) => setPackageName(e)}
                                //loading={loadingPackage}
                              >
                                <Option value='Number'>Number</Option>
                                <Option value='Percentage'>Percentage</Option>
                              </Select>
                            </FormItem>
                          </div>
                          {/* Pool Manger */}
                          <div className='col-md-2 col-sm-12'>
                            <FormItem
                              label={
                                poolRulesArray[idx].parameter == 'Number'
                                  ? 'Number in cycle'
                                  : 'Yearly'
                              }
                            >
                              <Input
                                defaultValue=''
                                value={poolRulesArray[idx].percentage_yearly}
                                onChange={(e) => {
                                  poolRulesArray[idx].percentage_yearly = Number(e.target.value);
                                  setMedicationTrue(!MedicationTrue);
                                }}
                                // onChange={addBufferPercentageYearly}
                              ></Input>
                            </FormItem>
                          </div>
                          <br />

                          {/* Activation Date */}
                          <div className='col-md-2 col-sm-12'>
                            <FormItem label='Buffer'>
                              <Input
                                defaultValue=''
                                value={poolRulesArray[idx].buffer_percentage}
                                onChange={(e) => {
                                  poolRulesArray[idx].buffer_percentage = Number(e.target.value);
                                  setMedicationTrue(!MedicationTrue);
                                }}

                                // onChange={(event) => setBufferName(event.target.value)}
                              ></Input>
                            </FormItem>
                          </div>

                          {/* Selection Period */}
                          <div className='col-md-2 col-sm-12'>
                            <Tooltip title='Delete'>
                              <i
                                className='fa fa-times'
                                style={{
                                  float: 'left',
                                  padding: 5,
                                  cursor: 'pointer',
                                  fontSize: 20,
                                  marginTop: 40
                                }}
                                onClick={() => {
                                  console.log('poolRulesArray before::', poolRulesArray, idx);
                                  console.log('c be', NewChangeOneNN);
                                  // setNewChangeOneN(false);
                                  poolRulesArray.splice(idx, 1);
                                  setpoolRulesArray(poolRulesArray);
                                  setNewChangeOneNN(!NewChangeOneNN);
                                  console.log('poolRulesArray after::', poolRulesArray, idx);
                                  console.log('poolRulesArray af', NewChangeOneNN);
                                }}
                              ></i>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                  <br />
                </>
              </Modal>

              <Modal
                width={500}
                visible={visibleDel}
                footer={[
                  <Button
                    style={{ backgroundColor: '#33b5e5' }}
                    loading={loaderValue}
                    onClick={deletePoolGenerated}
                  >
                    Delete
                  </Button>,
                  <Button
                    style={{ backgroundColor: '#ff4444' }}
                    onClick={() => setVisibleDel(false)}
                  >
                    Cancel
                  </Button>
                ]}
                onCancel={() => setVisibleDel(false)}
                destroyOnClose
                title={
                  <div>
                    <Row>
                      <Col span={24}>
                        <span>
                          <span className='title'>
                            Are you sure, you want to delete this generated pool?{' '}
                          </span>
                        </span>
                      </Col>
                    </Row>
                  </div>
                }
              >
                <></>
              </Modal>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
