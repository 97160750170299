import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  Spin,
  Space,
  Row,
  Col,
  Input,
  Form,
  Card,
  Modal,
  Table,
  Button,
  notification,
  DatePicker,
  Select,
  message
} from 'antd';

import Highlighter from 'react-highlight-words';
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting';
import { SearchOutlined } from '@ant-design/icons';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';

const config = require('../keyFront');
const { RangePicker } = DatePicker;
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'covid_vulnerable.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const CovidVulnerable = () => {
  const [companyName, setCompanyName] = useState('');
  const [tableFilterData, setTableFilterData] = useState([]);
  const [NoRecFound, setNoRecFound] = useState(false);
  const [filterType, setFilterType] = useState('All');
  const [covidVulnerData, setCovidVulnerData] = useState([]);
  const [covidVulnerDataCounts, setCovidVulnerDataCounts] = useState(0);
  const [approvedVacc, setApprovedVacc] = useState([]);
  const [declinedVacc, setDeclinedVacc] = useState([]);
  const [pendingVacc, setPendingVacc] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [covidLocation, setCovidLocation] = useState({});
  const [change, setChange] = useState(false);
  const [errorMsg, setErrMsg] = useState('');
  const [hasPermission, setHasPermission] = useState(false);
  const [table, setTable] = useState([]);
  const [covidVulnearbleCheck, setCovidVulnerableCheck] = useState(false);
  const [covidVulnerableFilter, setCovidVulnerableFilter] = useState([]);
  const [vulnerableTableBKP, setTableBKP] = useState([]);
  const [testingStatus, setTestingStatus] = useState('all');
  const [filterCovidDate, setFilterCovidDate] = useState([]);
  const [covidVulnerableData, setCovidVulnerableData] = useState([]);
  const [employeeNameRecords, setEmployeeNameRecords] = useState({});
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchColumn, setSearchColumn] = useState('');
  const [filterFlag, setFilterFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [docPermission, setDocPermission] = useState(false);
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [pagging, setPagging] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [pageApply, setPageApply] = useState(false);
  const [searchDateRange, setSearchDateRange] = useState('');
  const [latestFilterRecord, setLatestFilterRecord] = useState(
    searchDateRange != null ? true : false
  );
  const [typecheckValue, setTypeCheckValue] = useState('');

  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);

  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };

  function download(e, empId) {
    console.log('emp id', empId);
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificateForEmployee', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          empId: empId
        }
      })
      .then((response) => {
        console.log('Respone from download images', response.data);
        setChange(!change);
        setIsLoaderActive(false);
        if (Array.isArray(response.data)) {
          setIsModalVisible(true);
          setImageUrls(response.data);
        } else {
          window.open(response.data);
        }
      });
  }
  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.CovidVaccinationDocs
        }
      })
      .then((response) => {
        console.log('response docs', response);
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };
  const getCovidVulnerableData = () => {
    setIsLoading(true);
    setNoRecFound(false);
    axios
      .post(setting.serverUrl + '/api/Covid_Vulnerable', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response && response.data) {
          if (response.data.error) {
            alert('Some technical error occurred. Please try again in a bit.');
          } else if (response.status === 200) {
            if (response.data == 'Blocked') {
              // console.log('Covid Covid_Vulnerable data bl: ', response.data);
            } else {
              //console.log('Covid Covid_Vulnerable data: ', response.data);
              if (response.data == 0) {
                setNoRecFound(true);
              }

              setCovidVulnerData(response && response.data);
              // let employeeNames = {};
              // let records = response.data;
              // records.forEach((element) => {
              //   const name = element.first_name + ' ' + element.last_name;
              //   if (!employeeNames[name]) {
              //     employeeNames[name] = name;
              //   }
              // });
              // setEmployeeNameRecords(employeeNames);
              setHasPermission(true);
            }
          } else if (response.status === 201) {
            setNoRecFound(true);

            setErrMsg('Access permission not allowed.');
            setNoRecordMessage('Access permission not allowed.');
          } else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
          }
        }
      });
  };
  const getCovidVulnerableDataCount = () => {
    axios
      .post(setting.serverUrl + '/api/Covid_VulnerableCounts', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data) {
          if (response.data.error) {
            alert('Some technical error occurred. Please try again in a bit.');
          }
          if (response.status === 200) {
            //console.log('Covid Exemption Counts data: ', response.data);
            setCovidVulnerDataCounts(response.data);
          } else if (response.status === 201) {
            setNoRecFound(true);
            setNoRecordMessage('Access permission not allowed.');
          } else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
          }
        }
      });
  };

  const createTable = () => {
    setChange(true);
    let compId = localStorage.getItem('customerId');
    setCompanyName(' of ' + localStorage.getItem('displayName'));

    if (covidVulnerData?.length > 0) {
      covidVulnerData.map((x) => (x.name = x.first_name + ' ' + x.last_name));
      const ress = covidVulnerData.map((val) => {
        return {
          ...val,
          newReqDate: moment(val.create_date).format('MM/DD/YYYY'),
          newStatusDate: val.approval_date && moment(val.approval_date).format('MM/DD/YYYY'),
          newDL: val.DL != false ? val.DL : '',
          newEmpEIN: val.emp_ein != false ? val.emp_ein : '',
          newEmpSSN:
            val.emp_ssn != false && val.emp_ssn != '' && val.emp_ssn
              ? '***-**-' + val.emp_ssn?.substr(-4)
              : '',
          jobTitle: val.Job_Title != false ? val.Job_Title : '',
          departments: val.department != false ? val.department : '',
          program: val.program != false ? val.program : '',
          mode: val.mode != false ? val.mode : ''
        };
      });
      setWijmoFlexGrid(ress, 10);
      setTable(ress);
      setFilterCovidDate(ress);
      setCovidVulnerableData(ress);
      setTableBKP(ress);
      const approvedV = covidVulnerData.filter((emp) => emp.testing_status === 'approved');
      setApprovedVacc(approvedV);
      const pendingV = covidVulnerData.filter((emp) => emp.testing_status === 'pending');
      setPendingVacc(pendingV);
      const declinedV = covidVulnerData.filter((emp) => emp.testing_status === 'declined');
      setDeclinedVacc(declinedV);
    }
    else{
      setWijmoFlexGrid([], 10);
      setNoRecFound(false);
      setNoRecordMessage('No Record Found.');
    }
    setChange(false);
  };

  useEffect(() => {
    getCovidVulnerableData();
    getCovidVulnerableDataCount();
    getDocPermission();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, []);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }

  useEffect(() => {
    createTable();
  }, [covidVulnerData]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const paggination = (value) => {
    console.log('page value', value);
    setItemPerPage(value);
    setPageApply(true);
    setTimeout(() => {
      setWijmoFlexGrid(covidVulnearbleCheck ? covidVulnerableFilter : table, value);
    }, 500);
  };
  const setWijmoFlexGrid = (data, page) => {
    let views = new wijmo.CollectionView(data, { pageSize: Number(page) });
    let viewsData = new wijmo.CollectionView(data, { pageSize: Number(data && data.length) });
    console.log('views', views);
    setView(views);
    setViewData(viewsData);
    setViewIndex(views.pageIndex + 1);
    setViewCount(views.pageCount);
    //pagination records
    setTimeout(() => {
      setPagginationNumRecord({
        currentRecord: document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
        totalRecord: data.length
      });
    }, 2000);
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showReqDate = (item) => {
    return (
      <span className='nowrap'>
        {item.item.create_date ? moment(item.item.create_date).format('MM/DD/YYYY') : ''}
      </span>
    );
  };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showSSN = (item) => {
    if (item.item.newEmpSSN !== false && item.item.newEmpSSN !== null) {
      return <>{'***-**-' + item.item.newEmpSSN.substr(-4)}</>;
    } else {
      return <>{''}</>;
    }
    // return <>{item.item.emp_ssn != false ? "***-**-" + item.item.emp_ssn.substr(-4) : ''}</>;
    return <>{item.item.emp_ssn != false ? item.item.emp_ssn : ''}</>;
  };
  const showEIN = (item) => {
    return <>{item.item.newEmpEIN != false ? item.item.newEmpEIN : ''}</>;
  };
  const showDL = (item) => {
    return <>{item.item.newDL != false ? item.item.newDL : ''}</>;
  };

  const showStatusDate = (item) => {
    return (
      <span className='nowrap'>
        {item.item.approval_date ? moment(item.item.approval_date).format('MM/DD/YYYY') : ''}
      </span>
    );
  };

  const showStatus = (item) => {
    return (
      <span className='nowrap'>
        {item.item.testing_status === 'approved' ? (
          <b style={{ color: 'green' }}> Approved </b>
        ) : item.item.testing_status === 'pending' ? (
          <b style={{ color: '#ef6727' }}>Pending</b>
        ) : item.item.testing_status === 'declined' ? (
          <b style={{ color: 'red' }}>Declined</b>
        ) : (
          <b></b>
        )}
      </span>
    );
  };

  const showDoc = (item) => {
    return (
      <span className='nowrap'>
        {docPermission && item.item && item.item.emp_id ? (
          <button
            onClick={(e) => download(e, item.item.emp_id)}
            style={{
              border: 'none',
              textAlign: 'center',
              borderRadius: 5,
              backgroundColor: '#abc',
              fontweight: 'bold'
            }}
          >
            Preview
          </button>
        ) : (
          item.item.emp_id && <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
        )}
      </span>
    );
  };

  const updateViewPager = (target) => {
    console.log('target', target);
    let btn = wijmo.closest(target, 'button'),
      id = btn ? btn.id : '';
    switch (id) {
      case 'btnFirst':
        i = 1;
        view.moveToFirstPage();
        setTimeout(() => {
          setPagginationNumRecord({
            currentRecord:
              document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
            totalRecord: covidVulnearbleCheck ? covidVulnerableFilter.length : table.length
          });
        }, 1000);
        break;
      case 'btnPrev':
        if (i <= 1) {
          i = 1;
        } else {
          var a = Math.ceil(
            (covidVulnearbleCheck ? covidVulnerableFilter.length : table.length) / itemsPerPage
          );
          view.moveToPreviousPage();
          if (a === i) {
            setPagginationNumRecord({
              currentRecord:
                (covidVulnearbleCheck ? covidVulnerableFilter.length : table.length) -
                (document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1),
              totalRecord: covidVulnearbleCheck ? covidVulnerableFilter.length : table.length
            });
          } else {
            setPagginationNumRecord({
              currentRecord: pagginationNumRecord.currentRecord - itemsPerPage,
              totalRecord: covidVulnearbleCheck ? covidVulnerableFilter.length : table.length
            });
          }
          i--;
        }
        break;
      case 'btnNext':
        if (pageApply) {
          if (
            (covidVulnearbleCheck ? covidVulnerableFilter.length : table.length) ===
            document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1
          ) {
          } else {
            i = 2;
          }
          setPageApply(false);
        } else {
          if (
            i >=
            Math.ceil(
              (covidVulnearbleCheck ? covidVulnerableFilter.length : table.length) / itemsPerPage
            )
          ) {
          } else {
            i++;
          }
        }
        var a = Math.ceil(
          (covidVulnearbleCheck ? covidVulnerableFilter.length : table.length) / itemsPerPage
        );
        if (a === i) {
          setPagginationNumRecord({
            currentRecord: covidVulnearbleCheck ? covidVulnerableFilter.length : table.length,
            totalRecord: covidVulnearbleCheck ? covidVulnerableFilter.length : table.length
          });
        } else {
          setPagginationNumRecord({
            currentRecord:
              (document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1) * i,
            totalRecord: covidVulnearbleCheck ? covidVulnerableFilter.length : table.length
          });
        }
        view.moveToNextPage();
        break;
      case 'btnLast':
        i = Math.ceil(
          (covidVulnearbleCheck ? covidVulnerableFilter.length : table.length) / itemsPerPage
        );
        view.moveToLastPage();
        setPagginationNumRecord({
          currentRecord: covidVulnearbleCheck ? covidVulnerableFilter.length : table.length,
          totalRecord: covidVulnearbleCheck ? covidVulnerableFilter.length : table.length
        });
        break;
    }
    setViewIndex(view.pageIndex + 1);
    setViewCount(view.pageCount);
  };
  const handleDateRangeFilter = () => {
    const startDate = searchDateRange[0]?.format('MM/DD/YYYY');
    const endDate = searchDateRange[1]?.format('MM/DD/YYYY');
    if (startDate && endDate) {
      const matchFound = filterCovidDate.filter((item) => {
        const itemDate = new Date(item.newReqDate);
        const startDateDate = new Date(startDate);
        const endDateDate = new Date(endDate);
        return itemDate >= startDateDate && itemDate <= endDateDate;
      });
      if (matchFound.length > 0) {
        setWijmoFlexGrid(matchFound, 10);
        message.success('Match found!');
      } else {
        message.info('No match found !');
        setWijmoFlexGrid(covidVulnerableData, 10);
      }
    } else {
      message.error('Please select a date range!');

      setWijmoFlexGrid(covidVulnerableData, 10);
    }
  };
  const resetDateRangeFilter = () => {
    setSearchDateRange('');
    setLatestFilterRecord(false);
    setWijmoFlexGrid(covidVulnerableData, 10);
  };
  const filterTable = (e) => {
    setFilterType(e);
    setTestingStatus('all');
    setFilterFlag(true);
    if (e === 'Religious') {
      const religious = vulnerableTableBKP.filter((exemp) => exemp.exemption_type == 'religious');
      // setTable(religious);
      setCovidVulnerableFilter(religious);
      setWijmoFlexGrid(religious, 10);
    } else if (e === 'Medical') {
      const medical = vulnerableTableBKP.filter((exemp) => exemp.exemption_type == 'medical');
      //setTable(medical);
      setCovidVulnerableFilter(medical);
      setWijmoFlexGrid(medical, 10);
    } else if (e === 'app') {
      //setTable(approvedVacc);
      setCovidVulnerableFilter(approvedVacc);
      setWijmoFlexGrid(approvedVacc, 10);
    } else if (e === 'dec') {
      setCovidVulnerableFilter(declinedVacc);
      setWijmoFlexGrid(declinedVacc, 10);
    } else if (e === 'pend') {
      setCovidVulnerableFilter(pendingVacc);
      setWijmoFlexGrid(pendingVacc, 10);
    } else {
      createTable();
    }
    setCovidVulnerableCheck(true);
  };

  return hasPermission ? (
    <div style={{ padding: 20, marginTop: 20 }}>
      <div className='row'>
        <div className='col-12 col-md-12 col-xl-12' style={{ marginBottom: 20 }}>
          <h6 className='mt-0 mb-1' style={{ color: '#1C3A6A', fontWeight: 700, fontSize: 18 }}>
            COVID Vulnerable Data
          </h6>
        </div>
      </div>
      {NoRecFound ? (
        table.length > 0 || filterFlag ? (
          <>
            <div className='row'>
              {covidVulnerDataCounts.emp_total_count != 0 &&
                covidVulnerDataCounts.emp_total_count != undefined && (
                  <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                    <Card
                      style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                      className='animated with-shadow'
                      onClick={() => filterTable('all')}
                    >
                      <div className='row'>
                        <div className='col-12' style={{ textAlign: 'center' }}>
                          <div
                            className='count'
                            style={{
                              fontSize: 40,
                              color: '#EF6827',
                              lineHeight: 1.4,
                              fontWeight: 'bold'
                            }}
                          >
                            {covidVulnerDataCounts.emp_total_count}
                          </div>
                          <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                            Total <br /> Active Employees
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              {covidVulnerDataCounts.emp_app_count != 0 &&
                covidVulnerDataCounts.emp_app_count != undefined && (
                  <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                    <Card
                      style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                      className='animated with-shadow'
                      onClick={() => filterTable('app')}
                    >
                      <div className='row'>
                        <div className='col-12' style={{ textAlign: 'center' }}>
                          <div
                            className='count'
                            style={{
                              fontSize: 40,
                              color: '#EF6827',
                              lineHeight: 1.4,
                              fontWeight: 'bold'
                            }}
                          >
                            {approvedVacc && approvedVacc.length}
                          </div>
                          <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                            Employees with Approved Vulnerabilities
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              {covidVulnerDataCounts.emp_pend_count != 0 &&
                covidVulnerDataCounts.emp_pend_count != undefined && (
                  <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                    <Card
                      style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                      className='animated with-shadow'
                      onClick={() => filterTable('pend')}
                    >
                      <div className='row'>
                        <div className='col-12' style={{ textAlign: 'center' }}>
                          <div
                            className='count'
                            style={{
                              fontSize: 40,
                              color: '#EF6827',
                              lineHeight: 1.4,
                              fontWeight: 'bold'
                            }}
                          >
                            {pendingVacc && pendingVacc.length}
                          </div>
                          <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                            Employees with Pending Vulnerabilities
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              {covidVulnerDataCounts.emp_dec_count != 0 &&
                covidVulnerDataCounts.emp_dec_count != undefined && (
                  <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                    <Card
                      style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                      className='animated with-shadow'
                      onClick={() => filterTable('dec')}
                    >
                      <div className='row'>
                        <div className='col-12' style={{ textAlign: 'center' }}>
                          <div
                            className='count'
                            style={{
                              fontSize: 40,
                              color: '#EF6827',
                              lineHeight: 1.4,
                              fontWeight: 'bold'
                            }}
                          >
                            {declinedVacc && declinedVacc.length}
                          </div>
                          <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                            Employees with Declined Vulnerabilities
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
            </div>
            <br />

            <div>
              <Row>
                <Col span={8}>
                  <RangePicker
                    value={searchDateRange}
                    placeholder={['Start Requested Date', 'End Requested Date']}
                    allowClear={true}
                    style={{ borderRadius: 20, width: '100%', height: 40 }}
                    onChange={(dates) => {
                      setSearchDateRange(dates);
                    }}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={2}>
                  <Button
                    size='small'
                    style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', height: 35 }}
                    onClick={handleDateRangeFilter}
                  >
                    Search
                  </Button>
                </Col>
                <Col span={2}>
                  <Button
                    size='small'
                    style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', height: 35 }}
                    onClick={() => resetDateRangeFilter()}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
              <br />

              <Row gutter={[32, 32]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                  <FlexGridSearch
                    ref={theSearch}
                    placeholder='Search'
                    cssMatch=''
                    style={{ width: '70%', height: '40px' }}
                  />
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                  <div ref={fixedContainerRef}>
                    <Select
                      //showSearch
                      style={{ width: 160 }}
                      placeholder={`Items per page: ${pagging}`}
                      // optionFilterProp='children'
                      onChange={paggination}
                      options={[
                        { label: 10, value: 10 },
                        { label: 20, value: 20 },
                        { label: 50, value: 50 }
                      ]}
                      getPopupContainer={() => fixedContainerRef.current}
                    />
                  </div>
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#3d586a', float: 'right' }}
                    disabled={isExcelPreparing}
                    onClick={exportToExcel}
                  >
                    {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                  </Button>
                </Col>
              </Row>

              {/* to export entire data of table */}
              <FlexGrid
                ref={theGrid}
                style={{ display: 'none' }}
                className='dummyWijmoData'
                itemsSource={viewData}
                initialized={gridInitializedData}
                formatItem={formatItem}
                isReadOnly={true}
              >
                <FlexGridColumn binding='name' header='Employee Name' minWidth={150} width='*' />
                <FlexGridColumn binding='email' header='Email' minWidth={250} width='*' />
                <FlexGridColumn
                  binding='location_name'
                  header='Location'
                  minWidth={150}
                  width='*'
                />
                <FlexGridColumn
                  binding='exemption_type'
                  header='Exemption Type'
                  minWidth={150}
                  width='*'
                />
                <FlexGridColumn
                  binding='exemption_comment'
                  header='Exemption Comment'
                  minWidth={200}
                  wordWrap={true}
                  width='*'
                />

                <FlexGridColumn
                  binding='newReqDate'
                  header='Requested Date'
                  minWidth={150}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showReqDate} />
                </FlexGridColumn>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    return (
                      <FlexGridColumn
                        binding={
                          value === 'SSN'
                            ? 'newEmpSSN'
                            : value === 'EIN'
                            ? 'newEmpEIN'
                            : value === 'Driver License'
                            ? 'newDL'
                            : ''
                        }
                        header={
                          value === 'SSN'
                            ? 'SSN'
                            : value === 'EIN'
                            ? 'EIN'
                            : value === 'Driver License'
                            ? 'Driver License'
                            : ''
                        }
                        // width='12*'

                        minWidth={160}
                        width='*'
                      >
                        <FlexGridCellTemplate
                          cellType='Cell'
                          template={
                            value === 'SSN'
                              ? showSSN
                              : value === 'EIN'
                              ? showEIN
                              : value === 'Driver License'
                              ? showDL
                              : ''
                          }
                        />
                      </FlexGridColumn>
                    );
                  })}

                <FlexGridColumn
                  binding='jobTitle'
                  header='Job Title'
                  // width='10*'
                  minWidth={250}
                  width='*'
                />
                {/* <FlexGridColumn
                  binding='departments'
                  header='Job Position'
                  // width='10*'
                  minWidth={250}
                  width='*'
                /> */}

                <FlexGridColumn
                  binding='program'
                  header='Dot Mode'
                  // width='10*'
                  minWidth={160}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                </FlexGridColumn>

                <FlexGridColumn binding='testing_status' header='Status' minWidth={80} width='*'>
                  <FlexGridCellTemplate cellType='Cell' template={showStatus} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newStatusDate'
                  header='Status Date'
                  minWidth={140}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showStatusDate} />
                </FlexGridColumn>
              </FlexGrid>

              <FlexGrid
                ref={theGrid}
                id='theGridTallRows'
                alternatingRowStep={1}
                autoRowHeights={true}
                itemsSource={view}
                initialized={gridInitialized}
                formatItem={formatItem}
                itemsPerPage={10}
                isReadOnly={true}
              >
                <div style={{ display: 'none' }}>
                  <wjInput.ListBox
                    className='column-picker'
                    itemsSource={listboxColumns}
                    checkedMemberPath='visible'
                    displayMemberPath='header'
                    lostFocus={hidePicker}
                    initialized={initializedListBox}
                  ></wjInput.ListBox>
                </div>
                <FlexGridColumn binding='name' header='Employee Name' minWidth={150} width='*' />
                <FlexGridColumn binding='email' header='Email' minWidth={250} width='*' />
                <FlexGridColumn
                  binding='location_name'
                  header='Location'
                  minWidth={150}
                  width='*'
                />
                <FlexGridColumn
                  binding='exemption_type'
                  header='Exemption Type'
                  minWidth={150}
                  width='*'
                />
                <FlexGridColumn
                  binding='exemption_comment'
                  header='Exemption Comment'
                  minWidth={200}
                  wordWrap={true}
                  width='*'
                />

                <FlexGridColumn
                  binding='newReqDate'
                  header='Requested Date'
                  minWidth={150}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showReqDate} />
                </FlexGridColumn>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    return (
                      <FlexGridColumn
                        binding={
                          value === 'SSN'
                            ? 'newEmpSSN'
                            : value === 'EIN'
                            ? 'newEmpEIN'
                            : value === 'Driver License'
                            ? 'newDL'
                            : ''
                        }
                        header={
                          value === 'SSN'
                            ? 'SSN'
                            : value === 'EIN'
                            ? 'EIN'
                            : value === 'Driver License'
                            ? 'Driver License'
                            : ''
                        }
                        // width='12*'

                        minWidth={160}
                        width='*'
                      >
                        <FlexGridCellTemplate
                          cellType='Cell'
                          template={
                            value === 'SSN'
                              ? showSSN
                              : value === 'EIN'
                              ? showEIN
                              : value === 'Driver License'
                              ? showDL
                              : ''
                          }
                        />
                      </FlexGridColumn>
                    );
                  })}

                <FlexGridColumn
                  binding='jobTitle'
                  header='Job Title'
                  // width='10*'
                  minWidth={250}
                  width='*'
                />
                {/* <FlexGridColumn
                  binding='departments'
                  header='Job Position'
                  // width='10*'
                  minWidth={250}
                  width='*'
                /> */}

                <FlexGridColumn
                  binding='program'
                  header='Dot Mode'
                  // width='10*'
                  minWidth={160}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                </FlexGridColumn>

                <FlexGridColumn binding='testing_status' header='Status' minWidth={80} width='*'>
                  <FlexGridCellTemplate cellType='Cell' template={showStatus} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newStatusDate'
                  header='Status Date'
                  minWidth={140}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showStatusDate} />
                </FlexGridColumn>

                <FlexGridColumn binding='event_id' header='Documents' minWidth={110} width='*'>
                  <FlexGridCellTemplate cellType='Cell' template={showDoc} />
                </FlexGridColumn>
                <FlexGridFilter
                  filterColumns={[
                    'name',
                    'email',
                    'location_name',
                    'exemption_type',
                    'exemption_comment',
                    'newReqDate',
                    'testing_status',
                    'newStatusDate',
                    'newEmpEIN',
                    'newEmpSSN',
                    'newDL',
                    'jobTitle',
                    'departments',
                    'program'
                  ]}
                />
              </FlexGrid>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={(e) => updateViewPager(e.target)}
                  style={{
                    backgroundColor: '#425a81',
                    color: '#fff',
                    display: 'inline',
                    borderRadius: '5px',
                    // paddingTop: '4px',
                    height: '30px'
                  }}
                >
                  <button
                    id='btnFirst'
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    className='btn'
                  >
                    <span className='wj-glyph-step-backward'></span>
                  </button>
                  <button
                    id='btnPrev'
                    className='btn'
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  >
                    <span className='wj-glyph-left'></span>
                  </button>
                  <p style={{ display: 'inline' }}>
                    &nbsp;&nbsp;&nbsp;Page {viewIndex} of {viewCount}&nbsp;&nbsp;&nbsp;
                  </p>
                  <button
                    id='btnNext'
                    className='btn'
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  >
                    <span className='wj-glyph-right'></span>
                  </button>
                  <button
                    id='btnLast'
                    className='btn'
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  >
                    <span className='wj-glyph-step-forward'></span>
                  </button>
                </div>
                {pagginationNumRecord.currentRecord !== undefined ? (
                  <p style={{ fontWeight: 'bold' }}>
                    {' '}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {`${pagginationNumRecord.currentRecord} out of ${pagginationNumRecord.totalRecord}`}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </>
        ) : (
          <Row>
            <Col span={10}></Col>
            <Col span={8}>
              <Space size='middle' style={{ marginTop: 50 }}>
                <Spin size='large' />
              </Space>
            </Col>
          </Row>
        )
      ) : (
        <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
      )}
      <Modal
        width={1000}
        title='COVID Vulnerable Document'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls && imageUrls.length > 0 ? (
          imageUrls.map((img) => {
            console.log('img :: ', img);
            return (
              <p>
                {img.fileType &&
                (img.fileType == 'png' ||
                  img.fileType == 'PNG' ||
                  img.fileType == 'jpg' ||
                  img.fileType == 'JPG' ||
                  img.fileType == 'jpeg' ||
                  img.fileType == 'JPEG') ? (
                  <img src={img.url} />
                ) : (
                  <embed src={img.url} width='800px' height='2100px' />
                )}
              </p>
            );
          })
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )}
      </Modal>
      <DarkBackground disappear={isLoaderActive}>
        <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
      </DarkBackground>
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className="icofont-exclamation-tringle" style={{color:'red',fontSize:20}}></i>&nbsp;&nbsp; */}
        {errorMsg}
      </p>
    </div>
  );
};

export default CovidVulnerable;
