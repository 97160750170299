import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { history } from '../../redux/store';
import { Table, Button, Card, Form, Input } from 'antd';
import { Rule } from 'antd/es/form';
import { Spin, Space, Row, Col, Divider } from 'antd';
import { usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import moment from 'moment';
import _ from 'underscore';
import axios from "axios";
import setting from '../../config/setting'
import {
  IdcardOutlined,
  LockOutlined,
  MailOutlined,
  ReloadOutlined,
  SendOutlined,
  UserOutlined
} from '@ant-design/icons/lib';



const columnsNew = [
  // {
  //   key: 'first_name_emp',
  //   dataIndex: 'first_name_emp',
  //   sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
  //   title: 'Employee Name',
  //   // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
  //   // sorter: (a, b) => (a.name > b.name ? 1 : -1),
  //   render: (first_name_emp) => <strong>{first_name_emp}</strong>
  // },


  {
    title: 'Reference Id',
    key: 'refrence_id',
    dataIndex: 'refrence_id',
    sorter: (a, b) => a.refrence_id.length - b.refrence_id.length,
    // title: 'ID',
    // sorter: (a, b) => (a.id > b.id ? 1 : -1),
    render: (refrence_id) => (
      <span className='nowrap'>
        {refrence_id}
      </span>
    )
  },


  {
    title: 'Test Name',
    key: 'test_name',
    dataIndex: 'test_name',
    sorter: (a, b) => a.test_name.length - b.test_name.length,
    // title: 'ID',
    // sorter: (a, b) => (a.id > b.id ? 1 : -1),
    render: (test_name) => (
      <span className='nowrap'>
        {test_name}
      </span>
    )
  },

  {
    title: 'DOT Classification',
    key: 'dot_classification',
    dataIndex: 'dot_classification',
    sorter: (a, b) => a.dot_classification.length - b.dot_classification.length,
    render: (dot_classification) => <div
      //onClick={()=>handleShowInfo} 
      style={{ cursor: 'pointer' }}>{dot_classification == "False" ? '' : dot_classification}</div>
  },
  {
    key: 'testing_status',
    dataIndex: 'testing_status',
    title: 'Testing Status',
    sorter: (a, b) => a.testing_status.length - b.testing_status.length,
    // sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (testing_status) => <span>{testing_status}</span>
  },
  {
    key: 'create_date',
    dataIndex: 'create_date',
    title: 'Created Date',
    sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
    // sorter: (a, b) => a.age - b.age,
    render: (create_date) => (
      <span className='nowrap'>
         {create_date ? moment(create_date).format("MM/DD/YYYY HH:mm:ss") : ''}
      </span>
    )
  },

  {
    key: 'collected',
    dataIndex: 'collected',
    title: 'Collected Date',
    sorter: (a, b) => moment(a.collected).unix() - moment(b.collected).unix(),
    // sorter: (a, b) => a.age - b.age,
    render: (collected) => (
      <span className='nowrap'>
         {collected ? moment(collected).format("MM/DD/YYYY HH:mm:ss") : ''}
      </span>
    )
  },


  {
    key: 'shipped_date',
    dataIndex: 'shipped_date',
    title: 'Shipped Date',
    sorter: (a, b) => moment(a.shipped_date).unix() - moment(b.shipped_date).unix(),
    render: (shipped_date) => <span>{shipped_date ? moment(shipped_date).format("MM/DD/YYYY HH:mm:ss") : ''}</span>
  },

];

const FormValidationPage = () => {
  const location = useLocation();

  const data = location.state;

  const [employeeName, setEmployeeName] = useState([]);
  const [testArr, setTestArr] = useState([]);
  const [change, setChange] = useState(false);
  const [firstName, setEmployeeFirstName] = useState('');

  useEffect(() => {

    console.log("this.props Test History", data);
    if (data == undefined) {
      history.goBack();
    }
    else {
      setEmployeeFirstName(data['first_name'])

      getEmployee(data);
    }
  }, [])

  console.log("firstName", firstName);

  function getEmployee(data) {

    axios.get(setting.serverUrl + '/api/employeeTest', {
      // axios.get('http://localhost:3001/api/employee', {
      params:
      {
        //email:localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from employeeTest", response.data)
      if (response && response.status == 200) {

        setEmployeeName(response.data);

        setTimeout(() => {

          console.log("allTestHistory", data);
          console.log("allTestHistory employee", response.data);

          let dataArr = [];
          _.each(response.data, (resp) => {

            console.log("allTestHistory::::", resp.emp_selected);

            if (resp.emp_selected && resp.emp_selected.length > 0 && data.id == resp.emp_selected[0]) {

              dataArr.push(resp)

            }

          })

          console.log("allTestHistory dataArr::::", dataArr);

          setTestArr(dataArr);
          setChange(true);

        }, 2000)

        console.log("testArr", testArr);


      }
    })

  }

  //usePageData(pageData);
  return (
    <div style={{ padding: 20 }}>
      <Button
        size='small'
        style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <h3 style={{ padding: 10, textAlign: 'left', marginTop: 0 }}>{firstName ? firstName + " - Test History" : ''}</h3>
      <div className='col-md-12'>

        <div className='row'>

          {testArr.length > 0 ?

            <Table columns={columnsNew}
              dataSource={testArr.reverse()}
            //pagination={false}
            />

            :
            <Row>
              <Col span={change ? 7 : 10}>
              </Col>
              <Col span={12}>

                {change ?
                  <p style={{ fontSize: 18, padding: 20, color: 'red' }}>Currently there is no test history for this Employee</p>
                  :
                  <Space size="large" style={{ marginTop: 5 }}>

                    <Spin size="large" />
                  </Space>
                }
              </Col>
            </Row>}
        </div>
      </div>
    </div>
  );
};

export default FormValidationPage;
