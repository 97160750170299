//import React from 'react';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import moment from 'moment';
import {  useLocation } from 'react-router-dom';
import { Button, Card, Form, Input,  Spin, Space, Select,Collapse, DatePicker, Row, Col,Checkbox,Modal, notification, Table } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'underscore';
import { history } from '../../redux/store';
import setting from '../../config/setting'
import { IPageData } from '../../interfaces/page';
import CreateEmployee from './createEmployee';
import TextArea from 'antd/lib/input/TextArea';
import { callbackify } from 'util';
const FormItem = Form.Item;
const { Option } = Select;
const { Panel } = Collapse;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};

const pageData: IPageData = {
  title: 'Form layouts',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Home',
      route: 'default-dashboard'
    },
    {
      title: 'UI Kit ',
      route: 'default-dashboard'
    },
    {
      title: 'Form layouts'
    }
  ]
};

const vaccines = [
  'Pfizer',
  'Moderna',
  'Janssen',
  'Comirnaty',
  'Tozinameran',
  'Covishield',
  'Vaxzevria',
  'AstraZenca',
  'Takeda',
  'Spikevax',
  'Sinopharm-BIBP',
  'Sinovac-CoronaVac',
  'Covaxin',
  'Covovax',
  'Nuvaxovid'
];


const AddTestingEventView = () => {

  const [compNameN, setcompName] = useState('');
  const [eventTypeN, seteventType] = useState('');
  const [reasonN, setreason] = useState('');
  const [serviceN, setservice] = useState('');
  const [urgencyLevelN, seturgencyLevel] = useState('');
  const [testNameN, settestName] = useState('');
  const [locationEmpN, setlocationEmp] = useState('');
  const [clinicNameN, setclinicName] = useState('');
  const [statusN, setstatus] = useState('');
  const [assignToN, setassignTo] = useState([]);
  const [PackageValue, setPackageValue] = useState('');
  const [LoaderAllEmployee, setLoaderAllEmployee] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [firstNameValue, firstNameValueSearch] = useState('');
  const [lastNameValue, lastNameValueSearch] = useState('');

  const [eta_time_val, eta_time] = useState(moment());
  const [etd_time_val, etd_time] = useState(moment());
  
  const [EmployeeIds, setEmployeeIds] = useState([]);
  const [billToVal, billTo] = useState('');
  const [LoadMoreVal, setLoadMore] = useState(1);
  const [EmailsOptionCaptain, setEmailsOptionCaptain] = useState(false);
  const [EmailsOptionRecords, setEmailsOptionRecords] = useState(false);
  const [EmailsOptionGatelist, setEmailsOptionGatelist] = useState(false);
  const [EmailsOptionSchedule, setEmailsOptionSchedule] = useState(false);
  
  const [eta_time_bool_val, eta_time_bool] = useState(false);
  const [etd_time_bool_val, etd_time_bool] = useState(false);
  const [currentPageNumberVal, currentPageNumber] = useState(1);
  const [pageLimitVal, pageLimit] = useState(25);
  
  const [LoaderNN, setLoaderNN] = useState(false);
  const [LoaderEventVal, setLoaderEvent] = useState(false);
  const [EvenyComp, setEvenyComp] = useState('');
  const [comp, setComp] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loc, setLoc] = useState([]);
  const [locNew, setLocNew] = useState([]);
  const [PatArrTrue, setPatArrTrue] = useState(false);
  const [errorVal, setError] = useState(false);
  const [bool, setBool] = useState(false);
  const [boolNew, setBoolNew] = useState(false);
  const [boolN, setBoolN] = useState(false);
  const [boolNe, setBoolNe] = useState(false);
  const [boolNN, setBoolNN] = useState(false);
  const [NewChangeOneN, setNewChangeOneN] = useState(false);
  const [DateScheduled, setDateScheduled] = useState(moment());
  const [reasonNew, setReason] = useState([]);
  const [productTemplate, setProductTemplate] = useState([]);
  const [clinic, setclinic] = useState([]);
  const [testNames, setestName] = useState([]);

  const [comIDD, setCompId] = useState([]);
  const [serviceNew, setService] = useState([]);
  const [serviceNewBKP, setServiceBKP] = useState([]);
  const [testRegister, setTestRegister] = useState([]);
  const [patArr, setPatArr] = useState([]);
  const [CompNameTreeView, setCompNameTreeView] = useState('');
  const [reasonTreeValue, setreasonTreeValue] = useState('');
  const [serviceTreeValue, setserviceTreeValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleCE, setIsModalVisibleCE] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [EmpChange, setEmpChange] = useState(false);
  const [SelectedEmpRecords, setSelectedEmpRecords] = useState([]);
  const [EmpRecordsTrue, SelectedEmpRecordsTrue] = useState(false);
  
  const [EmployeeSearchListTrue, setEmployeeSearchListTrue] = useState(false);
  const [EmployeeSearchList, setEmployeeSearchList] = useState([]);
  const [CompDataNew, setCompDataNew] = useState([]);
  const [CompDataNewTrue, setCompDataNewTrue] = useState(false);
  const [selectCompanyId, setSelectCompanyId] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("")
  const [companies, setCompanies] = useState([])

  const [collectionDateValue, collectionDate] = useState("");
  const [covidTestDateValue, covidTestDate] = useState("");
  const [sampleValue, sample] = useState("");
  const [resultValue, result] = useState("");
  const [vaccineNameValue, vaccineName] = useState("");
  const [vaccineDateValue, vaccineDate] = useState("");
  const [doseValue, dose] = useState("");
  const [lotNumberValue, lotNumber] = useState("");
  const [packageCode, setPackageCode] = useState("");
  const [PropsData, setPropsData] = useState({});
  const location = useLocation();
  const [CompanyIDVal, setCompanyID] = useState("");
  const [CompanyIDTrue, setCompanyIDTrue] = useState(false);
  // const { loading, selectedRowKeys } = this.state;

  const [vesselNameVal, vesselName] = useState('');
  const [imoVal, imo] = useState('');
  const [locationsNameVal, locationsName] = useState('');
  const [staffVal, staff] = useState('');

  const [crewTypeVal, crewType] = useState('');
  const [vesselStatusVal, vesselStatus] = useState('');
  const [vacc_req_Val, vacc_req] = useState('');
  const [agencyVal, agency] = useState('');
  const [agentNameVal, agentName] = useState('');

  const [phoneVal, phone] = useState('');
  const [agencyEmailVal, agencyEmail] = useState('');
  const [poVal, po] = useState('');
  const [mileageVal, mileage] = useState('');
  const [captainVal, captain] = useState('');

  const [captainEmailVal, captainEmail] = useState('');
  const [schedule_name_Val, schedule_name] = useState('');
  const [schedule_email_Val, schedule_email] = useState('');
  const [schedule_phone_Val, schedule_phone] = useState('');
  const [eventNotesVal, eventNotes] = useState('');
  // const [emailsOptionVal, emailsOption] = useState('');

  const [vacc_completed_val, vacc_completed] = useState('');
  const [test_completed_val, test_completed] = useState('');
  const [test_request_val, test_request] = useState('');
  

  useEffect(() => {

    console.log("this.props");

    console.log("location.state",JSON.parse(localStorage.getItem('customerData')).employee_multi_select)

    // if (location && location.state) {
    //   let props = location.state;
    //   // getCompanyData('');
    //   // getViewPoolDetail(props.notification_event_id[0]);
    //   console.log('props===:::', props);
    //   setPropsData(props)
      // setSelectedCompanyName(props.pool.company[1])
      // setAllData(props)

    // }

    getCompanyData('');
    getReasons();
    getproduct_template();
    getClinics();
    getTestRegister();
    //getLocationData(JSON.parse(localStorage.getItem('customerData')).id)
    getServicesData(JSON.parse(localStorage.getItem('customerData')).id)
    // getEmployeeData(JSON.parse(localStorage.getItem('customerData')).employee_multi_select);
     

  }, [])
console.log("PropsData",PropsData)

const setAllData = (props)=> {
  // setCompanyID(props.pool.company[0])
  // setCompanyIDTrue(!CompanyIDTrue)
  // getCompanyData('dataFromPool');
  

  // getServicesData(props.pool.company[0])
  // setSelectedCompanyName(props.pool.company[1])
  // setSelectedEmpRecords([props.emp_data])
  // seturgencyLevel('Scheduled')
  // setlocationEmp(props.emp_data.emp_location[1].split(',')[1])
  // setlocationEmp(props.emp_data.emp_location[0])
  // setservice(props.emp_data.package_id)
  // setreason(props.pool.reasons)
  // setTimeout(()=>{
  //   filterCompanies(props.pool.company[0])
  // },8000)
  
}
  const getCompanyData = (datVal)=> {
    // setLoaderAllEmployee(true)
    setLoaderAllEmployee(true)
    setIsModalVisibleCE(false);
    console.log("getData :: ", localStorage.getItem('myData'), localStorage.getItem('myPass'))
    let custIdArr = [];
    axios.post(setting.serverUrl + '/api/allCustomerDataCustomerPortal', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        custId: JSON.parse(localStorage.getItem('customerData')).id
      }
    }).then(async response => {
      console.log("Respone from allCustomerData ", response.data)
      // setCompanies(response.data);
      if (response && response.status == 200) {

        // if(datVal == 'reset'){
         
        //   setCompanies(response.data);
        
        //   let selectedCmp =await response.data.filter(cmp => cmp.id == JSON.parse(localStorage.getItem('customerData')).id) 
        //   console.log("selectedCmp loc",selectedCmp)
        //   setEmployeeIds(selectedCmp[0].employee_multi_select)
        //   await getEmployeeData(selectedCmp[0].employee_multi_select);
        //   await setLoaderAllEmployee(false)
        // }
        setCompanies(response.data);
        
            let selectedCmp =await response.data.filter(cmp => cmp.id == JSON.parse(localStorage.getItem('customerData')).id) 
            console.log("selectedCmp loc",selectedCmp)
            setEmployeeIds(selectedCmp[0].employee_multi_select)
            await getEmployeeData(selectedCmp[0].employee_multi_select);
            await setLoaderAllEmployee(false)
          // }else{
        //     let selectedCmp =await response.data.filter(cmp => cmp.id == JSON.parse(localStorage.getItem('customerData')).id) 
        //     console.log("selectedCmp",selectedCmp)
        //     setEmployeeIds(selectedCmp[0].employee_multi_select)
        //     await getEmployeeData(selectedCmp[0].employee_multi_select);
        //     await setLoaderAllEmployee(false)
        //   }
        // }else{
        //   if(location && location.state){
        //   let selectedCmp =await response.data.filter(cmp => cmp.id == JSON.parse(localStorage.getItem('customerData')).id) 
        //   console.log("selectedCmp ::::",selectedCmp)
        //   setEmployeeIds(JSON.parse(selectedCmp[0].employee_multi_select))
        //   getEmployeeData(JSON.parse(selectedCmp[0].employee_multi_select))
        //   setLoaderAllEmployee(false)
        //   }
        // }
        
      }
    })
  }

console.log("EmployeeIds",EmployeeIds)
  const filterCompanies = (event) => {
    console.log("filterCompanies event :: ", event)
    console.log("companies :: ", companies)
    setEvenyComp(event)

    // let selectedCmp = companies.filter(cmp => cmp.id == event) 
    // console.log("selectedCmp::",selectedCmp)
    setCompDataNew(JSON.parse(localStorage.getItem('customerData')))
    setSelectedCompany(JSON.parse(localStorage.getItem('customerData')).id);
    setSelectedCompanyName(JSON.parse(localStorage.getItem('customerData')).display_name)
    getLocationData(JSON.parse(localStorage.getItem('customerData')).id)
    getServicesData(JSON.parse(localStorage.getItem('customerData')).id)
    getEmployeeData(JSON.parse(localStorage.getItem('customerData')).employee_multi_select);
  
  };

  console.log("CompDataNew only:::",CompDataNew)

  interface DataType {
    //key: 
    id: React.Key;
    name: string;
  }

  const columns = [
    {
      title: 'Gender',
      key: 'Gender',
      dataIndex: 'Gender',
      sorter: (a, b) => a.Gender.length - b.Gender.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (Gender) => (
        <span className='nowrap'>
          {Gender}
        </span>
      )
    },
    {
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      title: 'First Name',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (first_name) => <span>{first_name}</span>
    },
    {
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: (a, b) => a.last_name.length - b.last_name.length,
      title: 'Last Name',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (last_name) => <span>{last_name}</span>
    },
    {
      title: 'SSN',
      key: 'emp_ssn',
      dataIndex: 'emp_ssn',
      sorter: (a, b) => a.emp_ssn.length - b.emp_ssn.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (emp_ssn) => (
        <span className='nowrap'>
         {emp_ssn}
        </span>
      )
    },


    {
      key: 'dob',
      dataIndex: 'dob',
      title: 'DOB',
      // sorter: (a, b) => a.dob.length - b.dob.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (dob) => <span>{moment(dob).format("MM/DD/YYYY")}</span>
    },
    {
      title: 'Status',
      key: 'emp_status',
      dataIndex: 'emp_status',
      sorter: (a, b) => a.emp_status.length - b.emp_status.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (emp_status) => (
        <span className='nowrap'>
          {emp_status}
        </span>
      )
    },




  ];

  function getTestRegister() {

    axios.post(setting.serverUrl + '/api/getTestRegister', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from getTestRegister ", response.data)


      if (response && response.status == 200) {

        setTestRegister(response.data)

      }
    })

  }

  console.log("testRegister", testRegister)



  function getClinics() {
    setBool(true)
    axios.post(setting.serverUrl + '/api/getClinics', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from getClinics ", response.data)

      response.data.sort((a, b)=> 
        a.clinic_name.localeCompare(b.clinic_name) //using String.prototype.localCompare()
      );
      if (response && response.status == 200) {

        setclinic(response.data)
        //setBool(true)
        setBool(true)

      }
    })

  }

  

  function getproduct_template() {

    axios.post(setting.serverUrl + '/api/product_template', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from product_template ", response)

      if (response && response.status == 200) {

        setProductTemplate(response.data)
        //setBool(true)
        setBoolNew(true)

      }
    })

  }

  

  function getReasons() {
    axios.post(setting.serverUrl + '/api/getCompReasons', {
      // axios.get('http://localhost:3001/api/getReasons', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        compId:JSON.parse(localStorage.getItem('customerData')).id
      }
    }).then(response => {
      console.log("Respone from getCompReasons ", response.data)


      if (response && response.status == 200) {

        setReason(response.data.reason)
        setLocations(response.data.location)
        //setBool(true)
        setBoolN(true)

      }
    })

  }



  const getLocationData = (company) => {
    console.log("getLocationData :: ", company);
    setBoolNe(true)
    axios.post(setting.serverUrl + '/api/Res', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        id: company
      }
    }).then(response => {
      console.log("Respone from getLocationData ", response.data)


      if (response && response.status == 200) {

        setLocations(response.data)
        setBoolNe(true)

      }
    })

  }



  const getEmployeeData = async (custIdArr) => {
    console.log("ddd",firstNameValue,lastNameValue)
    // setLoaderAllEmployee(true)
    console.log("getEmployeeData ::  ", custIdArr);
    let dat = [];
    await axios.post(setting.serverUrl + '/api/employeeCustCustomerPortal', {
      //  axios.get('http://localhost:3001/api/employee',{
      params:
      {
        //email:localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        custIdArr: custIdArr,
        offset: 0,
        limit: pageLimitVal * currentPageNumberVal,
        first_name: firstNameValue,
        last_name: lastNameValue,
        compId: JSON.parse(localStorage.getItem('customerData')).id
      }
    }).then(response => {
      console.log("Respone from getData getEmployeeData", response.data)
      // setLoaderAllEmployee(false)
      response.data.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
      if (response && response.status == 200) {
        _.each(response.data, (resp) => {
          resp.key = resp.id
        })

        currentPageNumber(currentPageNumberVal + 1);

        console.log("dat", dat);
        setPatArr(response.data);
        setPatArrTrue(!PatArrTrue)

      }
    })

  }

console.log("currentPageNumberVal",currentPageNumberVal)

  function eventType(event) {
    // compName();
    console.log("eventType", event);
    seteventType(event)
  }

  const getServicesData = (company) => {

    setLoaderNN(true)
    console.log("dataNewArr::", company)

    axios.post(setting.serverUrl + '/api/hospital_customers_lines', {
      // axios.get('http://localhost:3001/api/employee',{
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        compId: company
      }
    }).then(response => {
      console.log("Respone from hospital_customers_lines ", response.data)
    //   response.data.sort((a, b)=> 
    //   a.display_name.localeCompare(b.display_name) //using String.prototype.localCompare()
    // );

      if (response && response.status == 200) {
        if(location.state){
          if(location.state["emp_data"]["package_id"] == 0){

            // let dataNewTestNames = response.data.filter(x=>x.product_id[0] == location.state["emp_data"]["package_id"])
            // console.log("dataNewTestNames",dataNewTestNames)
            setPackageCode('')
            // const dataArrTest = dataNewTestNames[0].test_name.split('\n');
            setservice('')
            // console.log("dataArrTest",dataArrTest)
            // console.log("dataArr::", dataArrTest.splice(-1))
            // setestName(dataArrTest);
        }
      else{
        let dataNewTestNames = response.data.filter(x=>x.product_id[0] == location.state["emp_data"]["package_id"])
        console.log("dataNewTestNames",dataNewTestNames)
        setPackageCode(dataNewTestNames[0].package_code==false?'False':dataNewTestNames[0].package_code)
        const dataArrTest = dataNewTestNames[0].test_name.split('\n');
        setservice(dataNewTestNames[0].product_id.length>0 ? dataNewTestNames[0].product_id[1]:'')
        console.log("dataArrTest",dataArrTest)
        console.log("dataArr::", dataArrTest.splice(-1))
        setestName(dataArrTest);
      }
    }
        setService(response.data)
        setServiceBKP(response.data)
        setLoaderNN(false)
      }

    })




  }

  console.log("serviceNewBKP",serviceNewBKP)

  const service = (event)=> {
    // compName();
    console.log("productTemplate :: ", productTemplate);
    let newTestArr = [];
    let selectedPackage = JSON.parse(event);
    console.log("service", selectedPackage);
    let pcode = ''
    if(selectedPackage){
      pcode = selectedPackage.package_code == false ? 'False' : selectedPackage.package_code
    }
    setPackageCode(pcode)
    let prodId = selectedPackage.product_id[0]
    let testId = productTemplate.length>0 && productTemplate.filter(x => x.id == prodId)[0].multi_select
    testId.map(dat => {
      let testName = testRegister.filter(test => test.id === dat)[0].test_name
      newTestArr.push(testName)
    })
    console.log("newTestArr :: ", newTestArr)
    setserviceTreeValue(selectedPackage.display_name)

    const dataArr = selectedPackage.test_name.split('\n');
    console.log("dataArr::", dataArr.splice(-1))
    setestName(newTestArr);
    setservice(selectedPackage.product_id[0])

  }
  

  console.log("testNames:::", testNames)

  function urgencyLevel(event) {
    
    console.log("urgencyLevel", event);
    // setService([])
    seturgencyLevel(event)
    if(event == 'Screening' || event == 'Background Screening'){

      let selectedCmp = serviceNewBKP.filter(cmp => (cmp.display_name !== 'Covid Vaccination' 
      && cmp.display_name !== 'Covid RT-PCR Test' && cmp.display_name !== 'Covid Antigen Test')) 
      console.log("Screening::",selectedCmp)
      selectedCmp.sort((a, b)=> 
      a.display_name.localeCompare(b.display_name) //using String.prototype.localCompare()
    );
      setService(selectedCmp)
      
      // setService(serviceNewBKP)
     
    }
    if(event == 'vaccination'){
      // setPackageValue("")
      
      let selectedCmp = serviceNewBKP.filter(cmp => cmp.display_name == 'Covid Vaccination') 
      console.log("vaccination::",selectedCmp)
      selectedCmp.sort((a, b)=> 
      a.display_name.localeCompare(b.display_name) //using String.prototype.localCompare()
    );
      setService(selectedCmp)
     
      
    }
    if(event == 'rtpcr'){
      
      let selectedCmp1 = serviceNewBKP.filter(cmp => cmp.display_name == 'Covid RT-PCR Test') 
      console.log("rtpcr::",selectedCmp1)
      selectedCmp1.sort((a, b)=> 
      a.display_name.localeCompare(b.display_name) //using String.prototype.localCompare()
    );
      setService(selectedCmp1)
      
    }
    if(event == 'antigen'){
      
      let selectedCmp2 = serviceNewBKP.filter(cmp => cmp.display_name == 'Covid Antigen Test') 
      console.log("antigen::",selectedCmp2)
      selectedCmp2.sort((a, b)=> 
      a.display_name.localeCompare(b.display_name) //using String.prototype.localCompare()
    );
      setService(selectedCmp2)
      
    }
    
  }
console.log("urgencyLevelN::",urgencyLevelN)
  function testName(event) {
    console.log("testName", event);
    settestName(event)
  }

  function locationEmp(event) {
    console.log("locationEmp", event);
    setlocationEmp(event)
  }

  function clinicName(event) {
    console.log("clinicName", event);
    setclinicName(event)
  }
  function status(event) {
    console.log("status", event);
    setstatus(event)
  }
  async function assignTo(event) {

    let empArrD = [];
    console.log("assignTo", event[0]);

    await _.each(event, (empEve) => {

      empArrD.push(JSON.parse(empEve).id);

    })

    setassignTo(empArrD)
  }

  console.log("assignToN::", assignToN)

  async function saveTest() {
    setEnableSave(true);
    
    console.log("packageCode",packageCode)
    
    // console.log("location.state",location.state["pool"]["company"][0])
    // console.log("serviceN",serviceN)
    // console.log("locationEmpN",locationEmpN)
    // console.log("urgencyLevelN",urgencyLevelN)
    console.log("SelectedEmpRecords1::",SelectedEmpRecords)
    
    if(
      
      serviceN && locationEmpN && urgencyLevelN 
      //&& SelectedEmpRecords && SelectedEmpRecords.length>0
      ){
        
        setLoaderEvent(true)
        // console.log("CompDataNew save1::::::", location.state?location.state["pool"]["company"][0]:CompDataNew[0].id)
        // console.log("CompDataNew save1::::::", eventTypeN)
        // console.log("CompDataNew Date Scheduled:::", DateScheduled)
        // console.log("CompDataNew save1::::::", location.state?location.state["emp_data"]["package_id"]:serviceN)
        // console.log("CompDataNew save1::::::", location.state?location.state["pool"]["company"][1] :CompDataNew[0].name + ' | ' + moment().format('MM/DD/YYYY HH:mm:ss') + ' | ' + serviceTreeValue + ' | ' + reasonTreeValue)
        // console.log("CompDataNew save1::::::", location.state?location.state["emp_data"]["emp_location"][0]:locationEmpN)
        // console.log("CompDataNew save1::::::", clinicNameN)
        // console.log("CompDataNew save::::::",CompDataNew[0].id)
        console.log("SelectedEmpRecords1::",SelectedEmpRecords)
        let resultEmpIdsArray = await SelectedEmpRecords.map(a => a.id);
        // console.log("::nn",CompNameTreeView + '|' + moment().format('MM/DD/YYYY HH:mm:ss') + '|' + serviceTreeValue + '|' + reasonTreeValue)
        console.log("resultEmpIdsArray1::", resultEmpIdsArray)
        
        console.log("saveTest");
        await axios.post(setting.serverUrl + '/api/saveTestCustomerPortal', {
          params:
          {
            email: localStorage.getItem('myData'),
            user: localStorage.getItem('myData'),
            pass: localStorage.getItem('myPass'),
    
            // compNameN: compNameN,
            compNameN: JSON.parse(localStorage.getItem('customerData')).id,
            eventTypeN: eventTypeN,
            reasonN: reasonN,
            serviceN: serviceN,
            urgencyLevelN: urgencyLevelN,
            tree_field: JSON.parse(localStorage.getItem('customerData')).name + ' | ' + moment().format('MM/DD/YYYY HH:mm:ss') + ' | ' + serviceTreeValue + ' | ' + reasonTreeValue,
            //testNameN: testNameN,
            locationEmpN: locationEmpN,
            clinicNameN: clinicNameN,
            statusN: statusN,
            DateScheduled:DateScheduled,
            assignToN: resultEmpIdsArray,
            SelectedEmpRecords:SelectedEmpRecords,
            package_code : packageCode,
            //poolId:location.state?location.state["pool"]["id"]:'',
            //pool_gen_id:location.state?location.state["pool_gen_id"]:'',
            
            vesselNameVal:vesselNameVal,
            imoVal:imoVal,
            locationsNameVal:locationsNameVal,
            staffVal:staffVal,
            crewTypeVal:crewTypeVal,
            vesselStatusVal:vesselStatusVal,
            vacc_req_Val:vacc_req_Val,
            agencyVal:agencyVal,
            agentNameVal:agentNameVal,
            phoneVal:phoneVal,
            agencyEmailVal:agencyEmailVal,
            poVal:poVal,
            mileageVal:mileageVal,
            captainVal:captainVal,
            captainEmailVal:captainEmailVal,
            schedule_name_Val:schedule_name_Val,
            schedule_email_Val:schedule_email_Val,
            schedule_phone_Val:schedule_phone_Val,
            eventNotesVal:eventNotesVal,
            vacc_completed_val:vacc_completed_val,
            test_completed_val:test_completed_val,
            test_request_val:test_request_val,
            EmailsOptionCaptain:EmailsOptionCaptain,
            EmailsOptionSchedule:EmailsOptionSchedule,
            EmailsOptionRecords:EmailsOptionRecords,
            EmailsOptionGatelist:EmailsOptionGatelist,
            eta_time:eta_time_bool_val == true ? eta_time_val : eta_time_val,
            etd_time:etd_time_bool_val == true ? etd_time_val : etd_time_val,
            billToVal:billToVal

          }
        }).then(response => {
          console.log("Respone from saveTest ", response.data)
    
          setLoaderEvent(false)
          if (response && response.status == 200) {
           
              notification['success']({
                message: 'Saved Successfully',
            
              })
            setError(false)
            history.goBack();
            setEnableSave(false);
            
          }else{
            notification["error"]({
              message: "Something went wrong",
              description: "Cannot save event"
            })
          }
        }).catch(() => {
        
            notification["error"]({
              message: "Something went wrong",
              description: "Cannot save event"
            })
          
        })
    }else{
      // alert("Please fill required fields")
      setError(true);
    }

  }

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const displayEmpList = () => {
    console.log("onclick")
    setIsModalVisible(true);

  }


  const rowSelection = {
    selectedRowKeys:SelectedEmpRecords.map(item => item.id),
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedEmpRecords(selectedRows)
      setEmpChange(!EmpChange)
    },
    getCheckboxProps: (record) => ({
      disabled: record.first_name === 'Disabled User',
      // Column configuration not to be checked
      first_name: record.first_name,
    }),
  };
  const handleOk = () => {
    setIsModalVisible(false);
    console.log("SelectedEmpRecords:::", SelectedEmpRecords)
  };

  const handleCreateEmployee = () => {
  console.log("handleCreateEmployee");
  setIsModalVisibleCE(true);
  };

   const  handleCancelCE = () => {
    console.log("EvenyComp",EvenyComp)
     getCompanyData('close');
    // let selectedCmp =await companies.filter(cmp => cmp.id == EvenyComp) 
    // console.log("selectedCmp",selectedCmp)
    // await setIsModalVisibleCE(false);
    // await getEmployeeData(selectedCmp[0].employee_multi_select);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };



  const searchEmployee = (event) => {

    console.log("search emp::", event.target.value)

    let employeeSearch = patArr.filter(function (e) {
      return e.first_name.toLowerCase().includes(event.target.value.toLowerCase())
        || e.last_name.toLowerCase().includes(event.target.value.toLowerCase())
        || (e.emp_status && e.emp_status.toLowerCase().includes(event.target.value.toLowerCase()));
    });
    console.log("employeeSearch:::", employeeSearch);
    // setTimeout(() => {
    setEmployeeSearchList(employeeSearch);
    setEmployeeSearchListTrue(!EmployeeSearchListTrue)


  }


console.log("EmployeeSearchList", EmployeeSearchList);

const deleteRow = (idx) => {

console.log("idx::",idx)
console.log("selectedEmployee::",SelectedEmpRecords)

SelectedEmpRecords.splice(idx,1);
// let newArr = [];
// let deleteItem = SelectedEmpRecords[idx]
// console.log("deleteItem",deleteItem)
// SelectedEmpRecords.map((dat)=>{
//   if(dat.id != deleteItem.id){
//     newArr.push(dat);
//   }
// })
// console.log("newwArr",newArr)
setSelectedEmpRecords(SelectedEmpRecords)
setNewChangeOneN(!NewChangeOneN);
}
console.log("SelectedEmpRecords2",SelectedEmpRecords)
const ResponseValData = (data) => {
  console.log("dddd:",data)
}

const sendDataToParent = (data) => { // the callback. Use a better name
  console.log("index::",data);
  // setDrive(index);
  if(data == "Added"){
    setIsModalVisibleCE(false);
    getCompanyData('close');
  }
};
console.log("eta",moment(eta_time_val).format('MM/DD/YYYY h:mm a'))
console.log("etd",moment(etd_time_val).format('MM/DD/YYYY h:mm a'))

const showTotal = (total) => {
  return (
    <p >Total {total} items</p>
  )
}

const handleLoadMore = () => {
  // getCust(this.state.selectedCustomerList);
};

const handleAdvanceFilter = () => {
  // this.currentPageNumber = 1;
  getCompanyData('')
};

const handleReset=()=> {
  firstNameValueSearch('')
  lastNameValueSearch('')
  setPatArr([])
  getDataFromcallback()
 
}

const getDataFromcallback = ()=>{
  getCompanyDataReset('') 
}

const getCompanyDataReset = (datVal)=> {
  // setLoaderAllEmployee(true)
  setLoaderAllEmployee(true)
  setIsModalVisibleCE(false);
  console.log("getData :: ", localStorage.getItem('myData'), localStorage.getItem('myPass'))
  let custIdArr = [];
  axios.post(setting.serverUrl + '/api/allCustomerDataCustomerPortal', {
    params:
    {
      email: localStorage.getItem('myData'),
      user: localStorage.getItem('myData'),
      pass: localStorage.getItem('myPass'),
      custId: JSON.parse(localStorage.getItem('customerData')).id
    }
  }).then(async response => {
    console.log("Respone from allCustomerData:: ", response.data)
    // setCompanies(response.data);
    if (response && response.status == 200) {

      // if(datVal == 'reset'){
       
      //   setCompanies(response.data);
      
      //   let selectedCmp =await response.data.filter(cmp => cmp.id == JSON.parse(localStorage.getItem('customerData')).id) 
      //   console.log("selectedCmp loc",selectedCmp)
      //   setEmployeeIds(selectedCmp[0].employee_multi_select)
      //   await getEmployeeData(selectedCmp[0].employee_multi_select);
      //   await setLoaderAllEmployee(false)
      // }
          setCompanies(response.data);
      
          let selectedCmp =await response.data.filter(cmp => cmp.id == JSON.parse(localStorage.getItem('customerData')).id) 
          console.log("selectedCmp loc",selectedCmp)
          setEmployeeIds(selectedCmp[0].employee_multi_select)
          await getEmployeeDataReset(selectedCmp[0].employee_multi_select);
          await setLoaderAllEmployee(false)
      
      
    }
  })
}

const getEmployeeDataReset = async (custIdArr) => {
  console.log("ddd getEmployeeDataReset",firstNameValue,lastNameValue)
  // setLoaderAllEmployee(true)
  console.log("getEmployeeDataReset ::  ", custIdArr);
  let dat = [];
  await axios.post(setting.serverUrl + '/api/employeeCustCustomerPortal', {
    //  axios.get('http://localhost:3001/api/employee',{
    params:
    {
      //email:localStorage.getItem('myData'),
      user: localStorage.getItem('myData'),
      pass: localStorage.getItem('myPass'),
      custIdArr: custIdArr,
      offset: 0,
      limit: 25,
      first_name: '',
      last_name: '',
      compId: JSON.parse(localStorage.getItem('customerData')).id
    }
  }).then(response => {
    console.log("Respone from getData getEmployeeData", response.data)
    // setLoaderAllEmployee(false)
    response.data.sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
    if (response && response.status == 200) {
      _.each(response.data, (resp) => {
        resp.key = resp.id
      })

      currentPageNumber(currentPageNumberVal + 1);

      console.log("dat", dat);
      setPatArr(response.data);
      setPatArrTrue(!PatArrTrue)

    }
  })

}

  return (
    <div style={{ padding: 20 }}>

      <Row>
        <Col span={16}>
          <h3>Add Schedule Event</h3>
        </Col>
        <Col span={8}>


        </Col>
      </Row>
      {bool && companies.length>0
        //&& boolNN
        ?

        <div className='row' style={{ padding: 20, backgroundColor: "#fff", borderRadius: 20 }}>


          <div className='col-md-12 col-sm-12'>
            <Spin spinning={LoaderNN}>
            <Card>
              <Form layout='vertical'>
                <div className='row'>
                

                  {/* <div className='col-md-'></div> */}
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label={errorVal ? <p>Location <span style={{color:'red'}}>*(Required field)</span></p> : <p>Location *</p>}>
                      <Select 
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                      disabled={location.state?true:false}
                      defaultValue={locationEmpN}
                        onChange={(event) => locationEmp(event)}
                      >
                        {locations.length > 0 && locations.map((loc, index) => (

                          <Option value={loc.id}>{loc.name}</Option>


                        ))}
                      </Select>
                    </FormItem>
                  </div>


                  <div className='col-md-2'></div>
                </div>


                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label={errorVal ? <p>Type of Event <span style={{color:'red'}}>*(Required field)</span></p> : <p>Type of Event *</p>}>
                      <Select 
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                      disabled={location.state?true:false}
                      defaultValue={urgencyLevelN}
                        onChange={(event) => urgencyLevel(event)}
                      >
                        <Option value="Screening">Screening</Option>
                        <Option value="Background Screening">Background Screening</Option>
                        {/* <Option value="Emergency">Emergency</Option> */}
                        <Option value="rtpcr">RT-PCR</Option>
                        <Option value="antigen">Antigen</Option>
                        <Option value="vaccination">Vaccination</Option>
                      </Select>
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label='Reason'>
                      <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      } 
                      disabled={location.state?true:false}
                      defaultValue={reasonN}
                        onChange={(event) => 
                          setreason(event)

                        }
                      >
                        {reasonNew.length > 0 && reasonNew.map((reason, index) => (

                        <Option value={reason}>{reason}</Option>
                        // <Option value="AUDIOGRAM">AUDIOGRAM</Option>
                        // <Option value="BLIND SAMPLE">BLIND SAMPLE</Option>
                        // <Option value="COURT ORDERED">COURT ORDERED</Option>
                        // <Option value="FITNESS FOR DUTY">FITNESS FOR DUTY</Option>
                        // <Option value="FOLLOW-UP">FOLLOW-UP</Option>
                        // <Option value="JOB TRANSFER">JOB TRANSFER</Option>
                        // <Option value="POST-ACCIDENT">POST-ACCIDENT</Option>
                        // <Option value="PRE-EMPLOYMENT">PRE-EMPLOYMENT</Option>
                        // <Option value="PRE-SITE ACCESS">PRE-SITE ACCESS</Option>
                        // <Option value="PROBATION">PROBATION</Option>
                        // <Option value="PROMOTION">PROMOTION</Option>
                        // <Option value="RANDOM">RANDOM</Option>
                        // <Option value="RE-CERTIFICATION">RE-CERTIFICATION</Option>
                        // <Option value="REASONABLE SUSPICION/CAUSE">REASONABLE SUSPICION/CAUSE</Option>
                        // <Option value="RETURN TO DUTY">RETURN TO DUTY</Option>
                        // <Option value="SWEEP">SWEEP</Option>
                        // <Option value="Transfer Medicals">Transfer Medicals</Option>
                        // <Option value="OTHER">OTHER</Option>
                        // <Option value="CONTRACTUAL">CONTRACTUAL</Option>
                        // <Option value="PERIODIC">PERIODIC</Option>

                         ))}
                      </Select>
                    </FormItem>
                  </div>


                  <div className='col-md-2'></div>
                </div>


                <div className="row">
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label={errorVal ? <p>Package <span style={{color:'red'}}>*(Required field)</span></p> : <p>Package *</p>}
                  >
                      <Select 
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                      disabled={location.state ? location.state["emp_data"]["package_id"] == 0 ? false : true : false }
                      defaultValue={serviceN}
                        onChange={(event) => service(event)}
                      >

                        {serviceNew.length > 0 && serviceNew.map((serv, index) => (
                          <Option value={JSON.stringify(serv)}>{serv.display_name}</Option>
                        ))}

                      </Select>
                    </FormItem>

                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label={testNames.length > 0 ? 'Tests Name' : ''}>

                      {testNames.length > 0 && testNames.map((test, index) => (

                        <p style={{ fontSize: 12 }}>{test}</p>

                      ))}

                    </FormItem>

                   
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-4 col-sm-12'>
                <FormItem label='Status'>
                      <Select defaultValue=""
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                        onChange={(event) => status(event)}
                      >
                       
                          {/* <Option value="New">New</Option> */}
                          <Option value="Scheduled">Scheduled</Option>
                          <Option value="Cancelled">Cancelled</Option>
                          <Option value="Completed">Completed</Option>

                      </Select>
                    </FormItem>
                    </div>
                    </div>
                

                {(urgencyLevelN == 'rtpcr' || urgencyLevelN == 'antigen' || urgencyLevelN == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Vessel Name'>
                      <Input 
                      value={vesselNameVal}
                      onChange={(event) => vesselName(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='IMO'>
                      <Input 
                      value={imoVal}
                      onChange={(event) => imo(event.target.value)}
                      />
                    </FormItem>
                  
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(urgencyLevelN == 'rtpcr' || urgencyLevelN == 'antigen' || urgencyLevelN == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Location'>
                      <Input 
                      value={locationsNameVal}
                      onChange={(event) => locationsName(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Staff'>
                      <Input 
                      value={staffVal}
                      onChange={(event) => staff(event.target.value)}
                      />
                    </FormItem>
                  
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }
                        {(urgencyLevelN == 'rtpcr' || urgencyLevelN == 'antigen') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Crew Type'>
                      <Input 
                      value={crewTypeVal}
                      onChange={(event) => crewType(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Mileage'>
                      <Input 
                      value={mileageVal}
                      onChange={(event) => mileage(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(urgencyLevelN == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Vessel Status'>
                  <Input 
                      value={vesselStatusVal}
                      onChange={(e) => vesselStatus(e.target.value)}
                      />
                  
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Mileage'>
                      <Input 
                      value={mileageVal}
                      onChange={(event) => mileage(event.target.value)}
                      />
                    </FormItem>
                  
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(urgencyLevelN == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Vaccination Requested'>
                      <Input 
                      value={vacc_req_Val}
                      onChange={(event) => vacc_req(event.target.value)}
                      />
                    </FormItem>
                  
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Vaccination Completed'>
                  <Input 
                      value={vacc_completed_val}
                      onChange={(e) => vacc_completed(e.target.value)}
                      />
                  
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

                {(urgencyLevelN == 'Screening' || urgencyLevelN == 'Background Screening') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Collection Site'>
                      <Select defaultValue=""
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                        onChange={(event) => eventType(event)}
                      >
                        <Option value="Onsite">Onsite</Option>
                        <Option value="clinic">clinic</Option>
                      </Select>
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12' >
                  <FormItem label='Clinic Name'>
                      <Select defaultValue=""
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                        onChange={(event) => clinicName(event)}
                      >
                        {clinic.length > 0 && clinic.map((cClinic, index) => (

                          <Option value={cClinic.id}>{cClinic.clinic_name}</Option>

                        ))}
                      </Select>
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

                {(urgencyLevelN == 'Screening' || urgencyLevelN == 'Background Screening'|| urgencyLevelN == 'antigen' || urgencyLevelN == 'rtpcr' || urgencyLevelN == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <FormItem label='Scheduled Date'>
                      <DatePicker style={{ width: '100%' }}
                        showTime={true}
                        // defaultValue=""
                        format="MM/DD/YYYY HH:mm:ss"
                        value={DateScheduled}
                        onChange={(date, dateString) => setDateScheduled(date)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12' >
                  <FormItem label='Bill To' style={{display:'none'}}>
                  <Select
                       
                       showSearch
                      disabled={location.state?true:false}
                       optionFilterProp="children"
                       defaultValue={billToVal}
                     onChange={(event) => billTo(event)}
                       
                       filterOption={(input, option) =>
                         option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                       }
                     >
                      {companies && companies.length>0 &&
                       companies.map((companyObj) => (
                         <Option value={companyObj['id']}>{companyObj.name}</Option>
                       ))}                 

                     </Select>
                     </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(urgencyLevelN == 'rtpcr' || urgencyLevelN == 'antigen' || urgencyLevelN == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Agency'>
                  <Select
                       
                       showSearch
                      disabled={location.state?true:false}
                       optionFilterProp="children"
                       defaultValue={agencyVal}
                     onChange={(event) =>  agency(event)}
                       
                       filterOption={(input, option) =>
                         option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                       }
                     >
                      {companies && companies.length>0 &&
                       companies.map((companyObj) => (
                         <Option value={companyObj['id']}>{companyObj.name}</Option>
                       ))}                 

                     </Select>
                      {/* <Input 
                      value={agencyVal}
                      onChange={(event) => agency(event.target.value)}
                      /> */}
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Agent Name'>
                      <Input 
                      value={agentNameVal}
                      onChange={(event) => agentName(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(urgencyLevelN == 'rtpcr' || urgencyLevelN == 'antigen' || urgencyLevelN == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Agent Phone'>
                      <Input 
                      value={phoneVal}
                      onChange={(event) => phone(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Agent Email'>
                      <Input 
                      value={agencyEmailVal}
                      onChange={(event) => agencyEmail(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(urgencyLevelN == 'rtpcr' || urgencyLevelN == 'antigen' || urgencyLevelN == 'vaccination') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='PO#'>
                      <Input 
                      value={poVal}
                      onChange={(event) => po(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  {/* <FormItem label='Mileage'>
                      <Input 
                      value={mileageVal}
                      onChange={(event) => mileage(event.target.value)}
                      />
                    </FormItem> */}
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{(urgencyLevelN == 'rtpcr' || urgencyLevelN == 'antigen') &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Test Requests'>
                      <Input 
                      value={test_request_val}
                      onChange={(event) => test_request(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Test Completed'>
                      <Input 
                      value={test_completed_val}
                      onChange={(event) => test_completed(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{urgencyLevelN == 'vaccination' &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Captain'>
                      <Input 
                      value={captainVal}
                      onChange={(event) => captain(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Captain Email'>
                      <Input 
                      value={captainEmailVal}
                      onChange={(event) => captainEmail(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>

                        }

{urgencyLevelN == 'vaccination' &&
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='ETA'>
                  <DatePicker
                  style={{width:'100%'}}
                                  allowClear={false}
                                  showTime={true}
                                  format="MM/DD/YYYY HH:mm:ss"
                                  value={eta_time_val}
                                  onChange={(date, dateString) => {
                                    eta_time(date);
                                    eta_time_bool(true);
                                  }
                                }
                                   
                                />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='ETD'>
                  <DatePicker
                  style={{width:'100%'}}
                                  allowClear={false}
                                  showTime={true}
                                  format="MM/DD/YYYY HH:mm:ss"
                                  value={etd_time_val}
                                  onChange={(date, dateString) => {
                                    etd_time(date);
                                    etd_time_bool(true);
                                  }}
                                 
                                />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>
                
                        }

{urgencyLevelN == 'vaccination' &&
<div>
<hr/>
  <span style={{
    display: 'inline-flex',
    alignItems: 'center',
    height: '32px',
    color: '#1C3A6A',
    fontSize: '15px',
    fontWeight: 700,
    textDecoration:'underline'}}>Scheduled By:</span>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Name'>
                      <Input 
                      value={schedule_name_Val}
                      onChange={(event) => schedule_name(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Phone'>
                      <Input 
                      value={schedule_phone_Val}
                      onChange={(event) => schedule_phone(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>
                </div>
                        }
                        {urgencyLevelN == 'vaccination' &&
                        <div>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Email'>
                      <Input 
                      value={schedule_email_Val}
                      onChange={(event) => schedule_email(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  {/* <FormItem label='Phone'>
                      <Input 
                      //value={captainEmailVal}
                      //onChange={(event) => captainEmail(event.target.value)}
                      />
                    </FormItem> */}
                  </div>
                  <div className='col-md-2'></div>
                </div>
                <hr/>
                </div>

                        }
                        <br/>
                        {urgencyLevelN == 'vaccination' &&
                        <div>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Emails-'>
                  <Checkbox name="Captain"
                  onChange={(e)=>{setEmailsOptionCaptain(e.target.checked);console.log("e",e.target.checked)}}
                  >Captain</Checkbox><br/>
                  <Checkbox name="Gatelist"
                  onChange={(e)=>{setEmailsOptionGatelist(e.target.checked);console.log("e",e.target.checked)}}
                  >Gatelist</Checkbox><br/>
                  <Checkbox name="Schedule"
                  onChange={(e)=>{setEmailsOptionSchedule(e.target.checked);console.log("e",e.target.checked)}}
                  >Schedule</Checkbox><br/>
                  <Checkbox name="Records"
                  onChange={(e)=>{setEmailsOptionRecords(e.target.checked);console.log("e",e.target.checked)}}
                  >Records</Checkbox>
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                  <div className='col-md-4 col-sm-12'>
                  <FormItem label='Event Notes'>
                      <TextArea
                      rows={3} 
                      value={eventNotesVal}
                      onChange={(event) => eventNotes(event.target.value)}
                      />
                    </FormItem>
                  </div>
                  <div className='col-md-2'></div>
                </div>
                <hr/>
                </div>

                        }
                        <br/>
                <div className='row'>
                  
                  <div className='col-md-4 col-sm-12'>
                    {/* <FormItem label={errorVal ? <p>Select Employee <span style={{color:'red'}}>*(Required field)</span></p> : <p>Select Employee *</p>}> */}
                    <FormItem label={<p>Select Employee </p>}>
                      <Button style={{ width: '50%', backgroundColor:"#1b3969", border:"none" }}
                      disabled={location.state?true:false}
                        onClick={displayEmpList}
                      >Select Employee</Button>
                      {/* <Input onClick={displayEmpList} /> */}
                      {/* {SelectedEmpRecords.length > 0 && SelectedEmpRecords.map((resp, index) => (
                        <Tag
                          //onClose={()=>log(index)} 
                          style={{ backgroundColor: '#97abd3', marginBottom: 10 }}>{resp.first_name + " " + resp.last_name}</Tag>
                      ))} */}

                      <Modal
                        visible={isModalVisible}
                        width={'80%'}
                        // onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                          <Button key="submit" style={{ backgroundColor: '#9badc0',color:"white",border:"none" }}
                           onClick={handleCreateEmployee}
                           >
                            Create Employee
                          </Button>,
                          <Button key="submit" style={{ backgroundColor: '#3d586a',color:"white",border:"none"  }} onClick={handleOk}>
                            Select
                          </Button>,
                          <Button key="submit" style={{ backgroundColor: '#ef6727',color:"white",border:"none"  }} onClick={handleCancel}>
                            Cancel
                          </Button>
                        ]}

                      >
                        {patArr.length > 0 ?

                          <div>
                            <Card title={
                              <Row>
                                <Col span={18}>
                                  <span>Employee List</span>
                                  </Col>
                                  <Col span={2}>
            <Button
              size='small'
              style={{ marginTop: 40, backgroundColor: '#247', color: 'white', border:"none"}}
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
          </Col>
                                  <Col span={2}>
                                  <Button
                                  size="small"
                                  style={{ marginTop: 40, backgroundColor: '#247', color: 'white', border:"none", marginRight:"0px" }}
            onClick={() => {

              //  let LoadMoreVal = {LoadMoreVal + 1;
              getCompanyData('close');

            }}
          >
            Load More
          </Button>
                                  </Col>
                                  </Row>
                                }>
                              <Input
                                style={{ border: "1px solid",background:"white", margin: "0 0 10px 0" }}
                                placeholder="Search by name"
                                //enterButton
                                onChange={(event) => searchEmployee(event)}
                              />
                               <Row>
          <Col>
            {/* <div style={{ position: "absolute", zIndex: "1" }}> */}
            <Collapse accordion>
              <Panel
                style={{ background: '#2F7197',backgroundColor: '#247', color: 'white' }}
                className='ant-collapse-header'
                header={
                  <span
                    style={{ fontSize: '110%', color:"fff", fontWeight: 700, width: '1100px', display: 'block' }}
                  >
                    Advanced Search Filter
                  </span>
                }
                key='1'

              >
                <Row>
                  <Col span={11}>
                    <Input
                      value={firstNameValue}
                      placeholder='First Name:'
                      suffix={<span className='icofont icofont-search' />}
                      style={{ width: '80%', backgroundColor: 'white' }}
                      onChange={(event) =>
                        firstNameValueSearch(event.target.value)
                      }
                    />
                  </Col>
                  <Col span={11}>
                    <Input
                      value={lastNameValue}
                      placeholder='Last Name:'
                      suffix={<span className='icofont icofont-search' />}
                      style={{ width: '80%', backgroundColor: 'white' }}
                      onChange={(event) =>
                        lastNameValueSearch(event.target.value)
                      }
                    />
                  </Col>
                  <Col 
                  //style={{ align: 'center' }} 
                  span={2}>
                    <Button
                      size='small'
                      style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                      onClick={() => handleAdvanceFilter()}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            {/* </div> */}
          </Col>
        </Row>
        <br />
                              <div className='row'>
                                <div className='col-12 col-md-12 col-xl-12' >

                                  <Table
                                    loading={LoaderAllEmployee}
                                    dataSource={EmployeeSearchList.length > 0 ? EmployeeSearchList : patArr}
                                    columns={columns}
                                    // footer={}
                                    rowSelection={{
                                      type: 'checkbox',
                                      ...rowSelection,
                                    }}
                                    pagination={{
                                      pageSize: pageLimitVal, hideOnSinglePage: false, showSizeChanger: true, total: patArr.length,
                                      showTotal: showTotal
                                    }}

                                  />
<br />
          <Button
            style={{backgroundColor: '#247', color: 'white', border:"none"}}
            onClick={() => {

              //  let LoadMoreVal = {LoadMoreVal + 1;
              getCompanyData('close');

            }}
          >
            Load More
          </Button>
                                </div>
                              </div>
                            </Card>
                          </div>
                          :

                          <Row>
                            <Col span={10}>
                            </Col>
                            <Col span={8}>
                              <Space size="large" style={{ marginTop: 30 }}>

                                <Spin size="large" />

                              </Space>
                            </Col>
                          </Row>

                        }


                      </Modal>


                      <Modal
                        visible={isModalVisibleCE}
                        width={'70%'}
                        // onOk={handleOk}
                        onCancel={handleCancelCE}
                        footer={[
                          // <Button key="submit" style={{ backgroundColor: '#ff4444' }} 
                          // onClick={handleCancel}
                          // >
                          //   Cancel
                          // </Button>
                        ]}

                      >
                        <CreateEmployee setData={JSON.parse(localStorage.getItem('customerData'))} sendDataToParent={sendDataToParent}/>
                        
                      </Modal>
                      
                    </FormItem>
                  </div>

                </div>

                {
                     (urgencyLevelN == 'antigen' || urgencyLevelN == 'rtpcr') && SelectedEmpRecords && SelectedEmpRecords.length>0?
                     <div>
                     <div className='row'>    
                
                        <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                            Employee Name
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Collection Date
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Covid Test Date
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Sample Type
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Result Type
                            </div>
                            <div className='col-md-2 col-sm-12'></div> 
                            
                   </div> 
                   {
                        SelectedEmpRecords.map((emp,idx) => {
                          // SelectedEmpRecords[idx].collectionDate = moment();
                          return (
                            <div style={{padding:10}}>
                            <div className='row'>    
                                <div className='col-md-2 col-sm-12'>
                                {emp.first_name} {emp.last_name}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                <DatePicker
                                  allowClear={false}
                                  showTime={true}
                                  value={moment(SelectedEmpRecords[idx].collectionDate)}
                                onChange={(date, dateString) => {SelectedEmpRecords[idx].collectionDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
                                  SelectedEmpRecordsTrue(!EmpRecordsTrue)}}
                                  // onChange={(date, dateString) =>collectionDate(dateString)} 
                                  style={{ width: '100%', borderRadius: 50, padding: 7 }}
                                />
                                {/* <Select allowClear>
                                <Option value=''> Select Vaccine</Option>
                                {vaccines.map((vaccine, i) => (
                                  <Option key={i} value={vaccine}>
                                    {vaccine}
                                  </Option>
                                ))}
                              </Select> */}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                <DatePicker
                                  allowClear={false}
                                  showTime={true}
                                  value={moment(SelectedEmpRecords[idx].covidTestDate)}
                                  onChange={(date, dateString) => {SelectedEmpRecords[idx].covidTestDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
                                    SelectedEmpRecordsTrue(!EmpRecordsTrue)}}
                                  style={{ width: '100%', borderRadius: 50, padding: 7 }}
                                />
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                <Select allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                }
                                value={SelectedEmpRecords[idx].sample}
                                onChange={(e) => {SelectedEmpRecords[idx].sample = e;SelectedEmpRecordsTrue(!EmpRecordsTrue)}}  
                                >
                                  <Option value=''>Select Sample</Option>
                                  {urgencyLevelN == 'antigen' &&
                                  <Option value='Nasal Swab'>Nasal Swab</Option>
                            }
                            {urgencyLevelN == 'rtpcr' &&
                                  <Option value='Nasopharyngeal Swab'>Nasopharyngeal Swab</Option>
                          }
                                </Select>
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                <Select allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                }
                                value={SelectedEmpRecords[idx].result}
                                onChange={(e) => {SelectedEmpRecords[idx].result = e;SelectedEmpRecordsTrue(!EmpRecordsTrue)}}
                                >
                                  <Option value=''>Select Result</Option>
                                  <Option value='Positive'>Positive</Option>
                                  <Option value='Negative'>Negative</Option>
                                </Select>
                                </div>   
                                <div className='col-md-2 col-sm-12'>
                                {location.state?
                                <></>:
                                  <i className="fa fa-times" style={{cursor:'pointer',fontSize:18,marginTop:10}}
                                  onClick={()=>deleteRow(idx)}
                                  />
                                }
                                  </div>    
                            </div> 
                          </div>  
                          )
                        })
                   } 
                   </div>
                   :
                    null
                   }  
                

                   {
                     urgencyLevelN == 'vaccination' && SelectedEmpRecords && SelectedEmpRecords.length>0?
                     <div>
                     <div className='row'>    
                
                        <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                            Employee Name
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Vaccine Name
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Vaccine Date
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Dose
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Lot No
                            </div>
                            <div className='col-md-2 col-sm-12'></div> 
                            
                   </div> 
                   {
                        SelectedEmpRecords.map((emp,idx) => {
                          return (
                            <div style={{padding:10}}>
                            <div className='row'>    
                                <div className='col-md-2 col-sm-12'>
                                {emp.first_name} {emp.last_name}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                <Select allowClear 
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                }
                                value={SelectedEmpRecords[idx].vaccineName}
                                onChange={(e) => {SelectedEmpRecords[idx].vaccineName = e;SelectedEmpRecordsTrue(!EmpRecordsTrue)}}
                                >
                                <Option value=''> Select Vaccine</Option>
                                {vaccines.map((vaccine, i) => (
                                  <Option key={i} value={vaccine}>
                                    {vaccine}
                                  </Option>
                                ))}
                              </Select>
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                <DatePicker
                                  allowClear={false}
                                  value={moment(SelectedEmpRecords[idx].vaccineDate)}
                                  onChange={(date, dateString) => {SelectedEmpRecords[idx].vaccineDate = dateString;
                                    SelectedEmpRecordsTrue(!EmpRecordsTrue)}}
                                  style={{ width: '100%', borderRadius: 50, padding: 7 }}
                                />
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                <Select allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                }
                                value={SelectedEmpRecords[idx].dose}
                                onChange={(e) => {SelectedEmpRecords[idx].dose = e;SelectedEmpRecordsTrue(!EmpRecordsTrue)}}
                                >
                                  <Option value=''>Select Dose</Option>
                                  <Option value='First Dose'>First Dose</Option>
                                  <Option value='Second Dose'>Second Dose</Option>
                                  <Option value='Booster Dose'>Booster Dose</Option>
                                  <Option value='Single Dose'>Single Dose</Option>
                                </Select>
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                <input 
                                value={SelectedEmpRecords[idx].lotNumber}
                                onChange={(e) => {SelectedEmpRecords[idx].lotNumber = e.target.value;SelectedEmpRecordsTrue(!EmpRecordsTrue)}}
                                />
                                </div>   
                                <div className='col-md-2 col-sm-12'>
                                {location.state?
                                <></>:
                                <i className="fa fa-times" style={{cursor:'pointer',fontSize:18,marginTop:10}} 
                                onClick={()=>deleteRow(idx)}
                                />
                        }
                                  </div>    
                            </div> 
                          </div>  
                          )
                        })
                   } 
                   </div>
                   :
                    null
                   }   

{
                    ( urgencyLevelN == 'Screening'|| urgencyLevelN == 'Background Screening' ) && SelectedEmpRecords && SelectedEmpRecords.length>0?
                     <div>
                     <div className='row'>    
                
                        <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                            Employee Name
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              DOB
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Status
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              SSN
                            </div>
                            <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Location
                            </div>
                            {/* <div style={{fontWeight:600}} className='col-md-2 col-sm-12'>
                              Location
                            </div> */}
                            <div className='col-md-2 col-sm-12'></div> 
                            
                   </div> 
                   {
                        SelectedEmpRecords.map((emp,idx) => {
                          return (
                            <div style={{padding:10}}>
                            <div className='row'>    
                                <div className='col-md-2 col-sm-12'>
                                {emp.first_name} {emp.last_name}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                {emp.dob}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                {emp.emp_status}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                {emp.emp_ssn}
                                </div>
                                <div className='col-md-2 col-sm-12'>
                                {emp.emp_location[1]}
                                </div>
                                {/* <div className='col-md-2 col-sm-12'>
                                {emp.first_name} {emp.last_name}
                                </div>   */}
                                <div className='col-md-2 col-sm-12'>
                                {location.state?
                                <></>:
                                <i className="fa fa-times" style={{cursor:'pointer',fontSize:18,marginTop:10}} 
                                onClick={()=>deleteRow(idx)}
                                />
                        }
                                  </div>    
                            </div> 
                          </div>  
                          )
                        })
                   } 
                   </div>
                   :
                    null
                   }
                <div className='row' style={{marginTop:50}}>
                  <div className='col-md-4 col-sm-4'>

                  </div>
                  <div className='col-md-5 col-sm-5'>
                    <Button type='primary' style={{ width: '50%', backgroundColor:"#1b3969" }}
                      onClick={saveTest} disabled={enableSave}
                    >Save</Button>
                     {LoaderEventVal &&
                                            <Space size="middle" style={{ marginTop: 10, marginLeft: 10 }}>
                                                <Spin size="large" />
                                            </Space>
                                        }
                  </div>
                </div>
              </Form>
            </Card>
            </Spin>

          </div>



        </div>

        :
        <Row>
          <Col span={10}>
          </Col>
          <Col span={8}>
            <Space size="middle" style={{ marginTop: 10 }}>

              <Spin size="large" />
            </Space>
          </Col>
        </Row>


      }



    </div>
  );
};

export default AddTestingEventView;
