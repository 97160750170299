import React, { CSSProperties } from 'react';
//import newLogoIcon from '../../../assets/img/logonew.png';
import newLogoIcon from '../../../assets/img/wssNewLogo.png';
import './Logo.scss';

type Props = {
  src: string;
  width?: number | string;
  height?: number | string;
  alt?: string;
  style?: CSSProperties;
};

const Logo = ({ alt = '', height = 'auto', width = 'auto', src, style = {} }: Props) => {
  return (
    <div className='logo' style={{height:"auto", display:"block", textAlign:"center", paddingBottom:0}}>

      {/* <img src="https://www.workplacesafetyscreenings.com/hubfs/wss-logo.svg" alt={alt} width={width} height={height} className='logo-img' /> */}
      {/* <img src={src} alt={alt} width={width} height={height} className='logo-img' /> */}
      <img src={newLogoIcon} alt={alt} style={{width:'80%'}} 
      //height={height}
       //className='logo-img' 
       />

    </div>
  );
};

export default Logo;
