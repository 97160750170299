import React, { useEffect, useState } from 'react';
import { Checkbox, Card, Table, Menu, Dropdown, Button, Space } from 'antd';
import axios from 'axios';
import _ from 'underscore';
import setting from './../../config/setting';
import { useLocation, useHistory } from 'react-router-dom';
import ArrivedModel from './arrivedModel';
import ShippedModel from './shippedModel';
import CollectedModel from './collectedModel';
import PendingMroModel from './pendingMroModel';

const TestingViewDetailFurther = () => {
  const [change, setChange] = useState(false);
  const [testResult, setTestResult] = useState([]);
  let history = useHistory();
  const location = useLocation();
  const propsData = location.state;

  useEffect(() => {
    if (propsData == undefined || propsData == "undefined") {
      history.goBack();
    }
    updateTestResult(propsData);
    console.log('this.props test details:::', propsData);
  }, []);

  const updateTestResult = (data) => {
    let testData = {
      service: data['service'],
      final_result: data['final_result'],
      test_result: data['test_result'],
      name: data['name']
    };
    setTestResult([testData]);
  };

  function download(attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: [attachment]
        }
      })
      .then((response) => {
        console.log('Respone from getData Covid_Vaccination', response.data);
        setChange(!change);
        window.open(response.data);
      });
  }

  const columns = [
    {
      title: 'Name',
      key: 'id',
      dataIndex: 'id',
      render: (attachments_url) => (
        <span className='nowrap' onClick={() => download(attachments_url)}>{attachments_url}

         
        </span>
      )
    },

    {
      title: 'Created On',
      key: 'create_date',
      dataIndex: 'create_date',
      render: (create_date) => (
        <span className='nowrap'>
         {propsData && propsData.create_date}
        </span>
      )
    }

    //   key: 'test_date',
    //   dataIndex: 'test_date',
    //   title: 'Covid Test Date',
    //   sorter: (a, b) => moment(a.test_date).unix() - moment(b.test_date).unix(),
    //   // sorter: (a, b) => a.age - b.age,
    //   render: (test_date) => (
    //     <span className='nowrap'>
    //       <strong> {test_date ? moment(test_date).format("MM/DD/YYYY") : ''}</strong>
    //     </span>
    //   )
    // },
  ];
  const columns1 = [
    {
      title: 'Drug Name',
      key: 'service',
      dataIndex: 'service',
      render: (service) => (
        <span className='nowrap'>
         {propsData && propsData.service}
        </span>
      )
    },
    {
      title: 'Result',
      key: 'final_result',
      dataIndex: 'final_result',
      render: (final_result) => (
        <span className='nowrap'>
        {propsData && propsData.final_result}
        </span>
      )
    },
    {
      title: 'Test Result',
      key: 'test_result',
      dataIndex: 'test_result',

      render: (test_result) => (
        <span className='nowrap'>
          {propsData && propsData.test_result}
        </span>
      )
    },
    {
      title: 'Level',
      key: 'name',
      dataIndex: 'name'

      // render: () => (
      //   <span className='nowrap'>
      //     <strong>Name</strong>
      //   </span>
      // )
    },
    {
      title: 'Unit',
      key: 'name',
      dataIndex: 'name'

      // render: () => (
      //   <span className='nowrap'>
      //     <strong>Name</strong>
      //   </span>
      // )
    },
    {
      title: 'ScreenCutOff',
      key: 'name',
      dataIndex: 'name'

      // render: () => (
      //   <span className='nowrap'>
      //     <strong>Name</strong>
      //   </span>
      // )
    },
    {
      title: 'ConfirmCutOff',
      key: 'name',
      dataIndex: 'name'

      // render: () => (
      //   <span className='nowrap'>
      //     <strong>Name</strong>
      //   </span>
      // )
    }
  ];
  const columns2 = [
    {
      title: 'Validity Name',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
          Name
        </span>
      )
    },
    {
      title: 'Result',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
         Name
        </span>
      )
    },
    {
      title: 'Level',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
         Name
        </span>
      )
    },

    {
      title: 'Unit',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
        Name
        </span>
      )
    }
  ];
  const columns3 = [
    {
      title: 'Employer Name',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
          Name
        </span>
      )
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
          Name
        </span>
      )
    },
    {
      title: 'ILA Work Number',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
          Name
        </span>
      )
    },

    {
      title: 'Emergency Contact',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
        Name
        </span>
      )
    },
    {
      title: 'Date',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
          Name
        </span>
      )
    },
    {
      title: 'Relationship to Emergency',
      key: 'name',
      dataIndex: 'name',

      render: () => (
        <span className='nowrap'>
         Name
        </span>
      )
    }
  ];
  const menu = (
    <Menu>
      <Menu.Item>
        <ShippedModel activeId={propsData && propsData} />
      </Menu.Item>
      <Menu.Item>
        <CollectedModel activeId={propsData && propsData} />
      </Menu.Item>
      {/* <Menu.Item>
        <ArrivedModel activeId={propsData && propsData} />
      </Menu.Item> */}
      {/* <Menu.Item>
        <PendingMroModel activeId={propsData && propsData} />
      </Menu.Item> */}
    </Menu>
  );
  const styles = {
    fontSize: '13px',
    //fontWeight: 700,
    paddingTop: 3
  };
  const styles2 = { fontSize: '14px' };
  return (
    <>
      <div>
        <Button
          size='small'
          style={{
            backgroundColor: '#247',
            color: 'white',
            marginTop: 5,
            marginLeft: 12,
            padding: 12, 
            border:"none"
          }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>
      <div style={{ padding: 20 }}>
        {/* {ClinicList.length > 0 ? ( */}

        <div style={{ backgroundColor: 'white', borderRadius: 20, padding: 20 }}>
          <Card title={''}>
            {/* <div style={{ textAlign: 'center' }}>
              <div className='row' style={{ fontSize: 20 }}>
                <div className='col'>
                  <Dropdown overlay={menu} placement='topCenter'>
                    <Button>Actions</Button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <br /> */}

            <div className='row' style={{ fontSize: 20 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Refrence ID :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.refrence_id}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Order Number :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.order_no}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>DOT classification :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.dot_classification === 'False'
                        ? ''
                        : propsData.dot_classification}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Item Name :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.test_name}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Package :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.service}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Testing Status :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.testing_status}</p>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <h5 style={{color:"#467"}}>
              Sample Status
            </h5>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Collected :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.collected}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Comments :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.comments}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Shipped Date :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.shipped_date}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'></div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Arrived Date :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.arrived_date}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'></div>
            </div>

            <hr />
            <h5 style={{color:"#467"}}>
              Customer
            </h5>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Company Name :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.company && propsData.company[1]}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Account Number :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.acc_number && propsData.acc_number}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Location :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.child_ids && propsData.child_ids[1]}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Location Code :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.loc_id && propsData.loc_id}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Select Employee :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.emp_selected && propsData.emp_selected[1]}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Lab Account :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.lab_account && propsData.lab_account}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <h5 style={{color:"#467"}}>
              Participant
            </h5>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>SSN :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.emp_ssn && propsData.emp_ssn}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>EIN :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.emp_ein && propsData.emp_ein}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Alternate ID :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.alternate_id && propsData.alternate_id}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>First Name :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.first_name && propsData.first_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Middle Name :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.last_name && propsData.middle_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Last Name :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.last_name && propsData.last_name}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>DOB :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.dob && propsData.dob}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Email Address :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.email && propsData.email}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Phone :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.phone && propsData.phone}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Phone2 :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.phone2 && propsData.phone2}</p>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <h5 style={{color:"#467"}}>
              Participant Address
            </h5>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Street :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.emp_ssn && propsData.street}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Street 2 :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.emp_ssn && propsData.street2}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>City :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.emp_ssn && propsData.city}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>State :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.emp_ssn && propsData.state_id}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Zip :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.emp_ssn && propsData.zip}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Country :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.emp_ssn && propsData.company_location}
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
            </div>
            <hr />
            <h5 style={{color:"#467"}}>
              Collection
            </h5>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Panel :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.panel && propsData.panel[1]}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Collection Site :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.type_of_location && propsData.type_of_location}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Reason :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.reasons && propsData.reasons}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Order Status :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.order_status && propsData.order_status}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Collected :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.collected && propsData.collected}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Order Number :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.collected && propsData.order_no}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>CCF :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.ccf}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Use Case As CCF:</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      <Checkbox
                        checked={propsData && propsData.case_as_ccf}
                        defaultChecked={false}
                        disabled
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>CCF URL :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.ccfFileName}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'></div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>MRO URL :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.mroFileName}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'></div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Donor Pass URL :</b>
                  </div>
                  <div className='col-md-8'>
                    <p>
                      <a href={propsData && propsData.url}>{propsData && propsData.url}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>DOT Agency :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.dot_agency && propsData.dot_agency}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Origin :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.origin && propsData.origin}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Fullfillment Type :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>
                      {propsData && propsData.fullfillment_type && propsData.fullfillment_type}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <br />
            <div className='row'>
              <div className='col-12 col-md-12 col-xl-12'>
                <h5 style={{color:"#467"}}>
                  Attachments
                </h5>
                <Table
                  dataSource={
                    propsData &&
                    propsData.attachments_id.map((element) => {
                      return { id: element };
                    })
                  }
                  columns={columns}
                />
              </div>
            </div>
            {/* <hr /> */}
            <div className='row'>
              <div className='col-12 col-md-12 col-xl-12'>
                <h5 style={{color:"#467"}}>
                  Physical Form
                </h5>
                <Table
                  //dataSource={ClinicListTrue ? propsData.ClinicListFilter : ClinicList}
                  columns={columns3}
                />
              </div>
            </div>
            {/* <hr /> */}
            <div className='row'>
              <div className='col-md-2'>
                <h5 style={{color:"#467"}}>
                  Result
                </h5>
              </div>
              <div className='col-md-10' style={{ paddingTop: 27 }}>
                <p>{propsData && propsData.final_result && propsData.final_result}</p>
              </div>
            </div>
            {/* <hr /> */}
            <div className='row'>
              <div className='col-md-12'>
                <h5 style={{color:"#467"}}>
                  Test Result
                </h5>
                <Table
                  //dataSource={ClinicListTrue ? propsData.ClinicListFilter : ClinicList}
                  dataSource={testResult}
                  columns={columns1}
                />
              </div>
            </div>
            {/* <hr /> */}
            <div className='row'>
              <div className='col-md-12'>
                <h5 style={{color:"#467"}}>
                  Validity
                </h5>
                <Table
                  //dataSource={ClinicListTrue ? propsData.ClinicListFilter : ClinicList}
                  columns={columns2}
                />
              </div>
            </div>
            {/* <hr /> */}
            <div className='row'>
              <div className='col-md-2'>
                <h5 style={{color:"#467"}}>
                  XML Attachments
                </h5>
              </div>
              <div className='col-md-10'>
                <p style={styles}>
                  {propsData && propsData.xml_attachments && propsData.xml_attachments[1]}
                </p>
              </div>
            </div>
            <hr />
            <h5 style={{color:"#467"}}>
              i3 Response
            </h5>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Case Number :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.caseNumber}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>i3 Testing Status :</b>
                  </div>
                  <div className='col-md-8'>
                    <p style={styles}>{propsData && propsData.testing_status}</p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
            </div>

            <div className='row' style={{ fontSize: 13 }}>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-4'>
                    <b style={styles2}>Donor Pass URL :</b>
                  </div>
                  <div className='col-md-8'>
                    <p>
                      <a href={propsData && propsData.url}>{propsData && propsData.url}</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
            </div>
          </Card>
        </div>
        {/* ) : ( */}
        {/* <Row>
                <Col span={10}></Col>
                <Col span={8}>
                    <Space size='large' style={{ marginTop: 30 }}>
                        <Spin size='large' />
                    </Space>
                </Col>
            </Row> */}
        {/* )} */}
      </div>
    </>
  );
};

export default TestingViewDetailFurther;
