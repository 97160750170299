import React, { useState, useEffect } from 'react';
import axios from "axios";
import "antd/dist/antd.css"
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import setting from './config/setting'
import { setPageData, updatePageDada } from './redux/page-data/actions';
import VerticalLayout from './layout/vertical/Vertical';
import HorizontalLayout from './layout/horizontal/Horizontal';

import NotFound from './pages/sessions/404';
import { defaultRoutes, sessionRoutes } from './routing';
import { history } from './redux/store';

import './App.scss';
import { useHideLoader } from './hooks/useHideLoader';


const Routes = ({ routes, layout = '' }) => (
  <Switch>
    {routes.map((route, index) => (
      <Route
        key={index}
        exact={route.exact}
        path={layout.length > 0 ? `/${layout}/${route.path}` : `/${route.path}\``}
        component={() => <route.component />}
      />
    ))}

    <Route path='*'>
      <Redirect to='/public/page-404' />
    </Route>
  </Switch>
);

const DefaultRoutes = ({ layout }) => <Routes routes={defaultRoutes} layout={layout} />;

const SessionRoutes = () => <Routes routes={sessionRoutes} layout='public' />;

const App = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const [CompDataNew, setCompDataNew] = useState([]);
  const [CompDataNewTrue, setCompDataNewTrue] = useState(false);

  useEffect(() => {

    
    console.log("Enter App page...");
    // setTimeout(() => dispatch(updatePageDada({ fulFilled: true, loaded: true })), 300);
    // getData();
    let logginUserCompanyId = localStorage.getItem('customerId')
    if(location.pathname.toLowerCase().includes('check-in') || location.pathname.toLowerCase().includes('checkdetails'))
    {
      setTimeout(() => {
        window.location.href='https://checkin.wss-corp.com';
      }, 300);   
    }
    else
    {
      if(logginUserCompanyId || location.pathname.toLowerCase().includes('physicalform')){
        setTimeout(() => dispatch(updatePageDada({ fulFilled: true, loaded: true })), 300);
      }else{
        setTimeout(() => history.push({ pathname: '/public/sign-in/', state: {} }), 300);
      }
    }

  }, [])


  useHideLoader();

  return (
    <Switch>

      <Route path='/check-in' exact/>

      <Route path='/' exact>
        <Redirect to='/public/sign-in' />
      </Route>
      
      <Route path='/default-dashboard' exact>
        <Redirect to='/vertical/default-dashboard' />
      </Route>

      <Route path='/public'>
        <SessionRoutes />
      </Route>

      <Route path='/horizontal'>
        <HorizontalLayout>
          <DefaultRoutes layout='horizontal' />
        </HorizontalLayout>
      </Route>

      <Route path='/vertical'>
        <VerticalLayout>
          <DefaultRoutes layout='vertical' />
        </VerticalLayout>
      </Route>

      <Route path='*'>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default App;
