//import React from 'react';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import moment from 'moment';
import { Button, Card, Form, Input, Modal,Table } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'underscore';
import { history } from '../../redux/store';
import setting from '../../config/setting'
import { Spin, Space, Row, Col, Divider, Tooltip } from 'antd';
import {
  Alert,
  AutoComplete,
  // Button,
  //Card,
  Checkbox,
  //Input,
  Radio,
  Rate,
  Select,
  Switch,
  Tag,
  //Form,
  DatePicker
} from 'antd';

import {

  PlusCircleTwoTone,
} from '@ant-design/icons';
//import { Button, Card, Form, Input } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons/lib';
const FormItem = Form.Item;
const { Option } = Select;

const AddCoursesEmployee = () => {

    const [ClinicList, setClinicList] = useState([]);
  const [change, setChange] = useState(false);
  const [event, setEvent] = useState([]);
  const [ret, setRet] = useState(false);
  const [compLength, setCompLength] = useState(0);
  const [testLength, setTestLength] = useState(-1);
  const [employeeLength, setEmployeeLength] = useState(0);
  const [notLength, setNotLength] = useState(0);
  const [compData, setCompData] = useState([]);
//   const [Res, setRes] = useState([]);
//   const [loc, setLoc] = useState([]);
//   const [locNew, setLocNew] = useState([]);
  const [arr, setArr] = useState([]);
  const [zipcodeVal, setzipcodeVal] = useState('');
  
  const [zipcodeValTrue, setzipcodeValTrue] = useState(false);
  const [StartDate, setStartDate] = useState('');
  const [CompletionDate, setCompletionDate] = useState('');
  const [ch, setCh] = useState(false);
  const [selected, setSelected] = useState({});
  const [sel, setsel] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(false);
const [ClinicListFilter, setClinicListFilter] = useState([]);
const [ClinicListTrue, setClinicListTrue] = useState(false);

  const [compNameN, setcompName] = useState('');
  const [eventTypeN, seteventType] = useState('');
  const [eventTypeIdN, seteventTypeId] = useState('');
  
  const [reasonN, setreason] = useState('');
  const [serviceN, setservice] = useState('');
  const [urgencyLevelN, seturgencyLevel] = useState('');
  const [testNameN, settestName] = useState('');
  const [locationEmpN, setlocationEmp] = useState('');
  const [clinicNameN, setclinicName] = useState('');
  const [statusN, setstatus] = useState('');
  const [assignToN, setassignTo] = useState([]);

  const [comp, setComp] = useState([]);
  const [Res, setRes] = useState([]);
  const [loc, setLoc] = useState([]);
  const [locNew, setLocNew] = useState([]);
  const [PatArrTrue,  setPatArrTrue] = useState(false);
 
  const [bool, setBool] = useState(false);
  const [boolNew, setBoolNew] = useState(false);
  const [boolN, setBoolN] = useState(false);
  const [boolNe, setBoolNe] = useState(false);
  const [boolNN, setBoolNN] = useState(false);

  const [reasonNew, setReason] = useState([]);
  const [productTemplate, setProductTemplate] = useState([]);
  const [clinic, setclinic] = useState([]);
  const [testNames, setestName] = useState([]);

  const [comIDD, setCompId] = useState([]);
  const [serviceNew, setService] = useState([]);
  const [testRegister, setTestRegister] = useState([]);
  const [patArr, setPatArr] = useState([]);
  const [CompNameTreeView, setCompNameTreeView] = useState('');
  const [reasonTreeValue, setreasonTreeValue] = useState('');
  const [serviceTreeValue, setserviceTreeValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [EmpChange, setEmpChange] = useState(false);
  const [SelectedEmpRecords, setSelectedEmpRecords] = useState([]);
  const [EmployeeSearchListTrue, setEmployeeSearchListTrue] = useState(false);
  const [EmployeeSearchList, setEmployeeSearchList] = useState([]);
  const [CompDataNew, setCompDataNew] = useState([]);
  const [CompDataNewTrue, setCompDataNewTrue] = useState(false);
  
  // const { loading, selectedRowKeys } = this.state;

  useEffect(() => {
   
    console.log("this.props");
    getData();
    getAllDataComp();
  
  }, [])

  function getAllDataComp() {
    let custIdArr = [];
    axios.post(setting.serverUrl + '/api/CustomerData', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(response => {
      console.log("Respone from getData ", response.data)


      if (response && response.status == 200) {
        _.each(response.data, (res) => {

          if(res.is_company){
          custIdArr.push(res);
          }

        })

        
         setTimeout(() => {

          getCust(custIdArr);
          //getResPArt(custIdArr)

        }, 2000)

       
      }
    })

  }

  const getCust = async (custIdArr) => {
    setPatArrTrue(false)
    let dat = [];
    await axios.post(setting.serverUrl + '/api/employeeCustCustomerPortal', {
        //  axios.get('http://localhost:3001/api/employee',{
        params:
        {
          //email:localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custIdArr:JSON.parse(localStorage.getItem('customerData')).employee_multi_select,
          compId: JSON.parse(localStorage.getItem('customerData')).id
      }
    }).then(response => {
      console.log("Respone from getData employeeCust", response.data)
      if (response && response.status == 200) {

        
        _.each(response.data, (resp) => {

            resp.key = resp.id
       
        })

        console.log("dat", dat);
        setPatArr(response.data);
        setPatArrTrue(true)

      }
    })

  }

  console.log("patArr:::::",patArr);

  function getData() {
    // setClinicList([])
    setClinicListTrue(false)

    console.log("getData :: ", localStorage.getItem('myData'), localStorage.getItem('myPass'))
    //console.log("setting.serverUrl",setting.serverUrl)
    axios.post(setting.serverUrl + '/api/getTrainings', {
        // axios.get('http://localhost:3001/api/getClinics', {
        params:
        {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId:JSON.parse(localStorage.getItem('customerData')).id
        }
      }).then(response => {
        console.log("Respone from getTrainings:::", response.data)
  
        if (response && response.status == 200) {
  
          setClinicList(response.data.rows)
          setChange(!change)
  
        }
      })

  }

  console.log("ClinicList add course::",ClinicList)

  const columns = [
    {
      title: 'Gender',
      key: 'Gender',
      dataIndex: 'Gender',
      sorter: (a, b) => a.Gender.length - b.Gender.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (Gender) => (
        <span className='nowrap'>
          {Gender}
        </span>
      )
    },
    {
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      title: 'First Name',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (first_name) => <span>{first_name}</span>
    },
    {
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: (a, b) => a.last_name.length - b.last_name.length,
      title: 'Last Name',
      // sorter: (a, b) => a.first_name_emp.length - b.first_name_emp.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (last_name) => <span>{last_name}</span>
    },
    {
      title: 'SSN',
      key: 'emp_ssn',
      dataIndex: 'emp_ssn',
      sorter: (a, b) => a.emp_ssn.length - b.emp_ssn.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (emp_ssn) => (
        <span className='nowrap'>
          {emp_ssn}
        </span>
      )
    },
    
  
    {
      key: 'dob',
      dataIndex: 'testing_status',
      title: 'dob',
      sorter: (a, b) => a.dob.length - b.dob.length,
      // sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (dob) => <span>{moment(dob).format("MM/DD/YYYY")}</span>
    },
    {
      title: 'Status',
      key: 'emp_status',
      dataIndex: 'emp_status',
      sorter: (a, b) => a.emp_status.length - b.emp_status.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (emp_status) => (
        <span className='nowrap'>
          {emp_status}
        </span>
      )
    },
    
  ];

  
  
  function eventType(event) {
    console.log("status", JSON.parse(event));
    seteventType(JSON.parse(event).course_name + " - " + JSON.parse(event).category)
    seteventTypeId(JSON.parse(event).id)
  }
  
 

  async function saveTest() {
  console.log("CompDataNew save::::::",SelectedEmpRecords[0].id,SelectedEmpRecords[0].first_name,SelectedEmpRecords[0].last_name)
  console.log("CompDataNew save::::::",eventTypeN)
  console.log("CompDataNew save::::::",eventTypeIdN)

  console.log("CompDataNew save::::::",StartDate)
  console.log("CompDataNew save::::::",CompletionDate)
  
    await axios.post(setting.serverUrl + '/api/saveCourseEmp', {
      // axios.get('http://localhost:3001/api/saveTest', {
      params:
      {
        // email: localStorage.getItem('myData'),
        // user: localStorage.getItem('myData'),
        // pass: localStorage.getItem('myPass'),

        employee_id: SelectedEmpRecords[0].id,
        employee_name:SelectedEmpRecords[0].first_name + " " + SelectedEmpRecords[0].last_name,
        course_id:eventTypeIdN,
        course_name:eventTypeN,
        start_date: StartDate,
        completion_date: CompletionDate,

      }
    }).then(response => {
      console.log("Respone from saveTest course ", response.data)

      if (response && response.status == 200) {
        history.push({ pathname: `/vertical/training` });
      }
    })
  }

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedEmpRecords(selectedRows)
      setEmpChange(!EmpChange)
    },
    getCheckboxProps: (record) => ({
      disabled: record.first_name === 'Disabled User',
      // Column configuration not to be checked
      first_name: record.first_name,
    }),
  };
 
  const handleOk = () => {
    setIsModalVisible(false);
console.log("SelectedEmpRecords:::",SelectedEmpRecords)    
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

//   const log =(i)=>{
// console.log("i::::",i)
// SelectedEmpRecords.splice(i,1);
// setChangeSplice(!ChangeSplice)

//   }
  //usePageData(pageData);

  const searchEmployee= (event)=>{

    console.log("search emp::",event.target.value)

    let employeeSearch =  patArr.filter(function (e) {
      return e.first_name.toLowerCase().includes(event.target.value.toLowerCase())
      || e.last_name.toLowerCase().includes(event.target.value.toLowerCase())
      || (e.emp_status && e.emp_status.toLowerCase().includes(event.target.value.toLowerCase()));
  });
  console.log("employeeSearch:::",employeeSearch);
// setTimeout(() => {
  setEmployeeSearchList(employeeSearch);
  setEmployeeSearchListTrue(!EmployeeSearchListTrue)

// },1000)
  

  // console.log("EmployeeSearchList::::11",EmployeeSearchList)

  }

  console.log("EmployeeSearchList::::",EmployeeSearchList)

  const displayEmpList =()=>{
    console.log("onclick")
    setIsModalVisible(true);

  }

  return (

    <div style={{padding: 20}}>
      <Card title='Take Course' style={{width:"auto"}}>
      {PatArrTrue && ClinicList.length > 0 ?
        <div className='row'>

          <div className='col-md-12 col-sm-12'>
            <Card style={{backgroundColor:"white", borderRadius:20}}>
              <Form layout='vertical'>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <FormItem label='Select Employee'>
                    <Input onClick={displayEmpList} />
  
    {SelectedEmpRecords.length>0 && SelectedEmpRecords.map((resp, index) => (
    <Tag 
     //onClose={()=>log(index)} 
     style={{backgroundColor:'#97abd3',marginBottom:10}}>{resp.first_name + " " + resp.last_name}</Tag>
    ))}

    <Modal
          visible={isModalVisible} 
          width={'60%'}
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={[
           
            <Button key="submit" style={{backgroundColor:'#33b5e5',}} onClick={handleOk}>
              Select
            </Button>,
            <Button key="submit" style={{backgroundColor:'#ff4444'}} onClick={handleCancel}>
            Cancel
          </Button>
          ]}
         
        >
         {patArr.length > 0 ?
         
        <div>
          <Card title='Employee List'>
          <Input
          style={{ border: "1px solid #a72626", margin: "0 0 10px 0" }}
          placeholder="Search by name"
          
          onChange={(event)=>searchEmployee(event)}
        />
            <div className='row'>
              <div className='col-12 col-md-12 col-xl-12' >

              <Table
   
    dataSource={EmployeeSearchList.length>0 ? EmployeeSearchList : patArr}
    columns={columns}
    // footer={}
    rowSelection={{
        type: 'radio',
      ...rowSelection,
    }}
   
  />

              </div>
            </div>
          </Card>        
        </div>
        :
     
        <Row>
          <Col span={10}>
          </Col>
          <Col span={8}>
            <Space size="large" style={{ marginTop: 30 }}>

              <Spin size="large" />
         
        </Space>
          </Col>
        </Row>
        
      }

   
        </Modal>
                    </FormItem>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <FormItem label='Select Course'>
                      <Select defaultValue=""
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                        onChange={(event) => eventType(event)}
                      >
                      {ClinicList.length>0 && ClinicList.map((row, index) => (
                        <Option value={JSON.stringify(row)}>
                            {row.course_name + " - " + row.category}</Option>
                       ))}
                      </Select>
                    </FormItem>
                  </div>
                </div>
                <br />
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <FormItem label='Start Date'>
                      <DatePicker 
                      style={{width:'100%'}}
                onChange={(date, dateString) =>setStartDate(dateString)}
                // onChange={onChange}
                 />
                    </FormItem>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                  <FormItem label='Target Completion Date'>
                     <DatePicker 
                     style={{width:'100%'}}
                onChange={(date, dateString) =>setCompletionDate(dateString)}
                //onChange={onChange}
                 />
                    </FormItem>
                   
                  </div>
                </div>
                
                <br /><br />
                <div className='row'>
                  <div className='col-md-4 col-sm-4'>

                  </div>
                  <div className='col-md-5 col-sm-5'>
                    <Button type='primary' style={{ width: '50%',backgroundColor:"#1b3969" }}
                      onClick={saveTest}
                    >Save</Button>
                  </div>
                </div>
              </Form>
            </Card>


          </div>



        </div>

        :
        <Row>
          <Col span={10}>
          </Col>
          <Col span={8}>
            <Space size="middle" style={{ marginTop: 10 }}>

              <Spin size="large" />
            </Space>
          </Col>
        </Row>


      }


</Card>
    </div>
  );
};

export default AddCoursesEmployee;
