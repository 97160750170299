import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Button } from 'antd';
import moment from 'moment';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FolderOpenTwoTone } from '@ant-design/icons/lib';

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'covidTest.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const CovidTestOfEmployee = ({ data, docTestPermission, download2 }) => {
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [covidTestData, setCovidTestData] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef(null);

  console.log(data,'CovidtestofEmployee')
  data.sort((a,b)=>{
    const dateA = new Date(a.test_date);
    const dateB = new Date(b.test_date);
    return dateB - dateA;
  })
  useEffect(() => {
    const ress = data.map((val) => {
      return {
        ...val,
        newTestDate: val.test_date ? val.test_date : '',
        newTestDateForExcel: val.test_date ? moment(val.test_date).format('MM/DD/YYYY') : '',
        newLocation:
          val.child_ids.length > 0 &&
          !val.child_ids[1].includes('|') &&
          !val.child_ids[1].includes(',')
            ? val.child_ids[1]
            : val.child_ids[1]?.split('|')[1] || val.child_ids[1]?.split(',')[1],
        newEmail: val.email
      };
    });
    setCovidTestData(ress);
    //setWijmoFlexGrid(ress, 10);
    console.log(ress, 'ressTesingCovid');
  }, []);

  useEffect(() => {
    if (covidTestData.length > 0) {
      const collectionView1 = new CollectionView(covidTestData, {
        pageSize: covidTestData.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(covidTestData, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      setView(collectionView2);
    }
  }, [covidTestData, pageSize]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showTestName = (item) => {
    return <span className='nowrap'>{item.item.test_type}</span>;
  };
  const showLocation = (item) => {
    return <span className='nowrap'>{item.item.newLocation}</span>;
  };

  const showTestDate = (item) => {
    return (
      <span className='nowrap'>
        {item.item.test_date ? moment(item.item.test_date).format('MM/DD/YYYY') : ''}
      </span>
    );
  };

  const showResult = (item) => {
    return item.item.test_result === 'Positive' ? (
      <span style={{ color: 'red', fontSize: 12, fontWeight: 600 }}>{item.item.test_result}</span>
    ) : (
      <span style={{ color: 'green', fontSize: 12, fontWeight: 600 }}>{item.item.test_result}</span>
    );
  };
  const showStatus = (item) => {
    return <span>{item.item.testing_status ? item.item.testing_status : ''}</span>;
  };

  // const showConductedBy = (item) => {
  //   return <span>{item.item.conducted_by == 'ODOO-PORTAL' ? 'Self' : item.item.conducted_by}</span>;
  // };

  // const showDoc = (item) => {
  //   return (
  //     <span className='nowrap'>
  //       {docTestPermission ? (
  //         !item.item.attachment_url ? (
  //           item.item.attachment_id.length > 0 && (
  //             <button style={{margin:'-2px 0px'}} onClick={(e) => download2(e, item.item.attachment_id)}>Preview</button>
  //           )
  //         ) : (
  //           <button style={{margin:'-2px 0px'}} onClick={(e) => download2(e, item.item.attachment_url)}>Preview</button>
  //         )
  //       ) : (
  //         (item.item.attachment_id || item.item.attachment_url) && (
  //           <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
  //         )
  //       )}
  //     </span>
  //   );
  // };

  const showDoc = (item) => {
    return (
      <span className='nowrap'>
        {docTestPermission ? (
          !item.item.attachment_url ? (
            item.item.attachment_id.length > 0 && (
              // <p style={{color:'blue'}}>Preview
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => download2(e, item.item.attachment_id)}
                />
              // </p>
            )
          ) : (
            // <button style={{margin:'-2px 0px'}} onClick={(e) => download2(e, item.item.attachment_url)}>Preview</button>
            // <p style={{color:'blue'}}>Preview
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => download2(e, item.item.attachment_id)}
              />
            // </p>
          )
        ) : (
          (item.item.attachment_id || item.item.attachment_url) && (
            <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
          )
        )}
      </span>
    );
  };

  const showEmail = (item) => {
    console.log(item, 'showEmail');
    return <span>{item.item.newEmail ? item.item.newEmail : ''}</span>;
  };

  return (
    <div>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={10}>
          <FlexGridSearch
            ref={theSearch}
            placeholder='Search'
            cssMatch=''
            style={{ width: '70%', height: '40px' }}
          />
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
          <div ref={fixedContainerRef}>
            <Select
              style={{ width: 170 }}
              placeholder={`Items per page : ${pageSize}`}
              onChange={paggination}
              options={[
                { label: 'Items per page : 10', value: 10 },
                { label: 'Items per page : 20', value: 20 },
                { label: 'Items per page : 50', value: 50 }
              ]}
              getPopupContainer={() => fixedContainerRef.current}
            />
          </div>
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={10}>
          <Button
            type='primary'
            size='small'
            style={{ backgroundColor: '#3d586a', float: 'right' }}
            disabled={isExcelPreparing}
            onClick={exportToExcel}
          >
            {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
          </Button>
        </Col>
      </Row>

      {/* to export entire data of table */}
      <FlexGrid
        ref={theGrid}
        style={{ display: 'none' }}
        className='dummyWijmoData'
        itemsSource={viewData}
        initialized={gridInitializedData}
        formatItem={formatItem}
        isReadOnly={true}
      >
        <FlexGridColumn binding='newLocation' header='Location' minWidth={150} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showLocation} />
        </FlexGridColumn>

        <FlexGridColumn binding='newEmail' header='Email' width={230}>
          <FlexGridCellTemplate cellType='Cell' template={showEmail} />
        </FlexGridColumn>

        <FlexGridColumn binding='test_type' header='Test Name' minWidth={150} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showTestName} />
        </FlexGridColumn>
        <FlexGridColumn binding='newTestDateForExcel' header='Test Dates' minWidth={200} width='*'/>
        <FlexGridColumn binding='test_result' header='Results' minWidth={150} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showResult} />
        </FlexGridColumn>
        <FlexGridColumn binding='testing_status' header='Status' minWidth={140} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showStatus} />
        </FlexGridColumn>
        {/* <FlexGridColumn
          binding='conducted_by'
          header='Conducted By'
          // width='11*'
          minWidth={150}
          width='*'
        >
          <FlexGridCellTemplate cellType='Cell' template={showConductedBy} />
        </FlexGridColumn> */}
      </FlexGrid>

      <FlexGrid
        // autoRowHeights={true}
        autoGenerateColumns={false}
        ref={theGrid}
        id='theGridTallRows'
        className='covidtesttab cliniclisttable'
        alternatingRowStep={1}
        itemsSource={view}
        initialized={gridInitialized}
        formatItem={formatItem}
        itemsPerPage={10}
        isReadOnly={true}
      >
        <div style={{ display: 'none' }}>
          <wjInput.ListBox
            className='column-picker'
            itemsSource={listboxColumns}
            checkedMemberPath='visible'
            displayMemberPath='header'
            lostFocus={hidePicker}
            initialized={initializedListBox}
          ></wjInput.ListBox>
        </div>
        <FlexGridColumn binding='newLocation' header='Location' width={190}>
          <FlexGridCellTemplate cellType='Cell' template={showLocation} />
        </FlexGridColumn>

        <FlexGridColumn binding='newEmail' header='Email' width={230}>
          <FlexGridCellTemplate cellType='Cell' template={showEmail} />
        </FlexGridColumn>

        <FlexGridColumn binding='test_type' header='Test Name' width={167.5}>
          <FlexGridCellTemplate cellType='Cell' template={showTestName} />
        </FlexGridColumn>
        <FlexGridColumn binding='newTestDate' header='Test Dates' width={167.5}>
          <FlexGridCellTemplate cellType='Cell' template={showTestDate} />
        </FlexGridColumn>
        <FlexGridColumn binding='test_result' header='Results' width={167.5}>
          <FlexGridCellTemplate cellType='Cell' template={showResult} />
        </FlexGridColumn>
        <FlexGridColumn binding='testing_status' header='Status' width={167.5}>
          <FlexGridCellTemplate cellType='Cell' template={showStatus} />
        </FlexGridColumn>
        {/* <FlexGridColumn
          binding='conducted_by'
          header='Conducted By'
          // width='11*'
          // minWidth={150}
          // width='*'
          width={185}
        >
          <FlexGridCellTemplate cellType='Cell' template={showConductedBy} />
        </FlexGridColumn> */}

        <FlexGridColumn binding='attachment_url' header='Documents' width={88}>
          <FlexGridCellTemplate cellType='Cell' template={showDoc} />
        </FlexGridColumn>
        <FlexGridFilter
          filterColumns={[
            'test_type',
            'newTestDate',
            'test_result',
            'testing_status',
            'conducted_by'
          ]}
        />
      </FlexGrid>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div
          style={{
            backgroundColor: '#425a81',
            color: '#fff',
            display: 'inline',
            borderRadius: '5px',
            height: '30px'
          }}
        >
          <CollectionViewNavigator
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
            headerFormat='Page {currentPage:n0} of {pageCount:n0}'
            byPage={true}
            cv={view}
          />
        </div>
        &nbsp;&nbsp;&nbsp;
        <p
          style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}
          ref={filterRefCounter}
          id='filterCounter'
        >
          {Globalize.format(filterRefCounter.current, 'n0')}
        </p>
        &nbsp;
        <p style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}>
          {' '}
          out of {covidTestData.length}
        </p>
      </div>
    </div>
  );
};

export default CovidTestOfEmployee;
