import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Form, Input } from 'antd';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
import _ from 'underscore';
import { history } from '../../redux/store';
import setting from '../../config/setting';
import { Spin, Space, Row, Col, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;

const EditLocationEmployee = () => {
    const [form] = Form.useForm();
    const location = useLocation();
    let data = location.state ? location.state.data : null;
    const [PatArrTrue, setPatArrTrue] = useState(true);
    const [streetNew, setstreet] = useState('');
    const [street2New, setstreet2] = useState('');
    const [stateNewA, setstateNew] = useState('');
    const [cityNewA, setcityNew] = useState('');
    const [zipNew, setzip] = useState('');
    const [locationNewA, setlocationNew] = useState('');
    const [dlNew, setdl] = useState('');
    const [countrNew, setCountr] = useState('');
    const [countName, setCountrName] = useState('');
    const [stateNewId, setstateNewID] = useState('');
    const [stateLocal, setStateLocal] = useState('');
    const [errorVal, setError] = useState(false);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [locationVal, setlocation] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log('location state edit location', location.state);
        // console.log('this.props');
        getCountry();
        form.setFieldsValue({
            LocationName: formatValue(data['name']),
            Street: formatValue(data['street']),
            State: formatValue(data['state_id'][1]),
            Country: formatValue(data['country_id'][1])
        });
        updateValues();
    }, []);
    const formatValue = (value) => {
        return value ? value : '';
    };

    function updateLocationCustomer() {
        console.log('stateLocal:::::', stateLocal);

        let loginCustomerData = JSON.parse(localStorage.getItem('customerData'));

        if (streetNew && locationVal) {
            setIsLoading(true);
            console.log('updatesaveEmployee else');
            axios
                .post(setting.serverUrl + '/api/updatesaveLocation', {
                    params: {
                        email: localStorage.getItem('myData'),
                        user: localStorage.getItem('myData'),
                        pass: localStorage.getItem('myPass'),
                        loc_id: data['id'],
                        name: locationVal,
                        streetNew: streetNew,
                        street2New: street2New,
                        stateNewA: [parseInt(stateNewId), stateNewA],
                        cityNewA: cityNewA,

                        zipNew: zipNew,
                        locationNewA: parseInt(locationNewA),
                        dlNew: dlNew,
                        countrNew: [parseInt(countrNew), countName],
                        comp_id: loginCustomerData.id
                    }
                })
                .then((response) => {
                    console.log('Respone from updateLocation::::: ', response.data);
                    // if (response && response.data.error) {
                    //     alert('Some Technical Error Occurred!');
                    // }
                    setIsLoading(false);

                    if (response && response.status == 200) {
                        history.push({ pathname: `/vertical/locationList` });
                    }
                });
        } else {
            console.log('updateEmployee if');
            setError(true);
        }
    }
    const updateValues = () => {
        setstreet(data['street']);
        setstreet2(data['street2']);
        setstateNewID(data['state_id'][0]);
        setstateNew(data['state_id'][1]);
        setlocation(data['name']);
        setcityNew(data['city']);
        setCountr(data['country_id'][0]);
        setCountrName(data['country_id'][1]);
        setzip(data['zip']);
    };

    function getCountry() {
        setPatArrTrue(false);
        let countryArr = [];
        axios
            .post(setting.serverUrl + '/api/getCountry', {
                params: {
                    email: localStorage.getItem('myData'),
                    user: localStorage.getItem('myData'),
                    pass: localStorage.getItem('myPass')
                }
            })
            .then((response) => {
                console.log('Respone from getCountry', response.data);
                // if (response && response.data.error) {
                //     alert('Some Technical Error Occurred!');
                // }
                setCountry(response.data);

                if (response && response.status == 200) {
                    setPatArrTrue(true);
                }
            });
    }

    function onBlur() {
        console.log('blur');
    }

    function onFocus() {
        console.log('focus');
    }

    function onSearch(val) {
        console.log('search:', val);
    }

    function onSearchState(val) {
        console.log('search:', val);
    }

    function street(event) {
        console.log('street', event.target.value);
        setstreet(event.target.value);
    }

    function street2(event) {
        console.log('street2', event.target.value);
        setstreet2(event.target.value);
    }

    function stateNew(event) {
        console.log('stateNew', JSON.parse(event));
        var parseDataState = JSON.parse(event);
        setstateNew(parseDataState.name);
        setstateNewID(parseDataState.id);
    }

    console.log('state', stateNewA, stateNewId);

    function cityNew(event) {
        console.log('cityNew', event.target.value);
        setcityNew(event.target.value);
    }

    function zip(event) {
        console.log('zip', event.target.value);
        setzip(event.target.value);
    }

    function stateSelect(event) {
        console.log('event country', JSON.parse(event));

        setStateLocal(JSON.parse(event).name);

        var parseData = JSON.parse(event);

        setCountr(parseData.id);
        setCountrName(parseData.name);
        axios
            .post(setting.serverUrl + '/api/getState', {
                params: {
                    email: localStorage.getItem('myData'),
                    user: localStorage.getItem('myData'),
                    pass: localStorage.getItem('myPass'),
                    event: parseData.id
                }
            })
            .then((response) => {
                console.log('Respone from getState', response.data);
                if (response && response.data.error) {
                    alert('Some Technical Error Occurred!');
                }
                if (response && response.status == 200) {
                    setState(response.data);
                }
            });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div style={{ padding: 20 }}>
            <Card title='Edit Location' style={{ padding: 20 }}>
                {PatArrTrue ? (
                    <div className='row' style={{ backgroundColor: '#fff', borderRadius: 20, padding: 20 }}>
                        <div className='col-md-12 col-sm-12'>
                            <Card>
                                <Form
                                    form={form}
                                    layout='vertical'
                                    initialValues={{ remember: true }}
                                    onFinish={() => updateLocationCustomer()}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <div className='row'>
                                        <div className='col-md-4 col-sm-12'>
                                            <FormItem
                                                label='Location Name'
                                                name='LocationName'
                                                rules={[{ required: true, message: 'This field is required.' }]}
                                            >
                                                <Input
                                                    placeholder='Location Name'
                                                    defaultValue={formatValue(data['loc_id'])}
                                                    onChange={(event) => setlocation(event.target.value)}
                                                />
                                            </FormItem>
                                        </div>
                                    </div>

                                    <h4>Add Address</h4>
                                    <div className='row'>
                                        <div className='col-md-4 col-sm-12'>
                                            <FormItem
                                                label='Street'
                                                name='Street'
                                                rules={[{ required: true, message: 'This field is required.' }]}
                                            >
                                                <Input
                                                    placeholder='Street'
                                                    onChange={(event) => street(event)}
                                                    defaultValue={formatValue(data['street'])}
                                                />
                                            </FormItem>
                                        </div>
                                        <div className='col-md-2'></div>
                                        <div className='col-md-4 col-sm-12'>
                                            <FormItem label='Street 2'>
                                                <Input
                                                    placeholder='Street 2'
                                                    onChange={(event) => street2(event)}
                                                    defaultValue={formatValue(data['street2'])}
                                                />
                                            </FormItem>
                                        </div>
                                        <div className='col-md-2'></div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-4 col-sm-12'>
                                            <FormItem
                                                label='Country'
                                                name='Country'
                                                rules={[{ required: true, message: 'This field is required.' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    defaultValue={data['country_id'] ? data['country_id'][1] : ''}
                                                    optionFilterProp='children'
                                                    onChange={(event) => stateSelect(event)}
                                                    onFocus={onFocus}
                                                    onBlur={onBlur}
                                                    onSearch={onSearch}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {country.length > 0 &&
                                                        country.map((count, index) => (
                                                            <Option value={JSON.stringify(count)}>{count.name}</Option>
                                                        ))}
                                                </Select>
                                            </FormItem>
                                        </div>
                                        <div className='col-md-2'></div>
                                        <div className='col-md-4 col-sm-12'>
                                            <FormItem
                                                label='State'
                                                name='State'
                                                rules={[{ required: true, message: 'This field is required.' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    defaultValue={data['state_id'] ? data['state_id'][1] : ''}
                                                    optionFilterProp='children'
                                                    onChange={(event) => stateNew(event)}
                                                    onSearch={onSearchState}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {state.length > 0 &&
                                                        state.map((state, index) => (
                                                            <Option value={JSON.stringify(state)}>{state.name}</Option>
                                                        ))}
                                                </Select>
                                            </FormItem>
                                        </div>
                                        <div className='col-md-2'></div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-4 col-sm-12'>
                                            <FormItem label='City' rules={[{ required: true }]}>
                                                <Input
                                                    defaultValue={formatValue(data['city'])}
                                                    onChange={(event) => cityNew(event)}
                                                    placeholder='City'
                                                />
                                            </FormItem>
                                        </div>
                                        <div className='col-md-2'></div>
                                        <div className='col-md-4 col-sm-12'>
                                            <FormItem label='Zip Code'>
                                                <Input
                                                    placeholder='Zip Code'
                                                    defaultValue={formatValue(data['zip'])}
                                                    onChange={(event) => zip(event)}
                                                />
                                            </FormItem>
                                        </div>
                                        <div className='col-md-2'></div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-4 col-sm-4'></div>
                                        <div className='col-md-5 col-sm-5'>
                                            <Button type='primary' htmlType='submit' style={{ width: '50%' , backgroundColor:"#1b3969"}}>
                                                Update
                                            </Button>
                                        </div>
                                    </div>
                                    {isLoading && (
                                        <Row>
                                            <Col span={10}></Col>
                                            <Col span={8}>
                                                <Space size='middle' style={{ marginTop: 10 }}>
                                                    <Spin size='large' />
                                                </Space>
                                            </Col>
                                        </Row>
                                    )}
                                </Form>
                            </Card>
                        </div>
                    </div>
                ) : (
                    <Row>
                        <Col span={10}></Col>
                        <Col span={8}>
                            <Space size='middle' style={{ marginTop: 10 }}>
                                <Spin size='large' />
                            </Space>
                        </Col>
                    </Row>
                )}
            </Card>
        </div>
    );
};

export default EditLocationEmployee;
