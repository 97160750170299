import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from '../../config/setting';
import {
    Button,
    Input,
    Select,
    Radio,
    Form,
    DatePicker,
    Spin,
    Space,
    Row,
    Col,
    Card,
    notification,
    Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
let i = 1;

const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;

const NewOrderForm = () => {
    const [form] = Form.useForm();
    const [locationNewA, setlocationNew] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [bool, setBool] = useState(false);
    const [Res, setRes] = useState([]);
    const [dateNew, setDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [legalName, setLegalName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [ssnValue, setSsnValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    // const [address, setAddress] = useState('');
    const [Notes, setNotes] = useState('');
    // const [typeScreen, setTypeScreen] = useState('');
    // const [specialInst, setSpecialInst] = useState('');
    const [bgScreening, setBgScreening] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [reasons, setSelectReason] = useState('');
    const [reasonValue, setReasonValue] = useState([]);
    const [service_multi_select, setSelectPackage] = useState('');
    const [packageValue, setPackageValue] = useState([]);
    const [loadingLocations, setLoadingLocations] = useState(false);
    const [loadingReasons, setLoadingReasons] = useState(false);
    const [loadingPkgs, setLoadingPkgs] = useState(false);
    const [emp_ein, setEinValue] = useState('');
    const [orderTypes, setOrderTypes] = useState('new_employee')
    const [DL, setDrivingLicense] = useState('');
    const [typecheckValue, setTypeCheckValue] = useState('');
    const [noRecordFound, setNoRecordFound] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
    const pageLimit = 500;
    const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
    const [fullName, setFullName] = useState('');
    const [newName, setNewName] = useState('');
    const [ssn, setSSN] = useState('');
    const [locationSearch, setLocationSearch] = useState('');
    const [emailSearch, setEmailSearch] = useState('');
    const [genderSearch, setGenderSearch] = useState('');
    const [programModeSearch, setProgramModeSearch] = useState('');
    const [statusSearch, setStatusSearch] = useState('');
    const [searchDob, setSearchDob] = useState('');
    const [ein, setEIN] = useState('');
    const [dl, setDL] = useState('');
    const [dotMode, setDotMode] = useState('');
    const [searchAddCompany, setSearchAddCompany] = useState('');
    const [searchHireRehireDate, setSearchHireRehireDate] = useState('');
    const [searchJobTitle, setSearchJobTitle] = useState('');
    const [searchNewDept, setSearchNewDept] = useState('');

    const [searchNewSupervisor, setSearchNewSupervisor] = useState('');
    const [searchStatusMarital, setSearchStatusMarital] = useState('');
    const [searchTerminationDate, setSearchTerminationDate] = useState('');
    const [newNationalitySearch, setNewNationalitySearch] = useState('');
    const [newPassportSearch, setNewPassportSearch] = useState('');
    const [employeeData, setEmployeeData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [tableType, setTableType] = useState('all');
    const [loadMore, setLoadMore] = useState(1);
    const [loadMoreLoader, setLoadMoreLoader] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([{}]);
    const [fileInputs, setFileInputs] = useState([0]);
    // const [tableData, setTableData] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    // const filterRefCounter = useRef();
    const companyId = localStorage.getItem('customerId');
    const [employees, setEmployees] = useState([{ employeeId: '', legalName: '', jobTitle: '', emailValue: '', phoneValue: '', startDate: '', dateNew: '', emp_ein: '', ssnValue: '', DL: '', }]);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedEmployees = [...employees];

        // Set the updated value for the specified field
        updatedEmployees[index][name] = value;

        // Update the state with the new employees array
        setEmployees(updatedEmployees);

        console.log("Updated Employees:", updatedEmployees);
    };

    useEffect(() => {
        setSelectedCompany(companyId);
        getResPArt(companyId);
        getReasonNames();
        getPackages(companyId);

        var typeValue = localStorage.getItem('IdtypeCheckValue');
        setTypeCheckValue(JSON.parse(typeValue));

        form.setFieldsValue({
            company: companyId
        });
    }, []);

    useEffect(() => {
        if (legalName && locationNewA && phoneValue && emailValue) {
            setIsSaveButtonDisabled(false);
        }
    }, [])

    // ---------------EMPLOYEE DATA API START---------------------
    const getEmployee = (currentPageNumber = 1, forceReset = false, advanceFilter = false) => {
        setIsLoading(true);
        if (!forceReset && !advanceFilter) {
            setIsLoadMoreLoading(true);
        }
        i = 1;
        const customerData = JSON.parse(localStorage.getItem('customerData'));

        axios
            .post(setting.serverUrl + '/api/employeeCustomerPortal2', {
                params: {
                    user: localStorage.getItem('myData'),
                    pass: localStorage.getItem('myPass'),
                    loadMore: currentPageNumber,
                    fullName: forceReset ? '' : fullName || newName,
                    ssn: forceReset ? '' : ssn,
                    locationSearch: forceReset ? '' : locationSearch,
                    emailSearch: forceReset ? '' : emailSearch,
                    genderSearch: forceReset ? '' : genderSearch,
                    programModeSearch: forceReset ? '' : programModeSearch,
                    statusSearch: forceReset ? '' : statusSearch,
                    searchDob: forceReset ? '' : searchDob,
                    ein: forceReset ? '' : ein,
                    dl: forceReset ? '' : dl,
                    dotMode: forceReset ? '' : dotMode,
                    searchAddCompany: forceReset ? '' : searchAddCompany,
                    searchHireRehireDate: forceReset ? '' : searchHireRehireDate,
                    searchJobTitle: forceReset ? '' : searchJobTitle,
                    searchNewDept: forceReset ? '' : searchNewDept,
                    searchNewSupervisor: forceReset ? '' : searchNewSupervisor,
                    searchStatusMarital: forceReset ? '' : searchStatusMarital,
                    searchTerminationDate: forceReset ? '' : searchTerminationDate,
                    newNationalitySearch: forceReset ? '' : newNationalitySearch,
                    newPassportSearch: forceReset ? '' : newPassportSearch,
                    custIdArr: customerData.employee_multi_select,
                    compId: JSON.parse(localStorage.getItem('customerData')).id,
                    limit: pageLimit
                }
            })
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {

                    // var ress =
                    response &&
                        response.data &&
                        response.data.length > 0 &&
                        response.data.map((val) => {
                            return {
                                ...val,
                                fullName:
                                    val.first_name && val.last_name
                                        ? val.first_name +
                                        ' ' +
                                        val.last_name
                                        : '',
                                empLocId:
                                    val.emp_location && val.emp_location.length > 0 ? val.emp_location[0] : '',

                                emp_location:
                                    val.emp_location &&
                                        val.emp_location.length > 0 &&
                                        !val.emp_location[1].includes('|') &&
                                        !val.emp_location[1].includes(',')
                                        ? val.emp_location[1]
                                        : val.emp_location[1]?.split('|')[1] || val.emp_location[1]?.split(',')[1],

                                newDOB: val.dob ? val.dob : '',
                                newDOBforExcel: val.dob ? moment(val.dob).format('MM/DD/YYYY') : '',
                                terminationDate:
                                    val.Termination_Date != false
                                        ? moment(val.Termination_Date).format('MM/DD/YYYY')
                                        : '',
                                hireRehireDate: val.Hire_Rehire_Date != false ? val.Hire_Rehire_Date : '',
                                hireRehireDateforExcel: val.Hire_Rehire_Date != false ? moment(val.Hire_Rehire_Date).format('MM/DD/YYYY') : '',
                                newPassport: val.passport != false ? val.passport : '',
                                statusMarital: val.status_p != false ? val.status_p : '',
                                newNationality: val.nationality != false ? val.nationality : '',
                                newSupervisor: val.supervisor != false ? val.supervisor : '',
                                jobTitle: val.Job_Title != false ? val.Job_Title : '',
                                addCompany: val.add_company[1] != false ? val.add_company[1] : '',
                                newDept: val.department != false ? val.department : '',
                                newGender: val.Gender !== false ? val.Gender : '',
                                newEmail: val.email !== false ? val.email : '',
                                newEmpStatus: val.emp_status !== false ? val.emp_status : '',
                                newProgram: val.program !== false ? val.program : '',
                                newEin: val.emp_ein != false ? val.emp_ein : '',
                                newDL: val.DL != false ? val.DL : '',
                                newSSN: val.emp_ssn != false ? val.emp_ssn?.slice(-4) : ''

                            };
                        });

                    setIsLoadMoreLoading(false);
                    console.log("setEmployeeData--------", response.data)
                    setEmployeeData(response.data);

                    setLoadMore(currentPageNumber);
                    setLoadMoreLoader(false);
                    setHasPermission(true);
                    setErrMsg('Module not activated.');
                    setNoRecordFound(false);
                } else if (response.status === 201) {
                    setNoRecordFound(true);
                    setNoRecordMessage('Access permission not allowed.');
                    setErrMsg('Access permission not allowed.');

                } else if (response.status === 202) {
                    setNoRecordFound(false);
                    setHasPermission(true);
                    setNoRecordMessage('No Record found');
                } else {
                    notification.error({
                        message: 'Failed to retrieve data at the moment. Please try again in a bit.'
                    });
                    setNoRecordFound(true);
                }
            });
    };
    useEffect(() => {
        getEmployee();

        var typeValue = localStorage.getItem('IdtypeCheckValue');
        setTypeCheckValue(JSON.parse(typeValue));
    }, [pageSize, tableType]);

    // ---------------------EMPLOYEE DATA API END -----------------
    function getReasonNames() {
        setLoadingReasons(true);
        setIsLoading(true);
        axios
            .post(setting.serverUrl + '/api/getReasons', {
                params: {
                    email: localStorage.getItem('myData'),
                    user: localStorage.getItem('myData'),
                    pass: localStorage.getItem('myPass')
                }
            })
            .then((response) => {
                if (response && response.status == 200) {
                    const uniqueNames =
                        response &&
                        response.data.reduce((acc, obj) => {
                            if (!acc[obj.reasons_name]) {
                                acc[obj.reasons_name] = obj;
                            }
                            return acc;
                        }, {});
                    const uniqueArrays = Object.values(uniqueNames);
                    console.log("reason-----------response", uniqueArrays)

                    setReasonValue(uniqueArrays);
                    setLoadingReasons(false);
                    setIsLoading(false);
                }
            });
    }
    function getResPArt(custId) {
        setLoadingLocations(true);
        axios
            .post(setting.serverUrl + '/api/Res', {
                params: {
                    email: localStorage.getItem('myData'),
                    user: localStorage.getItem('myData'),
                    pass: localStorage.getItem('myPass'),
                    id: custId
                }
            })
            .then((response) => {
                if (response && response.status == 200) {
                    let finalResult = response.data.map((val) => {
                        return {
                            ...val,
                            name:
                                val.name != '' ? (val.name.includes('|') ? val.name.split('|')[1] : val.name) : ''
                        };
                    });

                    setRes(finalResult);
                    setLoadingLocations(false);
                    setBool(true);
                    setHasPermission(true);
                } else if (response.status === 201) {
                    setNoRecordFound(true);
                    setNoRecordMessage('Access permission not allowed.');
                    setErrMsg('Access permission not allowed.');
                } else {
                    notification.error({
                        message: 'Failed to retrieve data at the moment. Please try again in a bit.'
                    });
                    setNoRecordFound(true);
                }
            });
    }
    const onChangeDate = (index, date, dateString) => {
        const updatedEmployees = [...employees];
        updatedEmployees[index].dateNew = dateString;
        setEmployees(updatedEmployees);
    };

    const onChangeStartDate = (index, date, dateString) => {
        const updatedEmployees = [...employees];
        updatedEmployees[index].startDate = dateString;
        setEmployees(updatedEmployees);
    };

    function locationNew(event) {
        setlocationNew(event);
    }

    const handleEmployeeSelect = (index, employeeId) => {
        setSelectedEmployee(employeeId);
        const selectedEmployeeData = employeeData.find((employee) => employee.id === employeeId);
        let updatedEmployees = [...employees];
        updatedEmployees[index] = {
            employeeId: selectedEmployeeData.id,
            legalName: `${selectedEmployeeData.first_name} ${selectedEmployeeData.last_name}`,
            jobTitle: selectedEmployeeData.Job_Title,
            emailValue: selectedEmployeeData.email,
            phoneValue: selectedEmployeeData.Mobile,
            startDate: selectedEmployeeData.Hire_Rehire_Date ? selectedEmployeeData.Hire_Rehire_Date : null,
            dateNew: selectedEmployeeData.dob ? selectedEmployeeData.dob : null,
            emp_ein: selectedEmployeeData.emp_ein ? selectedEmployeeData.emp_ein : null,
            ssnValue: selectedEmployeeData.emp_ssn ? selectedEmployeeData.emp_ssn : null,
            DL: selectedEmployeeData.DL ? selectedEmployeeData.DL : null,
            // fullSSN: selectedEmployeeData.fullSSN ? selectedEmployeeData.fullSSN : null
        };
        setEmployees(updatedEmployees);
        form.setFieldsValue({
            [`legalName-${index}`]: `${selectedEmployeeData.first_name} ${selectedEmployeeData.last_name}`,
            [`jobTitle-${index}`]: selectedEmployeeData.Job_Title,
            [`emailValue-${index}`]: selectedEmployeeData.email,
            [`phoneValue-${index}`]: selectedEmployeeData.Mobile,
            [`startDate-${index}`]: selectedEmployeeData.Hire_Rehire_Date ? moment(selectedEmployeeData.Hire_Rehire_Date) : null,
            [`dateNew-${index}`]: selectedEmployeeData.dob ? moment(selectedEmployeeData.dob) : null,
            [`emp_ein-${index}`]: selectedEmployeeData?.emp_ein || '',
            [`ssnValue-${index}`]: selectedEmployeeData?.emp_ssn
                ? `***-**-${selectedEmployeeData.emp_ssn.slice(-4)}` : '',
            [`DL-${index}`]: selectedEmployeeData?.DL || ''
        });
    };

    const clearInputField = () => {
        setEmployees([{ employeeId: '', legalName: '', jobTitle: '', emailValue: '', phoneValue: '', startDate: '', dateNew: '', emp_ein: '', ssnValue: '', DL: '' }]);
        setSelectedEmployee('');
        setlocationNew(''); // Ensure correct capitalization for setter
        setBgScreening('');
        setNotes('');
        setSelectReason('');
        setSelectPackage('');
        setSelectedFiles([]);
        setFileInputs([0]);
        form.setFieldsValue({
            emailValue: '',
            phoneValue: '',
            jobTitle: '',
            dateNew: null, // Assuming this is a date field
            startDate: null, // Assuming this is a date field
            selectedEmployee: '',
            locationNewA: '', // Ensure this matches your form field name
            bgScreening: '',
            Notes: '',
            reasonValue: '',
            employees: '',
            service_multi_select: '',
            packageValue: '',
            selectedFiles: null,
            fileInputs: '',
            emp_ein: '',
            ssnValue: '',
            DL: ''
        });


        form.setFieldsValue({
            [`legalName-0`]: '',
            [`jobTitle-0`]: '',
            [`emailValue-0`]: '',
            [`phoneValue-0`]: '',
            [`emp_ein-0`]: '',
            [`DL-0`]: '',
            [`ssnValue-0`]: '',
            [`selectedEmployee-0`]: '',
            [`startDate-0`]: null,
            [`dateNew-0`]: null,
            [`locationNewA-0`]: '',
            [`reasons-0`]: '',
            [`bgScreening-0`]: '',
            [`Notes-0`]: '',
            [`service_multi_select-0`]: '',
            [`packageValue-0`]: ''
        });
    };

    useEffect(() => {
        clearInputField()
    }, [orderTypes]); // Run the effect when `orderTypes` changes

    useEffect(() => {
        setEmployees([{ employeeId: '', legalName: '', jobTitle: '', emailValue: '', phoneValue: '', startDate: '', dateNew: '', emp_ein: '', ssnValue: '', DL: '', }]);
        setSelectedEmployee('');
        form.setFieldsValue({
            [`legalName-0`]: '',
            [`jobTitle-0`]: '',
            [`emailValue-0`]: '',
            [`phoneValue-0`]: '',
            [`selectedEmployee-0`]: '',
            [`startDate-0`]: null,
            [`dateNew-0`]: null,
            [`emp_ein-0`]: '',
            [`DL-0`]: '',
            [`ssnValue-0`]: '',
        });

    }, [orderTypes]);

    // function saveEmployee() {
    //     setIsLoading(true);

    //     const param = {
    //         email: localStorage.getItem('myData'),
    //         user: localStorage.getItem('myData'),
    //         pass: localStorage.getItem('myPass'),


    //         bgScreening: bgScreening,
    //         locationNewA: parseInt(locationNewA),
    //         comNew: companyId,
    //         reasons: reasons,
    //         Notes: Notes,
    //         employees: employees,
    //         service_multi_select: service_multi_select,
    //         new_employee: orderTypes,
    //         selectedFiles: selectedFiles,
    //         // fileInputs: fileInputs
    //     };

    //     const formdata = new FormData();
    //     formdata.append('files[]', selectedFiles[i]);

    //     const headers = {
    //         'Content-Type': 'multipart/form-data'
    //     };
    //     // Print the param object for each employee

    //     console.log("Parameter Object for Employee:", param);

    //     // Make the API call for each employee
    //     axios
    //         .post(setting.serverUrl + '/api/saveNewEmployeeInst', {
    //             headers,
    //             data: formdata,
    //             params: param
    //         })
    //         .then((response) => {
    //             console.log("form response----", response)
    //             setIsLoading(false);
    //             if (response && response.status === 200) {
    //                 let id = response.data.id;
    //                 let customerData = JSON.parse(localStorage.getItem('customerData'));
    //                 customerData.employee_multi_select.push(id);
    //                 localStorage.setItem('customerData', JSON.stringify(customerData));
    //                 clearInputField()

    //                 if (response.data.error === 'Technical Issue') {
    //                     notification.error({
    //                         message: 'Failed to retrieve data at the moment. Please try again in a bit.'
    //                     });
    //                 } else {
    //                     notification['success']({
    //                         message: 'Request Sent Successfully!'
    //                     });
    //                     // history.push({ pathname: `/vertical/new_orderform` });
    //                     // setRes(response.data);
    //                     setIsSaveButtonDisabled(true);
    //                 }
    //             }
    //         })
    //         .catch((error) => {
    //             setIsLoading(false);
    //             console.error("Error saving employee:", error);
    //         });
    //     // });

    // }
    function saveEmployee() {
        setIsLoading(true);
        const formdata = new FormData();
        formdata.append('bgScreening', bgScreening);
        formdata.append('locationNewA', locationNewA);
        formdata.append('comNew', companyId);
        formdata.append('reasons', reasons);
        formdata.append('Notes', Notes);
        formdata.append('employees', JSON.stringify(employees));
        formdata.append('service_multi_select', service_multi_select);
        formdata.append('new_employee', orderTypes);

        formdata.append('email', localStorage.getItem('myData')); // Assuming 'myData' holds the email
        formdata.append('user', localStorage.getItem('myData')); // Assuming 'myData' holds the username
        formdata.append('pass', localStorage.getItem('myPass'));

        (selectedFiles as File[]).forEach((file) => {
            formdata.append("files[]", file);  // Append file directly as a File object
        });

        console.log("FormData contents:", formdata, selectedFiles);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        // Log the parameters being sent
        console.log("Parameters for Employee:", {
            email: localStorage.getItem('myData'),
            user: localStorage.getItem('myData'),
            pass: localStorage.getItem('myPass'),
        });

        // Make the API call
        axios.post(setting.serverUrl + '/api/saveNewEmployeeInst', formdata, { headers })

            .then((response) => {
                console.log("Form response:", response);
                setIsLoading(false);
                if (response && response.status === 200) {
                    let id = response.data.id;
                    let customerData = JSON.parse(localStorage.getItem('customerData'));
                    customerData.employee_multi_select.push(id);
                    localStorage.setItem('customerData', JSON.stringify(customerData));
                    clearInputField();
                    form.resetFields();
                    setSelectedFiles([]);
                    if (response.data.error === 'Technical Issue') {
                        notification.error({
                            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
                        });
                    } else {
                        notification.success({
                            message: 'Request Sent Successfully!'
                        });
                        setIsSaveButtonDisabled(true);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error saving employee:", error);
                notification.error({
                    message: 'Error',
                    description: 'There was an issue saving the employee. Please try again later.'
                });
            });
    }

    function reasonSelection(event, value) {
        setSelectReason(event);
        // getPackages(companyId,value)
    }

    function getPackages(companyId) {
        setLoadingPkgs(true);
        axios
            .post(setting.serverUrl + '/api/getNewEmpPkg', {
                params: {
                    email: localStorage.getItem('myData'),
                    user: localStorage.getItem('myData'),
                    pass: localStorage.getItem('myPass'),
                    companyId: companyId
                }
            })
            .then((response) => {
                console.log("getPackages response------", response)
                if (response && response.status == 200) {
                    let result = response.data.rows;
                    // let nonNullResult = result.filter((x) => x.reasons != null);
                    // let filteredPkg = nonNullResult.filter(
                    //     (key) => key.reasons && key.reasons.toLowerCase() == value.children.toLowerCase()
                    // );

                    setPackageValue(result);
                    console.log("filteredPkg----", result)
                    setLoadingPkgs(false);
                }
            });
    }
    const handleAddFileInput = () => {
        setFileInputs([...fileInputs, fileInputs.length]); // Add a new index for another input
    };

    const handleFileChange = (fileList, index) => {
        // const files = e.target.files[0]; // Get the first file (single file upload)
        // const updatedFiles = [...selectedFiles];
        // updatedFiles[index] = info; // Replace or add the file at the specified index
        console.log("kkkkkk", fileList?.map(val => val?.originFileObj))
        console.log("fileList", fileList)
        setSelectedFiles(fileList?.map(val => val?.originFileObj));
    };

    const handleAddRow = () => {
        setEmployees([...employees, { employeeId: '', legalName: '', jobTitle: '', emailValue: '', phoneValue: '', startDate: '', dateNew: '', emp_ein: '', ssnValue: '', DL: '', }]);
        form.setFieldsValue({
            [`legalName-${employees?.length}`]: '',
            [`jobTitle-${employees?.length}`]: '',
            [`emailValue-${employees?.length}`]: '',
            [`phoneValue-${employees?.length}`]: '',
            [`emp_ein-${employees?.length}`]: '',
            [`DL-${employees?.length}`]: '',
            [`ssnValue-${employees?.length}`]: '',
            [`startDate-${employees?.length}`]: null,
            [`dateNew-${employees?.length}`]: null,
            [`selectedEmployee-${employees?.length}`]: '',
        });
    };

    const handleUploadChange = (info, index) => {
        console.log("info====", info)
        handleFileChange(info?.fileList, index);
    };

    const handleRemoveRow = (index) => {
        const newEmployees = employees.filter((_, idx) => idx !== index);
        setEmployees(newEmployees);

        form.setFieldsValue({
            [`legalName-${index}`]: '',
            [`jobTitle-${index}`]: '',
            [`emailValue-${index}`]: '',
            [`phoneValue-${index}`]: '',
            [`emp_ein-${index}`]: '',
            [`DL-${index}`]: '',
            [`ssnValue-${index}`]: '',
            [`startDate-${index}`]: null,
            [`dateNew-${index}`]: null,
            [`selectedEmployee-${index}`]: '',
        });

        newEmployees.forEach((_, idx) => {
            form.setFieldsValue({
                [`legalName-${idx}`]: form.getFieldValue(`legalName-${idx + (idx >= index ? 1 : 0)}`) || '',
                [`jobTitle-${idx}`]: form.getFieldValue(`jobTitle-${idx + (idx >= index ? 1 : 0)}`) || '',
                [`emailValue-${idx}`]: form.getFieldValue(`emailValue-${idx + (idx >= index ? 1 : 0)}`) || '',
                [`phoneValue-${idx}`]: form.getFieldValue(`phoneValue-${idx + (idx >= index ? 1 : 0)}`) || '',
                [`emp_ein-${idx}`]: form.getFieldValue(`emp_ein-${idx + (idx >= index ? 1 : 0)}`) || '',
                [`DL-${idx}`]: form.getFieldValue(`DL-${idx + (idx >= index ? 1 : 0)}`) || '',
                [`ssnValue-${idx}`]: form.getFieldValue(`ssnValue-${idx + (idx >= index ? 1 : 0)}`) || '',
                [`startDate-${idx}`]: form.getFieldValue(`startDate-${idx + (idx >= index ? 1 : 0)}`) || null,
                [`dateNew-${idx}`]: form.getFieldValue(`dateNew-${idx + (idx >= index ? 1 : 0)}`) || null,
                [`selectedEmployee-${idx}`]: form.getFieldValue(`selectedEmployee-${idx + (idx >= index ? 1 : 0)}`) || '',
            });
        });
    };

    return hasPermission && bool ? (
        <div style={{ padding: 20 }}>
            <h5 style={{ textAlign: 'left', color: '#1C3A6A' }}>New Order</h5>

            <Card style={{ background: 'white' }}>
                <Form
                    layout='vertical'
                    onFinish={() => saveEmployee()}
                    form={form}
                    onValuesChange={() => {
                        form
                            .validateFields(['legalName', 'locationNewA', 'phoneValue', 'emailValue'])
                            .then(() => {
                                setIsSaveButtonDisabled(false);
                            })
                            .catch(() => {
                                setIsSaveButtonDisabled(true);
                            });
                    }}

                >
                    <div style={{ padding: '20px' }}>
                        <div className='row'>

                            <div className="col-md-6">
                                <Form.Item label="Order Type">
                                    <Select
                                        value={orderTypes}
                                        onChange={(value) => setOrderTypes(value)}
                                        loading={loadingLocations}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Select Order Type"
                                    >
                                        <Option value="new_employee">New Employee</Option>
                                        <Option value="existing_employee">Existing Employee</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className='col-md-6'>
                                <FormItem
                                    label='Company Location'
                                    name='locationNewA'
                                >
                                    <Select
                                        placeholder='Select Location'
                                        onChange={(event) => locationNew(event)}
                                        loading={loadingLocations}
                                        showSearch
                                        optionFilterProp='children'>
                                        {Res.length > 0 &&
                                            Res.map((loc, index) => <Option value={loc.id}>{loc.name}</Option>)}
                                    </Select>
                                </FormItem>
                            </div>

                            <div className='col-md-6'>
                                <FormItem label='Reason' name='reasonValue'>
                                    <Select
                                        placeholder='Select Reason'
                                        onChange={(event, value) => reasonSelection(event, value)}
                                        loading={loadingReasons}
                                        showSearch
                                        optionFilterProp='children'
                                    >
                                        {reasonValue.length > 0 &&
                                            reasonValue.map((key, index) => (
                                                <Option value={key.id}>{key.reasons_name}</Option>
                                            ))}
                                    </Select>
                                </FormItem>
                            </div>

                            <div className='col-md-6'>
                                <FormItem label='Package' name='packageValue'>
                                    <Select
                                        placeholder='Select Package'
                                        showSearch
                                        optionFilterProp='children'
                                        loading={loadingPkgs}
                                        // onChange={(e)=>setSelectPackage(e.target.value)}
                                        value={service_multi_select}
                                        onChange={(value) => {
                                            console.log("value-----", value)
                                            setSelectPackage(value); // Store the selected ID
                                        }}
                                    >
                                        {console.log("packageValue===", packageValue)}
                                        {packageValue.length > 0 &&
                                            packageValue.map((key, index) => <Option value={key.id}>{key.name}</Option>)}
                                    </Select>
                                </FormItem>
                            </div>

                            <div className='col-md-6'>
                                <Form.Item label='Background Screening'>
                                    <Radio.Group value={bgScreening} onChange={(e) => setBgScreening(e.target.value)}>
                                        <Radio value="Yes">Yes</Radio>
                                        <Radio value="No">No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>

                            <div className='col-md-6'>
                                <FormItem label='Notes' name='Notes'>
                                    <TextArea
                                        rows={3}

                                        value={Notes}
                                        style={{ backgroundColor: '#fff' }}
                                        placeholder='Enter Notes '
                                        onChange={(event) => setNotes(event.target.value)}

                                    />
                                </FormItem>
                            </div>

                        </div>
                        <h5 style={{ textAlign: 'left', color: '#1C3A6A' }}>Employee(s)</h5>

                        {employees.map((employee, index) => (

                            <div className='row' key={index}>

                                <div className='row'>

                                    <div className='row' style={{ alignItems: 'center', display: 'flex' }}>
                                        <div className="col">
                                            <i onClick={() => handleRemoveRow(index)} className="fa fa-times-circle" style={{
                                                fontSize: '20px',
                                                cursor: "pointer",
                                                color: "#1b3969",
                                                marginLeft: '5px'
                                            }}></i>
                                        </div>
                                    </div>

                                    {orderTypes === 'new_employee' && (
                                        <div className="col-md-2">
                                            <Form.Item
                                                label={index === 0 ? "Employee Name" : ""}
                                                name={`legalName-${index}`}
                                            >

                                                <Input
                                                    style={{ backgroundColor: 'white', color: '#000', marginLeft: 10 }}
                                                    placeholder="Enter Employee Name"
                                                    value={employee.legalName}
                                                    name="legalName"
                                                    onChange={(event) => handleInputChange(index, event)}
                                                />
                                            </Form.Item>
                                        </div>
                                    )}

                                    {orderTypes === 'existing_employee' && (
                                        <div className="col-md-3">
                                            <Form.Item
                                                label={index === 0 ? "Employee Name" : ""}
                                                name={`selectedEmployee-${index}`}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Search and select employee"
                                                    value={selectedEmployee}
                                                    onChange={(value) => handleEmployeeSelect(index, value)}
                                                    filterOption={(input, option) =>
                                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {employeeData.map((employee) => (
                                                        <Option key={employee.id} value={employee.id}>
                                                            {employee.first_name + ' ' + employee.last_name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    )}

                                    <div className="col-md-2" key={index}>
                                        <FormItem label={index === 0 ? "EIN" : ""}
                                            name={`emp_ein-${index}`}>
                                            <Input
                                                style={{ backgroundColor: 'white', color: '#000' }}
                                                placeholder="Enter EIN"
                                                onChange={(event) => handleInputChange(index, event)}
                                                value={employee.emp_ein}
                                                name='emp_ein'
                                                disabled={!!selectedEmployee}
                                            />
                                        </FormItem>
                                    </div>

                                    <div className="col-md-2">
                                        <FormItem label={index === 0 ? "SSN" : ""} name={`ssnValue-${index}`}>
                                            <MaskedInput
                                                style={{ backgroundColor: 'white', color: '#000' }}
                                                mask="***-**-1111"// Keeps the display mask consistent
                                                placeholder="Enter SSN _ _ _/_ _/_ _ _ _"
                                                onChange={(event) => handleInputChange(index, event)}
                                                value={employees[index].ssnValue}  // Only shows the masked format on display
                                                name="ssnValue"
                                                disabled={!!selectedEmployee} // Disable if an employee is selected
                                            />
                                        </FormItem>
                                    </div>

                                    <div className='col-md-2'>
                                        <FormItem label={index === 0 ? "DL" : ""}
                                            name={`DL-${index}`}>
                                            <Input
                                                style={{ backgroundColor: 'white', color: '#000' }}
                                                placeholder='Enter Driver License'
                                                onChange={(event) => handleInputChange(index, event)}
                                                name='DL'
                                                value={employee.DL}
                                                disabled={!!selectedEmployee}
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Item
                                            label={index === 0 ? "DOB" : ""}
                                            name={`dateNew-${index}`}
                                        >
                                            <DatePicker
                                                style={{ width: '100%', padding: 10, borderRadius: 20, color: '#000' }}
                                                placeholder="Enter date of birth"
                                                onChange={(date, dateString) => onChangeDate(index, date, dateString)}
                                                value={employee.dateNew != "" ? moment(employee.dateNew) : null}
                                                format="YYYY-MM-DD"
                                                disabled={!!selectedEmployee}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Item
                                            label={index === 0 ? "Job Title" : ""}
                                            name={`jobTitle-${index}`}
                                        >
                                            <Input
                                                style={{ backgroundColor: 'white', color: '#000' }}
                                                value={employee.jobTitle}
                                                name="jobTitle"
                                                onChange={(event) => handleInputChange(index, event)}
                                                placeholder="Enter Job Title"
                                                disabled={!!selectedEmployee}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Item
                                            label={index === 0 ? "Email" : ""}
                                            name={`emailValue-${index}`}
                                        >
                                            <Input
                                                style={{ backgroundColor: 'white', color: '#000' }}
                                                value={employee.emailValue}
                                                name="emailValue"
                                                onChange={(event) => handleInputChange(index, event)}
                                                placeholder="Enter Email"
                                                disabled={!!selectedEmployee}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="col-md-2">
                                        <Form.Item
                                            label={index === 0 ? "Phone" : ""}
                                            name={`phoneValue-${index}`}
                                        >
                                            <Input
                                                style={{ backgroundColor: 'white', color: '#000' }}
                                                placeholder="Enter Phone"
                                                value={employee.phoneValue}
                                                name="phoneValue"
                                                onChange={(event) => handleInputChange(index, event)}
                                                disabled={!!selectedEmployee}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-2">
                                        {orderTypes === 'new_employee' && (
                                            <Form.Item
                                                label={index === 0 ? "Start Date" : ""}
                                                name={`startDate-${index}`}
                                            >
                                                <DatePicker
                                                    style={{ width: '100%', padding: 10, borderRadius: 20, color: '#000' }}
                                                    placeholder="Enter Start Date"
                                                    onChange={(date, dateString) => onChangeStartDate(index, date, dateString)}
                                                    value={employee.startDate ? moment(employee.startDate, 'YYYY-MM-DD') : null}
                                                    format="YYYY-MM-DD"
                                                    disabled={!!selectedEmployee}
                                                />
                                            </Form.Item>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                        <Button type='primary' style={{ backgroundColor: '#1b3969' }} className='mt-4' onClick={handleAddRow}>
                            + Add Employee
                        </Button>

                        <div>
                            <div>
                                <h5 style={{ textAlign: 'left', color: '#1C3A6A' }}>Add Attachments File </h5>

                                {fileInputs.map((input, index) => (
                                    <Form.Item label="File" name={`selectedFiles_${index}`} key={index}>  {/* Unique key for each Form.Item */}
                                        <div className="row">
                                            <div className="col-xs-3">
                                                {/* <Input
                                                    type="file"
                                                    className="file-input"
                                                    style={{ padding: '5px' }}
                                                    accept="image/jpeg, image/png, image/gif"
                                                    onChange={(e) => handleFileChange(e, index)}
                                                /> */}
                                                <Upload
                                                    // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                                    listType="picture"
                                                    accept="image/jpeg, image/png, image/gif, application/pdf"
                                                    onChange={(info) => handleUploadChange(info, index)}
                                                // defaultFileList={selectedFiles}

                                                >
                                                    <Button style={{ backgroundColor: '#1b3969' }} type="primary" icon={<UploadOutlined />}>
                                                        Upload
                                                    </Button>
                                                </Upload>
                                            </div>
                                        </div>
                                    </Form.Item>
                                ))}

                                {/* <Button
                                    type='primary'
                                    style={{ backgroundColor: '#1b3969' }}
                                    className='mt-4'
                                    onClick={handleAddFileInput}
                                >
                                    + Add More
                                </Button> */}
                            </div>

                            <Button
                                type='primary'
                                style={{ backgroundColor: '#1b3969' }}
                                htmlType={'submit'}
                                disabled={isSaveButtonDisabled}
                                className='mt-4'
                            >
                                Submit
                            </Button>

                            {isLoading && (
                                <Space size='middle' style={{ marginTop: 10, marginLeft: 10 }}>
                                    <Spin size='large' />
                                </Space>
                            )}
                        </div>

                    </div>

                </Form>
            </Card>
        </div>
    ) : (
        <div>
            {errMsg.length > 0 ? (
                <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
                    &nbsp;&nbsp; {errMsg}
                </p>
            ) : (
                <Row>
                    <Col span={10}></Col>
                    <Col span={8}>
                        <Space size='middle' style={{ marginTop: 50 }}>
                            <Spin size='large' />
                        </Space>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default NewOrderForm;
