import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  Space,
  Row,
  Col,
  Modal,
  Input,
  Select,
  Button,
  Collapse,
  DatePicker,
  Card
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { date, number, string } from 'yup';
import { FilePdfTwoTone, FolderOpenTwoTone } from '@ant-design/icons/lib';

const config = require('../keyFront');
const { Panel } = Collapse;
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;
let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;
let selectedPDFs = [];

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'drug_and_alcohol_list.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const DrugAndAlcoholList = () => {
  const [ClinicList, setClinicList] = useState([]);
  const [change, setChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found');
  const [LoadMoreVal, setLoadMore] = useState(1);
  const [fullNameValue, setFullNameValue] = useState('');
  const [locValue, setLocValue] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [reasonValue, setReasonValue] = useState('');
  const [einValue, setEinValue] = useState('');
  const [ssnValue, setSsnValue] = useState('');
  const [dlValue, setDLValue] = useState('');
  const [panelValue, setPanelValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [ccfValue, setCcfValue] = useState('');
  const [collectionDate, setCollectionDate] = useState('');
  const [agenda, setAgenda] = useState('');
  const [reportingDate, setReportingDate] = useState('');
  const [caseValue, setCaseValue] = useState('');
  const [count, setCount] = useState();
  const [loadMoreHide, setLoadMoreHide] = useState(false);
  const [tableLoaderVal, settableLoaderVal] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [docPermission, setDocPermission] = useState(false);
  const [reasonsCount, setReasonsCount] = useState([]);
  const [resultCount, setResultCount] = useState([]);
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [cardFilterResponse, setCardFilterResponse] = useState([]);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [selectedCardFilter, setSelectedCardFilter] = useState(null);
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);
  const [selectedCardReasonFilter, setSelectedCardReasonFilter] = useState('');
  const [selectedCardResultFilter, setSelectedCardResultFilter] = useState('');
  const [newName, setNewName] = useState();

  const filterRefCounter = useRef();
  const theGrid = useRef();
  const { RangePicker } = DatePicker;
  // const theSearch = useRef();
  // const gridRef = useRef(null);
  const fixedContainerRef = useRef(null);
  const pagesize = 25;
  const { Search } = Input;
  useEffect(() => {
    getData(LoadMoreVal, true);
    getData(LoadMoreVal);
    getDocPermission();
    getDrugStatusCounts();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, [pageSize]);

  // let theGrids = theGrid.current;
  // let theSearchs = theSearch.current;
  // if (theGrids && theSearchs) {
  //   theSearchs.control.grid = theGrids.control
  // }

  const handleChange = (e) => {
    console.log(e.target.value);
    setNewName(e.target.value);
    // setFullNameValue(newName);
  };

  const onSearch = (value) => {
    if (newName.length > 0) {
      console.log('Name');
      getData(1, false, false, true);
      // setFullNameValue('')
    }
  };
  //Advance filter Modal cancel method

  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
    getDocPermission();
  };

  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.DrugAndAlcoholDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };
  const getDrugStatusCounts = () => {
    axios
      .post(setting.serverUrl + '/api/drugStatusCount', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.status === 200) {
          let responseArr = Object.entries(response.data);
          let k1Value = responseArr[0];

          let reasonStatus = k1Value[1];
          let filteredReasonStatus = k1Value[1].filter((k) => k.reasons != 'False');
          console.log('reasonStatus======', reasonStatus);
          const aggregatedReasonData = filteredReasonStatus.reduce((result, entry) => {
            const key = entry.reasons.toLowerCase() === 'random' ? 'RANDOM' : entry.reasons;
            result[key] = (result[key] || 0) + parseInt(entry.count, 10);
            return result;
          }, {});
          const finalReasonResult = Object.entries(aggregatedReasonData).map(
            ([reasons, count]) => ({
              reasons,
              count: count.toString()
            })
          );

          let k2Value = responseArr[1];
          let resultStatus = k2Value[1];
          let drugFinalStatusCounts = resultStatus;
          drugFinalStatusCounts.forEach(function (a) {
            if (typeof a.final_result === 'string') {
              a.final_result = a && a.final_result && a.final_result.toLowerCase();
            }
          });

          let filteredResultStatus = drugFinalStatusCounts.filter((k) => {
            return k.final_result == 'negative' || k.final_result == 'positive';
          });
          setReasonsCount(getReasonCount(finalReasonResult));
          setResultCount(getResultCount(filteredResultStatus));
        } else if (response && response.data == 202) {
          setReasonsCount(0);
          setResultCount(0);
        }
      });
  };
  function getReasonCount(filteredCount) {
    let tempHistory = [];
    filteredCount.forEach((item) => {
      if (item.reasons !== 'audiogram') {
        let existingItem = tempHistory.find((tempItem) => tempItem.reasons === item.reasons);
        if (existingItem) {
          existingItem.count = (existingItem.count || 0) + (item.count || 0);
        } else {
          tempHistory.push(item);
        }
      }
    });
    return tempHistory;
  }

  function getResultCount(filteredCount) {
    let tempHistory = [];
    filteredCount.forEach((item) => {
      let noMatch = true;
      if (tempHistory.length > 0) {
        tempHistory.forEach((tempItem, i) => {
          if (item.final_result === tempItem.final_result) {
            tempHistory[i].count = Number(tempHistory[i].count) + Number(item.count);
            noMatch = !noMatch;
          }
        });
      }
      return noMatch ? tempHistory.push(item) : null;
    });
    return tempHistory;
  }

  async function getEapIdCheckValue(empId) {
    const res = await axios.post(setting.serverUrl + '/api/getIdTypeCheckValue', {
      params: {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        compId: JSON.parse(localStorage.getItem('customerData')).id,
        employeeId: empId
      }
    });

    const response = await res;
    const result = response.data.rows;
    return result;
  }

  async function getData(
    LoadMoreVal,
    getcount = false,
    check = false,
    advanceFilter = false,
    resetFilter = false,
    selectedCardReasonFilter = '',
    selectedCardResultFilter = ''
  ) {
    i = 1;
    settableLoaderVal(true);
    //setClinicListTrue(false);
    setChange(true);
    setIsLoading(true);
    if (!advanceFilter && !resetFilter) {
      setIsLoadMoreLoading(true);
    }
    console.log(
      LoadMoreVal,
      getcount,
      check,
      advanceFilter,
      resetFilter,
      '....advance filter,Load more,getcount,check,advanceFilter,resetFilter'
    );
    const url = getcount
      ? '/api/getTestDetailsDrugAlcohol_count'
      : '/api/getTestDetailsDrugAlcohol';
    await axios
      .post(setting.serverUrl + url, {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          LoadMoreVal: LoadMoreVal,
          fullNameValue: check ? '' : fullNameValue || newName,
          locValue: check ? '' : locValue,
          statusValue: check ? '' : statusValue,
          panelValue: check ? '' : panelValue,
          reasonValue: check ? '' : reasonValue,
          ssnValue: check ? '' : ssnValue,
          ccfValue: check ? '' : ccfValue,
          caseValue: check ? '' : caseValue,
          einValue: check ? '' : einValue,
          dlValue: check ? '' : dlValue,
          collectionDate: check ? '' : collectionDate,
          reportingDate: check ? '' : reportingDate,
          agenda: check ? '' : agenda,
          selectedCardReasonFilter: check ? '' : selectedCardReasonFilter,
          selectedCardResultFilter: check ? '' : selectedCardResultFilter,
          getcount: advanceFilter ? true : getcount,
          companyId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then(async (response) => {
        //setIsLoading(false);
        //setChange(false);
        if (response && response.status == 200) {
          var arrayEmpIds = [];
          var resultIds;
          if (getcount == false) {
            arrayEmpIds.push(response.data?.map((k) => k.emp_selected[0]));

            settableLoaderVal(false);
            setNoRecordFound(false);

            setClinicList(response && response.data);
            setIsLoading(false);

            setCardFilterResponse(response && response.data);
            if (advanceFilter) {
              setCount(response.data.length);
            }
            if (response.data.length !== LoadMoreVal * pagesize) {
              setLoadMoreHide(true);
            } else {
              setLoadMoreHide(false);
            }
            setHasPermission(true);
          } else {
            setCount(response.data.count);
            // setShowHideAdvFilter(response.data.count > 0 ? true : false);
          }
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setIsLoading(false);

          setNoRecordMessage('Access permission not allowed.');
          setErrMsg('Access permission not allowed.');
        } else {
          // notification.error({
          //   message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          // });
          setNoRecordFound(true);
        }

        if (response && response.status == 200) {
          console.log(response, 'drug and alcohol');
          if (response.data.length > 0) {
            response.data.sort((a, b) => {
              const dateA = new Date(a.testing_date);
              const dateB = new Date(b.testing_date);
              return dateB - dateA;
            });
          }

          const idResult = await getEapIdCheckValue(arrayEmpIds);
          const resss = [];
          const ab =
            response &&
            response.data &&
            response.data.length > 0 &&
            response.data.map((value) => {
              return idResult.filter((val, index) => {
                if (value.emp_selected[0] === val.id) {
                  resss.push({ ...value, idType: idResult[index] });
                }
              });
            });
          if (getcount == false) {
            settableLoaderVal(false);
            setNoRecordFound(false);

            const newResponse = resss.map((k) => {
              return {
                ...k,
                fullName: k?.first_name + ' ' + k?.last_name,
                test_name:k?.test_name[1],
                newLocation:
                  k.child_ids[1] != ''
                    ? k.child_ids[1].split(',')[1]
                      ? k.child_ids[1].split(',')[1].includes('|')
                        ? k.child_ids[1].split(',')[1].trim().split('|')[1].trim()
                        : k.child_ids[1].split(',')[1]
                      : k.child_ids[1].split(',')[0].trim()
                    : '',
                newCollected: k.testing_date ? k.testing_date : '',
                newCollectedforExcel: k.testing_date ? moment(k.testing_date).format('MM/DD/YYYY'):'',
                newCcf: k.ccf != false ? k.ccf : '',
                newEin: k.emp_ein != false ? k.emp_ein : '',
                newSsn:
                  k.emp_ssn != false && k.emp_ssn != null ? '***-**-' + k.emp_ssn?.substr(-4) : '',
                ccfFileName: k.ccfFileName != false ? k.ccfFileName : '',
                mroFileName: k.mroFileName != false ? k.mroFileName : '',
                tenpanelReport: k.tenpanelReport != false ? k.tenpanelReport : '',
                twelvepanelReport: k.twelvepanelReport != false ? k.twelvepanelReport : '',
                breathalcohol: k.breathalcohol != false ? k.breathalcohol : '',
                attachmentUrls: k.attachmentUrls != false ? k.attachmentUrls : '',
                final_result: k.final_result != false ? k.final_result : '',
                comments: k.comments != false ? k.comments : '',
                jobTitle: k.emp_data[0].Job_Title != false ? k.emp_data[0].Job_Title : '',
                departments: k.emp_data[0].department != false ? k.emp_data[0].department : '',
                program: k.program != false ? k.program : '',
                mode: k.mode != false ? k.mode : '',
                reasons: k.reasons != 'False' ? k.reasons : ''
              };
            });
            console.log({ newResponse });
            setClinicList(newResponse);
            const collectionView1 = new CollectionView(newResponse, {
              pageSize: newResponse.length
            });
            setViewData(collectionView1);
            const collectionView2 = new CollectionView(newResponse, {
              pageSize: pageSize,
              collectionChanged: (s) => {
                filterRefCounter.current = s.totalItemCount;
                var filterCounter = document.getElementById('filterCounter');
                if (filterCounter) {
                  filterCounter.textContent = s.totalItemCount;
                }
              }
            });
            setView(collectionView2);
            setIsLoadMoreLoading(false);
            // setWijmoFlexGrid(newResponse, 10);
            if (advanceFilter) {
              setCount(response.data.length);
            }
            if (response.data.length !== LoadMoreVal * pagesize) {
              setLoadMoreHide(true);
            } else {
              setLoadMoreHide(false);
            }
            setHasPermission(true);
          } else {
            setCount(response.data.count);
          }
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setIsLoading(false);

          setNoRecordMessage('Access permission not allowed.');
          setErrMsg('Access permission not allowed.');
        } else if (response.status === 202) {
          setNoRecordFound(false);
          //setWijmoFlexGrid([], 10);
          setHasPermission(true);
          setNoRecordMessage('No Record Found.');
        } else {
          // notification.error({
          //   message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          // });
          setNoRecordFound(true);
        }
      });
  }

  function download(e, attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: 'https://wss-files.s3.amazonaws.com/' + attachment
        }
      })
      .then((response) => {
        console.log('Respone from getData testEventReports', response.data);
        //setChange(!change)
        window.open(response.data);
      });
  }
  function downloadAttachmentMultiple(attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        console.log('Respone from attachment multiple', response.data);
        //setChange(!change);
        window.open(response.data);
      });
  }
  // const handleClinicHoursNN = (data) => {
  //   console.log('data handleClinicHoursNN::', data);
  //   history.push({ pathname: `/vertical/testingDetailViewFurther`, state: data });
  // };

  const handleReset = () => {
    setIsLoading(true);
    setNewName('');
    setCount();
    setFullNameValue('');
    setLocValue('');
    setSsnValue('');
    setEinValue('');
    setPanelValue('');
    setReasonValue('');
    setStatusValue('');
    setCcfValue('');
    setCaseValue('');
    setCollectionDate('');
    setSelectedCardReasonFilter('');
    setSelectedCardResultFilter('');
    getData(LoadMoreVal, false, true, false, true);
    getData(LoadMoreVal, true, true, false, true);
  };

  const advanceDateFilter = (filterType, value) => {
    if (filterType == 'collectiondate') {
      console.log('collection date: ', typeof value);
      setCollectionDate(value);
    } else if (filterType === 'reportingdate') {
      setReportingDate(value);
    }
  };

  const showAdvanceFilterModal = () => {
    setIsAdvanceFilterModalOpen(true);
  };

  const handleAdvanceFilterCancel = () => {
    setIsAdvanceFilterModalOpen(false);
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterModalOpen(false);
    getData(1, false, false, true);
  };

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
    } else {
      exportService.cancelExcelExport((progress) => {
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 37; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  // const showCcfDoc = (record) => {
  //   return (
  //     <span
  //       className='nowrap'
  //       style={{ display: 'flex', flexDirection: 'column', width: '75%', textAlign: 'center' }}
  //     >
  //       {docPermission ? (
  //         <>
  //           <div>
  //             {record.item && record.item.ccfFileName ? (
  //               <button
  //                 size='medium'
  //                 style={{ marginTop: '-1px' }}
  //                 onClick={(e) => download(e, record.item.ccfFileName)}
  //               >
  //                 View Doc
  //               </button>
  //             ) : (
  //               ''
  //             )}
  //           </div>
  //           <div
  //             style={{
  //               display: 'flex',
  //               flexDirection: 'column',
  //               width: '100%',
  //               textAlign: 'center'
  //             }}
  //           >
  //             {record.item && record.item.attachmentUrls && record.item.attachmentUrls.length > 0
  //               ? record.item.attachmentUrls.map((url, index) => {
  //                   return (
  //                     <>
  //                       <button
  //                         style={index == 0 ? { marginTop: '-1px' } : { marginTop: '8px' }}
  //                         size='medium'
  //                         onClick={() => downloadAttachmentMultiple(url.url)}
  //                       >
  //                         View Doc
  //                       </button>
  //                     </>
  //                   );
  //                 })
  //               : ''}
  //           </div>
  //         </>
  //       ) : (
  //         <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
  //       )}
  //     </span>
  //   );
  // };

  const showCcfDoc = (record) => {
    return (
      <span
        className='nowrap'
        style={{ display: 'flex', flexDirection: 'row', width: '100%', textAlign: 'center' }}
      >
        {docPermission ? (
          <>
            <div>
              {record.item && record.item.ccfFileName ? (
                // <p style={{ color: 'blue', paddingRight:10}}>
                //   View Doc
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => download(e, record.item.ccfFileName)}
                />
              ) : (
                // </p>
                ''
              )}
            </div>
            <div
            // style={{
            //   display: 'flex',
            //   flexDirection: 'row',
            //   width: '100%',
            //   textAlign: 'center'
            // }}
            // style={{marginLeft:10}}
            >
              {record.item && record.item.attachmentUrls && record.item.attachmentUrls.length > 0
                ? record.item.attachmentUrls.map((url, index) => {
                    return (
                      <>
                        {/* <p style={{ color: 'blue', paddingRight:10 }}>
                          View Doc */}
                        <FolderOpenTwoTone
                          twoToneColor='#cc7a00'
                          style={{
                            fontSize: '16px',
                            cursor: 'pointer',
                            marginLeft: '10px',
                            cursor: 'pointer'
                          }}
                          onClick={() => downloadAttachmentMultiple(url.url)}
                        />
                        {/* </p> */}
                      </>
                    );
                  })
                : ''}
            </div>
          </>
        ) : (
          <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
        )}
      </span>
    );
  };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showResult = (item) => {
    return (
      <span>
        {item.item.final_result === 'NEGATIVE' ? (
          <p style={{ color: 'green' }}>{item.item.final_result}</p>
        ) : item.item.final_result === 'POSITIVE' ? (
          <p style={{ color: 'red' }}>{item.item.final_result}</p>
        ) : item.item.final_result !== 'POSITIVE' && item.item.final_result !== 'NEGATIVE' ? (
          <p style={{ color: 'orange' }}>{item.item.final_result}</p>
        ) : (
          ''
        )}
      </span>
    );
  };
  // const showResultDoc = (record) => {
  //   return (
  //     <div style={{ display: 'flex', flexDirection: 'column', width: '65%' }}>
  //       {docPermission ? (
  //         <>
  //           {' '}
  //           {record.item.mroFileName == false ? (
  //             ''
  //           ) : (
  //             <>
  //               <button onClick={(e) => download(e, record.item.mroFileName)}>Result</button>
  //             </>
  //           )}
  //           {record.item.tenpanelreport == false ? (
  //             ''
  //           ) : (
  //             <>
  //               <button onClick={(e) => download(e, record.item.tenpanelreport)}>Result </button>
  //             </>
  //           )}
  //           {record.item.twelvepanelreport == false ? (
  //             ''
  //           ) : (
  //             <>
  //               <button onClick={(e) => download(e, record.item.twelvepanelreport)}>Result</button>
  //             </>
  //           )}
  //           {record.item.breathalcohol == false ? (
  //             ''
  //           ) : (
  //             <>
  //               <button onClick={(e) => download(e, record.item.breathalcohol)}>Result</button>
  //             </>
  //           )}
  //         </>
  //       ) : (
  //         (record.item.mroFileName ||
  //           record.item.attachmentUrls ||
  //           record.item.breathalcohol ||
  //           record.item.twelvepanelreport ||
  //           record.item.tenpanelreport ||
  //           record.item.ccfFileName ||
  //           record.item.mroFileName) && (
  //           <CheckSquareTwoTone style={{ color: 'green', fontSize: 18 }} />
  //         )
  //       )}
  //     </div>
  //   );
  // };

  const showResultDoc = (record) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: '85%' }}>
        {docPermission ? (
          <>
            {' '}
            {record.item.mroFileName == false ? (
              ''
            ) : (
              <>
                <FilePdfTwoTone
                  onClick={(e) => download(e, record.item.mroFileName)}
                  style={{ fontSize: '15px', width: '100px', cursor: 'pointer' }}
                  twoToneColor='#FF0000'
                />
              </>
            )}
            {record.item.tenpanelreport == false ? (
              ''
            ) : (
              <>
                {/* <button onClick={(e) => download(e, record.item.tenpanelreport)}>Result </button> */}
                <FilePdfTwoTone
                  onClick={(e) => download(e, record.item.tenpanelreport)}
                  style={{ fontSize: '15px', width: '100px', cursor: 'pointer' }}
                  twoToneColor='#FF0000'
                />
              </>
            )}
            {record.item.twelvepanelreport == false ? (
              ''
            ) : (
              <>
                {/* <button onClick={(e) => download(e, record.item.twelvepanelreport)}>Result</button> */}
                <FilePdfTwoTone
                  onClick={(e) => download(e, record.item.twelvepanelreport)}
                  style={{ fontSize: '15px', width: '100px', cursor: 'pointer' }}
                  twoToneColor='#FF0000'
                />
              </>
            )}
            {record.item.breathalcohol == false ? (
              ''
            ) : (
              <>
                {/* <button onClick={(e) => download(e, record.item.breathalcohol)}>Result</button> */}
                <FilePdfTwoTone
                  onClick={(e) => download(e, record.item.breathalcohol)}
                  style={{ fontSize: '15px', width: '100px', cursor: 'pointer' }}
                  twoToneColor='#FF0000'
                />
              </>
            )}
          </>
        ) : (
          (record.item.mroFileName ||
            record.item.attachmentUrls ||
            record.item.breathalcohol ||
            record.item.twelvepanelreport ||
            record.item.tenpanelreport ||
            record.item.ccfFileName ||
            record.item.mroFileName) && (
            <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
          )
        )}
      </div>
    );
  };

  const showSSN = (item) => {
    return item.item.newSsn ? item.item.newSsn : '';
  };
  const showEIN = (item) => {
    return <>{item.item.emp_ein != false ? item.item.emp_ein : ''}</>;
  };
  const showDL = (item) => {
    return <>{item.item.DL != false ? item.item.DL : ''}</>;
  };

  const renderCountCard = (data, cardType) => {
    return (
      <>
        <div
          key={data.reasons}
          className={`col-12 col-md-6 col-xl-2 ${
            selectedCardFilter === (data.reasons || data.final_result) ? 'selected' : ''
          }`}
          style={{ cursor: 'pointer' }}
          onClick={() => handleCardClick(data.reasons || data.final_result, cardType)}
        >
          {data.count !== 0 ? (
            <div>
              <Card
                style={{ background: 'rgba(251, 251, 251)', height: 125 }}
                className='animated with-shadow'
              >
                <div className='row'>
                  <div className='col-12' style={{ textAlign: 'center' }}>
                    <div
                      className='count'
                      style={{
                        fontSize: 30,
                        color: '#EF6827',
                        lineHeight: 1.4,
                        fontWeight: 'bold'
                      }}
                    >
                      {data.count}
                    </div>
                    <h6 className='mt-0 mb-01' style={{ color: '#707070' }}>
                      {data && data.reasons && data.reasons.toUpperCase()}
                      {data && data.final_result && data.final_result.toUpperCase()}
                    </h6>
                  </div>
                </div>
              </Card>
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    );
  };

  const renderDashboardCards = () => {
    let mergeStatus = reasonsCount.concat(resultCount);
    let cards = mergeStatus.map((data) => {
      const CardType = data.reasons ? 'reasons' : 'results';
      return renderCountCard(data, CardType);
    });
    return cards;
  };
  const handleCardClick = (filterValue, cardType) => {
    setSelectedCardFilter(filterValue);
    if (cardType === 'reasons') {
      setSelectedCardReasonFilter(filterValue);
      setSelectedCardResultFilter('');
      getData(1, false, false, true, false, filterValue, '');
    } else if (cardType === 'results') {
      setSelectedCardResultFilter(filterValue);
      setSelectedCardReasonFilter('');
      getData(1, false, false, true, false, '', filterValue);
    }
  };
  //  function filterChangedHandler() {
  //     gridRef.current.invalidate();
  //   }
  //   const formatDateTime = (value) => {
  //     return moment(value).format('MM/DD/YYYY hh:mm');
  //   };
  const flexGridRef = React.useRef(null);
  const showEmployeeName = (item) => {
    return item.item.fullName ? item.item.fullName : '';
  };
  const showLocationName = (item) => {
    return item.item.newLocation ? item.item.newLocation : '';
  };
  const showTestDate = (item) => {
    return <p>{item.item.newCollected ? moment(item.item.newCollected).format('MM/DD/YYYY'):''}</p>;
  };
  const showJobTitle = (item) => {
    return item.item.jobTitle ? item.item.jobTitle : '';
  };

  const onFilterApplied = () => {
    const filter = flexGridRef.current.collectionView.filterDefinition;
    filter.clear(); // clear any existing filters
    filter.conditionFilter('emp_ein', '==', true); // filter for isActive == true
  };
  // const handleFormatDate = (value) => {
  //   return moment(value).format('MM/DD/YYYY');
  // };
  // const formatMonth = (value) => moment(value).format('MMM YYYY');
  return hasPermission ? (
    <div style={{ padding: 20 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <div style={{ display: 'flex' }}>
          <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Drug & Alcohol List</h3>
        </div>

        {/* <Row style={{ alignItems: 'center', textAlign: 'center' }}> */}
        {/* <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 9 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <h3 style={{ textAlign: 'left', color: '#1C3A6A' }}>Drug & Alcohol List</h3>
        </Col> */}
        <>
          <div>
            {!loadMoreHide ? (
              <Button
                style={{
                  backgroundColor: '#247',
                  color: 'white',
                  border: 'none',
                  marginRight: '11px'
                }}
                size='small'
                onClick={() => {
                  setLoadMore(LoadMoreVal + 1);

                  getData(LoadMoreVal + 1, false, false);
                }}
              >
                {isLoadMoreLoading ? (
                  <Space size='middle'>
                    <Spin size='small' />
                  </Space>
                ) : (
                  ' Load More'
                )}
              </Button>
            ) : (
              ''
            )}

            <Button
              size='small'
              style={{
                marginRight: '11px',
                backgroundColor: '#247',
                border: 'none'
              }}
              onClick={showAdvanceFilterModal}
            >
              Advanced Search Filter
            </Button>
            <Modal
              width={1000}
              title='Advanced Search Filter'
              visible={isAdvanceFilterModalOpen}
              onCancel={handleAdvanceFilterCancel}
              footer={[
                <Button
                  size='small'
                  style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                  onClick={() => handleAdvanceFilter()}
                >
                  Search
                </Button>
              ]}
            >
              <Row gutter={[24, 24]}>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={fullNameValue}
                    placeholder='Full Name :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setFullNameValue(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={locValue}
                    placeholder='Location :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setLocValue(event.target.value)}
                  />
                </Col>

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={reasonValue}
                    placeholder='Reason :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setReasonValue(event.target.value)}
                  />
                </Col>

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={statusValue}
                    placeholder='Result :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setStatusValue(event.target.value)}
                  />
                </Col>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    if (value === 'SSN') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                          <Input
                            value={ssnValue}
                            placeholder='SSN :'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '80%', backgroundColor: 'white' }}
                            onChange={(event) => setSsnValue(event.target.value)}
                          />
                        </Col>
                      );
                    } else if (value === 'Driver License') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
                          <Input
                            value={dlValue}
                            placeholder='Driver License:'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '80%', backgroundColor: 'white' }}
                            onChange={(event) => setDLValue(event.target.value)}
                          />
                        </Col>
                      );
                    } else if (value === 'EIN') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                          <Input
                            value={einValue}
                            placeholder='EIN :'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '80%', backgroundColor: 'white' }}
                            onChange={(event) => setEinValue(event.target.value)}
                          />
                        </Col>
                      );
                    } else {
                    }
                  })}

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={ccfValue}
                    placeholder='CCF Number:'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setCcfValue(event.target.value)}
                  />
                </Col>

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <RangePicker
                    //value={collectionDate}
                    placeholder={['Start Test Date', 'End Test Date']}
                    allowClear={true}
                    style={{ borderRadius: 20, width: '80%', height: 40 }}
                    onChange={(value, dateString) => {
                      advanceDateFilter('collectiondate', dateString);
                    }}
                  />
                </Col>
              </Row>
            </Modal>
            <Button
              size='small'
              style={{
                marginTop: '10px',
                backgroundColor: '#247',
                border: 'none',
                marginRight: '11px'
              }}
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
          </div>
        </>
      </div>

      <div className='row'>{renderDashboardCards()}</div>

      {(isLoadMoreLoading || isLoading) && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      {!noRecordFound ? (
        ClinicList.length > 0 ? (
          <div>
            <h6>
              {console.log('count =======', count)}
              Total records :{' '}
              <span style={{ marginLeft: '10px' }}>{!count ? <Spin size='small' /> : count}</span>
            </h6>
            <div className='row'>
              <div className='col-12 col-md-12 col-xl-12'>
                <Row gutter={[32, 32]}>
                  <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                    {/* <FlexGridSearch
                      ref={theSearch}
                      placeholder='Search'
                      cssMatch=''
                      style={{ width: '70%', height: '40px' }}
                                          /> */}
                    <Search
                      placeholder='Search By Employee Name'
                      enterButton
                      // loading={isLoadMoreLoading === true ? '' : isLoading}
                      value={newName}
                      onSearch={onSearch}
                      onChange={handleChange}
                      size='small'
                      // style={{ width: 304 }}
                    />
                  </Col>

                  <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                    <div ref={fixedContainerRef}>
                      <Select
                        style={{ width: 170, marginBottom: 10 }}
                        placeholder={`Items per page : ${pageSize}`}
                        onChange={paggination}
                        options={[
                          { label: 'Items per page : 10', value: 10 },
                          { label: 'Items per page : 20', value: 20 },
                          { label: 'Items per page : 50', value: 50 }
                        ]}
                        getPopupContainer={() => fixedContainerRef.current}
                      />
                    </div>
                  </Col>

                  <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                    <Button
                      size='small'
                      style={{ backgroundColor: '#3d586a', border: 'none', marginLeft: '302px' }}
                      onClick={() => handleReset()}
                    >
                      Reset Filter
                    </Button>
                    <Button
                      type='primary'
                      style={{ backgroundColor: '#3d586a', float: 'right' }}
                      disabled={isExcelPreparing}
                      onClick={exportToExcel}
                      size='small'
                    >
                      {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                    </Button>
                  </Col>
                </Row>

                {/* to export entire data of table */}
                <FlexGrid
                  ref={theGrid}
                  style={{ display: 'none' }}
                  className='dummyWijmoData'
                  itemsSource={viewData}
                  initialized={gridInitializedData}
                  formatItem={formatItem}
                  isReadOnly={true}
                >
                  <FlexGridColumn
                    binding='fullName'
                    header='Employee Name'
                    minWidth={180}
                    width='*'
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showEmployeeName} />
                  </FlexGridColumn>
                  {typecheckValue &&
                    typecheckValue.length > 0 &&
                    typecheckValue.map((value) => {
                      return (
                        <FlexGridColumn
                          binding={
                            value === 'SSN'
                              ? 'newSsn'
                              : value === 'EIN'
                              ? 'newEin'
                              : value === 'Driver License'
                              ? 'DL'
                              : ''
                          }
                          header={
                            value === 'SSN'
                              ? 'SSN'
                              : value === 'EIN'
                              ? 'EIN'
                              : value === 'Driver License'
                              ? 'Driver License'
                              : ''
                          }
                          // width='12*'

                          minWidth={160}
                          width='*'
                        >
                          <FlexGridCellTemplate
                            cellType='Cell'
                            template={
                              value === 'SSN'
                                ? showSSN
                                : value === 'EIN'
                                ? showEIN
                                : value === 'Driver License'
                                ? showDL
                                : ''
                            }
                          />
                        </FlexGridColumn>
                      );
                    })}

                  <FlexGridColumn
                    binding='newLocation'
                    header='Location'
                    minWidth={150}
                    width='*'
                  />
                  <FlexGridColumn
                    binding='newCollectedforExcel'
                    header='Test Date'
                    minWidth={200}
                    width='*'
                  />

                  <FlexGridColumn binding='reasons' header='Reason' minWidth={200} width='*' />

                  {/* <FlexGridColumn
                    binding='jobTitle'
                    header='Job Title'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}
                  {/* <FlexGridColumn
                    binding='departments'
                    header='Job Position'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}

                  <FlexGridColumn
                    binding='program'
                    header='DOT Mode'
                    // width='10*'
                    minWidth={160}
                    width='*'
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn>

                  {/* <FlexGridColumn
                    binding='agenda'
                    header='Agenda'
                    // width='10*'
                    minWidth={100}
                    width='*'
                  /> */}

                  <FlexGridColumn binding='final_result' header='Result'>
                    <FlexGridCellTemplate cellType='Cell' template={showResult} />
                  </FlexGridColumn>

                  <FlexGridColumn
                    binding='newCcf'
                    header='CCF Number'
                    // width='10*'
                    minWidth={200}
                    width='*'
                  />
                  <FlexGridColumn
                    binding='ccfFileName'
                    header='CCF Doc'
                    // width='12*'
                    minWidth={150}
                    width='*'
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showCcfDoc} />
                  </FlexGridColumn>

                  {/* <FlexGridColumn
                    binding='reportingDate'
                    header='Reporting Date'
                    minWidth={160}
                    width='*'
                  /> */}

                  {/* <FlexGridColumn binding='comments' header='Comments' minWidth={100} width='*' /> */}
                </FlexGrid>

                <FlexGrid
                  ref={theGrid}
                  //ref={gridRef}
                  id='theGridTallRows'
                  className='cliniclisttable'
                  alternatingRowStep={1}
                  // autoRowHeights={true}
                  itemsSource={view}
                  initialized={gridInitialized}
                  formatItem={formatItem}
                  itemsPerPage={10}
                  isReadOnly={true}
                  scrollMode='Virtual' // Add this line for smooth scrolling
                  onFilterApplied={onFilterApplied}
                  //autoGenerateColumns={false}
                >
                  <div style={{ display: 'none' }}>
                    <wjInput.ListBox
                      className='column-picker'
                      itemsSource={listboxColumns}
                      checkedMemberPath='visible'
                      displayMemberPath='header'
                      lostFocus={hidePicker}
                      initialized={initializedListBox}
                    ></wjInput.ListBox>
                  </div>
                  <FlexGridColumn
                    binding='fullName'
                    header='Employee Name'
                    // minWidth={180}
                    // width='*'
                    width={200}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showEmployeeName} />
                  </FlexGridColumn>
                  {typecheckValue &&
                    typecheckValue.length > 0 &&
                    typecheckValue.map((value) => {
                      return (
                        <FlexGridColumn
                          binding={
                            value === 'SSN'
                              ? 'newSsn'
                              : value === 'EIN'
                              ? 'newEin'
                              : value === 'Driver License'
                              ? 'DL'
                              : ''
                          }
                          header={
                            value === 'SSN'
                              ? 'SSN'
                              : value === 'EIN'
                              ? 'EIN'
                              : value === 'Driver License'
                              ? 'Driver License'
                              : ''
                          }
                          // width='12*'

                          // minWidth={160}
                          // width='*'
                          width={120}
                        >
                          <FlexGridCellTemplate
                            cellType='Cell'
                            template={
                              value === 'SSN'
                                ? showSSN
                                : value === 'EIN'
                                ? showEIN
                                : value === 'Driver License'
                                ? showDL
                                : ''
                            }
                          />
                        </FlexGridColumn>
                      );
                    })}

                  <FlexGridColumn binding='newLocation' header='Location' width={200}>
                    <FlexGridCellTemplate cellType='Cell' template={showLocationName} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='test_name' header='Test Name' width={220} />
                  <FlexGridColumn binding='newCollected' header='Test Date' width={100}>
                    <FlexGridCellTemplate cellType='Cell' template={showTestDate} />
                  </FlexGridColumn>

                  <FlexGridColumn binding='reasons' header='Reason' width={220} />

                  {/* <FlexGridColumn
                    binding='jobTitle'
                    header='Job Title'
                    // width='10*'
                    minWidth={180}
                    width='*'
                  >
                  <FlexGridCellTemplate cellType='Cell' template={showJobTitle} />

                  </FlexGridColumn> */}
                  {/* <FlexGridColumn
                    binding='departments'
                    header='Job Position'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}

                  <FlexGridColumn
                    binding='program'
                    header='DOT Mode'
                    // width='10*'
                    width={110}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn>

                  {/* <FlexGridColumn
                    binding='agenda'
                    header='Agenda'
                    // width='10*'
                    minWidth={100}
                    width='*'
                  /> */}

                  <FlexGridColumn binding='final_result' header='Result' width={135}>
                    <FlexGridCellTemplate cellType='Cell' template={showResult} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='resultDocs'
                    header='Result Docs'
                    // width='10*'
                    width={95}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showResultDoc} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='newCcf'
                    header='CCF Number'
                    // width='10*'
                    // minWidth={200}
                    // width='*'
                    width={120}
                  />
                  <FlexGridColumn
                    binding='ccfFileName'
                    header='CCF Doc'
                    // width='12*'
                    // minWidth={150}
                    // width='*'
                    width={80}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showCcfDoc} />
                  </FlexGridColumn>

                  {/* <FlexGridColumn
                    binding='reportingDate'
                    header='Reporting Date'
                    minWidth={160}
                    width='*'
                  /> */}

                  {/* <FlexGridColumn binding='comments' header='Comments' minWidth={100} width='*' /> */}

                  <FlexGridFilter
                    //showFilterIcons={true}
                    // placeholder='Filter data...'
                    // defaultFilterType='Value'
                    filterColumns={[
                      'fullName',
                      'newLocation',
                      'newCollected',
                      'newEin',
                      //'newDl',
                      'newSsn',
                      'reasons',
                      'agenda',
                      'newCcf',
                      'final_result',
                      'reportingDate',
                      'comments',
                      'jobTitle',
                      'departments',
                      'program'
                    ]}
                  />
                </FlexGrid>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <div
                    style={{
                      backgroundColor: '#425a81',
                      color: '#fff',
                      display: 'inline',
                      borderRadius: '5px',
                      height: '30px'
                    }}
                  >
                    <CollectionViewNavigator
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                      headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                      byPage={true}
                      cv={view}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <p
                    style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                    ref={filterRefCounter}
                    id='filterCounter'
                  >
                    ({Globalize.format(filterRefCounter.current, 'n0')} )
                  </p>
                  &nbsp;
                  <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                    {' '}
                    out of {ClinicList.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
            )}
          </>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <Modal
        width={1000}
        title='Drug & Alcohol Data'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls && imageUrls.length > 0 && (
          <p>
            <embed src={imageUrls} width='800px' height='2100px' />
          </p>
        )}
      </Modal>
      <DarkBackground disappear={isLoaderActive}>
        <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
      </DarkBackground>
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        &nbsp;&nbsp; {errMsg}
      </p>
    </div>
  );
};

export default DrugAndAlcoholList;
