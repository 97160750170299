import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import _ from 'underscore';
import setting from '../../config/setting';
import { history } from '../../redux/store';
import { Button, Input, Select, Form, Spin, Space, Row, Col } from 'antd';

const { Option } = Select;
const FormItem = Form.Item;

const AddBackgroundScreening = () => {
  const [form] = Form.useForm();
  const [locationNewA, setlocationNew] = useState('');
  const [selecteEmp, setSelectedEmp] = useState('');
  //const [comNew, setCom] = useState('');
  //const [dateNew, setDate] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [bool, setBool] = useState(false);
  // const [boolNew, setBoolNew] = useState(false);
  const [loc, setLoc] = useState([]);
  //const [comp, setcomp] = useState([]);
  const [Res, setRes] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [client_id, setClientId] = useState('');
  const [location_id, setLocationId] = useState('');
  const [department_id, setDeptId] = useState('');
  const [packageId, setPackageId] = useState([])
  //const [startDate, setStartDate] = useState([]);
  // const [legalName, setLegalName] = useState('');
  //const [jobTitle, setJobTitle] = useState('');
  // const [ssnValue, setSsnValue] = useState('');
  const [phone_number, setPhoneValue] = useState('');
  //const [emailValue, setEmailValue] = useState('');
  //const [address, setAddress] = useState('');
  //const [typeScreen, setTypeScreen] = useState('');
  //const [specialInst, setSpecialInst] = useState('');
  // const [bgScreening, setBgScreening] = useState('Yes');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  //const [service, setService] = useState([]);
  const [kressPackages, setkressPackages] = useState([]);
  const [Packages, setPackage] = useState([])
  const [email_address, setEmailFill] = useState('')
  const [job_title, setJobTitle] = useState('')
  const [kressDept, setkressDept] = useState([]);
  const [empName, setNewName] = useState('')
  const [dept, setDept] = useState('')
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [isEmailEditable, setIsEmailEditable] = useState(false);
  const [isPhoneEditable, setIsPhoneEditable] = useState(false);

  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    console.log('this.props', data);
    let companyId = localStorage.getItem('customerId');
    setSelectedCompany(companyId);
    getResPArt(companyId);
    getEmployeeList(companyId);
    getKressPackages(companyId);
    getKressDepartments(companyId);
    form.setFieldsValue({
      company: companyId
    });
  }, []);
  const getKressPackages = (company) => {
    axios.post(setting.serverUrl + '/api/getKressPackages', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        compId: company
      }
    }).then(response => {
      console.log("Respone from getKressPackages ", response)

      if (response && response.status == 200) {

        setkressPackages(response.data.rows)
        //setBool(true)
        // setBoolNew(true)

      }
    })


  }
  const getKressDepartments = (company) => {
    axios.post(setting.serverUrl + '/api/getKressDepartments', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        compId: company
      }
    }).then(response => {
      console.log("Respone from getKressDepartments ", response)

      if (response && response.status == 200) {

        setkressDept(response.data.rows)
        //setBool(true)
        // setBoolNew(true)

      }
    })


  }

  function getResPArt(custId) {
    setLoadingLocations(true);
    axios
      .post(setting.serverUrl + '/api/Res', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          id: custId
        }
      })
      .then((response) => {
        console.log('Respone from getResPArt ', response.data);
        setLoadingLocations(false);
        if (response && response.status == 200) {

          setRes(response.data);
          setBool(true);
        }
      });
  }

  function getEmployeeList(custId) {
    // setLoadingLocations(true);
    axios
      .post(setting.serverUrl + '/api/employeeDropListCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          Id: custId
        }
      })
      .then((response) => {
        console.log('Respone from employeeDropListCustomerPortal ', response.data);
        //setLoadingLocations(false);
        if (response && response.status == 200) {
          setEmpList(response.data);

          //setBool(true);
        }
      });
  }


  function locationNew(event) {
    console.log('locationNew', event);
    setlocationNew(event);
    setLocationId(event);
  }
  function selectedPkg(event) {
    console.log('selectedPkg', event);
    setPackage(event);
    setPackageId(event);

  }
  console.log("packageId", packageId)
  function selectedDeptt(event) {
    console.log('selectedDeptt', event);
    setDept(event);
    setDeptId(event);
  }
  // function selectedEmp(event) {
  //   console.log('selectedEmp', event);
  //   setSelectedEmp(event);

  //   let selectedName = empList.find((name) => name.id === event);
  //   setEmailFill(selectedName.email && selectedName.email != false ? selectedName.email : "")
  //   setJobTitle(selectedName.Job_Title != false ? selectedName.Job_Title : "")
  //   setNewName(selectedName ? selectedName.display_name : "")
  //   setFirstName(selectedName ? selectedName.first_name : "");
  //   setLastName(selectedName ? selectedName.last_name : "");
  //   setPhoneValue(selectedName && selectedName.Mobile != false ? selectedName.Mobile : "");
  // }
  function selectedEmp(event) {
    console.log('selectedEmp', event);
    setSelectedEmp(event);

    let selectedName = empList.find((name) => name.id === event);
    setEmailFill(selectedName.email && selectedName.email !== false ? selectedName.email : "");
    setJobTitle(selectedName.Job_Title !== false ? selectedName.Job_Title : "");
    setNewName(selectedName ? selectedName.display_name : "");
    setFirstName(selectedName ? selectedName.first_name : "");
    setLastName(selectedName ? selectedName.last_name : "");
    setPhoneValue(selectedName && selectedName.Mobile !== false ? selectedName.Mobile : "");

    // Enable email and phone input if their values are empty
    setIsEmailEditable(selectedName && (selectedName.email == "" || selectedName.email == false ));
    setIsPhoneEditable(selectedName && (selectedName.Mobile === "" || selectedName.Mobile == false));
  }

  //console.log("emailFill",emailFill)
  function saveBackgrounds() {
    //console.log('saveEmployeeInst else');
    setIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/saveBackgroundApplicant', {
          params: {
            email: localStorage.getItem('myData'),
            user: localStorage.getItem('myData'),
            pass: localStorage.getItem('myPass'),
            Packages: Packages,
            job_title:job_title,
            callback_url: "https://webhook.site/3ad12100-6126-4e6b-bcbb-ddca0c85d5da",
            candidate: {
              first_name: first_name,
              last_name: last_name,
              phone_number: phone_number,
              email_address: email_address ,
          
            },

            preferences: {

              client_id: "4148",
              location_id: Number(location_id),
              department_id :Number(department_id),
            
            }
          }
        },
        {
          headers: {
            // 'Authorization': 'Bearer ' + 'DDC540CF-71DD-4814-AC78-67F73333D69A',
            // 'Content-Type': 'application/json'
            'Content-Type': 'application/json',
            'Authorization': 'Bearer DDC540CF-71DD-4814-AC78-67F73333D69A'
          }
        })
      .then((response) => {
        console.log('Respone from savebackgrounds inst ', response.data);
        setIsLoading(false);
        if (response && response.status == 200) {

          history.push({ pathname: `/vertical/background_screenings` });
          console.log("got ot saveBackgrounds")
          //setRes(response.data);
        }
      });
  }
//   const payload = {
//     Packages :["8243"],
//     job_title : "IT Manager",
//     callback_url : "https://webook.site/3ad12100-6126-4e6b-bcbb-ddca0c85d5da",
//    candidate : {
//       first_name:"Wss",
//       last_name: "AppInvite",
//       phone_number: "(123) 556-6256",
//       email_address: "manojbavikati@gmail.com"
//   },
//   preferences: {
//     client_id: "4148",
//     location_id: "1717",
//     department_id: "4148"
//  }
// }
          
// const headers = {
//                       // 'Authorization': 'Bearer ' + 'DDC540CF-71DD-4814-AC78-67F73333D69A',
//                       // 'Content-Type': 'application/json'
//                       'Content-Type': 'application/json',
//                       'Authorization': 'Bearer DDC540CF-71DD-4814-AC78-67F73333D69A'
//                     }
    
  // const saveBackgrounds = async () => {
  //   axios
  //     .post(
  //       "https://ssl.usbackgroundchecks.com/InfoXstage/api/checks",
  //       {
  //         Packages: [8242, 8243],
  //         job_title: "Operations Manager",
  //         callback_url:
  //           "https://odoomgmtdata.digitalglyde.com/api/kressUpdates",
  //         candidate: {
  //           first_name: "Lakita",
  //           last_name: "Marrero",
  //           phone_number: "(785)991-6256",
  //           email_address: "lakita_marrero@gmail.com"
  //         },
  //         preferences: {
  //           client_id: "4148",
  //           location_id: "231",
  //           department_id: "4148"
  //         }
  //       },
  //       {
  //         headers: {
  //           // 'application/json' is the modern content-type for JSON, but some
  //           // older servers may use 'text/json'.
  //           // See: http://bit.ly/text-json\
  //           Authorization: "Bearer DDC540CF-71DD-4814-AC78-67F73333D69A",
  //           "content-type": "application/json",
  //           // 'Access-Control-Request-Method': 'post'

  //         }
  //       }
  //     )
  //     .then((response) => console.log(response));


  // }
  return (
    <div style={{ padding: 20 }}>
      <div>
        <Button
          size='small'
          style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>

      <h5 style={{ textAlign: 'left', color: '#1C3A6A' }}>Add Background Screening</h5>

      {bool ? (
        <div>
          <Form layout='vertical' onFinish={() => saveBackgrounds()}>
            <div style={{ marginLeft: 25 }}>
              <div className='row'>
                <div className='col-md-6'>
                  <FormItem label='Company' name='company'>
                    <Select
                      defaultValue={localStorage.getItem('displayName')}
                      placeholder='Company Name*'
                    >
                      <Option value={localStorage.getItem('customerId')}>
                        {localStorage.getItem('displayName')}
                      </Option>
                    </Select>
                  </FormItem>
                </div>

                <div className='col-md-6'>
                  <FormItem label='Employee Location'>
                    <Select
                      placeholder='Select Location*'
                      onChange={(event) => locationNew(event)}
                      loading={loadingLocations}
                      showSearch
                      optionFilterProp='children'
                    >
                      {Res.length > 0 &&
                        Res.map((loc, index) => <Option value={loc.id}>{loc.name}</Option>)}
                    </Select>
                  </FormItem>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'   style={{width:"100%", height:"auto"}}>
                  <FormItem label='Package Names'   style={{width:"100%", height:"auto"}}>
                    <Select
                      placeholder='Select Package*'
                      mode="multiple"
                      style={{width:"100%", height:"auto"}}
                      onChange={(event) => selectedPkg(event)}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                                            
                    //loading={loadingLocations}
                    >
                      {kressPackages.length > 0 &&
                        kressPackages.map((key, index) => <Option value={key.package_id}>{key.package_name}</Option>)}
                    </Select>
                  </FormItem>
                </div>
                <div className='col-md-6'>
                  <FormItem label='Department'>
                    <Select
                      placeholder='Select Department*'
                      style={{ borderRadius: 10 }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                     
                      //mode="multiple"
                      onChange={(event) => selectedDeptt(event)}
                    //loading={loadingLocations}
                    >
                      {kressDept.length > 0 &&
                        kressDept.map((key, index) => <Option value={key.depart_id}>{key.depart_name}</Option>)}
                    </Select>
                  </FormItem>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <FormItem label='Employee Names'>
                    <Select
                      placeholder='Select Employee*'
                      onChange={(event) => selectedEmp(event)}
                      showSearch
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      // option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      //   }
                     
                    //loading={loadingEmployees}
                    >
                      {empList.length > 0 &&
                        empList.map((key, index) => <Option value={key.id}>{key.first_name} {key.last_name}</Option>)}

                    </Select>
                  </FormItem>
                </div>
                <div className='col-md-6'>
                  <FormItem label='Employee Email'>
                    <Input
                      style={{ backgroundColor: 'white' }}
                      //placeholder='Enter Email'
                      value={email_address}
                    // onChange={(event) => setTypeScreen(event.target.value)}
                    onChange={(event) => setEmailFill(event.target.value)}
                    disabled={!isEmailEditable} // Disable if email is not editable

                    />
                  </FormItem>
                </div>
              </div>



              <div className='row'>
                <div className='col-md-6'>
                  <FormItem label='Employee Phone'>
                    <Input
                      style={{ backgroundColor: 'white' }}
                      //placeholder='Enter Email'
                      value={phone_number}
                      onChange={(event) => setPhoneValue(event.target.value)}
                      disabled={!isPhoneEditable} // Disable if phone is not editable
  
                    // onChange={(event) => setTypeScreen(event.target.value)}
                    />
                  </FormItem>
                </div>
                <div className='col-md-6'>
                  {/* <FormItem
                    label='Phone'
                    name='Phone'
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <Input
                      style={{ backgroundColor: 'white' }}
                      placeholder='Enter Phone'
                      onChange={(event) => phoneValid(event)}
                    />
                  </FormItem> */}
                </div>
              </div>

              <div className='row' style={{ marginTop: 50 }}>
                <div className='col-md-4 col-sm-4'></div>
                <div className='col-md-5 col-sm-5'>
                  <Button
                    type='primary'
                    style={{ width: '40%', backgroundColor: '#1b3969' }}
                    htmlType={'submit'}
                  >
                    Submit
                  </Button>
                  {isLoading && (
                    <Space size='middle' style={{ marginTop: 10, marginLeft: 10 }}>
                      <Spin size='large' />
                    </Space>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 10 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AddBackgroundScreening;
