import React, { useEffect } from 'react';
import { Form } from 'antd';
import _ from 'underscore';
import ClinicServiceRateTable from './clinicServiceRateTable';
import { useHistory } from 'react-router-dom';
const ClinicServicesTab = (propsData) => {
  const history = useHistory();
  useEffect(() => {
    if (propsData == undefined) {
      history.goBack();
    }
  }, []);
  let propsValues = propsData && propsData.propsData;
  const { clininc_quest_blood, list_market_clinic, clinic_pick_up_arrange, wss_quest_blood } =
    propsValues || {};
  return (
    <>
      <Form layout='vertical' style={{ borderRadius: 20, backgroundColor: '#fff' }}>
        <h5 style={{ color: '#247', paddingLeft: 20 }}>Clinic Services Related Questions</h5>
        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px', paddingLeft:30 }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', fontWeight: 900 }}>
                {' '}
                Does the CLINIC carry Quest blood draw kits ?
              </span>{' '}
              <span style={{ flexBasis: '40%', paddingLeft: 82 }}>
                {' '}
                {clininc_quest_blood ? clininc_quest_blood : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', fontWeight: 900 }}>
                {' '}
                Does the WSS have to supply Quest blood draw kits?
              </span>{' '}
              <span style={{ flexBasis: '42%', paddingLeft: 90 }}>
                {' '}
                {wss_quest_blood ? wss_quest_blood : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{paddingLeft:'30px'}}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>
                {' '}
                Does the CLINIC have Quest pick-up or can arrange for a Quest pick-up ?
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 60, textAlign:'center' }}>
                {' '}
                {clinic_pick_up_arrange ? clinic_pick_up_arrange : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', fontWeight: 900 }}>
                {' '}
                List of markets the CLINIC offers
              </span>{' '}
              <span style={{ flexBasis: '60%', paddingLeft: 60 }}>
                {' '}
                {list_market_clinic ? list_market_clinic : ''}
              </span>
            </p>
          </div>
        </div>
      </Form>
      <h5 style={{ color: '#247', paddingLeft: 20 }}>Clinic Service Rates</h5>
      <ClinicServiceRateTable propsData={propsValues} />
    </>
  );
};

export default ClinicServicesTab;
