import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  Space,
  Row,
  Col,
  //Input,
  notification,
  //Form,
  Card,
  Select,
  DatePicker,
  //message,
  //Table,
  Button
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
//import * as wjcCore from '@grapecity/wijmo';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
// import * as wjFilter from '@grapecity/wijmo.react.grid.filter';
// import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FolderOpenTwoTone, VerticalAlignBottomOutlined } from '@ant-design/icons/lib';
const config = require('../keyFront');
const { RangePicker } = DatePicker;

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'covid_testing.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const CovidTesting = () => {
  const [noRecordFound, setnoRecordFound] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [employeeList, setEmployeeList] = useState([]);
  const [CovidTestData, setCovidTestData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [companyName, setCompanyName] = useState('');
  const [change, setChange] = useState(false);
  const [tableType, setTableType] = useState('all');
  const [CovidTestDataLength, setCovidTestDataLength] = useState(0);
  const [CovidTestDataApproved, setCovidTestDataApproved] = useState(0);
  const [CovidTestDataPending, setCovidTestDataPending] = useState(0);
  const [CovidTestDataNegative, setCovidTestDataNegative] = useState(0);
  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [EmployeeCount, setEmployeeCount] = useState(0);
  const [totalEmployeesWithTestPer, setTotalEmployeesWithTestPer] = useState(0);
  const [positiveRes, setPositiveRes] = useState([]);
  const [negativeRes, setNegativeRes] = useState([]);
  const [testingTable, setTable] = useState([]);
  //const [tableFilterData, setTableFilterData] = useState([]);
  const [totalConductedTest, setTotalConductedTest] = useState([]);
  const [employeeNameRecords, setEmployeeNameRecords] = useState([]);
  const [docPermission, setDocPermission] = useState(false);
  const [view, setView] = useState([]);
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState(false);
  const [isExcelPreparing, setIsExcelPreparing] = useState(false);
  const [excelProgress, setExcelProgress] = useState(0);
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [covidTestResult, setCovidTestResult] = useState([]);
  const [covidTestResultBkup, setCovidTestResultBkup] = useState([]);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();

  useEffect(() => {
    getData();
    getEmployees();
    getDocPermission();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, []);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }

  useEffect(() => {
    createTable();
  }, [CovidTestData, employeeList]);
  const filterTable = (type) => {
    setTableType(type);
  };

  useEffect(() => {
    if (CovidTestData.length > 0) {
      const collectionView1 = new CollectionView(CovidTestData, {
        pageSize: CovidTestData.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(CovidTestData, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      collectionView2.filters.push((item) => {
        const startDate = dateRange && dateRange.length > 0 && moment(dateRange[0]).startOf('day');
        const endDate = dateRange && dateRange.length > 0 && moment(dateRange[1]).endOf('day');
        const itemTestDate = item.test_date != false && moment(item.test_date).format('MM-DD-YYYY');
        return (
          (!startDate || moment(itemTestDate, 'MM-DD-YYYY').isSameOrAfter(startDate)) &&
          (!endDate || moment(itemTestDate, 'MM-DD-YYYY').isSameOrBefore(endDate))
        );
      });
      if (tableType === 'totalTestCond' || tableType === 'empTotalTested') {
        collectionView2.filters.push((item) => {
          return item;
        });
      } else if (tableType === 'posRes') {
        collectionView2.filters.push((item) => {
          return item.test_result === 'Positive';
        });
      } else if (tableType === 'negRes') {
        collectionView2.filters.push((item) => {
          return item.test_result === 'Negative';
        });
      }

      setView(collectionView2);
    }
  }, [CovidTestData, dateRange, pageSize, tableType]);

  /* Filter to set date range */
  const setDateRangeFilter = (dates) => {
    if (dates !== null) {
      setDateRange(dates.map((date) => date.format('MM-DD-YYYY')));
    } else {
      setDateRange([]);
    }
  };

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.CovidTestingDocs
        }
      })
      .then((response) => {
        console.log('response docs', response);
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };
  function getData() {
    setIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/Covid_test_CustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data == 'Blocked') {
          console.log('Respone from getData Covid_test blocked: ', response.data);
        } else {
          if (response && response.data.error) {
            // alert("Some Technical Error Occurred!")
          }
          if (response && response.status == 200) {
            if (response.data.length == 0) {
              setnoRecordFound(true);
            }
            setCompanyName(localStorage.getItem('displayName'));

            var ress = response?.data.map((val) => {
              return {
                ...val,
                newTestDate:
                  val.test_date != false
                    ? val.test_date 
                    : '',
                newTestDateForExcel: val.test_date ? moment(val.test_date).format('MM/DD/YYYY'):'',
                child_ids:
                  val.child_ids.length > 0 &&
                  !val.child_ids[1].includes('|') &&
                  !val.child_ids[1].includes(',')
                    ? val.child_ids[1]
                    : val.child_ids[1]?.split('|')[1] || val.child_ids[1]?.split(',')[1],
                test_type: val.test_type != false ? val.test_type : '',
                test_result: val.test_result != false ? val.test_result : '',
                testing_status: val.testing_status != false ? val.testing_status : '',
                attachment_url: val.attachment_url != false ? val.attachment_url : '',
                attachNewUrl: '',
                jobTitle:
                  val &&
                  val.emp_data &&
                  val.emp_data.length > 0 &&
                  val.emp_data[0].Job_Title != false
                    ? val.emp_data[0].Job_Title
                    : '',
                departments:
                  val &&
                  val.emp_data &&
                  val.emp_data.length > 0 &&
                  val.emp_data[0].department != false
                    ? val.emp_data[0].department
                    : '',
                program:
                  val && val.emp_data && val.emp_data.length > 0 && val.emp_data[0].program != false
                    ? val.emp_data[0].program
                    : '',
                mode:
                  val && val.emp_data && val.emp_data.length > 0 && val.emp_data[0].mode != false
                    ? val.emp_data[0].mode
                    : '',
                newEin:
                  val && val.emp_data && val.emp_data.length > 0 && val.emp_data[0].emp_ein != false
                    ? val.emp_data[0].emp_ein
                    : '',
                newSsn:
                  val &&
                  val.emp_data &&
                  val.emp_data.length > 0 &&
                  val.emp_data[0].emp_ssn != false &&
                  val.emp_data[0].emp_ssn != ''
                    ? val.emp_data[0].emp_ssn?.substr(-4)
                    : '',
                DL:
                  val && val.emp_data && val.emp_data.length > 0 && val.emp_data[0].DL != false
                    ? val.emp_data[0].DL
                    : ''
              };
            });
            //console.log('ress in covid testing===', ress);
            //  setWijmoFlexGrid(ress, 10);
            setCovidTestData(
              ress.sort((a, b) => {
                const dateA = moment(a.test_date_last, 'MM/DD/YYYY');
                const dateB = moment(b.test_date_last, 'MM/DD/YYYY');

                if (dateA > dateB) {
                  return -1;
                } else if (dateB > dateA) {
                  return 1;
                } else {
                  return 0;
                }
              })
            );
            setCovidTestResult(ress);
            setCovidTestResultBkup(ress);
            let empName = {};
            response && response.data.map((data) => (empName[data.employee[1]] = 'here'));
            setEmployeeNameRecords(empName);
            setHasPermission(true);
          } else if (response.status === 201) {
            setnoRecordFound(true);
            setErrMsg('Access permission not allowed.');
            setNoRecordMessage('Access permission not allowed.');
          } else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
          }
        }
      });
  }
  const getEmployees = () => {
    axios
      .post(setting.serverUrl + '/api/employeeCustCustomerPortal', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custIdArr: JSON.parse(localStorage.getItem('customerData')).employee_multi_select,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          // alert("Some Technical Error Occurred!")
        }
        if (response && response.status == 200) {
          console.log('response of covid testing', response.data);
          setEmployeeList(response.data);
        }
      })
      .catch((error) => {
        // notification['error']({
        //   message: 'Error',
        //   description: 'Failed to load employee data'
        // })
      });
  };

  const createTable = () => {
    setTableType('all');
    CovidTestData &&
      CovidTestData.map((empData) => {
        let test_date = moment(empData.test_date).format('MM/DD/YYYY');
        var currentDate = moment().format('MM/DD/YYYY');
        const date1 = new Date(currentDate);
        const date2 = new Date(test_date);
        const diffTime = Math.abs(date1 - date2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let resultStatus = 'Outdated';
        if (diffDays <= 7 && empData.test_result == 'Negative') {
          resultStatus = 'Yes';
        }
        if (diffDays <= 7 && empData.test_result == 'Positive') {
          resultStatus = 'Covid';
        }

        empData.resultStatus = resultStatus;
      });

    let CovidTestDataApproved = CovidTestData.filter(function (e) {
      return e.resultStatus == 'Outdated';
    });

    let CovidTestDataPending = CovidTestData.filter(function (e) {
      return e.test_result == 'Positive';
    });
    let CovidTestDataNegative = CovidTestData.filter(function (e) {
      return e.test_result == 'Negative';
    });

    let dataGroupByEmployee = _.groupBy(CovidTestData, 'employee');
    var noOfEmployee = Object.keys(dataGroupByEmployee).length;
    setEmployeeCount(noOfEmployee);

    let totalEmployees = employeeList.length;
    let empPer = (noOfEmployee / totalEmployees) * 100;

    setTotalEmployeesWithTestPer(empPer);
    setTable(CovidTestData);
    const posres = CovidTestData.filter((emp) => emp.test_result === 'Positive');
    const negres = CovidTestData.filter((emp) => emp.test_result === 'Negative');
    setCovidTestDataNegative(CovidTestDataNegative.length);
    setPositiveRes(posres);
    setNegativeRes(negres);
    setTotalConductedTest(CovidTestData);
    setCovidTestData(CovidTestData);
    setCovidTestDataLength(CovidTestData.length);
    setCovidTestDataApproved(CovidTestDataApproved.length);
    setCovidTestDataPending(CovidTestDataPending.length);
    setChange(!change);
  };
  function download(e, attachment_url) {
    axios
      .post(setting.serverUrl + '/api/downloadTestingCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_url: attachment_url
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          // alert("Some Technical Error Occurred!")
        }
        setChange(!change);
        window.open(response.data);
      });
  }

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showEmpName = (item) => {
    return (
      <span className='nowrap'>
        {item.item.employee == false
          ? ''
          : item.item.employee && item.item.employee.length > 0 && item.item.employee[1]}
      </span>
    );
  };

  const showEmail = (item) => {
    return <span>{item.item.email !== false && item.item.email}</span>;
  };

  const showTestName = (item) => {
    return (
      <span className='nowrap'>
        <span className='nowrap'>{item.item.test_type}</span>
      </span>
    );
  };

  const showTestDate = (item)=>{
    return <p>{item && item.item.newTestDate ? moment(item.item.newTestDate).format('MM/DD/YYYY'):''}</p>
  }

  const showResults = (item) => {
    if (item.item.test_result === 'Positive') {
      return (
        <span style={{ color: 'red', fontSize: 12, fontWeight: 600 }}>{item.item.test_result}</span>
      );
    } else {
      return (
        <span style={{ color: 'green', fontSize: 12, fontWeight: 600 }}>
          {item.item.test_result}
        </span>
      );
    }
  };

  const showStatus = (item) => {
    return <span className='nowrap'>{item.item.testing_status}</span>;
  };

  const showSSN = (item) => {
    if (item.item.newSsn !== false && item.item.newSsn !== null && item.item.newSsn != undefined) {
      return <>{'***-**-' + item.item.newSsn}</>;
    } else {
      return <>{''}</>;
    }
  };
  const showEIN = (item) => {
    return <>{item.item.emp_ein != false ? item.item.emp_ein : ''}</>;
  };
  const showDL = (item) => {
    return <>{item.item.DL != false ? item.item.DL : ''}</>;
  };
  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showDoc = (item) => {
    return item.item.attachment_url || item.item.attachment_id ? (
      <span className='nowrap'>
        {docPermission ? (
          !item.item.attachment_url ? (
            item.item.attachment_id.length > 0 && (
              // <button
              //   classname='doclink'
              //   onClick={(e) => download(e, item.item.attachment_id)}
              //   style={{
              //     border: 'none',
              //     textAlign: 'center',
              //     borderRadius: 5,
              //     backgroundColor: '#abc',
              //     fontweight: 'bold'
              //   }}
              // >
              //   Preview
              // </button>
              // <p style={{ color: 'blue' }}>
              //   Preview
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => download(e, item.item.attachment_id)}
                />
              // </p>
            )
          ) : (
            // <button
            //   classname='doclink'
            //   onClick={(e) => download(e, item.item.attachment_url)}
            //   style={{
            //     border: 'none',
            //     textAlign: 'center',
            //     borderRadius: 5,
            //     backgroundColor: '#abc',
            //     fontweight: 'bold'
            //   }}
            // >
            //   Preview
            // </button>
            // <p style={{ color: 'blue' }}>
            //   Preview
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => download(e, item.item.attachment_url)}
              />
            // </p>
          )
        ) : (
          (item.item.attachment_url || item.item.attachment_id) && (
            <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
          )
        )}
      </span>
    ) : (
      ''
    );
  };

  return hasPermission ? (
    <div style={{ padding: 20, marginTop: 20 }}>
      <div className='row'>
        <div className='col-12 col-md-12 col-xl-12' style={{ marginBottom: 20 }}>
          <h6 className='mt-0 mb-1' style={{ color: '#1C3A6A', fontWeight: 700, fontSize: 18 }}>
            COVID Testing Data
          </h6>
        </div>
      </div>

      <div className='row'>
        {EmployeeCount != 0 && EmployeeCount != undefined && (
          <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              onClick={() => filterTable('empTotalTested')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{ fontSize: 40, color: '#EF6827', lineHeight: 1.4, fontWeight: 'bold' }}
                  >
                    {EmployeeCount}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Total # of Employees Tested
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {CovidTestDataLength != 0 && CovidTestDataLength != undefined && (
          <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              onClick={() => filterTable('totalTestCond')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{ fontSize: 40, color: '#EF6827', lineHeight: 1.4, fontWeight: 'bold' }}
                  >
                    {CovidTestDataLength}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Total Tests Conducted
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {CovidTestDataPending != 0 && CovidTestDataPending != undefined && (
          <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              onClick={() => filterTable('posRes')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{ fontSize: 40, color: '#EF6827', lineHeight: 1.4, fontWeight: 'bold' }}
                  >
                    {CovidTestDataPending}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Positive <br /> Results
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {CovidTestDataNegative != 0 && CovidTestDataNegative != undefined && (
          <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              onClick={() => filterTable('negRes')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{ fontSize: 40, color: '#EF6827', lineHeight: 1.4, fontWeight: 'bold' }}
                  >
                    {CovidTestDataNegative}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Negative <br /> Results
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
      <br />

      <div>
        <Card>
          {!noRecordFound ? (
            CovidTestData.length > 0 ? (
              <div>
                <Row>
                  <Col span={8}>
                    <RangePicker
                      placeholder={['Start Test Date', 'End Test Date']}
                      allowClear={true}
                      style={{ width: '100%', height: 40 }}
                      format='MM-DD-YYYY'
                      onChange={(dates) => {
                        setDateRangeFilter(dates);
                      }}
                    />
                  </Col>
                  <Col span={1}></Col>
                </Row>
                <br />
                <div className='container-fluid' style={{ width: '100%' }}>
                  <div className='row'>
                    <div className='toolbar-item col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5'>
                      <FlexGridSearch
                        ref={theSearch}
                        placeholder='Search'
                        cssMatch=''
                        style={{ width: '70%', height: '40px', marginBottom: '15px' }}
                      />
                    </div>

                    <div
                      className='toolbar-item col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2'
                      style={{ textAlign: 'center' }}
                    >
                      <div ref={fixedContainerRef}>
                        <Select
                          style={{ width: 170 }}
                          placeholder={`Items per page : ${pageSize}`}
                          onChange={paggination}
                          options={[
                            { label: 'Items per page : 10', value: 10 },
                            { label: 'Items per page : 20', value: 20 },
                            { label: 'Items per page : 30', value: 50 }
                          ]}
                          //getPopupContainer={() => fixedContainerRef.current}
                        />
                      </div>
                    </div>

                    <div className='toolbar-item col-6 col-sm-4 col-md-4 col-lg-4 col-xl-5'>
                      <Button
                        type='primary'
                        size='small'
                        style={{ backgroundColor: '#3d586a', float: 'right' }}
                        disabled={isExcelPreparing}
                        onClick={exportToExcel}
                      >
                        {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                      </Button>
                    </div>
                  </div>
                </div>

                {/* to export entire data of table */}
                <FlexGrid
                  ref={theGrid}
                  style={{ display: 'none' }}
                  className='dummyWijmoData'
                  itemsSource={viewData}
                  initialized={gridInitializedData}
                  formatItem={formatItem}
                  isReadOnly={true}
                >
                  <FlexGridColumn
                    binding='employee[1]'
                    header='Employee Name'
                    minWidth={220}
                    width='*'
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showEmpName} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='child_ids' header='Location' minWidth={130} width='*'>
                    {/* <FlexGridCellTemplate cellType='Cell' template={showLocation} /> */}
                  </FlexGridColumn>
                  <FlexGridColumn binding='email' header='Email' minWidth={250} width='*'>
                    <FlexGridCellTemplate cellType='Cell' template={showEmail} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='test_type'
                    header='Test Name'
                    minWidth={150}
                    width='*'
                  />
                    
                  <FlexGridColumn
                    binding='newTestDateForExcel'
                    header='Test Dates'
                    // width='10*'
                    minWidth={150}
                    width='*'
                  >
                    {/* <FlexGridCellTemplate cellType='Cell' template={showTestDate} /> */}
                  </FlexGridColumn>
                  {typecheckValue &&
                    typecheckValue.length > 0 &&
                    typecheckValue.map((value) => {
                      return (
                        <FlexGridColumn
                          binding={
                            value === 'SSN'
                              ? 'newSsn'
                              : value === 'EIN'
                              ? 'newEin'
                              : value === 'Driver License'
                              ? 'DL'
                              : ''
                          }
                          header={
                            value === 'SSN'
                              ? 'SSN'
                              : value === 'EIN'
                              ? 'EIN'
                              : value === 'Driver License'
                              ? 'Driver License'
                              : ''
                          }
                          // width='12*'

                          minWidth={160}
                          width='*'
                        >
                          <FlexGridCellTemplate
                            cellType='Cell'
                            template={
                              value === 'SSN'
                                ? showSSN
                                : value === 'EIN'
                                ? showEIN
                                : value === 'Driver License'
                                ? showDL
                                : ''
                            }
                          />
                        </FlexGridColumn>
                      );
                    })}

                  <FlexGridColumn
                    binding='jobTitle'
                    header='Job Title'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  />
                  {/* <FlexGridColumn
                    binding='departments'
                    header='Job Position'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}

                  <FlexGridColumn
                    binding='program'
                    header='Dot Mode'
                    // width='10*'
                    minWidth={160}
                    width='*'
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='test_result'
                    header='Results'
                    // width='11*'
                    minWidth={100}
                    width='*'
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showResults} />
                  </FlexGridColumn>

                  <FlexGridColumn binding='testing_status' header='Status' width='*' minWidth={80}>
                    <FlexGridCellTemplate cellType='Cell' template={showStatus} />
                  </FlexGridColumn>
                </FlexGrid>

                <FlexGrid
                  ref={theGrid}
                  id='theGridTallRows'
                  className='cliniclisttable'
                  alternatingRowStep={1}
                  itemsSource={view}
                  initialized={gridInitialized}
                  formatItem={formatItem}
                  itemsPerPage={10}
                  isReadOnly={true}
                  scrollMode='Virtual' // Add this line for smooth scrolling
                  // loadedRows={(s) => s.autoSizeColumns()}
                  rowEditEnded={(s) => s.autoSizeColumns()}
                  cellEditEnded={(s, e) => s.autoSizeColumn(e.col)}
                  style={{ minwidth: '100%' }}
                >
                  <div style={{ display: 'none' }}>
                    <wjInput.ListBox
                      className='column-picker'
                      itemsSource={listboxColumns}
                      checkedMemberPath='visible'
                      displayMemberPath='header'
                      lostFocus={hidePicker}
                      initialized={initializedListBox}
                    ></wjInput.ListBox>
                  </div>
                  <FlexGridColumn binding='employee[1]' header='Employee Name' width={190}>
                    <FlexGridCellTemplate cellType='Cell' template={showEmpName} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='child_ids' header='Location' width={265}>
                    {/* <FlexGridCellTemplate cellType='Cell' template={showLocation} /> */}
                  </FlexGridColumn>
                  <FlexGridColumn binding='email' header='Email' width={270}>
                    <FlexGridCellTemplate cellType='Cell' template={showEmail} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='test_type'
                    header='Test Name'
                    // width='12*'
                    // minWidth={180}
                    // width='*'
                    width={260}
                    // wordWrap={true}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showTestName} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='newTestDate' header='Test Dates' width={125}>
                    <FlexGridCellTemplate cellType='Cell' template={showTestDate} />
                  </FlexGridColumn>
                  {typecheckValue &&
                    typecheckValue.length > 0 &&
                    typecheckValue.map((value) => {
                      return (
                        <FlexGridColumn
                          binding={
                            value === 'SSN'
                              ? 'newSsn'
                              : value === 'EIN'
                              ? 'newEin'
                              : value === 'Driver License'
                              ? 'DL'
                              : ''
                          }
                          header={
                            value === 'SSN'
                              ? 'SSN'
                              : value === 'EIN'
                              ? 'EIN'
                              : value === 'Driver License'
                              ? 'Driver License'
                              : ''
                          }
                          // width='12*'

                          // minWidth={150}
                          // width='*'
                          width={120}
                        >
                          <FlexGridCellTemplate
                            cellType='Cell'
                            template={
                              value === 'SSN'
                                ? showSSN
                                : value === 'EIN'
                                ? showEIN
                                : value === 'Driver License'
                                ? showDL
                                : ''
                            }
                          />
                        </FlexGridColumn>
                      );
                    })}

                  <FlexGridColumn
                    binding='jobTitle'
                    header='Job Title'
                    // width='10*'
                    // minWidth={200}
                    // width='*'
                    width={210}
                    // wordWrap={true}
                  />
                  {/* <FlexGridColumn
                    binding='departments'
                    header='Job Position'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}

                  <FlexGridColumn
                    binding='program'
                    header='Dot Mode'
                    // width='10*'
                    // minWidth={160}
                    // width='*'
                    width={100}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='test_result'
                    header='Results'
                    // width='11*'
                    // minWidth={100}
                    // width='*'
                    width={100}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showResults} />
                  </FlexGridColumn>

                  <FlexGridColumn binding='testing_status' header='Status' width={100}>
                    <FlexGridCellTemplate cellType='Cell' template={showStatus} />
                  </FlexGridColumn>
                  {hasPermission ? (
                    <FlexGridColumn
                      binding='attachment_url'
                      header='Documents'
                      // width='10*'
                      // minWidth={120}
                      // width='*'
                      width={100}
                    >
                      <FlexGridCellTemplate cellType='Cell' template={showDoc} />
                    </FlexGridColumn>
                  ) : (
                    <FlexGridColumn
                      binding='attach'
                      header='Documents'
                      // width='10*'
                      // minWidth={120}
                      // width='*'
                      width={120}
                    />
                  )}
                  <FlexGridFilter
                    filterColumns={[
                      'employee[1]',
                      'child_ids',
                      'email',
                      'test_type',
                      'newTestDate',
                      'test_result',
                      'testing_status',
                      'jobTitle',
                      'departments',
                      'program',
                      'newEin',
                      'DL',
                      'newSsn'
                    ]}
                  />
                </FlexGrid>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <div
                    style={{
                      backgroundColor: '#425a81',
                      color: '#fff',
                      display: 'inline',
                      borderRadius: '5px',
                      height: '30px'
                    }}
                  >
                    <CollectionViewNavigator
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                      headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                      byPage={true}
                      cv={view}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <p
                    style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                    ref={filterRefCounter}
                    id='filterCounter'
                  >
                    ({Globalize.format(filterRefCounter.current, 'n0')} )
                  </p>
                  &nbsp;
                  <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                    {' '}
                    out of {covidTestResult.length}
                  </p>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <div
                    onClick={(e) => updateViewPager(e.target)}
                    style={{
                      backgroundColor: '#425a81',
                      color: '#fff',
                      display: 'inline',
                      borderRadius: '5px',
                      // paddingTop: '4px',
                      height: '30px'
                    }}
                  >
                    <button
                      id='btnFirst'
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                      className='btn'
                    >
                      <span className='wj-glyph-step-backward'></span>
                    </button>
                    <button
                      id='btnPrev'
                      className='btn'
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    >
                      <span className='wj-glyph-left'></span>
                    </button>
                    <p style={{ display: 'inline' }}>
                      &nbsp;&nbsp;&nbsp;Page {viewIndex} of {viewCount}&nbsp;&nbsp;&nbsp;
                    </p>
                    <button
                      id='btnNext'
                      className='btn'
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    >
                      <span className='wj-glyph-right'></span>
                    </button>
                    <button
                      id='btnLast'
                      className='btn'
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    >
                      <span className='wj-glyph-step-forward'></span>
                    </button>
                  </div>
                  {pagginationNumRecord.currentRecord !== undefined ? (
                    <p style={{ fontWeight: 'bold' }}>
                      {' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`${pagginationNumRecord.currentRecord} out of ${pagginationNumRecord.totalRecord}`}
                    </p>
                  ) : (
                    ''
                  )} */}
                </div>
              </div>
            ) : (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50 }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            )
          ) : (
            <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
          )}
        </Card>
      </div>
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className="icofont-exclamation-tringle" style={{color:'red',fontSize:20}}></i>&nbsp;&nbsp; */}
        {errMsg}
      </p>
    </div>
  );
};

export default CovidTesting;
