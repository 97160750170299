import React, { useEffect, useState } from 'react';
import { Badge, Button, Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import axios from "axios";
import _ from 'underscore';
import moment from 'moment';
import { INotification } from '../../../interfaces/notification';
import { history } from '../../../redux/store';
import setting from '../../../config/setting'

const defaultNotifications = [
  {
    text: 'Sara Crouch liked your photo',
    icon: 'icofont-heart',
    time: '17 minutes ago'
  },
  {
    text: 'New user registered',
    icon: 'icofont-users-alt-6',
    time: '23 minutes ago'
  },
  {
    text: 'Amanda Lie shared your post',
    icon: 'icofont-share',
    time: '25 minutes ago'
  },
  {
    text: 'New user registered',
    icon: 'icofont-users-alt-6',
    time: '32 minutes ago'
  },
  {
    text: 'You have a new message',
    icon: 'icofont-ui-message',
    time: '58 minutes ago'
  }
];

type Props = {
  data?: INotification[];
};

const homeRoute = 'vertical/default-dashboard';
const notRoute = 'vertical/notifications';

const Notifications = ({ data = defaultNotifications }: Props) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [visible, setVisible] = useState(false);
  const [not, setNot] = useState([]);
  const [notLength, setNotLength] = useState(0);

  useEffect(() => {
    setNotifications(data);
    getData();

  }, [data]);

  

  function getData() {

    console.log("getData :: ", localStorage.getItem('myData'), localStorage.getItem('myPass'))
    //console.log("setting.serverUrl",setting.serverUrl)
    let custIdArr = [];
    axios.post(setting.serverUrl + '/api/CustomerData', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass')
      }
    }).then(async response => {
      console.log("Respone from getData ", response.data)
      if(response && response.data.error){
        alert("Some Technical Error Occurred!")
      }
     
      if (response && response.status == 200) {
       
        await _.each(response.data, (res) => {
          if(res.is_company){

          custIdArr.push(res.id);
          }

        })

       console.log("custIdArr 1",custIdArr);
       await 
       //setTimeout(() => {
         notificationsUpdated(custIdArr)
       //}, 2000)

      }
    })

  }


  function notificationsUpdated(custIdArr) {
    let arrN = [];
    console.log("custIdARR 2",custIdArr);
    console.log("getData :: ", localStorage.getItem('myData'), localStorage.getItem('myPass'))
    //let custIdArr = [];
    axios.post(setting.serverUrl + '/api/notifications', {
      params:
      {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        custId:custIdArr[0],
      }
    }).then(response => {
      console.log("Respone from notifications ", response.data)
      if(response && response.data.error){
        alert("Some Technical Error Occurred!")
      }
      
      if (response && response.status == 200) {

        // _.each(custIdArr, (id) => {

        //   _.each(response.data, (dat) => {

        //     if(id == dat.notification_company[0] 
        //       && dat.show_notification == true
        //       ){

        //       arrN.push(dat);

        //     }

        //   })

        // })

        console.log("arrN",response.data);

        setNot(response.data)
        setNotLength(response.data.length)
        // localStorage.setItem('notification_count',response.data.length);

      }
    })

  }

  console.log("not NEw", not);

  function notificationCall() {

    history.push({ pathname: `/vertical/notifications`, state: {} });


  }

  const handleClearAll = () => setNot([]);

  const notificationsMenu = (
    <Menu className='action-menu' style={{ minWidth: '300px' }}>
      <span className='dropdown-header'>
        <h3 className='dropdown-title'>Notifications</h3>

        {/* <a onClick={handleClearAll} className='text-danger'>
          Clear all
        </a> */}
      </span>

      {not.length &&
        not.map((item, index) => (
          <Menu.Item
            className='action-item'
            key={index}>
            <NavLink className='d-flex w-100'
              to={homeRoute}  
            >
              {/* <span className={`icon mr-3 ${item.icon}`} /> */}
              <span
                className='icofont-notification'
                style={{ fontSize: 23, color: 'rgba(51, 108, 251, 0.5)' }}
              />
              <span className='text' style={{ fontSize: 14, marginLeft: 20 }}>
                <span className='message'>{item.company[1]}</span>
                {/* <p></p> */}
                
                {/* <span className='sub-text'>Service : {item.notification_type ? item.notification_type:""}</span> */}
                
                
                <span className='sub-text'>

                Type : {item.notification_type ? item.notification_type:""}
                          
                </span>
                <span className='sub-text'>Package : {item.service_multi_select[1] ? item.service_multi_select[1]:""}</span>

                <span className='sub-text'>
                  {
                    moment(new Date()).diff(moment(item.write_date), 'minutes') < 60 ?
                      moment(new Date()).diff(moment(item.write_date), 'minutes') + ' minutes ago' :
                      moment(new Date()).diff(moment(item.write_date), 'minutes') > 60 && moment(new Date()).diff(moment(item.write_date), 'minutes') < 1440 ?
                        moment(new Date()).diff(moment(item.write_date), 'hours') + ' hour ago' :
                        moment(new Date()).diff(moment(item.write_date), 'minutes') > 1440 ?
                          moment(new Date()).diff(moment(item.write_date), 'days') + ' days ago' :
                          null

                  }
                </span>
              </span>
            </NavLink>
          </Menu.Item>
        ))}

      {not.length == 0 && (
        <span className='empty-item'>No notifications</span>
      )}

      {not.length == 0 ? null :  
        <div className='dropdown-actions'>
          <Button type='primary' className='w-100' onClick={notificationCall}>
            View all notifications
            
            <span
              style={{ fontSize: '1.2rem' }}
              className='icofont-calendar ml-3'
            />
          </Button>
        </div>
      }
    </Menu>
  );
  return (
    
    <Dropdown
      className='mr-3'
      overlay={notificationsMenu}
      trigger={['click']}
      visible={visible}
      onVisibleChange={setVisible}
      placement='bottomRight'
    >
      <Badge className='action-badge' count={not.length}>
        <span
          className={`notification-icon icofont-notification ${visible ? 'active' : null
            }`}
          style={{ fontSize: '22px', cursor: 'pointer',color:"#fff",opacity:1 }}
        />
      </Badge>
    </Dropdown>
   
  );
};

export default Notifications;
