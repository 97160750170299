import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import settings from '../../config/setting';
import {
  Spin,
  Space,
  Row,
  Col,
  Button,
  notification,
  Select,
  Input,
  Modal,
  DatePicker
} from 'antd';
import moment from 'moment';
import { history } from '../../redux/store';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import Search from 'antd/lib/input/Search';
import { InfoCircleTwoTone } from '@ant-design/icons';
import LeftBone from './../../assets/img/leftBone.png';
import LeftBoneUp from './../../assets/img/leftBoneUp.png';
import LeftMaskedBoneUp from './../../assets/img/leftMaskedBoneUp.png';
import LeftMaskedAirDown from './../../assets/img/leftzMaskedAirDown.png';
import LeftMaskedAirUp from './../../assets/img/leftMaskedAirUp.png';
import RightAir from './../../assets/img/rightAir.png';
import RightMaskedAirDown from './../../assets/img/rightMaskedAirDown.png';
import RightMaskedBoneUp from './../../assets/img/rightMaskedBoneUp.png';
import LeftAirDown from './../../assets/img/leftAirDown.png';
import LeftAirUp from './../../assets/img/leftAirUp.png';
import LeftCurrent from './../../assets/img/leftCurrent.png';
import RightAirDown from './../../assets/img/rightAirDown.png';
import RightBoneDown from './../../assets/img/rightBoneDown.png';
import RightBoneUp from './../../assets/img/rightBoneUp.png';
import RightCurrent from './../../assets/img/rightCurrent.png';
import RightMaskedAirUp from './../../assets/img/rightMaskedAirUp.png';
import RightMaskedBoneDown from './../../assets/img/rightMaskedBoneDown.png';
import LeftMaskedBoneDown from './../../assets/img/leftMaskedBoneDown.png';
import BaselineEstab from './../../assets/img/baselineEstab.png';
import { FilePdfTwoTone, FolderOpenTwoTone } from '@ant-design/icons/lib';

import { DownloadOutlined } from '@ant-design/icons';
let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'audiogram.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const AudiogramList = () => {
  const pageLimit = 25;
  const [noGfound, setNoGFound] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [audiogramData, setAudiogramData] = useState([]);
  const [leftInfoRecord, setLeftInfoRecord] = useState([]);
  const [nofound, setNoFound] = useState(true);
  const [rightNofound, setRightNoFound] = useState(false);
  const [showAdvanceButton, setShowAdvanceButton] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [view, setView] = useState();
  const [rightView, setRightView] = useState([]);
  const [infoVisible, setInfoVisible] = useState(false);
  const [viewData, setViewData] = useState();
  const [audiogramRightData, setAudiogramRightData] = useState([]);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [listboxColumns, setListboxColumns] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalaudioRecords, setTotalAudioRecords] = useState(0);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [loadMore, setLoadMore] = useState(1);
  const [graphData, setGraphData] = useState([]);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [total, setTotal] = useState();
  const [loadmorehide, setLoadmorehide] = useState(false);
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);
  const [fullNameValue, setFullNameValue] = useState('');
  const [locValue, setLocValue] = useState('');
  const [dobValue, setDobValue] = useState('');
  const [stsDate, setStsDate] = useState('');
  const [oshaDate, setOshaDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lastTestValue, setLastTestValue] = useState('');
  const { RangePicker } = DatePicker;
  const theGrid = useRef();
  const theSearch = useRef();
  const filterRefCounter = useRef();
  const fixedContainerRef = useRef(null);
  const gridRef = useRef(null);
  const [newName, setNewName] = useState('');

  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          //console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          //console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      //console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        //console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    //console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };
  const getAudiogramData = (currentPageNumber = 1, forceReset = false, advanceFilter = false) => {
    setIsLoading(true);
    if (!forceReset && !advanceFilter) {
      setIsLoadMoreLoading(true);
    }
    i = 1;

    axios
      .post(settings.serverUrl + '/api/audiogramListCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          loadMore: currentPageNumber,
          fullNameValue: forceReset ? '' : fullNameValue || newName,
          locValue: forceReset ? '' : locValue,
          dobValue: forceReset ? '' : dobValue,
          lastTestValue: forceReset ? '' : lastTestValue,
          oshaDate: forceReset ? '' : oshaDate,
          stsDate: forceReset ? '' : stsDate,
          limit: pageLimit
        }
      })
      .then((response) => {
        console.log(response, 'response');
        setIsLoading(false);
        //setIsLoadMoreLoading(false);
        if (
          response &&
          response.status == 200 &&
          response.data &&
          response.data.rows &&
          response.data.rows.length > 0
        ) {
          console.log('response from api audiogram list====', response.data);
          response.data.rows.sort((a, b) => {
            const dateA = new Date(a.dob);
            const dateB = new Date(b.dob);
            return dateB - dateA;
          });
          response.data.rows.sort((a, b) => {
            const dateA = new Date(a.testing_date);
            const dateB = new Date(b.testing_date);
            return dateB - dateA;
          });
          response.data.rows.sort((a, b) => {
            const dateA = new Date(a.completed_on);
            const dateB = new Date(b.completed_on);
            return dateB - dateA;
          });
          let finalResult = response?.data?.rows.map((k) => {
            return {
              ...k,
              fullName: k.first_name + ' ' + k.last_name,
              name: k.name ? k.name : '',
              dob: k.dob != false ? k.dob : '',
              newDobForExcel: k.dob ? moment(k.dob).format('MM/DD/YYYY') : '',
              com_loc_name:
                k.com_loc_name && !k.com_loc_name.includes('|') && !k.com_loc_name.includes(',')
                  ? k.com_loc_name
                  : k.com_loc_name?.split('|')[1] || k.com_loc_name?.split(',')[1],

              test_date_last: k.testing_date != null ? k.testing_date : '',
              newTest_Date_LastForExcel: k.testing_date
                ? moment(k.testing_date).format('MM/DD/YYYY')
                : '',
              osha_Date: k.completed_on != null ? k.completed_on : '',
              newOsha_DateForExcel: k.completed_on
                ? moment(k.completed_on).format('MM/DD/YYYY')
                : '',
              // sts_Date: k.completed_on != null ? k.completed_on : '',
              stsDate: k.stsDate != null ? moment(k.stsDate).format('MM/DD/YYYY') : '',
              oshaDate: k.oshaDate != null ? moment(k.oshaDate).format('MM/DD/YYYY') : '',
              newStsDateForExcel: k.completed_on ? moment(k.completed_on).format('MM/DD/YYYY') : ''
            };
          });
          setAudiogramData(response.data.rows);
          setLoadMore(currentPageNumber);
          setIsLoadMoreLoading(false);
          setLoadMoreLoader(false);
          setHasPermission(true);
          setErrMsg('Module not activated.');
          setTableData(finalResult);
          //hide(finalResult.length);
          setNoFound(false);
        } else if (response.status === 200 && response.data.rows?.length == 0) {
          setShowAdvanceButton(false);

          setNoFound(false);
          setTableData([]);
          setAudiogramData([]);
          if (!advanceFilter) {
            setShowAdvanceButton(true);
            //showResetButton(true);
          }
          //   setNoRecordMessage('No Record found');
          //  // setLoadMore(currentPageNumber);
          //   setIsLoadMoreLoading(false);
          //   setLoadMoreLoader(false);
        } else if (response.status === 201) {
          setNoFound(true);
          setNoRecordMessage('Access permission not allowed.');
          setErrMsg('Access permission not allowed.');
          // notification.error({
          // message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          // });
        } else if (response.status === 202) {
          setNoFound(false);
          //  setWijmoFlexGrid([], 10);
          setHasPermission(true);
          setNoRecordMessage('No Record found');
        }
        // else {
        //   notification.error({
        //     message: 'Failed to retrieve data at the moment. Please try again in a bit.'
        //   });
        //   setNoFound(true);
        // }
      });
  };

  const getAudiogramResult = (forceReset = false) => {
    axios
      .post(settings.serverUrl + '/api/audiogramListCustomerPortal_count', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          fullNameValue: forceReset ? '' : fullNameValue || newName,
          locValue: forceReset ? '' : locValue,
          dobValue: forceReset ? '' : dobValue,
          lastTestValue: forceReset ? '' : lastTestValue,
          oshaDate: forceReset ? '' : oshaDate,
          stsDate: forceReset ? '' : stsDate
        }
      })
      .then((response) => {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.total_count >= 0
        ) {
          setTotalRecords(response.data.total_count);
        } else if (response.status === 202) {
          console.log(response.data, 'err');
        }
      });
  };
  const downloadStsReport = (id) => {
    axios
      .post(settings.serverUrl + '/api/getAudiogramStsReport', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          testId: id
        }
      })
      .then((response) => {
        let finalResult = response.data;

        if (
          finalResult &&
          finalResult.length > 0 &&
          finalResult[0].url &&
          finalResult[0].url !== ''
        ) {
          window.open(finalResult[0].url);
        } else {
          if (finalResult && finalResult.length === 0) {
            notification.error({
              message: 'No Data Found!'
            });
          } else {
            notification.error({
              message: 'Empty URL in response!'
            });
          }
        }
      })
      .catch((error) => {
        console.error('Error while fetching data:', error);
      });
  };
  const downloadStsChart = (id) => {
    axios
      .post(settings.serverUrl + '/api/getAudiogramStsChartReport', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          testId: id
        }
      })
      .then((response) => {
        let finalResult = response.data;

        if (
          finalResult &&
          finalResult.length > 0 &&
          finalResult[0].url &&
          finalResult[0].url !== ''
        ) {
          window.open(finalResult[0].url);
        } else {
          if (finalResult && finalResult.length === 0) {
            notification.error({
              message: 'No Data Found!'
            });
          } else {
            notification.error({
              message: 'Empty URL in response!'
            });
          }
        }
      })
      .catch((error) => {
        console.error('Error while fetching data:', error);
      });
  };

  useEffect(() => {
    setInfoVisible(false);
    getAudiogramData();
    //getAudiogramData(loadMore);
    getAudiogramResult();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
    //}, [loadMore,total]);
  }, []);

  useEffect(() => {
    if (tableData.length > 0) {
      const collectionView1 = new CollectionView(tableData, {
        pageSize: tableData.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(tableData, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      setView(collectionView2);
    }
  }, [tableData, pageSize]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }

  const handleShowAudioInfo = (audioInfo) => {
    history.push({
      pathname: '/vertical/audiogramTestDetailForm',
      state: audioInfo
    });
  };
  const showEmpName = (item) => {
    return item && item.item.fullName ? item.item.fullName : '';
  };

  const showDOB = (item) => {
    return item && item.item.dob && moment(item.item.dob).format('MM/DD/YYYY');
  };
  const showEmpLoc = (item) => {
    console.log(item, 'showEmp');
    return item && item.item.com_loc_name ? item.item.com_loc_name : '';
  };
  const handleOpenDateModal = async (item) => {
    setSelectedItem(item);
    await getAudiogramRightHisData(item.emp_selected, item.id);
    setInfoVisible(true);
  };

  const handleCloseDateModal = () => {
    setInfoVisible(false);
    setSelectedItem(null);
    setRightView(null);
    setGraphData(null);
    // Clear the selected item when modal is closed
  };
  const getAudiogramRightHisData = (id, testId) => {
    setRightNoFound(true);
    axios
      .post(settings.serverUrl + '/api/getAudiogramRightHisDataByEmpId', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          empId: id
        }
      })
      .then((response) => {
        //let testNameEmp = location.state.test_name;
        let finalResult = response?.data;
        const uniqueIds = new Set();
        finalResult = response?.data
          ?.filter((k) => {
            // Filter out records with duplicate ids
            if (!uniqueIds.has(k.id)) {
              uniqueIds.add(k.id);
              return true;
            }
            return false;
          })
          ?.filter((k) => {
            return k.testname != null && k.id == testId;
            // && k.testname === testNameEmp;
          })
          .map((k) => {
            return {
              ...k,
              age: k.age != null ? k.age : '',
              testing_date:
                k.testing_date != null
                  ? k.testing_date && moment(k.testing_date).format('MM/DD/YYYY')
                  : '',
              testName: k.testname != null ? k.testname : '',
              is_first_record: k.is_first_record != null ? k.is_first_record : '',
              is_left_sts: k.is_left_sts != null ? k.is_left_sts : '',
              is_right_sts: k.is_right_sts != null ? k.is_right_sts : '',
              osha_recordable_left: k.osha_recordable_left != null ? k.osha_recordable_left : '',
              osha_recordable_right: k.osha_recordable_right != null ? k.osha_recordable_right : '',
              test_detail_id: k.test_detail_id != null && k.test_detail_id,
              testing_date_basline:
                k.testing_date_basline != null
                  ? k.testing_date_basline && moment(k.testing_date_basline).format('MM/DD/YYYY')
                  : '',
              is_baseline: k.is_baseline != null ? k.is_baseline : '',
              create_date: k.create_date != null ? moment(k.create_date).format('MM/DD/YYYY') : '',
              right_date:
                k.right_date != null
                  ? k.right_date && moment(k.right_date).format('MM/DD/YYYY')
                  : '',
              right_type: k.right_type && k.right_type,
              right_5k: k.right_5k != '' ? k.right_5k : '',
              right_1k: k.right_1k != '' ? k.right_1k : '',
              right_2k: k.right_2k != '' ? k.right_2k : '',
              right_3k: k.right_3k != '' ? k.right_3k : '',
              right_4k: k.right_4k != '' ? k.right_4k : '',
              right_6k: k.right_6k != '' ? k.right_6k : '',
              right_8k: k.right_8k != '' ? k.right_8k : '',
              right_ahl: k.right_ahl != '' ? k.right_ahl : '',
              right_sts: k.right_sts != '' ? k.right_sts : '',
              right_age_sts: k.right_age_sts != '' ? k.right_age_sts : '',
              right_osha: k.right_age_osha != '' ? k.right_age_osha : '',
              addendum_area: k.addendum_area != null ? k.addendum_area : '',
              medical_referral_left: k.medical_referral_left != null ? k.medical_referral_left : '',
              medical_referral_right:
                k.medical_referral_right != null ? k.medical_referral_right : '',
              asymmetric_right: k.asymmetric_right != null ? k.asymmetric_right : '',
              asymmetric_left: k.asymmetric_left != null ? k.asymmetric_left : '',
              audiologist_name: k.audiologist_name != null ? k.audiologist_name : '',
              abnormalLeftEar_25: k.abnormalLeftEar_25 != null ? k.abnormalLeftEar_25 : '',
              abnormalRightEar_25: k.abnormalRightEar_25 != null ? k.abnormalRightEar_25 : '',
              abnormalLeftEar: k.abnormalLeftEar != null ? k.abnormalLeftEar : '',
              abnormalRightEar: k.abnormalRightEar != null ? k.abnormalRightEar : '',
              normalRightEar: k.normalRightEar != null ? k.normalRightEar : '',
              normalLeftEar: k.normalLeftEar != null ? k.normalLeftEar : '',
              non_recordable_sts_left:
                k.non_recordable_sts_left != null ? k.non_recordable_sts_left : '',
              non_recordable_sts_right:
                k.non_recordable_sts_right != null ? k.non_recordable_sts_right : '',
              recordable_sts_right: k.recordable_sts_right != null ? k.recordable_sts_right : '',
              recordable_sts_left: (k.recordable_sts_left = null ? k.recordable_sts_left : ''),
              bright_5k: k.bright_5k != null ? k.bright_5k : '',
              bright_1k: k.bright_1k != null ? k.bright_1k : '',
              bright_2k: k.bright_2k != null ? k.bright_2k : '',
              bright_3k: k.bright_3k != null ? k.bright_3k : '',
              bright_4k: k.bright_4k != null ? k.bright_4k : '',
              bright_6k: k.bright_6k != null ? k.bright_6k : '',
              bright_8k: k.bright_8k != null ? k.bright_8k : '',
              bleft_5k: k.bleft_5k != null ? k.bleft_5k : '',
              bleft_1k: k.bleft_1k != null ? k.bleft_1k : '',
              bleft_2k: k.bleft_2k != null ? k.bleft_2k : '',
              bleft_3k: k.bleft_3k != null ? k.bleft_3k : '',
              bleft_4k: k.bleft_4k != null ? k.bleft_4k : '',
              bleft_6k: k.bleft_6k != null ? k.bleft_6k : '',
              bleft_8k: k.bleft_8k != null ? k.bleft_8k : '',
              left_5K: k.left_5k != '' ? k.left_5k : '',
              left_1K: k.left_1k != '' ? k.left_1k : '',
              left_2K: k.left_2k != '' ? k.left_2k : '',
              left_3K: k.left_3k != '' ? k.left_3k : '',
              left_4K: k.left_4k != '' ? k.left_4k : '',
              left_6K: k.left_6k != '' ? k.left_6k : '',
              left_8K: k.left_8k != '' ? k.left_8k : '',
              left_age_sts: k.left_age_sts != '' ? k.left_age_sts : '',
              left_osha: k.left_age_osha != '' ? k.left_age_osha : ''
            };
          });
        let sortedData = finalResult.sort(
          (a, b) => new Date(b.testing_date) - new Date(a.testing_date)
        );
        getAudiogramGraph(testId)
          .then((graphData) => {
            sortedData[0].graphData = graphData;
            setRightView(sortedData);
          })
          .catch((error) => {
            console.error('Error in getAudiogramGraph:', error);
          });

        setAudiogramRightData(sortedData);
        setRightNoFound(false);
      });
  };
  const getAudiogramGraph = (id) => {
    // setNoGFound(true);
    return axios
      .post(settings.serverUrl + '/api/getAudiogramGraphData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          testId: id
        }
      })
      .then((response) => {
        let finalResult = response.data;
        setGraphData(finalResult);
        // setNoGFound(false);
        return finalResult;
      })
      .catch((error) => {
        console.error('Error fetching audiogram graph data:', error);
        throw error;
      });
  };
  const showStsDate = (item) => {
    return (
      <>
        {item && item.item && item.item.stsDate ? (
          <>
            {moment(item.item.stsDate).format('MM/DD/YYYY')}
            <FolderOpenTwoTone
              twoToneColor='#cc7a00'
              style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
              onClick={() => handleOpenDateModal(item.item)}
            />
          </>
        ) : (
          ''
        )}
      </>
    );
  };
  const showOshaDate = (item) => {
    return (
      <>
        {item && item.item && item.item.oshaDate ? (
          <>
            {moment(item.item.oshaDate).format('MM/DD/YYYY')}
            <FolderOpenTwoTone
              twoToneColor='#cc7a00'
              style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
              onClick={() => handleOpenDateModal(item.item)}
            />
          </>
        ) : (
          ''
        )}
      </>

      // item &&
      // (item.item.osha_recordable_left == true || item.item.osha_recordable_right == true) &&
      // item.item.osha_Date &&
      // moment(item.item.osha_Date).format('MM/DD/YYYY')
    );
  };

  const showLastTest = (item) => {
    return item && item.item.test_date_last
      ? moment(item.item.test_date_last).format('MM/DD/YYYY')
      : '';
  };

  const showActions = (item) => {
    return (
      <div className='buttons-list nowrap'>
        {/* <Button
          shape='circle'
          onClick={() => handleShowAudioInfo(item.item)}
          danger
          style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            color: 'rgb(239,103,39)'
          }}
        >
          <span className='icofont icofont-info-circle' style={{ fontSize: 15 }} />
        </Button> */}
        <InfoCircleTwoTone
          onClick={() => handleShowAudioInfo(item.item)}
          style={{ marginLeft: 12, marginTop: 5, color: '#0066ff', fontSize: 15 }}
          twoToneColor='#0066ff'
        />
      </div>
    );
  };
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const hide = (data) => {
    if (data === total) {
      setLoadmorehide(true);
    }
  };

  const handleChange = (e) => {
    // console.log(e.target.value);
    // setFullNameValue(e.target.value);
    setNewName(e.target.value);
    // setFullNameValue(newName);
  };

  const onSearch = (value) => {
    if (newName.length > 0) {
      console.log('Name');
      getAudiogramData(1, false, true);
      getAudiogramResult();
      // setFullNameValue('');
    }
  };

  const advanceDateFilter = (filterType, value) => {
    if (filterType == 'dobValue') {
      console.log(value, 'date');
      setDobValue(value);
    } else if (filterType === 'lastTestValue') {
      setLastTestValue(value);
    } else if (filterType === 'stsDate') {
      setStsDate(value);
    } else if (filterType === 'oshaDate') {
      setOshaDate(value);
    }
  };

  const showAdvanceFilterModal = () => {
    setIsAdvanceFilterModalOpen(true);
  };

  const handleAdvanceFilterCancel = () => {
    setIsAdvanceFilterModalOpen(false);
  };

  const handleAdvanceFilter = () => {
    if (
      (oshaDate && oshaDate.length > 0) ||
      (stsDate && stsDate.length > 0) ||
      (fullNameValue && fullNameValue.length > 0) ||
      (locValue && locValue.length > 0) ||
      (lastTestValue && lastTestValue.length > 0)
    ) {
      getAudiogramData(1, false, true);
      setIsAdvanceFilterModalOpen(false);
      getAudiogramResult();
      setLoadMoreLoader(true);
    } else {
      setTableData(audiogramData);
      setIsAdvanceFilterModalOpen(false);
      // setLoadMoreLoader(false)
    }
  };
  let infoRecord = [];
  const handleReset = () => {
    console.log('click');
    setShowResetButton(true);
    setIsLoading(true);
    setLoadMoreLoader(true);
    setNewName('');
    setFullNameValue('');
    setLocValue('');
    setOshaDate('');
    setStsDate('');
    setLastTestValue('');
    getAudiogramData(1, true);
    getAudiogramResult(true);
  };

  const shouldShowLoadMore = () => {
    return loadMore * pageLimit <= totalRecords;
  };

  return (
    <div style={{ padding: 20 }}>
      <Row style={{ alignItems: 'center', textAlign: 'center' }}>
        <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 9 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <h3 style={{ padding: 20, textAlign: 'left', color: '#1C3A6A' }}>Audiogram</h3>
        </Col>
        <Col
          xl={{ span: 18 }}
          lg={{ span: 18 }}
          md={{ span: 15 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ textAlign: 'right' }}
        >
          {audiogramData && (
            <>
              {' '}
              {audiogramData.length === 0 && !showAdvanceButton && (
                <>
                  <Button
                    size='small'
                    style={{
                      marginBottom: 10,
                      backgroundColor: '#247',
                      border: 'none',
                      marginRight: '10px'
                    }}
                    onClick={showAdvanceFilterModal}
                  >
                    Advanced Search Filter
                  </Button>
                  <Modal
                    width={1000}
                    title='Advanced Search Filter'
                    visible={isAdvanceFilterModalOpen}
                    onCancel={handleAdvanceFilterCancel}
                    footer={[
                      <Button
                        size='small'
                        style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                        onClick={() => handleAdvanceFilter()}
                      >
                        Search
                      </Button>
                    ]}
                  >
                    <Row gutter={[24, 24]}>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <Input
                          value={fullNameValue}
                          placeholder='Full Name :'
                          suffix={<span className='icofont icofont-search' />}
                          style={{ width: '80%', backgroundColor: 'white' }}
                          onChange={(event) => setFullNameValue(event.target.value)}
                        />
                      </Col>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <Input
                          value={locValue}
                          placeholder='Location :'
                          suffix={<span className='icofont icofont-search' />}
                          style={{ width: '80%', backgroundColor: 'white' }}
                          onChange={(event) => setLocValue(event.target.value)}
                        />
                      </Col>

                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <RangePicker
                          //value={collectionDate}
                          placeholder={['Start DOB Date', 'End DOB Date']}
                          allowClear={true}
                          style={{ borderRadius: 20, width: '80%', height: 40 }}
                          onChange={(value, dateString) => {
                            advanceDateFilter('dobValue', dateString);
                          }}
                        />
                      </Col>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <RangePicker
                          //value={collectionDate}
                          placeholder={['Start Last Test', 'End Last Test']}
                          allowClear={true}
                          style={{ borderRadius: 20, width: '80%', height: 40 }}
                          onChange={(value, dateString) => {
                            advanceDateFilter('lastTestValue', dateString);
                          }}
                        />
                      </Col>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <RangePicker
                          //value={collectionDate}
                          placeholder={['Start OSHA Date', 'End OSHA Date']}
                          allowClear={true}
                          style={{ borderRadius: 20, width: '80%', height: 40 }}
                          onChange={(value, dateString) => {
                            advanceDateFilter('oshaDate', dateString);
                          }}
                        />
                      </Col>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <RangePicker
                          //value={collectionDate}
                          placeholder={['Start STS Date', 'End STS Date']}
                          allowClear={true}
                          style={{ borderRadius: 20, width: '80%', height: 40 }}
                          onChange={(value, dateString) => {
                            advanceDateFilter('stsDate', dateString);
                          }}
                        />
                      </Col>
                    </Row>
                  </Modal>

                  <Button
                    size='small'
                    style={{
                      backgroundColor: '#247',
                      marginBottom: 10,
                      border: 'none',
                      marginRight: '10px'
                    }}
                    onClick={() => handleReset()}
                  >
                    Reset Filter
                  </Button>
                </>
              )}
              {audiogramData.length > 0 && (
                <>
                  {shouldShowLoadMore() && (
                    <Button
                      size='small'
                      style={{
                        backgroundColor: '#247',
                        color: 'white',
                        border: 'none',
                        width: '80px',
                        marginBottom: 10,
                        marginRight: '10px'
                      }}
                      onClick={() => {
                        // setLoadMore(loadMore + 1);
                        getAudiogramData(loadMore + 1);
                        setLoadMoreLoader(true);
                      }}
                    >
                      {isLoadMoreLoading ? (
                        <Space size='middle'>
                          <Spin size='small' />
                        </Space>
                      ) : (
                        'Load More'
                      )}
                    </Button>
                  )}

                  <Button
                    size='small'
                    style={{
                      marginBottom: 10,
                      backgroundColor: '#247',
                      border: 'none',
                      marginRight: '10px'
                    }}
                    onClick={showAdvanceFilterModal}
                  >
                    Advanced Search Filter
                  </Button>
                  <Modal
                    width={1000}
                    title='Advanced Search Filter'
                    visible={isAdvanceFilterModalOpen}
                    onCancel={handleAdvanceFilterCancel}
                    footer={[
                      <Button
                        size='small'
                        style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                        onClick={() => handleAdvanceFilter()}
                      >
                        Search
                      </Button>
                    ]}
                  >
                    <Row gutter={[24, 24]}>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <Input
                          value={fullNameValue}
                          placeholder='Full Name :'
                          suffix={<span className='icofont icofont-search' />}
                          style={{ width: '80%', backgroundColor: 'white' }}
                          onChange={(event) => setFullNameValue(event.target.value)}
                        />
                      </Col>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <Input
                          value={locValue}
                          placeholder='Location :'
                          suffix={<span className='icofont icofont-search' />}
                          style={{ width: '80%', backgroundColor: 'white' }}
                          onChange={(event) => setLocValue(event.target.value)}
                        />
                      </Col>

                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <RangePicker
                          //value={collectionDate}
                          placeholder={['Start DOB Date', 'End DOB Date']}
                          allowClear={true}
                          style={{ borderRadius: 20, width: '80%', height: 40 }}
                          onChange={(value, dateString) => {
                            advanceDateFilter('dobValue', dateString);
                          }}
                        />
                      </Col>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <RangePicker
                          //value={collectionDate}
                          placeholder={['Start Last Test', 'End Last Test']}
                          allowClear={true}
                          style={{ borderRadius: 20, width: '80%', height: 40 }}
                          onChange={(value, dateString) => {
                            advanceDateFilter('lastTestValue', dateString);
                          }}
                        />
                      </Col>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <RangePicker
                          //value={collectionDate}
                          placeholder={['Start OSHA Date', 'End OSHA Date']}
                          allowClear={true}
                          style={{ borderRadius: 20, width: '80%', height: 40 }}
                          onChange={(value, dateString) => {
                            advanceDateFilter('oshaDate', dateString);
                          }}
                        />
                      </Col>
                      <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                        <RangePicker
                          //value={collectionDate}
                          placeholder={['Start STS Date', 'End STS Date']}
                          allowClear={true}
                          style={{ borderRadius: 20, width: '80%', height: 40 }}
                          onChange={(value, dateString) => {
                            advanceDateFilter('stsDate', dateString);
                          }}
                        />
                      </Col>
                    </Row>
                  </Modal>
                  <Button
                    size='small'
                    style={{
                      backgroundColor: '#247',
                      marginBottom: 10,
                      border: 'none',
                      marginRight: '10px'
                    }}
                    onClick={() => handleReset()}
                  >
                    Reset Filter
                  </Button>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      {isLoadMoreLoading ||
        (isLoading && (
          <Row>
            <Col span={10}></Col>
            <Col span={8}>
              <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                <Spin size='large' />
              </Space>
            </Col>
          </Row>
        ))}
      {!nofound ? (
        tableData.length > 0 ? (
          <div style={{ padding: 10, margin: 10 }}>
            <h6>
              Total:
              <span style={{ marginLeft: '10px' }}>
                {totalRecords >= 0 ? totalRecords : <Spin size='small' />}
              </span>
            </h6>
            <Row gutter={[32, 32]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                {/* <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                />  */}
                <Search
                  placeholder='Search By Employee Name'
                  enterButton
                  // loading={isLoading}
                  value={newName}
                  onSearch={onSearch}
                  onChange={handleChange}
                  size='small'
                  // style={{ width: 304 }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div ref={fixedContainerRef}>
                  <Select
                    style={{ width: 160 }}
                    placeholder={`Items per page : ${pageSize}`}
                    onChange={paggination}
                    options={[
                      { label: 'Items per page : 10', value: 10 },
                      { label: 'Items per page : 20', value: 20 },
                      { label: 'Items per page : 50', value: 50 }
                    ]}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                  size='small'
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>

            {/* to export entire data of table */}
            <FlexGrid
              ref={theGrid}
              style={{ display: 'none' }}
              className='dummyWijmoData'
              itemsSource={viewData}
              initialized={gridInitializedData}
              formatItem={formatItem}
              isReadOnly={true}
            >
              <FlexGridColumn
                binding='fullName'
                header='Employee Name'
                minWidth={200}
                width='*'
                wordWrap={true}
              />
              <FlexGridColumn binding='newDobForExcel' header='DOB' minWidth={100} width='*' />
              <FlexGridColumn
                binding='com_loc_name'
                header='Location'
                minWidth={300}
                width='*'
                wordWrap={true}
              />
              <FlexGridColumn
                binding='newTest_Date_LastForExcel'
                header='Last Test'
                minWidth={100}
              />
              <FlexGridColumn binding='actions' header='Tests' minWidth={50}>
                <FlexGridCellTemplate cellType='Cell' template={showActions} />
              </FlexGridColumn>

              <FlexGridColumn binding='stsDate' header='STS' minWidth={100}></FlexGridColumn>

              <FlexGridColumn
                binding='oshaDate'
                header='OSHA'
                minWidth={100}
                width='*'
              ></FlexGridColumn>
            </FlexGrid>

            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              className='cliniclisttable'
              itemsSource={view}
              initialized={gridInitialized}
              formatItem={formatItem}
              itemsPerPage={10}
              isReadOnly={true}
              //autoGenerateColumns={false}
            >
              <div style={{ display: 'none' }}>
                <wjInput.ListBox
                  className='column-picker'
                  itemsSource={listboxColumns}
                  checkedMemberPath='visible'
                  displayMemberPath='header'
                  lostFocus={hidePicker}
                  initialized={initializedListBox}
                ></wjInput.ListBox>
              </div>
              <FlexGridColumn binding='fullName' header='Employee Name' width={275}>
                <FlexGridCellTemplate cellType='Cell' template={showEmpName} />
              </FlexGridColumn>
              <FlexGridColumn binding='dob' header='DOB' width={134.25}>
                <FlexGridCellTemplate cellType='Cell' template={showDOB} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='com_loc_name'
                header='Location'
                width={277}
                // minWidth={200}
                // width='*'
                // wordWrap={true}
              >
                <FlexGridCellTemplate cellType='Cell' template={showEmpLoc} />
              </FlexGridColumn>
              {/* <FlexGridColumn
                binding='test_name'
                header='Test Name'
                // minWidth={50}
                width={152}
              ></FlexGridColumn> */}
              <FlexGridColumn
                binding='test_date_last'
                header='Last Test'
                // minWidth={50}
                width={134.25}
              >
                <FlexGridCellTemplate cellType='Cell' template={showLastTest} />
              </FlexGridColumn>
              <FlexGridColumn binding='actions' header='Tests' width={50}>
                <FlexGridCellTemplate cellType='Cell' template={showActions} />
              </FlexGridColumn>
              <FlexGridColumn binding='sts_Date' header='STS' width={134.25}>
                <FlexGridCellTemplate cellType='Cell' template={showStsDate} />
              </FlexGridColumn>
              <FlexGridColumn binding='osha_Date' header='OSHA' width={134.25}>
                <FlexGridCellTemplate cellType='Cell' template={showOshaDate} />
              </FlexGridColumn>
              <FlexGridFilter
                showFilterIcons={true}
                placeholder='Filter data...'
                defaultFilterType='Value'
                filterColumns={[
                  'fullName',
                  'com_loc_name',
                  'osha_Date',
                  'sts_Date',
                  'dob',
                  'test_date_last'
                ]}
              />
            </FlexGrid>
            <Modal
              width={1200}
              centered
              zIndex={1000}
              title={
                <h6>{`Test detail for ${
                  rightView?.length > 0 &&
                  (rightView[0]?.testing_date != null || rightView[0]?.testing_date != false)
                    ? rightView[0]?.testing_date
                    : ''
                }`}</h6>
              }
              visible={infoVisible}
              onOk={handleCloseDateModal}
              onCancel={handleCloseDateModal}
              footer={null}
            >
              {rightView && rightView.length > 0 ? (
                <>
                  <div style={{ margin: 10 }}>
                    {rightView?.length > 0 && rightView[0]?.testName == 'STS Report' && (
                      <div style={{ display: 'flex', direction: 'rtl' }}>
                        <Button
                          size='small'
                          style={{
                            backgroundColor: 'rgb(61 88 106)',
                            color: 'white',
                            marginTop: 0,
                            padding: 10,
                            marginRight: 5
                          }}
                          onClick={() =>
                            downloadStsChart(rightView?.length > 0 && rightView[0]?.id)
                          }
                          icon={<DownloadOutlined />}
                        >
                          STS Chart Report
                        </Button>
                        <Button
                          size='small'
                          style={{
                            backgroundColor: 'rgb(61 88 106)',
                            color: 'white',
                            marginTop: 0,
                            padding: 10,
                            marginRight: 5
                          }}
                          onClick={() =>
                            downloadStsReport(rightView?.length > 0 && rightView[0]?.id)
                          }
                          icon={<DownloadOutlined />}
                        >
                          STS Report
                        </Button>
                      </div>
                    )}
                    <span
                      style={{
                        color: 'rgb(195, 95, 78)',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h6>Right Ear Results:</h6>
                    </span>
                    <table className='rightearresult' style={{ width: '100%' }}>
                      <thead style={{ backgroundColor: 'rgb(195,95,78)', color: 'white' }}>
                        <tr>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>
                            Frequency (Hz)
                          </th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>500</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>1000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>2000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>3000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>4000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>6000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>8000</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              color: 'rgb(195,95,78)',
                              fontWeight: 'bold'
                            }}
                          >
                            Air (dB HL)
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.right_5k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.right_1k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.right_2k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.right_3k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.right_4k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.right_6k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.right_8k}
                          </td>
                        </tr>
                        {rightView?.length > 0 && rightView[0]?.testName == 'STS Report' && (
                          <tr>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              Baseline
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bright_5k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bright_1k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bright_2k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bright_3k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bright_4k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bright_6k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bright_8k}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {rightView?.length > 0 && rightView[0]?.testName == 'STS Report' && (
                      <p>
                        Established Baseline(
                        {rightView?.length > 0 && rightView[0]?.testing_date_basline})
                      </p>
                    )}
                    <br />
                    {console.log('leftInfoRecord------------', leftInfoRecord)}
                    <span
                      style={{
                        color: 'rgb(60,107,246)',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h6>Left Ear Results:</h6>
                    </span>

                    <table className='rightearresult' style={{ width: '100%' }}>
                      <thead style={{ backgroundColor: 'rgb(60,107,246)', color: 'white' }}>
                        <tr>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>
                            Frequency (Hz)
                          </th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>500</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>1000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>2000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>3000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>4000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>6000</th>
                          <th style={{ textAlign: 'center', border: '1px solid grey' }}>8000</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              color: 'rgb(60,107,246)',
                              fontWeight: 'bold'
                            }}
                          >
                            Air (dB HL)
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.left_5k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.left_1k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.left_2k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.left_3k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.left_4k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.left_6k}
                          </td>
                          <td
                            style={{
                              border: '1px solid grey',
                              textAlign: 'center',
                              fontWeight: 'bold'
                            }}
                          >
                            {rightView?.length > 0 && rightView[0]?.left_8k}
                          </td>
                        </tr>
                        {rightView?.length > 0 && rightView[0]?.testName == 'STS Report' && (
                          <tr>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              Baseline
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bleft_5k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bleft_1k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bleft_2k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bleft_3k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bleft_4k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bleft_6k}
                            </td>
                            <td
                              style={{
                                border: '1px solid grey',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}
                            >
                              {rightView?.length > 0 && rightView[0]?.bleft_8k}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {rightView?.length > 0 && rightView[0]?.testName == 'STS Report' && (
                      <p>
                        Established Baseline(
                        {rightView?.length > 0 && rightView[0]?.testing_date_basline})
                      </p>
                    )}
                  </div>
                  <br />
                  <div>
                    {rightView?.length > 0 && rightView[0]?.graphData?.length > 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexWrap: 'wrap'
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            maxWidth: '50%',
                            flex: '0 0 50%',
                            marginBottom: '20px'
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              top: '5px',
                              left: '5px',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: 'rgb(60,107,246)',
                              padding: '5px',
                              borderRadius: '5px'
                            }}
                          >
                            Left Ear Graph:
                          </span>
                          <br />
                          <img
                            src={
                              rightView?.length > 0 &&
                              rightView[0]?.graphData.find((item) => item.name.includes('left')).url
                            }
                            alt='Left Ear Graph'
                            style={{ width: '100%', height: 'auto', marginRight: 10 }}
                          />
                          <table
                            style={{
                              width: '100%',
                              margin: '10px 0px 0px -10px',
                              textAlign: 'center'
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ textAlign: 'right' }}>Right &nbsp;&nbsp;</td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={LeftAirUp}
                                    alt='Left Air'
                                    style={{ height: '12px', width: '12px' }}
                                  />
                                  &nbsp;&nbsp;Air
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={LeftMaskedAirUp}
                                    alt='Left Bone'
                                    style={{ height: '14px', width: '14px' }}
                                  />
                                  &nbsp;&nbsp;Masked Air
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={LeftBoneUp}
                                    alt='Left Bone'
                                    style={{ height: '12px', width: '10px' }}
                                  />
                                  &nbsp;&nbsp;Bone
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={LeftMaskedBoneUp}
                                    alt='Left Bone'
                                    style={{ height: '12px', width: '8px' }}
                                  />
                                  &nbsp;&nbsp;Masked Bone
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'right' }}>No Response&nbsp;&nbsp;</td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={LeftAirDown}
                                    alt='Left Air'
                                    style={{ height: '15px', width: '15px' }}
                                  />
                                  &nbsp;Air
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={LeftMaskedAirDown}
                                    alt='Left Bone'
                                    style={{ height: '15px', width: '15px' }}
                                  />
                                  &nbsp;Masked Air
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={LeftBone}
                                    alt='Left Bone'
                                    style={{ height: '25px', width: '20px' }}
                                  />
                                  &nbsp;Bone
                                </td>

                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={LeftMaskedBoneDown}
                                    alt='Left Bone'
                                    style={{ height: '15px', width: '15px' }}
                                  />
                                  &nbsp;Masked Bone
                                </td>
                              </tr>
                              <tr>
                                <td colspan={2}>
                                  <img
                                    src={LeftCurrent}
                                    alt='Left Bone'
                                    style={{ height: '8px', width: '40px' }}
                                  />
                                  &nbsp;Current
                                </td>
                                <td></td>
                                <td colspan={2}>
                                  <img
                                    src={BaselineEstab}
                                    alt='Left Bone'
                                    style={{ height: '8px', width: '40px' }}
                                  />
                                  &nbsp; Established Baseline (
                                  {rightView?.length > 0 && rightView[0]?.testing_date_basline})
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
                          style={{
                            position: 'relative',
                            maxWidth: '50%',
                            flex: '0 0 50%',
                            marginBottom: '20px'
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              top: '5px',
                              left: '5px',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: 'rgb(195,95,78)',
                              padding: '5px',
                              borderRadius: '5px'
                            }}
                          >
                            Right Ear Graph:
                          </span>
                          <br />
                          <img
                            src={
                              rightView?.length > 0 &&
                              rightView[0]?.graphData.find((item) => item.name.includes('right'))
                                .url
                            }
                            alt='Right Ear Graph'
                            style={{ width: '100%', height: 'auto', marginRight: 10 }}
                          />
                          <table
                            style={{
                              width: '100%',
                              margin: '10px 0px 0px -10px',
                              textAlign: 'center'
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ textAlign: 'right' }}>Right &nbsp;&nbsp;</td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={RightAir}
                                    alt='Right Air'
                                    style={{ height: '12px', width: '12px' }}
                                  />
                                  &nbsp;&nbsp;Air
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={RightMaskedAirUp}
                                    alt='Right Air'
                                    style={{ height: '12px', width: '12px' }}
                                  />
                                  &nbsp;&nbsp;Masked Air
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={RightBoneUp}
                                    alt='Right Air'
                                    style={{ height: '12px', width: '12px' }}
                                  />
                                  &nbsp;&nbsp;Bone
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={RightMaskedBoneUp}
                                    alt='Right Air'
                                    style={{ height: '12px', width: '12px' }}
                                  />
                                  &nbsp;&nbsp;Masked Bone
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'right' }}>No Response&nbsp;&nbsp;</td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={RightAirDown}
                                    alt='Right Air'
                                    style={{ height: '15px', width: '15px' }}
                                  />
                                  &nbsp;Air
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={RightMaskedAirDown}
                                    alt='Right Air'
                                    style={{ height: '15px', width: '15px' }}
                                  />
                                  &nbsp;Masked Air
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={RightBoneDown}
                                    alt='Right Air'
                                    style={{ height: '25px', width: '20px' }}
                                  />
                                  &nbsp;Bone
                                </td>
                                <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                  <img
                                    src={RightMaskedBoneDown}
                                    alt='Right Air'
                                    style={{ height: '15px', width: '15px' }}
                                  />
                                  &nbsp;Masked Bone
                                </td>
                              </tr>
                              <tr>
                                <td colspan={2}>
                                  <img
                                    src={RightCurrent}
                                    alt='Right Air'
                                    style={{ height: '8px', width: '40px' }}
                                  />
                                  &nbsp;Current
                                </td>
                                <td></td>
                                <td colspan={2}>
                                  <img
                                    src={BaselineEstab}
                                    alt='Right Air'
                                    style={{ height: '8px', width: '40px' }}
                                  />
                                  &nbsp; Established Baseline (
                                  {rightView?.length > 0 && rightView[0]?.testing_date_basline})
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <h5 style={{ color: '#ef6727', textAlign: 'center' }}>
                        No Graphs Available !
                      </h5>
                    )}
                  </div>
                </>
              ) : (
                <Row>
                  <Col span={10}></Col>
                  <Col span={4}>
                    <Space size='middle' style={{ marginTop: 25, marginLeft: 85 }}>
                      <Spin size='large' />
                    </Space>
                  </Col>
                  <Col span={10}></Col>
                </Row>
              )}
            </Modal>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                ref={filterRefCounter}
                id='filterCounter'
              >
                ({Globalize.format(filterRefCounter.current, 'n0')} )
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                {' '}
                out of {audiogramData.length}
              </p>
            </div>
          </div>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>
            {showResetButton ? '' : 'No Record Found!'}
          </h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AudiogramList;
