import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import MaskedInput from 'antd-mask-input';
import axios from 'axios';
import moment from "moment";
import _ from 'underscore';
import setting from '../../config/setting';
import { history } from '../../redux/store';
 
import {
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Spin,
  Space,
  Row,
  Col,
  Card,
  notification
} from 'antd';
const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;

const NewEmployeeInstruction = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const data = location?.state?.data;

  const [locationNewA, setlocationNew] = useState(data?.emp_location);
  const [dateNew, setDate] = useState(data?.dob ? data.dob : 'Enter Date of Birth');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [bool, setBool] = useState(false);
  const isMounted = useRef(true);
  const [Res, setRes] = useState([]);
  const [startDate, setStartDate] = useState(data?.hireRehireDateforExcel ? data.hireRehireDateforExcel : 'Enter Start Date');
  const [legalName, setLegalName] = useState(data?.fullName);
  const [jobTitle, setJobTitle] = useState(data?.Job_Title);
  const [ssnValue, setSsnValue] = useState('');
  const [phoneValue, setPhoneValue] = useState(data?.Mobile);
  const [emailValue, setEmailValue] = useState(data?.email);
  const [address, setAddress] = useState(data?.street + data?.city);

  const [typeScreen, setTypeScreen] = useState('');
  const [specialInst, setSpecialInst] = useState('');
  const [bgScreening, setBgScreening] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [reasons, setSelectReason] = useState('');
  const [reasonValue, setReasonValue] = useState('');
  const [service_multi_select, setSelectPackage] = useState('');
  const [packageValue, setPackageValue] = useState([]);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingReasons, setLoadingReasons] = useState(false);
  const [loadingPkgs, setLoadingPkgs] = useState(false);
  const [emp_ein, setEinValue] = useState(data?.emp_ein ? data.emp_ein : '');

  const [DL, setDrivingLicense] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');

  const companyId = localStorage.getItem('customerId');



  console.log("-------------------DATA---------------", data)




  useEffect(() => {
    setSelectedCompany(companyId);
    getResPArt(companyId);
    getReasonNames();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));

    form.setFieldsValue({
      company: companyId
    });
  }, []);

  function getReasonNames() {
    setLoadingReasons(true);
    setIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/getReasons', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass')
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          const uniqueNames =
            response &&
            response.data.reduce((acc, obj) => {
              if (!acc[obj.reasons_name]) {
                acc[obj.reasons_name] = obj;
              }
              return acc;
            }, {});

          const uniqueArray = Object.values(uniqueNames);

          setReasonValue(uniqueArray);
          setLoadingReasons(false);
          setIsLoading(false);
        }
      });
  }
  function getResPArt(custId) {
    setLoadingLocations(true);
    axios
      .post(setting.serverUrl + '/api/Res', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          id: custId
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          let finalResult = response.data.map((val) => {
            return {
              ...val,
              name:
                val.name != '' ? (val.name.includes('|') ? val.name.split('|')[1] : val.name) : ''
            };
          });

          setRes(finalResult);
          setLoadingLocations(false);
          setBool(true);
          setHasPermission(true);
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setNoRecordMessage('Access permission not allowed.');
          setErrMsg('Access permission not allowed.');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
          setNoRecordFound(true);
        }
      });
  }
  const onChangeDate = (date, dateString) => {
    setDate(dateString);
  };

  function onChangeStartDate(startdate, startdateString) {
    setStartDate(startdateString);
  }
  function validatePhone(phone) {
    const pattern = /^\+?\d{1,3}[- ]?\d{2,3}[- ]?\d{5,}$/;
    return pattern.test(phone) && phone.replace(/\D/g, '').length <= 10;
  }
  function phoneValid(event) {
    const value = event.target.value;
    setPhoneValue(value);

    setIsValidPhone(validatePhone(value));
  }
  function handleEinValue(event) {
    setEinValue(event.target.value);
  }
  function handleDLValue(event) {
    setDrivingLicense(event.target.value);
  }
  function locationNew(event) {
    setlocationNew(event);
  }
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  function handleEmailValue(event) {
    const inputEmail = event.target.value;
    setEmailValue(inputEmail);
    setValidEmail(validateEmail(inputEmail));
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Get the selected file
  };


  function saveEmployee() {
    setIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/saveNewEmployeeInst', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          legalName: legalName,
          jobTitle: jobTitle,
          phoneValue: phoneValue,
          emailValue: emailValue,
          ssnValue: ssnValue,
          locationNewA: parseInt(locationNewA),
          comNew: parseInt(selectedCompany),
          dateNew: dateNew,
          startDate: startDate,
          bgScreening: bgScreening,
          typeScreen: typeScreen,
          specialInst: specialInst,
          address: address,
          reasons: reasons,
          service_multi_select: service_multi_select,
          emp_ein: emp_ein,
          DL: DL
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response && response.status == 200) {
          let id = response.data.id;
          let customerData = JSON.parse(localStorage.getItem('customerData'));
          customerData.employee_multi_select.push(id);
          localStorage.setItem('customerData', JSON.stringify(customerData));
          if (response.data.error == 'Technical Issue') {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
          } else {
            notification['success']({
              message: 'Request Sent Successfully !'
            });
            history.push({ pathname: `/vertical/employeeList` });
            setRes(response.data);
            setIsSaveButtonDisabled(true);
          }
        }
      });
  }




  function reasonSelection(event, value) {
    setSelectReason(event);
    getPackages(companyId, value);
  }
  function getPackages(companyId, value) {
    setLoadingPkgs(true);
    axios
      .post(setting.serverUrl + '/api/getNewEmpPkg', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyId
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          let result = response.data.rows;
          let nonNullResult = result.filter((x) => x.reasons != null);
          let filteredPkg = nonNullResult.filter(
            (key) => key.reasons && key.reasons.toLowerCase() == value.children.toLowerCase()
          );
          setPackageValue(filteredPkg);
          setLoadingPkgs(false);
        }
      });
  }
  return hasPermission && bool ? (
    <div style={{ padding: 20 }}>
      <div>
        <Button
          size='small'
          style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>

      <h5 style={{ textAlign: 'left', color: '#1C3A6A' }}>Add New Employee Instruction</h5>

      <Card style={{ background: 'white' }}>
        <Form
          layout='vertical'
          onFinish={() => saveEmployee()}
          form={form}
          onValuesChange={() => {
            form
              .validateFields(['legalName', 'locationNewA', 'phoneValue', 'emailValue'])
              .then(() => {
                setIsSaveButtonDisabled(false); // No errors, enable the Save button
              })
              .catch(() => {
                setIsSaveButtonDisabled(true); // Errors present, disable the Save button
              });
          }}
          style={{ backgroudColor: 'white' }}
        >
          <div style={{ padding: '20px' }}>
            <div className='row'>
              <div className='col-md-6'>
                <FormItem
                  label='Full Name'
                  name='Full Name'
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <Input
                    style={{ backgroundColor: 'white' }}
                    onChange={(event) => setLegalName(event.target.value)}
                    placeholder='Enter Full Name'
                    defaultValue={legalName}

                  />
                </FormItem>
              </div>

              <div className='col-md-6'>
                <FormItem
                  label='Employee Location'
                  name='Employee Location'
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <Select
                    placeholder='Select Location'
                    onChange={(event) => locationNew(event)}
                    loading={loadingLocations}
                    showSearch
                    optionFilterProp='children'
                    defaultValue={locationNewA}
                  >
                    {Res.length > 0 &&
                      Res.map((loc, index) => <Option value={loc.id}>{loc.name}</Option>)}
                  </Select>
                </FormItem>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <FormItem label='Start Date' name='Start Date'>
                  <DatePicker
                    style={{ width: '100%', padding: 10, borderRadius: 20 }}
                    placeholder='Enter Start Date'
                    onChange={(startdate, startdateString) =>
                      onChangeStartDate(startdate, startdateString)}
                    defaultValue={startDate !== 'Enter Start Date' ? moment(startDate) : null}
                  />
                </FormItem>
              </div>


              <div className='col-md-6'>
                <Form.Item label='DOB' name='DOB'>
                  <DatePicker
                    style={{ width: '100%', padding: 10, borderRadius: 20 }}
                    placeholder='Enter Date of Birth'
                    onChange={(date, dateString) => onChangeDate(date, dateString)}
                    // Use ternary operator to conditionally set the default value
                    defaultValue={dateNew !== 'Enter Date of Birth' ? moment(dateNew) : null}
                  />
                </Form.Item>
              </div>

            </div>

            <div className='row'>
              <div className='col-md-6'>
                <FormItem label='Job Title' name='Job Title'>
                  <Input
                    style={{ backgroundColor: 'white' }}
                    onChange={(event) => setJobTitle(event.target.value)}
                    placeholder='Enter Job Title'
                    defaultValue={jobTitle}
                  />
                </FormItem>
              </div>
              <div className='col-md-6'>
                <FormItem label='Email ' name='Email'>
                  <Input
                    style={{ backgroundColor: 'white' }}
                    onChange={(event) => handleEmailValue(event)}
                    placeholder='Enter Email'
                    defaultValue={emailValue}
                  />
                  {!validEmail && (
                    <p style={{ color: 'red' }}>Please enter a valid email address !</p>
                  )}
                </FormItem>
              </div>
            </div>

            <div className='row'>
              {typecheckValue && typecheckValue.length > 0
                ? typecheckValue.map((val) => {
                  return val == 'SSN' ? (
                    <div className='col-md-6'>
                      <FormItem label='SSN' name='SSN'>
                        <MaskedInput
                          mask='111-11-1111'
                          placeholder='Enter SSN _ _ _/_ _/_ _ _ _'
                          onChange={(event) => setSsnValue(event.target.value)}
                          style={{ backgroundColor: 'white' }}
                        />
                      </FormItem>
                    </div>
                  ) : val == 'EIN' ? (
                    <div className='col-md-6'>
                      <FormItem label='EIN' name='EIN'>
                        <Input
                          style={{ backgroundColor: 'white' }}
                          placeholder='Enter EIN'
                          onChange={(event) => handleEinValue(event)}
                          defaultValue={emp_ein}
                        />
                      </FormItem>
                    </div>
                  ) : val == 'Driver License' ? (
                    <div className='col-md-6'>
                      <FormItem label='Driver License' name='Driver License'>
                        <Input
                          style={{ backgroundColor: 'white' }}
                          placeholder='Enter Driver License'
                          onChange={(event) => handleDLValue(event)}
                        />
                      </FormItem>
                    </div>
                  ) : (
                    ''
                  );
                })
                : ''}
              <div className='col-md-6'>
                <FormItem label='Phone' name='Phone'>
                  <Input
                    style={{ backgroundColor: 'white' }}
                    placeholder='Enter Phone'
                    onChange={(event) => phoneValid(event)}
                    defaultValue={phoneValue}
                  />
                  {!isValidPhone && (
                    <div style={{ color: 'red' }}>
                      Please enter a valid phone number. It must contain 10 digits only!
                    </div>
                  )}
                </FormItem>
              </div>

              <div className='col-md-6'>
                <FormItem label='Reason'>
                  <Select
                    placeholder='Select Reason'
                    onChange={(event, value) => reasonSelection(event, value)}
                    rules={[{ required: true, message: 'This field is required.' }]}
                    loading={loadingReasons}
                    showSearch
                    optionFilterProp='children'
                  >
                    {reasonValue.length > 0 &&
                      reasonValue.map((key, index) => (
                        <Option value={key.id}>{key.reasons_name}</Option>
                      ))}
                  </Select>
                </FormItem>
              </div>

              <div className='col-md-6'>
                <FormItem label='Package' name='Package'>
                  <Select
                    placeholder='Select Package'
                    showSearch
                    optionFilterProp='children'
                    loading={loadingPkgs}
                    onChange={(event) => setSelectPackage(event)}
                  >
                    {packageValue.length > 0 &&
                      packageValue.map((key, index) => <Option value={key.id}>{key.name}</Option>)}
                  </Select>
                </FormItem>
              </div>

              <div className='col-md-6'>
                <FormItem label='Type of Screening to be completed'>
                  <Input
                    style={{ backgroundColor: 'white' }}
                    placeholder='Enter Type of Screening to be completed'
                    onChange={(event) => setTypeScreen(event.target.value)}
                  />
                </FormItem>
              </div>

              <div className='col-md-6'>
                <FormItem label='Background Screening' name='Background Screening'>
                  <Select
                    placeholder='Select Background Screening'
                    value={bgScreening}
                    onChange={(event) => setBgScreening(event)}
                  >
                    <Option value='Yes'>Yes</Option>
                    <Option value='No'>No</Option>
                  </Select>
                </FormItem>
              </div>
              <div className='col-md-6'>
                <FormItem label='Special Instructions'>
                  <TextArea
                    rows={3}
                    style={{ backgroundColor: 'white' }}
                    placeholder='Enter Special Instructions'
                    onChange={(event) => setSpecialInst(event.target.value)}
                  />
                </FormItem>
              </div>
              <div className='col-md-6'>
                <FormItem label='Address'>
                  <TextArea
                    rows={3}
                    // maxLength={4}
                    value={address}
                    style={{ backgroundColor: '#fff' }}
                    placeholder='Enter Address '
                    onChange={(event) => setAddress(event.target.value)}
                    defaultValue={address}
                  />
                </FormItem>
              </div>

              {data ? (
                <div className='col-md-6' style={{ marginBottom: '16px', width: '100%' }}>

                  <FormItem label='File Upload'>
                    <Input
                      type='file'
                      onChange={handleFileChange}
                      style={{ backgroundColor: '#fff' }}
                        
                    />



                  </FormItem>
                </div>
              ) : (
                '' // Render nothing if data is falsy
              )}


            </div>

            <center>
              <div>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#1b3969' }}
                  htmlType={'submit'}
                  disabled={isSaveButtonDisabled}
                  className='mt-4'
                >
                  Submit
                </Button>
                {isLoading && (
                  <Space size='middle' style={{ marginTop: 10, marginLeft: 10 }}>
                    <Spin size='large' />
                  </Space>
                )}
              </div>
            </center>
          </div>
        </Form>
      </Card>
    </div>
  ) : (
    <div>
      {errMsg.length > 0 ? (
        <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
          &nbsp;&nbsp; {errMsg}
        </p>
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default NewEmployeeInstruction;
