module.exports = {
    menuPermission: {
      CovidVaccinationDocs : 259,
      OccHealthDocs : 261,
      DrugAndAlcoholDocs : 260,
      CovidTestingDocs: 258,
      BackgroundScreeningDocs : 256,
      TestDetailDocs : 257,
      ProtocolDocs : 263
    }
  };
