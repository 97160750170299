import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import settings from '../../config/setting';
import { Spin, Space, Row, Col } from 'antd';
import moment from 'moment';
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import '@grapecity/wijmo.styles/wijmo.css';

let flexes = '';
let showGrid = '';

const AudiogramInfoLtEarTable = (id) => {
  console.log('props employee id', id);
  let empId = id && id;
  const [audiogramData, setAudiogramData] = useState([]);
  const [nofound, setNoFound] = useState(false);
  const [view, setView] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const theGrid = useRef();
  const setWijmoFlexGrid = (data) => {
    let views = new wijmo.CollectionView(data);
    setView(views);
    setViewIndex(views.pageIndex + 1);
    setViewCount(views.pageCount);
    setTimeout(() => {
      setPagginationNumRecord({
        currentRecord: document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
        totalRecord: data.length
      });
    }, 2000);
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    flexes = ctl;
    setListboxColumns(ctl.columns);
  };

  const getAudiogramLtData = () => {
    setNoFound(true);
    axios
      .post(settings.serverUrl + '/api/audiogramLeftEarDataCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          empId: empId?.id
        }
      })
      .then((response) => {
        console.log('audiogramLeftEarDataCustomerPortal: ', response.data.rows);

        let responseFinal = response.data.rows;
        let finalResult = responseFinal.map((k) => {
          return {
            ...k,
            left_date:
              k.left_date != false && k.left_date != null
                ? moment(k.left_date).format('MM/DD/YYYY HH:mm:ss')
                : ''
          };
        });
        setAudiogramData(finalResult);
        setWijmoFlexGrid(finalResult);
        setNoFound(false);
      });
  };

  useEffect(() => {
    getAudiogramLtData();
  }, []);

  const columns = [
    { header: 'Date', field: 'left_date', width: 200 },
    { header: '.5K', field: 'left_5k', width: '*' },
    { header: '1K', field: 'left_1k', width: '*' },
    { header: '2K', field: 'left_2k', width: '*' },
    { header: '3K', field: 'left_3k', width: '*' },
    { header: '4K', field: 'left_4k', width: '*' },
    { header: '6K', field: 'left_6k', width: '*' },
    { header: '8K', field: 'left_8k', width: '*' }
  ];

  return (
    <div>
      {!nofound ? (
        audiogramData.length > 0 ? (
          <>
            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              itemsSource={view}
              initialized={gridInitialized}
             // itemsPerPage={10}
              isReadOnly={true}
              style = {{height:"auto", maxHeight :"300px"}}
            >
              {columns.map((column) => (
                <FlexGridColumn
                  header={column.header}
                  binding={column.field}
                  width={column.width}
                />
              ))}
              <FlexGridFilter
                showFilterIcons={true}
                placeholder='Filter data...'
                defaultFilterType='Value'
                filterColumns={['left_date']}
              />
            </FlexGrid>
          </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>No Record Found !</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AudiogramInfoLtEarTable;
