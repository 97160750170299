import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import settings from '../../config/setting';
import { Spin, Space, Row, Col, Modal, Button, notification } from 'antd';
import moment from 'moment';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import '@grapecity/wijmo.styles/wijmo.css';
import LeftBone from './../../assets/img/leftBone.png';
import LeftBoneUp from './../../assets/img/leftBoneUp.png';
import LeftMaskedBoneUp from './../../assets/img/leftMaskedBoneUp.png';
import LeftMaskedAirDown from './../../assets/img/leftzMaskedAirDown.png';
import LeftMaskedAirUp from './../../assets/img/leftMaskedAirUp.png';
import RightAir from './../../assets/img/rightAir.png';
import RightMaskedAirDown from './../../assets/img/rightMaskedAirDown.png';
import RightMaskedBoneUp from './../../assets/img/rightMaskedBoneUp.png';
import LeftAirDown from './../../assets/img/leftAirDown.png';
import LeftAirUp from './../../assets/img/leftAirUp.png';
import LeftCurrent from './../../assets/img/leftCurrent.png';
import RightAirDown from './../../assets/img/rightAirDown.png';
import RightBoneDown from './../../assets/img/rightBoneDown.png';
import RightBoneUp from './../../assets/img/rightBoneUp.png';
import RightCurrent from './../../assets/img/rightCurrent.png';
import RightMaskedAirUp from './../../assets/img/rightMaskedAirUp.png';
import RightMaskedBoneDown from './../../assets/img/rightMaskedBoneDown.png';
import LeftMaskedBoneDown from './../../assets/img/leftMaskedBoneDown.png';
import BaselineEstab from './../../assets/img/baselineEstab.png';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { DownloadOutlined } from '@ant-design/icons';

let flexes = '';
let showGrid = '';

const LeftEarTestForm = ({ id }) => {
  const [audiogramRightData, setAudiogramRightData] = useState([]);
  const [rightNofound, setRightNoFound] = useState(false);
  const [rightView, setRightView] = useState();
  const [noGfound, setNoGFound] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [listboxColumns, setListboxColumns] = useState([]);
  const [infoVisible, setInfoVisible] = useState(false);
  const [infoRecord, setInfoRecord] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const theGrid = useRef();
  const theSearch = useRef();

  const filterRefCounter = useRef();

  useEffect(() => {
    if (location && location.state) {
      let props = location.state;
      // console.log('propsaudiogramlist===::: in form', props);
      getAudiogramRightHisData(location.state.emp_selected);
    } else {
      history.goBack();
    }
  }, []);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }
  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    flexes = ctl;
    setListboxColumns(ctl.columns);
    const sender = ctl;
    sender.select(-1, -1);
    sender.addEventListener(sender.hostElement, 'click', (e) => {
      let ht = sender.hitTest(e);
      if (ht._p._rng._row !== -1) {
        let selectedRows = sender.cells._rows[ht.row]._data;
        if (ht.col < 12) {
          //if (ht.col > 0 && ht.col < 5) {
          setInfoVisible(true);
          let testId = selectedRows.id;
          // Call the API and handle the response
          getAudiogramGraph(testId)
            .then((graphData) => {
              selectedRows.graphData = graphData;
              setInfoRecord(selectedRows);
            })
            .catch((error) => {
              console.error('Error in getAudiogramGraph:', error);
            });
        }
      }
    });
  };
  const getAudiogramGraph = (id) => {
    setNoGFound(true);
    return axios
      .post(settings.serverUrl + '/api/getAudiogramGraphData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          testId: id
        }
      })
      .then((response) => {
        let finalResult = response.data;
        setGraphData(finalResult);
        setNoGFound(false);
        return finalResult;
      })
      .catch((error) => {
        console.error('Error fetching audiogram graph data:', error);
        throw error;
      });
  };

  const downloadStsReport = (id) => {
    alert('i m id', id);

    axios
      .post(settings.serverUrl + '/api/getAudiogramStsReport', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          testId: id
        }
      })
      .then((response) => {
        let finalResult = response.data;

        if (
          finalResult &&
          finalResult.length > 0 &&
          finalResult[0].url &&
          finalResult[0].url !== ''
        ) {
          window.open(finalResult[0].url);
        } else {
          if (finalResult && finalResult.length === 0) {
            notification.error({
              message: 'No Data Found!'
            });
          } else {
            notification.error({
              message: 'Empty URL in response!'
            });
          }
        }
      })
      .catch((error) => {
        console.error('Error while fetching data:', error);
      });
  };
  const downloadStsChart = (id) => {
    axios
      .post(settings.serverUrl + '/api/getAudiogramStsChartReport', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          testId: id
        }
      })
      .then((response) => {
        let finalResult = response.data;

        if (
          finalResult &&
          finalResult.length > 0 &&
          finalResult[0].url &&
          finalResult[0].url !== ''
        ) {
          window.open(finalResult[0].url);
        } else {
          if (finalResult && finalResult.length === 0) {
            notification.error({
              message: 'No Data Found!'
            });
          } else {
            notification.error({
              message: 'Empty URL in response!'
            });
          }
        }
      })
      .catch((error) => {
        console.error('Error while fetching data:', error);
      });
  };
  const getAudiogramRightHisData = (id) => {
    setRightNoFound(true);
    axios
      .post(settings.serverUrl + '/api/getAudiogramRightHisDataByEmpId', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          empId: id
        }
      })
      .then((response) => {
        let finalResult = response.data;
        const uniqueIds = new Set();
        let testNameEmp = location.state.test_name;

        finalResult = response.data
          .filter((k) => {
            if (!uniqueIds.has(k.id)) {
              uniqueIds.add(k.id);
              return true;
            }
            return false;
          })
          .filter((k) => {
            return k.testname != null
            // && k.testname === testNameEmp;
          })
          .map((k) => {
            return {
              ...k,
              age: k.age != null ? k.age : '',
              testing_date:
                k.testing_date != null
                  ? k.testing_date && moment(k.testing_date).format('MM/DD/YYYY')
                  : '',
              testName: k.testname != null ? k.testname : '',
              is_first_record: k.is_first_record != null ? k.is_first_record : '',
              is_left_sts: k.is_left_sts != null ? k.is_left_sts : '',
              is_right_sts: k.is_right_sts != null ? k.is_right_sts : '',
              osha_recordable_left: k.osha_recordable_left != null ? k.osha_recordable_left : '',
              osha_recordable_right: k.osha_recordable_right != null ? k.osha_recordable_right : '',
              test_detail_id: k.test_detail_id != null && k.test_detail_id,
              testing_date_basline:
                k.testing_date_basline != null
                  ? k.testing_date_basline && moment(k.testing_date_basline).format('MM/DD/YYYY')
                  : '',
              is_baseline: k.is_baseline != null ? k.is_baseline : '',
              create_date: k.create_date != null ? moment(k.create_date).format('MM/DD/YYYY') : '',
              right_date:
                k.right_date != null
                  ? k.right_date && moment(k.right_date).format('MM/DD/YYYY')
                  : '',
              right_type: k.right_type && k.right_type,
              right_5k: k.right_5k != '' ? k.right_5k : '',
              right_1k: k.right_1k != '' ? k.right_1k : '',
              right_2k: k.right_2k != '' ? k.right_2k : '',
              right_3k: k.right_3k != '' ? k.right_3k : '',
              right_4k: k.right_4k != '' ? k.right_4k : '',
              right_6k: k.right_6k != '' ? k.right_6k : '',
              right_8k: k.right_8k != '' ? k.right_8k : '',
              right_ahl: k.right_ahl != '' ? k.right_ahl : '',
              right_sts: k.right_sts != '' ? k.right_sts : '',
              right_age_sts: k.right_age_sts != '' ? k.right_age_sts : '',
              right_osha: k.right_age_osha != '' ? k.right_age_osha : '',
              addendum_area: k.addendum_area != null ? k.addendum_area : '',
              medical_referral_left: k.medical_referral_left != null ? k.medical_referral_left : '',
              medical_referral_right:
                k.medical_referral_right != null ? k.medical_referral_right : '',
              asymmetric_right: k.asymmetric_right != null ? k.asymmetric_right : '',
              asymmetric_left: k.asymmetric_left != null ? k.asymmetric_left : '',
              audiologist_name: k.audiologist_name != null ? k.audiologist_name : '',
              abnormalLeftEar_25: k.abnormalLeftEar_25 != null ? k.abnormalLeftEar_25 : '',
              abnormalRightEar_25: k.abnormalRightEar_25 != null ? k.abnormalRightEar_25 : '',
              abnormalLeftEar: k.abnormalLeftEar != null ? k.abnormalLeftEar : '',
              abnormalRightEar: k.abnormalRightEar != null ? k.abnormalRightEar : '',
              normalRightEar: k.normalRightEar != null ? k.normalRightEar : '',
              normalLeftEar: k.normalLeftEar != null ? k.normalLeftEar : '',
              non_recordable_sts_left:
                k.non_recordable_sts_left != null ? k.non_recordable_sts_left : '',
              non_recordable_sts_right:
                k.non_recordable_sts_right != null ? k.non_recordable_sts_right : '',
              recordable_sts_right: k.recordable_sts_right != null ? k.recordable_sts_right : '',
              recordable_sts_left: (k.recordable_sts_left = null ? k.recordable_sts_left : ''),
              bright_5k: k.bright_5k != null ? k.bright_5k : '',
              bright_1k: k.bright_1k != null ? k.bright_1k : '',
              bright_2k: k.bright_2k != null ? k.bright_2k : '',
              bright_3k: k.bright_3k != null ? k.bright_3k : '',
              bright_4k: k.bright_4k != null ? k.bright_4k : '',
              bright_6k: k.bright_6k != null ? k.bright_6k : '',
              bright_8k: k.bright_8k != null ? k.bright_8k : '',
              bleft_5k: k.bleft_5k != null ? k.bleft_5k : '',
              bleft_1k: k.bleft_1k != null ? k.bleft_1k : '',
              bleft_2k: k.bleft_2k != null ? k.bleft_2k : '',
              bleft_3k: k.bleft_3k != null ? k.bleft_3k : '',
              bleft_4k: k.bleft_4k != null ? k.bleft_4k : '',
              bleft_6k: k.bleft_6k != null ? k.bleft_6k : '',
              bleft_8k: k.bleft_8k != null ? k.bleft_8k : '',
              left_5K: k.left_5k != '' ? k.left_5k : '',
              left_1K: k.left_1k != '' ? k.left_1k : '',
              left_2K: k.left_2k != '' ? k.left_2k : '',
              left_3K: k.left_3k != '' ? k.left_3k : '',
              left_4K: k.left_4k != '' ? k.left_4k : '',
              left_6K: k.left_6k != '' ? k.left_6k : '',
              left_8K: k.left_8k != '' ? k.left_8k : '',
              left_age_sts: k.left_age_sts != '' ? k.left_age_sts : '',
              left_osha: k.left_age_osha != '' ? k.left_age_osha : ''
            };
          });
        let sortedData = finalResult.sort(
          (a, b) => new Date(b.testing_date) - new Date(a.testing_date)
        );
        const collectionView2 = new CollectionView(sortedData, {
          pageSize: sortedData.length,
          collectionChanged: (s) => {
            filterRefCounter.current = s.totalItemCount;
            var filterCounter = document.getElementById('filterCounter');
            if (filterCounter) {
              filterCounter.textContent = s.totalItemCount;
            }
          }
        });
        setRightView(collectionView2);

        setAudiogramRightData(sortedData);
        setRightNoFound(false);
      });
  };
  const showBaseline = (item) => {
    return (
      <span>
        <input
          type='checkbox'
          checked={item.item.is_baseline != null && item.item.is_baseline != false}
          disabled
        />
      </span>
    );
  };
  const showSts = (item) => {
    return (
      <span>
        <input
          type='checkbox'
          checked={item.item.is_left_sts != null && item.item.is_left_sts != false}
          disabled
        />
      </span>
    );
  };
  const showStsAge = (item) => {
    return (
      <span>
        <input
          type='checkbox'
          checked={item.item.right_age_sts != null && item.item.right_age_sts != false}
          disabled
        />
      </span>
    );
  };
  const showOshaRec = (item) => {
    return (
      <span>
        <input
          type='checkbox'
          checked={
            item.item.osha_recordable_left != null && item.item.osha_recordable_left != false
          }
          disabled
        />
      </span>
    );
  };

  return (
    <div style={{ padding: 30 }}>
      {!rightNofound ? (
        audiogramRightData.length > 0 ? (
          <>
            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              className='audiogramLeft cliniclisttable'
              itemsSource={rightView}
              initialized={gridInitialized}
              //itemsPerPage={10}
              isReadOnly={true}
              style={{ height: 'auto', maxHeight: '300px', overflowX: 'auto' }}
            >
              <FlexGridColumn binding='testing_date' header='Test Date' width={100} />

              <FlexGridColumn binding='is_baseline' header='Baseline' width={92}>
                <FlexGridCellTemplate cellType='Cell' template={showBaseline} />
              </FlexGridColumn>
              <FlexGridColumn binding='age' header='Age' width={92}></FlexGridColumn>
              <FlexGridColumn
                binding='left_5k'
                header='500'
                width={105}
                wordWrap={true}
              ></FlexGridColumn>
              <FlexGridColumn binding='left_1k' header='1000' width={92} wordWrap={true} />
              <FlexGridColumn binding='left_2k' header='2000' width={92} />
              <FlexGridColumn binding='left_3k' header='3000' width={92} />
              <FlexGridColumn binding='left_4k' header='4000' width={92} />
              <FlexGridColumn binding='left_6k' header='6000' width={92} />
              <FlexGridColumn binding='left_8k' header='8000' width={92} />
              <FlexGridColumn binding='is_left_sts' header='STS' width={93.4}>
                <FlexGridCellTemplate cellType='Cell' template={showSts} />
              </FlexGridColumn>
              <FlexGridColumn binding='osha_recordable_left' header='OSHA' width={93.4}>
                <FlexGridCellTemplate cellType='Cell' template={showOshaRec} />
              </FlexGridColumn>
              <FlexGridFilter
                showFilterIcons={true}
                placeholder='Filter data...'
                defaultFilterType='Value'
                filterColumns={[
                  'testing_date',
                  'is_baseline',
                  'age',
                  'left_5k',
                  'left_1k',
                  'left_2k',
                  'left_3k',
                  'left_4k',
                  'left_6k',
                  'left_8k',
                  'is_left_sts',
                  'osha_recordable_left'
                ]}
              />
            </FlexGrid>
            <Modal
              width={1200}
              centered
              zIndex={1000}
              title={<h6>{`Test detail for ${infoRecord.testing_date}`}</h6>}
              visible={infoVisible}
              onOk={() => setInfoVisible(false)}
              onCancel={() => setInfoVisible(false)}
              footer={null}
            >
              <div style={{ margin: 10 }}>
                {(infoRecord.testName == 'STS Report' || infoRecord.is_left_sts || infoRecord.is_right_sts) && (
                  <div style={{ display: 'flex', direction: 'rtl' }}>
                    <Button
                      size='small'
                      style={{
                        backgroundColor: 'rgb(61 88 106)',
                        color: 'white',
                        marginTop: 0,
                        padding: 10,
                        marginRight: 5
                      }}
                      onClick={() => downloadStsChart(infoRecord.id)}
                      icon={<DownloadOutlined />}
                    >
                      STS Chart Report
                    </Button>
                    <Button
                      size='small'
                      style={{
                        backgroundColor: 'rgb(61 88 106)',
                        color: 'white',
                        marginTop: 0,
                        padding: 10,
                        marginRight: 5
                      }}
                      onClick={() => downloadStsReport(infoRecord.id)}
                      icon={<DownloadOutlined />}
                    >
                      STS Report
                    </Button>
                  </div>
                )}
                <span
                  style={{
                    color: 'rgb(195, 95, 78)',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <h6>Right Ear Results:</h6>
                </span>
                <table className='rightearresult' style={{ width: '100%' }}>
                  <thead style={{ backgroundColor: 'rgb(195,95,78)', color: 'white' }}>
                    <tr>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>
                        Frequency (Hz)
                      </th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>500</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>1000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>2000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>3000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>4000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>6000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>8000</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          color: 'rgb(195,95,78)',
                          fontWeight: 'bold'
                        }}
                      >
                        Air (dB HL)
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.right_5k}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.right_1k}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.right_2k}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.right_3k}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.right_4k}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.right_6k}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.right_8k}
                      </td>
                    </tr>
                    {infoRecord.testName == 'STS Report' && (
                      <tr>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          Baseline
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bright_5k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bright_1k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bright_2k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bright_3k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bright_4k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bright_6k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bright_8k}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {infoRecord.testName == 'STS Report' && (
                  <p>
                    Established Baseline(
                    {infoRecord.testing_date_basline})
                  </p>
                )}
                <br />
                <span
                  style={{
                    color: 'rgb(60,107,246)',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <h6>Left Ear Results:</h6>
                </span>

                <table className='rightearresult' style={{ width: '100%' }}>
                  <thead style={{ backgroundColor: 'rgb(60,107,246)', color: 'white' }}>
                    <tr>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>
                        Frequency (Hz)
                      </th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>500</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>1000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>2000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>3000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>4000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>6000</th>
                      <th style={{ textAlign: 'center', border: '1px solid grey' }}>8000</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          color: 'rgb(60,107,246)',
                          fontWeight: 'bold'
                        }}
                      >
                        Air (dB HL)
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.left_5K}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.left_1K}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.left_2K}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.left_3K}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.left_4K}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.left_6K}
                      </td>
                      <td
                        style={{
                          border: '1px solid grey',
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {infoRecord.left_8K}
                      </td>
                    </tr>
                    {infoRecord.testName == 'STS Report' && (
                      <tr>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          Baseline
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bleft_5k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bleft_1k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bleft_2k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bleft_3k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bleft_4k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bleft_6k}
                        </td>
                        <td
                          style={{
                            border: '1px solid grey',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {infoRecord.bleft_8k}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {infoRecord.testName == 'STS Report' && (
                  <p>
                    Established Baseline(
                    {infoRecord.testing_date_basline})
                  </p>
                )}
              </div>
              <br />
              <div>
                {!noGfound ? (
                  infoRecord?.graphData?.length > 0 ? (
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
                    >
                      <div
                        style={{
                          position: 'relative',
                          maxWidth: '50%',
                          flex: '0 0 50%',
                          marginBottom: '20px'
                        }}
                      >
                        <span
                          style={{
                            position: 'absolute',
                            top: '5px',
                            left: '5px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: 'rgb(60,107,246)',
                            padding: '5px',
                            borderRadius: '5px'
                          }}
                        >
                          Left Ear Graph:
                        </span>
                        <br />
                        <img
                          src={infoRecord.graphData.find((item) => item.name.includes('left')).url}
                          alt='Left Ear Graph'
                          style={{ width: '100%', height: 'auto', marginRight: 10 }}
                        />
                        <table
                          style={{
                            width: '100%',
                            margin: '10px 0px 0px -10px',
                            textAlign: 'center'
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: 'right' }}>Right &nbsp;&nbsp;</td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={LeftAirUp}
                                  alt='Left Air'
                                  style={{ height: '12px', width: '12px' }}
                                />
                                &nbsp;&nbsp;Air
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={LeftMaskedAirUp}
                                  alt='Left Bone'
                                  style={{ height: '14px', width: '14px' }}
                                />
                                &nbsp;&nbsp;Masked Air
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={LeftBoneUp}
                                  alt='Left Bone'
                                  style={{ height: '12px', width: '10px' }}
                                />
                                &nbsp;&nbsp;Bone
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={LeftMaskedBoneUp}
                                  alt='Left Bone'
                                  style={{ height: '12px', width: '8px' }}
                                />
                                &nbsp;&nbsp;Masked Bone
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>No Response&nbsp;&nbsp;</td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={LeftAirDown}
                                  alt='Left Air'
                                  style={{ height: '15px', width: '15px' }}
                                />
                                &nbsp;Air
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={LeftMaskedAirDown}
                                  alt='Left Bone'
                                  style={{ height: '15px', width: '15px' }}
                                />
                                &nbsp;Masked Air
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={LeftBone}
                                  alt='Left Bone'
                                  style={{ height: '25px', width: '20px' }}
                                />
                                &nbsp;Bone
                              </td>

                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={LeftMaskedBoneDown}
                                  alt='Left Bone'
                                  style={{ height: '15px', width: '15px' }}
                                />
                                &nbsp;Masked Bone
                              </td>
                            </tr>
                            <tr>
                              <td colspan={2}>
                                <img
                                  src={LeftCurrent}
                                  alt='Left Bone'
                                  style={{ height: '8px', width: '40px' }}
                                />
                                &nbsp;Current
                              </td>
                              <td></td>
                              <td colspan={2}>
                                <img
                                  src={BaselineEstab}
                                  alt='Left Bone'
                                  style={{ height: '8px', width: '40px' }}
                                />
                                &nbsp; Established Baseline ({infoRecord.testing_date_basline})
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          maxWidth: '50%',
                          flex: '0 0 50%',
                          marginBottom: '20px'
                        }}
                      >
                        <span
                          style={{
                            position: 'absolute',
                            top: '5px',
                            left: '5px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: 'rgb(195,95,78)',
                            padding: '5px',
                            borderRadius: '5px'
                          }}
                        >
                          Right Ear Graph:
                        </span>
                        <br />
                        <img
                          src={infoRecord.graphData.find((item) => item.name.includes('right')).url}
                          alt='Right Ear Graph'
                          style={{ width: '100%', height: 'auto', marginRight: 10 }}
                        />
                        <table
                          style={{
                            width: '100%',
                            margin: '10px 0px 0px -10px',
                            textAlign: 'center'
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: 'right' }}>Right &nbsp;&nbsp;</td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={RightAir}
                                  alt='Right Air'
                                  style={{ height: '12px', width: '12px' }}
                                />
                                &nbsp;&nbsp;Air
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={RightMaskedAirUp}
                                  alt='Right Air'
                                  style={{ height: '12px', width: '12px' }}
                                />
                                &nbsp;&nbsp;Masked Air
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={RightBoneUp}
                                  alt='Right Air'
                                  style={{ height: '12px', width: '12px' }}
                                />
                                &nbsp;&nbsp;Bone
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={RightMaskedBoneUp}
                                  alt='Right Air'
                                  style={{ height: '12px', width: '12px' }}
                                />
                                &nbsp;&nbsp;Masked Bone
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>No Response&nbsp;&nbsp;</td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={RightAirDown}
                                  alt='Right Air'
                                  style={{ height: '15px', width: '15px' }}
                                />
                                &nbsp;Air
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={RightMaskedAirDown}
                                  alt='Right Air'
                                  style={{ height: '15px', width: '15px' }}
                                />
                                &nbsp;Masked Air
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={RightBoneDown}
                                  alt='Right Air'
                                  style={{ height: '25px', width: '20px' }}
                                />
                                &nbsp;Bone
                              </td>
                              <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                                <img
                                  src={RightMaskedBoneDown}
                                  alt='Right Air'
                                  style={{ height: '15px', width: '15px' }}
                                />
                                &nbsp;Masked Bone
                              </td>
                            </tr>
                            <tr>
                              <td colspan={2}>
                                <img
                                  src={RightCurrent}
                                  alt='Right Air'
                                  style={{ height: '8px', width: '40px' }}
                                />
                                &nbsp;Current
                              </td>
                              <td></td>
                              <td colspan={2}>
                                <img
                                  src={BaselineEstab}
                                  alt='Right Air'
                                  style={{ height: '8px', width: '40px' }}
                                />
                                &nbsp; Established Baseline ({infoRecord.testing_date_basline})
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <h5 style={{ color: '#ef6727', textAlign: 'center' }}>No Graphs Available !</h5>
                  )
                ) : (
                  <Row>
                    <Col span={10}></Col>
                    <Col span={4}>
                      <Space size='middle' style={{ marginTop: 15, marginLeft:85 }}>
                        <Spin size='large' />
                      </Space>
                    </Col>
                    <Col span={10}></Col>
                  </Row>
                )}
              </div>
            </Modal>
          </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>No Record Found !</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 15, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LeftEarTestForm;
