import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form, Input, Space, Spin, Row, Col } from 'antd';
import { LoginOutlined, QuestionCircleOutlined } from '@ant-design/icons/lib';
import setting from '../../config/setting';
import PublicLayout from '../../layout/public/Public';
import { useForm } from 'antd/es/form/Form';
import { history } from '../../redux/store';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Item } = Form;

const SignIn = () => {
  const [name, setName] = useState('');
  const [pass, setPass] = useState('');
  const [loader, setLoader] = useState(false);
  const [invalidCredential, setInvalidCredential] = useState('');
  const [hover, setHover] = useState(false);
  const [enableLogin, setEnableLogin] = useState(false)

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };
  useEffect(() => {
    // getData();
  }, []);

  async function getData() {
    console.log('name', name);
    console.log('PASS', pass);
    setEnableLogin(true)
    if (name && pass) {
      setInvalidCredential('');
      setLoader(true);
      console.log('getData users :: ');
      await axios
        .post(setting.serverUrl + '/api/users', {
          // axios.get('http://localhost:3001/api/users',{
          params: { user: name, pass: pass }
        })
        .then(async (response) => {
          console.log('Respone from getData users', response);
          if (response && response.data == 'Done') {
            // setAllData(response.data);
            console.log('connected user');
            setEnableLogin(true)
            history.push({ pathname: '/public/OTPlogin', state: { email: name, pass: pass } });
          } else if (
            response &&
            response.data &&
            response.data.error &&
            response.data.error == 'Access Denied'
          ) {
            setEnableLogin(false)
            setInvalidCredential(
              "Access Denied! You don't currently have permission to access customer portal. For more detail please contact to admin."
            );
            console.log('Access Denied');
            setLoader(false);
          } else {
            if (name == '' && pass == '') {
              // alert("Please enter user name and password!");
              setInvalidCredential('Please enter valid username and password!');
              console.log('Not connected user');
              setLoader(false);
              setEnableLogin(false)
            } else if (name == '') {
              // alert("Please enter valid user name!");
              setInvalidCredential('Please enter valid username!');
              console.log('Not connected user');
              setLoader(false);
              setEnableLogin(false)
            } else if (pass == '') {
              // alert("Please enter valid password!");
              setInvalidCredential('Please enter valid password!');
              console.log('Not connected user');
              setLoader(false);
              setEnableLogin(false)
            } else {
              // alert("Please enter valid usermail or password!");
              setInvalidCredential('Invalid usermail or password!');
              setEnableLogin(false)
              setLoader(false);
              
            }

            //setErrorMsg('Not found');
          }
        });
    } else {
      setInvalidCredential('Please enter username and password!');
      setEnableLogin(false)
    }
  }

  // let smsId = await sendSMS("+18326873021",msg)

  function userName(event) {
    console.log('event name', event.target.value.toLowerCase());
    setName(event.target.value.toLowerCase());
  }

  function password(event) {
    console.log('event pass', event.target.value);
    setPass(event.target.value);
  }

  const [form] = useForm();

  function login() {
    getData();
  }
  function forgotScreen() {
    history.push({ pathname: '/public/forgot-password', state: {} });
  }

  return (
    <>
      <Row
        style={{
          height: '100%',
          backgroundImage: `url(${require('./newBgPlane.jpg')})`,
          backgroundSize: 'cover'
        }}
      >
        <Col
          xl={{ span: 7 }}
          lg={{ span: 9 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ height: '100%' }}
        >
          <div>
            <PublicLayout
            // bgImg={require('./wss.png')}
            >
              <h4 className='mt-0 mb-1'>WSS Login</h4>

              <p
                style={{ color: '#161717', paddingBottom: 0, marginBottom: 10 }}
                //className='text-color-200'
              >
                Login to access your account
              </p>
              {invalidCredential !== '' ? (
                <span style={{ color: 'red', marginBottom: 10 }}>{invalidCredential}</span>
              ) : null}
              <Form form={form} layout='vertical' className='mb-4'>
                <Item name='login' rules={[{ required: true, message: <></> }]}>
                  <Input placeholder='Email' onChange={userName}   style={{ backgroundColor: 'white',  WebkitBoxShadow: "0 0 0 1000px white inset !important "}}/>
                </Item>
                <Item name='password' rules={[{ required: true, message: <></> }]} 
                >
                  <Input.Password placeholder='Password' type='password' onChange={password} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                    style={{ backgroundColor: 'white',  WebkitBoxShadow: "0 0 0 1000px white inset !important "}}
                    />
                </Item>
                {/* <div className='d-flex align-items-center mb-4'>
                 
                  <Switch defaultChecked /> <span className='ml-2'>Remember me</span>
                </div> */}
                <Button
                  block={false}
                  type='primary'
                  onClick={login}
                  htmlType='submit'
                  disabled={enableLogin}
                  icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
                >
                  Login
                </Button>
                &nbsp;&nbsp;&nbsp;
                {
                  loader && (
                    // <Row>
                    // <Col span={10}>
                    // </Col>
                    // <Col span={8}>
                    <Space
                      size='middle'
                      // style={{ marginTop: 10 }}
                    >
                      <Spin size='large' />
                    </Space>
                  )
                  //   </Col>
                  // </Row>
                }
                <br />
                <span
                  style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    color: hover ? '#f62' : '#247'
                  }}
                  onClick={forgotScreen}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <h6>
                    Forgot Password <QuestionCircleOutlined style={{ fontSize: '1.3rem' }} />
                  </h6>
                </span>
                <div className='d-flex align-items-center mb-4'>
                  {/* <Button
                    block={false}
                    type='primary'
                    onClick={forgotScreen}
                    htmlType='submit'
                    //icon={<QuestionCircleOutlined style={{ fontSize: '1.3rem' }} />}
                  >
                    Forgot Password
                    <QuestionCircleOutlined style={{ fontSize: '1.3rem' }} />
                  </Button> */}
                </div>
              </Form>
              <br />
            </PublicLayout>
          </div>
        </Col>
        <Col
          xl={{ span: 17 }}
          lg={{ span: 15 }}
          md={{ span: 12 }}
          sm={{ span: 0 }}
          xs={{ span: 0 }}
          //style={{}}
        >
          <h3
            style={{
              color: 'white',
              position: 'absolute',
              top: '48%',
              left: '0px',
              width: '100%',
              display: 'table',
              textAlign: 'center'
            }}
          >
            INCREASING SAFETY AND PRODUCTIVITY, REDUCING LIABILITY.™
          </h3>
        </Col>
      </Row>
    </>
  );
  // }
};

export default SignIn;
