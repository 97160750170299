import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { IMenuItemIcon } from '../../../interfaces/main-menu';
import getImageByKey from './MenuImages';

type Props = {
  title: string;
  routing: string;
  layout: string;
  icon: IMenuItemIcon;
  image: string;
  isActive: boolean;
};

const SimpleItem = ({ routing, title, layout, icon, image, isActive }: Props) => {
  const [active, setActive] = useState(isActive)
  useEffect(() => {
    
  }, [])
  return <li className='menu-item'>
    <NavLink
      // className='item-link'
      className={active || isActive ? 'item-link active' : "item-link"}
      to={`/${layout}/${routing}`}
      activeClassName={active || isActive ? 'active' : ""}
      replace
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
    >
      {/* {icon && (
        <span
          className={`link-icon ${icon.class}`}
          style={{ backgroundColor: icon.bg, color: icon.color }}
        />
      )} */}
      {image && (
        <span>
          <img src={getImageByKey(active||isActive ? image + "_Active" : image)} style={{ height: 15, width: 15, margin: 8 }} />
        </span>
      )}

      <span className='link-text'>{title}</span>
    </NavLink>
  </li>;
};

export default SimpleItem;
