import dashboardIcon_Active from '../../../assets/img/dashboardIconOrange.png';
import dashboardIcon from '../../../assets/img/dashboardIconBlu.png';
import notificationIcon from '../../../assets/img/02-icon-notifications.png';
import scheduleEventIcon from '../../../assets/img/03-icon-schedule-event.png';
import openedEventIcon from '../../../assets/img/opened-events.png';
import openedEventIcon_Active from '../../../assets/img/opened-events-orange.png';
import scheduleEventIcon_Active from '../../../assets/img/03-icon-schedule-event-orange.png';
import employeeIcon from '../../../assets/img/Icon metro-user.png';
import employeeIcon_Active from '../../../assets/img/Icon metro-user.png';
import locationIcon from '../../../assets/img/location.png';
import collectionSiteIcon from '../../../assets/img/06-Icon-collection-sites.png';
import drugAlcoholTestingIcon from '../../../assets/img/drug-alcohol.png';
import trainingIcon from '../../../assets/img/09-icon-training.png';
import covidTestingIcon from '../../../assets/img/11-icon-COVID.png';
import policyIcon from '../../../assets/img/15-icon-policies.png';
import policyIcon_Active from '../../../assets/img/15-icon-policies-orange.png';
import randomTestingPoolIcon from '../../../assets/img/05-Icon-random-testing-pools.png';
import randomTestingPoolIcon_Active from '../../../assets/img/05-Icon-random-testing-pools-orange.png';
import backgroundScreenIcon from '../../../assets/img/07-icon-background-screening.png';
import backgroundScreenIcon_Active from '../../../assets/img/07-icon-background-screening-orange.png';
import eapProgramIcon from '../../../assets/img/10-icon-EAP-program.png';
import eapProgramIcon_Active from '../../../assets/img/10-icon-EAP-program-orange.png';
import orderSupplyIcon from '../../../assets/img/12-icon-order-supplies.png';
import orderSupplyIcon_Active from '../../../assets/img/12-icon-order-supplies-orange.png';
import invoicesIcon from '../../../assets/img/13-icon-invoices.png';
import invoicesIcon_Active from '../../../assets/img/13-icon-invoices-orange.png';
import companyInformationIcon from '../../../assets/img/14-icon-company-info.png';
import companyInformationIcon_Active from '../../../assets/img/14-icon-company-info-orange.png';
import notificationIcon_Active from '../../../assets/img/02-icon-notifications-orange.png';
import locationIcon_Active from '../../../assets/img/location-orange.png';
import collectionSiteIcon_Active from '../../../assets/img/06-Icon-collection-sites-orange.png';
import drugAlcoholTestingIcon_Active from '../../../assets/img/drug-alcohol-orange.png';
import covidComplianceIcon from '../../../assets/img/employees-tested.png';
import covidComplianceIcon_Active from '../../../assets/img/employees-tested-orange.png';
import newLogoIcon from '../../../assets/img/logonew.png';
import cactusIcon_Active from '../../../assets/img/cactus-logo.png';
import covidVaccinationIcon from '../../../assets/img/Covid-vaccination.png';
import trainingIcon_Active from '../../../assets/img/09-icon-training-orange.png';
import covidTestingIcon_Active from '../../../assets/img/11-icon-COVID-orange.png';
import covidVaccinationIcon_Active from '../../../assets/img/covidVaccOrange.png';
import medicalSurvellianceIcon from '../../../assets/img/08-icon-medical-surveillance.png';
import driverQualifIcon from '../../../assets/img/driverIcon.png';
import driverQualifIcon_Active from '../../../assets/img/driverOrange.png';
import medicalSurvellianceIcon_Active from '../../../assets/img/08-icon-medical-surveillance-orange.png';
import clinicIcon from '../../../assets/img/06-Icon-collection-sites.png';
import clinicIcon_Active from '../../../assets/img/06-Icon-collection-sites-orange.png';
import add_icon_1 from '../../../assets/img/01_addicon_Bluee.png';
import add_icon_1_Active from '../../../assets/img/01_addicon_Orange-1.png';
import checkIn from "../../../assets/img/Icon opened-forms.png";
const images = {
  checkIn,
  driverQualifIcon,
  driverQualifIcon_Active,
  dashboardIcon_Active,
  dashboardIcon,
  notificationIcon_Active,
  scheduleEventIcon_Active,
  dashboardIcon,
  notificationIcon,
  scheduleEventIcon,
  employeeIcon,
  employeeIcon_Active,
  locationIcon,
  locationIcon_Active,
  collectionSiteIcon,
  collectionSiteIcon_Active,
  drugAlcoholTestingIcon,
  drugAlcoholTestingIcon_Active,
  trainingIcon_Active,
  trainingIcon,
  covidTestingIcon,
  covidTestingIcon_Active,
  covidVaccinationIcon,
  covidVaccinationIcon_Active,
  policyIcon,
  covidComplianceIcon,
  policyIcon_Active,
  randomTestingPoolIcon,
  randomTestingPoolIcon_Active,
  backgroundScreenIcon,
  backgroundScreenIcon_Active,
  medicalSurvellianceIcon,
  medicalSurvellianceIcon_Active,
  eapProgramIcon,
  eapProgramIcon_Active,
  orderSupplyIcon,
  orderSupplyIcon_Active,
  invoicesIcon,
  invoicesIcon_Active,
  companyInformationIcon,
  companyInformationIcon_Active,
  cactusIcon_Active,
  newLogoIcon,
  covidComplianceIcon_Active,
  openedEventIcon,
  openedEventIcon,
  clinicIcon,
  clinicIcon_Active,
  add_icon_1,
  add_icon_1_Active
  //formIcon,
  //formIcon_Active
};

function getImageByKey(key) {
  return images[key]
}

export default getImageByKey