import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  Space,
  Row,
  Col,
  Input,
  Form,
  Select,
  Card,
  Modal,
  Table,
  Button,
  notification
} from 'antd';
import moment from 'moment';
import * as wjcCore from '@grapecity/wijmo';
import * as grid from '@grapecity/wijmo.grid';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import * as wjFilter from '@grapecity/wijmo.react.grid.filter';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FolderOpenTwoTone } from '@ant-design/icons/lib';

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'Attachments.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const DrugAlcoholTab = ({ data, hasAttachmentPermission, downloadAttachment }) => {
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [pagging, setPagging] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [pageApply, setPageApply] = useState(false);
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const [response, setResponse] = useState([]);
  const filterRefCounter = useRef(null);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    console.log('drug alcohol table data', data);
    // setWijmoFlexGrid(data, 10);
    setResponse(data);
  }, []);

  useEffect(() => {
    if (response.length > 0) {
      const collectionView1 = new CollectionView(response, {
        pageSize: response.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(response, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      setView(collectionView2);
    }
  }, [response, pageSize]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  // const paggination = (value) => {
  //   console.log('page value', value);
  //   setItemPerPage(value);
  //   setPageApply(true);
  //   // setPagging(value);
  //   // this.setState({ pagging: e.target.value})
  //   setTimeout(() => {
  //     setWijmoFlexGrid(data, value);
  //   }, 500);
  // };

  // const setWijmoFlexGrid = (data, page) => {
  //   console.log('pagging', data, page);
  //   let views = new wijmo.CollectionView(data, { pageSize: Number(page) });
  //   let viewsData = new wijmo.CollectionView(data, { pageSize: Number(data && data.length) });
  //   console.log('views', views);
  //   setView(views);
  //   setViewData(viewsData);
  //   setViewIndex(views.pageIndex + 1);
  //   setViewCount(views.pageCount);
  //   //pagination records
  //   setTimeout(() => {
  //     setPagginationNumRecord({
  //       currentRecord: document.querySelectorAll('.attachmenttab .wj-cells .wj-row').length - 1,
  //       totalRecord: data.length
  //     });
  //   }, 2000);
  // };

  // function _createItemsSource(counter) {
  //   // const data = this.props.dataService.getData(counter);
  //   const view = new wjCore.CollectionView(table, {
  //       getError: (item, prop) => {
  //           const displayName = flexes.columns.getColumn(prop).header;
  //           // return this.props.dataService.validate(item, prop, displayName);
  //       }
  //   });
  //   view.collectionChanged.addHandler((s, e) => {
  //     // initializes new added item with a history data
  //     if (e.action === wjCore.NotifyCollectionChangedAction.Add) {
  //         e.item.history = this.props.dataService.getHistoryData();
  //         e.item.id = this._lastId;
  //         this._lastId++;
  //     }
  // });
  // return view;
  // }

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    // var self = this;
    // console.log("inside formatitem",e.panel)
    // console.log("sender.topLeftCells",sender.topLeftCells)
    // console.log("aaaaaaaaaaa",sender.topLeftCells===e.panel)
    // const vaccinations_filter = vaccineTable.map((value)=>{
    //   return value.vaccination.map((v, i) => (
    //       v.vacData
    //   ))
    // })
    // console.log("create_date_filter",vaccinations_filter)

    // let filterRatings = filter.getColumnFilter("vaccination");
    // filterRatings.valueFilter.uniqueValues = vaccinations_filter;

    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    // console.log('filter filter', filter);

    // var filter = new wjcGridFilter.FlexGridFilter(flexes);
    // console.log('filter', filter);
    // filter.getColumnFilter('create_date').valueFilter.maxValues = 3000;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showName = (item) => {
    return <span className='nowrap'>{item.item.name ? item.item.name : ''}</span>;
  };

  const showDoc = (item) => {
    return (
      <span className='nowrap'>
        {hasAttachmentPermission && item.item.class_id ? (
          // <button
          //   onClick={(e) => downloadAttachment(e, item.item.url)}
          //   style={{
          //     border: 'none',
          //     textAlign: 'center',
          //     borderRadius: 5,
          //     backgroundColor: '#abc'
          //   }}
          // >
          //   Preview
          // </button>
          // <p style={{color:'blue'}}>Preview
          <FolderOpenTwoTone
            twoToneColor='#cc7a00'
            style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
            onClick={(e) => downloadAttachment(e, item.item.url)}
          />
          // </p>
        ) : (
          item.item.class_id && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
        )}
      </span>
    );
  };

  // const updateViewPager = (target) => {
  //   console.log('target', target);
  //   let btn = wijmo.closest(target, 'button'),
  //     id = btn ? btn.id : '';
  //   switch (id) {
  //     case 'btnFirst':
  //       i = 1;
  //       view.moveToFirstPage();
  //       setTimeout(() => {
  //         setPagginationNumRecord({
  //           currentRecord: document.querySelectorAll('.drugtab .wj-cells .wj-row').length - 1,
  //           totalRecord: data.length
  //         });
  //       }, 1000);
  //       break;
  //     case 'btnPrev':
  //       if (i <= 1) {
  //         i = 1;
  //       } else {
  //         var a = Math.ceil(data.length / itemsPerPage);
  //         view.moveToPreviousPage();
  //         if (a === i) {
  //           setPagginationNumRecord({
  //             currentRecord:
  //               data.length - (document.querySelectorAll('.drugtab .wj-cells .wj-row').length - 1),
  //             totalRecord: data.length
  //           });
  //         } else {
  //           setPagginationNumRecord({
  //             currentRecord: pagginationNumRecord.currentRecord - itemsPerPage,
  //             totalRecord: data.length
  //           });
  //         }
  //         i--;
  //       }
  //       break;
  //     case 'btnNext':
  //       if (pageApply) {
  //         if (data.length === document.querySelectorAll('.drugtab .wj-cells .wj-row').length - 1) {
  //         } else {
  //           i = 2;
  //         }
  //         setPageApply(false);
  //       } else {
  //         if (i >= Math.ceil(data.length / itemsPerPage)) {
  //         } else {
  //           i++;
  //         }
  //       }
  //       var a = Math.ceil(data.length / itemsPerPage);
  //       if (a === i) {
  //         setPagginationNumRecord({
  //           currentRecord: data.length,
  //           totalRecord: data.length
  //         });
  //       } else {
  //         setPagginationNumRecord({
  //           currentRecord: (document.querySelectorAll('.drugtab .wj-cells .wj-row').length - 1) * i,
  //           totalRecord: data.length
  //         });
  //       }
  //       view.moveToNextPage();
  //       break;
  //     case 'btnLast':
  //       i = Math.ceil(data.length / itemsPerPage);
  //       view.moveToLastPage();
  //       setPagginationNumRecord({
  //         currentRecord: data.length,
  //         totalRecord: data.length
  //       });
  //       break;
  //   }
  //   setViewIndex(view.pageIndex + 1);
  //   setViewCount(view.pageCount);
  // };

  return (
    <div>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={10}>
          <FlexGridSearch
            ref={theSearch}
            placeholder='Search'
            cssMatch=''
            style={{ width: '70%', height: '40px' }}
          />
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
          <div ref={fixedContainerRef}>
            {/* <p style={{ marginTop: '10px' }}>Items per page:</p> */}
            {/* <label for="range">Items per page:</label> */}
            {/* <select 
                            style={{width:'100px',borderRadius:0}}
                            options={[
                              { label: 10, value: 10 },
                              { label: 20, value: 20 },
                              { label: 50, value: 50 }
                            ]}
                          /> */}
            {/* <select name="range" id='range' value={pagging} onChange={paggination} >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select> */}

            {/* <Select
              // showSearch
              style={{ width: 160 }}
              placeholder={`Items per page: ${pagging}`}
              // optionFilterProp='children'
              onChange={paggination}
              options={[
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
              ]}
              getPopupContainer={() => fixedContainerRef.current}
            /> */}
            <Select
              style={{ width: 170 }}
              placeholder={`Items per page : ${pageSize}`}
              onChange={paggination}
              options={[
                { label: 'Items per page : 10', value: 10 },
                { label: 'Items per page : 20', value: 20 },
                { label: 'Items per page : 50', value: 50 }
              ]}
              getPopupContainer={() => fixedContainerRef.current}
            />
          </div>
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={10}>
          <Button
            type='primary'
            size='small'
            style={{ backgroundColor: '#3d586a', float: 'right' }}
            disabled={isExcelPreparing}
            onClick={exportToExcel}
          >
            {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
          </Button>
        </Col>
      </Row>

      {/* to export entire data of table */}
      <FlexGrid
        ref={theGrid}
        style={{ display: 'none' }}
        className='dummyWijmoData'
        itemsSource={viewData}
        initialized={gridInitializedData}
        formatItem={formatItem}
        isReadOnly={true}
      >
        <FlexGridColumn binding='name' header='Name' width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showName} />
        </FlexGridColumn>
      </FlexGrid>

      <FlexGrid
        // autoRowHeights={true}
        autoGenerateColumns={false}
        ref={theGrid}
        id='theGridTallRows'
        className='attachmenttab cliniclisttable'
        alternatingRowStep={1}
        itemsSource={view}
        initialized={gridInitialized}
        formatItem={formatItem}
        itemsPerPage={10}
        isReadOnly={true}
      >
        <div style={{ display: 'none' }}>
          <wjInput.ListBox
            className='column-picker'
            itemsSource={listboxColumns}
            checkedMemberPath='visible'
            displayMemberPath='header'
            lostFocus={hidePicker}
            initialized={initializedListBox}
          ></wjInput.ListBox>
        </div>
        <FlexGridColumn binding='name' header='Name' width={1080}>
          <FlexGridCellTemplate cellType='Cell' template={showName} />
        </FlexGridColumn>
        <FlexGridColumn binding='url' header='Document' width={97}>
          <FlexGridCellTemplate cellType='Cell' template={showDoc} />
        </FlexGridColumn>

        <FlexGridFilter filterColumns={['name']} />
      </FlexGrid>

      {/* <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          onClick={(e) => updateViewPager(e.target)}
          style={{
            backgroundColor: '#425a81',
            color: '#fff',
            display: 'inline',
            borderRadius: '5px',
            // paddingTop: '4px',
            height: '30px'
          }}
        >
          <button
            id='btnFirst'
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
            className='btn'
          >
            <span className='wj-glyph-step-backward'></span>
          </button>
          <button
            id='btnPrev'
            className='btn'
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
          >
            <span className='wj-glyph-left'></span>
          </button>
          <p style={{ display: 'inline', fontSize: '13px' }}>
            &nbsp;&nbsp;&nbsp;Page {viewIndex} of {viewCount}&nbsp;&nbsp;&nbsp;
          </p>
          <button
            id='btnNext'
            className='btn'
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
          >
            <span className='wj-glyph-right'></span>
          </button>
          <button
            id='btnLast'
            className='btn'
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
          >
            <span className='wj-glyph-step-forward'></span>
          </button>
        </div>
        {pagginationNumRecord.currentRecord !== undefined ? (
          <p style={{ fontWeight: 'bold', fontSize: '15px' }}>
            {' '}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {`${pagginationNumRecord.currentRecord} out of ${pagginationNumRecord.totalRecord}`}
          </p>
        ) : (
          ''
        )}
      </div> */}
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div
          style={{
            backgroundColor: '#425a81',
            color: '#fff',
            display: 'inline',
            borderRadius: '5px',
            height: '30px'
          }}
        >
          <CollectionViewNavigator
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
            headerFormat='Page {currentPage:n0} of {pageCount:n0}'
            byPage={true}
            cv={view}
          />
        </div>
        &nbsp;&nbsp;&nbsp;
        <p
          style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}
          ref={filterRefCounter}
          id='filterCounter'
        >
          {Globalize.format(filterRefCounter.current, 'n0')}
        </p>
        &nbsp;
        <p style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}>
          {' '}
          out of {response.length}
        </p>
      </div>
    </div>
  );
};

export default DrugAlcoholTab;
