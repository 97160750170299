import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Button } from 'antd';
import moment from 'moment';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { history } from '../../../redux/store';
import { InfoCircleTwoTone } from '@ant-design/icons';

let columnPicker = [];
let flexes = '';
let showGrid = '';
let dragOver = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'TestDetail_audiogram.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const AudiogramTestTab = ({ data }) => {
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [audiogramData, setAudiogramData] = useState([]);
  const theGrid = useRef();
  const theSearch = useRef();
  const filterRefCounter = useRef(null);
  const fixedContainerRef = useRef(null);

  console.log(data, 'AudiogramTestTab');
  data.sort((a,b)=>{
    const dateA = new Date(a.create_date);
    const dateB = new Date(b.create_date);
    return dateB - dateA;
  });
  data.sort((a,b)=>{
    const dateA = new Date(a.completed_on);
    const dateB = new Date(b.completed_on);
    return dateB - dateA;
  });
  useEffect(() => {
    let finalResult = data.map((k) => {
      return {
        ...k,
        fullName: k.first_name + ' ' + k.last_name,
        name: k.name ? k.name : '',
        dob: k.dob != false ? k.dob && moment(k.dob).format('MM/DD/YYYY') : '',
        com_loc_name:
          k.com_loc_name && !k.com_loc_name.includes('|') && !k.com_loc_name.includes(',')
            ? k.com_loc_name
            : k.com_loc_name?.split('|')[1] || k.com_loc_name?.split(',')[1],

        test_date_last: k.create_date != null ? k.create_date : '',
        test_date_lastForExcel:
          k.create_date != null ? k.create_date && moment(k.create_date).format('MM/DD/YYYY') : '',
        osha_Date:
          k.completed_on != null
            ? k.completed_on 
            : '',
        osha_DateForExcel:
          k.completed_on != null
            ? k.completed_on && moment(k.completed_on).format('MM/DD/YYYY')
            : '',
        sts_Date:
          k.completed_on != null
            ? k.completed_on 
            : '',
        sts_DateForExcel:
          k.completed_on != null
            ? k.completed_on && moment(k.completed_on).format('MM/DD/YYYY')
            : ''
      };
    });

    setAudiogramData(finalResult);
  }, []);
  useEffect(() => {
    if (audiogramData.length > 0) {
      const collectionView1 = new CollectionView(audiogramData, {
        pageSize: audiogramData.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(audiogramData, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      setView(collectionView2);
    }
  }, [audiogramData, pageSize]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };
  const showEmpLoc = (item) => {
    return item && item.item.com_loc_name ? item.item.com_loc_name : '';
  };

  const showLastDate = (item) => {
    return item && item.item.test_date_last
      ? moment(item.item.test_date_last).format('MM/DD/YYYY')
      : '';
  };
  const handleShowAudioInfo = (audioInfo) => {
    history.push({
      pathname: '/vertical/audiogramTestDetailForm',
      state: audioInfo
    });
  };
  // const showActions = (item) => {
  //   return (
  //     <div className='buttons-list nowrap'>
  //       <Button
  //         shape='circle'
  //         onClick={() => handleShowAudioInfo(item.item)}
  //         danger
  //         style={{
  //           background: 'none',
  //           border: 'none',
  //           boxShadow: 'none',
  //           color: 'rgb(239,103,39)',
  //           margin:'-7px 0px'
  //         }}
  //       >
  //         <span className='icofont icofont-info-circle' style={{ fontSize: 15 }} />
  //       </Button>
  //     </div>
  //   );
  // };

  const showActions = (item) => {
    return (
      <div className='buttons-list nowrap'>
        <InfoCircleTwoTone
          onClick={() => handleShowAudioInfo(item.item)}
          style={{ marginLeft: 12, marginTop: 7, color: '#0066ff', fontSize: 15 }}
          twoToneColor='#0066ff'
        />
      </div>
    );
  };

  const showSts = (item)=>{
    return item && item.item.completed_on ? moment(item.item.completed_on).format('MM/DD/YYYY'):''
  }

  const showOsha = (item) =>{
    return item && item.item.completed_on ? moment(item.item.completed_on).format('MM/DD/YYYY'):'';
  }

  return (
    <div>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={10}>
          <FlexGridSearch
            ref={theSearch}
            placeholder='Search'
            cssMatch=''
            style={{ width: '70%', height: '40px' }}
          />
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
          <div ref={fixedContainerRef}>
            <Select
              style={{ width: 170 }}
              placeholder={`Items per page : ${pageSize}`}
              onChange={paggination}
              options={[
                { label: 'Items per page : 10', value: 10 },
                { label: 'Items per page : 20', value: 20 },
                { label: 'Items per page : 50', value: 50 }
              ]}
              getPopupContainer={() => fixedContainerRef.current}
            />
          </div>
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={10}>
          <Button
            type='primary'
            size='small'
            style={{ backgroundColor: '#3d586a', float: 'right' }}
            disabled={isExcelPreparing}
            onClick={exportToExcel}
          >
            {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
          </Button>
        </Col>
      </Row>

      {/* to export entire data of table */}
      <FlexGrid
        ref={theGrid}
        style={{ display: 'none' }}
        className='dummyWijmoData'
        itemsSource={viewData}
        initialized={gridInitializedData}
        formatItem={formatItem}
        isReadOnly={true}
      >
        <FlexGridColumn binding='com_loc_name' header='Location' minWidth={300} width='*'/>
        <FlexGridColumn binding='test_date_lastForExcel' header='Last Test' minWidth={100}/>
        <FlexGridColumn binding='sts_DateForExcel' header='STS' minWidth={100}/>
        <FlexGridColumn binding='osha_DateForExcel' header='OSHA' minWidth={100} width='*'/>
      </FlexGrid>

      <FlexGrid
        ref={theGrid}
        id='theGridTallRows'
        className='cliniclisttable'
        itemsSource={view}
        initialized={gridInitialized}
        formatItem={formatItem}
        itemsPerPage={10}
        isReadOnly={true}
        //autoGenerateColumns={false}
      >
        <div style={{ display: 'none' }}>
          <wjInput.ListBox
            className='column-picker'
            itemsSource={listboxColumns}
            checkedMemberPath='visible'
            displayMemberPath='header'
            lostFocus={hidePicker}
            initialized={initializedListBox}
          ></wjInput.ListBox>
        </div>
        <FlexGridColumn
          binding='com_loc_name'
          header='Location'
          // minWidth={200}
          // width='*'
          width={277}
          // wordWrap={true}
        >
          <FlexGridCellTemplate cellType='Cell' template={showEmpLoc} />
        </FlexGridColumn>

        <FlexGridColumn binding='test_name' header='Test Name' width={181}></FlexGridColumn>

        <FlexGridColumn binding='test_date_last' header='Last Test' width={181}>
          <FlexGridCellTemplate cellType='Cell' template={showLastDate} />
        </FlexGridColumn>
        <FlexGridColumn binding='actions' header='Tests' width={181}>
          <FlexGridCellTemplate cellType='Cell' template={showActions} />
        </FlexGridColumn>
        <FlexGridColumn binding='sts_Date' header='STS' width={181}>
          <FlexGridCellTemplate cellType="Cell" template={showSts}/>
        </FlexGridColumn>
        <FlexGridColumn binding='osha_Date' header='OSHA' width={181}>
          <FlexGridCellTemplate cellType="Cell" template={showOsha}/>
        </FlexGridColumn>
        <FlexGridFilter
          showFilterIcons={true}
          placeholder='Filter data...'
          defaultFilterType='Value'
          filterColumns={['com_loc_name', 'osha_Date', 'sts_Date', 'test_date_last']}
        />
      </FlexGrid>

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div
          style={{
            backgroundColor: '#425a81',
            color: '#fff',
            display: 'inline',
            borderRadius: '5px',
            height: '30px'
          }}
        >
          <CollectionViewNavigator
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
            headerFormat='Page {currentPage:n0} of {pageCount:n0}'
            byPage={true}
            cv={view}
          />
        </div>
        &nbsp;&nbsp;&nbsp;
        <p
          style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}
          ref={filterRefCounter}
          id='filterCounter'
        >
          {Globalize.format(filterRefCounter.current, 'n0')}
        </p>
        &nbsp;
        <p style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}>
          {' '}
          out of {audiogramData.length}
        </p>
      </div>
    </div>
  );
};

export default AudiogramTestTab;
