import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Button } from 'antd';
import moment from 'moment';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FilePdfTwoTone, FolderOpenTwoTone } from '@ant-design/icons/lib';
let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'TestResult_DrugAlcohol.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const DrugAlcoholTab = ({
  data,
  downloadDrugDocument,
  downloadAttachmentMultiple,
  docDrugPermission,
  modeValue,
  programValue
}) => {
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [drugAlcoholData, setDrugAlcoholData] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef(null);

  // console.log(data, 'data::::::::::');

  useEffect(() => {
    data.sort((a,b)=>{
      const dateA = new Date(a.testing_date);
      const dateB = new Date(b.testing_date);
      return dateB - dateA;
    })
    const ress = data.map((val) => {
      // console.log(val, 'drugAlchol');
      return {
        ...val,
        newLocation:
          val.child_ids[1] != ''
            ? val.child_ids[1].split(',')[1]
              ? val.child_ids[1].split(',')[1].includes('|')
                ? val.child_ids[1].split(',')[1].trim().split('|')[1].trim()
                : val.child_ids[1].split(',')[1]
              : val.child_ids[1].split(',')[0].trim()
            : '',
        newCollectionDate:
          val.testing_date != false
            ? val.testing_date
            : '',
        newCollectionDateForExcel: val.testing_date ? moment(val.testing_date).format('MM/DD/YYYY'):'',
        newPanel: val.panel[1],
        ccfFileName: val.ccfFileName != false ? val.ccfFileName : '',
        url: val.url != false ? val.url : '',
        final_result: val.final_result != false ? val.final_result : '',
        // jobTitle: val.emp_data[0].Job_Title != false ? val.emp_data[0].Job_Title : '',
        newEin: val.emp_ein != false ? val.emp_ein : '',
        newSsn:
          val.emp_ssn != false && val.emp_ssn != null ? '***-**-' + val.emp_ssn?.substr(-4) : '',
        newCcf: val.ccf != false ? val.ccf : '',
        program: programValue != false ? programValue : '',
        mode: modeValue != false ? modeValue : ''
      };
    });
    console.log('resss of drug alcohol', ress);
    // setWijmoFlexGrid(ress, 10);
    setDrugAlcoholData(ress);
  }, []);

  useEffect(() => {
    if (drugAlcoholData.length > 0) {
      const collectionView1 = new CollectionView(drugAlcoholData, {
        pageSize: drugAlcoholData.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(drugAlcoholData, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      setView(collectionView2);
    }
  }, [drugAlcoholData, pageSize]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };
  const showDotMode = (item) => {
    console.log(item, 'dot mode');
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showLocation = (item) => {
    console.log(item, 'locations test');
    return <p>{item.item.newLocation ? item.item.newLocation : ''}</p>;
  };

  const ShowCollectionDate = (item) => {
    return <p>{moment(item.item.collected).format('MM/DD/YYYY')}</p>;
  };

  const showReason = (item) => {
    return <span>{item.item.reasons !== false ? item.item.reasons : ''}</span>;
  };

  // const showCcf = (item) => {
  //   return (
  //     <span className='nowrap'>
  //       {docDrugPermission ? (
  //         <>
  //           {item.item.ccfFileName ? (
  //             <button onClick={(e) => downloadDrugDocument(e, item.item.ccfFileName)}>
  //               View Doc
  //             </button>
  //           ) : (
  //             ''
  //           )}
  //           {item.item && item.item.attachmentUrls && item.item.attachmentUrls.length > 0
  //             ? item.item.attachmentUrls.map((url) => {
  //                 return (
  //                   <>
  //                     &nbsp; &nbsp;
  //                     <button
  //                       style={{ margin: 3 }}
  //                       onClick={() => downloadAttachmentMultiple(url.url)}
  //                     >
  //                       Attachments
  //                     </button>
  //                   </>
  //                 );
  //               })
  //             : ''}
  //         </>
  //       ) : (
  //         item.item.ccfFileName && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
  //       )}
  //     </span>
  //   );
  // };

  const showCcf = (item) => {
    return (
      <span className='nowrap'>
        {docDrugPermission ? (
          <>
            {item.item.ccfFileName ? (
              // <p style={{ color: 'blue' }}>
              //   View Doc
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => downloadDrugDocument(e, item.item.ccfFileName)}
                />
              // </p>
            ) : (
              ''
            )}
            {item.item && item.item.attachmentUrls && item.item.attachmentUrls.length > 0
              ? item.item.attachmentUrls.map((url) => {
                  return (
                    <>
                      {/* <p style={{ color: 'blue' }}> */}
                        <FolderOpenTwoTone
                          twoToneColor='#cc7a00'
                          style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                          onClick={() => downloadAttachmentMultiple(url.url)}
                        />
                      {/* </p> */}
                    </>
                  );
                })
              : ''}
          </>
        ) : (
          item.item.ccfFileName && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
        )}
      </span>
    );
  };

  // const ShowResultDoc = (item) => {
  //   return (
  //     <div style={{ display: 'flex', flexDirection: 'row', width: '225px' }}>
  //       {docDrugPermission ? (
  //         <>
  //           {item.item.mroFileName == false ? (
  //             ''
  //           ) : (
  //             <>
  //             <button onClick={(e) => downloadDrugDocument(e, item.item.mroFileName)}>
  //               Result
  //             </button>
  //             &nbsp; &nbsp;
  //             </>
  //           )}
  //           {item.item.tenpanelreport == false ? (
  //             ''
  //           ) : (
  //             <>
  //               <button onClick={(e) => downloadDrugDocument(e, item.item.tenpanelreport)}>
  //                 Ten Panel{' '}
  //               </button>
  //             </>
  //           )}
  //           {item.item.twelvepanelreport == false ? (
  //             ''
  //           ) : (
  //             <>
  //               <button onClick={(e) => downloadDrugDocument(e, item.item.twelvepanelreport)}>
  //                 Twelve Panel
  //               </button>
  //               &nbsp; &nbsp;
  //             </>
  //           )}
  //           {item.item.breathalcohol == false ? (
  //             ''
  //           ) : (
  //             <>
  //               <button onClick={(e) => downloadDrugDocument(e, item.item.breathalcohol)}>
  //                 Breath Alcohol
  //               </button>
  //               &nbsp; &nbsp;
  //             </>
  //           )}
  //         </>
  //       ) : (
  //         (item.item.mroFileName ||
  //           item.item.attachmentUrls ||
  //           item.item.breathalcohol ||
  //           item.item.twelvepanelreport ||
  //           item.item.tenpanelreport ||
  //           item.item.ccfFileName ||
  //           item.item.mroFileName) && (
  //           <CheckSquareTwoTone style={{ color: 'green', fontSize: 12 }} />
  //         )
  //       )}
  //     </div>
  //   );
  // };

  const ShowResultDoc = (item) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: '350px' }}>
        {docDrugPermission ? (
          <>
            {item.item.mroFileName == false ? (
              ''
            ) : (
              <>
                {/* <p style={{ color: '#0066ff' }}>
                  Result */}
                  <FilePdfTwoTone
                    onClick={(e) => downloadDrugDocument(e, item.item.mroFileName)}
                    style={{ fontSize: '15px', width: '30px', cursor: 'pointer' }}
                    twoToneColor='#FF0000'
                  />
                {/* </p> */}
              </>
            )}
            {item.item.tenpanelreport == false ? (
              ''
            ) : (
              <>
                {/* <p style={{ color: '#0066ff' }}>
                  Ten Panel */}
                  <FilePdfTwoTone
                    onClick={(e) => downloadDrugDocument(e, item.item.tenpanelreport)}
                    style={{ fontSize: '15px', width: '30px', cursor: 'pointer' }}
                    twoToneColor='#FF0000'
                  />
                {/* </p> */}
              </>
            )}
            {item.item.twelvepanelreport == false ? (
              ''
            ) : (
              <>
                {/* <p style={{ color: '#0066ff' }}>
                  Ten Panel */}
                  <FilePdfTwoTone
                    onClick={(e) => downloadDrugDocument(e, item.item.twelvepanelreport)}
                    style={{ fontSize: '15px', width: '30px', cursor: 'pointer' }}
                    twoToneColor='#FF0000'
                  />
                {/* </p> */}
              </>
            )}
            {item.item.breathalcohol == false ? (
              ''
            ) : (
              <>
                {/* <p style={{color:'#0066ff'}}>
                  Breath Alcohol */}
                  <FilePdfTwoTone
                    onClick={(e) => downloadDrugDocument(e, item.item.breathalcohol)}
                    style={{ fontSize: '15px', width: '30px', cursor: 'pointer' }}
                    twoToneColor='#FF0000'
                  />
                {/* </p> */}
              </>
            )}
          </>
        ) : (
          (item.item.mroFileName ||
            item.item.attachmentUrls ||
            item.item.breathalcohol ||
            item.item.twelvepanelreport ||
            item.item.tenpanelreport ||
            item.item.ccfFileName ||
            item.item.mroFileName) && (
            <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
          )
        )}
      </div>
    );
  };

  const showResult = (item) => {
    return item.item.final_result === 'NEGATIVE' ? (
      <p style={{ color: 'green' }}>{item.item.final_result}</p>
    ) : item.item.final_result === 'POSITIVE' ? (
      <p style={{ color: 'red' }}>{item.item.final_result}</p>
    ) : item.item.final_result !== 'POSITIVE' && item.item.final_result !== 'NEGATIVE' ? (
      <p style={{ color: 'orange' }}>{item.item.final_result}</p>
    ) : (
      ''
    );
  };

  const showCcfNum = (item) => {
    return <span>{item.item.ccf ? item.item.ccf : ''}</span>;
  };

  return (
    <div>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={10}>
          <FlexGridSearch
            ref={theSearch}
            placeholder='Search'
            cssMatch=''
            style={{ width: '70%', height: '40px' }}
          />
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
          <div ref={fixedContainerRef}>
            <Select
              style={{ width: 170 }}
              placeholder={`Items per page : ${pageSize}`}
              onChange={paggination}
              options={[
                { label: 'Items per page : 10', value: 10 },
                { label: 'Items per page : 20', value: 20 },
                { label: 'Items per page : 50', value: 50 }
              ]}
              getPopupContainer={() => fixedContainerRef.current}
            />
          </div>
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={10}>
          <Button
            type='primary'
            size='small'
            style={{ backgroundColor: '#3d586a', float: 'right' }}
            disabled={isExcelPreparing}
            onClick={exportToExcel}
          >
            {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
          </Button>
        </Col>
      </Row>

      {/* to export entire data of table */}
      <FlexGrid
        ref={theGrid}
        style={{ display: 'none' }}
        className='dummyWijmoData'
        itemsSource={viewData}
        initialized={gridInitializedData}
        formatItem={formatItem}
        isReadOnly={true}
      >
        <FlexGridColumn binding='newLocation' header='Location' minWidth={150} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showLocation} />
        </FlexGridColumn>
        <FlexGridColumn binding='newCollectionDateForExcel' header='Test Date' minWidth={200} width='*'/>

        <FlexGridColumn binding='reasons' header='Reason' minWidth={150} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showReason} />
        </FlexGridColumn>
        <FlexGridColumn
          binding='program'
          header='DOT Mode'
          // width='10*'
          minWidth={160}
          width='*'
        >
          <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
        </FlexGridColumn>

        <FlexGridColumn binding='final_result' header='Result' minWidth={150} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showResult} />
        </FlexGridColumn>

        <FlexGridColumn
          binding='key'
          header='Result Docs'
          // width='11*'
          minWidth={150}
          width='*'
        >
          <FlexGridCellTemplate cellType='Cell' template={ShowResultDoc} />
        </FlexGridColumn>

        <FlexGridColumn binding='ccf' header='CCF Number' minWidth={150} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showCcfNum} />
        </FlexGridColumn>

        <FlexGridColumn binding='ccfFileName' header='CCF Doc' minWidth={140} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showCcf} />
        </FlexGridColumn>

        {/* <FlexGridColumn
          binding='agenda'
          header='Agenda'
          // width='10*'
          minWidth={100}
          width='*'
        /> */}

        {/* <FlexGridColumn binding='reportingDate' header='Reporting Date' minWidth={160} width='*' />
        <FlexGridColumn binding='comments' header='Comments' minWidth={100} width='*' /> */}
      </FlexGrid>

      <FlexGrid
        // autoRowHeights={true}
        autoGenerateColumns={false}
        ref={theGrid}
        className='drugtab cliniclisttable'
        id='theGridTallRows'
        alternatingRowStep={1}
        itemsSource={view}
        initialized={gridInitialized}
        formatItem={formatItem}
        itemsPerPage={10}
        isReadOnly={true}
        scrollMode='Virtual'
      >
        <div style={{ display: 'none' }}>
          <wjInput.ListBox
            className='column-picker'
            itemsSource={listboxColumns}
            checkedMemberPath='visible'
            displayMemberPath='header'
            lostFocus={hidePicker}
            initialized={initializedListBox}
          ></wjInput.ListBox>
        </div>
        <FlexGridColumn binding='newLocation' header='Location' width={250}>
          <FlexGridCellTemplate cellType='Cell' template={showLocation} />
        </FlexGridColumn>
        <FlexGridColumn binding='newCollectionDate' header='Test Date' width={115}>
          <FlexGridCellTemplate cellType='Cell' template={ShowCollectionDate} />
        </FlexGridColumn>

        <FlexGridColumn binding='reasons' header='Reason' width={220}>
          <FlexGridCellTemplate cellType='Cell' template={showReason} />
        </FlexGridColumn>
        <FlexGridColumn
          binding='program'
          header='DOT Mode'
          // width='10*'
          // minWidth={160}
          // width='*'
          width={90}
        >
          <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
        </FlexGridColumn>

        <FlexGridColumn binding='final_result' header='Result' width={135}>
          <FlexGridCellTemplate cellType='Cell' template={showResult} />
        </FlexGridColumn>

        <FlexGridColumn
          binding='key'
          header='Result Docs'
          // width='11*'
          // minWidth={150}
          // width='*'
          width={135}
        >
          <FlexGridCellTemplate cellType='Cell' template={ShowResultDoc} />
        </FlexGridColumn>

        {/* <FlexGridColumn
          binding='agenda'
          header='Agenda'
          // width='10*'
          minWidth={100}
          width='*'
        /> */}

        <FlexGridColumn binding='ccf' header='CCF Number' width={115}>
          <FlexGridCellTemplate cellType='Cell' template={showCcfNum} />
        </FlexGridColumn>

        <FlexGridColumn binding='ccfFileName' header='CCF Doc' width={120}>
          <FlexGridCellTemplate cellType='Cell' template={showCcf} />
        </FlexGridColumn>

        {/* <FlexGridColumn binding='reportingDate' header='Reporting Date' width={125}/>
        <FlexGridColumn binding='comments' header='Comments' minWidth={100} width='*' /> */}

        <FlexGridFilter
          filterColumns={[
            'newLocation',
            'newCollectionDate',
            'reasons',
            'final_result',
            'newPanel',
            'ccf',
            'caseNumber'
          ]}
        />
      </FlexGrid>

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div
          style={{
            backgroundColor: '#425a81',
            color: '#fff',
            display: 'inline',
            borderRadius: '5px',
            height: '30px'
          }}
        >
          <CollectionViewNavigator
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
            headerFormat='Page {currentPage:n0} of {pageCount:n0}'
            byPage={true}
            cv={view}
          />
        </div>
        &nbsp;&nbsp;&nbsp;
        <p
          style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}
          ref={filterRefCounter}
          id='filterCounter'
        >
          {Globalize.format(filterRefCounter.current, 'n0')}
        </p>
        &nbsp;
        <p style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}>
          {' '}
          out of {drugAlcoholData.length}
        </p>
      </div>
    </div>
  );
};

export default DrugAlcoholTab;
