import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Spin, Row, Col } from 'antd';
import { Form, Input, Space } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons/lib';
import setting from '../../config/setting';
import PublicLayout from '../../layout/public/Public';
import { useForm } from 'antd/es/form/Form';
import { history } from './../../redux/store';

const { Item } = Form;

const ForgotPassword = () => {
  const [name, setName] = useState('');
  const [loader, setLoader] = useState(false);
  const [invalidCredential, setInvalidCredential] = useState('');
  const [hoverCont, setHoverCont] = useState(false);
  const [hoverBack, setHoverBack] = useState(false);
  const handleContinueMouseEnter = () => {
    setHoverCont(true);
  };

  const handleContinueMouseLeave = () => {
    setHoverCont(false);
  };
  const handleBackMouseEnter = () => {
    setHoverBack(true);
  };

  const handleBackMouseLeave = () => {
    setHoverBack(false);
  };

  useEffect(() => {}, []);

  async function getData() {
    //console.log('name', name);

    if (name !== '') {
      setInvalidCredential('');
      setLoader(true);
      console.log('name', name);
      await axios
        .post(setting.serverUrl + '/api/forgotPasswordApi', {
          params: { name: name.trim() }
        })
        .then(async (response) => {
          console.log('Respone from getData users', response);
          if (response && response.status == 200) {
            setLoader(false);
            let res = await response.data;
            console.log('response===', res);
            if (response && response.data.msg == 'Done') {
              //console.log('connected user inside done');

              history.push({ pathname: '/public/change-password', state: { email: name } });
            } else if (
              response &&
              response.data &&
              response.data.error &&
              response.data.error == 'Access Denied'
            ) {
              setInvalidCredential(
                "Access Denied! You don't currently have permission to access customer portal. For more detail please contact to admin."
              );
              // console.log('Access Denied');
              setLoader(false);
            } else {
              setInvalidCredential('Please enter valid email address !');
            }
          }
        });
    } else {
      setInvalidCredential('Please enter email address !');
    }
  }

  function userName(event) {
    console.log('event name', event.target.value);
    setName(event.target.value);
  }

  // console.log({ name });

  const [form] = useForm();

  function getUserEmail() {
    getData();
  }

  return (
    <>
      <Row
        style={{
          height: '100%',
          backgroundImage: `url(${require('./newBgPlane.jpg')})`,
          backgroundSize: 'cover'
        }}
      >
        <Col
          xl={{ span: 7 }}
          lg={{ span: 9 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ height: '100%' }}
        >
          <div>
            <PublicLayout>
              <h4 className='mt-0 mb-1'>WSS Forgot Password </h4>

              <p style={{ color: '#161717', paddingBottom: 0, marginBottom: 10 }}>
                Enter your email address
              </p>
              {invalidCredential !== '' ? (
                <span style={{ color: 'red', marginBottom: 10 }}>{invalidCredential}</span>
              ) : null}
              <Form form={form} layout='vertical' className='mb-4'>
                <Item name='login' rules={[{ required: true, message: <></> }]}>
                  <Input placeholder='Email' onChange={userName} />
                </Item>
                <span
                  style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    color: hoverBack ? '#f62' : '#247'
                  }}
                  onClick={() => history.goBack()}
                  onMouseEnter={handleBackMouseEnter}
                  onMouseLeave={handleBackMouseLeave}
                >
                  <h6>
                    {' '}
                    <CaretLeftOutlined style={{ fontSize: '1rem' }} /> Back{' '}
                  </h6>
                </span>
                {/* <Button
                  block={false}
                  type='primary'
                  size="small"
                  onClick={()=>history.goBack()}
                  htmlType='submit'
                  //icon={<CaretLeftOutlined  style={{ fontSize: '1.3rem' }} />}
                >
                 <CaretLeftOutlined  style={{ fontSize: '1rem' }} />  Back
                </Button> */}
                {/* <Button
                  block={false}
                  size="small"
                  type='primary'
                  onClick={getUserEmail}
                  htmlType='submit'
                  style={{ float: 'right' }}

                >
                  Continue  <CaretRightOutlined style={{ fontSize: '1rem' }} />
                </Button> */}
                <span
                  style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    color: hoverCont ? '#f62' : '#247',
                    float: 'right'
                  }}
                  onClick={getUserEmail}
                  onMouseEnter={handleContinueMouseEnter}
                  onMouseLeave={handleContinueMouseLeave}
                >
                  <h6>
                    Continue <CaretRightOutlined style={{ fontSize: '1rem' }} />
                  </h6>
                </span>
                &nbsp;&nbsp;&nbsp;
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {loader && (
                    <Space size='middle'>
                      <Spin size='large' />
                    </Space>
                  )}
                </div>
              </Form>
              <br />
            </PublicLayout>
          </div>
        </Col>
        <Col
          xl={{ span: 17 }}
          lg={{ span: 15 }}
          md={{ span: 12 }}
          sm={{ span: 0 }}
          xs={{ span: 0 }}
        >
          <h3
            style={{
              color: 'white',
              position: 'absolute',
              top: '48%',
              left: '0px',
              width: '100%',
              display: 'table',
              textAlign: 'center'
            }}
          >
            INCREASING SAFETY AND PRODUCTIVITY, REDUCING LIABILITY.™
          </h3>
        </Col>
      </Row>
    </>
  );
};

export default ForgotPassword;
