import React, { useEffect, useState } from 'react';
import { Button, Card, Radio, Modal, Tabs, Spin, Space, Row, notification, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import axios from 'axios';
import _ from 'underscore';
import { history } from '../../../redux/store';
import setting from '../../../config/setting';
import '@grapecity/wijmo.styles/wijmo.css';
import moment from 'moment';
import DrugAlcoholTab from '../Test_Detail_of_Employee/DrugAlcoholTab';
import OccHealthEmployee from '../Test_Detail_of_Employee/OccHealthTab';
import OshaQuesEmp from '../Test_Detail_of_Employee/OshaQuesTab';
import FitTestEmp from '../Test_Detail_of_Employee/FitTestTab';
import AudiogramEmp from '../Test_Detail_of_Employee/AudiogramTestTab';
import PftTestEmp from '../Test_Detail_of_Employee/PftTestTab';
import BackgroundScreeningTab from '../Test_Detail_of_Employee/BackgroundScreeningTab';
import EAPProgramTab from '../Test_Detail_of_Employee/EAPProgramTab';
import {InfoCircleTwoTone} from '@ant-design/icons'
const config = require('../../keyFront');
const { TabPane } = Tabs;

const TestDetailOfEmployee = () => {
  const location = useLocation();
  const data = location.state;
  console.log(data, 'location data');
  const [testingDetailChange, setTestingDetailChange] = useState(false);
  const [employeeName, setEmployeeName] = useState([]);
  const [testArr, setTestArr] = useState([]);
  const [drugAlcoholData, setDrugAlcoholData] = useState([]);
  const [occHealthData, setOccHealthData] = useState([]);
  const [oshaQuesData, setOshaQuesData] = useState([]);
  const [quesLoader, setQuesLoader] = useState(true);
  const [occHealthChange, setOccHealthChange] = useState(true);
  const [audiogramLoader, setAudiogramLoader] = useState(true);
  const [audiogramEmpData, setAudiogramEmpData] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [change, setChange] = useState(false);
  const [eapDataChange, setEapDataChange] = useState(true);
  const [backgroundChange, setBackgroundChange] = useState(true);
  const [eapData, setEapData] = useState([]);
  const [backgroundScreeningData, setBackgroundScreeningData] = useState([]);
  const [clearanceUrl, setclearanceUrl] = useState('');
  const [docDrugPermission, setDocDrugPermission] = useState(false);
  const [hasDrugPermission, setHasDrugPermission] = useState(false);
  const [drugNoRecordFound, setDrugNoRecordFound] = useState(false);
  const [occIsLoading, setOccIsLoading] = useState(false);
  const [fitTestData, setFitTestData] = useState([]);
  const [pftTestData, setPftTestData] = useState([]);
  const [occHasPermission, setOccHasPermission] = useState(false);
  const [occNoFound, setOccNofound] = useState(false);
  const [occErrMsg, setOccErrMsg] = useState('');
  const [occNoRecordMessage, setOccNoRecordMessage] = useState('');
  const [isDrugLoading, setIsDrugLoading] = useState(false);
  const [drugNoRecordMessage, setDrugNoRecordMessage] = useState('');
  const [drugErrMsg, setDrugErrMsg] = useState('');
  const [isBgLoading, setIsBgLoading] = useState(false);
  const [hasBgPermission, setHasBgPermission] = useState(false);
  const [bgNoRecordFound, setBgNoRecordFound] = useState(true);
  const [bgNoRecordMessage, setBgNoRecordMessage] = useState('');
  const [bgErrMsg, setBgErrMsg] = useState('');
  const [hasEapPermission, setEapHasPermission] = useState(false);
  const [eapErrMsg, setEapErrMsg] = useState('');
  const [isEapLoading, setIsEapLoading] = useState(false);
  const [docOccHealthPermission, setDocOccHealthPermission] = useState(false);
  const [docBgScreenPermission, setDocBgScreenPermission] = useState(false);
  const [pftLoader, setPftLoader] = useState(true);
  const [fitLoader, setFitLoader] = useState(true);
  let navTabCheck = JSON.parse(localStorage.getItem('NavTabCheck'));
  const [tabPosition, setTabPosition] = useState('left');
  const DarkBackground = styled.div`
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    ${(props) =>
      props.disappear &&
      css`
        display: block; /* show */
      `}
  `;
  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };

  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };

  const getDocOccHealthPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.OccHealthDocs
        }
      })
      .then((response) => {
        console.log(response, 'occ health');
        if (response.status === 200) {
          setDocOccHealthPermission(true);
        }
      });
  };
  const getDocBgScreenPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.BackgroundScreeningDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setDocBgScreenPermission(true);
        }
      });
  };
  const getDocDrugPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.DrugAndAlcoholDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setDocDrugPermission(true);
        }
      });
  };
  useEffect(() => {
    console.log('this.props Test History', data);
    if (navTabCheck && navTabCheck[0].drug_and_alcohol) {
      getDocDrugPermission();
      getEmployeeTestInfo(data);
    }
    if (navTabCheck && navTabCheck[0].eap_program) {
      getEAPProgramsById(data);
    }
    if (navTabCheck && navTabCheck[0].backgrounds) {
      getEmployeeBackgroundScreening(data);
      getDocBgScreenPermission();
    }
    if (navTabCheck && navTabCheck[0].occ_health) {
      getEmployeemedicalSurvelliance(data);
      getDocOccHealthPermission();
    }
    // if (navTabCheck && navTabCheck[0].osha_questionnaire) {
    //   getEmpOshaQuesData();
    // }
    if (navTabCheck && navTabCheck[0].fit_test) {
      getEmpFitTestData();
    }
    // if (navTabCheck && navTabCheck[0].pft_test) {
    //   getEmpPftTestData();
    // }
    getAudiogramTestData();
  }, []);

  async function getEmpOshaQuesData() {
    await axios
      .post(setting.serverUrl + '/api/getTestDetailsOshaByEmpId', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          empId: data['id'],
          companyId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then(async (response) => {
        if (response && response.status == 200) {
          setOshaQuesData(response?.data);
          setQuesLoader(false);
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  }
  async function getAudiogramTestData() {
    await axios
      .post(setting.serverUrl + '/api/audiogramListCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          empId: data['id'],
          custId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then(async (response) => {
        // console.log(response,'audiogram')
        if (response && response.status == 200) {
          setAudiogramEmpData(response?.data);
          setAudiogramLoader(false);
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  }
  async function getEmpPftTestData() {
    await axios
      .post(setting.serverUrl + '/api/getTestDetailsPFTByEmpId', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          empId: data['id'],
          companyId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then(async (response) => {
        if (response && response.status == 200) {
          setPftTestData(response?.data);
          setPftLoader(false);
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  }
  function processTestDetails(response) {
    let finalResults = [];
    response &&
      response.length > 0 &&
      response.forEach((employee) => {
        // Initialize variables to store latest test details
        let oshaTest = null;
        let pulmonaryTest = null;
        let fitTest = null;

        // Iterate through test details for each employee
        employee.test_details.forEach((test) => {
          if (test.test_name === 'OSHA Medical Questionnaire') {
            if (!oshaTest || new Date(test.created_date) > new Date(oshaTest.created_date)) {
              oshaTest = test;
            }
          } else if (test.test_name === 'Pulmonary/Spirometry Function Test') {
            if (
              !pulmonaryTest ||
              new Date(test.created_date) > new Date(pulmonaryTest.created_date)
            ) {
              pulmonaryTest = test;
            }
          } else if (test.test_name === 'Respirator Fit Test (per mask)') {
            if (!fitTest || new Date(test.created_date) > new Date(fitTest.created_date)) {
              fitTest = test;
            }
          }
        });

        // Construct final result object for the employee
        let finalResult = {
          employee_id: employee.employee_id,
          first_name: employee.first_name,
          last_name: employee.last_name,
          emp_ein: employee.emp_ein,
          emp_ssn: employee.emp_ssn,
          com_loc_name: employee.com_loc_name,
          osha_result: oshaTest ? oshaTest.final_result : null,
          osha_doc: oshaTest ? oshaTest.url : null,
          oshaTestingDate: oshaTest ? oshaTest.testing_date : null,
          pft_result: pulmonaryTest ? pulmonaryTest.final_result : null,
          pft_doc: pulmonaryTest ? pulmonaryTest.url : null,
          pftTestingDate: pulmonaryTest ? pulmonaryTest.testing_date : null,
          fit_result: fitTest ? fitTest.final_result : null,
          fit_doc: fitTest ? fitTest.url : null,
          fitTestingDate: fitTest ? fitTest.testing_date : null,
          fitTestingStatus: fitTest ? fitTest.testing_status : null,
          pftTestingStatus: pulmonaryTest ? pulmonaryTest.testing_status : null,
          oshaTestingStatus: oshaTest ? oshaTest.testing_status : null,

          // Include all keys of the test details
          ...oshaTest,
          ...pulmonaryTest,
          ...fitTest
        };

        // Remove redundant keys
        delete finalResult.test_name;
        delete finalResult.created_date;
        delete finalResult.final_result;
        delete finalResult.url;

        // Add final result to the list
        finalResults.push(finalResult);
      });

    // Return the final results
    return finalResults;
  }
  async function getEmpFitTestData() {
    await axios
      .post(setting.serverUrl + '/api/getTestDetailsOshaPftFitByEmpId', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          empId: data['id'],
          companyId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then(async (response) => {
        console.log(response, 'fit testing');
        if (response && response.status == 200) {
          console.log('data in em id fit test', response.data);
          const processedData = processTestDetails(response.data);
          console.log(processedData, 'processedData');
          processedData.sort((a, b) => {
            const dateA = new Date(a.testing_date);
            const dateB = new Date(b.testing_date);
            return dateB - dateA;
          });
          processedData.sort((a, b) => {
            const dateA = new Date(a.expiry_date);
            const dateB = new Date(b.expiry_date);
            return dateB - dateA;
          });
          const newResponse =
            processedData &&
            processedData.length > 0 &&
            processedData.map((k) => {
              return {
                ...k,
                fullName: k.first_name + ' ' + k.last_name,
                newLocation:
                  k.com_loc_name && k.com_loc_name.includes('|')
                    ? k.com_loc_name.split('|')[1]
                    : k.com_loc_name,
                newCollected: k.testing_date != false ? k.testing_date : '',
                newCollectedForExcel:
                  k.testing_date != false
                    ? k.testing_date && moment(k.testing_date).format('MM/DD/YYYY')
                    : '',
                fit_Model: k.fit_Model != false ? k.fit_Model : '',
                fit_Size: k.fit_Size != false ? k.fit_Size : '',
                fit_Style: k.fit_Style != false ? k.fit_Style : '',
                testing_status: k.testing_status != false ? k.testing_status : '',
                expiry_date:
                  k.expiry_date != false && k.expiry_date != null
                    ? k.expiry_date
                    : '',
                expiry_dateForExcel:
                  k.expiry_date != false && k.expiry_date != null
                    ? moment(k.expiry_date).format('MM/DD/YYYY')
                    : '',
                fitTestPdf: k.fitTestPdf != false ? k.fitTestPdf : '',
                fitTestCard: k.fitTestCard != false ? k.fitTestCard : '',
                fitTestType: k.fit_Test_Type != false ? k.fit_Test_Type : '',
                fitManufacturer: k.fit_Manufacturer != false ? k.fit_Manufacturer : '',
                finalResultFit: k.fit_result != false ? k.fit_result : '',
                finalResultOsha: k.osha_result != false ? k.osha_result : '',
                finalResultPft: k.pft_result != false ? k.pft_result : '',

                newDL: k.DL != false ? k.DL : '',
                newEmpEIN: k.emp_ein != false ? k.emp_ein : '',
                newEmpSSN: k.emp_ssn != false ? k.emp_ssn : ''
              };
            });
          setFitTestData(newResponse);
          setFitLoader(false);
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  }

  const getEmployeemedicalSurvelliance = (data) => {
    setOccIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/medicalSurvelliance', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          // compId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id,
          employeeId: data['id']
        }
      })
      .then((response) => {
        setOccIsLoading(false);
        if (response.status === 200 && response.data) {
          console.log('response in occ health test===', response.data);
          let dataGroupById = _.groupBy(response.data.rows, 'test_event_id');
          console.log(dataGroupById, 'dataGroupById');
          let records = Object.keys(dataGroupById).map((element) => {
            const object = dataGroupById[element];
            const firstRecord = object[0];
            return {
              id: firstRecord.test_event_id,
              event_id: firstRecord.event_id,
              full_name: firstRecord.first_name + ' ' + firstRecord.last_name,
              package_name: firstRecord.package_name,
              event_status: firstRecord.event_status,
              create_date: firstRecord.create_date,
              // attachments: firstRecord.attachments,
              test_status: firstRecord.test_status,
              reasons: firstRecord.reasons,
              urls: firstRecord.urls,
              result: firstRecord.result,
              final_result: firstRecord.final_result,
              completed_on: firstRecord.completed_on,
              emp_selected: firstRecord.emp_selected,
              scheduled_date: firstRecord.scheduled_date,
              test_name_moved0: firstRecord.test_name_moved0,
              eventNotes: firstRecord.event_notes,
              loc_name: firstRecord.loc_name,
              mode: firstRecord.hospital_employee_mode,
              test_name: object.map((element) => {
                return {
                  test_name: element.test_name,
                  test_name_moved0: element.test_name_moved0,
                  test_status: element.test_status,
                  //attachments : element.attachments,
                  urls: element.urls,
                  completed_on: element.completed_on,
                  result: element.result,
                  final_result: element.final_result,
                  eventTdNotes: element.event_note
                };
              })
            };
          });

          // records.sort(function (a, b) {
          //   return new Date(b.create_date) - new Date(a.create_date);
          // });
          console.log(records, 'occhealthtabs:::::::::::::::::::');
          setOccHealthData(records);
          setOccHealthChange(false);
          setOccHasPermission(true);
        } else if (response.status === 201) {
          setOccNofound(true);
          setOccErrMsg('Access permission not allowed.');
          setOccNoRecordMessage('Access permission not allowed.');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };

  function getEmployeeTestInfo(data) {
    setIsDrugLoading(true);
    axios
      .post(setting.serverUrl + '/api/employeeTestInfo', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          employeeId: data['id']
        }
      })
      .then((response) => {
        setIsDrugLoading(false);
        if (response && response.status == 200) {
          // setEmployeeName(response.data);
          setTimeout(() => {
            let dataArr = [];
            _.each(response.data, (resp) => {
              if (
                resp.emp_selected &&
                resp.emp_selected.length > 0 &&
                data.id == resp.emp_selected[0]
              ) {
                dataArr.push(resp);
              }
            });

            let drugAlcoholValues = dataArr.filter((item) => item.item_type == 'drug and alcohol');
            console.log('drugAlcoholValues======', drugAlcoholValues);
            setDrugAlcoholData(drugAlcoholValues);
            setTestArr(dataArr);
            setDrugNoRecordFound(false);
            setHasDrugPermission(true);
            setTestingDetailChange(false);
          }, 2000);
        } else if (response.status === 201) {
          setTestingDetailChange(true);
          setDrugNoRecordFound(true);
          setDrugNoRecordMessage('Access permission not allowed.');
          setDrugErrMsg('Access permission not allowed.');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
          setTestingDetailChange(true);
        }
      });
  }

  async function getEAPProgramsById(data) {
    setIsEapLoading(true);
    await axios
      .post(setting.serverUrl + '/api/getEAPProgramsByEmployeeId', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: JSON.parse(localStorage.getItem('customerData')).id,
          employeeId: data['id']
        }
      })
      .then((response) => {
        setIsEapLoading(false);
        if (response.data == 'Blocked') {
          console.log('Respone from getEAPPrograms App tsx blocked: ', response.data);
        } else {
          setChange(true);
          if (response && response.data.error) {
            alert('Some Technical Error Occurred!');
          }
          if (response && response.status == 200) {
            let allEapdatas = response.data;
            let allEapdata = allEapdatas.value;

            allEapdata &&
              allEapdata.sort((a, b) => {
                let date1 = new Date(b.__last_update);
                let date2 = new Date(a.__last_update);
                return date1 > date2 ? 1 : -1;
              });

            setEapData(allEapdata);
            setEapDataChange(false);
            setEapHasPermission(true);
          } else if (response.status === 201) {
            setEapErrMsg('Access permission not allowed.');
          } else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
            // setNofound(true);
          }
        }
      });
  }
  const getEmployeeBackgroundScreening = (data) => {
    setIsBgLoading(true);
    axios
      .post(setting.serverUrl + '/api/getBackgroundScreeningsByEmployeeId', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          company: localStorage.getItem('customerId'),
          employeeId: data['id']
        }
      })
      .then((response) => {
        setIsBgLoading(false);
        if (response.status === 200 && response.data) {
          setBackgroundScreeningData(response.data);
          setBackgroundChange(false);
          setHasBgPermission(true);
        } else if (response.status === 201) {
          setBgNoRecordFound(true);
          setBgErrMsg('Access permission not allowed.');
          setBgNoRecordMessage('Access permission not allowed.');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };
  // const handleDrugAlcohol = (data) => {
  //   console.log('data handleDrugAlcohol::', data);
  //   history.push({ pathname: `/vertical/testingDetailViewFurther`, state: data });
  // };
  // const actionsDrugAlcohol = (data) => (
  //   <div className='buttons-list nowrap'>
  //     <Tooltip title='Show details'>
  //       <Button
  //         shape='circle'
  //         onClick={() => handleDrugAlcohol(data)}
  //         type='primary'
  //         style={{
  //           background: 'none',
  //           border: 'none',
  //           boxShadow: 'none',
  //           color: '#f62'
  //         }}
  //       >
  //         <span className='icofont icofont-info-circle' style={{ fontSize: 20 }} />
  //       </Button>
  //     </Tooltip>
  //   </div>
  // );
  function downloadUrlResultAttach(e, attachment) {
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        console.log('Respone from download images', response.data);
        setIsLoaderActive(false);
        setChange(false);
        setIsModalVisible(true);
        if (response.data && response.data.length > 0) {
          setImageUrls(response.data);
        }
      });
  }

  function downloadAuthorization(e, attachment) {
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: [attachment[0][0]]
        }
      })
      .then((response) => {
        setIsLoaderActive(false);
        setChange(false);
        setIsModalVisible(true);
        if (response.data && response.data.length > 0) {
          setImageUrls(response.data[0].url);
        }
      });
  }
  function downloadDrugDocument(e, filename) {
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadCCFMROCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          filename: filename
        }
      })
      .then((response) => {
        setIsLoaderActive(false);
        setChange(false);
        setIsModalVisible(true);
        setImageUrls(response.data);
      });
  }
  function downloadDrugDocument(e, attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: 'https://wss-files.s3.amazonaws.com/' + attachment
        }
      })
      .then((response) => {
        window.open(response.data);
      });
  }
  function downloadAttachmentMultiple(attachment) {
    if (attachment && attachment.length > 0) {
      axios
        .post(setting.serverUrl + '/api/downloadAttachmentOccHealth', {
          params: {
            email: localStorage.getItem('myData'),
            user: localStorage.getItem('myData'),
            pass: localStorage.getItem('myPass'),
            attachment_detail: attachment
          }
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            console.log('Respone from attachment multiple', response.data);
            //setChange(!change);
            // if(response.data != null){
            window.open(response.data);
            // }
          } else if (response.status === 201) {
            alert('Permission not allowed!');
          }
        });
    } else {
      alert('No Record Found');
    }
  }
  const downloadClearance = (e, event_id, emp_selected) => {
    console.log('data', event_id, emp_selected);
    axios
      .post(setting.serverUrl + '/api/downloadClearanceForm', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          event_id: event_id,
          employee_id: emp_selected
        }
      })
      .then((response) => {
        if (response.data.url == 'not found') {
          setclearanceUrl('');
          alert('No Record Found');
        } else {
          setclearanceUrl(response.data.url);
          window.open(response.data.url);
        }
      });
  };
  const handleShowInfoEap = (patient) => {
    console.log('patient of handleshowinfo', patient);
    console.log('location concate', location.state);
    history.push({
      pathname: `/vertical/EAP_Programs_Details`,
      state: Object.assign(patient, location.state)
    });
    // history.push({
    //   pathname: '/vertical/randomPoolDetails'
    //   , state: patient
    // });
  };

  const actionsEap = (patient) => (
    <div className='buttons-list nowrap'>
      {/* <Button
        shape='circle'
        onClick={() => handleShowInfoEap(patient)}
        danger
        style={{
          background: 'none',
          border: 'none',
          boxShadow: 'none',
          color: 'rgb(239,103,39)',
          margin: '-7px 5px'
        }}
      >
        <span className='icofont icofont-info-circle' style={{ fontSize: 15, cursor: 'pointer' }} />
      </Button> */}
       <InfoCircleTwoTone
          onClick={() => handleShowInfoEap(patient)}
          style={{ marginLeft: 20, marginTop: 4, color: '#0066ff', fontSize: 15 }}
          twoToneColor='#0066ff'
        />
    </div>
  );

  return (
    <>
      <Tabs>
        {navTabCheck &&
          navTabCheck.length > 0 &&
          navTabCheck[0].drug_and_alcohol &&
          navTabCheck[0].drug_and_alcohol != null &&
          navTabCheck[0].drug_and_alcohol != false && (
            <TabPane
              tab={
                <Radio.Group value={tabPosition} onChange={changeTabPosition}>
                  <Radio.Button value='left'>Drug & Alcohol</Radio.Button>
                </Radio.Group>
              }
              key='1.1'
              style={{ fontSize: 6 }}
            >
              {hasDrugPermission ? (
                <div>
                  {!testingDetailChange ? (
                    drugAlcoholData.length > 0 ? (
                      <>
                        <DrugAlcoholTab
                          programValue={data.program}
                          modeValue={data.mode}
                          data={drugAlcoholData}
                          downloadDrugDocument={downloadDrugDocument}
                          downloadAttachmentMultiple={downloadAttachmentMultiple}
                          docDrugPermission={docDrugPermission}
                        />
                      </>
                    ) : (
                      <>
                        {isDrugLoading ? (
                          <Row>
                            <Col span={10}></Col>
                            <Col span={8}>
                              <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                                <Spin size='large' />
                              </Space>
                            </Col>
                          </Row>
                        ) : (
                          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>
                            No Record Found.
                          </h5>
                        )}
                      </>
                    )
                  ) : (
                    <Row>
                      <Col span={10}></Col>
                      <Col span={8}>
                        <Space size='middle' style={{ marginTop: 10, marginLeft: 85 }}>
                          <Spin size='large' />
                        </Space>
                      </Col>
                    </Row>
                  )}
                  <Modal
                    width={1000}
                    title='Drug & Alcohol Data'
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    {imageUrls && imageUrls.length > 0 && (
                      <p>
                        <embed src={imageUrls} width='800px' height='2100px' />
                      </p>
                    )}
                  </Modal>

                  <DarkBackground disappear={isLoaderActive}>
                    <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
                  </DarkBackground>
                </div>
              ) : (
                <div>
                  {isDrugLoading && (
                    <Row>
                      <Col span={10}></Col>
                      <Col span={8}>
                        <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                          <Spin size='large' />
                        </Space>
                      </Col>
                    </Row>
                  )}
                  <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
                    &nbsp;&nbsp; {drugErrMsg}
                  </p>
                </div>
              )}
            </TabPane>
          )}
        {navTabCheck &&
          navTabCheck.length > 0 &&
          navTabCheck[0].occ_health &&
          navTabCheck[0].occ_health != null &&
          navTabCheck[0].occ_health != false && (
            <TabPane
              tab={
                <Radio.Group value={tabPosition} onChange={changeTabPosition}>
                  <Radio.Button value='left'>Health Surveillance</Radio.Button>
                </Radio.Group>
              }
              key='1.2'
              style={{ fontSize: 6 }}
            >
              {occHasPermission ? (
                <div>
                  {!occHealthChange ? (
                    <div>
                      {occHealthData.length > 0 ? (
                        <>
                          <OccHealthEmployee
                            data={occHealthData}
                            docOccHealthPermission={docOccHealthPermission}
                            downloadClearance={downloadClearance}
                            downloadAttachmentMultiple={downloadAttachmentMultiple}
                          />
                        </>
                      ) : (
                        <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: '#ef6727' }}>
                          No Record Found!
                        </p>
                      )}
                    </div>
                  ) : (
                    <Row>
                      <Col span={10}></Col>
                      <Col span={8}>
                        <Space size='middle' style={{ marginTop: 10, marginLeft: 85 }}>
                          <Spin size='large' />
                        </Space>
                      </Col>
                    </Row>
                  )}
                  <Modal
                    width={1000}
                    title='Occ. Health Documents'
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    {imageUrls && imageUrls.length > 0 && (
                      <p>
                        <embed src={imageUrls} width='800px' height='2100px' />
                      </p>
                    )}
                  </Modal>

                  <DarkBackground disappear={isLoaderActive}>
                    <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
                  </DarkBackground>
                </div>
              ) : (
                <div>
                  {occIsLoading && (
                    <Row>
                      <Col span={10}></Col>
                      <Col span={8}>
                        <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                          <Spin size='large' />
                        </Space>
                      </Col>
                    </Row>
                  )}
                  <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
                    &nbsp;&nbsp; {occErrMsg}
                  </p>
                </div>
              )}
            </TabPane>
          )}
        {navTabCheck &&
          navTabCheck.length > 0 &&
          navTabCheck[0].fit_test &&
          navTabCheck[0].fit_test != null &&
          navTabCheck[0].fit_test != false && (
            <TabPane
              tab={
                <Radio.Group value={tabPosition} onChange={changeTabPosition}>
                  <Radio.Button value='left'>Fit Testing</Radio.Button>
                </Radio.Group>
              }
              key='1.3'
              style={{ fontSize: 6 }}
            >
              <div>
                {fitLoader ? (
                  <Row>
                    <Col span={10}></Col>
                    <Col span={8}>
                      <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                        <Spin size='large' />
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  <>
                    {fitTestData.length > 0 ? (
                      <>
                        <FitTestEmp data={fitTestData} />
                      </>
                    ) : (
                      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: '#ef6727' }}>
                        No Record Found!
                      </p>
                    )}
                  </>
                )}
              </div>
            </TabPane>
          )}
        {/* {navTabCheck &&
          navTabCheck.length > 0 &&
          navTabCheck[0].pft_test &&
          navTabCheck[0].pft_test != null &&
          navTabCheck[0].pft_test != false && (
            <TabPane
              tab={
                <Radio.Group value={tabPosition} onChange={changeTabPosition}>
                  <Radio.Button value='left'>PFT Test</Radio.Button>
                </Radio.Group>
              }
              key='1.4'
              style={{ fontSize: 6 }}
            >
              <div>
                {pftLoader ? (
                  <Row>
                    <Col span={10}></Col>
                    <Col span={8}>
                      <Space size='middle' style={{ marginTop: 50 }}>
                        <Spin size='large' />
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  <>
                    {pftTestData.length > 0 ? (
                      <>
                        <PftTestEmp
                          data={pftTestData}
                          docOccHealthPermission={docOccHealthPermission}
                          downloadClearance={downloadClearance}
                          downloadAttachmentMultiple={downloadAttachmentMultiple}
                        />
                      </>
                    ) : (
                      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: '#ef6727' }}>
                        No Record Found!
                      </p>
                    )}
                  </>
                )}
              </div>
            </TabPane>
          )} */}
        {/* {navTabCheck &&
          navTabCheck.length > 0 &&
          navTabCheck[0].osha_questionnaire != null &&
          navTabCheck[0].osha_questionnaire != false && (
            <TabPane
              tab={
                <Radio.Group value={tabPosition} onChange={changeTabPosition}>
                  <Radio.Button value='left'>OSHA Questionnaire</Radio.Button>
                </Radio.Group>
              }
              key='1.5'
              style={{ fontSize: 6 }}
            >
              <div>
                {quesLoader ? (
                  <Row>
                    <Col span={10}></Col>
                    <Col span={8}>
                      <Space size='middle' style={{ marginTop: 50 }}>
                        <Spin size='large' />
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  <>
                    {oshaQuesData.length > 0 ? (
                      <>
                        <OshaQuesEmp
                          data={oshaQuesData}
                          docOccHealthPermission={docOccHealthPermission}
                          downloadClearance={downloadClearance}
                          downloadAttachmentMultiple={downloadAttachmentMultiple}
                        />
                      </>
                    ) : (
                      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: '#ef6727' }}>
                        No Record Found!
                      </p>
                    )}
                  </>
                )}
              </div>
            </TabPane>
          )} */}
        {navTabCheck &&
          navTabCheck.length > 0 &&
          navTabCheck[0].backgrounds &&
          navTabCheck[0].backgrounds != null &&
          navTabCheck[0].backgrounds != false && (
            <TabPane
              tab={
                <Radio.Group value={tabPosition} onChange={changeTabPosition}>
                  <Radio.Button value='left'>Background Screening</Radio.Button>
                </Radio.Group>
              }
              key='1.6'
              style={{ fontSize: 6 }}
            >
              {hasBgPermission ? (
                <div>
                  {!backgroundChange ? (
                    <div>
                      {backgroundScreeningData.length > 0 ? (
                        <>
                          <BackgroundScreeningTab
                            data={backgroundScreeningData}
                            docBgScreenPermission={docBgScreenPermission}
                            downloadUrlResultAttach={downloadUrlResultAttach}
                            downloadAuthorization={downloadAuthorization}
                          />
                        </>
                      ) : (
                        <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: '#ef6727' }}>
                          No Record Found!
                        </p>
                      )}
                    </div>
                  ) : (
                    <Row>
                      <Col span={10}></Col>
                      <Col span={8}>
                        <Space size='middle' style={{ marginTop: 10, marginLeft: 85 }}>
                          <Spin size='large' />
                        </Space>
                      </Col>
                    </Row>
                  )}
                  <Modal
                    width={1000}
                    title='Backgrounds Data'
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    {imageUrls && imageUrls.length > 0 && (
                      <p>
                        <embed src={imageUrls} width='800px' height='2100px' />
                      </p>
                    )}
                  </Modal>

                  <DarkBackground disappear={isLoaderActive}>
                    <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
                  </DarkBackground>
                </div>
              ) : (
                <div>
                  {isBgLoading && (
                    <Row>
                      <Col span={10}></Col>
                      <Col span={8}>
                        <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                          <Spin size='large' />
                        </Space>
                      </Col>
                    </Row>
                  )}
                  <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
                    &nbsp;&nbsp; {bgErrMsg}
                  </p>
                </div>
              )}
            </TabPane>
          )}
        {navTabCheck &&
          navTabCheck.length > 0 &&
          navTabCheck[0].eap_program &&
          navTabCheck[0].eap_program != null &&
          navTabCheck[0].eap_program != false && (
            <TabPane
              tab={
                <Radio.Group value={tabPosition} onChange={changeTabPosition}>
                  <Radio.Button value='left'>EAP Program</Radio.Button>
                </Radio.Group>
              }
              key='1.7'
              style={{ fontSize: 6 }}
            >
              {hasEapPermission ? (
                <div>
                  <Card>
                    <div className='row'>
                      <div className='col-12 col-md-12 col-xl-12'>
                        {!eapDataChange ? (
                          <div>
                            {eapData.length > 0 ? (
                              <>
                                {/* <Table dataSource={eapData} columns={eapColumns} /> */}
                                <EAPProgramTab data={eapData} actionsEap={actionsEap} />
                              </>
                            ) : (
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 700,
                                  padding: 20,
                                  color: '#ef6727'
                                }}
                              >
                                No Record Found!
                              </p>
                            )}
                          </div>
                        ) : (
                          <Row>
                            <Col span={10}></Col>
                            <Col span={8}>
                              <Space size='middle' style={{ marginTop: 10, marginLeft: 85 }}>
                                <Spin size='large' />
                              </Space>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              ) : (
                <div>
                  {isEapLoading && (
                    <Row>
                      <Col span={10}></Col>
                      <Col span={8}>
                        <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                          <Spin size='large' />
                        </Space>
                      </Col>
                    </Row>
                  )}
                  <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
                    &nbsp;&nbsp; {eapErrMsg}
                  </p>
                </div>
              )}
            </TabPane>
          )}
        <TabPane
          tab={
            <Radio.Group value={tabPosition} onChange={changeTabPosition}>
              <Radio.Button value='left'>Audiogram</Radio.Button>
            </Radio.Group>
          }
          key='1.8'
          style={{ fontSize: 6 }}
        >
          {audiogramLoader ? (
            <Row>
              <Col span={10}></Col>
              <Col span={8}>
                <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                  <Spin size='large' />
                </Space>
              </Col>
            </Row>
          ) : (
            <>
              {audiogramEmpData.length > 0 ? (
                <AudiogramEmp
                  data={audiogramEmpData}
                  docOccHealthPermission={docOccHealthPermission}
                  downloadClearance={downloadClearance}
                  downloadAttachmentMultiple={downloadAttachmentMultiple}
                />
              ) : (
                <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: '#ef6727' }}>
                  No Record Found!
                </p>
              )}
            </>
          )}
        </TabPane>
      </Tabs>
    </>
  );
};

export default TestDetailOfEmployee;
