import React from 'react';
import moment from 'moment';
import { Modal, List, Button } from 'antd';

const CertificateModal = ({ isVisible, certificates, certType, onClose, downloadCert }) => {
  return (
    <Modal
      title={`Certificate History for ${certType}`}
      visible={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <List
        itemLayout='horizontal'
        dataSource={certificates}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type='link' onClick={(e) => downloadCert(e, item.attachment_id)}>
                View
              </Button>
            ]}
          >
            <List.Item.Meta
              title={certType}
              description={`Expiration Date: ${
                item.cert_exp != null ? moment(item.cert_exp).format('MM/DD/YYYY') : 'NA'
              }`}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default CertificateModal;
