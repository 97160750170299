import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  Spin,
  Space,
  Row,
  Col,
  DatePicker,
  Input,
  notification,
  Card,
  Modal,
  Table,
  message,
  Button,
  Select
} from 'antd';
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import * as wjcCore from '@grapecity/wijmo';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import * as wjFilter from '@grapecity/wijmo.react.grid.filter';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import '@grapecity/wijmo.styles/wijmo.css';

const { RangePicker } = DatePicker;

const config = require('../keyFront');
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'covid_exemption.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const CovidExemption = () => {
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [NoRecFound, setNoRecFound] = useState(false);
  const [filterType, setFilterType] = useState('All');
  const [searchString, setSearchedString] = useState('');
  const [covidExemptionData, setCovidExemptionData] = useState([]);
  const [covidExemptionDataCounts, setCovidExemptionDataCounts] = useState(0);
  const [approvedVacc, setApprovedVacc] = useState([]);
  const [declinedVacc, setDeclinedVacc] = useState([]);
  const [pendingVacc, setPendingVacc] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [covidLocation, setCovidLocation] = useState({});
  const [change, setChange] = useState(false);
  const [table, setTable] = useState([]);
  const [exemptionTableBKP, setTableBKP] = useState([]);
  const [testingStatus, setTestingStatus] = useState('all');
  const [filterLocation, setFilterLocation] = useState('all');
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [employeeNameRecords, setEmployeeNameRecords] = useState({});
  const [tableFilterData, setTableFilterData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchColumn, setSearchColumn] = useState('');
  const [filterFlag, setFilterFlag] = useState(false);
  const [docPermission, setDocPermission] = useState(false);
  const [searchDateRange, setSearchDateRange] = useState('');
  const [latestFilterRecord, setLatestFilterRecord] = useState(
    searchDateRange != null ? true : false
  );
  const [covidExempData, setCovidExempData] = useState([]);
  const [covidExempDataBkup, setCovidExempDataBkup] = useState([]);
  const [typecheckValue, setTypeCheckValue] = useState('');

  //wijmo table

  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [pagging, setPagging] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [pageApply, setPageApply] = useState(false);
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const getRecordAfterSearch = (searchText) => {
    const result = exemptionTableBKP.filter((data) =>
      data.name.toString().toLowerCase().startsWith(searchText.toLowerCase())
    );
    return result;
  };

  const getRecordsAfterTestingLocation = (location) => {
    let records = getRecordAfterSearch(searchString);
    if (location != 'all') {
      return records.filter((dat) => dat.location_name == location);
    } else {
      return records;
    }
  };

  const getRecordsAfterExemptionType = (type) => {
    const records = getRecordsAfterTestingLocation(filterLocation);
    let exeptionTypeRecords = records;
    if (type === 'Religious') {
      exeptionTypeRecords = records.filter((exemp) => exemp.exemption_type == 'religious');
    } else if (type === 'Medical') {
      exeptionTypeRecords = records.filter((exemp) => exemp.exemption_type == 'medical');
    }
    return exeptionTypeRecords;
  };

  const filterTable = (e) => {
    console.log('e::', e);
    setFilterType(e);
    setTestingStatus('all');
    setFilterFlag(true);
    const records = getRecordsAfterExemptionType(e);
    setTable(records);

    if (e === 'Religious') {
      const religious = table.filter((exemp) => exemp.exemption_type == 'religious');
      setTable(religious);
      setWijmoFlexGrid(religious, 10);
    } else if (e === 'Medical') {
      const medical = table.filter((exemp) => exemp.exemption_type == 'medical');
      setTable(medical);
      setWijmoFlexGrid(medical, 10);
    } else if (e === 'app') {
      setTable(approvedVacc);
      setWijmoFlexGrid(approvedVacc, 10);
    } else if (e === 'dec') {
      setTable(declinedVacc);
      setWijmoFlexGrid(declinedVacc, 10);
    } else if (e === 'pend') {
      setTable(pendingVacc);
      setWijmoFlexGrid(pendingVacc, 10);
    } else {
      createTable();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };
  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.CovidVaccinationDocs
        }
      })
      .then((response) => {
        console.log('response docs', response);
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };
  function download(e, empId) {
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificateForEmployee', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          empId: empId
        }
      })
      .then((response) => {
        console.log('Respone from download images', response.data);
        setChange(!change);
        setIsLoaderActive(false);
        if (Array.isArray(response.data)) {
          setIsModalVisible(true);
          setImageUrls(response.data);
        } else {
          window.open(response.data);
        }
      });
  }

  const getExemption = () => {
    setNoRecFound(false);
    setIsLoading(true);
    //setChange(true);
    axios
      .post(setting.serverUrl + '/api/getCovidExemption', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data == 'Blocked') {
          console.log('Covid Exemption data blocked: ', response.data);
        } else {
          setChange(false);
          if (response?.data && response?.data?.rows?.length == 0) {
            setNoRecFound(true);
          }

        if (response && response.data) {
          if (response.data.error) {
            alert('Some technical error occurred. Please try again in a bit.');
          }
          if (response.status === 200) {
           
              // let locations = {};
              // response.data.rows.map((data) => (locations[data.location_name] = 'here'));
              // setCovidLocation(locations);
               setCovidExemptionData(response.data.rows);
              // let employeeNames = {};
              // let records = response.data.rows;
              // records.forEach((element) => {
              //   const name = element.first_name + ' ' + element.last_name;
              //   if (!employeeNames[name]) {
              //     employeeNames[name] = name;
              //   }
              // });
              // setEmployeeNameRecords(employeeNames);
              setHasPermission(true);
              setNoRecFound(false);

              //setErrMsg('Module not activated.');
            }
          } else if (response.status === 201) {
            setNoRecFound(true);
            setErrMsg('Access permission not allowed.');
            setNoRecordMessage('Access permission not allowed.');
          }  
          else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
          }
        }
      });
  };

  const getExemptionCounts = () => {
    //setChange(true);
    axios
      .post(setting.serverUrl + '/api/getCovidExemptionCounts', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        if (response && response.data) {
          if (response.data.error) {
            alert('Some technical error occurred. Please try again in a bit.');
          }
          if (response.status === 200) {
            // console.log('Covid Exemption Counts data: ', response.data);
            setCovidExemptionDataCounts(response.data);
            setHasPermission(true);
            setErrMsg('Module not activated.');
          } else if (response.status === 201) {
            setNoRecFound(true);
            setNoRecordMessage('Access permission not allowed.');
            setErrMsg('Access permission not allowed.');
          } 
          else if (response.status === 202) {
            setHasPermission(true);
            setNoRecFound(false);
            setNoRecordMessage('No Record Found');
            setCovidExemptionDataCounts(0);

          } else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
          }
        }
      });
  };
  const createTable = () => {
    setChange(true);
    let compId = localStorage.getItem('customerId');
    setCompanyName(' of ' + localStorage.getItem('displayName'));

    if (covidExemptionData?.length > 0) {
      covidExemptionData.map((x) => (x.name = x.first_name + ' ' + x.last_name));
      console.log('exmep ', covidExemptionData);

      const ress = covidExemptionData.map((val) => {
        console.log("sssn ssn",val.emp_ssn)
        return {
          ...val,
          newReqDate: val.create_date && moment(val.create_date).format('MM/DD/YYYY'),
          newStatusDate: val.approval_date && moment(val.approval_date).format('MM/DD/YYYY'),
          exemption_comment:
            val.exemption_comment != false && val.exemption_comment != ''
              ? val.exemption_comment
              : '',
          newDL: val.DL != false ? val.DL : '',
          newEmpEIN: val.emp_ein != false ? val.emp_ein : '',
          newEmpSSN: val.emp_ssn != false && val.emp_ssn != '' && val.emp_ssn ? '***-**-' + val.emp_ssn?.substr(-4) : '',
          jobTitle: val.Job_Title != false ? val.Job_Title : '',
          departments: val.department != false ? val.department : '',
          program: val.program != false ? val.program : '',
          mode: val.mode != false ? val.mode : ''
        };
      });
      setWijmoFlexGrid(ress, 10);
      setTable(ress);
      setCovidExempData(ress);
      setCovidExempDataBkup(ress);
      setTableBKP(covidExemptionData);
      const approvedV = covidExemptionData.filter((emp) => emp.testing_status === 'approved');
      console.log('covidExemptionData', covidExemptionData);
      console.log('approvedV', approvedV);
      setApprovedVacc(approvedV);
      const pendingV = covidExemptionData.filter((emp) => emp.testing_status === 'pending');
      console.log('pendingV', pendingV);
      setPendingVacc(pendingV);
      const declinedV = covidExemptionData.filter((emp) => emp.testing_status === 'declined');
      console.log('declinedV', declinedV);
      setDeclinedVacc(declinedV);
    }
    else{
      setNoRecFound(false);
      setWijmoFlexGrid([], 10);
      setNoRecordMessage("No Record Found.")
    }
  };

  useEffect(() => {
    getExemption();
    getExemptionCounts();
    getDocPermission();
     var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, []);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }

  useEffect(() => {
    createTable();
  }, [covidExemptionData]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: (text) => text
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const paggination = (value) => {
    console.log('page value', value);
    setItemPerPage(value);
    setPageApply(true);
    // setPagging(value);
    // this.setState({ pagging: e.target.value})
    setTimeout(() => {
      setWijmoFlexGrid(table, value);
    }, 500);
  };
  const setWijmoFlexGrid = (data, page) => {
    console.log('pagging', data, page);
    let views = new wijmo.CollectionView(data, { pageSize: Number(page) });
    let viewsData = new wijmo.CollectionView(data, { pageSize: Number(data && data.length) });
    console.log('views', views);
    setView(views);
    setViewData(viewsData);
    setViewIndex(views.pageIndex + 1);
    setViewCount(views.pageCount);
    //pagination records
    setTimeout(() => {
      setPagginationNumRecord({
        currentRecord: document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
        totalRecord: data.length
      });
    }, 2000);
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showLocation = (item) => {
    return <span>{item.item.location_name == false ? '' : item.item.location_name}</span>;
  };

  const showExemption = (item) => {
    return (
      <span className='nowrap'>
        {' '}
        {item.item.exemption_type === 'medical' ? (
          <b style={{ color: '#f07668' }}>Medical</b>
        ) : item.item.exemption_type === 'religious' ? (
          <b style={{ color: ' #0000FF' }}>Religious</b>
        ) : (
          ''
        )}
      </span>
    );
  };

  const showComment = (item) => {
    return item.item.exemption_comment && item.item.exemption_comment.length > 50
      ? item.item.exemption_comment.substring(0, 100)
      : item.item.exemption_comment;
  };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showSSN = (item) => {
    if (item.item.newEmpSSN !== false && item.item.newEmpSSN !== null) {
      return <>{'***-**-' + item.item.newEmpSSN?.substr(-4)}</>;
    } else {
      return <>{''}</>;
    }
    // return <>{item.item.emp_ssn != false ? "***-**-" + item.item.emp_ssn.substr(-4) : ''}</>;
    return <>{item.item.emp_ssn != false ? item.item.emp_ssn : ''}</>;
  };
  const showEIN = (item) => {
    return <>{item.item.newEmpEIN != false ? item.item.newEmpEIN : ''}</>;
  };
  const showDL = (item) => {
    return <>{item.item.newDL != false ? item.item.newDL : ''}</>;
  };

  const showReqDate = (item) => {
    return (
      <span className='nowrap'>
        {item.item.create_date ? moment(item.item.create_date).format('MM/DD/YYYY') : ''}
      </span>
    );
  };

  const showStatus = (item) => {
    return (
      <span>
        {item.item.testing_status === 'approved' ? (
          <b style={{ color: '#008000' }}> Approved </b>
        ) : item.item.testing_status === 'pending' ? (
          <b style={{ color: '#f62' }}>Pending</b>
        ) : item.item.testing_status === 'declined' ? (
          <b style={{ color: '#FF0000' }}>Declined</b>
        ) : (
          <b></b>
        )}
      </span>
    );
  };

  const showAppDate = (item) => {
    return (
      <span className='nowrap'>
        {item.item.approval_date ? moment(item.item.approval_date).format('MM/DD/YYYY') : ''}
      </span>
    );
  };

  const showDoc = (item) => {
    return (
      <span className='nowrap'>
        {docPermission && item.item.emp_id ? (
          <button
            onClick={(e) => download(e, item.item.emp_id)}
            style={{
              border: 'none',
              textAlign: 'center',
              borderRadius: 5,
              backgroundColor: '#abc',
              fontweight: 'bold'
            }}
          >
            Preview
          </button>
        ) : (
          item.item.emp_id && <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
        )}
        {/* <button onClick={download}>Download</button> */}
      </span>
    );
  };

  const updateViewPager = (target) => {
    console.log('target', target);
    let btn = wijmo.closest(target, 'button'),
      id = btn ? btn.id : '';
    switch (id) {
      case 'btnFirst':
        i = 1;
        view.moveToFirstPage();
        setTimeout(() => {
          setPagginationNumRecord({
            currentRecord:
              document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
            totalRecord: table.length
          });
        }, 1000);
        break;
      case 'btnPrev':
        if (i <= 1) {
          i = 1;
        } else {
          var a = Math.ceil(table.length / itemsPerPage);
          view.moveToPreviousPage();
          if (a === i) {
            setPagginationNumRecord({
              currentRecord:
                table.length -
                (document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1),
              totalRecord: table.length
            });
          } else {
            setPagginationNumRecord({
              currentRecord: pagginationNumRecord.currentRecord - itemsPerPage,
              totalRecord: table.length
            });
          }
          i--;
        }
        break;
      case 'btnNext':
        if (pageApply) {
          if (
            table.length ===
            document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1
          ) {
          } else {
            i = 2;
          }
          setPageApply(false);
        } else {
          if (i >= Math.ceil(table.length / itemsPerPage)) {
          } else {
            i++;
          }
        }
        var a = Math.ceil(table.length / itemsPerPage);
        if (a === i) {
          setPagginationNumRecord({
            currentRecord: table.length,
            totalRecord: table.length
          });
        } else {
          setPagginationNumRecord({
            currentRecord:
              (document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1) * i,
            totalRecord: table.length
          });
        }
        view.moveToNextPage();
        break;
      case 'btnLast':
        i = Math.ceil(table.length / itemsPerPage);
        view.moveToLastPage();
        setPagginationNumRecord({
          currentRecord: table.length,
          totalRecord: table.length
        });
        break;
    }
    setViewIndex(view.pageIndex + 1);
    setViewCount(view.pageCount);
  };

  const handleDateRangeFilter = () => {
    //if (latestFilterRecord === true) {
    const startDate = searchDateRange[0]?.format('MM/DD/YYYY');
    const endDate = searchDateRange[1]?.format('MM/DD/YYYY');
    if (startDate && endDate) {
      const matchFound = covidExempDataBkup.filter((item) => {
        // const itemDate = item.newReqDate;
        // return itemDate && (itemDate.includes(startDate) || itemDate.includes(endDate));
        const itemDate = new Date(item.newReqDate);
        const startDateDate = new Date(startDate);
        const endDateDate = new Date(endDate);
        return itemDate >= startDateDate && itemDate <= endDateDate;
      });
      if (matchFound.length > 0) {
        setWijmoFlexGrid(matchFound, 10);
        message.success('Match found!');
      } else {
        message.info('No match found !');
        setWijmoFlexGrid(covidExempData, 10);
      }
    } else {
      message.error('Please select a date range!');

      setWijmoFlexGrid(covidExempData, 10);
    }
  };
  const resetDateRangeFilter = () => {
    setSearchDateRange('');
    //zsetLatestFilterRecord(false);
    setWijmoFlexGrid(covidExempData, 10);
  };

  return hasPermission ? (
    <div style={{ padding: 20, marginTop: 20 }}>
      <div className='row'>
        <div className='col-12 col-md-12 col-xl-12' style={{ marginBottom: 20 }}>
          <h6 className='mt-0 mb-1' style={{ color: '#1C3A6A', fontWeight: 700, fontSize: 18 }}>
            COVID Exemption Data
          </h6>
        </div>
      </div>
      {NoRecFound ? (
        table.length > 0 || filterFlag ? (
          <>
            <div className='row'>
              {covidExemptionDataCounts.emp_total_count != 0 &&
                covidExemptionDataCounts.emp_total_count != undefined && (
                  <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                    <Card
                      style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                      className='animated with-shadow'
                      onClick={() => filterTable('all')}
                    >
                      <div className='row'>
                        <div className='col-12' style={{ textAlign: 'center' }}>
                          <div
                            className='count'
                            style={{
                              fontSize: 40,
                              color: '#EF6827',
                              lineHeight: 1.4,
                              fontWeight: 'bold'
                            }}
                          >
                            {covidExemptionDataCounts.emp_total_count}
                          </div>
                          <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                            Total <br />
                            Active Employees
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              {covidExemptionDataCounts.emp_app_count != 0 &&
                covidExemptionDataCounts.emp_app_count != undefined && (
                  <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                    <Card
                      style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                      className='animated with-shadow'
                      onClick={() => filterTable('app')}
                    >
                      <div className='row'>
                        <div className='col-12' style={{ textAlign: 'center' }}>
                          <div
                            className='count'
                            style={{
                              fontSize: 40,
                              color: '#EF6827',
                              lineHeight: 1.4,
                              fontWeight: 'bold'
                            }}
                          >
                            {approvedVacc && approvedVacc.length}
                            {/* {covidExemptionDataCounts.emp_app_count} */}
                          </div>
                          <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                            Employees with Approved Exemptions
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              {covidExemptionDataCounts.emp_pend_count != 0 &&
                covidExemptionDataCounts.emp_pend_count != undefined && (
                  <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                    <Card
                      style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                      className='animated with-shadow'
                      onClick={() => filterTable('pend')}
                    >
                      <div className='row'>
                        <div className='col-12' style={{ textAlign: 'center' }}>
                          <div
                            className='count'
                            style={{
                              fontSize: 40,
                              color: '#EF6827',
                              lineHeight: 1.4,
                              fontWeight: 'bold'
                            }}
                          >
                            {pendingVacc && pendingVacc.length}
                          </div>
                          <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                            Employees with Pending Exemptions
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              {covidExemptionDataCounts.emp_dec_count != 0 &&
                covidExemptionDataCounts.emp_dec_count != undefined && (
                  <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                    <Card
                      style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                      className='animated with-shadow'
                      onClick={() => filterTable('dec')}
                    >
                      <div className='row'>
                        <div className='col-12' style={{ textAlign: 'center' }}>
                          <div
                            className='count'
                            style={{
                              fontSize: 40,
                              color: '#EF6827',
                              lineHeight: 1.4,
                              fontWeight: 'bold'
                            }}
                          >
                            {covidExemptionDataCounts.emp_dec_count}
                          </div>
                          <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                            Employees with Declined Exemptions
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
            </div>

            <br />
            <div>
              <Row>
                <Col span={8}>
                  <RangePicker
                    value={searchDateRange}
                    placeholder={['Start Requested Date', 'End Requested Date']}
                    allowClear={true}
                    style={{ borderRadius: 20, width: '100%', height: 40 }}
                    onChange={(dates) => {
                      setSearchDateRange(dates);
                    }}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={2}>
                  <Button
                    size='small'
                    style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', height: 35 }}
                    onClick={handleDateRangeFilter}
                  >
                    Search
                  </Button>
                </Col>
                {/* <Col span={1}></Col> */}
                <Col span={2}>
                  <Button
                    size='small'
                    style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', height: 35 }}
                    onClick={() => resetDateRangeFilter()}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
              <br />

              <Row gutter={[32, 32]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                  <FlexGridSearch
                    ref={theSearch}
                    placeholder='Search'
                    cssMatch=''
                    style={{ width: '70%', height: '40px' }}
                  />
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                  <div ref={fixedContainerRef}>
                    <Select
                      // showSearch
                      style={{ width: 160 }}
                      placeholder={`Items per page: ${pagging}`}
                      //optionFilterProp='children'
                      onChange={paggination}
                      options={[
                        { label: 10, value: 10 },
                        { label: 20, value: 20 },
                        { label: 50, value: 50 }
                      ]}
                      getPopupContainer={() => fixedContainerRef.current}
                    />
                  </div>
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#3d586a', float: 'right' }}
                    disabled={isExcelPreparing}
                    onClick={exportToExcel}
                  >
                    {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                  </Button>
                </Col>
              </Row>

              {/* to export entire data of table */}
              <FlexGrid
                ref={theGrid}
                style={{ display: 'none' }}
                className='dummyWijmoData'
                itemsSource={viewData}
                initialized={gridInitializedData}
                formatItem={formatItem}
                isReadOnly={true}
              >
                <FlexGridColumn binding='name' header='Employee Name' minWidth={150} width='*' />
                <FlexGridColumn binding='email' header='Email' minWidth={220} width='*' />
                <FlexGridColumn binding='location_name' header='Location' minWidth={150} width='*'>
                  <FlexGridCellTemplate cellType='Cell' template={showLocation} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='exemption_type'
                  header='Exemption Type'
                  // width='12*'
                  minWidth={140}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showExemption} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='exemption_comment'
                  header='Exemption Comment'
                  //
                  minWidth={200}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showComment} />
                </FlexGridColumn>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    return (
                      <FlexGridColumn
                        binding={
                          value === 'SSN'
                            ? 'newEmpSSN'
                            : value === 'EIN'
                            ? 'newEmpEIN'
                            : value === 'Driver License'
                            ? 'newDL'
                            : ''
                        }
                        header={
                          value === 'SSN'
                            ? 'SSN'
                            : value === 'EIN'
                            ? 'EIN'
                            : value === 'Driver License'
                            ? 'Driver License'
                            : ''
                        }
                        // width='12*'

                        minWidth={160}
                        width='*'
                      >
                        <FlexGridCellTemplate
                          cellType='Cell'
                          template={
                            value === 'SSN'
                              ? showSSN
                              : value === 'EIN'
                              ? showEIN
                              : value === 'Driver License'
                              ? showDL
                              : ''
                          }
                        />
                      </FlexGridColumn>
                    );
                  })}

                <FlexGridColumn
                  binding='jobTitle'
                  header='Job Title'
                  // width='10*'
                  minWidth={180}
                  width='*'
                />
                {/* <FlexGridColumn
                  binding='departments'
                  header='Job Position'
                  // width='10*'
                  minWidth={250}
                  width='*'
                /> */}

                <FlexGridColumn
                  binding='program'
                  header='Dot Mode'
                  // width='10*'
                  minWidth={160}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newReqDate'
                  header='Requested Date'
                  // width='11*'
                  minWidth={150}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showReqDate} />
                </FlexGridColumn>

                <FlexGridColumn binding='testing_status' header='Status' minWidth={80} width='*'>
                  <FlexGridCellTemplate cellType='Cell' template={showStatus} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newStatusDate'
                  header='Status Date'
                  //
                  minWidth={150}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showAppDate} />
                </FlexGridColumn>
              </FlexGrid>

              <FlexGrid
                ref={theGrid}
                id='theGridTallRows'
                alternatingRowStep={1}
                // autoRowHeights={true}
                itemsSource={view}
                initialized={gridInitialized}
                formatItem={formatItem}
                itemsPerPage={10}
                isReadOnly={true}
              >
                <div style={{ display: 'none' }}>
                  <wjInput.ListBox
                    className='column-picker'
                    itemsSource={listboxColumns}
                    checkedMemberPath='visible'
                    displayMemberPath='header'
                    lostFocus={hidePicker}
                    initialized={initializedListBox}
                  ></wjInput.ListBox>
                </div>
                <FlexGridColumn binding='name' header='Employee Name' minWidth={180} width='*' />
                <FlexGridColumn binding='email' header='Email' minWidth={220} width='*' />
                <FlexGridColumn binding='location_name' header='Location' minWidth={180} width='*'>
                  <FlexGridCellTemplate cellType='Cell' template={showLocation} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='exemption_type'
                  header='Exemption Type'
                  // width='12*'
                  minWidth={140}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showExemption} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='exemption_comment'
                  header='Exemption Comment'
                  //
                  minWidth={200}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showComment} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newReqDate'
                  header='Requested Date'
                  // width='11*'
                  minWidth={150}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showReqDate} />
                </FlexGridColumn>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    return (
                      <FlexGridColumn
                        binding={
                          value === 'SSN'
                            ? 'newEmpSSN'
                            : value === 'EIN'
                            ? 'newEmpEIN'
                            : value === 'Driver License'
                            ? 'newDL'
                            : ''
                        }
                        header={
                          value === 'SSN'
                            ? 'SSN'
                            : value === 'EIN'
                            ? 'EIN'
                            : value === 'Driver License'
                            ? 'Driver License'
                            : ''
                        }
                        // width='12*'

                        minWidth={160}
                        width='*'
                      >
                        <FlexGridCellTemplate
                          cellType='Cell'
                          template={
                            value === 'SSN'
                              ? showSSN
                              : value === 'EIN'
                              ? showEIN
                              : value === 'Driver License'
                              ? showDL
                              : ''
                          }
                        />
                      </FlexGridColumn>
                    );
                  })}

                <FlexGridColumn
                  binding='jobTitle'
                  header='Job Title'
                  // width='10*'
                  minWidth={180}
                  width='*'
                />
                {/* <FlexGridColumn
                  binding='departments'
                  header='Job Position'
                  // width='10*'
                  minWidth={250}
                  width='*'
                /> */}

                <FlexGridColumn
                  binding='program'
                  header='Dot Mode'
                  // width='10*'
                  minWidth={160}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                </FlexGridColumn>

                <FlexGridColumn binding='testing_status' header='Status' minWidth={80} width='*'>
                  <FlexGridCellTemplate cellType='Cell' template={showStatus} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newStatusDate'
                  header='Status Date'
                  //
                  minWidth={150}
                  width='*'
                >
                  <FlexGridCellTemplate cellType='Cell' template={showAppDate} />
                </FlexGridColumn>

                <FlexGridColumn binding='event_id' header='Documents' minWidth={110} width='*'>
                  <FlexGridCellTemplate cellType='Cell' template={showDoc} />
                </FlexGridColumn>
                <FlexGridFilter
                  filterColumns={[
                    'name',
                    'email',
                    'location_name',
                    'exemption_type',
                    'exemption_comment',
                    'newReqDate',
                    'testing_status',
                    'newStatusDate',
                    'newEmpEIN',
                    'newEmpSSN',
                    'newDL',
                    'jobTitle',
                    'departments',
                    'program'

                  ]}
                />
              </FlexGrid>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={(e) => updateViewPager(e.target)}
                  style={{
                    backgroundColor: '#425a81',
                    color: '#fff',
                    display: 'inline',
                    borderRadius: '5px',
                    // paddingTop: '4px',
                    height: '30px'
                  }}
                >
                  <button
                    id='btnFirst'
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    className='btn'
                  >
                    <span className='wj-glyph-step-backward'></span>
                  </button>
                  <button
                    id='btnPrev'
                    className='btn'
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  >
                    <span className='wj-glyph-left'></span>
                  </button>
                  <p style={{ display: 'inline' }}>
                    &nbsp;&nbsp;&nbsp;Page {viewIndex} of {viewCount}&nbsp;&nbsp;&nbsp;
                  </p>
                  <button
                    id='btnNext'
                    className='btn'
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  >
                    <span className='wj-glyph-right'></span>
                  </button>
                  <button
                    id='btnLast'
                    className='btn'
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  >
                    <span className='wj-glyph-step-forward'></span>
                  </button>
                </div>
                {pagginationNumRecord.currentRecord !== undefined ? (
                  <p style={{ fontWeight: 'bold' }}>
                    {' '}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {`${pagginationNumRecord.currentRecord} out of ${pagginationNumRecord.totalRecord}`}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </>
        ) : (
          <Row>
            <Col span={10}></Col>
            <Col span={8}>
              <Space size='middle' style={{ marginTop: 50 }}>
                <Spin size='large' />
              </Space>
            </Col>
          </Row>
        )
      ) : (
        <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
      )}
      <Modal
        width={1000}
        title='COVID Exemption Document'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls && imageUrls.length > 0 ? (
          imageUrls.map((img) => {
            console.log('img :: ', img);
            return (
              <p>
                {img.fileType &&
                (img.fileType == 'png' ||
                  img.fileType == 'PNG' ||
                  img.fileType == 'jpg' ||
                  img.fileType == 'JPG' ||
                  img.fileType == 'jpeg' ||
                  img.fileType == 'JPEG') ? (
                  <img src={img.url} />
                ) : (
                  <embed src={img.url} width='800px' height='2100px' />
                )}
              </p>
            );
          })
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )}
      </Modal>
      <DarkBackground disappear={isLoaderActive}>
        <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
      </DarkBackground>
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className="icofont-exclamation-tringle" style={{color:'red',fontSize:20}}></i>&nbsp;&nbsp; */}
        {errMsg}
      </p>
    </div>
  );
};

export default CovidExemption;
