import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { useLocation,useParams } from "react-router-dom";
import { useFormik } from 'formik';
import _ from 'underscore';
import axios from 'axios';
import setting from '../../config/setting';
import { Spin, Space, Row, Col, Divider } from 'antd';
import moment from 'moment';
import { Button, Card, Form, Input, Radio, Select,DatePicker, Timeline, Tooltip } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
// import { history } from '../../redux/store';
import {
  ExperimentOutlined,
  MonitorOutlined,
  UserAddOutlined,
  UserOutlined,
  MedicineBoxOutlined,
  CalendarOutlined,
  CheckSquareOutlined
} from '@ant-design/icons/lib';
import { Tabs } from 'antd';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import { Select } from 'antd';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',

    sorter: (a, b) => a.name.length - b.name.length,
    // sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => (
      <div
        //onClick={()=>handleShowInfo}
        style={{ cursor: 'pointer' }}
      >
        {name}
      </div>
    )
  },
  {
    title: 'Address',
    key: 'address',
    dataIndex: 'address',

    sorter: (a, b) => a.address.length - b.address.length,
    // sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (address) => (
      <div
        //onClick={()=>handleShowInfo}
        style={{ cursor: 'pointer' }}
      >
        {address}
      </div>
    )
  },
  {
    title: 'Contact Number',
    key: 'phone',
    dataIndex: 'phone',

    sorter: (a, b) => a.phone.length - b.phone.length,
    // sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (phone) => (
      <div
        //onClick={()=>handleShowInfo}
        style={{ cursor: 'pointer' }}
      >
      {phone}
      </div>
    )
  }
];

const IntakeForm = () => {

  const { innerWidth: width, innerHeight: height } = window;

    const [DataResponse, setDataResponse] = useState([]);
    //Physical Form
    const [employerName, setEmployerName] = useState('');

    const [Name, setName] = useState('');
    const [Date, setDate] = useState('');
    const [ILAWorkNumber, setILAWorkNumber] = useState('');
    const [Telephone, setTelephone] = useState('');
    const [EmergencyContact, setEmergencyContact] = useState('');
    const [Telephone2, setTelephone2] = useState('');
    const [RelationEmergerncyContact, setRelationEmergerncyContact] = useState('');
    const [DateofLastTetanusVaccination, setDateofLastTetanusVaccination] = useState('');
   
    const [PastMedHistory, setPastMedHistory] = useState([
        {
          prior_illness: {reason:'',date:''},
          injury: {reason:'',date:''},
          hospitalization: {reason:'',date:''},
          surgery: {reason:'',date:''},
          trauma: {reason:'',date:''},
        }
      ]);

      
      const [MEDICALEXAMINERCOMMENTSONHEALTHHISTORY, setMEDICALEXAMINERCOMMENTSONHEALTHHISTORY] = useState([
        {
        comments:'',
        Medical_Examiners_Name:'',
         
        }
    ])

    const [MEDICALEXAMINERCOMMENTSONHEALTHHISTORYTrue, setMEDICALEXAMINERCOMMENTSONHEALTHHISTORYTrue] = useState(false)

    const [PHYSICALEXAMINATION, setPHYSICALEXAMINATION] = useState([
        {
            Height:'',
            Weight:'',
         
        }
    ])

    const [PHYSICALEXAMINATIONTrue, setPHYSICALEXAMINATIONTrue] = useState(false)

    const [URINALYSISFINDINGS, setURINALYSISFINDINGS] = useState([
        {
            SP_GR:'',
            Protein:'',
            Blood:'',
            Sugar:'',
            Ketones:'',
            other_testing_desc:'',
         
        }
    ])
    const [URINALYSISFINDINGSTrue, setURINALYSISFINDINGSTrue] = useState(false)
    const [VISION, setVISION] = useState([
        {
            Right_Eye:{Uncorrected:'',Corrected:'',Horizontal_Field_of_Vision:''},
            Left_Eye:{Uncorrected:'',Corrected:'',Horizontal_Field_of_Vision:''},
            Both_Eyes:{Uncorrected:'',Corrected:'',Horizontal_Field_of_Vision:''},

            Applicant_can_recognize_and_distinguish_among_traffic_control_signals:'',
            Applicant_meets_visual_acuity_requirement_only_when_wearing_corrective_lenses:'',
            Monocular_Vision:''
         
        }
    ])
    const [VISIONTrue, setVISIONTrue] = useState(false)
    const [HEARING, setHEARING] = useState([
        {
            // Check_if_hearing_aid_used_for_tests:'',
            // Check_if_hearing_aid_required_to_meet_standard:'',
            Check_if_hearing_aid_used_for_tests_or_meet_standard:'',
            Record_distance_from_individual:{Right_Ear:'',Left_Ear:''},
            If_audiometer_is_used:{Right_Ear:'',Left_Ear:''},
         
        }
    ])

    const [HEARINGTrue, setHEARINGTrue] = useState(false)

    const [BLOODPRESSUREPULSERATE, setBLOODPRESSUREPULSERATE] = useState([
        {
            Blood_Pressure:'',
            Temperature:'',
            Pulse_Rate:'',
            Record_Pulse_Rate:'',
         
        }
    ])

    const [BLOODPRESSUREPULSERATETrue, setBLOODPRESSUREPULSERATETrue] = useState(false)

    const [PHYSICALEXAMINATION2, setPHYSICALEXAMINATION2] = useState([
        {
            General_Appearance:'',
            Eyes:'',
            Ears:'',
            Mouth_and_Throat:'',
            Heart:'',
            Lungs_and_chest:'',
            Abdomen_and_Viscera:'',
            Vascular_System:'',
            Genito_urinary_System:'',
            Extremities:'',
            Spine_other_musculoskeletal:'',
            Neurological:'',
            
        }
    ])

    const [PHYSICALEXAMINATION2True, setPHYSICALEXAMINATION2True] = useState(false)

    const [Comments2, setComments2] = useState('');

      const [healthHistory, sethealthHistory] = useState([
        {
          injury:{option:'',desc:''},
          head_brain_injury:'',
          Seizures:'',
          Medication_for_seizures_epilepsy:'',
          Eye_disorders_or_impaired_vision:{option:'',desc:''},
          Ear_disorders_loss_of_hearing:'',
          Heart_disease:'',

          Medication_for_heart_condition:'',
          Heart_surgery:'',
          High_blood_pressure:'',
          Medication_for_high_blood_pressure:'',
          Muscular_disease:'',
          Shortness_of_breath:'',

          Lung_disease_emphysema:'',
          Kidney_disease_dialysis:'',
          Liver_disease:'',
          Digestive_problems:'',
          Diabetes_or_elevated_blood_sugar:{option:'',desc:''},
          Nervous_or_psychiatric_disorder:'',

          Medication_for_nervous_or_psychiatric_disorder:'',
          Loss_of_or_altered_consciousness:'',
          Fainting_dizziness:'',
          Sleep_disorders_pauses_in_breathing:'',
          Stroke_or_paralysis:'',
          Missing_or_impaired_hand_arm_foot:'',
          Spinal_injury_or_disease:'',
          Chronic_low_back_pain:'',
          Regular_frequent_alcohol_use:'',
          Narcotic_or_habit_forming_drug_use:'',
          diagnosis:''
         

        }
      ]);

      const [HealthHistoryTrue, setHealthHistoryTrue] = useState(false);

   
      const [medicationArrOne, setMedicationArrOne] = useState([
        {
          medication:'',
          dose:'',
          route:'',
          frequency:'',
          reason:'',
          how_long_taken:'',
          prescribed_by:''
        }
      ]);
      const [newChangeOne, setNewChangeOne] = useState(false);

      const [allergyArrOne, setAllergyArrOne] = useState([
        {
          allergy_type: '',
          reaction_type:'',
        }
      ]);
      const [newChangeAllergyOne, setNewChangeAllergyOne] = useState(false);

      const [MedicationTrue, setMedicationTrue] = useState(false);
      const [AllrTrue, setAllrTrue] = useState(false);
      const [PastMEdTrue, setPastMEdTrue] = useState(false);

      ////Intake Form values

      const [patientFirstName, setPatientFirstName] = useState('');
      const [patientMiddleName, setPatientMiddleName] = useState('');
      const [patientLastName, setPatientLastName] = useState('');
      const [DateofBirth, setDateofBirth] = useState('');
      const [gender, setGender] = useState('');
      const [Nationality, setNationality] = useState('');
      const [EINSSNPassportno, setEINSSNPassportno] = useState('');
      const [JobTitle, setJobTitle] = useState('');
      const [MobileNumber, setMobileNumber] = useState('');
      const [Email, setEmail] = useState('');
      const [Alternatecontactinformation, setAlternatecontactinformation] = useState('');
      
      const [TrackVisits, setTrackVisits] = useState([
        {
            firstVisitDate:'',
            Timein:'',
          Timeout:'',
          CareProvided:'',
          Height:{feet:'',inch:''},
          Weight:'',
        }
      ]);
      const [TrackVisitsTrue, setTrackVisitsTrue] = useState(false);


      const [medicationArr, setMedicationArr] = useState([
        {
          medication:'',
          dose:'',
          route:'',
          frequency:'',
          reason:'',
          how_long_taken:'',
          prescribed_by:''
        }
      ]);
      const [newChange, setNewChange] = useState(false);
      
      const [allergyArr, setAllergyArr] = useState([
        {
          allergy_type: '',
          reaction_type:'',
        }
      ]);
      const [newChangeAllergy, setNewChangeAllergy] = useState(false);


      const [PastMedicalSurgicalTraumaHistory, setPastMedicalSurgicalTraumaHistory] = useState([
        {
            Priorillness:'',
            Injury:'',
            Hospitalization:'',
            Surgery:'',
            Trauma:''
        }
      ]);
      const [PastMedicalSurgicalTraumaHistoryTrue, setPastMedicalSurgicalTraumaHistoryTrue] = useState(false);

      
      const [Howlonghowmuch, setHowlonghowmuch] = useState([
        {
            Smoking:'',
            Alcohol:'',
            Sleephours:'',
            Exercise:'',
            Weight:{lossgain:'',suddenslow:''}
        }
      ]);
      const [HowlonghowmuchTrue, setHowlonghowmuchTrue] = useState(false);

      const [VisionHearing, setVisionHearing] = useState([
        {
            Vision:{option:'',desc:''},
            Hearing:{option:'',desc:''},
            Pulse:{option1:'',option2:''},
            BloodPressure:{option1:'',option2:''},
            Edema:{option:'',desc:''},
            BloodSugar:{Fasting:'',NonFasting:'',Timesincelastmedication:''},
            Pacemaker:{Kind:'',Timeofplacement:'',Place:'',LastChecked:''},
            //BloodSugar:{option:'',desc:''},
            Respirationproblems:{option:'',desc:''},
            LungSounds:{option:'',desc:''},
            Cough:{option:'',ProductiveAmount:'',color:'',howoften:''},
        }
      ]);
      const [VisionHearingTrue, setVisionHearingTrue] = useState(false);

      const [ChangeTrueSearch, setChangeTrueSearch] = useState(false);

  

      const email = useParams();
      
      useEffect(() => {

        console.log("email params::",email.email);

        axios.get(setting.serverUrl + '/api/EmployeeInfo', {
            // axios.get('http://localhost:3001/api/saveTest', {
            params:
            {
              email: localStorage.getItem('myData'),
              user: localStorage.getItem('myData'),
              pass: localStorage.getItem('myPass'),
              email:email.email,
          
            }
          }).then(response => {
            console.log("Respone from EmployeInfo::", response.data)
            console.log("search success")
            setDataResponse(response.data)
            setPatientFirstName(response.data.length>0 ? response.data[0].first_name:'')
            setPatientLastName(response.data.length>0 ? response.data[0].last_name:'')
            setChangeTrueSearch(true)
            if (response && response.status == 200) {
      
              //history.push({ pathname: `/vertical/testingEvents` });
      
              //setRes(response.data)
      
            }
          })
      
      }, []);

      console.log("DataResponse::",DataResponse)

      

      function onChange(date, dateString) {
        console.log(date, dateString);
      }

      function onBlur() {
        console.log('blur');
      }

      function onFocus() {
        console.log('focus');
      }

      function onSearch(val) {
        console.log('search:', val);
      }

      // console.log("locationn", loc)
      // console.log("setArr NEw", arr)

      const medication = () => {
        console.log('medication');

        // setChangeTrue(true);
      };

      const allergy = () => {
        console.log('allergy');

        // setChangeTrueallergy(true);
      };
      

      const saveFormIntake = () => {
        console.log("saveFormIntake",gender);

        axios.get(setting.serverUrl + '/api/saveFormIntake', {
            // axios.get('http://localhost:3001/api/saveTest', {
            params:
            {
              email: localStorage.getItem('myData'),
              user: localStorage.getItem('myData'),
              pass: localStorage.getItem('myPass'),
              id : DataResponse.length>0?DataResponse[0].id:'',
              patientFirstName: patientFirstName,
              patientMiddleName: patientMiddleName,
              patientLastName: patientLastName,
              DateofBirth:DateofBirth,
              gender: gender,
              Nationality: Nationality,
              EINSSNPassportno:EINSSNPassportno,
              JobTitle:JobTitle,
              MobileNumber:MobileNumber,
              Email:Email,
              Alternatecontactinformation:Alternatecontactinformation,
              TrackVisits:TrackVisits,
              medicationArr:medicationArr,
              allergyArr:allergyArr,
              PastMedicalSurgicalTraumaHistory:PastMedicalSurgicalTraumaHistory,
              Howlonghowmuch:Howlonghowmuch,
              VisionHearing:VisionHearing
            }
          }).then(response => {
            console.log("Respone from saveFormIntake ", response.data)
      
            if (response && response.status == 200) {
      
              //history.push({ pathname: `/vertical/testingEvents` });
      
              //setRes(response.data)
      
            }
          })
      }

      

  return (
    <div style={{padding:20, overflow: 'auto', height: height}}>
        {ChangeTrueSearch && DataResponse.length>0 ?
      
        <div style={{ padding:20}}>

          <span
            style={{
              fontSize: 'calc( 16px + (24 - 20) * (100vw - 400px) / (800 - 400) )',
              marginTop: 10,
              fontWeight: 700,
              textDecoration: 'underline'
            }}
            //   style={{fontWeight:700,marginTop:10,fontSize:15,textDecoration:'underline'}}
          >
            Intake form
          </span>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Patient First Name:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small' 
                  readOnly
                  value={patientFirstName}
                  onChange={(e) =>setPatientFirstName(e.target.value)}
                  placeholder='Patient First Name' />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Patient Middle Name:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  value={patientMiddleName}
                  onChange={(e) =>setPatientMiddleName(e.target.value)}
                  placeholder='Patient Middle Name' />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Patient Last Name:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  readOnly
                  value={patientLastName}
                  onChange={(e) =>setPatientLastName(e.target.value)}
                  placeholder='Patient Last Name' />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Date of Birth:
                  </span>
                </Col>
                <Col span={12}>
                <DatePicker 
                onChange={(date, dateString) =>setDateofBirth(dateString)}
                //onChange={onChange}
                 />
                  {/* <Input size='small' placeholder='Date of Birth' /> */}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
          <Col span={12}>
              <Row>
          <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Gender:
                  </span>
                </Col>
                <Col span={12}>
                  
                <Radio.Group name='radiogroup' defaultValue={1}
                onChange={(e)=>setGender(e.target.value)}
                >
                <Radio value='Male' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Male</Radio>
                <Radio value='Female' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Female</Radio>
              </Radio.Group>
                </Col>
              </Row>
              </Col>
              </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Nationality:
                  </span>
                </Col>
                <Col span={12}>
                  <Input
                  value={Nationality}
                  onChange={(e) =>setNationality(e.target.value)}
                  size='small' placeholder='Nationality' />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    EIN/SSN/Passport no:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small' 
                  value={EINSSNPassportno}
                  onChange={(e) =>setEINSSNPassportno(e.target.value)}
                  placeholder='EIN/SSN/Passport no' />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Job Title:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  value={JobTitle}
                  onChange={(e) =>setJobTitle(e.target.value)}
                  placeholder='Job Title' />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Mobile Number:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  value={MobileNumber}
                  onChange={(e) =>setMobileNumber(e.target.value)}
                  placeholder='Mobile Number' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Email:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  value={Email}
                  onChange={(e) =>setEmail(e.target.value)}
                  placeholder='Email' />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Alternate contact information:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  value={Alternatecontactinformation}
                  onChange={(e) =>setAlternatecontactinformation(e.target.value)}
                  placeholder='Alternate contact information' />
                </Col>
              </Row>
            </Col>
          </Row>
          
          <br />
          <br />
          <span
            style={{ fontWeight: 700, marginTop: 10, fontSize: 15, textDecoration: 'underline' }}
          >
            Track Visits:
          </span>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    1st Visit Date:
                  </span>
                </Col>
                <Col span={12}>
                <DatePicker 
                onChange={(date, dateString) =>{TrackVisits[0].firstVisitDate = dateString;setTrackVisitsTrue(!TrackVisitsTrue)}}
                //onChange={onChange}
                 />
                  {/* <Input size='small' placeholder='1st Visit Date' /> */}
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Time in:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  value={TrackVisits[0].Timein}
                  onChange={(e) =>{TrackVisits[0].Timein = e.target.value;setTrackVisitsTrue(!TrackVisitsTrue)}}
                  placeholder='Time in' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Time out:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  value={TrackVisits[0].Timeout}
                  onChange={(e) =>{TrackVisits[0].Timeout = e.target.value;setTrackVisitsTrue(!TrackVisitsTrue)}}
                  placeholder='Time out' />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Care Provided:
                  </span>
                </Col>
                <Col span={12}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                    // showSearch
                    size='small'
                    value={TrackVisits[0].CareProvided}
                    onChange={(e) =>{TrackVisits[0].CareProvided = e;setTrackVisitsTrue(!TrackVisitsTrue)}}
                    //style={{ width: 200 }}
                    placeholder='Select a person'
                    //optionFilterProp='children'
                    //onChange={onChange}
                    
                  >
                    <Option value='Clinic'>Clinic</Option>
                    <Option value='Vessel'>Vessel</Option>
                    <Option value='Hotel'>Hotel</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={14}>
              <Row>
                <Col span={8}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Height:
                  </span>
                </Col>
                <Col span={14}>
                  <Row>
                    <Col span={12}>
                      <Row>
                        <Col span={10}>
                          <span
                            style={{
                              fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                            }}
                          >
                            {' '}
                            Feet:{' '}
                          </span>
                        </Col>
                        <Col span={10}>
                          <Input size='small'
                           value={TrackVisits[0].Height.feet}
                           onChange={(e) =>{TrackVisits[0].Height.feet = e.target.value;setTrackVisitsTrue(!TrackVisitsTrue)}}
                          placeholder='Feet' />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={10}>
                          <span
                            style={{
                              fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                            }}
                          >
                            {' '}
                            Inch:{' '}
                          </span>
                        </Col>
                        <Col span={10}>
                          <Input size='small'
                          value={TrackVisits[0].Height.inch}
                          onChange={(e) =>{TrackVisits[0].Height.inch = e.target.value;setTrackVisitsTrue(!TrackVisitsTrue)}}
                          placeholder='Inches' />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Weight:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  value={TrackVisits[0].Weight}
                  onChange={(e) =>{TrackVisits[0].Weight = e.target.value;setTrackVisitsTrue(!TrackVisitsTrue)}}
                  placeholder='Lbs or Kg' />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <hr />
<br/>
          <Row>
            <Col span={12}>
              <Button
                variant="contained"
                //  disabled={!this.state.section4}
                style={{
                  backgroundColor: "rgba(255,122,90,0.90)",
                  color: "white",
                  width: 100,
                }}
                onClick={() => {
                  setNewChange(false)
                  medicationArr.push({
                    medication:'',
                    dose:'',
                    route:'',
                    frequency:'',
                    reason:'',
                    how_long_taken:'',
                    prescribed_by:''
                  });
                  setNewChange(!newChange)
                  
                  console.log("medicationArr", medicationArr);
                }}
              >
                Add
                      </Button>
            </Col>
          </Row>
          
          {medicationArr.length > 0 &&
                          medicationArr.map((data, idx) => (
          <div key={idx} style={{border:'1px solid grey', borderRadius:25,padding:20,marginTop:10}}>
          <Row style={{ marginTop: 0 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{
                      fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )',
                      fontWeight: 700
                    }}
                  >
                    Medications:
                  </span>
                </Col>
                <Col span={12}>
                  <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                  }
                  size='small' onChange={(e)=>{medicationArr[idx].medication = e;setNewChange(!newChange)}}>
                    <Option value='prescription'>prescription</Option>
                    <Option value='over_the_counter'>over the counter</Option>
                    <Option value='vitamins'>vitamins</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* {changeTrue ? ( */}
            <div>
              <Row style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Dose:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input 
                      value={medicationArr[idx].dose}
                       onChange={(e)=>{medicationArr[idx].dose = e.target.value;setNewChange(!newChange)}}
                      size='small' placeholder='' />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Route:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input 
                      value={medicationArr[idx].route}
                      onChange={(e)=>{medicationArr[idx].route = e.target.value;setNewChange(!newChange)}}
                      size='small' placeholder='' />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Frequency:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input size='small'
                       value={medicationArr[idx].frequency}
                       onChange={(e)=>{medicationArr[idx].frequency = e.target.value;setNewChange(!newChange)}}
                      placeholder='' />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Reason:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input
                       value={medicationArr[idx].reason}
                       onChange={(e)=>{medicationArr[idx].reason = e.target.value;setNewChange(!newChange)}}
                      size='small' placeholder='' />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        How long taken:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input size='small'
                       value={medicationArr[idx].how_long_taken}
                       onChange={(e)=>{medicationArr[idx].how_long_taken = e.target.value;setNewChange(!newChange)}}
                      placeholder='' />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Prescribed by:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input size='small'
                       value={medicationArr[idx].prescribed_by}
                       onChange={(e)=>{medicationArr[idx].prescribed_by = e.target.value;setNewChange(!newChange)}}
                      placeholder='' />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            </div>
                          ))}
        

          <hr style={{ marginTop: 15 }} />
          <br/>
          <Row>
            <Col span={12}>
              <Button
                variant="contained"
                //  disabled={!this.state.section4}
                style={{
                  backgroundColor: "rgba(255,122,90,0.90)",
                  color: "white",
                  width: 100,
                }}
                onClick={() => {
                    setNewChangeAllergy(false)
                  allergyArr.push({
                    allergy_type:'',
                    reaction_type:'',
                  });
                  setNewChangeAllergy(!newChangeAllergy)
                  
                  console.log("allergyArr", allergyArr);
                }}
              >
                Add
                      </Button>
            </Col>
          </Row>
          {allergyArr.length > 0 &&
                          allergyArr.map((data, idx) => (
          <div key={idx} style={{border:'1px solid grey', borderRadius:25,padding:20,marginTop:10}}>
          <Row style={{ marginTop: 0 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{
                      fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )',
                      fontWeight: 700
                    }}
                  >
                    Allergies:
                  </span>
                </Col>
                <Col span={12}>
                  <Select size='small'
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                  }
                      onChange={(e)=>{allergyArr[idx].allergy_type = e;setNewChangeAllergy(!newChangeAllergy)}}>
                    <Option value='food'>food</Option>
                    <Option value='drug'>drug</Option>
                    <Option value='others'>others</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            {/* {changeTrueallergy ? ( */}
              <Col span={12}>
                <Row>
                  <Col span={10}>
                    <span
                      style={{
                        fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                      }}
                    >
                      Reaction type:
                    </span>
                  </Col>
                  <Col span={12}>
                    <Input size='small' 
                     value={allergyArr[idx].reaction_type}
                     onChange={(e)=>{allergyArr[idx].reaction_type = e.target.value;setNewChangeAllergy(!newChangeAllergy)}}
                    placeholder='' />
                  </Col>
                </Row>
              </Col>
           
          </Row>
          </div>
          ))}
          <br />
          <hr/>
          <br/>
          <span
            style={{
              fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )',
              fontWeight: 700,
              textDecoration: 'underLine'
            }}
          >
            Past Medical, Surgical & Trauma History:
          </span>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Prior illness:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                  
                  value={PastMedicalSurgicalTraumaHistory[0].Priorillness}
                  onChange={(e) =>{PastMedicalSurgicalTraumaHistory[0].Priorillness = e.target.value;setPastMedicalSurgicalTraumaHistoryTrue(!PastMedicalSurgicalTraumaHistoryTrue)}}
                  placeholder='' />
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Injury:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                   value={PastMedicalSurgicalTraumaHistory[0].Injury}
                   onChange={(e) =>{PastMedicalSurgicalTraumaHistory[0].Injury = e.target.value;setPastMedicalSurgicalTraumaHistoryTrue(!PastMedicalSurgicalTraumaHistoryTrue)}}
                  placeholder='' />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Hospitalization:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                   value={PastMedicalSurgicalTraumaHistory[0].Hospitalization}
                   onChange={(e) =>{PastMedicalSurgicalTraumaHistory[0].Hospitalization = e.target.value;setPastMedicalSurgicalTraumaHistoryTrue(!PastMedicalSurgicalTraumaHistoryTrue)}}
                  placeholder='' />
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Surgery:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                   value={PastMedicalSurgicalTraumaHistory[0].Surgery}
                   onChange={(e) =>{PastMedicalSurgicalTraumaHistory[0].Surgery = e.target.value;setPastMedicalSurgicalTraumaHistoryTrue(!PastMedicalSurgicalTraumaHistoryTrue)}}
                  placeholder='' />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Trauma:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small'
                   value={PastMedicalSurgicalTraumaHistory[0].Trauma}
                   onChange={(e) =>{PastMedicalSurgicalTraumaHistory[0].Trauma = e.target.value;setPastMedicalSurgicalTraumaHistoryTrue(!PastMedicalSurgicalTraumaHistoryTrue)}}
                  placeholder='' />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <hr/>
          <br/>
          <span
            style={{
              fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )',
              fontWeight: 700,
              textDecoration: 'underLine'
            }}
          >
                How long/how much:
          </span>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Smoking:
                  </span>
                </Col>
                <Col span={12}>
                  <TextArea 
                  value={Howlonghowmuch[0].Smoking}
                  onChange={(e) =>{Howlonghowmuch[0].Smoking = e.target.value;setHowlonghowmuchTrue(!HowlonghowmuchTrue)}}
                  
                  rows={3}/>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Alcohol:
                  </span>
                </Col>
                <Col span={12}>
                  <TextArea 
                   value={Howlonghowmuch[0].Alcohol}
                   onChange={(e) =>{Howlonghowmuch[0].Alcohol = e.target.value;setHowlonghowmuchTrue(!HowlonghowmuchTrue)}}
                   
                  rows={3}/>
                </Col>
              </Row>
            </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Sleep hours:
                  </span>
                </Col>
                <Col span={12}>
                  <TextArea 
                   value={Howlonghowmuch[0].Sleephours}
                   onChange={(e) =>{Howlonghowmuch[0].Sleephours = e.target.value;setHowlonghowmuchTrue(!HowlonghowmuchTrue)}}
                   
                  rows={3}/>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Exercise:
                  </span>
                </Col>
                <Col span={12}>
                  <TextArea
                   value={Howlonghowmuch[0].Exercise}
                   onChange={(e) =>{Howlonghowmuch[0].Exercise = e.target.value;setHowlonghowmuchTrue(!HowlonghowmuchTrue)}}
                   
                  rows={3}/>
                </Col>
              </Row>
            </Col>
            </Row>
<br/>
            <Row style={{ marginTop: 20 }}>
            <Col span={8}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontWeight:700, fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Weight:
                  </span>
                </Col>
                <Col span={12}>
                <Radio.Group name='radiogroup' defaultValue={1}
                  onChange={(e) =>{Howlonghowmuch[0].Weight.lossgain = e.target.value;setHowlonghowmuchTrue(!HowlonghowmuchTrue)}}
                  >
                <Radio value='Loss' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Loss</Radio>
                <Radio value='Gain' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Gain</Radio>
              </Radio.Group>

              
                </Col>
              </Row>
            </Col>

            <Col span={12}>
            <Radio.Group name='radiogroup' defaultValue={1}
                  onChange={(e) =>{Howlonghowmuch[0].Weight.suddenslow = e.target.value;setHowlonghowmuchTrue(!HowlonghowmuchTrue)}}
                  >
                <Radio value='Sudden' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Sudden</Radio>
                <Radio value='Slow' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Slow</Radio>
              </Radio.Group>
            </Col>
            </Row>
<br/>
<hr/>
<br/>
<span
            style={{
              fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )',
              fontWeight: 700,
              textDecoration: 'underLine'
            }}
          >
                Vision/Hearing:
          </span>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Vision (With glasses):
                  </span>
                </Col>
                <Col span={12}>
                <Radio.Group name='radiogroup' defaultValue={1}
                onChange={(e) =>{VisionHearing[0].Vision.option = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                >
                <Radio value='Normal' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Normal</Radio>
                <Radio value='Problem' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Problem</Radio>
              </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
                <TextArea
                value={VisionHearing[0].Vision.desc}
                onChange={(e) =>{VisionHearing[0].Vision.desc = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                
                rows={2}/>
            </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Hearing:
                  </span>
                </Col>
                <Col span={12}>
                <Radio.Group name='radiogroup' defaultValue={1}
                onChange={(e) =>{VisionHearing[0].Hearing.option = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                
                >
                <Radio value='Normal' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Normal</Radio>
                <Radio value='Problem' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Problem</Radio>
              </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
                <TextArea
                value={VisionHearing[0].Hearing.desc}
                onChange={(e) =>{VisionHearing[0].Hearing.desc = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                
                rows={2}/>
            </Col>
            </Row>
<br/>
<hr/>
<br/>

<Row style={{ marginTop: 20 }}>
            <Col span={8}>
              <Row>
                <Col span={6}>
                  <span
                    style={{ fontWeight:700,fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Pulse:
                  </span>
                </Col>
                <Col span={12}>
                <Radio.Group name='radiogroup' defaultValue={1}
                onChange={(e) =>{VisionHearing[0].Pulse.option1 = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                
                >
                <Radio value='Apical' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Apical</Radio>
                <Radio value='Radial' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Radial</Radio>
              </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
            <Radio.Group name='radiogroup' defaultValue={1}
            onChange={(e) =>{VisionHearing[0].Pulse.option2 = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                
            >
                <Radio value='Regular' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Regular</Radio>
                <Radio value='Irregular' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Irregular</Radio>
              </Radio.Group>
            </Col>
            </Row>
            <br/>
<hr/>
<br/>

            <Row style={{ marginTop: 20 }}>
            <Col span={8}>
              <Row>
                <Col span={6}>
                  <span
                    style={{ fontWeight:700,fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Blood Pressure:
                  </span>
                </Col>
                <Col span={12}>
                <Radio.Group name='radiogroup' defaultValue={1}
                 onChange={(e) =>{VisionHearing[0].BloodPressure.option1 = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
              
                >
                <Radio value='Left' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Left</Radio>
                <Radio value='Right' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Right</Radio>
              </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
            <Radio.Group name='radiogroup' defaultValue={1}
             onChange={(e) =>{VisionHearing[0].BloodPressure.option2 = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
              
            >
                <Radio value='Sitting' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Sitting</Radio>
                <Radio value='Standing' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Standing</Radio>
                <Radio value='Laying' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Laying</Radio>
              </Radio.Group>
            </Col>
            </Row>
            <br/>
<hr/>
<br/>
            <Row style={{ marginTop: 20 }}>
            <Col span={14}>
              <Row>
                <Col span={4}>
                  <span
                    style={{ fontWeight:700,fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Edema:
                  </span>
                </Col>
                <Col span={18}>
                <Radio.Group name='radiogroup' defaultValue={1}
                 onChange={(e) =>{VisionHearing[0].Edema.option = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
             
                >
                <Radio value='Pitting' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Pitting</Radio>
                <Radio value='None_Pitting' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>None Pitting</Radio>
                <Radio value='Positional' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Positional</Radio>
              </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
           <Input size="small"
            value={VisionHearing[0].Edema.desc}
            onChange={(e) =>{VisionHearing[0].Edema.desc = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
            
           placeholder="Location" />
            </Col>
            </Row>
            <br/>
<hr/>
<br/>

            <Row style={{ marginTop: 20 }}>
            <Col span={8}>
              <Row>
                <Col span={8}>
                  <span
                    style={{ fontWeight:700,fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Blood Sugar:
                  </span>
                </Col>
                <Col span={12}>
                <span
                    style={{ fontWeight:700,fontSize: 'calc( 8px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Fasting:
                  </span>
                  <Input size="small" 
                   value={VisionHearing[0].BloodSugar.Fasting}
                   onChange={(e) =>{VisionHearing[0].BloodSugar.Fasting = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                   
                  />
                </Col>
              </Row>
            </Col>
            
            <Col span={4}>
            <span
                    style={{ fontWeight:700,fontSize: 'calc( 8px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Non Fasting:
                  </span>
                  <Input size="small" 
                  value={VisionHearing[0].BloodSugar.NonFasting}
                  onChange={(e) =>{VisionHearing[0].BloodSugar.NonFasting = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                  
                  />
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
            <span
                    style={{ fontWeight:700,fontSize: 'calc( 8px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Time since last medication:
                  </span>
                  <Input size="small" 
                  value={VisionHearing[0].BloodSugar.Timesincelastmedication}
                  onChange={(e) =>{VisionHearing[0].BloodSugar.Timesincelastmedication = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                  
                  />
            </Col>
            </Row>
            <br/>
<hr/>
<br/>
            
            <Row style={{ marginTop: 20 }}>
            <Col span={8}>
              <Row>
                <Col span={8}>
                  <span
                    style={{ fontWeight:700,fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Pacemaker:
                  </span>
                </Col>
                <Col span={12}>
                <span
                    style={{ fontWeight:700,fontSize: 'calc( 8px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                Kind:
                  </span>
                  <Input size="small" 
                   value={VisionHearing[0].Pacemaker.Kind}
                   onChange={(e) =>{VisionHearing[0].Pacemaker.Kind = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                   
                  />
                </Col>
              </Row>
            </Col>
            
            <Col span={4}>
            <span
                    style={{ fontWeight:700,fontSize: 'calc( 8px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Time of placement:
                  </span>
                  <Input size="small" 
                   value={VisionHearing[0].Pacemaker.Timeofplacement}
                   onChange={(e) =>{VisionHearing[0].Pacemaker.Timeofplacement = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                   
                  />
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
            <span
                    style={{ fontWeight:700,fontSize: 'calc( 8px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Place:
                  </span>
                  <Input size="small" 
                   value={VisionHearing[0].Pacemaker.Place}
                   onChange={(e) =>{VisionHearing[0].Pacemaker.Place = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                   
                  />
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
            <span
                    style={{ fontWeight:700,fontSize: 'calc( 8px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Last Checked:
                  </span>
                  <DatePicker onChange={(date, dateString) =>{VisionHearing[0].Pacemaker.LastChecked= dateString;setVisionHearingTrue(!VisionHearingTrue)}} />
                  {/* <Input size="small" 
                   value={VisionHearing[0].Pacemaker.LastChecked}
                   onChange={(e) =>{VisionHearing[0].Pacemaker.LastChecked = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
                   
                  /> */}
            </Col>
            </Row>
            <br/>
<hr/>
<br/>
            <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{fontWeight:700, fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Respiration problems:
                  </span>
                </Col>
                <Col span={12}>
                <Radio.Group name='radiogroup' defaultValue={1} 
                 onChange={(e) =>{VisionHearing[0].Respirationproblems.option = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
             
                
                >
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
                <TextArea 
                value={VisionHearing[0].Respirationproblems.desc}
                 onChange={(e) =>{VisionHearing[0].Respirationproblems.desc = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
             
                rows={2} placeholder="Explain here"/>
            </Col>
            </Row>
            <br/>
<hr/>
<br/>
            <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={5}>
                  <span
                    style={{ fontWeight:700,fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Lung Sounds:
                  </span>
                </Col>
                <Col span={12}>
                <Radio.Group name='radiogroup' defaultValue={1}
                //  value={VisionHearing[0].Respirationproblems.desc}
                 onChange={(e) =>{VisionHearing[0].LungSounds.option = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
             
                >
                <Radio value='Clear' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Clear</Radio>
                <Radio value='Normal/abnormal' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Normal/Abnormal (wheezes, crackles, diminished)</Radio>
              </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
                <TextArea 
                 value={VisionHearing[0].LungSounds.desc}
                 onChange={(e) =>{VisionHearing[0].LungSounds.desc = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
             
                rows={2} placeholder="Explain position here"/>
            </Col>
            </Row>
            <br/>
<hr/>
<br/>
            <Row style={{ marginTop: 20 }}>
            <Col span={8}>
              <Row>
                <Col span={5}>
                  <span
                    style={{ fontWeight:700,fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Cough:
                  </span>
                </Col>
                <Col span={12}>
                <Radio.Group name='radiogroup' defaultValue={1}
                //  value={VisionHearing[0].LungSounds.desc}
                 onChange={(e) =>{VisionHearing[0].Cough.option = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
             
                >
                <Radio value='Dry' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Dry</Radio>
                <Radio value='Constant' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Constant</Radio>
              </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
                <Input size="small"
                 value={VisionHearing[0].Cough.ProductiveAmount}
                 onChange={(e) =>{VisionHearing[0].Cough.ProductiveAmount = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
             
                placeholder="Productive-Amount"/>
                
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
               
                <Input size="small"
                 value={VisionHearing[0].Cough.color}
                 onChange={(e) =>{VisionHearing[0].Cough.color = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
             
                placeholder="Color"/>
                
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
                
                <Input size="small"
                 value={VisionHearing[0].Cough.howoften}
                 onChange={(e) =>{VisionHearing[0].Cough.howoften = e.target.value;setVisionHearingTrue(!VisionHearingTrue)}}
             
                placeholder="How often"/>
            </Col>
            </Row>
            <br/>
<hr/>
<br/>
<Row style={{ marginTop: 20 }}>
            <Col span={8}></Col>
            <Col span={8}>
            <Button type='primary' style={{ width: '70%', backgroundColor:"#1b3969" }} onClick={saveFormIntake}>Save</Button>
                </Col>
                </Row>
            
        </div>
      :
<Row>
          <Col span={12}>
          </Col>
          <Col span={8}>
            <Space size="middle" style={{ marginTop: 50 }}>

              <Spin size="large" />
            </Space>
          </Col>
        </Row>
        }
    </div>
  );
};

export default IntakeForm;
