import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  Space,
  Row,
  Col,
  Modal,
  Select,
  Button,
  Collapse,
  notification,
  DatePicker,
  Card
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { CheckSquareTwoTone, FolderViewOutlined, FolderOpenTwoTone } from '@ant-design/icons/lib';

const config = require('../keyFront');
const { Panel } = Collapse;
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;
let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'fit_test_doc.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const FitTest = () => {
  const [fitTestResult, setFitTestResult] = useState([]);
  const [change, setChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageSizeW, setPageSizeW] = useState(10);
  const [ClinicListTrue, setClinicListTrue] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found');
  const [LoadMoreVal, setLoadMore] = useState(1);
  const [fullNameValue, setFullNameValue] = useState('');
  const [locValue, setLocValue] = useState('');
  const [reasonValue, setReasonValue] = useState('');
  const [einValue, setEinValue] = useState('');
  const [ssnValue, setSsnValue] = useState('');
  const [dlValue, setDLValue] = useState('');
  const [panelValue, setPanelValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  // const [ccfValue, setCcfValue] = useState('');
  // const [emptyRecords, setEmptyRecords] = useState(false);
  const [collectionDate, setCollectionDate] = useState('');
  const [agenda, setAgenda] = useState('');
  const [reportingDate, setReportingDate] = useState('');
  const [caseValue, setCaseValue] = useState('');
  const [count, setCount] = useState();
  const [loadMoreHide, setLoadMoreHide] = useState(false);
  const [tableLoaderVal, settableLoaderVal] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  //const [docPermission, setDocPermission] = useState(false);
  const [reasonsCount, setReasonsCount] = useState([]);
  const [resultCount, setResultCount] = useState([]);
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  // const [viewIndex, setViewIndex] = useState(0);
  // const [viewCount, setViewCount] = useState(0);
  //const [pagging, setPagging] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  // const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [itemsPerPage, setItemPerPage] = useState(10);
  // const [pageApply, setPageApply] = useState(false);
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);
  let getCountState;
  const theGrid = useRef();
  const { RangePicker } = DatePicker;
  const theSearch = useRef();
  const gridRef = useRef(null);
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();

  const pagesize = 25;

  useEffect(() => {
    //getData(LoadMoreVal, true);
    getData(LoadMoreVal);
    //getDocPermission();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, [pageSizeW]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }
  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };

  //   const getDocPermission = () => {
  //     axios
  //       .post(setting.serverUrl + '/api/documentPermission', {
  //         params: {
  //           user: localStorage.getItem('myData'),
  //           pass: localStorage.getItem('myPass'),
  //           docId: config.menuPermission.DrugAndAlcoholDocs
  //         }
  //       })
  //       .then((response) => {
  //         console.log('response docs', response);
  //         if (response.status === 200) {
  //           setDocPermission(true);
  //         }
  //       });
  //   };

  // async function getEapIdCheckValue(empId) {
  //   // console.log("inside getEapIdCheckValue", result)
  //   const res = await axios.post(setting.serverUrl + '/api/getIdTypeCheckValue', {
  //     params: {
  //       email: localStorage.getItem('myData'),
  //       user: localStorage.getItem('myData'),
  //       pass: localStorage.getItem('myPass'),
  //       compId: JSON.parse(localStorage.getItem('customerData')).id,
  //       employeeId: empId
  //     }
  //   });

  //   const response = await res;
  //   const result = response.data.rows;
  //   return result;
  // }
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSizeW(value);
    view.refresh();
  };
  function processTestDetails(response) {
    let finalResults = [];
    response &&
      response.length > 0 &&
      response.forEach((employee) => {
        // Initialize variables to store latest test details
        let oshaTest = null;
        let pulmonaryTest = null;
        let fitTest = null;

        // Iterate through test details for each employee
        employee.test_details.forEach((test) => {
          if (test.test_name === 'OSHA Medical Questionnaire') {
            if (!oshaTest || new Date(test.created_date) > new Date(oshaTest.created_date)) {
              oshaTest = test;
            }
          } else if (test.test_name === 'Pulmonary/Spirometry Function Test') {
            if (
              !pulmonaryTest ||
              new Date(test.created_date) > new Date(pulmonaryTest.created_date)
            ) {
              pulmonaryTest = test;
            }
          } else if (test.test_name === 'Respirator Fit Test (per mask)') {
            if (!fitTest || new Date(test.created_date) > new Date(fitTest.created_date)) {
              fitTest = test;
            }
          }
        });

        // Construct final result object for the employee
        let finalResult = {
          employee_id: employee.employee_id,
          first_name: employee.first_name,
          last_name: employee.last_name,
          emp_ein: employee.emp_ein,
          emp_ssn: employee.emp_ssn,
          com_loc_name: employee.com_loc_name,
          osha_result: oshaTest ? oshaTest.final_result : null,
          osha_doc: oshaTest ? oshaTest.url : null,
          oshaTestingDate: oshaTest ? oshaTest.testing_date : null,
          pft_result: pulmonaryTest ? pulmonaryTest.final_result : null,
          pft_doc: pulmonaryTest ? pulmonaryTest.url : null,
          pftTestingDate: pulmonaryTest ? pulmonaryTest.testing_date : null,
          fit_result: fitTest ? fitTest.final_result : null,
          fit_doc: fitTest ? fitTest.url : null,
          fitTestingDate: fitTest ? fitTest.testing_date : null,
          fitTestingStatus: fitTest ? fitTest.testing_status : null,
          pftTestingStatus: pulmonaryTest ? pulmonaryTest.testing_status : null,
          oshaTestingStatus: oshaTest ? oshaTest.testing_status : null,

          // Include all keys of the test details
          ...oshaTest,
          ...pulmonaryTest,
          ...fitTest
        };

        // Remove redundant keys
        delete finalResult.test_name;
        delete finalResult.created_date;
        delete finalResult.final_result;
        delete finalResult.url;

        // Add final result to the list
        finalResults.push(finalResult);
      });

    // Return the final results
    return finalResults;
  }
  async function getData(
    LoadMoreVal,
    getcount = false,
    check = false,
    advanceFilter = false,
    resetFilter = false
  ) {
    i = 1;
    settableLoaderVal(true);
    setClinicListTrue(false);
    //setChange(true);
    setIsLoading(true);
    if (!advanceFilter && !resetFilter) {
      setIsLoadMoreLoading(true);
    }
    getCountState = getcount;
    // const url = getcount ? '/api/getTestDetailsBestFit_count' : '/api/getTestDetailsBestFit';
    const url = getcount ? '/api/getTestDetailsOshaPftFit_count' : '/api/getTestDetailsOshaPftFit';
    await axios
      .post(setting.serverUrl + url, {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          LoadMoreVal: LoadMoreVal,
          fullNameValue: check ? '' : fullNameValue,
          locValue: check ? '' : locValue,
          statusValue: check ? '' : statusValue,
          panelValue: check ? '' : panelValue,
          reasonValue: check ? '' : reasonValue,
          ssnValue: check ? '' : ssnValue,
          //ccfValue: check ? '' : ccfValue,
          caseValue: check ? '' : caseValue,
          einValue: check ? '' : einValue,
          dlValue: check ? '' : dlValue,
          collectionDate: check ? '' : collectionDate,
          //reportingDate: check ? '' : reportingDate,
          // agenda: check ? '' : agenda,
          //getcount: advanceFilter ? true : getcount,
          companyId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then(async (response) => {
        setIsLoading(false);
        console.log('Respone from getTestDetails:::', response.data);
        const processedData = processTestDetails(response.data);
        console.log('new merged response=====', processedData);
        processedData.sort((a, b) => {
          const dateA = new Date(a.testing_date);
          const dateB = new Date(b.testing_date);
          return dateB - dateA;
        });

        processedData.sort((a, b) => {
          const dateA = new Date(a.expiry_date);
          const dateB = new Date(b.expiry_date);
          return dateB - dateA;
        });

        //setChange(false);

        // if (response && response.status == 200) {
        //   // var arrayEmpIds = [];
        //   // var resultIds;
        //   // arrayEmpIds.push(
        //   //   response &&
        //   //     response.data &&
        //   //     response.data.length > 0 &&
        //   //     response.data.map((k) => k.emp_selected[0])
        //   // );
        //   // console.log({ arrayEmpIds });

        //   if (getcount == false) {
        //     settableLoaderVal(false);
        //     setNoRecordFound(false);
        //     setIsLoadMoreLoading(false);
        //    // setFitTestResult(response && response.data);

        //     if (advanceFilter) {
        //       setCount(response.data.length);
        //     }
        //     if (response.data.length !== LoadMoreVal * pagesize) {
        //       setLoadMoreHide(true);
        //     } else {
        //       setLoadMoreHide(false);
        //     }
        //     setHasPermission(true);
        //   } else {
        //     setCount(response.data.count);
        //   }
        //   setNoRecordFound(false);

        // } else if (response.status === 201) {
        //   setNoRecordFound(true);
        //   setNoRecordMessage('Access permission not allowed.');
        //   setErrMsg('Access permission not allowed.');
        //   // notification.error({
        //   //   message: 'Failed to retrieve data at the moment. Please try again in a bit.'
        //   // });
        // } else {
        //   notification.error({
        //     message: 'Failed to retrieve data at the moment. Please try again in a bit.'
        //   });
        //   setNoRecordFound(true);
        // }

        if (response && response.status == 200) {
          var resss = [];
          // console.log('array empiid', arrayEmpIds);
          // if (arrayEmpIds && arrayEmpIds[0] && arrayEmpIds[0].length > 0) {
          //   const idResult = await getEapIdCheckValue(arrayEmpIds);
          //   console.log('idsResult', idResult);

          //   const ab = response.data.map((value) => {
          //     // return {...val,idType: idResult[index]}
          //     return idResult.filter((val, index) => {
          //       if (value.emp_selected[0] === val.id) {
          //         resss.push({ ...value, idType: idResult[index] });
          //       }
          //     });
          //   });
          //   console.log('resss', ab, advanceFilter, resss);
          // } else {
          // resss.push(response.data);
          //console.log('set resss data', response.data);
          // setErrMsg("No Record Found")
          //  setIsLoading(false);
          //}

          if (getcount == false) {
            settableLoaderVal(false);
            setNoRecordFound(false);

            console.log('resss ress data', resss);
            const newResponse =
              processedData &&
              processedData.length > 0 &&
              processedData.map((k) => {
                return {
                  ...k,
                  fullName: k.first_name + ' ' + k.last_name,
                  newLocation:
                    k.com_loc_name && k.com_loc_name.includes('|')
                      ? k.com_loc_name.split('|')[1]
                      : k.com_loc_name,
                  // newCollected: k.collected && moment(k.collected).format('MM/DD/YYYY hh:mm'),
                  newCollected: k.testing_date != false ? k.testing_date : '',
                  // newCcf: k.ccf == false ? ' ' : k.ccf,
                  fit_Model: k.fit_Model != false ? k.fit_Model : '',
                  fit_Size: k.fit_Size != false ? k.fit_Size : '',
                  fit_Style: k.fit_Style != false ? k.fit_Style : '',
                  testing_status: k.testing_status != false ? k.testing_status : '',
                  expiry_date: k.expiry_date != false && k.expiry_date != null ? k.expiry_date : '',
                  fitTestPdf: k.fitTestPdf != false ? k.fitTestPdf : '',
                  fitTestCard: k.fitTestCard != false ? k.fitTestCard : '',
                  fitTestType: k.fit_Test_Type != false ? k.fit_Test_Type : '',
                  fitManufacturer: k.fit_Manufacturer != false ? k.fit_Manufacturer : '',
                  fitMakeModel:
                    (k.fit_Manufacturer != false && k.fit_Manufacturer != null) ||
                    (k.fit_Model != false && k.fit_Model != null)
                      ? k.fit_Manufacturer + '-' + k.fit_Model
                      : '',
                  finalResultFit: k.fit_result != false ? k.fit_result : '',
                  finalResultOsha: k.osha_result != false ? k.osha_result : '',
                  finalResultPft: k.pft_result != false ? k.pft_result : '',

                  newDL: k.DL != false ? k.DL : '',
                  newEmpEIN: k.emp_ein != false ? k.emp_ein : '',
                  newEmpSSN: k.emp_ssn != false ? k.emp_ssn : '',
                  // jobTitle: k.emp_data[0].Job_Title != false ? k.emp_data[0].Job_Title : '',
                  // departments: k.emp_data[0].department != false ? k.emp_data[0].department : '',
                  // program: k.emp_data[0].program != false ? k.emp_data[0].program : '',
                  // mode: k.emp_data[0].mode != false ? k.emp_data[0].mode : ''
                  newTest_Date_ForExcel: k.testing_date
                    ? moment(k.testing_date).format('MM/DD/YYYY')
                    : '',
                  newExpiry_Date_ForExcel: k.expiry_date
                    ? moment(k.expiry_date).format('MM/DD/YYYY')
                    : ''
                };
              });

            if (newResponse.length > 0) {
              setFitTestResult(newResponse);
            }

            //setWijmoFlexGrid(newResponse, 10);

            if (newResponse.length > 0) {
              const collectionView1 = new CollectionView(newResponse, {
                pageSize: newResponse.length
              });
              setViewData(collectionView1);
              const collectionView2 = new CollectionView(newResponse, {
                pageSize: pageSizeW,
                collectionChanged: (s) => {
                  filterRefCounter.current = s.totalItemCount;
                  var filterCounter = document.getElementById('filterCounter');
                  if (filterCounter) {
                    filterCounter.textContent = s.totalItemCount;
                  }
                }
              });
              setIsLoadMoreLoading(false);
              setView(collectionView2);
              setNoRecordFound(false);
            }
            if (advanceFilter) {
              setCount(response.data.length);
            }
            if (response.data.length !== LoadMoreVal * pagesize) {
              setLoadMoreHide(true);
            } else {
              setLoadMoreHide(false);
            }
            setHasPermission(true);
          } else {
            setCount(response.data.count);
          }
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setNoRecordMessage('Access permission not allowed.');
          setErrMsg('Access permission not allowed.');
          // notification.error({
          //   message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          // });
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
          setNoRecordFound(true);
        }
      });
  }

  function download(e, attachment) {
    // setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: 'https://wss-files.s3.amazonaws.com/' + attachment
        }
      })
      .then((response) => {
        console.log('Respone from getData testEventReports', response.data);
        if (response && response.data && response.data.length > 0) {
          // setIsLoaderActive(false);
          // setIsModalVisible(true);
          // setImageUrls(response.data);

          // setChange(!change)
          window.open(response.data);
        }
      });
  }
  function downloadAttachmentMultiple(attachment) {
    console.log('attachment :: ', attachment);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        console.log('Respone from attachment multiple', response.data);
        setChange(!change);
        window.open(response.data);
      });
  }

  const handleReset = () => {
    // setIsLoading(true);
    setCount();
    setFullNameValue('');
    setLocValue('');
    setSsnValue('');
    setEinValue('');
    setPanelValue('');
    setReasonValue('');
    setStatusValue('');
    //setCcfValue('');
    setCaseValue('');
    setCollectionDate('');
    getData(LoadMoreVal, false, true, false, true);
    getData(LoadMoreVal, true, true, false, true);
  };

  const showTotal = (total) => {
    return <p>Total {total} items</p>;
  };

  const advanceDateFilter = (filterType, value) => {
    if (filterType == 'collectiondate') {
      console.log('collection date: ', typeof value);
      setCollectionDate(value);
    } else if (filterType === 'reportingdate') {
      setReportingDate(value);
    }
  };

  const showAdvanceFilterModal = () => {
    console.log('insdie advance filter');
    setIsAdvanceFilterModalOpen(true);
  };

  const handleAdvanceFilterCancel = () => {
    setIsAdvanceFilterModalOpen(false);
  };

  const handleAdvanceFilter = () => {
    // setCurrentPageNumber(1);
    setIsAdvanceFilterModalOpen(false);
    getData(1, false, false, true);
    // getData(1, true);
  };
  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  console.log('collected type state===', typeof collectionDate);
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 38; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;

    setListboxColumns(ctl.columns);
  };
  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showCollectedDate = (item) => {
    return (
      <span className='nowrap'>
        {item.item.collected ? moment(item.item.collected).format('MM/DD/YYYY hh:mm') : ''}
      </span>
    );
  };
  const showFitTestPdf = (record) => {
    return (
      <span className='nowrap'>
        {record.item && record.item.fitTestPdf ? (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                download(e, record.item.fitTestPdf);
              }}
            >
              View PDF
            </button>
          </>
        ) : (
          ''
        )}
      </span>
    );
    return (
      <span
        className='nowrap'
        style={{ display: 'flex', flexDirection: 'column', width: '70%', textAlign: 'center' }}
      >
        <div>
          {record.item && record.item.fitTestPdf ? (
            <button onClick={(e) => download(e, record.item.fitTestPdf)}>View PDF</button>
          ) : (
            ''
          )}
        </div>
      </span>
    );
  };
  const showFitTestCard = (record) => {
    return (
      <span
        className='nowrap'
        style={{ display: 'flex', flexDirection: 'column', width: '70%', textAlign: 'center' }}
      >
        <div>
          {record.item && record.item.fitTestCard ? (
            <button
              style={{ marginTop: '-2px' }}
              onClick={(e) => download(e, record.item.fitTestCard)}
            >
              View Card
            </button>
          ) : (
            ''
          )}
        </div>
      </span>
    );
  };
  const showResult = (item) => {
    return (
      <span>
        {item.item.finalResultFit === 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'green', margin: 0 }}>{item.item.finalResultFit}</p>
            {item && item.item && item.item.fitTestPdf != null ? (
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => {
                  //  e.stopPropagation();
                  download(e, item.item.fitTestPdf);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultFit === 'POSITIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'red', margin: 0 }}>{item.item.finalResultFit}</p>
            {item && item.item && item.item.fitTestPdf != null ? (
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => {
                  // e.stopPropagation();
                  download(e, item.item.fitTestPdf);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultFit !== 'POSITIVE' && item.item.finalResultFit !== 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'orange', margin: 0 }}>{item.item.finalResultFit}</p>
            {item && item.item && item.item.fitTestPdf != null ? (
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => {
                  //e.stopPropagation();
                  download(e, item.item.fitTestPdf);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          ''
        )}
      </span>
    );
  };

  //   const showResult = (item) => {
  //     return (
  //       <span>
  //         {item.item.finalResultFit === 'NEGATIVE' ? (
  //           <div style={{ display: 'flex', alignItems: 'center' }}>
  //             <p style={{ color: 'green' }}>{item.item.finalResultFit}</p>
  //             {item && item.item && item.item.fitTestPdf != null ? (
  //               <FolderViewOutlined
  //                 style={{ color: 'green', fontSize: '16px', cursor: 'pointer' }}
  //                 onClick={(e) => {
  //                   //  e.stopPropagation();
  //                   download(e, item.item.fitTestPdf);
  //                 }}
  //               />
  //             ) : (
  //               <></>
  //             )}
  //           </div>
  //         ) : item.item.finalResultFit === 'POSITIVE' ? (
  // <div style={{ display: 'flex', alignItems: 'center' }}>
  //             <p style={{ color: 'red' }}>{item.item.finalResultFit}</p>
  //             {item && item.item && item.item.fitTestPdf != null ? (
  //               <FolderViewOutlined
  //                 style={{ color: 'green', fontSize: '16px', cursor: 'pointer' }}
  //                 onClick={(e) => {
  //                   // e.stopPropagation();
  //                   download(e, item.item.fitTestPdf);
  //                 }}
  //               />
  //             ) : (
  //               <></>
  //             )}
  //           </div>
  //         ) : item.item.finalResultFit !== 'POSITIVE' && item.item.finalResultFit !== 'NEGATIVE' ? (
  // <div style={{ display: 'flex', alignItems: 'center' }}>
  //             <p style={{ color: 'orange' }}>{item.item.finalResultFit}</p>
  //             {item && item.item && item.item.fitTestPdf != null ? (
  //               <FolderViewOutlined
  //                 style={{ color: 'green', fontSize: '16px', cursor: 'pointer' }}
  //                 onClick={(e) => {
  //                   //e.stopPropagation();
  //                   download(e, item.item.fitTestPdf);
  //                 }}
  //               />
  //             ) : (
  //               <></>
  //             )}
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //       </span>
  //     );
  //   };
  // const showOshaResult = (item) => {
  //   console.log('itemsosha',item.item.finalResultOsha,item.item.first_name)
  //   return (
  //     <span>
  //       {item.item.finalResultOsha === 'NEGATIVE' ? (
  //         <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <p style={{ color: 'green', margin: 0 }}>{item.item.finalResultOsha}</p>
  //           {item && item.item && item.item.osha_doc != null ? (
  //             <FolderViewOutlined
  //               style={{ color: 'green', fontSize: '16px', cursor: 'pointer', marginLeft: '5px' }}
  //               onClick={() => downloadAttachmentMultiple(item.item.osha_doc)}
  //             />
  //           ) : (
  //             <></>
  //           )}
  //         </div>
  //       ) : item.item.finalResultOsha === 'POSITIVE' ? (
  //         <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <p style={{ color: 'red', margin: 0 }}>{item.item.finalResultOsha}</p>
  //           {item && item.item && item.item.osha_doc != null ? (
  //             <FolderViewOutlined
  //               style={{ color: 'green', fontSize: '16px', cursor: 'pointer', marginLeft: '5px' }}
  //               onClick={() => downloadAttachmentMultiple(item.item.osha_doc)}
  //             />
  //           ) : (
  //             <></>
  //           )}
  //         </div>
  //       ) : item.item.finalResultOsha !== 'POSITIVE' && item.item.finalResultOsha !== 'NEGATIVE' ? (
  //         <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <p style={{ color: 'orange', margin: 0 }}>{item.item.finalResultOsha}</p>
  //           {item && item.item && item.item.osha_doc != null ? (
  //             <FolderViewOutlined
  //               style={{ color: 'green', fontSize: '16px', cursor: 'pointer', marginLeft: '5px' }}
  //               onClick={() => downloadAttachmentMultiple(item.item.osha_doc)}
  //             />
  //           ) : (
  //             <></>
  //           )}
  //         </div>
  //       ) : (
  //         ''
  //       )}
  //     </span>
  //   );
  // };
  const showOshaResult = (item) => {
    console.log('itemsosha', item.item.finalResultOsha, item.item.first_name);
    return (
      <span>
        {item.item.finalResultOsha === 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'green', margin: 0 }}>{item.item.finalResultOsha}</p>
            {item && item.item && item.item.osha_doc != null ? (
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.osha_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultOsha === 'POSITIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'red', margin: 0 }}>{item.item.finalResultOsha}</p>
            {item && item.item && item.item.osha_doc != null ? (
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ color: 'green', fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.osha_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultOsha !== 'POSITIVE' && item.item.finalResultOsha !== 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'orange', margin: 0 }}>{item.item.finalResultOsha}</p>
            {item && item.item && item.item.osha_doc != null ? (
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.osha_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          ''
        )}
      </span>
    );
  };
  // const showPftResult = (item) => {
  //   return (
  //     <span>
  //       {item.item.finalResultPft === 'NEGATIVE' ? (
  //         <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <p style={{ color: 'green', margin: 0 }}>{item.item.finalResultPft}</p>
  //           {item && item.item && item.item.pft_doc != null ? (
  //             <FolderViewOutlined
  //               style={{ color: 'green', fontSize: '16px', cursor: 'pointer', marginLeft: '4px' }}
  //               onClick={() => downloadAttachmentMultiple(item.item.pft_doc)}
  //             />
  //           ) : (
  //             <></>
  //           )}
  //         </div>
  //       ) : item.item.finalResultPft === 'POSITIVE' ? (
  //         <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <p style={{ color: 'red', margin: 0 }}>{item.item.finalResultPft}</p>
  //           {item && item.item && item.item.pft_doc != null ? (
  //             <FolderViewOutlined
  //               style={{ color: 'green', fontSize: '16px', cursor: 'pointer', marginLeft: '4px' }}
  //               onClick={() => downloadAttachmentMultiple(item.item.pft_doc)}
  //             />
  //           ) : (
  //             <></>
  //           )}
  //         </div>
  //       ) : item.item.finalResultPft !== 'POSITIVE' && item.item.finalResultPft !== 'NEGATIVE' ? (
  //         <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <p style={{ color: 'orange', margin: 0 }}>{item.item.finalResultPft}</p>
  //           {item && item.item && item.item.pft_doc != null ? (
  //             <FolderViewOutlined
  //               style={{ color: 'green', fontSize: '16px', cursor: 'pointer', marginLeft: '4px' }}
  //               onClick={() => downloadAttachmentMultiple(item.item.pft_doc)}
  //             />
  //           ) : (
  //             <></>
  //           )}
  //         </div>
  //       ) : (
  //         ''
  //       )}
  //     </span>
  //   );
  // };

  const showPftResult = (item) => {
    return (
      <span>
        {item.item.finalResultPft === 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'green', margin: 0 }}>{item.item.finalResultPft}</p>
            {item && item.item && item.item.pft_doc != null ? (
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.pft_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultPft === 'POSITIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'red', margin: 0 }}>{item.item.finalResultPft}</p>
            {item && item.item && item.item.pft_doc != null ? (
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.pft_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultPft !== 'POSITIVE' && item.item.finalResultPft !== 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'orange', margin: 0 }}>{item.item.finalResultPft}</p>
            {item && item.item && item.item.pft_doc != null ? (
              <FolderOpenTwoTone
                twoToneColor='#cc7a00'
                style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.pft_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          ''
        )}
      </span>
    );
  };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };
  const showJobTitle = (item) => {
    return <p> {item.item.jobTitle != '' ? item.item.jobTitle : ''}</p>;
  };

  const showSSN = (item) => {
    if (item.item.newEmpSSN !== false && item.item.newEmpSSN !== null) {
      return <>{'***-**-' + item.item.newEmpSSN?.substr(-4)}</>;
    } else {
      return <>{''}</>;
    }
  };
  const showEIN = (item) => {
    return <>{item.item.newEmpEIN != false ? item.item.newEmpEIN : ''}</>;
  };
  const showDL = (item) => {
    return <>{item.item.newDL != false ? item.item.newDL : ''}</>;
  };

  const renderCountCard = (data) => {
    return (
      <>
        {data.count !== 0 ? (
          <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {data.count}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    {data && data.reasons && data.reasons.toUpperCase()}
                    {data && data.final_result && data.final_result.toUpperCase()}
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderDashboardCards = () => {
    let mergeStatus = reasonsCount.concat(resultCount);
    console.log('mergeStatus', mergeStatus);
    let cards = mergeStatus.map((data) => {
      return renderCountCard(data);
    });
    return cards;
  };
  function filterChangedHandler() {
    gridRef.current.invalidate();
  }
  const formatDateTime = (value) => {
    return moment(value).format('MM/DD/YYYY hh:mm');
  };
  const flexGridRef = React.useRef(null);

  const onFilterApplied = () => {
    const filter = flexGridRef.current.collectionView.filterDefinition;
    filter.clear(); // clear any existing filters
    filter.conditionFilter('emp_ein', '==', true); // filter for isActive == true
  };
  const handleFormatDate = (value) => {
    return moment(value).format('MM/DD/YYYY');
  };
  const formatMonth = (value) => moment(value).format('MMM YYYY');
  const showNewLocation = (item) => {
    return item.item.newLocation;
  };

  const showNewCollected = (item) => {
    return item.item.newCollected
      ? item.item.newCollected && moment(item.item.newCollected).format('MM/DD/YYYY')
      : '';
  };

  const showExpiry = (item) => {
    return item.item.expiry_date
      ? item.item.expiry_date && moment(item.item.expiry_date).format('MM/DD/YYYY')
      : '';
  };

  return hasPermission ? (
    <div style={{ padding: 20 }}>
      <Row style={{ alignItems: 'center', textAlign: 'center' }}>
        <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 9 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <h3 style={{ textAlign: 'left', color: '#1C3A6A' }}>Fit Testing</h3>
        </Col>

        {/* <Col
          xl={{ span: 18 }}
          lg={{ span: 18 }}
          md={{ span: 12 }}
          sm={{ span: 0 }}
          xs={{ span: 0 }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size='small'
              style={{ backgroundColor: '#247', marginBottom: 10, border: 'none', marginRight: 10 }}
              onClick={() => {
                setLoadMore(LoadMoreVal + 1);
                getData(LoadMoreVal + 1, false, false);
              }}
            >
              {isLoadMoreLoading ? (
                <Space size='middle'>
                  <Spin size='small' />
                </Space>
              ) : (
                ' Load More'
              )}
            </Button>

            <Button
              size='small'
              style={{ marginBottom: 10, backgroundColor: '#247', border: 'none', marginRight: 10 }}
              onClick={showAdvanceFilterModal}
            >
              Advanced Search Filter
            </Button>
            <Modal
              width={1000}
              title='Advanced Search Filter'
              visible={isAdvanceFilterModalOpen}
              onCancel={handleAdvanceFilterCancel}
              footer={[
                <Button
                  size='small'
                  style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                  onClick={() => handleAdvanceFilter()}
                >
                  Search
                </Button>
              ]}
            >
              <Row gutter={[24, 24]}>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={fullNameValue}
                    placeholder='Full Name :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setFullNameValue(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={locValue}
                    placeholder='Location :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setLocValue(event.target.value)}
                  />
                </Col>

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={reasonValue}
                    placeholder='Reason :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setReasonValue(event.target.value)}
                  />
                </Col>

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={panelValue}
                    placeholder='Panel :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setPanelValue(event.target.value)}
                  />
                </Col>

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={statusValue}
                    placeholder='Fit Test :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setStatusValue(event.target.value)}
                  />
                </Col>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    if (value === 'SSN') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                          <Input
                            value={ssnValue}
                            placeholder='SSN :'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '80%', backgroundColor: 'white' }}
                            onChange={(event) => setSsnValue(event.target.value)}
                          />
                        </Col>
                      );
                    } else if (value === 'Driver License') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
                          <Input
                            value={dlValue}
                            placeholder='Driver License:'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '80%', backgroundColor: 'white' }}
                            onChange={(event) => setDLValue(event.target.value)}
                          />
                        </Col>
                      );
                    } else if (value === 'EIN') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                          <Input
                            value={einValue}
                            placeholder='EIN :'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '80%', backgroundColor: 'white' }}
                            onChange={(event) => setEinValue(event.target.value)}
                          />
                        </Col>
                      );
                    } else {
                    }
                  })}

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <RangePicker
                    //value={collectionDate}
                    placeholder={['Start Test Date', 'End Test Date']}
                    allowClear={true}
                    style={{ borderRadius: 20, width: '80%', height: 40 }}
                    onChange={(value, dateString) => {
                      advanceDateFilter('collectiondate', dateString);
                    }}
                  />
                </Col>
              </Row>
            </Modal>
            <Button
              size='small'
              style={{ backgroundColor: '#247', marginBottom: 10, border: 'none' }}
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
          </div>
        </Col> */}
      </Row>

      {/* <div className='row'>{renderDashboardCards()}</div> */}

      {/* {(isLoadMoreLoading || isLoading) && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )} */}
      {console.log('states loader============', !noRecordFound, isLoading)}
      {!noRecordFound ? (
        fitTestResult.length > 0 ? (
          <div>
            {/* <h6>
              Total records :{' '}
              <span style={{ marginLeft: '10px' }}>{!count ? <Spin size='small' /> : count}</span>
            </h6> */}
            <div className='row'>
              <div className='col-12 col-md-12 col-xl-12'>
                <Row gutter={[32, 32]}>
                  <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                    <FlexGridSearch
                      ref={theSearch}
                      placeholder='Search'
                      cssMatch=''
                      style={{ width: '70%', height: '40px' }}
                    />
                  </Col>

                  <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                    <div ref={fixedContainerRef}>
                      <Select
                        style={{ width: 170 }}
                        placeholder={`Items per page : ${pageSizeW}`}
                        onChange={paggination}
                        options={[
                          { label: 'Items per page : 10', value: 10 },
                          { label: 'Items per page : 20', value: 20 },
                          { label: 'Items per page : 50', value: 50 }
                        ]}
                        getPopupContainer={() => fixedContainerRef.current}
                      />
                    </div>
                  </Col>

                  <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                    <Button
                      type='primary'
                      style={{ backgroundColor: '#3d586a', float: 'right' }}
                      disabled={isExcelPreparing}
                      onClick={exportToExcel}
                      size='small'
                    >
                      {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                    </Button>
                  </Col>
                </Row>

                {/* to export entire data of table */}
                <FlexGrid
                  ref={theGrid}
                  style={{ display: 'none' }}
                  className='dummyWijmoData'
                  itemsSource={viewData}
                  initialized={gridInitializedData}
                  formatItem={formatItem}
                  isReadOnly={true}
                >
                  <div style={{ display: 'none' }}>
                    <wjInput.ListBox
                      className='column-picker'
                      itemsSource={listboxColumns}
                      checkedMemberPath='visible'
                      displayMemberPath='header'
                      lostFocus={hidePicker}
                      initialized={initializedListBox}
                    ></wjInput.ListBox>
                  </div>
                  <FlexGridColumn
                    binding='fullName'
                    header='Employee Name'
                    minWidth={200}
                    width='*'
                    wordWrap={true}
                  />
                  {typecheckValue &&
                    typecheckValue.length > 0 &&
                    typecheckValue.map((value) => {
                      return (
                        <FlexGridColumn
                          binding={
                            value === 'SSN'
                              ? 'newEmpSSN'
                              : value === 'EIN'
                              ? 'newEmpEIN'
                              : value === 'Driver License'
                              ? 'newDL'
                              : ''
                          }
                          header={
                            value === 'SSN'
                              ? 'SSN'
                              : value === 'EIN'
                              ? 'EIN'
                              : value === 'Driver License'
                              ? 'Driver License'
                              : ''
                          }
                          // width='12*'

                          minWidth={160}
                          width='*'
                        >
                          <FlexGridCellTemplate
                            cellType='Cell'
                            template={
                              value === 'SSN'
                                ? showSSN
                                : value === 'EIN'
                                ? showEIN
                                : value === 'Driver License'
                                ? showDL
                                : ''
                            }
                          />
                        </FlexGridColumn>
                      );
                    })}

                  <FlexGridColumn
                    binding='newLocation'
                    header='Location'
                    minWidth={180}
                    // width='*'
                    wordWrap={true}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='newTest_Date_ForExcel'
                    header='Test Date'
                    minWidth={100}
                    width='*'
                  />
                  <FlexGridColumn
                    binding='newExpiry_Date_ForExcel'
                    header='Expiry Date'
                    minWidth={100}
                  ></FlexGridColumn>

                  <FlexGridColumn binding='finalResultOsha' header='OSHA'>
                    <FlexGridCellTemplate cellType='Cell' template={showOshaResult} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='finalResultPft' header='PFT'>
                    <FlexGridCellTemplate cellType='Cell' template={showPftResult} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='finalResultFit' header='Fit Test'>
                    <FlexGridCellTemplate cellType='Cell' template={showResult} />
                  </FlexGridColumn>
                  {/* <FlexGridColumn binding='oshaResult' header='OSHA Q'>
                    {/* <FlexGridCellTemplate cellType='Cell' template={showResult} /> */}
                  {/* </FlexGridColumn> */}
                  {/* <FlexGridColumn binding='pftResult' header='PFT'> */}
                  {/* <FlexGridCellTemplate cellType='Cell' template={showResult} /> */}
                  {/* </FlexGridColumn> */}
                  {/* <FlexGridColumn binding='fitTestPdf' header='Fit Test Pdf'>
                    <FlexGridCellTemplate cellType='Cell' template={showFitTestPdf} />
                  </FlexGridColumn> */}
                  {/* <FlexGridColumn binding='fitTestCard' header='Fit Test Card'>
                    <FlexGridCellTemplate cellType='Cell' template={showFitTestCard} />
                  </FlexGridColumn> */}

                  {/* <FlexGridColumn
                    binding='jobTitle'
                    header='Job Title'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}
                  {/* <FlexGridColumn
                    binding='departments'
                    header='Job Position'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}

                  {/* <FlexGridColumn
                    binding='program'
                    header='Dot Mode'
                    // width='10*'
                    minWidth={160}
                    width='*'
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn> */}

                  <FlexGridColumn binding='fitMakeModel' header='Make-Model'></FlexGridColumn>
                  {/* <FlexGridColumn binding='fit_Model' header='Model'></FlexGridColumn> */}
                  <FlexGridColumn binding='fit_Size' header='Size'></FlexGridColumn>
                  <FlexGridColumn binding='fit_Style' header='Style'></FlexGridColumn>

                  <FlexGridColumn binding='fitTestType' header='Test Type'></FlexGridColumn>
                  {/*<FlexGridColumn binding='reasons' header='Reason' minWidth={200} />
                  <FlexGridColumn binding='testing_status' header='Status'></FlexGridColumn>*/}
                </FlexGrid>

                <FlexGrid
                  ref={theGrid}
                  //ref={gridRef}
                  id='theGridTallRows'
                  className='cliniclisttable'
                  alternatingRowStep={1}
                  // autoRowHeights={true}
                  itemsSource={view}
                  initialized={gridInitialized}
                  formatItem={formatItem}
                  itemsPerPage={10}
                  isReadOnly={true}
                  scrollMode='Virtual' // Add this line for smooth scrolling
                  //onFilterApplied={onFilterApplied}
                  //autoGenerateColumns={false}
                >
                  <div style={{ display: 'none' }}>
                    <wjInput.ListBox
                      className='column-picker'
                      itemsSource={listboxColumns}
                      checkedMemberPath='visible'
                      displayMemberPath='header'
                      lostFocus={hidePicker}
                      initialized={initializedListBox}
                    ></wjInput.ListBox>
                  </div>
                  <FlexGridColumn
                    binding='fullName'
                    header='Employee Name'
                    // minWidth={200}
                    // width='*'
                    width={150}
                    wordWrap={true}
                  />
                  {typecheckValue &&
                    typecheckValue.length > 0 &&
                    typecheckValue.map((value) => {
                      return (
                        <FlexGridColumn
                          binding={
                            value === 'SSN'
                              ? 'newEmpSSN'
                              : value === 'EIN'
                              ? 'newEmpEIN'
                              : value === 'Driver License'
                              ? 'newDL'
                              : ''
                          }
                          header={
                            value === 'SSN'
                              ? 'SSN'
                              : value === 'EIN'
                              ? 'EIN'
                              : value === 'Driver License'
                              ? 'Driver License'
                              : ''
                          }
                          // width='12*'

                          // minWidth={160}
                          // width='*'
                          width={120}
                        >
                          <FlexGridCellTemplate
                            cellType='Cell'
                            template={
                              value === 'SSN'
                                ? showSSN
                                : value === 'EIN'
                                ? showEIN
                                : value === 'Driver License'
                                ? showDL
                                : ''
                            }
                          />
                        </FlexGridColumn>
                      );
                    })}

                  <FlexGridColumn
                    binding='newLocation'
                    header='Location'
                    // minWidth={180}
                    // width='*'
                    width={240}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='newCollected' header='Test Date' width={100}>
                    <FlexGridCellTemplate cellType='Cell' template={showNewCollected} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='expiry_date' header='Expiry Date' width={150}>
                    <FlexGridCellTemplate cellType='Cell' template={showExpiry} />
                  </FlexGridColumn>

                  <FlexGridColumn binding='finalResultOsha' header='OSHA'>
                    <FlexGridCellTemplate cellType='Cell' template={showOshaResult} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='finalResultPft' header='PFT'>
                    <FlexGridCellTemplate cellType='Cell' template={showPftResult} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='finalResultFit' header='Fit Test'>
                    <FlexGridCellTemplate cellType='Cell' template={showResult} />
                  </FlexGridColumn>
                  {/* <FlexGridColumn binding='oshaResult' header='OSHA Q'> */}
                  {/* <FlexGridCellTemplate cellType='Cell' template={showResult} /> */}
                  {/* </FlexGridColumn> */}
                  {/* <FlexGridColumn binding='pftResult' header='PFT'> */}
                  {/* <FlexGridCellTemplate cellType='Cell' template={showResult} /> */}
                  {/* </FlexGridColumn> */}
                  {/* <FlexGridColumn binding='fitTestPdf' header='Fit Test Pdf'>
                    <FlexGridCellTemplate cellType='Cell' template={showFitTestPdf} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='fitTestCard' header='Fit Test Card' width={110}>
                    <FlexGridCellTemplate cellType='Cell' template={showFitTestCard} />
                  </FlexGridColumn> */}

                  {/* <FlexGridColumn
                    binding='jobTitle'
                    header='Job Title'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}
                  {/* <FlexGridColumn
                    binding='departments'
                    header='Job Position'
                    // width='10*'
                    minWidth={250}
                    width='*'
                  /> */}

                  {/* <FlexGridColumn
                    binding='program'
                    header='Dot Mode'
                    // width='10*'
                    // minWidth={160}
                    // width='*'
                    width={110}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn> */}

                  <FlexGridColumn
                    binding='fitMakeModel'
                    header='Make-Model'
                    width={150}
                  ></FlexGridColumn>
                  {/* <FlexGridColumn binding='fit_Model' header='Model' width={115}></FlexGridColumn> */}
                  <FlexGridColumn binding='fit_Size' header='Size' width={85}></FlexGridColumn>
                  <FlexGridColumn binding='fit_Style' header='Style' width={90}></FlexGridColumn>

                  <FlexGridColumn
                    binding='fitTestType'
                    header='Test Type'
                    width={110}
                  ></FlexGridColumn>
                  {/* <FlexGridColumn binding='reasons' header='Reason' width={150} />

                  <FlexGridColumn binding='testing_status' header='Status'></FlexGridColumn> */}

                  <FlexGridFilter
                    // showFilterIcons={true}
                    // placeholder='Filter data...'
                    // defaultFilterType='Value'
                    filterColumns={[
                      'fullName',
                      'newLocation',
                      'newCollected',
                      'newEmpEIN',
                      'newEmpSSN',
                      'newDL',
                      'reasons',
                      'fitTestType',
                      'fitManufacturer',
                      // 'finalResult',
                      'finalResultFit',
                      'finalResultOsha',
                      'finalResultPft',
                      // 'jobTitle',
                      // 'departments',
                      // 'program',
                      'fit_Style',
                      'fit_Size',
                      'fit_Model',
                      'expiry_date'
                      // 'testing_status'
                    ]}
                  />
                  {/* <FlexGridFilter
                    column={{
                      binding: 'collected',
                      header: 'Collection Date',
                      filter: true,
                      width: '*'
                    }}
                    formatItem={(s, e) => {
                      if (e.data) {
                        e.cell.textContent = handleFormatDate(e.data.collected);
                      }
                    }}
                  /> */}
                </FlexGrid>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <div
                    style={{
                      backgroundColor: '#425a81',
                      color: '#fff',
                      display: 'inline',
                      borderRadius: '5px',
                      height: '30px'
                    }}
                  >
                    <CollectionViewNavigator
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                      headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                      byPage={true}
                      cv={view}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <p
                    style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                    ref={filterRefCounter}
                    id='filterCounter'
                  >
                    {Globalize.format(filterRefCounter.current, 'n0')}
                  </p>
                  &nbsp;
                  <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                    {' '}
                    out of {fitTestResult.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
            )}
          </>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <Modal
        width={1000}
        title='Fit Test Document'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls && imageUrls.length > 0 && (
          <p>
            <embed src={imageUrls} width='800px' height='900px' />
          </p>
        )}
      </Modal>
      <DarkBackground disappear={isLoaderActive}>
        <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
      </DarkBackground>
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        &nbsp;&nbsp; {errMsg}
      </p>
    </div>
  );
};

export default FitTest;
