import React, { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import _ from 'underscore';
import axios from 'axios';
import setting from '../../config/setting';
import { Spin, Space, Row, Col, Button, Input, DatePicker } from 'antd';

const { TextArea } = Input;


function callback(key) {
  console.log(key);
}

const AudiometricHistory = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const [employer, setEmployer] = useState({});
  const [contactNumber, setContactNumber] = useState('');
  const [haveHadValues, setHaveHadValues] = useState({});
  const [hearingRate, setHearingRate] = useState({});
  const [recentNoiseExposer, setRecentNoiseExposer] = useState({});
  const [hearingProtection, setHearingProtection] = useState({});
  const [nonWorkNoiseExposure, setNonWorkNoiseExposure] = useState({});
  const [militoryWorkNoiseExposure, setMilitoryWorkNoiseExposure] = useState({});
  const [noiseTypeAtWork, setNoiseTypeAtWork] = useState({});
  const [hasSecondJob, setHasSecondJob] = useState('');
  const [secondJobOtherValue, setSecondJobOtherValue] = useState('');

  const [earDescribeAbnormal, setEarDescribeAbnormal] = useState({
    External_Ear_Right: '',
    External_Ear_Left: '',
    Ear_Canal_Right: '',
    Ear_Canal_Left: '',
    Ear_Drum_Left: '',
    Ear_Drum_Right: '',
    Ear_Canal_Describe: '',
    Ear_Drum_Describe: '',
    External_Ear_Describe: ''
  });
  const [audiogramResult, setAudiogramResult] = useState({});
  const [hasWorker, setHasWorker] = useState({});
  const [comment, setComment] = useState({});
  const radio_options = ['Now', 'Past', 'Never'];
  const radio_options_yes_no = ['Yes', 'No'];
  const [examinerSign, setExaminerSign] = useState({});
  const [employeeSign, setEmployeeSign] = useState({});
  const [testingMethod, setTestingMethod] = useState({});
  const [reasonTest, setReasonTest] = useState({});
  const [describeAbnormals, setDescribeAbnormals] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createdDate, setCreatedDate] = useState('');
  const [earInjuryspecific, setEarInjuryspecific] = useState('');
  const [problemNotListedSpecify, setProblemNotListedSpecify] = useState('');
  const [currentHearingProtectionOther, setCurrentHearingProtectionOther] = useState('');
  const [currentHearingProtectionOtherValue, setCurrentHearingProtectionOtherValue] = useState('');
  const [secondJobNowSpecify, setSecondJobNowSpecify] = useState('');
  const [otherLoadNoiseSpecify, setOtherLoadNoiseSpecify] = useState('');
  const [exposedNoise14hoursTestSpecify, setExposedNoise14hoursTestSpecify] = useState('');
  const [externalEarRight, setExternalEarRight] = useState('');
  const [externalEarLeft, setExternalEarLeft] = useState('');
  //const [externalEarDescribe, setExternalEarDescribe] = useState('');
  const [earCanalRight, setEarCanalRight] = useState('');
  const [earCanalLeft, setEarCanalLeft] = useState('');
  //const [earCanalDescribe, setEarCanalDescribe] = useState('');
  const [earDrumRight, setEarDrumRight] = useState('');
  const [earDrumLeft, setEarDrumLeft] = useState('');
  //const [earDrumDescribe, setEarDrumDescribe] = useState('');
  const [testingMethodOther, setTestingMethodOther] = useState('');
  const [audiogramResultOther, setAudiogramResultOther] = useState('');
  const noiseExposureHistory = [
    {
      name: 'Frequent Headaches',
      key: 'Frequent_Headaches',
      isOthers: false
    },
    {
      name: 'Excessive Ear Ringing (Tinnitus)',
      key: 'Excessive_Ear_Ringing',
      isOthers: false
    },
    {
      name: 'Excessive Dizziness (Vertigo)',
      key: 'Excessive_Dizziness',
      isOthers: false
    },
    {
      name: 'Head Injury (with unconsciousness)',
      key: 'Head_Injury',
      isOthers: false
    },
    {
      name: 'High Fever (Over 104°F)',
      key: 'High_Fever',
      isOthers: false
    },
    {
      name: 'Ear Drainage',
      key: 'Ear_Drainage',
      isOthers: false
    },
    {
      name: 'Ear Surgery – Specific:',
      key: 'Ear_Surgery',
      otherKey: 'Ear_Surgery_Other',
      isOthers: true
    },
    {
      name: 'Ear Injury – Specific:',
      key: 'Ear_Injury',
      otherKey: 'Ear_Injury_Other',
      isOthers: true
    },
    {
      name: 'Sinus Trouble',
      key: 'Sinus_Trouble',
      isOthers: false
    },
    {
      name: 'Allergies / Hay Fever',
      key: 'Allergies_Fever',
      isOthers: false
    },
    {
      name: 'Hearing Problems from Medication',
      key: 'Hearing_Problems',
      isOthers: false
    },
    {
      name: 'Problem Not Listed – Specify',
      key: 'Problem_Not_Listed',
      otherKey: 'Problem_Not_Listed_Other',
      isOthers: true
    }
  ];

  const nonWorkNoiseExposureData = [
    {
      name: 'Gunfire',
      key: 'Gunfire',
      isOthers: false
    },
    {
      name: 'Chainsaws / Power Tools',
      key: 'Chainsaws_Power_Tools',
      isOthers: false
    },
    {
      name: 'Heavy Equipment',
      key: 'Heavy_Equipment',
      isOthers: false
    },
    {
      name: 'Motorcycle/Speedboat',
      key: 'Motorcycle_Speedboat',
      isOthers: false
    },
    {
      name: 'Aircraft',
      key: 'Aircraft',
      isOthers: false
    },
    {
      name: 'Other Load Noises',
      key: 'Other_Load_Noises_specify',
      otherKey: 'Other_Load_Noises_specify_Other',
      isOthers: true
    }
  ];

  const militoryWorkNoiseExposureData = [
    {
      name: 'Gunfire',
      key: 'Mil_Gunfire',
      isOthers: false
    },
    {
      name: 'Aircraft',
      key: 'Mil_Aircraft',
      isOthers: false
    },
    {
      name: 'Other Load Noises',
      key: 'Mil_Other_Noise',
      otherKey: 'Mil_Other_Noise_Other',
      isOthers: true
    }
  ];

  const hasTheWorkerData = [
    {
      name: 'Been working prior to examination?',
      key: 'working_prior_to_examination',
      isOthers: false
    },
    {
      name: 'Been Exposed to noise 14 hours prior to test? If YES, indicate the number of hours:',
      key: 'exposed_noise14hours_test',
      isOthers: true
    },
    {
      name: 'Had an audiometric test in the last year?',
      key: 'audiometric_test_last_year',
      isOthers: false
    },
    {
      name: 'Had an audiometric test over 1 year ago?',
      key: 'audiometric_test_one_year_ago',
      isOthers: false
    },
    {
      name: 'It is unknown whether an audiometric test had been performed.',
      key: 'audiometric_test_unknown',
      isOthers: false
    }
  ];

  const token = useParams();

  useEffect(() => {
    console.log('render called');

    getEmployeeInfo();
  }, []);

  const getEmployeeInfo = () => {
    axios
      .post(setting.serverUrl + '/api/EmployeeInfo', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          email: token.email,

        }
      })
      .then((response) => {
        console.log('Respone from EmployeInfo::', response.data);
        //console.log('search success', response.data[0].id);
        setEmployer({
          Email: token.email,
          employee_id: response.data.length > 0 ? response.data[0].id : '',
          Name: response.data.length > 0 ? response.data[0].display_name : '',
          contactNumber: response.data.length > 0 ? response.data[0].Mobile : '',
          companyName: response.data.length > 0 ? response.data[0].company[1] : '',
          ssn: response.data.length > 0 ? response.data[0].ssn : ''
        });
      });
  };


  const updateEmployerInfo = (value, key) => {
    let employerInfo = employer;
    employerInfo[key] = value;
    setEmployer(employerInfo);
    console.log('employerInfo::', employerInfo);
  };
  const updateHearingRate = (value, key) => {
    let newHearingRate = hearingRate;
    newHearingRate[key] = value;
    setHearingRate(newHearingRate);
    console.log('newHearingRate::', newHearingRate);
  };
  const updateExaminerSign = (value, key) => {
    let newExaminerSign = examinerSign;
    newExaminerSign[key] = value;
    setExaminerSign(newExaminerSign);
    console.log('setExaminerSign::', newExaminerSign);
  };
  const updateEmployeeSign = (value, key) => {
    let newEmployeeSign = employeeSign;
    newEmployeeSign[key] = value;

    setEmployeeSign(newEmployeeSign);
    console.log('newEmployeeSign::', newEmployeeSign);
  };
  const updateHaveHadValues = (value, key) => {
    console.log('value', value, key);
    let newHaveHadValues = haveHadValues;
    newHaveHadValues[key] = value;
    setHaveHadValues(newHaveHadValues);
    console.log('newHaveHadValues', newHaveHadValues);
  };

  const updateRecentNoiseExposer = (value, key) => {
    let newRecentNoiseExposer = recentNoiseExposer;
    newRecentNoiseExposer[key] = value;
    setRecentNoiseExposer(newRecentNoiseExposer);
    console.log('newRecentNoiseExposer::', newRecentNoiseExposer);
  };
  const updateAudiogramResult = (value, key) => {
    let newAudiogramResult = audiogramResult;
    newAudiogramResult[key] = value;
    setAudiogramResult(newAudiogramResult);
    console.log('setAudiogramResult::', newAudiogramResult);
  };
  const updateNoiseAtWork = (value, key) => {
    let noiseTypeValues = noiseTypeAtWork;
    noiseTypeValues[key] = value;
    setNoiseTypeAtWork(noiseTypeValues);
    console.log('noiseTypeValues::', noiseTypeValues);
  };

  const updateHasSecondJob = (value, key) => {
    let hasSecondJobValues = hasSecondJob;
    hasSecondJobValues[key] = value;
    setNoiseTypeAtWork(hasSecondJobValues);
    console.log('hasSecondJobValues::', hasSecondJobValues);
  };
  const updateTestingMethod = (value, key) => {
    let testingMethodValues = testingMethod;
    testingMethodValues[key] = value;
    setTestingMethod(testingMethodValues);
    console.log('setTestingMethod::', testingMethodValues);
  };
  const updateHearingProtection = (value, key) => {
    if (value == 'Other') {
      setCurrentHearingProtectionOther(value);
    } else {
      let currentHearingProtection = hearingProtection;
      currentHearingProtection[key] = value;
      setHearingProtection(currentHearingProtection);
      setCurrentHearingProtectionOther('');
    }

    console.log('currentHearingProtection::', hearingProtection, currentHearingProtectionOther);
  };
  const updateComment = (value, key) => {
    let newComment = comment;
    newComment[key] = value;
    setComment(newComment);
    console.log('setComment::', newComment);
  };
  const updateReasonTest = (value, key) => {
    let reasonTestValues = reasonTest;
    reasonTestValues[key] = value;
    setReasonTest(reasonTestValues);
    console.log('setReasonTest::', reasonTestValues);
  };

  /*
     render non work exposure noise
   */
  const updateNonWorkNoiseExposure = (value, key) => {
    let noiseExposure = nonWorkNoiseExposure;
    noiseExposure[key] = value;
    setNonWorkNoiseExposure(noiseExposure);
    console.log('setNonWorkNoiseExposure::', noiseExposure);
  };
  const renderNonWorkNoiseExposure = () => {
    return nonWorkNoiseExposureData.map((element) => {
      return (
        <Row>
          <Col xs={24} sm={12}>
            <Row
              onChange={(event) => updateNonWorkNoiseExposure(event.target.value, element.key)}
            >
              {renderRadioElement(radio_options, element.name, 'col-4')}
            </Row>
          </Col>

          <Col xs={24} sm={12}>
            {element.name}
            {element.isOthers && (
              <Input
                size='small'
                onChange={(event) =>
                  updateNonWorkNoiseExposure(event.target.value, element.otherKey)
                }
                placeholder={element.name}
              />
            )}
          </Col>
        </Row>
      );
    });
  };

  const updateMilitoryWorkNoiseExposure = (value, key) => {
    let noiseExposure = militoryWorkNoiseExposure;
    noiseExposure[key] = value;
    setMilitoryWorkNoiseExposure(noiseExposure);
    console.log('setNonWorkNoiseExposure::', noiseExposure);
  };
  const renderMilitoryWorkNoiseExposure = () => {
    return militoryWorkNoiseExposureData.map((element) => {
      return (
        <Row>
          <Col xs={24} sm={12}>
            <Row
              onChange={(event) => updateMilitoryWorkNoiseExposure(event.target.value, element.key)}
            >
              {renderRadioElement(radio_options, element.key, 'col-4')}
            </Row>
          </Col>

          <Col xs={24} sm={12}>
            {element.name}
            {element.isOthers && (
              <Input
                size='small'
                onChange={(event) =>
                  updateMilitoryWorkNoiseExposure(event.target.value, element.otherKey)
                }
                placeholder={element.name}
              />
            )}
          </Col>
        </Row>
      );
    });
  };

  /*  
     render Has The Worker
   */

  const updateHasTheWorkerOptions = (value, key) => {
    let hasWorkerValues = hasWorker;
    hasWorkerValues[key] = value;
    setHasWorker(hasWorkerValues);
    console.log('sethasWorker::', hasWorkerValues);
  };
  const renderHasTheWorker = () => {
    return hasTheWorkerData.map((element) => {
      return (
        <Row>
          <Col xs={24} sm={12}>
            <Row
              onChange={(event) => updateHasTheWorkerOptions(event.target.value, element.key)}
            >
              {renderRadioElement(radio_options_yes_no, element.name, 'col-4')}
            </Row>
          </Col>
          <Col xs={24} sm={12}>
            {element.name}
            {element.isOthers && (
              <Input
                size='small'
                onChange={(event) =>
                  updateHasTheWorkerOptions(event.target.value, element.otherKey)
                }
                placeholder={element.name}
              />
            )}
          </Col>
        </Row>
      );
    });
  };

  /*
     Render Noise Exposure Level History
   */
  const renderRadioElement = (values, name, className = 'col-2') => {
    return values.map((element) => {
      return (
        <div className={className}>
          <input type='radio' name={name} value={element} />
        </div>
      );
    });
  };

  const renderNoiseExposureLevelHistory = () => {
    return noiseExposureHistory.map((element) => {
      console.log('element', element);
      return (

        <Row style={{ marginTop: "10px" }}>
          <Col xs={24} sm={12}>
            <Row
              onChange={(event) => updateHaveHadValues(event.target.value, element.key)}
            >
              {renderRadioElement(radio_options, element.name, 'col-4')}
            </Row>
          </Col>
          <Col xs={24} sm={12}>
            {element.name}
            {element.isOthers && (
              <Input
                size='small'
                onChange={(event) => updateHaveHadValues(event.target.value, element.otherKey)}
                placeholder={element.name}
              />
            )}
          </Col>
        </Row>
      );
    });
  };

  const saveFormData = () => {
    console.log('saveFormData employer', employer);
    console.log('haveHadValues', haveHadValues);
    console.log('hearingRate', hearingRate);
    console.log('recentNoiseExposer', recentNoiseExposer);
    console.log('hearingProtection', hearingProtection);
    console.log('nonWorkNoiseExposure', nonWorkNoiseExposure);
    console.log('militoryWorkNoiseExposure', militoryWorkNoiseExposure);
    console.log('noiseTypeAtWork', noiseTypeAtWork);
    console.log('hasSecondJob', hasSecondJob);
    console.log('audiogramResult', audiogramResult);
    console.log('hasWorker', hasWorker);
    console.log('comment', comment);
    console.log('examinerSign', examinerSign);
    console.log('employeeSign', employeeSign);
    console.log('testingMethod', testingMethod);
    console.log('reasonTest', reasonTest);
    console.log('describeAbnormals', describeAbnormals);
    let params = {
      email: localStorage.getItem('myData'),
      user: localStorage.getItem('myData'),
      pass: localStorage.getItem('myPass'),

      employee_id: employer.employee_id,
      Employer: employer.companyName,
      Name: employer.Name,
      SSN: employer.ssn,
      Contact_Number: employer.contactNumber,
      Email: employer.Email,
      Allergies_Fever: haveHadValues.Allergies_Fever,
      Ear_Drainage: haveHadValues.Ear_Drainage,
      Ear_Injury: haveHadValues.Ear_Injury,
      Ear_Surgery: haveHadValues.Ear_Surgery,
      Excessive_Dizziness: haveHadValues.Excessive_Dizziness,
      Excessive_Ear_Ringing: haveHadValues.Excessive_Ear_Ringing,
      Frequent_Headaches: haveHadValues.Frequent_Headaches,
      Head_Injury: haveHadValues.Head_Injury,
      Hearing_Problems: haveHadValues.Hearing_Problems,
      High_Fever: haveHadValues.High_Fever,
      Problem_Not_Listed: haveHadValues.Problem_Not_Listed,
      Sinus_Trouble: haveHadValues.Sinus_Trouble,
      Hearing_Rate: hearingRate.Hearing_Rate,
      Recent_Noise_Exposer: recentNoiseExposer.Recent_Noise_Exposer,
      CURRENT_HEARING_PROTECTION: hearingProtection.CURRENT_HEARING_PROTECTION,
      Aircraft: nonWorkNoiseExposure.Aircraft,
      Chainsaws_Power_Tools: nonWorkNoiseExposure.Chainsaws_Power_Tools,
      Gunfire: nonWorkNoiseExposure.Gunfire,
      Heavy_Equipment: nonWorkNoiseExposure.Heavy_Equipment,
      Motorcycle_Speedboat: nonWorkNoiseExposure.Motorcycle_Speedboat,
      Other_Load_Noises_specify: nonWorkNoiseExposure.Other_Load_Noises_specify,
      Other_Load_Noises_specify_Other: nonWorkNoiseExposure.Other_Load_Noises_specify_Other,
      Mil_Aircraft: militoryWorkNoiseExposure.Mil_Aircraft,
      Mil_Gunfire: militoryWorkNoiseExposure.Mil_Gunfire,
      Mil_Other_Noise: militoryWorkNoiseExposure.Mil_Other_Noise,
      Mil_Other_Noise_Other: militoryWorkNoiseExposureData.Mil_Other_Noise_Other,
      Work_Type_Noise: noiseTypeAtWork.Work_Type_Noise,
      Second_Job_Now: hasSecondJob,

      External_Ear_Left: earDescribeAbnormal.External_Ear_Left,
      External_Ear_Right: earDescribeAbnormal.External_Ear_Right,
      Ear_Canal_Right: earDescribeAbnormal.Ear_Canal_Right,
      Ear_Canal_Left: earDescribeAbnormal.Ear_Canal_Left,
      Ear_Drum_Left: earDescribeAbnormal.Ear_Drum_Left,
      Ear_Drum_Right: earDescribeAbnormal.Ear_Drum_Left,
      External_Ear_Describe: earDescribeAbnormal.External_Ear_Describe,
      Ear_Canal_Describe: earDescribeAbnormal.Ear_Canal_Describe,
      Ear_Drum_Describe: earDescribeAbnormal.Ear_Drum_Describe,
      Audiogram_Result: audiogramResult.Audiogram_Result,
      audiometric_test_last_year: hasWorker.audiometric_test_last_year,
      audiometric_test_one_year_ago: hasWorker.audiometric_test_one_year_ago,
      audiometric_test_unknown: hasWorker.audiometric_test_unknown,
      exposed_noise14hours_test: hasWorker.exposed_noise14hours_test,
      working_prior_to_examination: hasWorker.working_prior_to_examination,
      Created_Comment: comment.Created_Comment,
      Examiner_Sign: examinerSign.Examiner_Sign,
      Employee_Sign: employeeSign.Employee_Sign,
      Testing_Method: testingMethod.Testing_Method,
      Reason_Test: reasonTest.Reason_Test,
      // Describe_Abnormals: describeAbnormals.Describe_Abnormals,
      created_date: createdDate,
      Ear_Injury_specific: earInjuryspecific,
      Problem_Not_Listed_specify: problemNotListedSpecify,
      exposed_noise14hours_test_specify: exposedNoise14hoursTestSpecify,
      External_Ear: describeAbnormals.External_Ear,
      //External_Ear_Describe: describeAbnormals.External_Ear,
      Ear_Canal: describeAbnormals.Ear_Canal,
      // Ear_Canal_Describe: describeAbnormals.Ear_Canal,
      Ear_Drum: describeAbnormals.Ear_Drum,
      // Ear_Drum_Describe: describeAbnormals.Ear_Drum,
      Testing_Method_other: testingMethod.Testing_Method_Other,
      Audiogram_Result_other: audiogramResult.Audiogram_Result_Other,
      Ear_Surgery_specific: haveHadValues.Ear_Surgery_Other,
      Ear_Injury_specific: haveHadValues.Ear_Injury_Other,
      Problem_Not_Listed_specify: haveHadValues.Problem_Not_Listed_Other,
      CURRENT_HEARING_PROTECTION_other: currentHearingProtectionOtherValue,
      Second_Job_Now_specify: secondJobOtherValue
    };
    setIsLoading(true);
    console.log("params :: ", params)
    axios
      .get(setting.serverUrl + '/api/saveAudioMetric', {
        params: params
      })
      .then((response) => {
        console.log('Respone from saveAudioGram', response.data);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        setIsLoading(false);
      });
  };
  const updateDescribeAbnormals = (value, key) => {
    let describeAbnormalsValue = describeAbnormals;
    describeAbnormalsValue[key] = value;
    setDescribeAbnormals(describeAbnormalsValue);
    console.log('describeAbnormalsValue::', describeAbnormalsValue);
  };
  const updateEarAbnormalDescribe = (key, value) => {

    let values = earDescribeAbnormal;
    values[key] = value;
    setEarDescribeAbnormal(values);
  };

  return isLoading ? (
    <>
      <Row>
        <Col span={10}></Col>
        <Col span={8}>
          <Space size='middle' style={{ marginTop: 10 }}>
            <Spin size='large' />
          </Space>
        </Col>
      </Row>
    </>
  ) : (
    <div style={{ padding: 20, overflow: 'auto', height: height }}>
      {/* {DataResponse.length>0 ? */}
      <div style={{ padding: 20 }}>
        <div className='container' style={{ minWidth: "100%" }}>

          <Row>
            <Col xs={24}
              style={{
                border: 'solid',
                borderWidth: '1px',
                borderColor: 'black',
                backgroundColor: 'black',
                color: 'white',
                fontWeight: 700,
                fontSize: 18,
                textAlign: 'center',
                padding: 5
              }}
            >
              EMPLOYEES INFORMATION
            </Col>
          </Row>
          {/* <div className='w-100'></div> */}
          <Row>
            <Col xs={24}
              style={{ border: 'solid', borderWidth: '1px', padding: 5, fontWeight: 700 }}
            >
              <span>
                Employer:
                <Input size='small' value={employer.companyName} placeholder='Employer' />
              </span>
            </Col>
          </Row>


          <Row>
            <Col xs={24} md={16}
              style={{ border: 'solid', borderWidth: '0.5px', padding: 5, fontWeight: 700 }}
            >
              Name:
              <Input size='small' value={employer.Name == false ? '' : employer.Name} placeholder='Name' />{' '}
            </Col>
            <Col xs={24} md={8}
              style={{ border: 'solid', borderWidth: '0.5px', padding: 5, fontWeight: 700 }}
            >

              SSN (Last 4):
              <Input size='small' value={employer.ssn == false ? '' : employer.ssn} placeholder='SSN (Last 4)' />
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12}
              style={{ border: 'solid', borderWidth: '0.5px', padding: 5, fontWeight: 700 }}
            >
              Contact Number:{' '}
              <Input size='small' value={employer.contactNumber == false ? '' : employer.contactNumber} placeholder='Contact Number' />
            </Col>
            <Col xs={24} md={12}
              style={{ border: 'solid', borderWidth: '0.5px', padding: 5, fontWeight: 700 }}
            >
              Email:
              <Input
                size='small'
                value={employer.Email}
                onChange={(e) => updateEmployerInfo(e.target.value, ' Email')}
                placeholder=' Email'
              />
            </Col>
          </Row>
          {/* <div className='w-100'></div> */}
          <div
            className='col'
            style={{
              border: 'solid',
              borderWidth: '0.5px',
              borderColor: 'black',
              backgroundColor: 'black',
              color: 'white',
              fontWeight: 700,
              fontSize: 18,
              textAlign: 'center',
              padding: 5
            }}
          >
            AUDIOGRAM HISTORY
          </div>

        </div>
        <div className='container' style={{ minWidth: '100%' }}>
          <div
            className='row'
            style={{
              fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )',
              textAlign: 'justify'
            }}
          >
            <div className='col'>
              The Genetic Information Nondiscrimination Act of 2008 (GINA) prohibits employers and
              other entities covered by GINA Title II from requesting or requiring genetic
              information of an individual or family member of the individual, except as
              specifically allowed by this law. To comply with this law, we are asking that you not
              provide any genetic information when responding to this request for medical
              information. ‘Genetic information’ as defined by GINA, includes an individual’s family
              medical history, the results of an individual’s or family member’s genetic tests, the
              fact that an individual or an individual's family member sought or received genetic
              services, and genetic information of a fetus carried by an individual or an
              individual’s family member or an embryo lawfully held by an individual or family
              member receiving assistive reproductive services.
            </div>
          </div>
        </div>
        <hr style={{ height: '0.2px', backgroundColor: 'black', marginTop: 0 }} />
        <br />
        <div
          className='container'
          onChange={(event) => updateHearingRate(event.target.value, 'Hearing_Rate')}
        >
          <Row>
            <Col xs={24} md={12}>HOW DO YOU RATE YOUR HEARING:</Col>
            <Col xs={24} md={12}>
              <input
                type='radio'
                name='HOW DO YOU RATE YOUR HEARING'
                onclick='handleClick(this);'
                value='Good'
              />{' '}
              &nbsp;Good &nbsp;&nbsp;&nbsp;
              <input type='radio' name='HOW DO YOU RATE YOUR HEARING' value='Fair' /> &nbsp;Fair
              &nbsp;&nbsp;&nbsp;
              <input type='radio' name='HOW DO YOU RATE YOUR HEARING' value='Poor' /> &nbsp;Poor
              &nbsp;&nbsp;&nbsp;
              <Row>
                <Col xs={24} sm={12}>
                  <input
                    type='radio'
                    name='HOW DO YOU RATE YOUR HEARING'
                    value='Difficulty hearing in
             crowds'
                  />{' '}
                  &nbsp;Difficulty hearing in crowds &nbsp;&nbsp;&nbsp;
                </Col>
                <Col xs={24} sm={12}>
                  <input
                    type='radio'
                    name='HOW DO YOU RATE YOUR HEARING'
                    value='Difficult to hear safety alarms'
                  />
                  &nbsp; Difficult to hear safety alarms &nbsp;&nbsp;&nbsp;
                </Col>
              </Row>
            </Col>

          </Row>
        </div>

        <br />
        <div
          className='container'
          onChange={(event) => updateRecentNoiseExposer(event.target.value, 'Recent_Noise_Exposer')}
        >
          <Row>
            <Col xs={24} sm={12}>Most Recent Noise Exposer Level:</Col>
            <Col xs={24} sm={12}>
              14 Hours Noise Free? &nbsp;&nbsp;
              <input type='radio' name=' 14 Hours Noise Free?' value='Yes' /> &nbsp;Yes &nbsp;&nbsp;
              <input type='radio' name=' 14 Hours Noise Free?' value='No' /> &nbsp;No
            </Col>

          </Row>
        </div>

        <br />
        <div
          className='container'
          style={{ border: 'solid', borderWidth: '0.2px', minWidth: '100%' }}
        >
          <Row className='row'>
            <Col xs={24} md={12} style={{ border: 'solid', borderWidth: '0.5px' }}>
              <div className='container' style={{ minWidth: "100%" }}>
                <Row>
                  <Col xs={24} sm={24}>
                    Mark an ‘X’ in the one appropriate box next to each item.
                  </Col>
                </Row>

                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24} sm={12}>
                    <Row>
                      <Col xs={8}>NOW</Col>
                      <Col xs={8}>Past</Col>
                      <Col xs={8}>Never</Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={12}>I HAVE / HAD:</Col>

                </Row>

                {renderNoiseExposureLevelHistory()}
                <br />
              </div>
            </Col>
            <Col xs={24} md={12} style={{ border: 'solid', borderWidth: '0.5px' }}>
              <div className='container' style={{ minWidth: "100%" }}>
                <div className='row'>
                  <div className='col'>CURRENT HEARING PROTECTION USED:</div>
                </div>
                <br />
                <div className='row'>
                  <div className='col'>
                    <div
                      onChange={(event) =>
                        updateHearingProtection(event.target.value, 'CURRENT_HEARING_PROTECTION')
                      }
                    >
                      <input type='radio' name='CURRENT HEARING PROTECTION USED' value='None' />{' '}
                      &nbsp; None &nbsp;&nbsp;
                      <input
                        type='radio'
                        name='CURRENT HEARING PROTECTION USED'
                        value='Ear Plugs'
                      />{' '}
                      &nbsp; Ear Plugs &nbsp;&nbsp;
                      <input
                        type='radio'
                        name='CURRENT HEARING PROTECTION USED'
                        value='Ear Muffs'
                      />{' '}
                      &nbsp; Ear Muffs
                      <br />
                      <input
                        type='radio'
                        name='CURRENT HEARING PROTECTION USED'
                        value='Both Plugs & Muffs'
                      />{' '}
                      &nbsp; Both Plugs & Muffs &nbsp;&nbsp;
                      <input
                        type='radio'
                        name='CURRENT HEARING PROTECTION USED'
                        value='Other'
                      />{' '}
                      &nbsp; Other:{' '}
                    </div>
                    <>
                      <Input
                        size='small'
                        disabled={currentHearingProtectionOther != 'Other'}
                        onChange={(event) =>
                          setCurrentHearingProtectionOtherValue(event.target.value)
                        }
                        placeholder='Other'
                      />
                    </>
                  </div>
                </div>

                <br />
                <hr style={{ height: '0.5px', backgroundColor: 'black' }} />
                <br />
                <div className='row'>
                  <div className='col'>TYPE OF NOISE AT WORK:</div>
                </div>
                <br />
                <div
                  className='row'

                >
                  <div className='col'>
                    <input
                      type='radio'
                      value='Constant'
                      name='TYPE OF NOISE AT WORK'
                      onChange={(event) => updateNoiseAtWork(event.target.value, 'Work_Type_Noise')}
                    />{' '}
                    &nbsp; Constant &nbsp;&nbsp;
                    <input
                      type='radio'
                      value='Intermittent'
                      name='TYPE OF NOISE AT WORK'
                      onChange={(event) => updateNoiseAtWork(event.target.value, 'Work_Type_Noise')}
                    />{' '}
                    &nbsp; Intermittent &nbsp;&nbsp;
                    <input
                      type='radio'
                      value='None'
                      name='TYPE OF NOISE AT WORK'
                      onChange={(event) => updateNoiseAtWork(event.target.value, 'Work_Type_Noise')}
                    />{' '}
                    &nbsp; None
                  </div>
                </div>
                <br />
                <hr style={{ height: '0.5px', backgroundColor: 'black' }} />
                <br />
                <Row>
                  <Col xs={24} sm={12} onChange={(event) => setHasSecondJob(event.target.value)}>
                    Do you have a second job now?
                  </Col>
                  <Col xs={24} sm={12}>
                    <input type='radio' name='Second Job' value='Yes' /> &nbsp; Yes &nbsp;&nbsp;
                    <input type='radio' name='Second Job' value='No' /> &nbsp; No
                  </Col>
                </Row>
                <br />
                <div className='row'>
                  <div className='col'>
                    If YES, does your second job involve expose to excessive noise? Specify:
                    <Input
                      size='small'
                      disabled={hasSecondJob != 'Yes'}

                      onChange={(e) => setSecondJobOtherValue(e.target.value)}
                      placeholder='Ear Surgery – Specific'
                    />
                    <br />
                  </div>
                </div>
                <br />
              </div>
            </Col>


            <Col xs={24} md={12} style={{ border: 'solid', borderWidth: '0.5px' }}>
              <div className='container' style={{ minWidth: "100%" }}>

                NON-WORK EXPOSURE TO NOISE: Do/Did you have significant exposure to any of the
                following without hearing protection outside of work? (Mark an ‘X’ in the Now,
                PST (Past), or NVR (Never) box next to each item.)

                <Row>
                  <Col xs={24} sm={12}>
                    <Row>
                      <Col xs={8}>NOW</Col>
                      <Col xs={8}>Past</Col>
                      <Col xs={8}>Never</Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={12}></Col>
                </Row>

                {renderNonWorkNoiseExposure()}
                <br />
              </div>
            </Col>
            <Col xs={24} md={12} style={{ border: 'solid', borderWidth: '0.5px' }}>
              <div className='container' style={{ minWidth: "100%" }}>
                <div className='row'>
                  <div className='col'>
                    MILITARY EXPOSURE TO NOISE: Do/Did you ever have exposure to any of the
                    following while in the military without hearing protection? (Mark an ‘X’ in the
                    Now, PST (Past), or NVR (Never) box next to each item.)
                  </div>
                </div>
                <Row>
                  <Col xs={24} sm={12}>
                    <Row>
                      <Col xs={8}>NOW</Col>
                      <Col xs={8}>Past</Col>
                      <Col xs={8}>Never</Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={12}></Col>
                </Row>
                {renderMilitoryWorkNoiseExposure()}
              </div>
            </Col>
          </Row>
        </div>

        <div className='container' style={{ minWidth: '100%' }}>
          <div className='row'>
            <div className='col'>
              I certify that the above information is complete and true. I understand that
              inaccurate, false or missing information may invalidate the examination and my Medical
              Examiner’s Certificate.
            </div>
          </div>
          <br />
          <Row>
            <Col xs={24} md={8}>
              <Input
                placeholder='Employees Signature'
                onChange={(event) => updateEmployeeSign(event.target.value, 'Employee_Sign')}
              />
              <br />
              Employees Signature
            </Col>
            <Col xs={24} md={8}></Col>
            <Col xs={24} md={8}>
              <DatePicker
                style={{ width: '100%' }}
                onChange={(date, dateString) => setCreatedDate(dateString)}
              />
              <br />
              Date
            </Col>
          </Row>
        </div>
        <br />
        <div
          className='container'
          style={{
            border: 'solid',
            borderWidth: '0.5px',
            borderColor: 'black',
            backgroundColor: 'black',
            color: 'white',
            fontWeight: 700,
            fontSize: 18,
            textAlign: 'center',
            padding: 5,
            minWidth: '100%'
          }}
        >
          OTOSCOPIC EXAM

        </div>
        <br />
        <div style={{ border: 'solid', borderWidth: '0.5px' }}>
          <div className='container'>

            <br />
            <div style={{ textAlign: "center" }}>TO BE COMPLETED BY TECHNICIAN AT THE TIME OF OTOSCOPIC EXAMINATION</div>

            <br />
            <Row>
              <Col xs={24} md={11}>
                HAS THE WORKER:
                <div className='container' style={{ minWidth: "100%" }}>
                  <Row>
                    <Col span={4}>Yes</Col>
                    <Col span={4}>No</Col>
                  </Row>
                  {renderHasTheWorker()}
                </div>
              </Col>
              <Col xs={24} md={2}></Col>
              <Col xs={24} md={11}

              >
                HAS THE WORKER: Check N (NORMAL) or A (ABNORMAL) for each ear. If A (ABNORMAL) is
                checked, describe the abnormality below.
                <div className='container' style={{ minWidth: '100%' }}>
                  <br />
                  <Row>
                    <Col xs={24} sm={6}></Col>
                    <Col xs={24} sm={8}>
                      <Row>
                        <Col xs={12}>RIGHT</Col>
                        <Col xs={12}>LEFT</Col>
                      </Row>
                      <Row>
                        <Col xs={6}>[ N ]</Col>
                        <Col xs={6}>[ A ]</Col>
                        <Col xs={6}>[ N ]</Col>
                        <Col xs={6}>[ A ]</Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={10}>
                      DESCRIBE ABNORMALS
                    </Col>
                  </Row>


                  <Row style={{ marginTop: '10px' }}>
                    <Col xs={24} sm={6}>
                      External Ear
                    </Col>
                    <Col xs={24} sm={8}>
                      <Row>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='External Ear Right'
                            value='[N]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe(
                                'External_Ear_Right',
                                event.target.value
                              )
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='External Ear Right'
                            value='[A]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe(
                                'External_Ear_Right',
                                event.target.value
                              )
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='External Ear Left'
                            value='[N]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('External_Ear_Left', event.target.value)
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='External Ear Left'
                            value='[A]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('External_Ear_Left', event.target.value)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={10}>
                      <Input
                        size='small'

                        // disabled={
                        //   !(
                        //     earDescribeAbnormal.Ear_Drum_Left === '[A]' ||
                        //     earDescribeAbnormal.Ear_Drum_Right === '[A]'
                        //   )
                        // }
                        onChange={(event) =>
                          updateEarAbnormalDescribe(
                            'External_Ear_Describe',
                            event.target.value
                          )
                        }
                        placeholder='Describe Abnormals'
                      />
                    </Col>
                  </Row>
                  {/* <div className='w-100'></div> */}

                  <Row style={{ marginTop: '10px' }}>
                    <Col xs={24} sm={6}>
                      Ear Canal
                    </Col>
                    <Col xs={24} sm={8}>
                      <Row>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='Ear Canal Right'
                            value='[N]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('Ear_Canal_Right', event.target.value)
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='Ear Canal Right'
                            value='[A]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('Ear_Canal_Right', event.target.value)
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='Ear Canal Left'
                            value='[N]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('Ear_Canal_Left', event.target.value)
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='Ear Canal Left'
                            value='[A]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('Ear_Canal_Left', event.target.value)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={10}>
                      <Input
                        size='small'

                        disabled={
                          earDescribeAbnormal.Ear_Canal_Left === '[A]' ||
                            earDescribeAbnormal.Ear_Canal_Right === '[A]'
                            ? earDescribeAbnormal.Ear_Canal_Describe
                            : ''
                        }
                        onChange={(event) =>
                          updateEarAbnormalDescribe(
                            'Ear_Canal_Describe',
                            event.target.value
                          )
                        }
                        placeholder='Describe Abnormals'
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginTop: '10px' }}>
                    <Col xs={24} sm={6}>
                      Ear Drum
                    </Col>
                    <Col xs={24} sm={8}>
                      <Row>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='Ear Drum Right'
                            value='[N]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('Ear_Drum_Right', event.target.value)
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='Ear Drum Right'
                            value='[A]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('Ear_Drum_Right', event.target.value)
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='Ear Drum Left'
                            value='[N]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('Ear_Drum_Left', event.target.value)
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                            type='radio'
                            name='Ear Drum Left'
                            value='[A]'

                            onChange={(event) =>
                              updateEarAbnormalDescribe('Ear_Drum_Left', event.target.value)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={10}>
                      <Input
                        size='small'

                        disabled={
                          earDescribeAbnormal.Ear_Drum_Left === '[A]' ||
                            earDescribeAbnormal.Ear_Drum_Right === '[A]'
                            ? earDescribeAbnormal.Ear_Drum_Describe
                            : ''
                        }
                        onChange={(event) =>
                          updateEarAbnormalDescribe(
                            'Ear_Drum_Describe',
                            event.target.value
                          )
                        }
                        placeholder='Describe Abnormals'
                      />
                    </Col>
                  </Row>

                  <br />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className='container'
          style={{
            border: 'solid',
            minWidth: '100%',
            borderWidth: '0.5px',
            borderColor: 'black',
            backgroundColor: 'black',
            color: 'white',
            fontWeight: 700,
            fontSize: 18,
            textAlign: 'center',
            padding: 5
          }}
        >
          <div className='row'>
            <div className='col'>AUDIOGRAM RESULT</div>
          </div>
        </div>
        <br />
        <div className='container' style={{ minWidth: "100%" }}>
          <div className='row'>
            <div
              className='col'
              style={{
                backgroundColor: ' grey',
                fontWeight: 700,
                paddingRight: 5,
                fontSize: 18,
                textAlign: 'right',
                padding: 5
              }}
            >
              <i>Audiogram Test Must Be Attached to medical File</i>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div
          className='container' style={{ minWidth: "100%" }}
          onChange={(event) => updateReasonTest(event.target.value, 'Reason_Test')}
        >
          <div className='row'>
            <div className='col'>
              Reason for Test:
              <input
                type='radio'
                name=' Reason for Test'
                value='Pre-Employment, Baseline Audiogram'
              />
              &nbsp; Pre-Employment, Baseline Audiogram &nbsp;&nbsp;
              <input type='radio' name=' Reason for Test' value='Baseline' /> &nbsp; Baseline
            </div>
          </div>
        </div>
        <hr style={{ height: '1px', backgroundColor: 'black' }} />
        <div className='container' style={{ minWidth: "100%" }}>
          <div className='row'>
            <div className='col' style={{ fontWeight: '700' }}>
              Testing Method:
            </div>
          </div>
          <br />
          <Row>
            <Col xs={24} sm={12}>
              <div onChange={(event) => updateTestingMethod(event.target.value, 'Testing_Method')}>
                <input
                  type='radio'
                  name='Testing Method'
                  value='Supra-aural earphones (ear muffs)'
                />
                &nbsp; Supra-aural earphones (ear muffs)&nbsp;&nbsp;
                <br />
                <input type='radio' name='Testing Method' value='Ear inserts' /> &nbsp; Ear inserts
                <br />
                <input type='radio' name='Testing Method' value='Other' /> &nbsp; Other. Please
                list:
              </div>
              <Input
                size='small'
                onChange={(event) =>
                  updateTestingMethod(event.target.value, 'Testing_Method_other')
                }
                placeholder='Other. Please list'
              />
            </Col>
            <Col xs={24} sm={12}>
              <input
                type='radio'
                name='Testing Method'
                value='Used hearing booth or Sound proof room'
                onChange={(event) =>
                  updateTestingMethod(event.target.value, 'Testing_Method_other_value')
                }
              />
              &nbsp;Used hearing booth or Sound proof room
            </Col>
          </Row>
          <br />
        </div>

        <hr style={{ height: '1px', backgroundColor: 'black' }} />
        <div className='container' style={{ minWidth: "100%" }}>
          <div className='row'>
            <div className='col' style={{ fontWeight: '700' }}>
              Audiogram Result
            </div>
          </div>
          <br />
          <div className='row'>
            <div
              className='col'
              style={{ fontWeight: '700' }}
              onChange={(event) => updateAudiogramResult(event.target.value, 'Audiogram_Result')}
            >
              <input type='radio' name='Audiogram Result' value='Hearing is Normal or Acceptable' />{' '}
              &nbsp; Hearing is Normal or Acceptable
            </div>
          </div>
          <br />
          <div className='row'>
            <div
              className='col'
              style={{ fontWeight: '700' }}
              onChange={(event) => updateAudiogramResult(event.target.value, 'Audiogram_Result')}
            >
              <input type='radio' name='Audiogram Result' value='Wear Ear Plugs, Yearly Recheck' />
              &nbsp; Wear Ear Plugs, Yearly Recheck
            </div>
          </div>
          <br />
          <div className='row'>
            <div
              className='col'
              style={{ fontWeight: '700' }}
              onChange={(event) => updateAudiogramResult(event.target.value, 'Audiogram_Result')}
            >
              <input type='radio' name='Audiogram Result' value='Needs Follow-Up' /> &nbsp; Needs
              Follow-Up
            </div>
          </div>
          <br />
          <div className='row'>
            <div
              className='col'
              style={{ fontWeight: '700' }}
              onChange={(event) => updateAudiogramResult(event.target.value, 'Audiogram_Result')}
            >
              <input type='radio' name='Audiogram Result' value=' Needs ENT Referral' /> &nbsp;
              Needs ENT Referral
            </div>
          </div>
          <br />
          <div className='row'>
            <div
              className='col'
              style={{ fontWeight: '700' }}
              onChange={(event) => updateAudiogramResult(event.target.value, 'Audiogram_Result')}
            >
              <input
                type='radio'
                name='Audiogram Result'
                value='Other'
                onChange={(event) => updateAudiogramResult(event.target.value, 'Audiogram_Result')}
              />{' '}
              &nbsp; Other
            </div>
          </div>
          <div className='row'>
            <div className='col' style={{ fontWeight: '700' }}>
              <Input
                size='small'
                onChange={(event) =>
                  updateAudiogramResult(event.target.value, 'Audiogram_Result_Other')
                }
                placeholder='Other '
              />
            </div>
          </div>
          <br />
          <br />
          <div className='row'>
            <div className='col' style={{ fontWeight: '700' }}>
              Comments
              <TextArea

                onChange={(event) => updateComment(event.target.value, 'Created_Comment')}
                rows={4}
              />
            </div>
          </div>
        </div>
        <br />
        <div className='container' style={{ minWidth: "100%" }}>
          <Row>
            <Col xs={24} sm={6} style={{ border: 'solid', borderWidth: '0.5px', padding: 20 }}>
              Examiners Signature
            </Col>
            <Col xs={24} sm={18} style={{ border: 'solid', borderWidth: '0.5px', padding: 20 }}>
              <Input
                size='small'
                onChange={(event) => updateExaminerSign(event.target.value, 'Examiner_Sign')}
                placeholder=' Examiners Signature'
              />
            </Col>
          </Row>
          <Row>

            <Col xs={24} sm={6} style={{ border: 'solid', borderWidth: '0.5px', padding: 20 }}></Col>
            <Col xs={24} sm={18} style={{ border: 'solid', borderWidth: '0.5px', padding: 20 }}></Col>
          </Row>
        </div>
      </div>
      <br />
      <br />
      <div class='container'>
        <div class='row'>
          <div class='col' style={{ textAlign: 'center' }}>
            <Button type='primary' style={{ width: '30%', backgroundColor:"#1b3969" }} onClick={() => saveFormData()}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>

  );
};
export default AudiometricHistory;
