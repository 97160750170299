import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Tabs, Spin, Space, Row, Col } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import _ from 'underscore';
import setting from '../../config/setting';
import TestDetailOfEmployee from './Test_Detail_of_Employee/TestDetailOfEmployee';
import CovidTestOfEmployee from './Test_Detail_of_Employee/CovidTestOfEmployee';
import CovidVaccinationOfEmployee from './Test_Detail_of_Employee/CovidVaccinationOfEmployee';
import AttachmentsDetail from './Test_Detail_of_Employee/AttachmentsDetail';
import CovidReportOfEmployee from './Test_Detail_of_Employee/CovidReportTab';
const config = require('../keyFront');
const { TabPane } = Tabs;
let navTabCheck;
const EmployeeDetail = ({ patient }) => {
  let IdtypeCheckValue = JSON.parse(localStorage.getItem('IdtypeCheckValue'));
  navTabCheck = JSON.parse(localStorage.getItem('NavTabCheck'));

  const renderField = (fieldName, label, patient) => (
    <p key={fieldName}>
      <span style={{ width: '49.5%', display: 'inline-block' }}>{label} :</span>{' '}
      <span style={{ width: '30%', display: 'inline-block' }}>
        {patient && patient[fieldName] ? patient[fieldName] : ''}
      </span>
    </p>
  );

  const renderFieldsBasedOnIdType = (IdtypeCheckValue, patient) => {
    return IdtypeCheckValue.map((idType) => {
      switch (idType) {
        case 'SSN':
          return renderField('emp_ssn', 'Employee SSN', patient);
        case 'EIN':
          return renderField('emp_ein', 'Employee EIN', patient);
        case 'DL':
          return renderField('DL', 'Employee DL', patient);
        default:
          return null;
      }
    });
  };

  const renderedFields = renderFieldsBasedOnIdType(IdtypeCheckValue, patient);

  return (
    <Form layout='vertical' style={{ padding: 20, borderRadius: 20, backgroundColor: '#fff' }}>
      <div className='row'>
        {/* <div className='col-md-1 col-sm-1'></div> */}
        <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px', paddingLeft:'50px' }}>
          <h6>PERSONAL INFORMATION</h6>
          <p>
            <span style={{ width: '60%', display: 'inline-block' }}>First Name :</span>{' '}
            <span style={{ width: '30%', display: 'inline-block' }}>
              {patient && patient.first_name ? patient.first_name : ''}
            </span>
          </p>
          <p>
            <span style={{ width: '60%', display: 'inline-block' }}>Last Name :</span>
            <span style={{ width: '30%', display: 'inline-block' }}>
              {patient && patient.last_name ? patient.last_name : ''}
            </span>
          </p>
          <p>
            <span style={{ width: '60%', display: 'inline-block' }}>DOB :</span>
            <span style={{ width: '30%', display: 'inline-block' }}>
              {' '}
              {patient && patient.dob ? patient.dob : ''}
            </span>
          </p>
          <p>
            <span style={{ width: '60%', display: 'inline-block' }}>Sex :</span>{' '}
            <span style={{ width: '30%', display: 'inline-block' }}>
              {patient && patient.Gender ? patient.Gender : ''}
            </span>
          </p>
          <p>
            <span style={{ width: '60%', display: 'inline-block' }}>Mobile Number :</span>{' '}
            <span style={{ width: '30%', display: 'inline-block' }}>
              {patient && patient.Mobile ? patient.Mobile : ''}
            </span>
          </p>
          <p>
            <span style={{ width: '60%', display: 'inline-block' }}>Alternate Phone :</span>
            <span style={{ width: '30%', display: 'inline-block' }}>
              {' '}
              {patient && patient.Alternate_Phones && patient.Alternate_Phones != 'NaN'
                ? patient.Alternate_Phones
                : ''}
            </span>
          </p>
          {/* <p>
            <span style={{ width: '30%', display: 'inline-block' }}>Work Email :</span>
            <span style={{ width: '60%', display: 'inline-block' }}>
              {patient && patient.Email_work ? patient.Email_work : ''}
            </span>
          </p> */}

          {/* <p>
            <span style={{ width: '30%', display: 'inline-block' }}>Nationality : </span>
            <span style={{ width: '35%', display: 'inline-block' }}>
              {patient && patient.newNationality ? patient.newNationality : ''}
            </span>
          </p> */}
          {/* <p>
            <span style={{ width: '30%', display: 'inline-block' }}>Passport : </span>
            <span style={{ width: '35%', display: 'inline-block' }}>
              {patient && patient.newPassport ? patient.newPassport : ''}{' '}
            </span>
          </p> */}
          {/* <p>
            <span style={{ width: '30%', display: 'inline-block' }}>Marital Status : </span>
            <span style={{ width: '35%', display: 'inline-block' }}>
              {patient && patient.statusMarital ? patient.statusMarital : ''}{' '}
            </span>
          </p> */}
        </div>

        {/* <div className='col-md-1 col-sm-1'></div> */}
        <div className='col-md-6 col-sm-5' style={{paddingLeft:50}}>
          <h6>EMPLOYER INFORMATION</h6>

          <p>
            <span style={{ width: '50%', display: 'inline-block' }}>Status :</span>{' '}
            <span style={{ width: '40%', display: 'inline-block' }}>
              {patient && patient.emp_status ? patient.emp_status : ''}
            </span>
          </p>
          {renderedFields}

          <p>
            <span style={{ width: '50%', display: 'inline-block' }}>Location : </span>{' '}
            <span style={{ width: '40%', display: 'inline-block' }}>
              {patient && patient.emp_location ? patient.emp_location : ''}
            </span>
          </p>
          <p>
            <span style={{ width: '50%', display: 'inline-block' }}>Supervisor : </span>{' '}
            <span style={{ width: '40%', display: 'inline-block' }}>
              {patient && patient.newSupervisor ? patient.newSupervisor : ''}
            </span>
          </p>
          <p>
            <span style={{ width: '50%', display: 'inline-block' }}>Email : </span>{' '}
            <span style={{ width: '40%', display: 'inline-block' }}>
              {patient && patient.email ? patient.email : ''}
            </span>
          </p>
          <p>
            <span style={{ width: '50%', display: 'inline-block' }}>Job Title : </span>{' '}
            <span style={{ width: '40%', display: 'inline-block' }}>
              {patient && patient.jobTitle ? patient.jobTitle : ''}
            </span>
          </p>
          <p>
            <span style={{ width: '50%', display: 'inline-block' }}>Department : </span>{' '}
            <span style={{ width: '40%', display: 'inline-block' }}>
              {patient && patient.newDept ? patient.newDept : ''}
            </span>
          </p>
          {/* <p>
            <span style={{ width: '35%', display: 'inline-block' }}>Additional Company : </span>{' '}
            <span style={{ width: '60%', display: 'inline-block' }}>
              {' '}
              {patient && patient.addCompany ? patient.addCompany : ''}
            </span>
          </p> */}
          <p>
            <span style={{ width: '50%', display: 'inline-block' }}>Hire/Rehire Date :</span>{' '}
            <span style={{ width: '40%', display: 'inline-block' }}>
              {' '}
              {patient && patient.hireRehireDate ? '' : patient.hireRehireDate}
            </span>
          </p>
          <p>
            <span style={{ width: '50%', display: 'inline-block' }}>Termination Date :</span>{' '}
            <span style={{ width: '40%', display: 'inline-block' }}>
              {' '}
              {patient && patient.terminationDate ? '' : patient.terminationDate}
            </span>
          </p>
        </div>
      </div>
    </Form>
  );
};
const PatientProfilePage = () => {
  const [empName, setEmployeeFirstName] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [CovidVaccinationData, setCovidVaccinationData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);
  const [CovidTestData, setCovidTestData] = useState([]);
  const [CovidReportData, setCovidReportData] = useState([]);

  const [attachmentChange, setAttachmentChange] = useState(false);
  const [change1, setChange1] = useState(false);
  const [change, setChange] = useState(false);
  const location = useLocation();
  const data = location.state;
  const [covidVaccinationChange, setCovidVaccinationChange] = useState(true);
  const [covidTestChange, setCovidTestChange] = useState(true);
  const [isCovReportLoading, setIsCovReportLoading] = useState(false);

  const [covidReportChange, setCovidReportChange] = useState(true);
  const [docVaccPermission, setdocVaccPermission] = useState(false);
  const [docTestPermission, setdocTestPermission] = useState(false);
  const [hasAttachmentPermission, setHasAttachmentPermission] = useState(false);
  const [hasVaccPermission, setHasVaccPermission] = useState(false);
  const [hasCovTestPermission, setHasCovTestPermission] = useState(false);
  const [hasCovReportPermission, setHasCovReportPermission] = useState(false);
  const [isVaccLoading, setIsVaccLoading] = useState(false);
  const [isCovTestLoading, setIsCovTestLoading] = useState(false);
  const [errVaccMsg, setErrVaccMsg] = useState('');
  const [covTestErrMsg, setCovTestErrMsg] = useState('');
  const [covReportErrMsg, setCovReportErrMsg] = useState('');

  let history = useHistory();
  useEffect(() => {
    if (data != undefined) {
      setEmployeeFirstName(data['display_name']);
      // if (navTabCheck && navTabCheck[0].covid_vac) {
      //   getVaccination();
      //   getVaccDocPermission();
      // }
      if (navTabCheck && navTabCheck[0].covid_test) {
        getTestData(data);
        getTestDocPermission();
      }
      getAttachment();
      getAttachmentPermission();
    } else {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    createTable();
  }, [CovidVaccinationData]);

  const getAttachmentPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.CovidVaccinationDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setHasAttachmentPermission(true);
        }
      });
  };

  const getVaccDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.CovidVaccinationDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setdocVaccPermission(true);
        }
      });
  };
  
  const getTestDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.TestDetailDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setdocTestPermission(true);
        }
      });
  };

  const getAttachment = () => {
    setAttachmentChange(true);
    axios
      .post(setting.serverUrl + '/api/getEmployeeAttachmentCustomer', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          employeeId: data['id']
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status === 200) {
          if (response.data.length === 0) {
            setAttachmentChange(true);
          } else {
            setAttachmentChange(false);
          }
          setAttachmentData(response.data);
          setAttachmentChange(false);
        }
      });
  };

  function getTestData(data) {
    setIsCovTestLoading(true);
    setCovidTestChange(true);
    axios
      .post(setting.serverUrl + '/api/Covid_test_CustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id,
          empId: data.id
        }
      })
      .then((response) => {
        setIsCovTestLoading(false);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          let employeeTests = response && response.data;
          console.log('employee list covid test===========', employeeTests);
          setHasCovTestPermission(true);
          setCompanyName(response.data.length > 0 && response.data[0].display_name);
          setCovidTestData(employeeTests);
          setCovidTestChange(false);
        } else if (response.status === 201) {
          setCovTestErrMsg('Access permission not allowed.');
        }
      });
  }

  function download2(e, attachment_url) {
    setChange1(change1);
    axios
      .post(setting.serverUrl + '/api/downloadTestingCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_url: attachment_url
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        setChange1(!change1);
        window.open(response.data);
      });
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };

  const createTable = () => {
    let table = [];

    if (CovidVaccinationData.length > 0) {
      CovidVaccinationData.map((data) => {
        let d = table.filter((t) => t.id === data.employee[0]);

        if (d.length === 0) {
          let employeeData = {
            id: data.employee[0],
            name: data.employee[1],
            status: data.testing_status,
            conducted_by: data.conducted_by,
            attachment_url: data.attachment_url,
            attachment_id: data.attachment_id,
            vaccination: [],
            no_of_vaccination: 0
          };

          let vaccine = {
            vacData:
              data.test_date && data.test_type ? data.test_date + ' - ' + data.test_type : '',
            attachment_url: data.attachment_url,
            attachment_id: data.attachment_id
          };
          employeeData.vaccination.push(vaccine);
          employeeData.no_of_vaccination += 1;
          table.push(employeeData);
        } else {
          table.map((t) => {
            if (t.id === data.employee[0]) {
              let vaccine = {
                vacData:
                  data.test_date && data.test_type ? data.test_date + ' - ' + data.test_type : '',
                attachment_url: data.attachment_url,
                attachment_id: data.attachment_id
              };
              t.vaccination.push(vaccine);
              t.attachment_url = data.attachment_url ? data.attachment_url : t.attachment_url;
              t.attachment_id = data.attachment_id ? data.attachment_id : t.attachment_id;
              t.no_of_vaccination += 1;
            }
          });
        }
      });
      console.log('table in covid vaccination', table);
      setTableData(table);
    }
  };

  const getVaccination = () => {
    setCovidVaccinationChange(true);
    setIsVaccLoading(true);
    axios
      .post(setting.serverUrl + '/api/Covid_Vaccination_customerportal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          employeeId: data['id']
        }
      })
      .then((response) => {
        setIsVaccLoading(false);
        if (response && response.status == 200 && response.data.length == 0) {
          setHasVaccPermission(true);
          setCovidVaccinationChange(false);
        } else if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.length > 0
        ) {
          setHasVaccPermission(true);
          let vaccDataSorted = response.data.sort(
            (a, b) => new Date(b.test_date) - new Date(a.test_date)
          );

          let vaccDataFiltered = vaccDataSorted.filter(function (e) {
            return e.employee[1].trim() === data['display_name'].trim();
          });

          setCovidVaccinationData(vaccDataFiltered);
          setCompanyName(response && response.data[0].display_name);
          setCovidVaccinationChange(false);
        } else if (response.status === 201) {
          setCovidVaccinationChange(true);
          setErrVaccMsg('Access permission not allowed.');
        }
      });
  };
  function download(e, attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        setChange(!change);
        if (Array.isArray(response.data)) {
          setIsModalVisible(true);
          setImageUrls(response.data);
        } else {
          window.open(response.data);
        }
      });
  }
  function downloadAttachment(e, url) {
    axios
      .post(setting.serverUrl + '/api/downloadEmployeeAttachments', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: url
        }
      })
      .then((response) => {
        setChange(!change);
        setIsModalVisible(true);
        setImageUrl(response.data);
      });
  }

  return (
    <div style={{ padding: 20 }}>
      {data && (
        <>
          <div>
            <Button
              size='small'
              style={{
                backgroundColor: 'rgb(61 88 106)',
                color: 'white',
                marginTop: 0,
                padding: 10
              }}
              onClick={() => history.push({ pathname: `/vertical/employeeList` })}
            >
              Back
            </Button>
          </div>
          <h3 style={{ textAlign: 'left' }}>{empName ? empName + ' - Employee Details' : ''}</h3>
          <div>
            <EmployeeDetail patient={data} />
          </div>

          <br />
          <Tabs defaultActiveKey='1' type='card'>
            <TabPane
              tab={
                <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>
                  Test Results
                </span>
              }
              key='1'
              style={{ fontSize: 6 }}
            >
              <TestDetailOfEmployee />
            </TabPane>
            {navTabCheck &&
              navTabCheck.length > 0 &&
              navTabCheck[0].covid_reports &&
              navTabCheck[0].covid_reports != null &&
              navTabCheck[0].covid_reports != false && (
                <TabPane
                  tab={
                    <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>
                      Covid Reports
                    </span>
                  }
                  key='9'
                  style={{ fontSize: 6 }}
                >
                  <div>
                    <div className='row'>
                      <div className='col-12 col-md-12 col-xl-12'>
                        <CovidReportOfEmployee empId={data['id']} />{' '}
                      </div>
                    </div>

                    <Modal
                      width={1000}
                      title='Covid Reports'
                      visible={isModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                    >
                      {imageUrls &&
                        imageUrls.length > 0 &&
                        imageUrls.map((img) => {
                          console.log('img :: ', img);
                          return (
                            <p>
                              {img.fileType && img.fileType == 'application/pdf' ? (
                                <embed src={img.url} width='800px' height='2100px' />
                              ) : (
                                <img src={img.url} />
                              )}
                            </p>
                          );
                        })}
                    </Modal>
                  </div>
                </TabPane>
              )}
            {navTabCheck &&
              navTabCheck.length > 0 &&
              navTabCheck[0].covid_test &&
              navTabCheck[0].covid_test != null &&
              navTabCheck[0].covid_test != false && (
                <TabPane
                  tab={
                    <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>
                      Covid Testing
                    </span>
                  }
                  key='2'
                  style={{ fontSize: 6 }}
                >
                  <div>
                    <div className='row'>
                      <div className='col-12 col-md-12 col-xl-12'>
                        {hasCovTestPermission ? (
                          !covidTestChange ? (
                            <div>
                              {CovidTestData.length > 0 ? (
                                <>
                                  <CovidTestOfEmployee
                                    data={CovidTestData}
                                    docTestPermission={docTestPermission}
                                    download2={download2}
                                  />
                                </>
                              ) : (
                                <p
                                  style={{
                                    fontSize: 18,
                                    fontWeight: 700,
                                    padding: 20,
                                    color: '#ef6727'
                                  }}
                                >
                                  No Record Found!
                                </p>
                              )}
                            </div>
                          ) : (
                            <Row>
                              <Col span={10}></Col>
                              <Col span={8}>
                                <Space size='middle' style={{ marginTop: 10,marginLeft:85 }}>
                                  <Spin size='large' />
                                </Space>
                              </Col>
                            </Row>
                          )
                        ) : (
                          <div>
                            {isCovTestLoading && (
                              <Row>
                                <Col span={10}></Col>
                                <Col span={8}>
                                  <Space size='middle' style={{ marginTop: 50,marginLeft:85 }}>
                                    <Spin size='large' />
                                  </Space>
                                </Col>
                              </Row>
                            )}
                            <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
                              {covTestErrMsg}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <Modal
                      width={1000}
                      title='Covid Tests'
                      visible={isModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                    >
                      {imageUrls &&
                        imageUrls.length > 0 &&
                        imageUrls.map((img) => {
                          console.log('img :: ', img);
                          return (
                            <p>
                              {img.fileType && img.fileType == 'application/pdf' ? (
                                <embed src={img.url} width='800px' height='2100px' />
                              ) : (
                                <img src={img.url} />
                              )}
                            </p>
                          );
                        })}
                    </Modal>
                  </div>
                </TabPane>
              )}
            {navTabCheck &&
              navTabCheck.length > 0 &&
              navTabCheck[0].covid_vac &&
              navTabCheck[0].covid_vac != null &&
              navTabCheck[0].covid_vac != false && (
                <TabPane
                  tab={
                    <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>
                      Covid Vaccinations
                    </span>
                  }
                  key='3'
                  style={{ fontSize: 6 }}
                >
                  <div>
                    <Card>
                      <div className='row'>
                        <div className='col-12 col-md-12 col-xl-12'>
                          <CovidVaccinationOfEmployee empId={data['id']} />
                        </div>
                      </div>
                    </Card>
                  </div>
                </TabPane>
              )}
            <TabPane
              tab={
                <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>
                  Attachments Detail
                </span>
              }
              key='4'
              style={{ fontSize: 6 }}
            >
              <div>
                {!attachmentChange ? (
                  <div>
                    {attachmentData.length > 0 ? (
                      <>
                        <AttachmentsDetail
                          data={attachmentData}
                          downloadAttachment={downloadAttachment}
                          hasAttachmentPermission={hasAttachmentPermission}
                        />
                      </>
                    ) : (
                      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: '#ef6727' }}>
                        No Record Found!
                      </p>
                    )}
                  </div>
                ) : (
                  <Row>
                    <Col span={10}></Col>
                    <Col span={8}>
                      <Space size='middle' style={{ marginTop: 10, marginLeft:85 }}>
                        <Spin size='large' />
                      </Space>
                    </Col>
                  </Row>
                )}
                <Modal
                  width={1000}
                  title='Attachments'
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <p>{<embed src={imageUrl} width='800px' height='2100px' />}</p>
                </Modal>
              </div>
            </TabPane>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default PatientProfilePage;
