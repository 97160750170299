import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spin, Space, Row, Col, Button, Form, Divider, Checkbox } from 'antd';
import moment from 'moment';
import AudiogramInfoLtEarTable from './audiogramInfoLtEarTable';
import AudiogramInfoRtEarTable from './audiogramInfoRtEarTable';
import AudiogramInfoFireArmTable from './audiogramInfoFireArmTable';
import AudiogramInfoNoiseExTable from './audiogramInfoNoiseExTable'

const AudiogramDetails = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    console.log('this.props customerId 1', localStorage.getItem('customerId'));
    if (location && location.state) {
      let props = location.state;
      console.log('propsaudiogramlist===:::', props);
    } else {
      history.goBack();
    }
  }, []);

  // console.log('location.state', location.state);
  const splitText = location?.state?.review?.split('\n');

  return (
    <div>
      <Button
        size='small'
        style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <br />
      <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Audiogram Detail</h3>
      {location && location.state ? (
        <Form layout='vertical' style={{ padding: 20, borderRadius: 20, backgroundColor: '#fff' }}>
          <h5>Section 1 : About the Employee </h5>
          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Name :</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {' '}
                  {location &&
                    location.state &&
                    location.state.first_name + ' ' + location.state.last_name}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>EIN :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.ein ? location.state.ein : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Phone :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.ph_num ? location.state.ph_num : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Email Address :</span>
                <span style={{ width: '50%', display: 'inline-block' }}>
                  {' '}
                  {location && location.state && location.state.email ? location.state.email : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Hire Date : </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.hire_date
                    ? moment(location.state.hire_date).format('MM-DD-YYYY')
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Location : </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.name ? location.state.name : ''}
                </span>
              </p>
            </div>

            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5'>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Facility : </span>
                <span style={{ width: '70%', display: 'inline-block' }}>
                  {location && location.state && location.state.facility
                    ? location.state.facility
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Department :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.department
                    ? location.state.department
                    : ''}
                </span>
              </p>

              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Test Date : </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.test_date
                    ? moment(location.state.test_date).format('MM-DD-YYYY')
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  Confirming Test Date :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location &&
                  location.state &&
                  location.state.confirming_test_date &&
                  location.state.confirming_test_date != null
                    ? moment(location.state.confirming_test_date).format('MM-DD-YYYY')
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  TWA (Time Weighted Average) Exposure :{' '}
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location &&
                  location.state &&
                  location.state.current_test &&
                  location.state.current_test != null
                    ? location.state.current_test
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <Divider />
          <h5>Section 2 : About the Interviewer </h5>
          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}> Interviewer Name :</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {' '}
                  {location && location.state && location.state.interviewer_name}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  Interviewer Job Title :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.interviewer_job
                    ? location.state.interviewer_job
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Date on Interview :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.interviewer_date
                    ? moment(location.state.interviewer_date).format('MM-DD-YYYY')
                    : ''}
                </span>
              </p>
            </div>

            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5'>
              <p>
                <span style={{ width: '36%', display: 'inline-block' }}>
                  Interviewer's Work Phone :{' '}
                </span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.interviewer_work_phone
                    ? location.state.interviewer_work_phone
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '35%', display: 'inline-block' }}>
                  Interviewer's Work Fax :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.interviewer_fax
                    ? location.state.interviewer_fax
                    : ''}
                </span>
              </p>

              <p>
                <span style={{ width: '35%', display: 'inline-block' }}>
                  Interviewer's Email :{' '}
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.interviewer_email
                    ? location.state.interviewer_email
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <Divider />
          <h5>Section 3 : Workplace Noise Exposure </h5>
          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {' '}
                  TWA (Time Weighted Exposure) :
                </span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {' '}
                  {location &&
                  location.state &&
                  location.state.current_test &&
                  location.state.current_test != null
                    ? location.state.current_test
                    : ''}{' '}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  Shift Length (8hrs, 10hrs, etc) :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.shift_length
                    ? location.state.shift_length
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  Type of hearing protection worn :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.hearing_protection
                    ? location.state.hearing_protection
                    : ''}
                </span>
              </p>
            </div>

            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5'>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  NRR (Noise reduction rating of protectors) :{' '}
                </span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.nrr ? location.state.nrr : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  Has workplace noise level increased :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.workplace_noise
                    ? location.state.workplace_noise
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  Additional Information regarding Employee's Workplace Noise :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.workplace_noise_comment
                    ? location.state.workplace_noise_comment
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <Divider />
          <h5>Test History for Right Ear</h5>
          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-10 col-sm-10'>
              <AudiogramInfoRtEarTable id={location && location.state && location.state.employee} />
            </div>

            <div className='col-md-1 col-sm-1'></div>
          </div>
          <Divider />
          <h5>Test History for Left Ear</h5>

          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-10 col-sm-10'>
              <AudiogramInfoLtEarTable id={location && location.state && location.state.employee} />
            </div>

            <div className='col-md-1 col-sm-1'></div>
          </div>
          <br />
          <Divider />
          <h5>Most Recent Test :</h5>
          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Examiner :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.examiner
                    ? location.state.examiner
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Model :</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.model ? location.state.model : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {' '}
                  Hours Since Last Exposure :
                </span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {' '}
                  {location && location.state && location.state.hours ? location.state.hours : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Exposure :</span>{' '}
                <span style={{ width: '40%', display: 'inline-block' }}>
                {location && location.state && location.state.exposure ? location.state.exposure : ''}
                </span>
              </p>
              

              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>Department :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.examiner_department
                    ? location.state.examiner_department
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Job : </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.job ? location.state.job : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Shift :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.shift ? location.state.shift : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Protector Type :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.protector_type
                    ? location.state.protector_type
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Facility :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.examiner_facility
                    ? location.state.examiner_facility
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Comment :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.examiner_comment
                    ? location.state.examiner_comment
                    : ''}
                </span>
              </p>
            </div>

            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5'>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Serial :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.serial ? location.state.serial : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Cal Date :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.cal_date
                    ? location.state.cal_date
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Protector Use :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.protector_use
                    ? location.state.protector_use
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>Lf Otoscope :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.lf_otoscope
                    ? location.state.lf_otoscope
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>Rt Otoscope:</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.rt_otoscope
                    ? location.state.rt_otoscope
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Training :</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.training
                    ? location.state.training
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Refer Subject :</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.refer_subject
                    ? location.state.refer_subject
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Self Eval :</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.self_eval
                    ? location.state.self_eval
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <Divider />
          <h5>Section 4a: Non -Work Related Exposure</h5>

          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-10 col-sm-10'>
              <AudiogramInfoNoiseExTable id={location && location.state && location.state.employee} />
            </div>

            <div className='col-md-1 col-sm-1'></div>
          </div>
          <Divider />
          <h5>Section 4b : Firearm Activity</h5>

          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-10 col-sm-10'>
              <AudiogramInfoFireArmTable
                id={location && location.state && location.state.employee}
              />
            </div>

            <div className='col-md-1 col-sm-1'></div>
          </div>
          <Divider />

          <h5>Section 5 : Medical History</h5>
          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
              <p>
                <span style={{ width: '45%', display: 'inline-block' }}>
                  {' '}
                  Do you have a hearing loss ?{' '}
                </span>{' '}
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {location && location.state && location.state.hearing_loss
                    ? location.state.hearing_loss
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '46%', display: 'inline-block' }}>
                  {' '}
                  Family members with hearing loss ?{' '}
                </span>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {location && location.state && location.state.family_hearing_loss
                    ? location.state.family_hearing_loss
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '45%', display: 'inline-block' }}> Severe head injury :</span>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {' '}
                  {location && location.state && location.state.head_injury
                    ? location.state.head_injury
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Pain in ear :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                {location && location.state && location.state.ear_pain ? location.state.ear_pain : ''}
                </span>
              </p>
             

              <p>
                <span style={{ width: '45%', display: 'inline-block' }}>Tinnitus :</span>{' '}
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {location && location.state && location.state.tinnitus
                    ? location.state.tinnitus
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '45%', display: 'inline-block' }}>
                  {' '}
                  Sudden loss of hearing ?{' '}
                </span>{' '}
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {location && location.state && location.state.sudden_hearing_loss
                    ? location.state.sudden_hearing_loss
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '45%', display: 'inline-block' }}> Vertigo :</span>{' '}
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {location && location.state && location.state.vertigo
                    ? location.state.vertigo
                    : ''}
                </span>
              </p>
            </div>

            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5'>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {' '}
                  Feeling of Fullness :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.feeling_of_fullness
                    ? location.state.feeling_of_fullness
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {' '}
                  Excess wax in ear canal :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.ear_wax
                    ? location.state.ear_wax
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>
                  {' '}
                  Previous ear surgery :
                </span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.ear_surgery
                    ? location.state.ear_surgery
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}>Drainage from ears :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.drainage_ears
                    ? location.state.drainage_ears
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Allergies :</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.allergies
                    ? location.state.allergies
                    : ''}
                </span>
              </p>
              <p>
                <span style={{ width: '40%', display: 'inline-block' }}> Hearing Aids ?</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {location && location.state && location.state.hearing_aids
                    ? location.state.hearing_aids
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <Divider />
          <h5>Diseases that may have affected hearing :</h5>
          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}> High Fever</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  <Checkbox
                    name='High Fever'
                    defaultChecked={location && location.state && location.state.high_fever}
                    disabled
                  />
                  {/* {' '}
                  {location &&
                    location.state &&
                    location.state.first_name + ' ' + location.state.last_name} */}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Measels</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {/* {location && location.state && location.state.ein ? location.state.ein : ''} */}
                  <Checkbox
                    name='Measels'
                    defaultChecked={location && location.state && location.state.measles}
                    disabled
                  />
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}> Mumps</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  <Checkbox
                    name='Mumps'
                    defaultChecked={location && location.state && location.state.mumps}
                    disabled
                  />
                  {/* {location && location.state && location.state.ph_num ? location.state.ph_num : ''} */}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}> Diabetes</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  <Checkbox
                    name='Diabetes'
                    defaultChecked={location && location.state && location.state.diabetes}
                    disabled
                  />

                  {/* {location && location.state && location.state.ph_num ? location.state.ph_num : ''} */}
                </span>
              </p>
            </div>

            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5'>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Scarlet Fever </span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {/* {location && location.state && location.state.facility
                    ? location.state.facility
                    : ''} */}
                  <Checkbox
                    name='Scarlet Fever'
                    defaultChecked={location && location.state && location.state.scarlet_fever}
                    disabled
                  />
                </span>
              </p>

              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Otosclerosis</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  <Checkbox
                    name='Otosclerosis'
                    defaultChecked={location && location.state && location.state.otosclerosis}
                    disabled
                  />

                  {/* {location && location.state && location.state.facility
                    ? location.state.facility
                    : ''} */}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Meniere Disease</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  <Checkbox
                    name='Meniere Disease'
                    defaultChecked={location && location.state && location.state.meniere_disease}
                    disabled
                  />

                  {/* {location && location.state && location.state.facility
                    ? location.state.facility
                    : ''} */}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Ear Infection</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  <Checkbox
                    name='Ear Infection'
                    defaultChecked={location && location.state && location.state.ear_infection}
                    disabled
                  />

                  {/* {location && location.state && location.state.facility
                    ? location.state.facility
                    : ''} */}
                </span>
              </p>
            </div>
          </div>
          <Divider />
          <h5>Medications :</h5>
          <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Mycin Drug</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  <Checkbox
                    name='Mycin Drug'
                    defaultChecked={location && location.state && location.state.mycin_drug}
                    disabled
                  />
                  {/* {' '}
                  {location &&
                    location.state &&
                    location.state.first_name + ' ' + location.state.last_name} */}
                </span>
              </p>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Cisplatin</span>{' '}
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {/* {location && location.state && location.state.ein ? location.state.ein : ''} */}
                  <Checkbox
                    name='Cisplatin'
                    defaultChecked={location && location.state && location.state.cisplatin}
                    disabled
                  />
                </span>
              </p>
            </div>

            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-5 col-sm-5'>
              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Methotrexate</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  {/* {location && location.state && location.state.facility
                    ? location.state.facility
                    : ''} */}
                  <Checkbox
                    name='Methotrexate'
                    defaultChecked={location && location.state && location.state.methotrexate}
                    disabled
                  />
                </span>
              </p>

              <p>
                <span style={{ width: '30%', display: 'inline-block' }}>Excess Aspirin</span>
                <span style={{ width: '30%', display: 'inline-block' }}>
                  <Checkbox
                    name='Excess Aspirin'
                    defaultChecked={location && location.state && location.state.excess_aspirin}
                    disabled
                  />

                  {/* {location && location.state && location.state.facility
                    ? location.state.facility
                    : ''} */}
                </span>
              </p>
            </div>
          </div>
          {/* <div className='row'>
            <div className='col-md-1 col-sm-1'></div>
            <div className='col-md-4 col-sm-1'> Audiogram Review</div>
 
            <div className='col-md-6 col-sm-10'>
                  <div>
      {splitText.map((text, index) => (
        <div key={index}>{text}</div>
      ))}
    </div>

            </div>

            <div className='col-md-1 col-sm-1'></div>
          </div> */}
        </Form>
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AudiogramDetails;
