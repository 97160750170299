import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Button, Modal } from 'antd';
import axios from 'axios'
import moment from 'moment';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import setting from '../../../config/setting';
import styled, { css } from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

let columnPicker = [];
let flexes = '';
let showGrid = '';
let dragOver = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'TestDetail_PFT.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const PftTestEmp = ({
  data,
  docOccHealthPermission,
  downloadClearance,
  downloadAttachmentMultiple
}) => {
  const [view, setView] = useState();
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewData, setViewData] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [imageUrls, setImageUrls] = useState([]);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [occFitData, setOccFitData] = useState([]);
  const [occPftData, setOccPftData] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const theGrid = useRef();
  const theSearch = useRef();
  const filterRefCounter = useRef(null);
  const fixedContainerRef = useRef(null);
  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };

  useEffect(() => {
    const ress = data.map((k) => {
        return {
          ...k,
          //fullName: k.first_name + ' ' + k.last_name,
          testing_status: k.testing_status != false ? k.testing_status :"",
          newLocation:
            k.child_ids.length > 0 && k.child_ids[1].includes('|')
              ? k.child_ids[1].split('|')[1]
              : k.child_ids[1].split(',')[1],
          newCollected: k.testing_date != false ? k.testing_date && moment(k.testing_date).format('MM/DD/YYYY hh:mm'):"",
          newCcf: k.ccf == false ? ' ' : k.ccf,
          fitTestPdf: k.fitTestPdf != false ? k.fitTestPdf : '',
          fitTestCard: k.fitTestCard != false ? k.fitTestCard : '',
          fitTestType: k.fit_Test_Type != false ? k.fit_Test_Type : '',
          fitManufacturer: k.fit_Manufacturer != false ? k.fit_Manufacturer : '',
          finalResult: k.final_result != false ? k.final_result : '',
          program: k.emp_data[0].program != false ? k.emp_data[0].program : '',
          mode: k.emp_data[0].mode != false ? k.emp_data[0].mode : '',
          attachment: k.attachments != false && k.attachments.length > 0 ? k.attachments : ''
        };
      });

    setOccPftData(ress);
    
  }, []);
  useEffect(() => {
    if (occPftData.length > 0) {
        const collectionView1 = new CollectionView(occPftData, {
            pageSize: occPftData.length
          });
          setViewData(collectionView1);
          const collectionView2 = new CollectionView(occPftData, {
            pageSize: pageSize,
            collectionChanged: (s) => {
              filterRefCounter.current = s.totalItemCount;
              var filterCounter = document.getElementById('filterCounter');
              if (filterCounter) {
                filterCounter.textContent = s.totalItemCount;
              }
            }
          });
          setView(collectionView2);
       
    }
  }, [occPftData, pageSize]);
  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  // const showDocuments = (item) => {
  //   return (
  //     <p>
  //       {' '}
  //       {docOccHealthPermission && item.item.event_id && item.item.emp_selected ? (
  //         <button onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)}>
  //           View Doc
  //         </button>
  //       ) : (
  //         item.item.event_id &&
  //         item.item.emp_selected && <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
  //       )}
  //     </p>
  //   );
  // };

  const showResult = (item) => {
    return <p>{item.item.final_result ? item.item.final_result : ''}</p>;
  };

  // const showDoc = (item) => {
  //   return (
  //     <p>
  //       {docOccHealthPermission && item.item.urls && item.item.urls.length > 0
  //         ? item.item.urls.map((url) => {
  //             return (
  //               <>
  //                 &nbsp;
  //                 <button style={{ margin: 3 }} onClick={() => downloadAttachmentMultiple(url)}>
  //                   View Doc
  //                 </button>
  //               </>
  //             );
  //           })
  //         : item.item.urls &&
  //           item.item.urls.length > 0 && (
  //             <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
  //           )}
  //     </p>
  //   );
  // };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showAttachment = (item) => {
    return (
      <span>
        {item && item.item && item.item.attachment && item.item.attachment.length > 0 ? (
          <button
            style={{ margin: "-2px 0px" }}
            onClick={() => downloadAttachmentMultiple(item.item.attachment[0])}
          >
            View Doc
          </button>
        ) : (
          <></>
        )}
      </span>
    );
  };
  const showNewLocation = (item) => {
    return item.item.newLocation;
  };
  function download(e, attachment) {
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: 'https://wss-files.s3.amazonaws.com/' + attachment
        }
      })
      .then((response) => {
        console.log('Respone from getData testEventReports', response.data);
        if (response && response.data && response.data.length > 0) {
          setIsLoaderActive(false);
          setIsModalVisible(true);
          setImageUrls(response.data);
        }

        // setChange(!change)
        // window.open(response.data);
      });
  }
 
  // const showFitTestPdf = (record) => {
  //   return (
  //     <span className='nowrap'>
  //       {record.item && record.item.fitTestPdf ? (
  //         <button
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             download(e, record.item.fitTestPdf);
  //           }}
  //         >
  //           View PDF
  //         </button>
  //       ) : (
  //         ''
  //       )}
  //     </span>
  //   );
  //   return (
  //     <span
  //       className='nowrap'
  //       style={{ display: 'flex', flexDirection: 'column', width: '70%', textAlign: 'center' }}
  //     >
  //       <div>
  //         {record.item && record.item.fitTestPdf ? (
  //           <button onClick={(e) => download(e, record.item.fitTestPdf)}>View PDF</button>
  //         ) : (
  //           ''
  //         )}
  //       </div>
  //     </span>
  //   );
  // };

  // const showFitTestCard = (record) => {
  //   return (
  //     <span
  //       className='nowrap'
  //       style={{ display: 'flex', flexDirection: 'column', width: '70%', textAlign: 'center' }}
  //     >
  //       <div>
  //         {record.item && record.item.fitTestCard ? (
  //           <button onClick={(e) => download(e, record.item.fitTestCard)}>View Card</button>
  //         ) : (
  //           ''
  //         )}
  //       </div>
  //     </span>
  //   );
  // };

  return (
    <div>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={10}>
          <FlexGridSearch
            ref={theSearch}
            placeholder='Search'
            cssMatch=''
            style={{ width: '70%', height: '40px' }}
          />
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
          <div ref={fixedContainerRef}>
            <Select
              style={{ width: 170 }}
              placeholder={`Items per page : ${pageSize}`}
              onChange={paggination}
              options={[
                { label: 'Items per page : 10', value: 10 },
                { label: 'Items per page : 20', value: 20 },
                { label: 'Items per page : 50', value: 50 }
              ]}
              getPopupContainer={() => fixedContainerRef.current}
            />
          </div>
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={10}>
          <Button
            type='primary'
            size='small'
            style={{ backgroundColor: '#3d586a', float: 'right' }}
            disabled={isExcelPreparing}
            onClick={exportToExcel}
          >
            {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
          </Button>
        </Col>
      </Row>

      {/* to export entire data of table */}
      <FlexGrid
        ref={theGrid}
        style={{ display: 'none' }}
        className='dummyWijmoData'
        itemsSource={viewData}
        initialized={gridInitializedData}
        formatItem={formatItem}
        isReadOnly={true}
      >
       <FlexGridColumn binding='newLocation' header='Location' minWidth={250} width='*'>
                    <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='newCollected'
                    header='Test Date/Time'
                    minWidth={200}
                    width='*'
                  />
           
                  <FlexGridColumn binding='reasons' header='Reason for Test' minWidth={200} />
                  <FlexGridColumn binding='testing_status' header='Status'>
                  </FlexGridColumn>
                  
                  <FlexGridColumn binding='finalResult' header='Result'>
                    <FlexGridCellTemplate cellType='Cell' template={showResult} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='program'
                    header='DOT Mode'
                    // width='10*'
                    minWidth={160}
                    width='*'
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='attachment' header='Documents'>
                    <FlexGridCellTemplate cellType='Cell' template={showAttachment} />
                  </FlexGridColumn>
                  </FlexGrid>

      <FlexGrid
                  ref={theGrid}
                  //ref={gridRef}
                  id='theGridTallRows'
                  className="cliniclisttable"
                  alternatingRowStep={1}
                  // autoRowHeights={true}
                  itemsSource={view}
                  initialized={gridInitialized}
                  formatItem={formatItem}
                  itemsPerPage={10}
                  isReadOnly={true}
                  //onFilterApplied={onFilterApplied}
                  //autoGenerateColumns={false}
                >
                  <div style={{ display: 'none' }}>
                    <wjInput.ListBox
                      className='column-picker'
                      itemsSource={listboxColumns}
                      checkedMemberPath='visible'
                      displayMemberPath='header'
                      lostFocus={hidePicker}
                      initialized={initializedListBox}
                    ></wjInput.ListBox>
                  </div>
                  <FlexGridColumn binding='newLocation' header='Location' width={450}>
                    <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='newCollected'
                    header='Test Date/Time'
                    width={150}
                  />
           
                  <FlexGridColumn binding='reasons' header='Reason for Test' width={140} />
                  <FlexGridColumn binding='testing_status' header='Status' width={95}>
                  </FlexGridColumn>
                  
                  <FlexGridColumn binding='finalResult' header='Result' width={95}>
                    <FlexGridCellTemplate cellType='Cell' template={showResult} />
                  </FlexGridColumn>
                  <FlexGridColumn
                    binding='program'
                    header='DOT Mode'
                    // width='10*'
                    // minWidth={160}
                    // width='*'
                    width={105}
                  >
                    <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
                  </FlexGridColumn>
                  <FlexGridColumn binding='attachment' header='Documents' width={143}>
                    <FlexGridCellTemplate cellType='Cell' template={showAttachment} />
                  </FlexGridColumn>

                  <FlexGridFilter
                    // showFilterIcons={true}
                    // placeholder='Filter data...'
                    // defaultFilterType='Value'
                    filterColumns={[
                      'fullName',
                      'newLocation',
                      'newCollected',
                      'newEmpEIN',
                      'newEmpSSN',
                      'newDL',
                      'reasons',
                      'fitTestType',
                      'fitManufacturer',
                      "testing_status",
                      'finalResult',
                      'jobTitle',
                      'departments',
                      'program'
                    ]}
                  />
                  {/* <FlexGridFilter
                    column={{
                      binding: 'collected',
                      header: 'Collection Date',
                      filter: true,
                      width: '*'
                    }}
                    formatItem={(s, e) => {
                      if (e.data) {
                        e.cell.textContent = handleFormatDate(e.data.collected);
                      }
                    }}
                  /> */}
                </FlexGrid>

                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div
          style={{
            backgroundColor: '#425a81',
            color: '#fff',
            display: 'inline',
            borderRadius: '5px',
            height: '30px'
          }}
        >
          <CollectionViewNavigator
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
            headerFormat='Page {currentPage:n0} of {pageCount:n0}'
            byPage={true}
            cv={view}
          />
        </div>
        &nbsp;&nbsp;&nbsp;
        <p
          style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}
          ref={filterRefCounter}
          id='filterCounter'
        >
          {Globalize.format(filterRefCounter.current, 'n0')}
        </p>
        &nbsp;
        <p style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}>
          {' '}
          out of {occPftData.length}
        </p>
        <Modal
        width={1000}
        title='Fit Test Document'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls && imageUrls.length > 0 && (
          <p>
            <embed src={imageUrls} width='800px' height='900px' />
          </p>
        )}
      </Modal>
      <DarkBackground disappear={isLoaderActive}>
        <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
      </DarkBackground>

      </div>
    </div>
  );
};

export default PftTestEmp;
