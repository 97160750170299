import React, { useEffect, useState, useRef } from 'react';
import { Spin, Space, Row, Col, Select, Button } from 'antd';
import axios from 'axios';
import _ from 'underscore';
import setting from './../../config/setting';
import styled, { css } from 'styled-components';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { useHistory } from 'react-router-dom';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
      },
      'clinic_service_rate.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },

        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const ClinicServiceRateTable = (propsData) => {
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [pagging, setPagging] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const [itemsPerPage, setItemPerPage] = useState(10);
  // const [pageApply, setPageApply] = useState(false);
  const [noFound, setNoFound] = useState(false);
  const [serviceTableData, setServiceTableData] = useState([]);
  const theGrid = useRef();
  const theSearch = useRef();
  const gridRef = useRef(null);
  const fixedContainerRef = useRef(null);
  const history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const filterRefCounter = useRef();

  // const setWijmoFlexGrid = (data, page) => {
  //   let views = new wijmo.CollectionView(data, { pageSize: Number(page) });
  //   let viewsData = new wijmo.CollectionView(data, { pageSize: Number(data && data.length) });
  //   setView(views);
  //   setViewData(viewsData);
  //   setViewIndex(views.pageIndex + 1);
  //   setViewCount(views.pageCount);
  //   //setIsLoadMoreLoading(false);
  //   setTimeout(() => {
  //     setPagginationNumRecord({
  //       currentRecord: document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
  //       totalRecord: data.length
  //     });
  //   }, 2000);
  // };
  useEffect(() => {
    if (propsData != undefined) {
      getServiceRate();
    } else {
      history.goBack();
    }
  }, []);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    //console.log('the searches', (theSearchs.grid = theGrids));
  }

  const getServiceRate = () => {
    let clinicIdValue = propsData && propsData.propsData && propsData.propsData.id;
    setNoFound(true);
    axios
      .post(setting.serverUrl + '/api/clinicServiceRateCustomer', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          clinicId: clinicIdValue
        }
      })
      .then((response) => {
        console.log('clinicServiceRateCustomer: ', response.data);
        if (response && response.status == 200) {
          let result = response && response.data;
          const finalValues = result.map((k) => {
            let itemPrice;
            let tiervalue = propsData && propsData.propsData && propsData.propsData.tier;
            if (tiervalue == 'Tier 1') {
              itemPrice = k.tier1_price != null ? k.tier1_price : '';
            } else if (tiervalue == 'Tier 2') {
              itemPrice = k.tier2_price != null ? k.tier2_price : '';
            } else if (tiervalue == 'Tier 3') {
              itemPrice = k.tier3_price != null ? k.tier3_price : '';
            } else {
              itemPrice = '';
            }
            return {
              test_name: k.test_name != null ? k.test_name : '',
              remark: k.remark != null ? k.remark : '',
              price: k.price != null ? k.price : '',
              itemPrice: itemPrice
            };
          });

          const collectionView1 = new CollectionView(finalValues, {
            pageSize: finalValues.length
          });
          console.log(collectionView1, 'collectorview1');
          setViewData(collectionView1);
          const collectionView2 = new CollectionView(finalValues, {
            pageSize: pageSize,
            collectionChanged: (s) => {
              console.log(s, 'collectionChanged');
              filterRefCounter.current = s.totalItemCount;
              console.log(filterRefCounter.current,'filters')
              var filterCounter = document.getElementById('filterCounter');
              if (filterCounter) {
                filterCounter.textContent = s.totalItemCount;
              }
              console.log(filterCounter,'filterCounters')
            }
          });
          setView(collectionView2);
          setServiceTableData(finalValues);
          // setWijmoFlexGrid(finalValues, 10);
          setNoFound(false);
        }
      });
  };

  const paggination = (value) => {
    console.log('page value', value);
    setPagging(value);
    setItemPerPage(value);
    setPageSize(value);
    // setPageApply(true);
    // setWijmoFlexGrid(serviceTableData, value);
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          // console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          //console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      // console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    //console.log('grids.hostelement', showGrid);

    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  //console.log('collected type state===', typeof collectionDate);
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.style.textAlign = 'center';
      span.addEventListener('mousedown', function (e) {
        // console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  // const updateViewPager = (target) => {
  //   console.log('target', target);
  //   let btn = wijmo.closest(target, 'button'),
  //     id = btn ? btn.id : '';
  //   switch (id) {
  //     case 'btnFirst':
  //       i = 1;
  //       view.moveToFirstPage();
  //       setTimeout(() => {
  //         setPagginationNumRecord({
  //           currentRecord:
  //             document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1,
  //           totalRecord: serviceTableData.length
  //         });
  //       }, 1000);
  //       break;
  //     case 'btnPrev':
  //       if (i <= 1) {
  //         i = 1;
  //       } else {
  //         var a = Math.ceil(serviceTableData.length / itemsPerPage);
  //         view.moveToPreviousPage();
  //         if (a === i) {
  //           setPagginationNumRecord({
  //             currentRecord:
  //               serviceTableData.length -
  //               (document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1),
  //             totalRecord: serviceTableData.length
  //           });
  //         } else {
  //           setPagginationNumRecord({
  //             currentRecord: pagginationNumRecord.currentRecord - itemsPerPage,
  //             totalRecord: serviceTableData.length
  //           });
  //         }
  //         i--;
  //       }
  //       break;
  //     case 'btnNext':
  //       if (pageApply) {
  //         if (
  //           serviceTableData.length ===
  //           document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1
  //         ) {
  //         } else {
  //           i = 2;
  //         }
  //         setPageApply(false);
  //       } else {
  //         if (i >= Math.ceil(serviceTableData.length / itemsPerPage)) {
  //         } else {
  //           i++;
  //         }
  //       }
  //       var a = Math.ceil(serviceTableData.length / itemsPerPage);
  //       if (a === i) {
  //         setPagginationNumRecord({
  //           currentRecord: serviceTableData.length,
  //           totalRecord: serviceTableData.length
  //         });
  //       } else {
  //         setPagginationNumRecord({
  //           currentRecord:
  //             (document.querySelectorAll('#theGridTallRows .wj-cells .wj-row').length - 1) * i,
  //           totalRecord: serviceTableData.length
  //         });
  //       }
  //       view.moveToNextPage();
  //       break;
  //     case 'btnLast':
  //       i = Math.ceil(serviceTableData.length / itemsPerPage);
  //       view.moveToLastPage();
  //       setPagginationNumRecord({
  //         currentRecord: serviceTableData.length,
  //         totalRecord: serviceTableData.length
  //       });
  //       break;
  //   }
  //   setViewIndex(view.pageIndex + 1);
  //   setViewCount(view.pageCount);
  // };

  const flexGridRef = React.useRef(null);

  // const onFilterApplied = () => {
  //   const filter = flexGridRef.current.collectionView.filterDefinition;
  //   filter.clear(); // clear any existing filters
  //   filter.conditionFilter('emp_ein', '==', true); // filter for isActive == true
  // };

  const columns = [
    { header: 'Item Price', field: 'itemPrice', width: 100 },
    // { header: 'Clinic Price', field: 'price', width: 150 },
    { header: 'Notes', field: 'remark', width: 324 }
  ];
  const showTestName = (item) => {
    return <span className='nowrap'>{item.item.test_name != null ? item.item.test_name : ''}</span>;
  };
  return (
    <div style={{ padding: 20 }}>
      {!noFound ? (
        serviceTableData.length > 0 ? (
          <div>
            <div className='row'>
              <div className='col-12 col-md-12 col-xl-12'>
                <Row gutter={[32, 32]}>
                  <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                    <FlexGridSearch
                      ref={theSearch}
                      placeholder='Search'
                      cssMatch=''
                      style={{ width: '70%', height: '40px' }}
                    />
                  </Col>

                  <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                    <div ref={fixedContainerRef}>
                      <Select
                        style={{ width: 160 }}
                        placeholder={`Items per page: ${pagging}`}
                        onChange={paggination}
                        options={[
                          { label: 10, value: 10 },
                          { label: 20, value: 20 },
                          { label: 50, value: 50 }
                        ]}
                        getPopupContainer={() => fixedContainerRef.current}
                      />
                    </div>
                  </Col>

                  <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                    <Button
                      type='primary'
                      size='small'
                      style={{ backgroundColor: '#3d586a', float: 'right' }}
                      disabled={isExcelPreparing}
                      onClick={exportToExcel}
                    >
                      {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                    </Button>
                  </Col>
                </Row>

                {/* to export entire data of table */}
                <FlexGrid
                  ref={theGrid}
                  style={{ display: 'none' }}
                  className='dummyWijmoData'
                  itemsSource={viewData}
                  initialized={gridInitializedData}
                  formatItem={formatItem}
                  isReadOnly={true}
                >
                  <FlexGridColumn binding='test_name' header='Items' width='*' minWidth={720}>
                    <FlexGridCellTemplate cellType='Cell' template={showTestName} />
                  </FlexGridColumn>

                  {columns.map((column) => (
                    <FlexGridColumn
                      header={column.header}
                      binding={column.field}
                      width={column.width}
                    />
                  ))}
                </FlexGrid>

                <FlexGrid
                  ref={theGrid}
                  id='theGridTallRows'
                  className='cliniclisttable'
                  alternatingRowStep={0}
                  itemsSource={view}
                  initialized={gridInitialized}
                  formatItem={formatItem}
                  itemsPerPage={10}
                  isReadOnly={true}
                  // onFilterApplied={onFilterApplied}
                >
                  <div style={{ display: 'none' }}>
                    <wjInput.ListBox
                      className='column-picker'
                      itemsSource={listboxColumns}
                      checkedMemberPath='visible'
                      displayMemberPath='header'
                      lostFocus={hidePicker}
                      initialized={initializedListBox}
                    ></wjInput.ListBox>
                  </div>
                  <FlexGridColumn binding='test_name' header='Items' width='*' minWidth={720}>
                    <FlexGridCellTemplate cellType='Cell' template={showTestName} />
                  </FlexGridColumn>

                  {columns.map((column) => (
                    <FlexGridColumn
                      header={column.header}
                      binding={column.field}
                      width={column.width}
                    />
                  ))}
                  <FlexGridFilter filterColumns={['test_name', 'itemPrice', 'price', 'remark']} />
                </FlexGrid>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
                {/* <div
                    onClick={(e) => updateViewPager(e.target)}
                    style={{
                      backgroundColor: '#425a81',
                      color: '#fff',
                      display: 'inline',
                      borderRadius: '5px',
                      height: '30px'
                    }}
                  >
                    <button
                      id='btnFirst'
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                      className='btn'
                    >
                      <span className='wj-glyph-step-backward'></span>
                    </button>
                    <button
                      id='btnPrev'
                      className='btn'
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    >
                      <span className='wj-glyph-left'></span>
                    </button>
                    <p style={{ display: 'inline', fontSize: 12, fontWeight: 'bold' }}>
                      &nbsp;&nbsp;&nbsp;Page {viewIndex} of {viewCount}&nbsp;&nbsp;&nbsp;
                    </p>
                    <button
                      id='btnNext'
                      className='btn'
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    >
                      <span className='wj-glyph-right'></span>
                    </button>
                    <button
                      id='btnLast'
                      className='btn'
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    >
                      <span className='wj-glyph-step-forward'></span>
                    </button>
                  </div>
                  {pagginationNumRecord.currentRecord !== undefined ? (
                    <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                      {' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`${pagginationNumRecord.currentRecord} out of ${pagginationNumRecord.totalRecord}`}
                    </p>
                  ) : (
                    ''
                  )} */}
                {/* <div
                    style={{
                      backgroundColor: '#425a81',
                      color: '#fff',
                      display: 'inline',
                      borderRadius: '5px',
                      height: '30px'
                    }}
                  >
                    <CollectionViewNavigator
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                      headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                      byPage={true}
                      cv={view}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <p
                    style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                    ref={filterRefCounter}
                    id='filterCounter'
                  >
                    {Globalize.format(filterRefCounter.current, 'n0')}
                  </p>
                  &nbsp;
                  <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                    {' '}
                    out of {serviceTableData.length}
                  </p>
                </div> */}
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <div
                    style={{
                      backgroundColor: '#425a81',
                      color: '#fff',
                      display: 'inline',
                      borderRadius: '5px',
                      height: '30px'
                    }}
                  >
                    <CollectionViewNavigator
                      style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                      headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                      byPage={true}
                      cv={view}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <p
                    style={{ fontWeight: 'bold', verticalAlign: 'baseline',fontSize:15 }}
                    ref={filterRefCounter}
                    id='filterCounter'
                  >
                    {Globalize.format(filterRefCounter.current, 'n0')}
                  </p>
                  &nbsp;
                  <p style={{ fontWeight: 'bold', verticalAlign: 'baseline',fontSize:15 }}>
                    {' '}
                    out of {serviceTableData.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>No Record Found !</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ClinicServiceRateTable;
