import React, { useEffect, useState, useRef } from 'react';
import { Spin, Space, Button, Row, Select, Col, DatePicker, notification, Card } from 'antd';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGridDetail } from '@grapecity/wijmo.react.grid.detail';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FilePdfTwoTone } from '@ant-design/icons';
import { date } from 'yup';

let tt = new wijmo.Tooltip();
const { RangePicker } = DatePicker;

const config = require('../keyFront');

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: _formatExcelItem
      },
      'Medical_Surveillance.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => _formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const Medical_surveillance = () => {
  const [medicalSurvellianceData, setMedicalSurvellianceData] = useState([]);
  const [found, setNofound] = useState(false);
  const [change, setChange] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [EventMed, setEventMed] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clearanceUrl, setclearanceUrl] = useState('');
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [docPermission, setDocPermission] = useState(false);
  const [completedCount, setCompletedCount] = useState(0);
  const [scheduledCount, setScheduledCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [view, setView] = useState([]);
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState(false);
  const [isExcelPreparing, setIsExcelPreparing] = useState(false);
  const [excelProgress, setExcelProgress] = useState(0);
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [tableType, setTableType] = useState('all');
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();

  const filterTable = (type) => {
    setTableType(type);
  };
  /* Filter to set date range */
  const setDateRangeFilter = (dates) => {
    if (dates !== null) {
      setDateRange(dates.map((date) => date.format('MM-DD-YYYY')));
    } else {
      setDateRange([]);
    }
  };

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const getEmployeemedicalSurvelliance = () => {
    setIsLoading(true);
    //setNofound(true);
    axios
      .post(setting.serverUrl + '/api/medicalSurvelliance', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          // company: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then(async (response) => {
        setIsLoading(false);
        console.log(response, 'responseMedicalAPI');
        if (response.status == 200 && response.data && response.data.rows) {
          if (response?.data?.rows == [] || response?.data?.rows.length == 0) {
            setNofound(false);
            setHasPermission(true);
            setChange(true);
          } else {
            console.log('resposne.data new api===========', response.data.rows);
            let dataGroupById = _.groupBy(response.data.rows,  item => item.test_event_id+'-'+item.emp_selected);
           
            let records = await Promise.all(Object.keys(dataGroupById).map(async (element) => {
              
        
              const object = dataGroupById[element];
              const firstRecord = object[0];
              
              let test_name_arr = await Promise.all(object.map((childelement) => {
                
                return {
                  test_name: childelement.test_name,
                  test_name_moved0: childelement.test_name_moved0,
                  test_status: childelement.test_status,
                  //attachments : element.attachments,
                  urls: childelement.urls,
                  completed_on: childelement.completed_on,
                  testing_date: childelement.testing_date,
                  result: childelement.result,
                  final_result: childelement.final_result,
                  eventNoteEx: childelement.event_note
                };
              }))

              return {
                id: firstRecord.test_event_id+firstRecord.emp_selected,
                event_id: firstRecord.event_id,
                full_name: firstRecord.first_name + ' ' + firstRecord.last_name,
                package_name: firstRecord.package_name,
                event_status: firstRecord.event_status,
                create_date: firstRecord.create_date,
                // attachments: firstRecord.attachments,
                test_status: firstRecord.test_status,
                reasons: firstRecord.reasons,
                urls: firstRecord.urls,
                result: firstRecord.result,
                final_result: firstRecord.final_result,
                completed_on: firstRecord.completed_on,
                testing_date: firstRecord.testing_date,
                emp_selected: firstRecord.emp_selected,
                scheduled_date: firstRecord.scheduled_date,
                test_name_moved0: firstRecord.test_name_moved0,
                jobTitle: firstRecord.hospital_employee_job_title,
                emp_location: firstRecord.hospital_employee_location,
                departments: firstRecord.hospital_employee_department,
                emp_ssn:
                  firstRecord.hospital_employee_emp_ssn &&
                  firstRecord.hospital_employee_emp_ssn != false &&
                  firstRecord.hospital_employee_emp_ssn != '' &&
                  firstRecord.hospital_employee_emp_ssn != null
                    ? '***-**-' + firstRecord.hospital_employee_emp_ssn?.substr(-4)
                    : '',
                emp_ein: firstRecord.hospital_employee_emp_ein,
                DL: firstRecord.hospital_employee_DL,
                program: firstRecord.hospital_employee_program,
                mode: firstRecord.hospital_employee_mode,
                eventNote: firstRecord.event_notes,
                loc_name:
                  firstRecord && firstRecord.loc_name && firstRecord.loc_name.includes('|')
                    ? firstRecord.loc_name.split('|')[1].trim()
                    : firstRecord.loc_name,
                test_name: test_name_arr
              };
           
            }));
            records.sort(function (a, b) {
              return new Date(b.create_date) - new Date(a.create_date);
            });
            console.log('final occ response======', records);
            const ress = records.map((val) => {
              return {
                ...val,
                newScheduleDate: val.scheduled_date ? val.scheduled_date :'',
                newScheduleDateforExcel: val.scheduled_date ? moment(val.scheduled_date).format('MM/DD/YYYY'):'',
              };
            });
           
            ress.sort((a,b)=>{
              const dateA = new Date(a.newScheduleDate);
              const dateB = new Date(b.newScheduleDate);
              return dateB - dateA;
            })
            ress.sort((a,b)=>{
              const dateA = new Date(a.testing_date);
              const dateB = new Date(b.testing_date);
              return dateB - dateA;
            })
            const collectionView1 = new CollectionView(ress, {
              pageSize: ress.length
            });
            setViewData(collectionView1);
            const collectionView2 = new CollectionView(ress, {
              pageSize: pageSize,
              collectionChanged: (s) => {
                filterRefCounter.current = s.totalItemCount;
                var filterCounter = document.getElementById('filterCounter');
                if (filterCounter) {
                  filterCounter.textContent = s.totalItemCount;
                }
              }
            });

            collectionView2.filters.push((item) => {
              // setTableType('all')
              const startDate =
                dateRange && dateRange.length > 0 && moment(dateRange[0]).startOf('day');
              const endDate =
                dateRange && dateRange.length > 0 && moment(dateRange[1]).endOf('day');
              const itemTestDate =
                item.scheduled_date != false && moment(item.scheduled_date).format('MM-DD-YYYY');
              return (
                (!startDate || moment(itemTestDate, 'MM-DD-YYYY').isSameOrAfter(startDate)) &&
                (!endDate || moment(itemTestDate, 'MM-DD-YYYY').isSameOrBefore(endDate))
              );
            });
            if (tableType === 'Completed') {
              collectionView2.filters.push((item) => {
                return item.event_status == 'Completed';
              });
            } else if (tableType === 'Scheduled') {
              collectionView2.filters.push((item) => {
                return item.event_status == 'Scheduled';
              });
            } else if (tableType === 'Archived') {
              collectionView2.filters.push((item) => {
                return item.event_status == 'Archived';
              });
            } else if (tableType === 'all') {
              collectionView2.filters.push((item) => {
                return item;
              });
            }
            
            setView(collectionView2);
            setMedicalSurvellianceData(ress);
            let CompletedStatus = records.filter((val) => val.event_status == 'Completed');
            setCompletedCount(CompletedStatus.length);
            let SchedStatus = records.filter((val) => val.event_status == 'Scheduled');
            setScheduledCount(SchedStatus.length);
            let ArchStatus = records.filter((val) => val.event_status == 'Archived');
            setArchivedCount(ArchStatus.length);
            setEventMed(records);
            setNofound(false);
            setHasPermission(true);
            setChange(true);
          }
          //setErrMsg('Module not activated.');
        } else if (response.status === 201) {
          setNofound(true);
          setErrMsg('Access permission not allowed.');
          setNoRecordMessage('Access permission not allowed.');
          // notification.error({
          //   message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          // });
        } else if (response.status === 202) {
          //setWijmoFlexGrid([], 10);
          setHasPermission(true);
          setNofound(false);
          setNoRecordMessage('No Record Found');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };
  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.OccHealthDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };
  function downloadAttachmentMultiple(attachment) {
    if (attachment && attachment.length > 0) {
      axios
        .post(setting.serverUrl + '/api/downloadAttachmentOccHealth', {
          params: {
            email: localStorage.getItem('myData'),
            user: localStorage.getItem('myData'),
            pass: localStorage.getItem('myPass'),
            attachment_detail: attachment
          }
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            // setChange(!change);
            window.open(response.data);
            // setAttachmentAlertMessage(false);
          } else if (response.status === 201) {
            //setNofound(true);
            alert('Permission not allowed!');
          }
        });
    } else {
      alert('No Record Found');
      // show alert No Attachment Found
    }
  }
  const downloadClearance = (e, event_id, emp_selected) => {
    axios
      .post(setting.serverUrl + '/api/downloadClearanceForm', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          event_id: event_id,
          employee_id: emp_selected
        }
      })
      .then((response) => {
        // setChangeCF(!changeCF);
        if (response.data.url == 'not found') {
          setclearanceUrl('');
          // alert('No Record Found');
          notification.error({
            message: 'No Record Found'
          });
        } else {
          setclearanceUrl(response.data.url);
          window.open(response.data.url);
        }

        // window.open(response.data.rows[0].url);
      });
  };
  useEffect(() => {
    getEmployeemedicalSurvelliance();
    getDocPermission();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, [tableType, dateRange, pageSize]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }
  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.style.cursor = 'pointer';
      span.addEventListener('mousedown', function (e) {
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    if (e.panel == sender.cells) {
      // console.log('kdsfjds', sender, e.row);
      // Specify the cell you want to add a tooltip to
      if (e.col === 9) {
        if (sender.rows[e.row]._data.eventNote && sender.rows[e.row]._data.eventNote.length > 40) {
          tt.setTooltip(e.cell, sender.rows[e.row]._data.eventNote);
        }
      }
    }
  };
  const formatExItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.style.cursor = 'pointer';
      span.addEventListener('mousedown', function (e) {
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    if (e.panel == sender.cells) {
      // console.log('kdsfjds', sender, e.row);
      // Specify the cell you want to add a tooltip to
      if (e.col === 5) {
        if (
          sender.rows[e.row]._data.eventNoteEx &&
          sender.rows[e.row]._data.eventNoteEx.length > 40
        ) {
          tt.setTooltip(e.cell, sender.rows[e.row]._data.eventNoteEx);
        }
      }
      // sender.setRowHeight(e.row, 150);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showPackageName = (item) => {
    return <p>{item.item.package_name ? item.item.package_name : ''}</p>;
  };

  const showReasons = (item) => {
    return <p>{item.item.reasons ? item.item.reasons : ''}</p>;
  };

  const showStatus = (item) => {
    return <p>{item.item.event_status ? item.item.event_status : ''}</p>;
  };
  const showJobTitle = (item) => {
    return <p>{item.item.jobTitle ? item.item.jobTitle : ''}</p>;
  };

  const showScheduleDate = (item) => {
    return (
      <p>{item.item.scheduled_date ? moment(item.item.scheduled_date).format('MM/DD/YYYY') : ''}</p>
    );
  };

  const showDoc = (item) => {
    console.log('item.item.item', docPermission);
    return (
      <p>
        {docPermission && item.item.event_id && item.item.emp_selected ? (
          // <button  onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)} style={{margin:"-4px 0px"}}>
          //   View Doc
          // </button>
          <FilePdfTwoTone
            onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)}
            style={{ fontSize: '15px', width: '60px', cursor: 'pointer' }}
            twoToneColor='#FF0000'
          />
        ) : (
          item.item.event_id &&
          item.item.emp_selected && (
            <CheckSquareTwoTone style={{ color: 'green', fontSize: 15, width: '60px' }} />
          )
        )}
      </p>
    );
  };

  const getSubItemSource = (item) => {
    console.log('getSubItemSource', item.item);
    const res = medicalSurvellianceData.filter((value) => {
      if (value.id === item.item.id) {
        return value.test_name;
      }
    });
    console.log('expend res', res[0].test_name);
    return res[0].test_name;
  };

  const showCompledOn = (item) => {
    console.log(item, 'medicalleave');
    return (
      <p>{item.item.testing_date ? moment(item.item.testing_date).format('MM/DD/YYYY') : ''}</p>
    );
  };

  const showSSN = (item) => {
    if (item.item.emp_ssn !== false && item.item.emp_ssn !== null) {
      return <>{item.item.emp_ssn}</>;
    } else {
      return <>{''}</>;
    }
  };
  const showEIN = (item) => {
    return <>{item.item.emp_ein != false ? item.item.emp_ein : ''}</>;
  };
  const showDL = (item) => {
    return <>{item.item.DL != false ? item.item.DL : ''}</>;
  };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showExDoc = (item) => {
    return (
      <p>
        {docPermission && item.item.urls && item.item.urls.length > 0
          ? item.item.urls.map((url) => {
              if (url != null) {
                return (
                  <>
                    {/* <button style={{ margin: '-4px 0px' }} onClick={() => downloadAttachmentMultiple(url)}>
                      View Doc
                    </button> */}
                    <FilePdfTwoTone
                      onClick={() => downloadAttachmentMultiple(url)}
                      style={{ fontSize: '15px', width: '60px', cursor: 'pointer' }}
                      twoToneColor='#FF0000'
                    />
                  </>
                );
              } else {
                return <div></div>;
              }
            })
          : item.item.urls && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />}
      </p>
    );
  };

  const showMessage = (item) => {
    const style = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    };
    return (
      <p style={style}>
        {' '}
        {item.item.eventNote != null && item.item.eventNote.length > 40
          ? item.item.eventNote.substring(0, 37) + '...'
          : item.item.eventNote}
      </p>
    );
  };
  const showTdMessage = (item) => {
    const style = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    };
    return (
      <p style={style}>
        {' '}
        {item.item.eventNoteEx != null && item.item.eventNoteEx.length > 40
          ? item.item.eventNoteEx.substring(0, 37) + '...'
          : item.item.eventNoteEx}
      </p>
    );
  };

  return hasPermission ? (
    <div style={{ padding: 20 }}>
      <Row>
        <Col span={6}>
          {' '}
          <h4 style={{ color: '#1C3A6A' }}>Health Surveillance</h4>
        </Col>
      </Row>

      {!found ? (
        medicalSurvellianceData.length > 0 ? (
          <>
            <div className='row'>
              {medicalSurvellianceData.length != 0 && medicalSurvellianceData.length != undefined && (
                <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                  <Card
                    style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                    className='animated with-shadow'
                    onClick={() => filterTable('all')}
                  >
                    <div className='row'>
                      <div className='col-12' style={{ textAlign: 'center' }}>
                        <div
                          className='count'
                          style={{
                            fontSize: 40,
                            color: '#EF6827',
                            lineHeight: 1.4,
                            fontWeight: 'bold'
                          }}
                        >
                          {medicalSurvellianceData.length}
                        </div>
                        <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                          Total Employees
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              {completedCount != 0 && completedCount != undefined && (
                <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                  <Card
                    style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                    className='animated with-shadow'
                    onClick={() => filterTable('Completed')}
                  >
                    <div className='row'>
                      <div className='col-12' style={{ textAlign: 'center' }}>
                        <div
                          className='count'
                          style={{
                            fontSize: 40,
                            color: '#EF6827',
                            lineHeight: 1.4,
                            fontWeight: 'bold'
                          }}
                        >
                          {completedCount}
                        </div>
                        <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                          Employees with Completed Status
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              {scheduledCount != 0 && scheduledCount != undefined && (
                <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                  <Card
                    style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                    className='animated with-shadow'
                    onClick={() => filterTable('Scheduled')}
                  >
                    <div className='row'>
                      <div className='col-12' style={{ textAlign: 'center' }}>
                        <div
                          className='count'
                          style={{
                            fontSize: 40,
                            color: '#EF6827',
                            lineHeight: 1.4,
                            fontWeight: 'bold'
                          }}
                        >
                          {scheduledCount}
                        </div>
                        <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                          Employees with Scheduled Status
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              {archivedCount != 0 && archivedCount != undefined && (
                <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                  <Card
                    style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                    className='animated with-shadow'
                    onClick={() => filterTable('Archived')}
                  >
                    <div className='row'>
                      <div className='col-12' style={{ textAlign: 'center' }}>
                        <div
                          className='count'
                          style={{
                            fontSize: 40,
                            color: '#EF6827',
                            lineHeight: 1.4,
                            fontWeight: 'bold'
                          }}
                        >
                          {archivedCount}
                        </div>
                        <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                          Employees with Archived Status
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
            </div>

            <br />
            <Row>
              <Col span={8}>
                <RangePicker
                  placeholder={['Start Scheduled Date', 'End Scheduled Date']}
                  allowClear={true}
                  style={{ width: '100%', height: 40 }}
                  format='MM-DD-YYYY'
                  onChange={(dates) => {
                    setDateRangeFilter(dates);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row gutter={[32, 32]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div ref={fixedContainerRef}>
                  <Select
                    style={{ width: 170 }}
                    placeholder={`Items per page : ${pageSize}`}
                    onChange={paggination}
                    options={[
                      { label: 'Items per page : 10', value: 10 },
                      { label: 'Items per page : 20', value: 20 },
                      { label: 'Items per page : 50', value: 50 }
                    ]}
                    getPopupContainer={() => fixedContainerRef.current}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                  size='small'
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>

            {/* to export entire data of table */}
            <FlexGrid
              ref={theGrid}
              style={{ display: 'none' }}
              className='dummyWijmoData'
              itemsSource={viewData}
              initialized={gridInitializedData}
              formatItem={formatItem}
              isReadOnly={true}
            >
              <FlexGridColumn binding='full_name' header='Employee Name' minWidth={150} width='*' />
              {typecheckValue &&
                typecheckValue.length > 0 &&
                typecheckValue.map((value) => {
                  return (
                    <FlexGridColumn
                      binding={
                        value === 'SSN'
                          ? 'emp_ssn'
                          : value === 'EIN'
                          ? 'emp_ein'
                          : value === 'Driver License'
                          ? 'DL'
                          : ''
                      }
                      header={
                        value === 'SSN'
                          ? 'SSN'
                          : value === 'EIN'
                          ? 'EIN'
                          : value === 'Driver License'
                          ? 'Driver License'
                          : ''
                      }
                      // width='12*'

                      minWidth={160}
                      width='*'
                    >
                      <FlexGridCellTemplate
                        cellType='Cell'
                        template={
                          value === 'SSN'
                            ? showSSN
                            : value === 'EIN'
                            ? showEIN
                            : value === 'Driver License'
                            ? showDL
                            : ''
                        }
                      />
                    </FlexGridColumn>
                  );
                })}
              <FlexGridColumn
                binding='loc_name'
                header='Location'
                minWidth={280}
                width='*'
                wordWrap={true}
              />

              <FlexGridColumn
                binding='newScheduleDateforExcel'
                header='Scheduled Date'
                minWidth={140}
                width='*'
              >
                
              </FlexGridColumn>
              <FlexGridColumn binding='event_status' header='Status' minWidth={120} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showStatus} />
              </FlexGridColumn>
              <FlexGridColumn binding='event_id' header='Clearance' minWidth={130} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showDoc} />
              </FlexGridColumn>

              <FlexGridColumn binding='reasons' header='Reason' minWidth={130} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showReasons} />
              </FlexGridColumn>

              <FlexGridColumn binding='package_name' header='Package' minWidth={250} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showPackageName} />
              </FlexGridColumn>

              {/* <FlexGridColumn
                binding='jobTitle'
                header='Job Title'
                // width='10*'
                minWidth={250}
                width='*'
              />
              */}
              <FlexGridColumn
                binding='program'
                header='DOT Mode'
                // width='10*'
                minWidth={160}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
              </FlexGridColumn>

              <FlexGridColumn binding='eventNote' header='Notes' minWidth={200} width='*' />

              <FlexGridDetail
                template={(ctx) => (
                  <FlexGrid
                    id='theGridTestingEvent'
                    autoGenerateColumns={false}
                    headersVisibility='Column'
                    alternatingRowStep={1}
                    initialized={gridInitialized}
                    formatItem={formatItem}
                    itemsSource={getSubItemSource(ctx)}
                    itemsPerPage={10}
                    isReadOnly={true}
                    loadedRows={(s) => s.autoSizeColumns()}
                    rowEditEnded={(s) => s.autoSizeColumns()}
                    cellEditEnded={(s, e) => s.autoSizeColumn(e.col)}
                    style={{ minwidth: '100%' }}
                  >
                    <FlexGridColumn
                      binding='test_name_moved0'
                      header='Test Name'
                      minWidth={330}
                      width='*'
                    />
                    <FlexGridColumn
                      binding='final_result'
                      header='Result'
                      minWidth={150}
                      width='*'
                    />
                    <FlexGridColumn
                      binding='test_status'
                      header='Status'
                      minWidth={120}
                      width='*'
                    />
                    <FlexGridColumn
                      binding='newtestingDateforExcel'
                      header='Test Date'
                      minWidth={150}
                      width='*'
                    >
                      <FlexGridCellTemplate cellType='Cell' template={showCompledOn} />
                    </FlexGridColumn>
                    <FlexGridColumn binding='eventNoteEx' header='Notes' minWidth={150} width='*' />
                  </FlexGrid>
                )}
              />
            </FlexGrid>

            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              className='cliniclisttable'
              alternatingRowStep={1}
              // autoRowHeights={true}
              itemsSource={view}
              initialized={gridInitialized}
              formatItem={formatItem}
              itemsPerPage={10}
              isReadOnly={true}
              scrollMode='Virtual' // Add this line for smooth scrolling
            >
              <div style={{ display: 'none' }}>
                <wjInput.ListBox
                  className='column-picker'
                  itemsSource={listboxColumns}
                  checkedMemberPath='visible'
                  displayMemberPath='header'
                  lostFocus={hidePicker}
                  initialized={initializedListBox}
                ></wjInput.ListBox>
              </div>
              <FlexGridColumn
                binding='full_name'
                header='Employee Name'
                width={170}
                wordWrap={true}
              />
              {typecheckValue &&
                typecheckValue.length > 0 &&
                typecheckValue.map((value) => {
                  return (
                    <FlexGridColumn
                      binding={
                        value === 'SSN'
                          ? 'emp_ssn'
                          : value === 'EIN'
                          ? 'emp_ein'
                          : value === 'Driver License'
                          ? 'DL'
                          : ''
                      }
                      header={
                        value === 'SSN'
                          ? 'SSN'
                          : value === 'EIN'
                          ? 'EIN'
                          : value === 'Driver License'
                          ? 'Driver License'
                          : ''
                      }
                      // width='12*'

                      width={120}
                    >
                      <FlexGridCellTemplate
                        cellType='Cell'
                        template={
                          value === 'SSN'
                            ? showSSN
                            : value === 'EIN'
                            ? showEIN
                            : value === 'Driver License'
                            ? showDL
                            : ''
                        }
                      />
                    </FlexGridColumn>
                  );
                })}

              <FlexGridColumn binding='loc_name' header='Location' width={210} wordWrap={true} />
              <FlexGridColumn binding='newScheduleDate' header='Scheduled Date' width={145}>
                <FlexGridCellTemplate cellType='Cell' template={showScheduleDate} />
              </FlexGridColumn>
              <FlexGridColumn binding='event_status' header='Status' width={90}>
                <FlexGridCellTemplate cellType='Cell' template={showStatus} />
              </FlexGridColumn>
              <FlexGridColumn binding='event_id' header='Clearance' width={75}>
                <FlexGridCellTemplate cellType='Cell' template={showDoc} />
              </FlexGridColumn>

              <FlexGridColumn binding='reasons' header='Reason' width={140}>
                <FlexGridCellTemplate cellType='Cell' template={showReasons} />
              </FlexGridColumn>

              <FlexGridColumn binding='package_name' header='Package' width={320}>
                <FlexGridCellTemplate cellType='Cell' template={showPackageName} />
              </FlexGridColumn>

              {/* <FlexGridColumn
                binding='jobTitle'
                header='Job Title'
                // width='10*'
                minWidth={180}
                wordWrap={true}
                width='*'
              >
             <FlexGridCellTemplate cellType='Cell' template={showJobTitle} />
             </FlexGridColumn> */}
              <FlexGridColumn
                binding='program'
                header='DOT Mode'
                // width='10*'
                width={100}
              >
                <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
              </FlexGridColumn>

              <FlexGridColumn binding='eventNote' header='Notes' width={260}>
                <FlexGridCellTemplate cellType='Cell' template={showMessage} />
              </FlexGridColumn>
              {/* </FlexGridColumn> */}

              <FlexGridDetail
                template={(ctx) => (
                  <div style={{ overflowX: 'auto', transition: 'width 0.3s ease' }}>
                    <FlexGrid
                      id='theGridTestingEvent'
                      autoGenerateColumns={false}
                      headersVisibility='Column'
                      alternatingRowStep={1}
                      itemsSource={getSubItemSource(ctx)}
                      itemsPerPage={10}
                      isReadOnly={true}
                      scrollMode='Virtual' // Add this line for smooth scrolling
                      formatItem={formatExItem}
                    >
                      <FlexGridColumn
                        binding='test_name_moved0'
                        header='Test Name'
                        // minWidth={250}
                        // width='*'
                        width={284}
                        wordWrap={true}
                      />
                      <FlexGridColumn
                        binding='final_result'
                        header='Result'
                        // minWidth={100}
                        // width='*'
                        width={160}
                      />

                      <FlexGridColumn
                        binding='test_status'
                        header='Status'
                        // minWidth={100}
                        // width='*'
                        width={90}
                      />
                      <FlexGridColumn
                        binding='testing_date'
                        header='Test Date'
                        // minWidth={150}
                        // width='*'
                        width={120}
                      >
                        <FlexGridCellTemplate cellType='Cell' template={showCompledOn} />
                      </FlexGridColumn>

                      <FlexGridColumn binding='urls' header='Documents' width={80}>
                        <FlexGridCellTemplate cellType='Cell' template={showExDoc} />
                      </FlexGridColumn>
                      <FlexGridColumn
                        binding='eventNoteEx'
                        header='Notes'
                        // minWidth={150}
                        // width='*'
                        width={1044}
                      >
                        <FlexGridCellTemplate cellType='Cell' template={showTdMessage} />
                      </FlexGridColumn>

                      <FlexGridFilter
                        filterColumns={[
                          'test_name_moved0',
                          'final_result',
                          'test_status',
                          'testing_date',
                          'eventNoteEx'
                        ]}
                      />
                    </FlexGrid>
                  </div>
                )}
                isAnimated={true} // Add this if you want to animate expansion
              />

              <FlexGridFilter
                filterColumns={[
                  'full_name',
                  'package_name',
                  'reasons',
                  'event_status',
                  'newScheduleDate',
                  'jobTitle',
                  'departments',
                  'loc_name',
                  'emp_ssn',
                  'emp_ein',
                  'DL',
                  'program',
                  'eventNote'
                ]}
              />
            </FlexGrid>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                ref={filterRefCounter}
                id='filterCounter'
              >
                ({Globalize.format(filterRefCounter.current, 'n0')} )
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                {' '}
                out of {medicalSurvellianceData.length}
              </p>
            </div>
          </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className='icofont-exclamation-tringle' style={{ color: 'red', fontSize: 20 }}></i> */}
        &nbsp;&nbsp; {errMsg}
      </p>
    </div>
  );
};

export default Medical_surveillance;
