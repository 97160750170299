import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  Form,
  Input,
  Tooltip,
  Modal,
  DatePicker,
  Select,
  Tabs,
  Space,
  Spin,
  Checkbox
} from 'antd';

import moment from 'moment';
import _ from 'underscore';
import axios from 'axios';

import { history } from '../../redux/store';
import setting from '../../config/setting';

const { TabPane } = Tabs;

// const handleClinicHours = (data) => {
//   console.log('data handleClinicHours::', data);

//   history.push({ pathname: `/vertical/testingDetailViewFurther`, state: data });
// };

// const vaccines = [
//   'Pfizer',
//   'Moderna',
//   'Janssen',
//   'Comirnaty',
//   'Tozinameran',
//   'Covishield',
//   'Vaxzevria',
//   'AstraZenca',
//   'Takeda',
//   'Spikevax',
//   'Sinopharm-BIBP',
//   'Sinovac-CoronaVac',
//   'Covaxin',
//   'Covovax',
//   'Nuvaxovid'
// ];
// const actions = (data) => (
//   <div className='buttons-list nowrap'>
//     <Tooltip title='Test Information'>
//       <Button
//         shape='circle'
//         onClick={() => handleClinicHours(data)}
//         type='primary'
//         style={{
//           background: 'none',
//           border: 'none',
//           boxShadow: 'none',
//           color: '#336cfb'
//         }}
//       >
//         <span className='icofont icofont-info-circle' style={{ fontSize: 25 }} />
//       </Button>
//     </Tooltip>
//   </div>
// );

const TestingDetails = () => {
  const location = useLocation();

  const data = location.state['id'];

  const [imageUrls, setImageUrls] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const [employeeName, setEmployeeName] = useState([]);
  //const [testArr, setTestArr] = useState([]);
  // const [obj, setObj] = useState({});
  const [change, setChange] = useState(false);

  const [ChangeNN, setChangeNN] = useState(false);
  // const [firstName, setEmployeeFirstName] = useState('');
  const [RecordsArr, setRecords] = useState([]);

  useEffect(() => {
    console.log('this.props Test Event', data);
    if (data == 'undefined' || data == undefined) {
      history.goBack();
    } else {
      getTestingEventDetailData(location.state['id']);
    }

    //console.log("location.state['data']",location.state['data'])
  }, []);

  const columnsEmployee = [
    {
      key: 'gender',
      dataIndex: 'gender',
      sorter: (a, b) => a.gender.length - b.gender.length,
      title: 'Gender',

      render: (gender) => <span>{gender}</span>
    },
    {
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      title: 'First Name',

      render: (first_name) => <span>{first_name}</span>
    },
    {
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: (a, b) => a.last_name.length - b.last_name.length,
      title: 'Last Name',

      render: (last_name) => <span>{last_name}</span>
    },
    {
      key: 'ssn',
      dataIndex: 'ssn',

      title: 'SSN',

      render: (ssn) => <span>{ssn}</span>
    },
    {
      key: 'dob',
      dataIndex: 'dob',

      title: 'DOB',

      render: (dob) => <span>{dob}</span>
    }
  ];
  const columnsTestDetail = [
    {
      key: 'caseNumber',
      dataIndex: 'caseNumber',
      sorter: (a, b) => a.caseNumber.length - b.caseNumber.length,
      title: 'Case Number',

      render: (caseNumber) => <span>{caseNumber}</span>
    },
    {
      key: 'url',
      dataIndex: 'url',
      sorter: (a, b) => a.url.length - b.url.length,
      title: 'Donor Pass Url',

      render: (url) => (
        <a href={url} target='_blank'>
          <span>{url && url.substring(0, 22) + '...'}</span>
        </a>
      )
    },

    {
      title: 'Item Name',
      key: 'test_name',
      dataIndex: 'test_name',

      render: (test_name) => <span className='nowrap'>{test_name == false ? '' : test_name}</span>
    },

    {
      title: 'First Name',
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      render: (first_name) => <div>{first_name}</div>
    },
    // {
    //   key: 'dot_classification',
    //   dataIndex: 'dot_classification',
    //   title: 'DOT Classification',
    //   sorter: (a, b) => a.dot_classification.length - b.dot_classification.length,

    //   render: (dot_classification) => (
    //     <span>{dot_classification == 'False' ? '' : dot_classification}</span>
    //   )
    // },
    {
      key: 'event_status',
      dataIndex: 'event_status',
      title: 'Testing Status',
      sorter: (a, b) => a.event_status.length - b.event_status.length,
      render: (event_status) => (
        <span className='nowrap'>{event_status == false ? '' : event_status}</span>
      )
    },
    {
      key: 'completed_on',
      dataIndex: 'completed_on',
      title: 'Completed Date',
      // sorter: (a, b) => a.completed_date.length - b.completed_date.length,
      render: (completed_on) => (
        <span className='nowrap'>{completed_on == false ? '' : completed_on}</span>
      )
    },
    {
      key: 'comments',
      dataIndex: 'comments',
      title: 'Comments',
      sorter: (a, b) => a.comments.length - b.comments.length,
      render: (comments) => <span className='nowrap'>{comments == false ? '' : comments}</span>
    }
    // {
    //   key: 'actions',
    //   title: 'Actions',
    //   render: actions
    // }
  ];
  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };
  function downloadVaccination(e, attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        console.log('Respone from download images', response.data);
        if (response && response.status === 200) {
          setChange(!change);
          if (Array.isArray(response.data)) {
            setIsModalVisible(true);
            setImageUrls(response.data);
          } else {
            window.open(response.data);
          }
          // if (Array.isArray(response.data)) {
          //   window.open(response.data[0].url);
          // }
        }
      });
  }

  function download(e, attachment_url) {
    axios
      .post(setting.serverUrl + '/api/downloadTestingCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_url: attachment_url
        }
      })
      .then((response) => {
        console.log('Respone from getData Covid_Vaccination', response.data);
        setChangeNN(!ChangeNN);
        window.open(response.data);
      });
  }

  function getTestingEventDetailData(eventId) {
    setNoRecordFound(true);

    axios
      .post(setting.serverUrl + '/api/getTestingEventById', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          eventId: eventId
        }
      })
      .then(async (response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        console.log('Respone from employee details ', response.data);
        let result = response.data;

        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          setRecords(response.data);
          setNoRecordFound(false);
          console.log('final result openNotifId::::', result);
        }
      });
  }
  console.log('RecordsArr', RecordsArr);

  return (
    <div>
      <h4 style={{ padding: 10, textAlign: 'left' }}>Test Event Details</h4>
      {!noRecordFound ? (
        RecordsArr.length > 0 ? (
          <>
            <Card style={{ backgroundColor: 'white' }}>
              <h6>Job Summary</h6>
              <div className='row'>
                <div className='col-md-1 col-sm-1'></div>
                <div className='col-md-5 col-sm-5' style={{ borderRight: 'solid #abc 2px' }}>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Type of Event :</span>
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].type_of_events
                        ? RecordsArr[0].type_of_events
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Package :</span>
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].test_name ? RecordsArr[0].test_name : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Clinic Name :</span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].clinic_name ? RecordsArr[0].clinic_name : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Scheduled Date :</span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].scheduled_date
                        ? RecordsArr[0].scheduled_date
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Bill to :</span>
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {' '}
                      {RecordsArr[0] && RecordsArr[0].bill_to[1] ? RecordsArr[0].bill_to[1] : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>CCF Type :</span>
                    <span style={{ width: '35%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].ccf_type ? RecordsArr[0].ccf_type : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Vercx :</span>
                    <span style={{ width: '35%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].vercx_checkbox
                        ? RecordsArr[0].vercx_checkbox
                        : ''}{' '}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      Clearance Required :
                    </span>
                    <span style={{ width: '35%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].clearance_required
                        ? RecordsArr[0].clearance_required
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Vercx App WoNum:</span>
                    <span style={{ width: '35%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].vercx_app_wonum
                        ? RecordsArr[0].vercx_app_wonum
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Vercx Work Order:</span>
                    <span style={{ width: '35%', display: 'inline-block' }}>{''}</span>
                  </p>
                </div>

                <div className='col-md-1 col-sm-1'></div>
                <div className='col-md-5 col-sm-5'>
                  {/* <p>
            <span style={{ width: '30%', display: 'inline-block' }}>Location :</span>{' '}
            <span style={{ width: '30%', display: 'inline-block' }}> {RecordsArr[0] && RecordsArr[0].child_ids ? RecordsArr.child_ids[1] : ''}</span>
          </p> */}
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Reason : </span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].reasons ? RecordsArr[0].reasons : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Items Name : </span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].tree_field ? RecordsArr[0].tree_field : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      Collection Site :{' '}
                    </span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {' '}
                      {RecordsArr[0] && RecordsArr[0].observed_Collection
                        ? RecordsArr[0].observed_Collection
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Schedule Type : </span>{' '}
                    <span style={{ width: '35%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].schedule_type
                        ? RecordsArr[0].schedule_type
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>Status : </span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {' '}
                      {RecordsArr[0] && RecordsArr[0].event_status
                        ? RecordsArr[0].event_status
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}> Vercx Status :</span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {' '}
                      {RecordsArr[0] && RecordsArr[0].vercx_status
                        ? RecordsArr[0].vercx_status
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {' '}
                      Number To Be Tested :
                    </span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {RecordsArr[0] && RecordsArr[0].no_to_be_tested
                        ? RecordsArr[0].no_to_be_tested
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}> Clinic hours :</span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {' '}
                      {RecordsArr[0] && RecordsArr[0].clinic_hours
                        ? RecordsArr[0].clinic_hours
                        : ''}
                    </span>
                  </p>
                  <p>
                    <span style={{ width: '30%', display: 'inline-block' }}> Event Notes :</span>{' '}
                    <span style={{ width: '30%', display: 'inline-block' }}>
                      {' '}
                      {RecordsArr[0] && RecordsArr[0].event_notes ? RecordsArr[0].event_notes : ''}
                    </span>
                  </p>
                </div>
              </div>
            </Card>

            <Tabs defaultActiveKey='1'>
              <TabPane
                tab={
                  <span style={{ fontSize: 13, textShadow: '1px 1px 3px #c0d7c0' }}>
                    EMPLOYEE DETAILS
                  </span>
                }
                key='1'
                style={{ fontSize: 6 }}
              >
                {/* <h4 style={{ padding: 10, textAlign: 'left' }}>Employee Details</h4> */}

                <Table columns={columnsEmployee} dataSource={RecordsArr} />
              </TabPane>
              <br />
              <TabPane
                tab={
                  <span style={{ fontSize: 13, textShadow: '1px 1px 3px #c0d7c0' }}>
                    TEST DETAILS
                  </span>
                }
                key='2'
                style={{ fontSize: 6 }}
              >
                {/* <h4 style={{ padding: 10, textAlign: 'left' }}>Test Details</h4> */}

                <Table columns={columnsTestDetail} dataSource={RecordsArr} />
              </TabPane>
            </Tabs>
          </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>No details found</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      <Modal
        width={1000}
        title='Documents Preview'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {imageUrls &&
          imageUrls.length > 0 &&
          imageUrls.map((img) => {
            console.log('img :: ', img);
            return (
              <p>
                {img.fileType && img.fileType == 'application/pdf' ? (
                  <embed src={img.url} width='800px' height='2100px' />
                ) : (
                  <img src={img.url} />
                )}
              </p>
            );
          })}
      </Modal>
    </div>
  );
};

export default TestingDetails;
