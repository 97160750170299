import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spin, Space, Row, Col, Table, Button } from 'antd';
import { history } from '../../redux/store';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import setting from '../../config/setting';

const EAP_Programs_Details = () => {
  const [state, setState] = useState(true);
  const [data, setData] = useState([]);
  const [change, setChange] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [attachmentResponse, setAttachmentResponse] = useState([]);
  const [nofoundAttach, setNoFoundAttach] = useState(false);
  const [nofoundTrail, setNoFoundTrail] = useState(false);
  const location = useLocation();

  async function getEapAttachments() {
    setNoFoundAttach(true);
    axios
      .post(setting.serverUrl + '/api/eapAttachments', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          eapId: location.state.id
        }
      })
      .then(async (response) => {
      
        console.log('eapAttachments response ::::: ', response);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
        
          setAttachmentResponse(response.data.rows);
          setNoFoundAttach(false);
        } else {
          alert("Error!")
        }
      });
  }
  async function get_activity_trail() {
    setNoFoundTrail(true);
    setState(false);
    setChange(false);
    console.log('get_activity_trail :: ');
    await axios
      .post(setting.serverUrl + '/api/get_activity_trail', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          activity_trail: location.state.activity_trail
        }
      })
      .then((response) => {
        setChange(true);
        console.log('Respone from get_activity_trail App tsx ', response.data);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          console.log('customer get_activity_trail data', response.data);
          let alldata = response.data;
          console.log(
            'getEAPPrograms',
            alldata.map((element) => element.__last_update)
          );
          alldata.sort((a, b) => {
            let date1 = new Date(b.__last_update);
            let date2 = new Date(a.__last_update);
            return date1 > date2 ? 1 : -1;
          });
          setNoFoundTrail(false);
          setData(alldata);
          setFilterData(alldata);
          console.log('customer get_activity_trail data', alldata);
        }
      });
  }

  useEffect(() => {
    if (location && location.state) {
      let props = location.state;
      //console.log('this.props customerId 1', JSON.parse(localStorage.getItem('customerData')));
      get_activity_trail();
      getEapAttachments();
      console.log('location.state', props);
    } else {
      history.goBack();
    }
  }, []);

  function downloadAttachmentMultiple(attachment) {
    console.log('attachment :: ', attachment);
    if(attachment && attachment.length > 0) {
      axios
      .post(setting.serverUrl + '/api/downloadEmployeeAttachments', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log('Respone from attachment multiple', response.data);
          setChange(!change);
          window.open(response.data);
         }
      });
    } else {
      alert('No Record Found');
     
    }
  }
  const columns = [
    {
      key: ' test_event',
      dataIndex: 'test_event',
      sorter: (a, b) => a.test_event[1].length - b.test_event[1].length,
      title: 'Test Event',
     

      render: (test_event) => <span>{test_event.length > 0 ? test_event[1] : ''}</span>
    },
    {
      title: 'Event Notes',
      key: 'event_notes',
      dataIndex: 'event_notes',
      sorter: (a, b) => a.event_notes.length - b.event_notes.length,
      render: (event_notes) => <span className='nowrap'>{event_notes}</span>
    }
  ];
  const columnsAttachTable = [
    {
      key: ' name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      title: 'Name',

      render: (name) => <span>{name.length > 0 ? name : ''}</span>
    },
    {
      title: 'Created On',
      key: 'create_date',
      dataIndex: 'create_date',
      //sorter: (a, b) => a.create_date.length - b.create_date.length,
      render: (create_date) => (
        <span className='nowrap'>
          {create_date ? moment(create_date).format('MM/DD/YYYY') : ''}
        </span>
      )
    },
    {
      title: 'Documents',
      key: 'url',
      dataIndex: 'url',
      render: (url) => (
        <p>
          <button style={{ margin: 3 }} onClick={() => downloadAttachmentMultiple(url)}>View Doc</button>
        </p>
      )
    }
  ];
 
 

  return (
    <div style={{ padding: 20, marginTop: 10 }}>
       <div>
        <Button
          size='small'
          style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
          //onClick={() =>history.push({ pathname: `/vertical/employeeDetails`, state: location.state})}
          onClick={()=>history.goBack()}
        >
          Back
        </Button>
      </div>
      <br/>
      <Row>
        <Col span={10} style={{ textAlign: 'left', fontWeight: 900, fontSize: 27 }}>
          EAP Programs Details
        </Col>

        <Col span={14}></Col>
      </Row>

      <div style={{ margin: 10, padding: 10, borderRadius: '5px', backgroundColor: '#fff' }}>
        <div className='row g-50'>
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Location : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>
              {location.state && location.state.child_ids &&  location.state.child_ids.length > 0 &&
                      !location.state.child_ids[1].includes('|') &&
                      !location.state.child_ids[1].includes(',')
                     ? location.state.child_ids[1] : location.state.child_ids[1]?.split('|')[1] || location.state.child_ids[1]?.split(',')[1]}

            </p>
          </div>

          <div className='col-md-3 col-sm-3 col-6'>
            <b>Drug Test Date : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.drug_test_date ? moment(location.state.drug_test_date).format('MM/DD/YYYY') :''}</p>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Name : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.employee && location.state.employee[1]}</p>
          </div>
          {/* Parent Client Id */}
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Suspension Request : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.suspension_req}</p>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Department : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.department}</p>
          </div>
          {/* Parent Client Id */}
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Letter Date : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.letter_date ? moment(location.state.letter_date).format('MM/DD/YYYY') : ""}</p>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-3 col-sm-3 col-6'>
            <b>EIN : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.ein}</p>
          </div>
          {/* Parent Client Id */}
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Expiration Date: </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.expiration_date ? moment(location.state.expiration_date).format('MM/DD/YYYY') : ""}</p>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Phone : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.ph_num}</p>
          </div>
          {/* Parent Client Id */}
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Called Date: </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.called_date ? moment(location.state.called_date).format('MM/DD/YYYY') : ""}</p>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Email Address : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.email}</p>
          </div>
          {/* Parent Client Id */}
          <div className='col-md-3 col-sm-3 col-6'>
            <b>RTDL Req Date: </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.rtdl_req_date ?  moment(location.state.rtdl_req_date).format('MM/DD/YYYY') : ""}</p>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Address : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.address}</p>
          </div>
          {/* Parent Client Id */}
          <div className='col-md-3 col-sm-3 col-6'>
            <b>RTD Drug Test Date: </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.rtd_drug_test_date ?  moment(location.state.rtd_drug_test_date).format('MM/DD/YYYY'):""}</p>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Program : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.program}</p>
          </div>
          {/* Parent Client Id */}
          <div className='col-md-3 col-sm-3 col-6'>
            <b>RTD Date : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.rtd_date ? moment(location.state.rtd_date).format('MM/DD/YYYY'):""}</p>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-md-3 col-sm-3 col-6'>
            <b>Status : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>{location.state && location.state.status}</p>
          </div>

          <div className='col-md-3 col-sm-3 col-6'>
            <b>Expected Graduation : </b>
          </div>
          <div className='col-md-3 col-sm-3 col-6'>
            <p style={{ fontSize: 12 }}>
              {location.state && location.state.expected_graduation_date ? moment(location.state.expected_graduation_date).format('MM/DD/YYYY'):""}
            </p>
          </div>
        </div>
      </div>
      <br />
      <Row>
        <Col span={10} style={{ textAlign: 'left', fontWeight: 900, fontSize: 22 }}>
          Activities Trail/Events
        </Col>
      </Row>
      <br/>
      <Row>
        <Col span={24}>
        {!nofoundTrail ? (
          filterData.length > 0 ?(
            <div className='row' style={{ marginTop: 20 }}>
              <div className='col-12 col-md-12 col-xl-12'>
                <Table dataSource={filterData} columns={columns} />
              </div>
            </div>
              ) : (
                <p style={{ fontSize: 15, fontWeight: 700, marginLeft: 12, color: '#ef6727' }}>
                  
                  No data found !
                </p>
              )
              ) : (
                <Row>
                  <Col span={10}></Col>
                  <Col span={8}>
                    <Space size='middle' style={{ marginTop: 50,marginLeft:85 }}>
                      <Spin size='large' />
                    </Space>
                  </Col>
                </Row>
              )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={10} style={{ textAlign: 'left', fontWeight: 900, fontSize: 22 }}>
          Attachments
        </Col>
      </Row>
      <br/>
      <Row>
        <Col span={24}>
         {!nofoundAttach ? (
          attachmentResponse.length > 0 ? (
            <div className='row' style={{ marginTop: 20 }}>
              <div className='col-12 col-md-12 col-xl-12'>
                <Table dataSource={attachmentResponse} columns={columnsAttachTable} />
              </div>
            </div>
           ) : (
            <p style={{ fontSize: 15, fontWeight: 700, marginLeft: 12, color: '#ef6727' }}>
              
              No data found !
            </p>
          )
          ) : (
            <Row>
              <Col span={10}></Col>
              <Col span={8}>
                <Space size='middle' style={{ marginTop: 50,marginLeft:85 }}>
                  <Spin size='large' />
                </Space>
              </Col>
            </Row>
          )}
           
       
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={24} sm={24} lg={2} xl={2}>
          <p style={{ fontWeight: 900, fontSize: 22 }}>Notes</p>
        </Col>
        <Col  xs={24} sm={24} lg={22} xl={22}>
          <div style={{border: "solid 0.5px white", backgroundColor: "#fff", minHeight:'50px'}}>{location && location.state && location.state.notes}</div>
          </Col>
      </Row>
    </div>
  );
};

export default EAP_Programs_Details;
