import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spin, Space, Row, Col, Button, Select } from 'antd';

import settings from '../../config/setting';
import '@grapecity/wijmo.styles/wijmo.css';
import LeftBone from './../../assets/img/leftBone.png';
import LeftBoneUp from './../../assets/img/leftBoneUp.png';
import LeftMaskedBoneUp from './../../assets/img/leftMaskedBoneUp.png';
import LeftMaskedAirDown from './../../assets/img/leftzMaskedAirDown.png';
import LeftMaskedAirUp from './../../assets/img/leftMaskedAirUp.png';
import moment from 'moment';
import RightAir from './../../assets/img/rightAir.png';
import RightMaskedAirDown from './../../assets/img/rightMaskedAirDown.png';
import RightMaskedBoneUp from './../../assets/img/rightMaskedBoneUp.png';
import LeftAirDown from './../../assets/img/leftAirDown.png';
import LeftAirUp from './../../assets/img/leftAirUp.png';
import LeftCurrent from './../../assets/img/leftCurrent.png';
import RightAirDown from './../../assets/img/rightAirDown.png';
import RightBoneDown from './../../assets/img/rightBoneDown.png';
import RightBoneUp from './../../assets/img/rightBoneUp.png';
import RightCurrent from './../../assets/img/rightCurrent.png';
import RightMaskedAirUp from './../../assets/img/rightMaskedAirUp.png';
import RightMaskedBoneDown from './../../assets/img/rightMaskedBoneDown.png';
import LeftMaskedBoneDown from './../../assets/img/leftMaskedBoneDown.png';
import BaselineEstab from './../../assets/img/baselineEstab.png';

const ShowHisGraph = ({ testId }) => {
  console.log('testId from right table----------', testId);
  const [nofound, setNoFound] = useState(false);

  const [graphData, setGraphData] = useState([]);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    console.log('testId from right table----------', testId);

    if (location && location.state) {
      console.log('location.state graph---------', location.state);
      getAudiogramGraph(location.state.id);
    } else {
      history.goBack();
    }
  }, []);

  const getAudiogramGraph = (id) => {
    setNoFound(true);
    axios
      .post(settings.serverUrl + '/api/getAudiogramGraphData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: localStorage.getItem('customerId'),
          testId: id
        }
      })
      .then((response) => {
        let finalResult = response.data;
        setGraphData(finalResult);

        setNoFound(false);
      });
  };

  return (
    //     <div>

    //     {!nofound ? (
    //         graphData.length > 0 ? (
    //           <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>

    //     {graphData.map((item, index) => (
    //       <div key={item.id} style={{ position: 'relative', maxWidth: '50%', flex: '0 0 50%', marginBottom: '20px' }}>
    //         <span
    //           style={{
    //             position: 'absolute',
    //             top: '5px',
    //             left: '5px',
    //             fontSize: '16px',
    //             fontWeight: 'bold',
    //             color: index === 0 ? 'rgb(195,95,78)' : 'rgb(60,107,246)',
    //             padding: '5px',
    //             borderRadius: '5px'
    //           }}
    //         >
    //           {index === 0 ? 'Right Ear Graph:' : 'Left Ear Graph:'}
    //         </span>
    //         <span
    //           style={{
    //             position: 'absolute',
    //             top: '5px',
    //             right: '5px',
    //             fontSize: '15px',
    //             fontWeight: 'bold',
    //             color: index === 0 ? 'rgb(195,95,78)' : 'rgb(60,107,246)',
    //             padding: '5px',
    //             borderRadius: '5px'
    //           }}
    //         >
    //           {/* {index === 0 ? `AHL: ${location.state.ahl_right}` : `AHL: ${location.state.ahl_left}`} */}
    //         </span>
    //         <br/>
    //         <img src={item.url} alt={item.name || ''} style={{ width: '100%', height: 'auto', marginRight: 10 }} />
    //         <table style={{ width: '100%', margin: '10px 0px 0px -10px', textAlign: 'center'}}>
    //           <tbody>
    //             <tr>
    //               <td style={{ textAlign:'right'}}>Right &nbsp;&nbsp;</td>
    //               <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
    //             {index === 0 ? <img src={RightAir} alt="Right Air" style={{ height: '12px', width: '12px' }} /> : <img src={LeftAirUp} alt="Left Air" style={{ height: '12px', width: '12px' }} />}
    //             &nbsp;&nbsp;Air
    //           </td>

    //               {/* <td style={{textAlign:'center',border: '1px solid lightgrey'}}><img src={LeftAirUp} alt="Left Bone" style={{ height: '12px', width: '12px' }} />&nbsp;&nbsp;Air</td> */}
    //               <td style={{textAlign:'center',border: '1px solid lightgrey'}}>
    //               {index === 0 ? <img src={RightMaskedAirUp} alt="Right Air" style={{ height: '12px', width: '12px' }} /> : <img src={LeftMaskedAirUp} alt="Left Bone" style={{ height: '14px', width: '14px' }} />}&nbsp;&nbsp;Masked Air</td>
    //               <td style={{textAlign:'center',border: '1px solid lightgrey'}}>
    //               {index === 0 ? <img src={RightBoneUp} alt="Right Air" style={{ height: '10px', width: '8px' }} /> :<img src={LeftBoneUp} alt="Left Bone" style={{ height: '12px', width: '10px' }} />}&nbsp;&nbsp;Bone</td>
    //               <td style={{textAlign:'center',border: '1px solid lightgrey'}}>
    //               {index === 0 ? <img src={RightMaskedBoneUp} alt="Right Air" style={{ height: '10px', width: '7px' }} /> :<img src={LeftMaskedBoneUp} alt="Left Bone" style={{ height: '12px', width: '8px' }} />}&nbsp;&nbsp;Masked Bone</td>

    //             </tr>
    //             <tr>
    //               <td style={{textAlign:'right'}}>No Response&nbsp;&nbsp;</td>
    //               <td style={{textAlign:'center',border: '1px solid lightgrey'}}>
    //               {index === 0 ? <img src={RightAirDown} alt="Right Air" style={{ height: '15px', width: '15px' }} /> : <img src={LeftAirDown} alt="Left Bone" style={{ height: '15px', width: '15px' }} />} &nbsp;Air</td>
    //               <td style={{textAlign:'center',border: '1px solid lightgrey'}}>
    //               {index === 0 ? <img src={RightMaskedAirDown} alt="Right Air" style={{ height: '15px', width: '15px' }} /> :<img src={LeftMaskedAirDown} alt="Left Bone" style={{ height: '20px', width: '19px' }} />}&nbsp;Masked Air</td>
    //               <td style={{textAlign:'center',border: '1px solid lightgrey'}}>
    //               {index === 0 ? <img src={RightBoneDown} alt="Right Air" style={{ height: '25px', width: '20px' }} /> :<img src={LeftBone} alt="Left Bone" style={{ height: '30px', width: '25px' }} />}
    // Bone</td>
    //               <td style={{textAlign:'center',border: '1px solid lightgrey'}}>
    //               {index === 0 ? <img src={RightMaskedBoneDown} alt="Right Air" style={{ height: '15px', width: '12px' }} /> :<img src={LeftMaskedBoneDown} alt="Left Bone" style={{ height: '15px', width: '15px' }} />}&nbsp;Masked Bone</td>

    //             </tr>
    //             <tr>
    //               <td colspan={2}> {index === 0 ? <img src={RightCurrent} alt="Right Air" style={{ height: '12px', width: '40px' }} /> :<img src={LeftCurrent} alt="Left Bone" style={{ height: '8px', width: '40px' }} />} &nbsp;Current</td>
    //               <td></td>
    // <td colspan={2}>
    //   {(
    //     (location.state.test_name === 'Audiogram' && location.state.test_name === 'Completed') ||
    //     location.state.is_baseline
    //   ) && (
    //     <>
    //       <img src={BaselineEstab} alt="Left Bone" style={{ height: '8px', width: '40px' }} />&nbsp;
    //       Established Baseline ({location.state.testing_date_basline != null && moment(location.state.testing_date_basline).format('MM/DD/YYYY')})
    //     </>
    //   )}
    // </td>

    //             </tr>
    //             {/* Additional rows with data can be added here */}
    //           </tbody>
    //         </table>
    //       </div>
    //     ))}
    //        </div>
    //         ) : (
    //           <h5 style={{ color: '#ef6727', textAlign: 'center' }}>No Graphs Available !</h5>
    //         )
    //       ) : (
    //         <Row>
    //           <Col span={10}></Col>
    //           <Col span={4}>
    //             <Space size='middle' style={{ marginTop: 50 }}>
    //               <Spin size='large' />
    //             </Space>
    //           </Col>
    //           <Col span={10}></Col>

    //         </Row>
    //       )}
    //   </div>
    <div>
      {!nofound ? (
        graphData?.length > 0 ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div
              style={{
                position: 'relative',
                maxWidth: '50%',
                flex: '0 0 50%',
                marginBottom: '20px'
              }}
            >
              <span
                style={{
                  position: 'absolute',
                  top: '5px',
                  left: '5px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: 'rgb(60,107,246)',
                  padding: '5px',
                  borderRadius: '5px'
                }}
              >
                Left Ear Graph:
              </span>
              <br />
              <img
                src={graphData.find((item) => item.name?.includes('left'))?.url}
                alt='Left Ear Graph'
                style={{ width: '100%', height: 'auto', marginRight: 10 }}
              />
              <table
                style={{
                  width: '100%',
                  margin: '10px 0px 0px -10px',
                  textAlign: 'center'
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right' }}>Right &nbsp;&nbsp;</td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={LeftAirUp}
                        alt='Left Air'
                        style={{ height: '12px', width: '12px' }}
                      />
                      &nbsp;&nbsp;Air
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={LeftMaskedAirUp}
                        alt='Left Bone'
                        style={{ height: '14px', width: '14px' }}
                      />
                      &nbsp;&nbsp;Masked Air
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={LeftBoneUp}
                        alt='Left Bone'
                        style={{ height: '12px', width: '10px' }}
                      />
                      &nbsp;&nbsp;Bone
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={LeftMaskedBoneUp}
                        alt='Left Bone'
                        style={{ height: '12px', width: '8px' }}
                      />
                      &nbsp;&nbsp;Masked Bone
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right' }}>No Response&nbsp;&nbsp;</td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={LeftAirDown}
                        alt='Left Air'
                        style={{ height: '15px', width: '15px' }}
                      />
                      &nbsp;Air
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={LeftMaskedAirDown}
                        alt='Left Bone'
                        style={{ height: '15px', width: '15px' }}
                      />
                      &nbsp;Masked Air
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={LeftBone}
                        alt='Left Bone'
                        style={{ height: '25px', width: '20px' }}
                      />
                      &nbsp;Bone
                    </td>

                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={LeftMaskedBoneDown}
                        alt='Left Bone'
                        style={{ height: '15px', width: '15px' }}
                      />
                      &nbsp;Masked Bone
                    </td>
                  </tr>
                  <tr>
                    <td colspan={2}>
                      {' '}
                      <img
                        src={LeftCurrent}
                        alt='Left Bone'
                        style={{ height: '8px', width: '40px' }}
                      />{' '}
                      &nbsp;Current
                    </td>
                    <td></td>
                    <td colspan={2}>
                      {((location.state.test_name === 'Audiogram' &&
                        location.state.testing_status === 'Completed') ||
                        location.state.is_baseline) && (
                        <>
                          <img
                            src={BaselineEstab}
                            alt='Left Bone'
                            style={{ height: '8px', width: '40px' }}
                          />
                          &nbsp; Established Baseline (
                          {location.state.testing_date_basline != null &&
                            moment(location.state.testing_date_basline).format('MM/DD/YYYY')}
                          )
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                position: 'relative',
                maxWidth: '50%',
                flex: '0 0 50%',
                marginBottom: '20px'
              }}
            >
              <span
                style={{
                  position: 'absolute',
                  top: '5px',
                  left: '5px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: 'rgb(195,95,78)',
                  padding: '5px',
                  borderRadius: '5px'
                }}
              >
                Right Ear Graph:
              </span>
              <br />
              <img
                src={graphData.find((item) => item.name?.includes('right'))?.url}
                alt='Right Ear Graph'
                style={{ width: '100%', height: 'auto', marginRight: 10 }}
              />
              <table
                style={{
                  width: '100%',
                  margin: '10px 0px 0px -10px',
                  textAlign: 'center'
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right' }}>Right &nbsp;&nbsp;</td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={RightAir}
                        alt='Right Air'
                        style={{ height: '12px', width: '12px' }}
                      />
                      &nbsp;&nbsp;Air
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={RightMaskedAirUp}
                        alt='Right Air'
                        style={{ height: '12px', width: '12px' }}
                      />
                      &nbsp;&nbsp;Masked Air
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={RightBoneUp}
                        alt='Right Air'
                        style={{ height: '12px', width: '12px' }}
                      />
                      &nbsp;&nbsp;Bone
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={RightMaskedBoneUp}
                        alt='Right Air'
                        style={{ height: '12px', width: '12px' }}
                      />
                      &nbsp;&nbsp;Masked Bone
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right' }}>No Response&nbsp;&nbsp;</td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={RightAirDown}
                        alt='Right Air'
                        style={{ height: '15px', width: '15px' }}
                      />
                      &nbsp;Air
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={RightMaskedAirDown}
                        alt='Right Air'
                        style={{ height: '15px', width: '15px' }}
                      />
                      &nbsp;Masked Air
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={RightBoneDown}
                        alt='Right Air'
                        style={{ height: '25px', width: '20px' }}
                      />
                      &nbsp;Bone
                    </td>
                    <td style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                      <img
                        src={RightMaskedBoneDown}
                        alt='Right Air'
                        style={{ height: '15px', width: '15px' }}
                      />
                      &nbsp;Masked Bone
                    </td>
                  </tr>
                  <tr>
                    <td colspan={2}>
                      {' '}
                      <img
                        src={RightCurrent}
                        alt='Right Air'
                        style={{ height: '12px', width: '40px' }}
                      />{' '}
                      &nbsp;Current
                    </td>
                    <td></td>
                    <td colspan={2}>
                      {((location.state.test_name === 'Audiogram' &&
                        location.state.testing_status === 'Completed') ||
                        location.state.is_baseline) && (
                        <>
                          <img
                            src={BaselineEstab}
                            alt='Left Bone'
                            style={{ height: '8px', width: '40px' }}
                          />
                          &nbsp; Established Baseline (
                          {location.state.testing_date_basline != null &&
                            moment(location.state.testing_date_basline).format('MM/DD/YYYY')}
                          )
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>No Graphs Available !</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={4}>
            <Space size='middle' style={{ marginTop: 50,marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
          <Col span={10}></Col>
        </Row>
      )}
    </div>
  );
};

export default ShowHisGraph;
