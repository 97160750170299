import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  Space,
  Row,
  Col,
  notification,
  Input,
  Button,
  Collapse,
  Card,
  Select,
  Modal
} from 'antd';
import { history } from './../../redux/store';
import axios from 'axios';
import _ from 'underscore';
import setting from './../../config/setting';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import Search from 'antd/lib/input/Search';
import { InfoCircleTwoTone } from '@ant-design/icons';

const { Panel } = Collapse;

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'eap_programs.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const EAP_Programs = () => {
  const [state, setState] = useState(true);
  const [data, setData] = useState([]);
  const [change, setChange] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found');
  const [LoadMoreVal, setLoadMore] = useState(1);
  const [empNameValue, setEmpNameValue] = useState('');
  const [locValue, setLocValue] = useState('');
  const [programValue, setProgramValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [ssn, setSSN] = useState('');
  const [ein, setEIN] = useState('');
  const [dl, setDL] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [departmentValue, setDepartmentValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [tableLoaderVal, settableLoaderVal] = useState(false);
  const [found, setNofound] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [count, setCount] = useState();
  const [activeCount, setActiveCount] = useState(0);
  const [loadMoreHide, setLoadMoreHide] = useState(false);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [eapCount, setTotalEapCount] = useState(0);
  const [nullCount, setNullCount] = useState(0);
  const [totalShowCount, setTotalShowCount] = useState(0);
  const [view, setView] = useState();
  const [tableType, setTableType] = useState('all');
  const [pageSize, setPageSize] = useState(10);
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);
  const [listboxColumns, setListboxColumns] = useState([]);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [itemsPerPage, setItemPerPage] = useState(10);
  const theGrid = useRef();
  const theSearch = useRef();
  const gridRef = useRef(null);
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();
  const pagesize = 25;
  const [newEmp,setNewEmp] = useState('')

  useEffect(() => {
    //getEAPTests();
    getEAPStatusCounts();
    getEAPPrograms(LoadMoreVal, true);
    getEAPPrograms(LoadMoreVal);
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, [pageSize]);

  const handleShowInfo = (patient) => {
    history.push({ pathname: `/vertical/EAP_Programs_Details`, state: patient });
  };
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };
  const filterTable = (type) => {
    setTableType(type);
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;

    setListboxColumns(ctl.columns);
  };
  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const getEAPStatusCounts = () => {
    axios
      .post(setting.serverUrl + '/api/eapStatusCount', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response.status === 200) {
          //console.log('eap status count', response.data.rows);
          let statusCounts = response.data.rows;
          let ActiveStatus = statusCounts.filter((val) => val.status == 'Active');
          let InActiveStatus = statusCounts.filter((val) => val.status == 'Inactive');
          let nullStatus = statusCounts.filter((val) => val.status == null);
          let activecount = InActiveStatus && InActiveStatus[0] && InActiveStatus[0].count;
          let inactivecount = ActiveStatus && ActiveStatus[0] && ActiveStatus[0].count;
          let nullcount = nullStatus && nullStatus[0] && nullStatus[0].count;
          let sumCount =
            (activecount != undefined && Number(activecount)) +
            (inactivecount != undefined && Number(inactivecount)) +
            (nullcount != undefined && Number(nullcount));
          setActiveCount(ActiveStatus && ActiveStatus[0] && ActiveStatus[0].count);
          setInactiveCount(InActiveStatus && InActiveStatus[0] && InActiveStatus[0].count);
          setNullCount(nullStatus);
          setTotalEapCount(sumCount);
          setTotalShowCount(sumCount);
        }
      });
  };

  async function getEAPPrograms(
    LoadMoreVal,
    getcount = false,
    check = false,
    advanceFilter = false,
    employeeId
  ) {
    i = 1;
    settableLoaderVal(true);
    setIsLoading(true);
    setState(false);
    setChange(false);
    console.log('getEAPPrograms :: ');
    if (LoadMoreVal !== 1) {
      setIsLoadMoreLoading(true);
    }

    await axios
      .post(setting.serverUrl + '/api/getEAPPrograms', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: JSON.parse(localStorage.getItem('customerData')).id,
          LoadMoreVal: LoadMoreVal,
          getcount: advanceFilter ? true : getcount,
          employeeId: employeeId,
          empNameValue: check ? '' : empNameValue || newEmp,
          locValue: check ? '' : locValue,
          emailValue: check ? '' : emailValue,
          departmentValue: check ? '' : departmentValue,
          programValue: check ? '' : programValue,
          statusValue: check ? '' : statusValue
          // ssnValue :check ? '' : ssn,
          // einValue : check ? '' : ein,
          // dlValue : check ? '' : dl
        }
      })
      .then(async (response) => {
        if (response.data == 'Blocked') {
          console.log('Respone from getEAPPrograms App tsx blocked: ', response.data);
        } else {
          setChange(true);
          console.log('Respone from getEAPPrograms App tsx ', response.data);
          if (response && response.data.error) {
            alert('Some Technical Error Occurred!');
          }
          if (response && response.status == 200) {
            if (getcount == false) {
              if (response.data.value.length !== LoadMoreVal * pagesize) {
                setLoadMoreHide(true);
              } else {
                setLoadMoreHide(false);
              }
              //console.log('customer getEAPPrograms data', response.data);

              let alldatas = response.data;
              let alldata = alldatas.value;

              if (alldata && alldata.length > 0) {
                let arrayEmpIds = [];
                arrayEmpIds.push(alldata.map((k) => k.employee[0]));

                alldata.sort((a, b) => {
                  let date1 = new Date(b.__last_update);
                  let date2 = new Date(a.__last_update);
                  return date1 > date2 ? 1 : -1;
                });

                if (arrayEmpIds && arrayEmpIds.length > 0 && arrayEmpIds[0].length > 0) {
                  const idResult = await getEapIdCheckValue(arrayEmpIds);
                  console.log('idsResult', idResult);
                  const eapTestResult = await getEAPTests(arrayEmpIds);
                  console.log('eapTestResult======', eapTestResult);

                  const resss = [];
                  const ab = alldata.map((value) => {
                    return idResult.filter((val, index) => {
                      if (value.employee[0] === val.id) {
                        resss.push({ ...value, idType: idResult[index] });
                      }
                    });
                  });
                  var a = [];
                  if (advanceFilter) {
                    //console.log('inside ssn', ssn, resss[0].idType.emp_ssn.substr(-4));
                    if ((ssn && ssn.length > 0) || (ein && ein.length > 0 && dl && dl.length > 0)) {
                      a = resss.filter((val) => {
                        if (ssn && ssn.length > 0 && ssn === val.idType.emp_ssn?.substr(-4)) {
                          console.log('inside ssns', ssn);
                          return val;
                        } else if (ein && ein.length > 0 && ein.includes(val.idType.emp_ein)) {
                          console.log('inside ein');
                          return val;
                        } else if (dl && dl.length > 0 && dl.includes(val.idType.DL)) {
                          console.log('inside dl');
                          return val;
                        }
                      });
                    } else {
                      a = resss;
                    }
                  } else {
                    a = resss;
                  }
                  let responseResult = a.map((k) => {
                    return {
                      ...k,
                      newLocation:
                        k.child_ids.length > 0 &&
                        !k.child_ids[1].includes('|') &&
                        !k.child_ids[1].includes(',')
                          ? k.child_ids[1]
                          : k.child_ids[1]?.split('|')[1] || k.child_ids[1]?.split(',')[1],
                      newEmail: k.email != false ? k.email : '',
                      newPhone: k.ph_num != false ? k.ph_num : '',
                      newDepartment: k.department != false ? k.department : '',
                      newProgram: k.program == false ? '' : k.program,
                      newStatus: k.status == false ? '' : k.status,
                      newSsn:
                        k.idType.emp_ssn != false ? '***-**-' + k.idType.emp_ssn?.substr(-4) : '',
                      newEin: k.idType.emp_ein != false ? k.idType.emp_ein : '',
                      newDl: k.idType.DL != false ? k.idType.DL : '',
                      combinedStatusTest: (() => {
                        const employeeId = k.employee[0];

                        // Check if eapTestResult exists and has data for the employeeId
                        if (eapTestResult && eapTestResult[employeeId]) {
                          return eapTestResult[employeeId]
                            .map((x) => {
                              // Check for null, undefined, and false values before formatting and concatenating
                              const program = x.program || 'N/A';
                              const letter_date = x.letter_date
                                ? new Date(x.letter_date).toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric'
                                  })
                                : 'N/A';
                              const status = x.status || 'N/A';

                              return `${program} | ${letter_date} | ${status}`;
                            })
                            .join('\n'); // Add a newline character after each record
                        } else {
                          // Handle case when eapTestResult or data for the employeeId is not present
                          return 'N/A';
                        }
                      })()
                    };
                  });

                  setFilterData(responseResult);
                  //setWijmoFlexGrid(responseResult, 10);
                  const collectionView1 = new CollectionView(responseResult, {
                    pageSize: responseResult.length
                  });
                  setViewData(collectionView1);
                  const collectionView2 = new CollectionView(responseResult, {
                    pageSize: pageSize,
                    collectionChanged: (s) => {
                      filterRefCounter.current = s.totalItemCount;
                      var filterCounter = document.getElementById('filterCounter');
                      if (filterCounter) {
                        filterCounter.textContent = s.totalItemCount;
                      }
                    }
                  });
                  if (tableType === 'all') {
                    collectionView2.filters.push((item) => {
                      return item;
                    });
                  } else if (tableType === 'preEmployment') {
                    collectionView2.filters.push((item) => {
                      return item.event_status === 'Scheduled';
                    });
                  } else if (tableType === 'decease') {
                    collectionView2.filters.push((item) => {
                      return item.event_status === 'Completed';
                    });
                  } else if (tableType === 'absence') {
                    collectionView2.filters.push((item) => {
                      return item.event_status === 'Completed';
                    });
                  } else if (tableType === 'terminate') {
                    collectionView2.filters.push((item) => {
                      return item.event_status === 'Completed';
                    });
                  }
                  setTotalRecords(responseResult.length);
                  setView(collectionView2);
                  setNofound(false);
                  settableLoaderVal(false);

                  if (advanceFilter) {
                    setCount(response.data.value.length);
                  }
                  setHasPermission(true);
                } else {
                  setErrMsg('No Record Found');
                }
              } else {
                setErrMsg('No Record Found');
                setNofound(true);
                setFilterData([]);
                setHasPermission(true);
              }
            } else {
              setCount(response.data.value.length);
              setData(response.data.value);
            }
          } else if (response.status === 201) {
            setNofound(true);
            setErrMsg('Access permission not allowed.');
            setNoRecordMessage('Access permission not allowed.');
          } else {
            notification.error({
              message: 'Failed to retrieve data at the moment. Please try again in a bit.'
            });
            setNofound(true);
          }
        }
        setIsLoading(false);
      });
  }
  async function getEAPTests(empId) {
    const res = await axios.post(setting.serverUrl + '/api/getEAPTestCustomer', {
      params: {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        //compId: JSON.parse(localStorage.getItem('customerData')).id
        employeeIds: empId
      }
    });
    const responseTest = await res;
    const resultresponseTest = responseTest?.data?.rows;
    let dataGroupByEmployeeId = _.groupBy(resultresponseTest, 'employee');

    return dataGroupByEmployeeId;
  }

  async function getEapIdCheckValue(empId) {
    const res = await axios.post(setting.serverUrl + '/api/getIdTypeCheckValue', {
      params: {
        email: localStorage.getItem('myData'),
        user: localStorage.getItem('myData'),
        pass: localStorage.getItem('myPass'),
        compId: JSON.parse(localStorage.getItem('customerData')).id,
        employeeId: empId
      }
    });

    const response = await res;
    console.log('aaaaaaa', response);
    const result = response.data.rows;
    console.log('aaaaaaa', result);
    return result;
  }

  // const showTotal = (total) => {
  //   return <p>Total {total} items</p>;
  // };
  // let theGrids = theGrid.current;
  // let theSearchs = theSearch.current;
  // if (theGrids && theSearchs) {
  //   theSearchs.control.grid = theGrids.control;
  //   //console.log('the searches', (theSearchs.grid = theGrids));
  // }

  const showAdvanceFilterModal = () => {
    console.log('insdie advance filter');
    setIsAdvanceFilterModalOpen(true);
  };

  const handleAdvanceFilterCancel = () => {
    setIsAdvanceFilterModalOpen(false);
  };

  const handleChange = (e) => {
    // console.log(e.target.value);
    setNewEmp(e.target.value);
    // setEmpNameValue(newEmp);
  };

  const onSearch = (value) => {
    if (newEmp.length > 0) {
      var employeeId = data.filter((value, index) => {
        // console.log('data data', value);
        if (
          value.employee[1].toUpperCase().includes(newEmp.toUpperCase()) ||
          value.employee[1].toUpperCase() === newEmp.toUpperCase()
        ) {
          return value.employee[0];
        }
      });

      if (employeeId && employeeId.length > 0) {
        const employeeID = employeeId.map((val) => {
          return val.employee[0];
        });

        getEAPPrograms(1, false, false, true, employeeID);
      } else {
        getEAPPrograms(1, false, false, true, '');
      }
    }
    setEmpNameValue('');
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterModalOpen(false);
    var employeeId = data.filter((value, index) => {
      console.log('data data', value);
      if (
        value.employee[1].toUpperCase().includes(empNameValue.toUpperCase()) ||
        value.employee[1].toUpperCase() === empNameValue.toUpperCase()
      ) {
        return value.employee[0];
      }
    });

    if (employeeId && employeeId.length > 0) {
      const employeeID = employeeId.map((val) => {
        return val.employee[0];
      });

      getEAPPrograms(1, false, false, true, employeeID);
    } else {
      getEAPPrograms(1, false, false, true, '');
    }
  };
  const showSSN = (item) => {
    return (
      <span>
        {' '}
        {item.item.idType && item.item.idType.emp_ssn != null && item.item.idType.emp_ssn != false
          ? '***-**-' + item.item.idType.emp_ssn?.substr(-4)
          : ''}
      </span>
    );
  };
  const showEIN = (item) => {
    return (
      <span>
        {item.item.idType && item.item.idType.emp_ein != null && item.item.idType.emp_ein != false
          ? item.item.idType.emp_ein
          : ''}
      </span>
    );
  };
  const showDL = (item) => {
    return (
      <span>
        {item.item.idType && item.item.idType.DL != null && item.item.idType.DL != false
          ? item.item.idType.DL
          : ''}
      </span>
    );
  };

  const showActions = (item) => {
    return (
      <div className='buttons-list nowrap'>
        {/* <Button
          shape='circle'
          onClick={() => handleShowInfo(item.item)}
          danger
          style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            color: 'rgb(239,103,39)',
            margin: '-8px 7px'
          }}
        >
          <span
            className='icofont icofont-info-circle'
            style={{ fontSize: 16, cursor: 'pointer' }}
          />
        </Button> */}
        <InfoCircleTwoTone
          onClick={() => handleShowInfo(item.item)}
          style={{ marginLeft: 20, marginTop: 4, color: '#0066ff', fontSize: 15 }}
          twoToneColor='#0066ff'
        />
      </div>
    );
  };

  return hasPermission ? (
    <div style={{ padding: 20, marginTop: 10 }}>
      {totalShowCount > 0 ? (
        <Row>
          <Col
            xl={{ span: 6 }}
            lg={{ span: 6 }}
            md={{ span: 9 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{
              padding: 10,
              textAlign: 'left',
              fontWeight: 500,
              fontSize: 27,
              color: '#1C3A6A'
            }}
          >
            <h3> EAP Programs</h3>
          </Col>
          <Col
            xl={{ span: 1 }}
            lg={{ span: 2 }}
            md={{ span: 2 }}
            sm={{ span: 0 }}
            xs={{ span: 2 }}
          ></Col>
          <Col xl={{ span: 2 }} lg={{ span: 2 }} md={{ span: 2 }} sm={{ span: 5 }} xs={{ span: 3 }}>
            {!loadMoreHide ? (
              <Button
                size='small'
                style={{ backgroundColor: '#247', color: 'white', border: 'none', marginTop: 35 }}
                onClick={() => {
                  setLoadMore(LoadMoreVal + 1);
                  getEAPPrograms(LoadMoreVal + 1);
                }}
              >
                {isLoadMoreLoading ? (
                  <Space size='middle'>
                    <Spin size='small' />
                  </Space>
                ) : (
                  ' Load More'
                )}
              </Button>
            ) : (
              ''
            )}
          </Col>

          <Col
            xl={{ span: 9 }}
            lg={{ span: 8 }}
            md={{ span: 4 }}
            sm={{ span: 0 }}
            xs={{ span: 0 }}
          ></Col>

          <Col
            xl={{ span: 2 }}
            lg={{ span: 3 }}
            md={{ span: 3 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <Button
              size='small'
              style={{ marginTop: 35, marginLeft: 75, backgroundColor: '#247', border: 'none' }}
              onClick={showAdvanceFilterModal}
            >
              Advanced Search Filter
            </Button>
            <Modal
              width={1000}
              title='Advanced Search Filter'
              visible={isAdvanceFilterModalOpen}
              onCancel={handleAdvanceFilterCancel}
              footer={[
                <Button
                  size='small'
                  style={{ backgroundColor: '#247', border: 'none' }}
                  onClick={() => handleAdvanceFilter()}
                >
                  Search
                </Button>
              ]}
            >
              <Row gutter={[24, 24]}>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={empNameValue}
                    placeholder='Employee Name:'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setEmpNameValue(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={locValue}
                    placeholder='Location :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setLocValue(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={emailValue}
                    placeholder='Email :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setEmailValue(event.target.value)}
                  />
                </Col>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    if (value === 'SSN') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                          <Input
                            value={ssn}
                            placeholder='SSN:'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '80%', backgroundColor: 'white' }}
                            onChange={(event) => setSSN(event.target.value)}
                          />
                        </Col>
                      );
                    } else if (value === 'Driver License') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
                          <Input
                            value={dl}
                            placeholder='Driver License:'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '80%', backgroundColor: 'white' }}
                            onChange={(event) => setDL(event.target.value)}
                          />
                        </Col>
                      );
                    } else if (value === 'EIN') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
                          <Input
                            value={ein}
                            placeholder='EIN'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '80%', backgroundColor: 'white' }}
                            onChange={(event) => setEIN(event.target.value)}
                          />
                        </Col>
                      );
                    } else {
                    }
                  })}

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={departmentValue}
                    placeholder='Department :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setDepartmentValue(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={programValue}
                    placeholder='Program :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setProgramValue(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={statusValue}
                    placeholder='Status :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '80%', backgroundColor: 'white' }}
                    onChange={(event) => setStatusValue(event.target.value)}
                  />
                </Col>
              </Row>
            </Modal>
          </Col>
          {/* <Col
            xl={{ span: 2 }}
            lg={{ span: 2 }}
            md={{ span: 2 }}
            sm={{ span: 0 }}
            xs={{ span: 0 }}
          ></Col> */}
          <Col xl={{ span: 2 }} lg={{ span: 2 }} md={{ span: 2 }} sm={{ span: 5 }} xs={{ span: 5 }}>
            <Button
              size='small'
              style={{ marginTop: 35, marginLeft: 125, backgroundColor: '#247', border: 'none' }}
              onClick={() => {
                setIsLoading(true);
                setLocValue('');
                setEmailValue('');
                setDepartmentValue('');
                setStatusValue('');
                setEmpNameValue('');
                setProgramValue('');
                setSSN('');
                setEIN('');
                setDL('');
                setCount();
                getEAPPrograms(1, false, true);
                getEAPPrograms(1, true, true);
              }}
            >
              Reset Filter
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col
            xl={{ span: 6 }}
            lg={{ span: 6 }}
            md={{ span: 9 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{
              padding: 10,
              textAlign: 'left',
              fontWeight: 500,
              fontSize: 27,
              color: '#1C3A6A'
            }}
          >
            <h3> EAP Programs</h3>
          </Col>
        </Row>
      )}
      <div className='row' style={{ marginTop: '15px' }}>
        {eapCount !== 0 && (
          <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                  >
                    {eapCount}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Total <br /> Employees
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {activeCount && activeCount !== 0 && (
          <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {activeCount ? activeCount : ''}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Employees with Active Status
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {inactiveCount && inactiveCount !== 0 && (
          <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {inactiveCount ? inactiveCount : ''}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Employees with Inactive Status
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>

      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      {!found ? (
        filterData.length > 0 ? (
          <div className='row' style={{ marginTop: 10 }}>
            <h6>
              Total records :
              <span style={{ marginLeft: '10px' }}>{!count ? <Spin size='small' /> : count}</span>
            </h6>
            <div className='col-12 col-md-12 col-xl-12'>
              <Row gutter={[32, 32]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                  {/* <FlexGridSearch
                    ref={theSearch}
                    placeholder='Search'
                    cssMatch=''
                    style={{ width: '70%', height: '40px' }}
                  /> */}

                  <Search
                    placeholder='Search By Employee Name'
                    enterButton
                    // loading={isLoading}
                    value={newEmp}
                    onSearch={onSearch}
                    onChange={handleChange}
                    size='small'
                    // style={{ width: 304 }}
                  />
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                  <div ref={fixedContainerRef}>
                    <Select
                      style={{ width: 170 }}
                      placeholder={`Items per page : ${pageSize}`}
                      onChange={paggination}
                      options={[
                        { label: 'Items per page : 10', value: 10 },
                        { label: 'Items per page : 20', value: 20 },
                        { label: 'Items per page : 50', value: 50 }
                      ]}
                      getPopupContainer={() => fixedContainerRef.current}
                    />
                  </div>
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#3d586a', float: 'right' }}
                    disabled={isExcelPreparing}
                    onClick={exportToExcel}
                    size='small'
                  >
                    {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                  </Button>
                  <Button
                    size='small'
                    style={{ backgroundColor: '#3d586a', border: 'none',float:"right", marginRight:"11px" }}
                    onClick={() => {
                      setIsLoading(true);
                      setNewEmp('')
                      setLocValue('');
                      setEmailValue('');
                      setDepartmentValue('');
                      setStatusValue('');
                      setEmpNameValue('');
                      setProgramValue('');
                      setSSN('');
                      setEIN('');
                      setDL('');
                      setCount();
                      getEAPPrograms(1, false, true);
                      getEAPPrograms(1, true, true);
                    }}
                  >
                    Reset Filter
                  </Button>
                </Col>
              </Row>

              {/* to export entire data of table */}
              <FlexGrid
                ref={theGrid}
                style={{ display: 'none' }}
                className='dummyWijmoData'
                itemsSource={viewData}
                initialized={gridInitializedData}
                formatItem={formatItem}
                isReadOnly={true}
              >
                <FlexGridColumn
                  binding='employee[1]'
                  header='Employee Name'
                  minWidth={150}
                  width='*'
                />
                <FlexGridColumn binding='newLocation' header='Location' minWidth={150} width='*' />

                <FlexGridColumn binding='newEmail' header='Email' minWidth={300} width='*' />

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    return (
                      <FlexGridColumn
                        binding={
                          value === 'SSN'
                            ? 'newSsn'
                            : value === 'EIN'
                            ? 'newEin'
                            : value === 'Driver License'
                            ? 'newDl'
                            : ''
                        }
                        header={
                          value === 'SSN'
                            ? 'SSN'
                            : value === 'EIN'
                            ? 'EIN'
                            : value === 'Driver License'
                            ? 'Driver License'
                            : ''
                        }
                        // width='12*'
                        minWidth={150}
                        width='*'
                      >
                        <FlexGridCellTemplate
                          cellType='Cell'
                          template={
                            value === 'SSN'
                              ? showSSN
                              : value === 'EIN'
                              ? showEIN
                              : value === 'Driver License'
                              ? showDL
                              : ''
                          }
                        />
                      </FlexGridColumn>
                    );
                  })}

                <FlexGridColumn binding='newPhone' header='Mobile' minWidth={200} width='*' />

                <FlexGridColumn
                  binding='newDepartment'
                  header='Department'
                  // width='12*'
                  minWidth={200}
                  width='*'
                />

                <FlexGridColumn
                  binding='combinedStatusTest'
                  header='Program | Date | Status'
                  // width='10*'
                  minWidth={350}
                  width='*'
                />
              </FlexGrid>

              <FlexGrid
                ref={theGrid}
                id='theGridTallRows'
                className='cliniclisttable'
                itemsSource={view}
                initialized={gridInitialized}
                formatItem={formatItem}
                itemsPerPage={10}
                isReadOnly={true}
                scrollMode='Virtual' // Add this line for smooth scrolling
                //autoGenerateColumns={false}
              >
                <div style={{ display: 'none' }}>
                  <wjInput.ListBox
                    className='column-picker'
                    itemsSource={listboxColumns}
                    checkedMemberPath='visible'
                    displayMemberPath='header'
                    lostFocus={hidePicker}
                    initialized={initializedListBox}
                  ></wjInput.ListBox>
                </div>
                <FlexGridColumn
                  binding='employee[1]'
                  header='Employee Name'
                  // minWidth={180}
                  // width='*'
                  width={160}
                />
                <FlexGridColumn binding='newLocation' header='Location' width={160} />

                <FlexGridColumn binding='newEmail' header='Email' width={240} />

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    return (
                      <FlexGridColumn
                        binding={
                          value === 'SSN'
                            ? 'newSsn'
                            : value === 'EIN'
                            ? 'newEin'
                            : value === 'Driver License'
                            ? 'newDl'
                            : ''
                        }
                        header={
                          value === 'SSN'
                            ? 'SSN'
                            : value === 'EIN'
                            ? 'EIN'
                            : value === 'Driver License'
                            ? 'Driver License'
                            : ''
                        }
                        // width='12*'
                        // minWidth={130}
                        // width='*'
                        width={120}
                      >
                        <FlexGridCellTemplate
                          cellType='Cell'
                          template={
                            value === 'SSN'
                              ? showSSN
                              : value === 'EIN'
                              ? showEIN
                              : value === 'Driver License'
                              ? showDL
                              : ''
                          }
                        />
                      </FlexGridColumn>
                    );
                  })}

                <FlexGridColumn binding='newPhone' header='Mobile' width={125} />

                <FlexGridColumn
                  binding='newDepartment'
                  header='Department'
                  // width='12*'
                  // minWidth={200}
                  // width='*'
                  width={210}
                />

                <FlexGridColumn
                  binding='combinedStatusTest'
                  header='Program | Date | Status'
                  // width='10*'
                  // minWidth={350}
                  // width='*'
                  width={300}
                />

                <FlexGridColumn
                  binding='actions'
                  header='Actions'
                  // width='10*'
                  // minWidth={120}
                  // width='*'
                  width={60}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showActions} />
                </FlexGridColumn>

                <FlexGridFilter
                  showFilterIcons={true}
                  placeholder='Filter data...'
                  defaultFilterType='Value'
                  filterColumns={[
                    'employee[1]',
                    'newLocation',
                    'newEmail',
                    'idType',
                    'newSsn',
                    'newEin',
                    'newDL',
                    'newPhone',
                    'newDepartment',
                    'combinedStatusTest'
                    //'newProgram',
                    //'newStatus'
                  ]}
                />
              </FlexGrid>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div
                  style={{
                    backgroundColor: '#425a81',
                    color: '#fff',
                    display: 'inline',
                    borderRadius: '5px',
                    height: '30px'
                  }}
                >
                  <CollectionViewNavigator
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                    byPage={true}
                    cv={view}
                  />
                </div>
                &nbsp;&nbsp;&nbsp;
                <p
                  style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                  ref={filterRefCounter}
                  id='filterCounter'
                >
                  ({Globalize.format(filterRefCounter.current, 'n0')} )
                </p>
                &nbsp;
                <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                  {' '}
                  out of {totalRecords}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
            )}
          </>
        )
      ) : (
        <div>
          {isLoading ? (
            <Row>
              <Col span={10}></Col>
              <Col span={8}>
                <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
                  <Spin size='large' />
                </Space>
              </Col>
            </Row>
          ) : (
            <center>
              {' '}
              <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
                {/* <i className="icofont-exclamation-tringle" style={{ color: 'red', fontSize: 20 }}></i>&nbsp;&nbsp; */}
                No Record Found !!!
              </p>
            </center>
          )}
        </div>
      )}
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <center>
        {' '}
        <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
          {/* <i className="icofont-exclamation-tringle" style={{ color: 'red', fontSize: 20 }}></i>&nbsp;&nbsp; */}
          {errMsg}
        </p>
      </center>
    </div>
  );
};

export default EAP_Programs;
