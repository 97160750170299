import React from 'react';
import './Actions.scss';
import Notifications from './Notifications';
import SettingsDropdown from './SettingsDropdown';
import SwitchCompany from './SwitchCompany';
import {
  SettingOutlined,
} from '@ant-design/icons';

const Actions = () => {
  return (
    <div className='actions' style={{ backgroundColor: '#425A81', minHeight: 60 }}>
      <Notifications />

      <SwitchCompany />
      
      <a href='#/vertical/profile'>
      <SettingOutlined style={{ color: 'white', fontSize: '18px' }}/>
        {/* <i class='icofont-ui-user' style={{ color: 'white', fontSize: '18px' }}></i> */}
      </a>

      <SettingsDropdown />

     
    </div>
  );
};

export default Actions;
