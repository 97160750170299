import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form, Input, Space, Spin, Row, Col, notification } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';
import setting from '../../config/setting';
import PublicLayout from '../../layout/public/Public';
import { useLocation } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { history } from './../../redux/store';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Item } = Form;

const ChangePassword = () => {
  const location = useLocation();
  const [pass, setPass] = useState('');
  const [passNew, setPassNew] = useState('');
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [invalidCredential, setInvalidCredential] = useState('');
  const [invalidOTPCredential, setInvalidOTPCredential] = useState('');
  const [OTPLoader, setOTPLoader] = useState(false);
  const [otp, setOTP] = useState('');
  const [pwdMatch, setPwdMatch] = useState(false);


  useEffect(() => {
    if (location && location.state) {
      setEmail(location.state.email)
    } else {
      localStorage.clear();
      history.push({ pathname: '/public/sign-in', state: {} });
    }
  }, []);

  
  function password(event) {
    const password = event.target.value;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{12,}$/;
    const isValid = regex.test(password);
    setPass(password);
  
    const error = document.getElementById('passwordError');
    if (isValid) {
      error.style.display = 'none';
    } else {
      error.style.display = 'block';
    }
  }
  function passwordConfirm(event) {
    console.log('event pass', event.target.value);
    setPassNew(event.target.value);
  }

  const [form] = useForm();

  function confirm() {
    if (pass === passNew) {
      setPwdMatch(true);
    } else if (pass == '' || passNew == '') {
      setInvalidCredential('Please enter Password !');
      setPwdMatch(false);
    } 
    else {
      setPwdMatch(false);
      setInvalidCredential("Password doesn't match !");
    }
  }

  async function SubmitOTP() {
    if (email && otp !== '' && pass == passNew) {
      confirm();
      setInvalidOTPCredential('');
      setOTPLoader(true);
      //console.log('forgot otp users :: ');
      await axios
        .post(setting.serverUrl + '/api/VerifyForgotOTP', {
          params: { user: email.trim(), otp: otp.trim(), newPass: passNew.trim() }
        })
        .then(async (response) => {
          console.log('Respone from forgot otp  users', response);

          if (response && response.status == 200) {
            if (response.data && response.data.status && response.data.status == 'Done') {
              history.push({ pathname: '/public/sign-in' });
              notification.success({
                message: 'Your password has been changed successfully !'
              });
              setPass('');
              setPassNew('');
              setOTP('')
            } else if (
              response &&
              response.data &&
              response.data.error &&
              response.data.error == 'OTP does not match.'
            ) {
              setOTPLoader(false);
              setInvalidOTPCredential('OTP does not match.');
            }
          } else if (response && response.status == 201) {
            setOTPLoader(false);
            setInvalidOTPCredential('Some technical issue. Please try again later!');
          }
        });
    } else {
      setOTPLoader(false);
      setInvalidCredential("Password doesn't match !");
    }
  }

  function setOTPVal(event) {
    console.log('event otp', event.target.value);
    setOTP(event.target.value);
  }

  return (
    <>
      <Row
        style={{
          height: '100%',
          backgroundImage: `url(${require('./newBgPlane.jpg')})`,
          backgroundSize: 'cover'
        }}
      >
        <Col
          xl={{ span: 7 }}
          lg={{ span: 9 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ height: '100%' }}
        >
          <div>
            <PublicLayout>
              <h4 className='mt-0 mb-1'>WSS Change Password </h4>
              <br />
              <p id="passwordError" style={{ color: 'red', display: 'none' }}>Password must be at least 12 characters and contain at least one uppercase letter, one lowercase letter, one number, and one symbol</p>


              {invalidCredential !== '' ? (
                <span style={{ color: 'red', marginBottom: 10 }}>{invalidCredential}</span>
              ) : null}
              {invalidOTPCredential !== '' ? (
                <span style={{ color: 'red', marginBottom: 10 }}>{invalidOTPCredential}</span>
              ) : null}
              <Form form={form} layout='vertical' className='mb-4'>
                {}
                <p style={{ color: '#161717', paddingBottom: 0, marginBottom: 10 }}>
                  Enter new password{' '}
                </p>
                <Item
                  name='passwordNew'
                  rules={[{ required: true, message: <>Please fill value !</> }]}
                >
                  <Input.Password placeholder='Password' type='password' onChange={password} 
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                  style={{ backgroundColor: 'white',  WebkitBoxShadow: "0 0 0 1000px white inset !important "}}/>
                </Item>
                <p style={{ color: '#161717', paddingBottom: 0, marginBottom: 10 }}>
                  Confirm password{' '}
                </p>
                <Item
                  name='password'
                  rules={[{ required: true, message: <>Please fill value !</> }]}
                  
                >
                  <Input.Password
                    placeholder='Confirm Password'
                    type='password'
                    onChange={passwordConfirm}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                  style={{ backgroundColor: 'white',  WebkitBoxShadow: "0 0 0 1000px white inset !important "}}
                  />
                </Item>
                <p style={{ color: '#161717', paddingBottom: 0, marginBottom: 10 }}>
                  OTP for secure login on WSS Portal
                </p>
                <Item name='login' rules={[{ required: true, message: <>Please fill value !</> }]}>
                  <Input placeholder='Enter OTP' onChange={setOTPVal} />
                </Item>
                <Button
                  block={false}
                  type='primary'
                  onClick={SubmitOTP}
                  htmlType='submit'
                  icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
                >
                  Submit
                </Button>
                &nbsp;&nbsp;&nbsp;
                {OTPLoader && (
                  <Space size='middle'>
                    <Spin size='large' />
                  </Space>
                )}
              </Form>
            </PublicLayout>
          </div>
        </Col>
        <Col
          xl={{ span: 17 }}
          lg={{ span: 15 }}
          md={{ span: 12 }}
          sm={{ span: 0 }}
          xs={{ span: 0 }}
        >
          <h3
            style={{
              color: 'white',
              position: 'absolute',
              top: '48%',
              left: '0px',
              width: '100%',
              display: 'table',
              textAlign: 'center'
            }}
          >
            INCREASING SAFETY AND PRODUCTIVITY, REDUCING LIABILITY.™
          </h3>
        </Col>
      </Row>
    </>
  );
};

export default ChangePassword;
