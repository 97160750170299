import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form, Input, Space, Spin, Row, Col, Tooltip, Modal, Table } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';
import setting from '../../config/setting';
import PublicLayout from '../../layout/public/Public';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { navigateHome } from '../../utils/naviagate-home';
import { history } from './../../redux/store';
import LogoSvg from './../../assets/img/company.png';

const { Item } = Form;

const VerifyOTP = () => {
  const location = useLocation();
  const [name, setName] = useState('');
  const [pass, setPass] = useState();
  const [allData, setAllData] = useState([]);
  const [CompDataNew, setCompDataNew] = useState([]);
  const [CompDataNewCopy, setCompDataNewCopy] = useState([]);
  const [CompDataNewTrue, setCompDataNewTrue] = useState(false);
  const [loader, setLoader] = useState(false);
  const [invalidCredential, setInvalidCredential] = useState('');
  const [otp, setOTP] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [logoVal, setLogo] = useState({});
  const [enableOtp, setEnableOtp] = useState(false);
  const [searchFieldVal, searchField] = useState('');

  useEffect(() => {
    if (location && location.state) {
      setName(location.state['email']);
      setPass(location.state['pass']);
    } else {
      localStorage.clear();
      history.push({ pathname: '/public/sign-in', state: {} });
    }
  }, []);

  function combineLogoRequests(data) {
    let requests = [];

    let resultIds = data.map((a) => a.id);
    {
      requests.push(
        axios.post(setting.serverUrl + '/api/getAllCompLogo', {
          params: {
            email: localStorage.getItem('myData'),
            user: localStorage.getItem('myData'),
            pass: localStorage.getItem('myPass'),
            custId: resultIds
          }
        })
      );
    }

    return requests;
  }
  function getCompanyLogo(data) {
    setLoader(true);
    axios.all(combineLogoRequests(data)).then(
      axios.spread((...responses) => {
        let logos = {};
        let responseUpdated = responses && responses[0] && responses[0].data;
        responseUpdated.map((val) => {
         return val && (logos[val.comapnyId] = val.logodata);
        });
        setLoader(false);
        localStorage.setItem('logos', JSON.stringify(logos));
        setLogo(logos);
      })
    );
  }
  function getTypeCheckId(companyId) {
    axios
      .post(setting.serverUrl + '/api/getIdCheckType', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyId
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          let result = response && response.data && response.data.rows;
          let typevalue = result?.map((val) => {
            return val.id_type;
          });
          localStorage.setItem('IdtypeCheckValue', JSON.stringify(typevalue));
        }
      });
  }
  function getNavTabsCheck(customerId) {
    axios
      .post(setting.serverUrl + '/api/getNavbarTabs', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: customerId
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          let result = response && response.data;
          localStorage.setItem('NavTabCheck', JSON.stringify(result));
        }
      });
  }

  async function SubmitOTP() {
    setEnableOtp(true);
    if (name && pass && otp) {
      setInvalidCredential('');
      setLoader(true);
      await axios
        .post(setting.serverUrl + '/api/VerifyOTP', {
          params: { user: name, pass: pass, otp: otp }
        })
        .then(async (response) => {
          if (response && response.status == 200) {
            if (
              response &&
              response.data &&
              response.data.error &&
              response.data.error == 'OTP does not match.'
            ) {
              setLoader(false);
              setInvalidCredential('OTP does not match.');
              setEnableOtp(false);
            } else {
              localStorage.setItem('myData', name);
              localStorage.setItem('myPass', pass);

              await axios
                .post(setting.serverUrl + '/api/CustomerDataApp', {
                  params: {
                    email: name,
                    user: name,
                    pass: pass
                  }
                })
                .then((response) => {
                  if (response && response.status == 200) {
                    if (response.data && response.data.length == 0) {
                      alert(
                        'Access Denied! You are not associated to any company. Please contact to administrator.'
                      );
                      history.push({ pathname: '/public/sign-in' });
                      setEnableOtp(true);
                    } else {
                      setEnableOtp(false);
                      let result = response.data;
                      let finalResult = result;

                      setCompDataNew(finalResult);
                      setCompDataNewCopy(finalResult);
                      setCompDataNewTrue(!CompDataNewTrue);
                      setLoader(false);
                      if (response && response.data && response.data.length > 1) {
                        setShowModal(true);
                        // getCompanyLogo(response.data);
                        getCompanyLogo(finalResult);
                        let filterIdName = finalResult.map((x) => {
                          return { id: x.id, name: x.name };
                        });
                        localStorage.setItem('customerCompanies', JSON.stringify(filterIdName));
                      } else {
                        localStorage.setItem(
                          'customerId',
                          finalResult && finalResult[0] && finalResult[0].id
                        );
                        localStorage.setItem('customerData', JSON.stringify(finalResult[0]));
                        localStorage.setItem(
                          'displayName',
                          finalResult && finalResult[0] && finalResult[0].name
                        );
                        getTypeCheckId(finalResult && finalResult[0] && finalResult[0].id);
                        // const secondApiResponse = await getTypeCheckIdSingleComp( finalResult && finalResult[0] && finalResult[0].id);
                        getNavTabsCheck(finalResult && finalResult[0] && finalResult[0].id);
                        form
                          .validateFields()
                          .then(() => navigateHome())
                          .catch(() => null);
                      }
                    }
                  }
                });
            }
          } else {
            setLoader(false);
            setInvalidCredential('Some technical issue. Please try again later!');
            setEnableOtp(false);
          }
        });
    } else {
      setLoader(false);
      setInvalidCredential('Please enter OTP!');
      setEnableOtp(false);
    }
  }

  function setOTPVal(event) {
    setOTP(event.target.value);
  }

  const [form] = useForm();

  const updateSelectedCompany = (data) => {
    localStorage.setItem('customerId', data.id);
    localStorage.setItem('customerData', JSON.stringify(data));
    localStorage.setItem('displayName', data.name);
    localStorage.setItem('companyLogo', logoVal[data.id]);
    getTypeCheckId(data.id);
    getNavTabsCheck(data.id);

    form
      .validateFields()
      .then(() => navigateHome())
      .catch(() => null);
  };
  const actions = (data) => (
    <div className='buttons-list nowrap'>
      <Tooltip title='Go to '>
        <Button
          onClick={() => updateSelectedCompany(data)}
          type='primary'
          style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            color: 'rgb(27,57,105)'
          }}
        >
          {/* <img src={goIcon} alt='' /> */}
          <span>
            <LoginOutlined style={{ fontSize: '1.3rem' }} />
          </span>
        </Button>
      </Tooltip>
    </div>
  );
  const columns = [
    {
      title: 'Company Logo',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        //console.log("element", element)
        <p>
          {logoVal[id] === undefined ? (
            <img
              //src={logoVal[element.id]}
              src={LogoSvg}
              style={{ width: 100, zIndex: 1 }}
              alt='company Logo &nbsp;&nbsp;&nbsp;&nbsp;'
            />
          ) : (
            <img
              src={logoVal[id]}
              //src={LogoSvg}
              style={{ width: 100, zIndex: 1 }}
              alt='company Logo &nbsp;&nbsp;&nbsp;&nbsp;'
            />
          )}
        </p>
      )
    },
    {
      title: 'Company Name',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <p>{name}</p>
    },
    {
      title: 'Location',
      dataIndex: 12,
      key: 12,
      //width:100,
      render: (text, record) => (
        <span className='nowrap'>
          {record
            ? (record.street === false ? '' : record.street) +
              (record.street2 === false ? '' : ', ' + record.street2) +
              (record.city === false ? '' : ', ' + record.city) +
              (record.state_id === false || record.state_id.length == 0
                ? ''
                : ', ' + record.state_id[1].replace(' (US)', '')) +
              (record.zip === false || record.zip === 0 ? '' : ', ' + record.zip)
            : null}
        </span>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: actions
      // render: (_, record) => (
      //   <Space size="middle">
      //     <a>Goto {record.name}</a>

      //   </Space>
      // ),
    }
  ];
  return (
    <>
      <Row
        style={{
          height: '100%',
          backgroundImage: `url(${require('./newBgPlane.jpg')})`,
          backgroundSize: 'cover'
        }}
      >
        <Col
          xl={{ span: 7 }}
          lg={{ span: 9 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ height: '100%' }}
        >
          <div>
            <Modal
              visible={showModal}
              width={700}
              //bodyStyle={{height: "650px"}}
              //height={100}
              maskClosable={false}
              closable={false}
              // onCancel={() => setShowModal(false)}
              footer={null}
            >
              <div>
                <>
                  <Input
                    style={{ backgroundColor: 'white', marginTop: 30 }}
                    placeholder='Type to search...'
                    suffix={<span className='icofont icofont-search' />}
                    value={searchFieldVal}
                    onChange={(e) => {
                      searchField(e.target.value);
                      if (e.target.value.length > 0) {
                        let datArr = CompDataNewCopy.filter(
                          (val) =>
                            (val.name.length > 0 &&
                              val.name.toLowerCase().includes(e.target.value.toLowerCase())) ||
                            (val.contact_address &&
                              val.contact_address
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase()))
                        );
                        console.log('datArr', datArr);
                        setCompDataNew(datArr);
                      } else {
                        console.log('e.target.value.length > 0', e.target.value.length);
                        console.log('driverEvent:', CompDataNewCopy);
                        setCompDataNew(CompDataNewCopy);
                      }
                    }}
                  />
                  <br />
                  {!loader ? (
                    CompDataNew.length > 0 ? (
                      <>
                        <Table
                          columns={columns}
                          dataSource={CompDataNew}
                          pagination={{
                            // pageSize:
                            // hideOnSinglePage: false,
                            showSizeChanger: true
                          }}
                        />
                      </>
                    ) : (
                      <h5 style={{ color: '#ef6727' }}>No Record found!</h5>
                    )
                  ) : (
                    <Row>
                      <Col span={10}></Col>
                      <Col span={8}>
                        <Space size='middle' style={{ marginLeft: 45 }}>
                          <Spin size='large' />
                        </Space>
                      </Col>
                    </Row>
                  )}
                </>
              </div>
            </Modal>

            <PublicLayout
            // bgImg={require('./wss.png')}
            >
              <h4 className='mt-0 mb-1'>WSS Login</h4>

              <p
                style={{ color: '#161717', paddingBottom: 0, marginBottom: 10 }}
                //className='text-color-200'
              >
                OTP for secure login on WSS Portal
                {/* We sent you an email with OTP number for secure login. Please enter the number you received in your email. */}
              </p>
              {invalidCredential !== '' ? (
                <span style={{ color: 'red', marginBottom: 10 }}>{invalidCredential}</span>
              ) : null}
              <Form form={form} layout='vertical' className='mb-4'>
                <Item name='otp' rules={[{ required: true, message: <></> }]}>
                  <Input placeholder='Enter OTP' onChange={setOTPVal} />
                </Item>
                <Button
                  block={false}
                  type='primary'
                  onClick={SubmitOTP}
                  htmlType='submit'
                  disabled={enableOtp}
                  icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
                >
                  Verify OTP
                </Button>
                &nbsp;&nbsp;&nbsp;
                {loader && (
                  <Space size='middle'>
                    <Spin size='large' />
                  </Space>
                )}
              </Form>
            </PublicLayout>
          </div>
        </Col>
        <Col
          xl={{ span: 17 }}
          lg={{ span: 15 }}
          md={{ span: 12 }}
          sm={{ span: 0 }}
          xs={{ span: 0 }}
        >
          <h3
            style={{
              color: 'white',
              position: 'absolute',
              top: '48%',
              left: '0px',
              width: '100%',
              display: 'table',
              textAlign: 'center'
            }}
          >
            INCREASING SAFETY AND PRODUCTIVITY, REDUCING LIABILITY.™
          </h3>
        </Col>
      </Row>
    </>
  );
  // }
};

export default VerifyOTP;
