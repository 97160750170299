import React, { useEffect, useState, useRef } from 'react';
import { Spin, Space, Row, Col, Typography, Button } from 'antd';
import moment from 'moment';
import axios from 'axios';
import _ from 'underscore';
import setting from '../../../config/setting';
import LogoSvg from './../../../assets/img/company.png';
import * as wjcCore from '@grapecity/wijmo';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { FlexGridDetail } from '@grapecity/wijmo.react.grid.detail';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { useHistory, useLocation } from 'react-router-dom';
import { CheckSquareTwoTone } from '@ant-design/icons';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { date } from 'yup';
import { FolderOpenTwoTone } from '@ant-design/icons';

var dashboard_permission = [];
var dashboard_permissions = [];
var isInit = false;

wjcCore.setLicenseKey(
  'customerportal.wss-corp.com,926535591656245#B0BHnhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnplQroGe0t6SNZkUURzQON6Yj5WQr84UVVmUxIjMXdHRRBjbkNVdYhUaXpkcsdmVPVlN6k4YjZmSUhGWNpnY5ZkUl3GVmZjMS5UVuRDb7lWVY3EOlZHduNTa9ZjYZllb5NmTlBFaaVWSjFHMPFnehp6VNZzUqJVczVENG9EcztkQURzQSBnejFzTykjUuRlWKZzLwZEMGNTTNFDbZNVMTJDVihlaxYjQWZ4ZxtkQs9kRMRlcxQ5LstERSdlYZJmSYdma8olWSJVYRJUdypXOwRjcVpnY6AjRQV5R7Nme6dlQwdGTpNVOW3iSsNGV5gja6kDTq3UY9MmbpxWdFZme7BHZSZET4JXUqlEclJ5Z424TxhnZEFXN8Njc0J6SysyLvVkcMJGaPdDVZd5SmhFbyREdKZndNB7QTplQ5NmdENlMqBFU4Q5avt6cjlmN82GMxZzYrlFbiojITJCLiIUNwIER4EzNiojIIJCLzkTOxgTMwYDO0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI7UzMzUDMgATM4AzMyAjMiojI4J7QiwiIt36YuAncvNWLzN7duwWY4J7bwJXZt3GdzV7YiojIz5GRiwiIlRWesdEIsFGdpdWaEJiOiEmTDJCLiUDNyYTN6ETO5UzM5YjM9IiOiQWSiwSfdtlOicGbmJCLiIjdxIDMyIiOiIXZ6lCjl'
);

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'latest_scheduled_events.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const DashboardPage = () => {
  let loginCustomerData = JSON.parse(localStorage.getItem('customerData'))
    ? JSON.parse(localStorage.getItem('customerData'))
    : '';
  console.log('loginCustomerData====', loginCustomerData);
  // const localStorageDashboardPermission = JSON.parse(localStorage.getItem('dashboard_permision'))
  //   ? JSON.parse(localStorage.getItem('dashboard_permision'))
  //   : '';
  const localStorageDashboardPermission = (() => {
    const storedData = localStorage.getItem('dashboard_permision');

    try {
      return JSON.parse(storedData) || [];
    } catch (error) {
      return [];
    }
  })();

  const [change, setChange] = useState(false);
  const [event, setEvent] = useState(0);
  const [openevent, setOpenEvent] = useState(0);
  const [randomPool, setRandomPool] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [EmployeeCountVaccinated, setEmployeeCountVaccinated] = useState([]);
  const [notLength, setNotLength] = useState(0);
  const [EmployeeCount, setEmployeeCount] = useState(0);
  const [EmployeeAllCount, setEmployeeAll] = useState(0);
  const [activeEmployees, setActiveEmployees] = useState(0);
  const [CovidTestDataPendingVal, setCovidTestDataPendingVal] = useState(0);
  const [DataEmployeeCust, setDataEmployeeCust] = useState([]);
  const [DataEmployeeCustTrue, setDataEmployeeCustTrue] = useState(false);
  const [DataEmployeeCustTrueNN, setDataEmployeeCustTrueNN] = useState(false);
  const [CovidTestDataPendingValTrue, setCovidTestDataPendingValTrue] = useState(false);
  const [logoVal, setLogo] = useState('');
  const [TableVaccData, setTableVaccData] = useState([]);
  const [CovidVaccinationData, setCovidVaccinationData] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [NotLengthTrue, setNotLengthTrue] = useState(false);
  const [EventTrue, setEventTrue] = useState(false);
  const [CovidTestDataPendingValTrue1, setCovidTestDataPendingValTrue1] = useState(false);
  const [EmployeeCountTrue, setEmployeeCountTrue] = useState(false);
  const [EmployeeCountVaccinatedTrue, setEmployeeCountVaccinatedTrue] = useState(false);
  const [TotalEmpOneVaccTrue, setTotalEmpOneVaccTrue] = useState(false);
  const [covidExemptionDataCounts, setCovidExemptionDataCounts] = useState([]);
  const [covidVulnerDataCounts, setCovidVulnerDataCounts] = useState([]);
  const [primary_contact_val, setPrimaryContact] = useState([]);
  const [secondary_contact_val, setSecondaryContact] = useState([]);
  const [cardLoader, setCardLoader] = useState(false);
  const [covidVulnerDataCountsTrue, setcovidVulnerDataCountsTrue] = useState(false);
  const [count, setCount] = useState([]);
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [dash, setDash] = useState([]);
  const [updatedCardLoader, setUpdatedCardLoader] = useState(false);
  const [testEvents, setTestEvents] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const [showHideAdvFilter, setShowHideAdvFilter] = useState([]);
  const [testEventTable, setTestEventTableData] = useState([]);
  const [testEventTableBKP, setTestEventTableDataBKP] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [clearanceUrl, setclearanceUrl] = useState('');
  // search filters
  const [hasPermission, setHasPermission] = useState(false);
  const [testEventReports, setTestEventsReports] = useState([]);
  const [wijmoTableLoader, setWijmoTableLoader] = useState(false);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [docPermission, setDocPermission] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [scheduledCount, setScheduledCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [attachments, setAttachments] = useState([]);

  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const filterRefCounter = useRef();

  useEffect(() => {
    dashboard_permission = [];
    dashboard_permissions = [];
    const a = localStorage.getItem('dashboard_status');
    if (JSON.parse(a)) {
      isInit = false;
      localStorage.setItem('dashboard_status', JSON.stringify(false));
    }

    getCompanyLogo();
    notifications();
    getTestEvents();
    getCovidTesting();
    getAllEmp();
    getVaccination();
    getVaccinationCounts();
    getExemptionCounts();
    getVulnerableCounts();
    getOpenEvents();
    getCompAddress();
    // getRandomPoolPercentage();
    //getLocationsCustomerData(loginCustomerData);

    isInit = false;

    return () => {
      console.log('Component will unmount.');
    };
  }, []);

  useEffect(() => {
    createTable();
  }, [employees, testEvents, testDetails]);

  useEffect(() => {
    if (!isInit) {
      setTimeout(() => {
        if (dashboard_permission.length === 9 || dashboard_permissions.length === 9) {
          const callFuc = async () => {
            isInit = true;
            await axios
              .post(setting.serverUrl + '/api/userDashboardPermissionShow', {
                params: {
                  email: localStorage.getItem('myData'),
                  user: localStorage.getItem('myData'),
                  pass: localStorage.getItem('myPass'),
                  compId: JSON.parse(localStorage.getItem('customerData')).id,
                  dashboardPermission: dashboard_permission
                }
              })
              .then(async (response) => {
                if (response && response.data && response.data.length > 0 && response.data[0]) {
                  dashboard_permissions = response.data[0].permissions;
                  setDash(
                    response &&
                      response.data &&
                      response.data.length > 0 &&
                      response.data[0] &&
                      response.data[0].permissions
                  );
                  console.log('dashboard_permissions=======', dashboard_permissions);
                  console.log('Before stringification:', dashboard_permissions);
                  localStorage.setItem(
                    'dashboard_permision',
                    JSON.stringify(dashboard_permissions)
                  );
                  console.log(
                    'After stringification:',
                    localStorage.getItem('dashboard_permision')
                  );
                }
              });
          };
          callFuc();
        }
      }, 500);
    }
  });
  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }
  function getLocationsCustomerData(data) {
    setCardLoader(true);
    axios
      .post(setting.serverUrl + '/api/locationsCustomerData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          id: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        let dataArr = [];

        _.each(data.child_ids, (child) => {
          console.log('child Ids - ', child);
          _.each(response.data, (allCustList) => {
            if (child == allCustList.id) {
              dataArr.push(allCustList);
            }
          });
        });
        setCount(dataArr);
        setCardLoader(false);
        let primary_contact = dataArr.filter((x) => x.der_categorys == 'primary');
        let secondary_contact = dataArr.filter((x) => x.der_categorys == 'secondary');
        console.log('p', primary_contact);
        console.log('secondary======', secondary_contact);
        // setPrimaryContact(primary_contact);
        // setSecondaryContact(secondary_contact);
      });
  }
  function getRandomPoolPercentage() {
    axios
      .post(setting.serverUrl + '/api/getRandomPoolPercentage', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        console.log('Respone from getRandomPoolPercentage ::::', response.data);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        setRandomPool(response.data.value);
        // dashboard_permission.push({title:'Random pool %',count:response.data.value,randomPool:response.data.value,status:true})
      });
  }

  function getOpenEvents() {
    axios
      .post(setting.serverUrl + '/api/getOpenEvents', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        console.log('Respone from getOpenEvents ::::', response.data);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        let countValue = response?.data?.value;
        setOpenEvent(countValue);

        let openEventStatus = false;
        if (
          localStorageDashboardPermission != '' &&
          Array.isArray(localStorageDashboardPermission)
        ) {
          const statusLen = localStorageDashboardPermission.filter(
            (val) =>{
             return val?.title == 'Open Events' && val?.status
            } 
          );
          if (statusLen && statusLen.length > 0) {
            openEventStatus = true;
          } else {
            openEventStatus = false;
          }
        }
        dashboard_permission.push({
          title: 'Open Events',
          count: countValue,
          openEvent: countValue,
          // status: countValue > 0 && countValue !== null ? true : false
          status: openEventStatus
        });
      });
  }
  function getCompAddress() {
    setCardLoader(true);

    axios
      .post(setting.serverUrl + '/api/showDashboardCardAddress', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        console.log('Respone from new address api ::::', response.data);
        setPrimaryContact(response.data.primaryAddress);
        setSecondaryContact(response.data.secondaryAddress);
        setCardLoader(false);
      });
  }

  const getVulnerableCounts = () => {
    //setChange(true);
    console.log('inside vulnerable counts');
    axios
      .post(setting.serverUrl + '/api/Covid_VulnerableCountsDashboard', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data) {
          console.log('inside resp from getVulnerableCounts', response.data);

          let VulnerableStatus = false;
          if (
            localStorageDashboardPermission != '' &&
            Array.isArray(localStorageDashboardPermission)
          ) {
            const statusLen = localStorageDashboardPermission.filter(
              (val) => val?.title == 'Vulnerable Employees' && val?.status
            );
            if (statusLen && statusLen.length > 0) {
              VulnerableStatus = true;
            } else {
              VulnerableStatus = false;
            }
          }

          if (response.data.error) {
            alert('Some technical error occurred. Please try again in a bit.');
          }
          if (
            response.status === 200 &&
            response.data !== 'Blocked' &&
            response.data != undefined
          ) {
            console.log('Covid Vulnerable Counts data: ', response.data);
            setCovidVulnerDataCounts(response.data);
            dashboard_permission.push({
              title: 'Vulnerable Employees',
              count: response.data.emp_app_count,
              covidVulnerDataCounts: response.data.emp_app_count,
              // status: response.data.emp_app_count > 0 && response.data.value !== null ? true : false
              status: VulnerableStatus
            });
            setcovidVulnerDataCountsTrue(true);
          } else {
            console.log('Covid Vulnerable Counts data: ', response.data);
            setCovidVulnerDataCounts(0);
            dashboard_permission.push({
              title: 'Vulnerable Employees',
              count: 0,
              covidVulnerDataCounts: 0,
              // status: false
              status: VulnerableStatus
            });
            setcovidVulnerDataCountsTrue(true);
          }
        }
      });
  };

  const getExemptionCounts = () => {
    //setChange(true);
    console.log('inside getExemptionCounts');
    axios
      .post(setting.serverUrl + '/api/getCovidExemptionCountsDashboard', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data) {
          console.log('inside resp of getExemptionCounts ', response.data);

          let ExemptedStatus = false;
          if (
            localStorageDashboardPermission != '' &&
            Array.isArray(localStorageDashboardPermission)
          ) {
            const statusLen = localStorageDashboardPermission.filter(
              (val) => val?.title == 'Exempted Employees' && val?.status
            );
            if (statusLen && statusLen.length > 0) {
              ExemptedStatus = true;
            } else {
              ExemptedStatus = false;
            }
          }

          if (response.data.error) {
            alert('Some technical error occurred. Please try again in a bit.');
          }
          if (
            response.status === 200 &&
            response.data &&
            response.data !== 'Blocked' &&
            response.data.emp_app_count != undefined
          ) {
            console.log('Covid Exemption Counts data: ', response.data);
            setCovidExemptionDataCounts(response.data.emp_app_count);
            dashboard_permission.push({
              title: 'Exempted Employees',
              count: response.data.emp_app_count,
              covidExemptionDataCounts: response.data.emp_app_count,
              // status: response.data.emp_app_count ? 0 && response.data.value !== null > true : false
              status: ExemptedStatus
            });
            setTotalEmpOneVaccTrue(true);
          } else {
            console.log('Covid Exemption Counts data: ', response.data);
            setCovidExemptionDataCounts(0);
            dashboard_permission.push({
              title: 'Exempted Employees',
              count: 0,
              covidExemptionDataCounts: 0,
              // status: false
              status: ExemptedStatus
            });
            setTotalEmpOneVaccTrue(true);
          }
        }
      });
  };

  const getVaccinationCounts = () => {
    //setChange(true);
    console.log('inside covid vaccination dashboard');
    axios
      .post(setting.serverUrl + '/api/getCovidVaccinationCountsDashboard', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        console.log('Covid Vaccination counts data: ', response);
        //setChange(false);

        let VaccinatedStatus = false;
        if (
          localStorageDashboardPermission != '' &&
          Array.isArray(localStorageDashboardPermission)
        ) {
          const statusLen = localStorageDashboardPermission.filter(
            (val) => val?.title == 'Employee Vaccinated' && val?.status
          );
          if (statusLen && statusLen.length > 0) {
            VaccinatedStatus = true;
          } else {
            VaccinatedStatus = false;
          }
        }

        if (response && response.data) {
          console.log('inside Vaccination counts data: ', response.data);
          if (response.data.error) {
            alert('Some technical error occurred. Please try again in a bit.');
          }
          if (
            response.status == 200 &&
            response.data &&
            response.data != 'Blocked' &&
            response.data.emp_app_count != undefined
          ) {
            setEmployeeCountVaccinated(response.data);
            console.log('inside the not block condition of emp vaccinated', response.data);
            dashboard_permission.push({
              title: 'Employee Vaccinated',
              count: response?.data?.emp_app_count ? response?.data?.emp_app_count : 0,
              EmployeeCountVaccinated: response.data.emp_app_count,
              // status: response.data.emp_app_count > 0 && response.data.value !== null ? true : false
              status: VaccinatedStatus
            });
            setEmployeeCountVaccinatedTrue(true);
          } else {
            setEmployeeCountVaccinated(0);
            dashboard_permission.push({
              title: 'Employee Vaccinated',
              count: 0,
              EmployeeCountVaccinated: 0,
              // status: false
              status: VaccinatedStatus
            });
            setEmployeeCountVaccinatedTrue(true);
          }
        } else {
          console.log('inside the  block condition of emp vaccinated');
          dashboard_permission.push({
            title: 'Employee Vaccinated',
            count: 0,
            EmployeeCountVaccinated: 0,
            // status: false
            status: VaccinatedStatus
          });
        }
      });
  };
  console.log('employeeVaccinated', EmployeeCountVaccinated);
  const getVaccination = () => {
    setNoRecordFound(true);
    axios

      .post(setting.serverUrl + '/api/latestScheduledEvents', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status === 200) {
          console.log('Respone from getData test events ', response.data);
          setTestEvents(
            response &&
              response.data &&
              response.data.testEvent &&
              response.data.testEvent.length > 0
              ? response.data.testEvent
              : []
          );
          setShowHideAdvFilter(
            response &&
              response.data &&
              response.data.testEvent &&
              response.data.testEvent.length > 0
              ? response.data.testEvent
              : []
          );
          let testDetailRes =
            response &&
            response.data &&
            response.data.testDetail &&
            response.data.testDetail.length > 0
              ? response.data.testDetail[0]
              : [];
          let attachmentRes =
            response &&
            response.data &&
            response.data.attachments &&
            response.data.attachments.length > 0 &&
            response.data.attachments[0].rows;

          let result = testDetailRes.map((test) => {
            let attachment = attachmentRes.filter((attach) => attach.class_id == test.id);
            if (attachment.length > 0) {
              return {
                ...test,
                ...attachment[0]
              };
            } else {
              return test;
            }
          });
          setTestDetails(result);
          setEmployees(
            response &&
              response.data &&
              response.data.employees &&
              response.data.employees.length > 0
              ? response.data.employees[0]
              : []
          );
          setNoRecordFound(false);
          setChange(true);
          setAttachments(attachmentRes);
          setHasPermission(true);
          setLoadMoreLoader(false);
          setWijmoTableLoader(false);
          //setErrMsg('Module not activated.');
        } else if (response && response.status === 202) {
          setNoRecordFound(false);
          setChange(true);
          setHasPermission(true);
          setLoadMoreLoader(false);
          setWijmoTableLoader(false);
          //setWijmoFlexGrid([], 10);
        }
      });
    // .catch((error) => {
    //   notification['error']({
    //     message: 'Error',
    //     description: 'Failed to load covid vaccination data'
    //   });
    // });
  };

  const createTable = () => {
    let tableData = [];
    console.log('Test Event: ', testEvents);
    console.log('Test Detail: ', testDetails);
    console.log('Employees: ', employees);
    console.log('Expandable mro', testEventReports);
    // if (employees.length == 0 && change) {
    //   console.log('NO RECORD FOUND: ', employees.length);
    //   setNoRecordFound(true);
    // } else {
    let i = 1;
    console.log('check test events data', testEvents);
    testEvents &&
      testEvents.length > 0 &&
      testEvents.map((event) => {
        console.log('inside map fucntion', testEvents, testDetail);
        var testDetail = testDetails.filter((data) => data.event_id === event.id);
        console.log('testDetail matched id===', testDetail);
        var selectedEmp = [];

        testDetail.map((td) => {
          if (!selectedEmp.includes(td.emp_selected[0])) {
            selectedEmp.push(td.emp_selected[0]);
          }
        });

        selectedEmp.map((e) => {
          var eventData = {
            id: event.id,
            rowid: i,
            company: event.company,
            location: event.child_ids,
            dateStarted: event.scheduled_date,
            type_of_events: event.type_of_events,
            completedEventDate: event.event_completed_date,
            dateCompleted: event.completed_on,
            urgency_level: event.urgency_level,
            reason: event.reasons,
            packageName: event.service_multi_select,
            event_status: event.event_status,
            mode_dot: event.mode_dot,
            observed_Collection: event.observed_Collection,
            auth_form_pdf: event.auth_form_pdf,
            event_notes: event.event_notes,
            testDetail: [],
            employee: []
          };

          let empData = employees.filter((emp) => emp.id === e)[0];
          let empTestDetails = testDetail.filter((td) => td.emp_selected[0] === e);

          if (empData) {
            eventData.testDetail = empTestDetails;
            eventData.employee = empData;
            tableData.push(eventData);
            setNoRecordFound(false);
            i++;
          }
        });
      });
    // }

    let filteredScheduled = tableData.filter((k) => {
      return k.event_status == 'Scheduled';
    });
    let filteredCompleted = tableData.filter((k) => {
      return k.event_status == 'Completed';
    });
    // console.log('filteredScheduled', filteredScheduled.length, filteredCompleted.length);
    tableData.map((x) => (x.name = x.employee.first_name + ' ' + x.employee.last_name));
    console.log('table data', tableData);

    tableData.sort((a, b) => {
      const dateA = new Date(a.newDateCompleted);
      const dateB = new Date(b.newDateCompleted);
      return dateB - dateA;
    });

    var ress = tableData.map((val) => {
      return {
        ...val,
        newDOB: moment(val.employee.dob).format('MM/DD/YYYY'),
        newDateStarted: val.dateStarted ? val.dateStarted : '',
        newDateStartedforExcel: val.dateStarted ? moment(val.dateStarted).format('MM/DD/YYYY') : '',
        newDateCompleted:
          val.completedEventDate !== false
            ? moment(val.completedEventDate).format('MM/DD/YYYY')
            : '',
        newReason: val.reason != false ? val.reason : '',
        newStatus: val.event_status != false ? val.event_status : '',
        newLocation:
          val.location && val.location.length > 0
            ? val.location[1].includes('|')
              ? val.location[1].split('|')[1]
              : val.location[1].includes(',')
              ? val.location[1].split(',')[1]
              : val.location[1]
            : '',
        newService: val.packageName[1],
        newEin: val.employee.emp_ein != false ? val.employee.emp_ein : '',
        newSsn: val.employee.emp_ssn != false ? val.employee.emp_ssn : ''
        // testDetail: val.testDetail.map((val) => {
        //   return {
        //     ...val,
        //     completedOn:
        //       val.completed_on !== false ? moment(val.completed_on).format('MM/DD/YYYY') : '',
        //     final_result: val.final_result !== false ? val.final_result : ''
        //   };
        // })
      };
    });

    setTestEventTableData(ress);
    setTestEventTableDataBKP(ress);
    console.log('wijmo ress', ress);
    const collectionView1 = new CollectionView(ress, {
      pageSize: ress.length
    });
    setViewData(collectionView1);
    const collectionView2 = new CollectionView(ress, {
      pageSize: pageSize,
      collectionChanged: (s) => {
        filterRefCounter.current = s.totalItemCount;
        var filterCounter = document.getElementById('filterCounter');
        if (filterCounter) {
          filterCounter.textContent = s.totalItemCount;
        }
      }
    });
    setView(collectionView2);
    setCovidVaccinationData(ress);
    setTotalCount(tableData.length);
    console.log('table table table', tableData);
    setCompletedCount(filteredCompleted.length);
    setScheduledCount(filteredScheduled.length);
    //setTestEventTableDataBKP(tableData);
  };

  console.log('data last:', CovidVaccinationData);
  console.log(testEventTable, 'ress');

  function getCompanyLogo() {
    axios
      .post(setting.serverUrl + '/api/getCompLogo', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          console.log('logo response======', response.data);
          if (response.data) {
            setLogo(response.data);
          }
        }
      });
  }

  async function getAllEmp() {
    setDataEmployeeCustTrue(false);
    setDataEmployeeCustTrueNN(false);
    await axios
      .post(setting.serverUrl + '/api/employeeCustCountDashboard', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custIdArr: JSON.parse(localStorage.getItem('customerData')).id,
          custIdArrEmployee: JSON.parse(localStorage.getItem('customerData')).employee_multi_select,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          // alert("Some Technical Error Occurred!")
        }
        console.log('Respone from getData employeeCust:::::', response.data);

        if (response && response.status == 200) {
          if (response.data.length == 0) {
            setDataEmployeeCustTrue(true);
          }
          setDataEmployeeCust(response.data);
          setEmployeeAll(response.data.value);
        }
      });

    await axios
      .post(setting.serverUrl + '/api/getActiveEmployeeCount', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custIdArr: JSON.parse(localStorage.getItem('customerData')).id,
          custIdArrEmployee: JSON.parse(localStorage.getItem('customerData')).employee_multi_select,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        let ActiveStatus = false;
        if (
          localStorageDashboardPermission != '' &&
          Array.isArray(localStorageDashboardPermission)
        ) {
          const statusLen = localStorageDashboardPermission.filter(
            (val) => val?.title == 'Active Employees' && val?.status
          );
          if (statusLen && statusLen.length > 0) {
            ActiveStatus = true;
          } else {
            ActiveStatus = false;
          }
        }

        if (response && response.data.error) {
          // alert("Some Technical Error Occurred!")
        }
        console.log('Respone from getData employeeCust active', response.data);
        if (
          response &&
          response.status == 200 &&
          response.data &&
          response.data !== 'Blocked' &&
          response.data.value != undefined
        ) {
          if (response.data.length == 0) {
            setDataEmployeeCustTrueNN(true);
          }
          // setDataEmployeeCust(response.data);

          setActiveEmployees(response.data.value);
          dashboard_permission.push({
            title: 'Active Employees',
            count: response.data.value,
            activeEmployees: response.data.value,
            // status: response.data.value > 0 && response.data.value !== null ? true : false
            status: ActiveStatus
          });
        } else {
          setDataEmployeeCustTrueNN(true);
          setActiveEmployees(response.data.value);
          dashboard_permission.push({
            title: 'Active Employees',
            count: 0,
            activeEmployees: 0,
            // status: false
            status: ActiveStatus
          });
        }
      });
  }

  console.log('TableVaccData::', TableVaccData);
  async function getCovidTesting() {
    console.log(
      'getCovidTesting :: ',
      localStorage.getItem('myData'),
      localStorage.getItem('myPass')
    );
    //console.log("setting.serverUrl",setting.serverUrl)
    //let custIdArr = [];
    axios
      .post(setting.serverUrl + '/api/Covid_test_CustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then(async (response) => {
        let progressStatus = false;
        if (
          localStorageDashboardPermission != '' &&
          Array.isArray(localStorageDashboardPermission)
        ) {
          const statusLen = localStorageDashboardPermission.filter(
            (val) => val?.title == 'Test in progress' && val?.status
          );
          if (statusLen && statusLen.length > 0) {
            progressStatus = true;
          } else {
            progressStatus = false;
          }
        }

        let EmployeesTestedStatus = false;
        if (
          localStorageDashboardPermission != '' &&
          Array.isArray(localStorageDashboardPermission)
        ) {
          const statusLen = localStorageDashboardPermission.filter(
            (val) => val?.title == 'Employees Tested' && val?.status
          );
          if (statusLen && statusLen.length > 0) {
            EmployeesTestedStatus = true;
          } else {
            EmployeesTestedStatus = false;
          }
        }

        if (response.data == 'Blocked') {
          console.log('Respone from getData Covid_test blocked: ', response.data);
        } else {
          if (response && response.data.error) {
            // alert("Some Technical Error Occurred!")
          }
          console.log('Respone from getData Covid_test', response.data);

          if (
            response &&
            response.status == 200 &&
            response.data &&
            response.data !== 'Blocked' &&
            response.data.length > 0
          ) {
            let resultData = response?.data;
            let CovidTestDataPending =
              (await resultData.length) > 0 &&
              resultData.filter(function (e) {
                return e.testing_status == 'pending';
              });
            console.log('CovidTestDataPending::', CovidTestDataPending);
            setCovidTestDataPendingVal(
              CovidTestDataPending.length > 0 ? CovidTestDataPending.length : 0
            );
            dashboard_permission.push({
              title: 'Test in progress',
              count: CovidTestDataPending.length > 0 ? CovidTestDataPending.length : 0,
              CovidTestDataPendingVal:
                CovidTestDataPending.length > 0 ? CovidTestDataPending.length : 0,
              // status: CovidTestDataPending.length > 0 && response.data.value !== null ? true : false
              status: progressStatus
            });
            setCovidTestDataPendingValTrue1(true);
            setCovidTestDataPendingValTrue(!CovidTestDataPendingValTrue);
            let dataGroupByEmployee = _.groupBy(response.data, 'employee');
            var noOfEmployee = Object.keys(dataGroupByEmployee).length;
            console.log('noOfEmployee', noOfEmployee);
            setEmployeeCount(noOfEmployee);
            dashboard_permission.push({
              title: 'Employees Tested',
              count: noOfEmployee,
              employeeCount: noOfEmployee,
              // status: noOfEmployee > 0 && response.data.value !== null ? true : false
              status: EmployeesTestedStatus
            });
            setEmployeeCountTrue(true);
          } else {
            setCovidTestDataPendingVal(0);
            dashboard_permission.push({
              title: 'Test in progress',
              count: 0,
              CovidTestDataPendingVal: 0,
              // status: false
              status: progressStatus
            });
            setCovidTestDataPendingValTrue1(true);
            setCovidTestDataPendingValTrue(!CovidTestDataPendingValTrue);

            setEmployeeCount(0);
            dashboard_permission.push({
              title: 'Employees Tested',
              count: 0,
              employeeCount: 0,
              // status: false
              status: EmployeesTestedStatus
            });
            // setEmployeeCountTrue(true);
          }
        }
      });
  }

  function getTestEvents() {
    axios
      .post(setting.serverUrl + '/api/dataTestEventsId', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        console.log('Respone from test Events dashboard:::', response.data);

        let ScheduledStatus = false;
        if (
          localStorageDashboardPermission != '' &&
          Array.isArray(localStorageDashboardPermission)
        ) {
          // if (localStorageDashboardPermission != '') {
          const statusLen =
            localStorageDashboardPermission &&
            localStorageDashboardPermission.filter(
              (val) => val?.title == 'Scheduled Events' && val?.status
            );
          if (statusLen && statusLen.length > 0) {
            ScheduledStatus = true;
          } else {
            ScheduledStatus = false;
          }
        }
        if (response && response.data.error) {
          console.log('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          let countValue = response?.data?.value;
          console.log('scheduled event count value =', countValue);
          setEvent(countValue);
          dashboard_permission.push({
            title: 'Scheduled Events',
            count: countValue,
            event: countValue,
            // status: countValue > 0 && countValue !== null ? true : false
            status: ScheduledStatus
          });
          setEventTrue(true);
          setChange(true);
        } else {
          dashboard_permission.push({
            title: 'Scheduled Events',
            count: 0,
            event: 0,
            // status: countValue > 0 && countValue !== null ? true : false
            status: ScheduledStatus
          });
        }
      });
  }

  function notifications() {
    //let arrN = [];
    console.log('getData :: ', localStorage.getItem('myData'), localStorage.getItem('myPass'));
    // let custIdArr = [];
    axios
      .post(setting.serverUrl + '/api/notificationsCount', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          custId: JSON.parse(localStorage.getItem('customerId'))
        }
      })
      .then((response) => {
        console.log('Respone from notifications Dashboard::', response.data.value);

        let NotificationsStatus = false;
        if (
          localStorageDashboardPermission != '' &&
          Array.isArray(localStorageDashboardPermission)
        ) {
          const statusLen =
            localStorageDashboardPermission &&
            localStorageDashboardPermission.length > 0 &&
            localStorageDashboardPermission.filter(
              (val) => val?.title == 'Notifications' && val?.status
            );
          if (statusLen && statusLen.length > 0) {
            NotificationsStatus = true;
          } else {
            NotificationsStatus = false;
          }
        }

        if (response && response.data.error) {
          // alert("Some Technical Error Occurred!")
        }

        if (
          response &&
          response.status == 200 &&
          response.data &&
          response.data.value != undefined
        ) {
          let notificationCountValue = response?.data?.value;
          setNotLength(notificationCountValue);
          dashboard_permission.push({
            title: 'Notifications',
            count: notificationCountValue,
            notLength: notificationCountValue,
            // status: notificationCountValue > 0 && response.data.value !== null ? true : false
            status: NotificationsStatus
          });
          setNotLengthTrue(true);
        } else {
          dashboard_permission.push({
            title: 'Notifications',
            count: 0,
            notLength: 0,
            // status: notificationCountValue > 0 && response.data.value !== null ? true : false
            status: NotificationsStatus
          });
        }
      });
  }

  const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    background: '#fff',
    margin: '12px',
    width: '146px',
    height: '100px',
    borderRadius: '10px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    cursor: 'pointer'
  };
  const cardProfileStyleForWindow = {
    display: 'flex',
    justifyContent: 'space-around',
    background: '#fff',
    margin: '12px',
    boxSizing: 'border-box',
    padding: '50px',
    height: 'auto',
    width: '90%',
    borderRadius: '10px',
    maxWidth: '100%',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
  };

  const cardProfileStyleForPhone = {
    display: 'block',
    background: '#fff',
    margin: '12px',
    boxSizing: 'border-box',
    padding: '20px',
    height: 'auto',
    width: '90%',
    borderRadius: '10px',
    maxWidth: '100%',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;

    setListboxColumns(ctl.columns);
    const sender = ctl;
    sender.select(-1, -1);
    sender.addEventListener(sender.hostElement, 'click', (e) => {
      let ht = sender.hitTest(e);
      if (ht._p._rng._row !== -1) {
        let selectedRows = sender.cells._rows[ht.row]._data;
        if (ht.col < 5) {
          console.log('selected rows', selectedRows);
          history.push({ pathname: `/vertical/testingEvents`, state: selectedRows?.id });
        }
      }
    });
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };
  function download(e, attachment) {
    console.log('attachments', attachment);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: 'https://wss-files.s3.amazonaws.com/' + attachment
        }
      })
      .then((response) => {
        console.log('Respone from getData testEventReports', response.data);
        // setChange(!change)
        window.open(response.data);
      });
  }

  const downloadClearance = (e, event_id, emp_selected) => {
    console.log('data', event_id, emp_selected);
    axios
      .post(setting.serverUrl + '/api/downloadClearanceForm', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          event_id: event_id,
          employee_id: emp_selected[0],
          company: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        console.log('Respone from getData downloadClearanceForm', response.data);
        // setChangeCF(!changeCF);
        if (response.data.rows.length == 0) {
          setclearanceUrl('');
          alert('No Record Found');
        } else {
          setclearanceUrl(response.data.rows[0].url);
          window.open(response.data.rows[0].url);
        }

        // window.open(response.data.rows[0].url);
      });
  };

  function downloadAttachmentMultiple(attachment) {
    console.log('attachment :: ', attachment);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        console.log('Respone from attachment multiple', response.data);
        setChange(!change);
        window.open(response.data);
      });
  }

  const showLocation = (item) => {
    return (
      <div style={{ whiteSpace: 'normal' }}>
        {item.item.newLocation ? item.item.newLocation : ''}
      </div>
    );
  };

  const showService = (item) => {
    return (
      <span className='text-align-left '>{item.item.newService ? item.item.newService : ''}</span>
    );
  };

  const showScheduleDate = (item) => {
    return (
      <span className='text-align-left'>
        {item.item.newDateCompleted ? moment(item.item.newDateCompleted).format('MM/DD/YYYY') : ''}
      </span>
    );
  };

  const getSubItemSource = (item) => {
    console.log('getSubItemSource', item.item);
    console.log('getSubItemSource', testEventTable[item.item.rowid - 1].testDetail);
    const element = document.getElementById('yourElementId');
    if (element) {
      element.style.height = '36px';
    }
    return testEventTable[item.item.rowid - 1].testDetail;
  };

  const showItemType = (item) => {
    return (
      <p>
        {item.item.item_type && item.item.item_type.length > 0 ? (
          item.item.item_type === 'drug and alcohol' ? (
            <p> Drug & Alcohol </p>
          ) : item.item.item_type === 'medical surveillance' ? (
            <p>Occupational Health</p>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </p>
    );
  };

  const showTestName = (item) => {
    return (
      <p>{item.item.test_name && item.item.test_name.length > 0 ? item.item.test_name[1] : ''}</p>
    );
  };

  var showTestReasons = (item) => {
    return <p>{item.item.reasons ? item.item.reasons : ''}</p>;
  };

  const showResult = (item) => {
    return item.item.final_result === 'NEGATIVE' ? (
      <p style={{ color: 'green' }}>{item.item.final_result}</p>
    ) : item.item.final_result === 'POSITIVE' ? (
      <p style={{ color: 'red' }}>{item.item.final_result}</p>
    ) : item.item.final_result !== 'POSITIVE' && item.item.final_result !== 'NEGATIVE' ? (
      <p style={{ color: 'orange' }}>{item.item.final_result}</p>
    ) : (
      ''
    );
  };

  // const showCompledOn = (item) => {
  //   return <p>{item.item.completedOn ? moment(item.item.completedOn).format('MM/DD/YYYY') : ''}</p>;
  // };

  // const showFilename = (item) => {
  //   return (
  //     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: -40 }}>
  //       {docPermission ? (
  //         <>
  //           {' '}
  //           {item.item.mroFileName == false ? (
  //             ''
  //           ) : (
  //             // <button
  //             //   style={{ width: '50%', marginBottom: '5px' }}
  //             //   onClick={(e) => download(e, item.item.mroFileName)}
  //             // >
  //             //   Results
  //             // </button>
  //             <FolderOpenTwoTone
  //               twoToneColor='#cc7a00'
  //               style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
  //               onClick={(e) => download(e, item.item.mroFileName)}
  //             />
  //           )}
  //           {/* {item.item.ccfFileName == false ? (
  //             ''
  //           ) : (
  //             <>
  //               {' '}
  //               &nbsp; &nbsp;
  //               <button onClick={(e) => download(e, item.item.ccfFileName)}>CCF</button>
  //             </>
  //           )}
  //           &nbsp; &nbsp; */}
  //           {item.item.donorFileName == false ? (
  //             ''
  //           ) : (
  //             // <button
  //             //   style={{ width: '50%', marginBottom: '5px' }}
  //             //   onClick={(e) => download(e, item.item.donorFileName)}
  //             // >
  //             //   Donor Pass
  //             // </button>
  //             <FolderOpenTwoTone
  //               twoToneColor='#cc7a00'
  //               style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
  //               onClick={(e) => download(e, item.item.donorFileName)}
  //             />
  //           )}
  //           {item.item.tenpanelreport == false ? (
  //             ''
  //           ) : (
  //             <>
  //               &nbsp; &nbsp;
  //               {/* <button
  //                 style={{ width: '50%', marginBottom: '5px' }}
  //                 onClick={(e) => download(e, item.item.tenpanelreport)}
  //               >
  //                 Results
  //               </button> */}
  //               <FolderOpenTwoTone
  //                 twoToneColor='#cc7a00'
  //                 style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
  //                 onClick={(e) => download(e, item.item.tenpanelreport)}
  //               />
  //             </>
  //           )}
  //           {item.item.twelvepanelreport == false ? (
  //             ''
  //           ) : (
  //             <>
  //               &nbsp; &nbsp;
  //               {/* <button
  //                 style={{ width: '50%', marginBottom: '5px' }}
  //                 onClick={(e) => download(e, item.item.twelvepanelreport)}
  //               >
  //                 Results
  //               </button> */}
  //               <FolderOpenTwoTone
  //                 twoToneColor='#cc7a00'
  //                 style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
  //                 onClick={(e) => download(e, item.item.twelvepanelreport)}
  //               />
  //             </>
  //           )}
  //           {item.item.breathalcohol == false ? (
  //             ''
  //           ) : (
  //             <>
  //               &nbsp; &nbsp;
  //               {/* <button
  //                 style={{ width: '50%', marginBottom: '5px' }}
  //                 onClick={(e) => download(e, item.item.breathalcohol)}
  //               >
  //                 Results
  //               </button> */}
  //               <FolderOpenTwoTone
  //                 twoToneColor='#cc7a00'
  //                 style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
  //                 onClick={(e) => download(e, item.item.breathalcohol)}
  //               />
  //             </>
  //           )}
  //           {item.item.event_status === 'Completed' ? (
  //             <>
  //               &nbsp; &nbsp;
  //               {/* <button
  //                 style={{ width: '70%', marginBottom: '5px' }}
  //                 onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)}
  //               >
  //                 Clearance Form
  //               </button> */}
  //               <FolderOpenTwoTone
  //                 twoToneColor='#cc7a00'
  //                 style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
  //                 onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)}
  //               />
  //             </>
  //           ) : (
  //             ''
  //           )}
  //           {item.item && item.item.attachmentUrls && item.item.attachmentUrls.length > 0
  //             ? item.item.attachmentUrls.map((url) => {
  //                 return (
  //                   <>
  //                     &nbsp; &nbsp;
  //                     {/* <button
  //                       style={{ width: '50%', marginBottom: '5px' }}
  //                       //style={{ margin: 3 }}
  //                       onClick={() => downloadAttachmentMultiple(url.url)}
  //                     >
  //                       Attachments
  //                     </button> */}
  //                     <FolderOpenTwoTone
  //                       twoToneColor='#cc7a00'
  //                       style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
  //                       onClick={() => downloadAttachmentMultiple(url.url)}
  //                     />
  //                   </>
  //                 );
  //               })
  //             : ''}
  //           {/* {record.auth_form_pdf == false ? "" :
  //     (<button  onClick={(e)=>downloadVaccinationAuth( e, record.auth_form_pdf)}>Auth Form</button>)} */}
  //         </>
  //       ) : (
  //         (item.item.mroFileName ||
  //           item.item.attachmentUrls ||
  //           item.item.event_status === 'Completed' ||
  //           item.item.breathalcohol ||
  //           item.item.twelvepanelreport ||
  //           item.item.tenpanelreport ||
  //           item.item.donorFileName ||
  //           // item.item.ccfFileName ||
  //           item.item.mroFileName) && (
  //           <CheckSquareTwoTone style={{ color: 'green', fontSize: 15, marginLeft: '-103px' }} />
  //         )
  //       )}
  //     </div>
  //   );
  // };

  const renderProfileDashboard = (data) => {
    return (
      <Row>
        <div
          style={window.innerWidth >= 560 ? cardProfileStyleForWindow : cardProfileStyleForPhone}
        >
          <div
            style={
              window.innerWidth >= 560
                ? { width: '40%', margin: 'auto' }
                : { width: '100%', margin: 'auto' }
            }
          >
            <div>
              {logoVal ? (
                <img src={logoVal} style={{ width: '40%' }} alt='Company Logo' />
              ) : (
                <img src={LogoSvg} style={{ width: '40%' }} alt='Company Logo' />
              )}
            </div>
            <div>
              <Typography.Text
                style={{ fontSize: 18, color: '#707070', fontWeight: 700, marginTop: 20 }}
              >
                {loginCustomerData.name ? loginCustomerData.name : ''}
              </Typography.Text>
            </div>
          </div>
          <Row></Row>

          <div
            style={
              window.innerWidth >= 560
                ? { borderLeft: '2px solid gray', width: '60%', paddingLeft: '60px' }
                : {
                    borderTop: '2px solid gray',
                    borderLeft: 'none',
                    padding: '20px 0px 20px 0px',
                    marginTop: '10px',
                    width: '100%'
                  }
            }
          >
            <div>
              <Typography.Text style={{ fontSize: 16, color: '1C3A6A', fontWeight: 900 }}>
                Corporate Address
              </Typography.Text>
            </div>
            <div>
              <Typography.Text style={{ fontSize: 14, color: '#707070' }}>
                {loginCustomerData
                  ? (loginCustomerData.street === false ||
                    loginCustomerData.street === undefined ||
                    Object.keys(loginCustomerData.street).length === 0
                      ? ''
                      : loginCustomerData.street + ', ') +
                    (loginCustomerData.city === false ||
                    loginCustomerData.city === undefined ||
                    Object.keys(loginCustomerData.city).length === 0
                      ? ''
                      : loginCustomerData.city + ', ') +
                    (loginCustomerData.state_id[1] === false ||
                    loginCustomerData.state_id[1] === undefined ||
                    (loginCustomerData.state_id &&
                      Object.keys(loginCustomerData.state_id[1]).length === 0)
                      ? ''
                      : loginCustomerData.state_id[1] &&
                        loginCustomerData.state_id[1] &&
                        loginCustomerData.state_id[1]
                          .replace(' (US)', '')
                          .split('(')[1]
                          .slice(0, -1) + ' ') +
                    (loginCustomerData.zip === false ||
                    loginCustomerData.zip === undefined ||
                    Object.keys(loginCustomerData.zip).length === 0
                      ? ''
                      : ' ' + loginCustomerData.zip)
                  : ''}
                {/* 200 Park Ave.
                  New York, NY 10017
                  (800) 638-5433 */}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text style={{ fontSize: 16, color: '1C3A6A', fontWeight: 900 }}>
                Corporate Phone Number
              </Typography.Text>
            </div>

            <div>
              <Typography.Text style={{ fontSize: 14, color: '#707070' }}>
                {loginCustomerData.phone}
              </Typography.Text>
            </div>

            <Row>
              <div>
                <div>
                  <Typography.Text style={{ fontSize: 16, color: '1C3A6A', fontWeight: 900 }}>
                    Primary Contact
                  </Typography.Text>
                </div>
                {primary_contact_val.length > 0 && (
                  <div>
                    <Typography.Text style={{ fontSize: 14, color: '#707070' }}>
                      {primary_contact_val[0].name}
                      <br />
                      {primary_contact_val[0].email}
                      <br />
                      {primary_contact_val[0].phone}
                    </Typography.Text>
                  </div>
                )}
              </div>
            </Row>

            <Row>
              <div>
                <div>
                  <Typography.Text style={{ fontSize: 16, color: '1C3A6A', fontWeight: 900 }}>
                    Secondary Contact
                  </Typography.Text>
                </div>
                {secondary_contact_val.length > 0 && (
                  <div>
                    <Typography.Text style={{ fontSize: 14, color: '#707070' }}>
                      {secondary_contact_val[0].name}
                      <br />
                      {secondary_contact_val[0].email}
                      <br />
                      {secondary_contact_val[0].phone}
                    </Typography.Text>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </div>
      </Row>
    );
  };

  const renderCard = (data) => {
    // const history = useHistory();
    const navigateToPage = () => {
      if (data.title == 'Notifications') {
        history.push({ pathname: `/vertical/notifications` });
      } else if (data.title == 'Scheduled Events') {
        history.push({ pathname: `/vertical/testingEvents` });
      } else if (data.title == 'Test in progress') {
        history.push({ pathname: `/vertical/testingEvents` });
      } else if (data.title == 'Employees Tested') {
        history.push({ pathname: `/vertical/covidTesting` });
      } else if (data.title == 'Employee Vaccinated') {
        history.push({ pathname: `/vertical/covidVaccination` });
      } else if (data.title == 'Exempted Employees') {
        history.push({ pathname: `/vertical/covidExemption` });
      } else if (data.title == 'Vulnerable Employees') {
        history.push({ pathname: `/vertical/covidVulnerable` });
      } else if (data.title == 'Active Employees') {
        history.push({ pathname: `/vertical/employeeList` });
      } else if (data.title == 'Open Events') {
        history.push({ pathname: `/vertical/testingEvents` });
      } else if (data.title == 'Random pool %') {
        history.push({ pathname: `/vertical/random_testing_pools` });
      }
    };
    return (
      <>
        {/* {data.count !== 0 ? ( */}
        {console.log("dataaaaaaa",data)}
        {data && data.status ? (
          <Col>
            <div style={cardStyles} onClick={navigateToPage}>
              <Row justify='center'>
                <Col>
                  {(data.title == 'Notifications' && data.status) ||
                  (data.title == 'Scheduled Events' && data.status) ||
                  (data.title == 'Test in progress' && data.status) ||
                  (data.title == 'Employees Tested' && data.status) ||
                  (data.title == 'Employee Vaccinated' && data.status) ||
                  (data.title == 'Exempted Employees' && data.status) ||
                  (data.title == 'Vulnerable Employees' && data.status) ||
                  (data.title == 'Active Employees' && data.status) ||
                  (data.title == 'Open Events' && data.status) ||
                  (data.title == 'Random pool %' && data.status) ||
                  (data.title == 'Clinics' && data.status) ? (
                    //  (data.title == 'Random Pools') ||
                    //  (data.title == 'Employees Cleared')
                    <Typography.Text style={{ fontSize: 35, color: '#2F7197' }}>
                      {data.count}
                    </Typography.Text>
                  ) : (
                    <Typography.Text style={{ fontSize: 35, color: '#2F7197' }}>
                      {data.count}
                    </Typography.Text>
                  )}
                </Col>
              </Row>
              <Row justify='center'>
                <Col>
                  <Typography.Text style={{ fontSize: 14, color: '#707070', fontWeight: 900 }}>
                    {data.title}
                  </Typography.Text>
                </Col>
              </Row>
            </div>
          </Col>
        ) : (
          ''
        )}
      </>
    );
  };
  const renderDashboardCards = (data) => {
    console.log('dashboard items', data);

    if (!data || !Array.isArray(data)) {
      // Handle the case where data is falsy or not an array
      console.log('Invalid data:', data);
      return [];
    }

    let cards = data.map((value) => {
      return renderCard(value);
    });

    return cards;
  };

  return (
    <div>
      <Row style={{ paddingTop: '20px' }}>
        <Col style={{ color: '#1b3969', fontWeight: 900, padding: 20, fontSize: 25 }}>
          Dashboard
        </Col>
      </Row>
      <Row>
        <Col span={1}></Col>
        <Col span={22}>
          {dash.length > 0 ? (
            <Row>{renderDashboardCards(dash)}</Row>
          ) : (
            <Row>
              <Col span={10}></Col>
              <Col span={8}>
                <Space size='middle' style={{ marginTop: 20, marginLeft: 70 }}>
                  <Spin size='large' />
                </Space>
              </Col>
            </Row>
          )}
        </Col>
        {/* <Col span={12}>{renderProfileDashboard('')}</Col> */}
        <Col span={1}></Col>
      </Row>
      <Row>
        {updatedCardLoader ? (
          <Row>
            <Col span={10}></Col>
            <Col span={8}>
              <Space size='middle' style={{ marginTop: 20, marginLeft: 85 }}>
                <Spin size='large' />
              </Space>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Row>
      <Row>
        <Col span={1}></Col>
        <Col span={22}>{renderProfileDashboard('')}</Col>
        <Col span={1}></Col>
      </Row>

      <Row>
        <Col style={{ color: '#1b3969', fontWeight: 900, padding: 20, fontSize: 25 }}>
          Latest Scheduled Events
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      {!noRecordFound ? (
        testEventTable.length > 0 ? (
          <>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <div style={{ minHeight: '100%' }}>
                  <Row gutter={[32, 32]}>
                    <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                      <FlexGridSearch
                        ref={theSearch}
                        placeholder='Search'
                        cssMatch=''
                        style={{ width: '70%', height: '40px' }}
                      />
                    </Col>

                    <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                      {/* <div ref={fixedContainerRef}>
                        <Select
                          style={{ width: 170 }}
                          placeholder={`Items per page : ${pageSize}`}
                          onChange={paggination}
                          options={[
                            { label: 'Items per page : 10', value: 10 },
                            { label: 'Items per page : 20', value: 20 },
                            { label: 'Items per page : 50', value: 50 }
                          ]}
                          getPopupContainer={() => fixedContainerRef.current}
                        />
                      </div> */}
                    </Col>

                    <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                      <Button
                        type='primary'
                        style={{ backgroundColor: '#3d586a', float: 'right' }}
                        disabled={isExcelPreparing}
                        onClick={exportToExcel}
                        size='small'
                      >
                        {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                      </Button>
                    </Col>
                  </Row>

                  {/* to export entire data of table */}
                  <FlexGrid
                    ref={theGrid}
                    style={{ display: 'none' }}
                    className='dummyWijmoData'
                    itemsSource={viewData}
                    initialized={gridInitializedData}
                    formatItem={formatItem}
                    isReadOnly={true}
                  >
                    <FlexGridColumn
                      binding='name'
                      header='Employee Name'
                      minWidth={150}
                      width='*'
                    />
                    <FlexGridColumn
                      binding='newLocation'
                      header='Location'
                      minWidth={200}
                      width='*'
                    >
                      <FlexGridCellTemplate cellType='Cell' template={showLocation} />
                    </FlexGridColumn>
                    <FlexGridColumn binding='newService' header='Service' minWidth={250} width='*'>
                      <FlexGridCellTemplate cellType='Cell' template={showService} />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding='event_status'
                      header='Event Status'
                      // width='12*'
                      minWidth={140}
                      width='*'
                    />
                    <FlexGridColumn
                      binding='newDateCompleted'
                      header='Scheduled Date'
                      // width='10*'
                      minWidth={150}
                      width='*'
                    >
                    </FlexGridColumn>
                  </FlexGrid>

                  <FlexGrid
                    ref={theGrid}
                    id='theGridTallRows'
                    className='cliniclisttable'
                    alternatingRowStep={1}
                    // autoRowHeights={true}
                    itemsSource={view}
                    initialized={gridInitialized}
                    formatItem={formatItem}
                    itemsPerPage={10}
                    isReadOnly={true}
                    scrollMode='Virtual' // Add this line for smooth scrolling
                  >
                    <div style={{ display: 'none' }}>
                      <wjInput.ListBox
                        className='column-picker'
                        itemsSource={listboxColumns}
                        checkedMemberPath='visible'
                        displayMemberPath='header'
                        lostFocus={hidePicker}
                        initialized={initializedListBox}
                      ></wjInput.ListBox>
                    </div>
                    <FlexGridColumn
                      binding='name'
                      header='Employee Name'
                      width={225}
                      // wordWrap={true}
                    />
                    <FlexGridColumn
                      binding='newLocation'
                      header='Location'
                      // width='*'
                      width={225}
                      // wordWrap={true}
                    >
                      <FlexGridCellTemplate cellType='Cell' template={showLocation} />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding='newService'
                      header='Service'
                      // minWidth={250}
                      // wordWrap={true}
                      // width='*'
                      width={300}
                    >
                      <FlexGridCellTemplate cellType='Cell' template={showService} />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding='event_status'
                      header='Event Status'
                      // width='*'
                      width={225}
                      // wordWrap={true}
                    />
                    <FlexGridColumn binding='newDateCompleted' header='Scheduled Date' width={225}>
                    </FlexGridColumn>

                    <FlexGridDetail
                      template={(ctx) => (
                        <div style={{ overflowX: 'auto', transition: 'width 0.5s ease' }}>
                          <FlexGrid
                            id='theGridTallRows'
                            autoGenerateColumns={false}
                            headersVisibility='Column'
                            alternatingRowStep={1}
                            // autoRowHeights={true}
                            // loadedRows={onloadedRows.bind(}
                            itemsSource={getSubItemSource(ctx)}
                            itemsPerPage={10}
                            isReadOnly={true}
                            scrollMode='Virtual' // Add this line for smooth scrolling
                          >
                            <FlexGridColumn
                              binding='item_type'
                              header='Item Type'
                              // width='*'
                              width={218}
                              // wordWrap={true}
                            >
                              <FlexGridCellTemplate cellType='Cell' template={showItemType} />
                            </FlexGridColumn>
                            <FlexGridColumn
                              binding='test_name[1]'
                              header='Test Name'
                              // width='*'
                              width={250}
                              // wordWrap={true}
                            >
                              <FlexGridCellTemplate cellType='Cell' template={showTestName} />
                            </FlexGridColumn>
                            <FlexGridColumn
                              binding='reasons'
                              header='Reason'
                              // width='*'
                              width={202}
                              // wordWrap={true}
                            >
                              <FlexGridCellTemplate cellType='Cell' template={showTestReasons} />
                            </FlexGridColumn>
                            <FlexGridColumn
                              binding='testing_status'
                              header='Status'
                              // width='*'
                              width={225}
                              // wordWrap={true}
                            />
                            <FlexGridColumn
                              binding='final_result'
                              header='Result'
                              // width='*'
                              width={225}
                              // wordWrap={true}
                            >
                              <FlexGridCellTemplate cellType='Cell' template={showResult} />
                            </FlexGridColumn>
                            {/* <FlexGridColumn
                              binding='completedOn'
                              header='Date Completed'
                              // width='*'
                              width={124}
                              // wordWrap={true}
                            >
                              <FlexGridCellTemplate cellType='Cell' template={showCompledOn} />
                            </FlexGridColumn> */}
                            {/* <FlexGridColumn
                              binding='FileName'
                              header='Documents'
                              // width='*'
                              width={215}
                              // wordWrap={true}
                            >
                              <FlexGridCellTemplate cellType='Cell' template={showFilename} />
                            </FlexGridColumn> */}
                            {/* <FlexGridFilter
                            filterColumns={[
                              'item_type',
                              'test_name[1]',
                              'reasons',
                              'final_result',
                              'testing_status',
                              'completedOn'
                            ]}
                          /> */}
                          </FlexGrid>
                        </div>
                      )}
                      isAnimated={true} // Add this if you want to animate expansion
                    />

                    <FlexGridFilter
                      filterColumns={[
                        'name',
                        'newLocation',
                        'newService',
                        'event_status',
                        'newDateStarted'
                      ]}
                      // filterChanged={handleFilterApplied}
                    />
                  </FlexGrid>

                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <div
                      style={{
                        backgroundColor: '#425a81',
                        color: '#fff',
                        display: 'inline',
                        borderRadius: '5px',
                        height: '30px'
                      }}
                    >
                      <CollectionViewNavigator
                        style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                        headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                        byPage={true}
                        cv={view}
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <p
                      style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                      ref={filterRefCounter}
                      id='filterCounter'
                    >
                      {Globalize.format(filterRefCounter.current, 'n0')}
                    </p>
                    &nbsp;
                    <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                      {' '}
                      out of {testEventTable.length}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={1}></Col>
            </Row>
          </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}> No record found !</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DashboardPage;
