import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import setting from '../../config/setting';
import { Spin, Space, Row, Col, Select, Button, notification } from 'antd';
import * as wjcCore from '@grapecity/wijmo';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FilePdfTwoTone } from '@ant-design/icons/lib';


let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
      },
      'protocol_attachments.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const ProtocolAttachment = () => {
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [viewIndex, setViewIndex] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [pagging, setPagging] = useState(10);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [pagginationNumRecord, setPagginationNumRecord] = useState({});
  const [isLoadingProtocol, setIsLoadingProtocol] = useState(false);
  const [allData, setAllData] = useState([]);
  const [change, setChange] = useState(false);
  const [eventPolicy, setEventPolicy] = useState([]);
  const [attachNameFilter, setAttachNameFilter] = useState({});
  const [hasPermission, setHasPermission] = useState(false);
  const [docPermission, setDocPermission] = useState(false);
  const [found, setFound] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [pageApply, setPageApply] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [errMsg, setErrMsg] = useState();
  const config = require('../keyFront');

  const theGrid = useRef();
  const theSearch = useRef();
  const gridRef = useRef(null);
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }
/* Wijmo pagesize set functionality */
const paggination = (value) => {
  setItemPerPage(value);
  setPageSize(value);
  view.refresh();
};

  useEffect(() => {
    getProtocolAttachments();
    getDocPermission();
  }, [pageSize]);

  function download(e, url) {
    axios
      .post(setting.serverUrl + '/api/downloadTestingCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_url: url
        }
      })
      .then((response) => {
        console.log('Respone from download images', response.data);

        window.open(response.data && response.data);
      });
  }
  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.ProtocolDocs
        }
      })
      .then((response) => {
        console.log('response pritocol  docs', response);
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };
  async function getProtocolAttachments() {
    setIsLoadingProtocol(true);
    axios
      .post(setting.serverUrl + '/api/protocolAttachments', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId')
        }
      })
      .then(async (response) => {
        setIsLoadingProtocol(false);
        console.log('protocolAttachments response ::::: ', response);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          let attachNames = {};
          response &&
            response.data &&
            response.data.rows
              .filter((x) => x.area && x.area.length > 0 && x.area != false)
              .map((data) => (attachNames[data.area] = 'here'));

          setAllData(response.data.rows);
          const collectionView1 = new CollectionView(response.data.rows, {
            pageSize: response.data.rows.length
          });
          setViewData(collectionView1);
          const collectionView2 = new CollectionView(response.data.rows, {
            pageSize: pageSize,
            collectionChanged: (s) => {
              filterRefCounter.current = s.totalItemCount;
              var filterCounter = document.getElementById('filterCounter');
              if (filterCounter) {
                filterCounter.textContent = s.totalItemCount;
              }
            }
          });
          setView(collectionView2);

          //setWijmoFlexGrid(response.data.rows, 10);
          setChange(true);
          setEventPolicy(response.data.rows);
          setAttachNameFilter(attachNames);
          setHasPermission(true);
        } else if (response.status === 201) {
          setFound(true);
          setNoRecordMessage(true);
          setErrMsg('Access permission not allowed.');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  }
  const exportToExcel = () => {
    const exportService = new ExportService();

    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
    col = flex.columns[e.col],
    row = flex.rows[e.row];
    if(row){
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;

    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) =>{
    flexes = ctl;
  }

  // const showDocs = (item) => {
  //   return (
  //     <p>
  //       {docPermission && item.item.url ? (
  //         <button
  //           className='doclink'
  //           onClick={(e) => download(e, item.item.url)}
  //           style={{
  //             border: 'none',
  //             textAlign: 'center',
  //             borderRadius: 5,
  //             backgroundColor: '#abc',
  //             fontweight: 'bold'
  //           }}
  //         >
  //           View Docs
  //         </button>
  //       ) : (
  //         item.item.url && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
  //       )}
  //     </p>
  //   );
  // };

  const showDocs = (item) => {
    return (
      <p>
        {docPermission && item.item.url ? (
          <FilePdfTwoTone onClick={(e) => download(e, item.item.url)} style={{fontSize:"15px", width:"55px",cursor:"pointer"}} twoToneColor="#FF0000"/>
        ) : (
          item.item.url && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
        )}
      </p>
    );
  };

  return (
    <div style={{ padding: 30 }}>
      <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Protocol Attachments</h3>
      {!isLoadingProtocol ? (
        allData.length > 0 ? (
          <>
            <Row gutter={[32, 32]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
              <div ref={fixedContainerRef}>
                        <Select
                          style={{ width: 170 }}
                          placeholder={`Items per page : ${pageSize}`}
                          onChange={paggination}
                          options={[
                            { label: 'Items per page : 10', value: 10 },
                            { label: 'Items per page : 20', value: 20 },
                            { label: 'Items per page : 50', value: 50 }
                          ]}
                          getPopupContainer={() => fixedContainerRef.current}
                        />
                      </div>
              
              
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                  size="small"
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>
            <br />

            {/* to export entire data of table */}
            <FlexGrid
             ref={theGrid}
             style={{display:"none"}}
             className='dummyWijmoData'
             itemsSource={viewData}
             initialized={gridInitializedData}
             formatItem={formatItem}
             isReadOnly={true}
             >

            <FlexGridColumn binding='area' header='Attachment Name' minWidth={800} width='*' />
            </FlexGrid>

            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              className='protocolAttachments cliniclisttable'
              itemsSource={view}
              initialized={gridInitialized}
              formatItem={formatItem}
              itemsPerPage={10}
              isReadOnly={true}
            >
              <div style={{ display: 'none' }}>
                <wjInput.ListBox
                  className='column-picker'
                  itemsSource={listboxColumns}
                  checkedMemberPath='visible'
                  displayMemberPath='header'
                  lostFocus={hidePicker}
                  initialized={initializedListBox}
                ></wjInput.ListBox>
              </div>
              <FlexGridColumn binding='area' header='Attachment Name' width={580}/>

              <FlexGridColumn binding='url' header='View Docs' width={579.5}>
                <FlexGridCellTemplate cellType='Cell' template={showDocs} />
              </FlexGridColumn>

              <FlexGridFilter
                showFilterIcons={true}
                placeholder='Filter data...'
                defaultFilterType='Value'
                filterColumns={['area']}
              />
            </FlexGrid>

            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                ref={filterRefCounter}
                id='filterCounter'
              >
                {Globalize.format(filterRefCounter.current, 'n0')} 
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                {' '}
                out of {eventPolicy.length}
              </p>
            </div>
                   </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50,marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProtocolAttachment;
