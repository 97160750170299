import React, { useState, useEffect } from 'react';
import { Checkbox, Form, Tabs, Button } from 'antd';
import _ from 'underscore';
import { useLocation, useHistory } from 'react-router-dom';
import ClinicGeneralInfo from './clinicGeneralInfo';
import CollectorTechTab from './collectorTechTab';
import ClinicHourTab from './clinicHourTab';
import AppointmentTab from './appointmentTab';
import CcfParkingTab from './ccfParkingTab';
import EbtTwicTab from './ebtTwicTab';
import ClinicServicesTab from './clinicServices';
import { Space, Typography } from 'antd';
const { Text, Title } = Typography;
const ClinicDetail = () => {
  const replaceNaNWithEmpty = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceNaNWithEmpty(obj[key]);
      } else if (obj[key] === 'nan') {
        obj[key] = '';
      }
    }
  };
  const history = useHistory();
  const location = useLocation();
  const { TabPane } = Tabs;
  replaceNaNWithEmpty(location.state);
  const propsData = location.state;
  const [isChecked, setIsChecked] = useState(propsData && propsData.if_appointments); // Set the initial value to `true` for pre-filled checkbox
  console.log({ propsData });

  useEffect(() => {
    if (propsData == undefined) {
      history.goBack();
    }
  }, []);
  return (
    <>
      <div>
        <Button
          size='small'
          style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
          onClick={() => history.push({ pathname: `/vertical/clinicList` })}
        >
          Back
        </Button>
      </div>
      <br />
      <Form layout='vertical' style={{ padding: 20, borderRadius: 20, backgroundColor: '#fff' }}>
        {/* <h6 style={{ paddingLeft: 100 }}>
          Appointments &nbsp; &nbsp;<Checkbox checked={isChecked} disabled></Checkbox>
        </h6> */}

        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div
            className='col-md-6 col-sm-5'
            style={{ borderRight: 'solid #abc 2px', paddingLeft: 20, paddingTop: 5 }}
          >
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '71%', fontWeight: 900 }}>Appointments </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {/* {' '} */}
                <Checkbox checked={isChecked} disabled></Checkbox>
              </span>
            </p>

            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '70%', fontWeight: 900 }}> Clinic Legal Name</span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {' '}
                {propsData && propsData.clinic_name ? propsData.clinic_name : ''}
              </span>
            </p>

            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '70%', fontWeight: 900 }}> Authorization Email</span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {' '}
                {propsData && propsData.emails_legacy ? propsData.emails_legacy : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '71%', fontWeight: 900 }}>On-Site Testing </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {' '}
                {propsData && propsData.on_site_testing ? propsData.on_site_testing : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '70%', fontWeight: 900 }}>After hour phone number </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {' '}
                {propsData && propsData.after_hour_number ? propsData.after_hour_number : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{paddingLeft:'25px', paddingTop:'10px'}}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', fontWeight: 900 }}> Type of Clinic </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.Type ? propsData.Type : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', fontWeight: 900 }}> Tier Type </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.tier && propsData.tier}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', fontWeight: 900 }}> Phone </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.phone ? propsData.phone : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', fontWeight: 900 }}> Fax </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.fax ? propsData.fax : ''}
              </span>
            </p>
          </div>
        </div>
        <br />

        <div className='row'>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px', paddingLeft:'20px' }}>
            <h5 style={{ color: '#247' }}>Clinic Physical Address</h5>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.street ? propsData.street : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.street2 ? propsData.street2 : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.city ? propsData.city : ''} &nbsp;
                {/* <font style={{ color: '#567', fontSize: 14, fontweight: 900 }}> */}
                <font style={{ fontSize: 14, fontweight: 900 }}>
                  {' '}
                  {propsData && propsData.state_id && propsData.state_id ? propsData.state_id : ''}
                </font>
                &nbsp;
                {propsData && propsData.zip ? propsData.zip : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', color: '#567' }}>
                {propsData && propsData.country_id.length > 0 && propsData.country_id[1]
                  ? propsData.country_id[1]
                  : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-5 col-sm-5' style={{paddingLeft:'25px'}}>
            <h5 style={{ color: '#247' }}>Clinic Billing Address</h5>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.street_bill ? propsData.street_bill : ''}
              </span>
            </p>

            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.street2_bill ? propsData.street2_bill : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.city_bill ? propsData.city_bill : ''} &nbsp;&nbsp;
                {propsData && propsData.state_id_bill && propsData.state_id_bill[1]
                  ? propsData.state_id_bill[1]
                  : ''}
                &nbsp;&nbsp;
                {propsData && propsData.zip_bill ? propsData.zip_bill : ''}
              </span>
            </p>

            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', color: '#567' }}>
                {propsData && propsData.country_id_bill ? propsData.country_id_bill[1] : ''}
              </span>
            </p>

            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', fontWeight: 900 }}> Contact Number </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.contact_number ? propsData.contact_number : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '60%', fontWeight: 900 }}> Contact Billing Email </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {propsData && propsData.contact_billing_email && propsData.contact_billing_email}
              </span>
            </p>
          </div>
        </div>

        <br />
        <Tabs defaultActiveKey='1' type='card'>
          <TabPane
            tab={
              <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>
                Clinic General Information
              </span>
            }
            key='1'
            style={{ fontSize: 6 }}
          >
            <ClinicGeneralInfo propsData={propsData} />
          </TabPane>

          <TabPane
            tab={
              <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>Clinic Hours</span>
            }
            key='2'
            style={{ fontSize: 6 }}
          >
            <ClinicHourTab propsData={propsData} />
          </TabPane>
          <TabPane
            tab={
              <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>Appointments</span>
            }
            key='3'
            style={{ fontSize: 6 }}
          >
            <AppointmentTab propsData={propsData} />
          </TabPane>

          <TabPane
            tab={
              <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>e-CCF/Parking</span>
            }
            key='4'
            style={{ fontSize: 6 }}
          >
            <CcfParkingTab propsData={propsData} />
          </TabPane>
          <TabPane
            tab={<span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>EBT/TWIC</span>}
            key='5'
            style={{ fontSize: 6 }}
          >
            <EbtTwicTab propsData={propsData} />
          </TabPane>
          <TabPane
            tab={
              <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>
                Collectors/Tech/Medical
              </span>
            }
            key='6'
            style={{ fontSize: 6 }}
          >
            <CollectorTechTab propsData={propsData} />
          </TabPane>
          <TabPane
            tab={
              <span style={{ fontSize: 16, textShadow: '1px 1px 3px #c0d7c0' }}>
                Clinic Services
              </span>
            }
            key='7'
            style={{ fontSize: 6 }}
          >
            <ClinicServicesTab propsData={propsData} />
          </TabPane>
        </Tabs>
      </Form>
      <br />
    </>
  );
};

export default ClinicDetail;
