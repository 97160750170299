import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Button } from 'antd';
import moment from 'moment';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGridDetail } from '@grapecity/wijmo.react.grid.detail';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FilePdfTwoTone } from '@ant-design/icons/lib';
// import { InternalSymbolName } from 'typescript';
// import { send } from 'process';

let columnPicker = [];
let flexes = '';
let showGrid = '';
let dragOver = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'TestDetail_OccHealth.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const OccHealthEmployee = ({
  data,
  docOccHealthPermission,
  downloadClearance,
  downloadAttachmentMultiple
}) => {
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [occHealthData, setOccHealthData] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef(null);
  let tt = new wijmo.Tooltip();

  console.log(data, 'data occ');

  useEffect(() => {
    data.sort((a,b)=>{
      const dateA = new Date(a.scheduled_date);
      const dateB = new Date(b.scheduled_date);
      return dateB - dateA;
    })
    const ress = data.map((val) => {
      // console.log('response in occ health tab===', val);
      // console.log('occ health tabss::::',val.rows)
      return {
        ...val,
        newLocation: val.loc_name.split('|')[1],
        // newLocation:
        // val.child_ids[1] != ''
        //   ? val.child_ids[1].split(',')[1]
        //     ? val.child_ids[1].split(',')[1].includes('|')
        //       ? val.child_ids[1].split(',')[1].trim().split('|')[1].trim()
        //       : val.child_ids[1].split(',')[1]
        //     : val.child_ids[1].split(',')[0].trim()
        //   : '',
        newDate: val.scheduled_date ? val.scheduled_date: '',
        newDateForExcel: val.scheduled_date ? moment(val.scheduled_date).format('MM/DD/YYYY'):"",
        mode: val.mode != false ? val.mode : ''
      };
    });
    // setWijmoFlexGrid(ress, 10);
    setOccHealthData(ress);
  }, []);

  useEffect(() => {
    if (occHealthData.length > 0) {
      const collectionView1 = new CollectionView(occHealthData, {
        pageSize: occHealthData.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(occHealthData, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      setView(collectionView2);
    }
  }, [occHealthData, pageSize]);

  console.log(occHealthData, 'locationocchealth');

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const getSubItemSource = (item) => {
    console.log(item, 'osha......');
    const res = occHealthData.filter((value) => {
      if (value.id === item.item.id) {
        return value.test_name;
      }
    });

    const ress = res[0].test_name.map((val) => {
      return {
        ...val,
        newCompleteDate: val.completed_on ? val.completed_on : '',
        newCompleteDateForExcel: val.completed_on ? moment(val.completed_on).format('MM/DD/YYYY') : ''
      };
    });
    ress.sort((a,b)=>{
      const dateA = new Date(a.newCompleteDate);
      const dateB = new Date(b.newCompleteDate);
      return dateB - dateA;
    })
    return ress;
  };

  const showPackageName = (item) => {
    return <p>{item.item.package_name ? item.item.package_name : ''}</p>;
  };

  const showReason = (item) => {
    return <p>{item.item.reasons ? item.item.reasons : ''}</p>;
  };

  const showStatus = (item) => {
    return <p>{item.item.event_status ? item.item.event_status : ''}</p>;
  };

  const showScheduleDate = (item) => {
    return (
      <p>{item.item.scheduled_date ? moment(item.item.scheduled_date).format('MM/DD/YYYY') : ''}</p>
    );
  };

  const showDocuments = (item) => {
    return (
      <p>
        {' '}
        {docOccHealthPermission && item.item.event_id && item.item.emp_selected ? (
          // <button onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)}>
          //   View Doc
          // </button>
          <FilePdfTwoTone
            onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)}
            style={{ fontSize: '15px', width: '60px', cursor: 'pointer' }}
            twoToneColor='#FF0000'
          />
        ) : (
          item.item.event_id &&
          item.item.emp_selected && <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
        )}
      </p>
    );
  };

  const showResult = (item) => {
    return <p>{item.item.final_result ? item.item.final_result : ''}</p>;
  };

  const showTestName = (item) => {
    return <p>{item.item.test_name_moved0 ? item.item.test_name_moved0 : ''}</p>;
  };

  const showExpandStatus = (item) => {
    return <p>{item.item.test_status ? item.item.test_status : ''}</p>;
  };

  const showCompledOn = (item) => {
    return (
      <p>{item.item.completed_on ? moment(item.item.completed_on).format('MM/DD/YYYY') : ''}</p>
    );
  };

  const showDoc = (item) => {
    return (
      <p>
        {docOccHealthPermission && item.item.urls && item.item.urls.length > 0
          ? item.item.urls.map((url) => {
              return (
                <>
                  {/* &nbsp;
                  <button
                    style={{ margin: '-2px 0px' }}
                    onClick={() => downloadAttachmentMultiple(url)}
                  >
                    View Doc
                  </button> */}
                  {/* <p style={{color:'blue'}}>View Doc */}
                  <FilePdfTwoTone
                    onClick={() => downloadAttachmentMultiple(url)}
                    style={{ fontSize: '15px', width: '60px', cursor: 'pointer' }}
                    twoToneColor='#FF0000'
                  />
                  {/* </p> */}
                </>
              );
            })
          : item.item.urls &&
            item.item.urls.length > 0 && (
              <CheckSquareTwoTone style={{ color: 'green', fontSize: 15 }} />
            )}
      </p>
    );
  };
  const showMessage = (item) => {
    return (
      <p>
        {' '}
        {item.item.eventNotes != null && item.item.eventNotes.length > 40
          ? item.item.eventNotes.substring(0, 37) + '...'
          : item.item.eventNotes}
      </p>
    );
  };
  const showTdMessage = (item) => {
    return (
      <p>
        {' '}
        {item.item.eventTdNotes != null && item.item.eventTdNotes.length > 40
          ? item.item.eventTdNotes.substring(0, 37) + '...'
          : item.item.eventTdNotes}
      </p>
    );
  };
  const formatExItem = (sender, e) => {
    console.log(sender, e, 'senders::::::::::::');
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    if (e.panel == sender.cells) {
      // console.log('kdsfjds test detail', sender, e.row);
      // Specify the cell you want to add a tooltip to
      if (e.col === 6) {
        if (
          sender.rows[e.row]._data.eventTdNotes &&
          sender.rows[e.row]._data.eventTdNotes.length > 40
        ) {
          tt.setTooltip(e.cell, sender.rows[e.row]._data.eventTdNotes);
        }
      }
    }
    // for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const showLocation = (item) => {
    console.log(item, 'locations test');
    return <p>{item.item.newLocation ? item.item.newLocation : ''}</p>;
  };

  const showDotMode = (item) => {
    console.log(item, 'dot mode');
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  return (
    <div>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={10}>
          <FlexGridSearch
            ref={theSearch}
            placeholder='Search'
            cssMatch=''
            style={{ width: '70%', height: '40px' }}
          />
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
          <div ref={fixedContainerRef}>
            <Select
              style={{ width: 170 }}
              placeholder={`Items per page : ${pageSize}`}
              onChange={paggination}
              options={[
                { label: 'Items per page : 10', value: 10 },
                { label: 'Items per page : 20', value: 20 },
                { label: 'Items per page : 50', value: 50 }
              ]}
              getPopupContainer={() => fixedContainerRef.current}
            />
          </div>
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={10}>
          <Button
            type='primary'
            size='small'
            style={{ backgroundColor: '#3d586a', float: 'right' }}
            disabled={isExcelPreparing}
            onClick={exportToExcel}
          >
            {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
          </Button>
        </Col>
      </Row>

      {/* to export entire data of table */}
      <FlexGrid
        ref={theGrid}
        style={{ display: 'none' }}
        className='dummyWijmoData'
        itemsSource={viewData}
        initialized={gridInitializedData}
        formatItem={formatItem}
        isReadOnly={true}
      >
        <FlexGridColumn binding='newLocation' header='Location' width={275}>
          <FlexGridCellTemplate cellType='Cell' template={showLocation} />
        </FlexGridColumn>
        <FlexGridColumn binding='newDateForExcel' header='Scheduled Date' minWidth={140} width='*'/>
        <FlexGridColumn binding='testing_status' header='Status' width='*' minWidth={150}>
          <FlexGridCellTemplate cellType='Cell' template={showExpandStatus} />
        </FlexGridColumn>
        <FlexGridColumn binding='reasons' header='Reason' minWidth={150} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showReason} />
        </FlexGridColumn>
        <FlexGridColumn binding='package_name' header='Package' minWidth={230} width='*'>
          <FlexGridCellTemplate cellType='Cell' template={showPackageName} />
        </FlexGridColumn>
        <FlexGridColumn binding='program' header='DOT Mode' width={90}>
          <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
        </FlexGridColumn>
        <FlexGridColumn
          binding='eventNotes'
          header='Notes'
          minWidth={150}
          width='*'
        ></FlexGridColumn>
        <FlexGridDetail
          template={(ctx) => (
            <FlexGrid
              id='theGridTallRows'
              autoGenerateColumns={false}
              headersVisibility='Column'
              alternatingRowStep={1}
              // autoRowHeights={true}
              // loadedRows={onloadedRows.bind(}
              itemsSource={getSubItemSource(ctx)}
              itemsPerPage={10}
              isReadOnly={true}
            >
              <FlexGridColumn
                binding='test_name_moved0'
                header='Test Name'
                width='*'
                minWidth={150}
                // wordWrap={true}
              >
                <FlexGridCellTemplate cellType='Cell' template={showTestName} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='final_result'
                header='Result'
                width='*'
                wordWrap={true}
                minWidth={150}
              >
                <FlexGridCellTemplate cellType='Cell' template={showResult} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='eventTdNotes'
                header='Event Notes'
                width='*'
                minWidth={150}
              />
              <FlexGridColumn
                binding='newCompleteDateForExcel'
                header='Completed Date'
                width='*'
                minWidth={150}
              >
              </FlexGridColumn>
            </FlexGrid>
          )}
        />
      </FlexGrid>

      <FlexGrid
        // autoRowHeights={true}
        autoGenerateColumns={false}
        ref={theGrid}
        id='theGridTallRows'
        // className='occtab'
        className='cliniclisttable'
        alternatingRowStep={1}
        itemsSource={view}
        initialized={gridInitialized}
        formatItem={formatItem}
        itemsPerPage={10}
        isReadOnly={true}
        scrollMode='Virtual' // Add this line for smooth scrolling
      >
        <div style={{ display: 'none' }}>
          <wjInput.ListBox
            className='column-picker'
            itemsSource={listboxColumns}
            checkedMemberPath='visible'
            displayMemberPath='header'
            lostFocus={hidePicker}
            initialized={initializedListBox}
          ></wjInput.ListBox>
        </div>
        <FlexGridColumn binding='newLocation' header='Location' width={275}>
          <FlexGridCellTemplate cellType='Cell' template={showLocation} />
        </FlexGridColumn>
        <FlexGridColumn binding='newDate' header='Scheduled Date' width={135}>
          <FlexGridCellTemplate cellType='Cell' template={showScheduleDate} />
        </FlexGridColumn>
        <FlexGridColumn binding='event_status' header='Status' width={100}>
          <FlexGridCellTemplate cellType='Cell' template={showStatus} />
        </FlexGridColumn>
        <FlexGridColumn
          binding='event_id'
          header='Clearance'
          // width='11*'
          width={100}
        >
          <FlexGridCellTemplate cellType='Cell' template={showDocuments} />
        </FlexGridColumn>
        <FlexGridColumn binding='reasons' header='Reason' width={150}>
          <FlexGridCellTemplate cellType='Cell' template={showReason} />
        </FlexGridColumn>
        <FlexGridColumn binding='package_name' header='Package' width={265}>
          <FlexGridCellTemplate cellType='Cell' template={showPackageName} />
        </FlexGridColumn>
        <FlexGridColumn binding='program' header='DOT Mode' width={90}>
          <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
        </FlexGridColumn>
        <FlexGridColumn binding='eventNotes' header='Notes' width={310}>
          <FlexGridCellTemplate cellType='Cell' template={showMessage} />
        </FlexGridColumn>
        <FlexGridDetail
          template={(ctx) => (
            <div style={{ overflowX: 'auto', transition: 'width 0.5s ease' }}>
              <FlexGrid
                id='theGridTallRows'
                autoGenerateColumns={false}
                headersVisibility='Column'
                alternatingRowStep={1}
                // autoRowHeights={true}
                // loadedRows={onloadedRows.bind(}
                itemsSource={getSubItemSource(ctx)}
                itemsPerPage={10}
                isReadOnly={true}
                formatItem={formatExItem}
                scrollMode='Virtual' // Add this line for smooth scrolling
              >
                <FlexGridColumn
                  binding='test_name_moved0'
                  header='Test Name'
                  // width='*'
                  // minWidth={150}
                  width={268}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showTestName} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='final_result'
                  header='Result'
                  // width='*'
                  width={135}
                  wordWrap={true}
                  // minWidth={150}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showResult} />
                </FlexGridColumn>

                <FlexGridColumn binding='testing_status' header='Status' width={100}>
                  <FlexGridCellTemplate cellType='Cell' template={showExpandStatus} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='newCompleteDate'
                  header='Test Date'
                  // width='*'
                  // minWidth={150}
                  width={100}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showCompledOn} />
                </FlexGridColumn>
                <FlexGridColumn binding='urls' header='Documents' width={150}>
                  <FlexGridCellTemplate cellType='Cell' template={showDoc} />
                </FlexGridColumn>
                <FlexGridColumn binding='eventTdNotes' header='Notes' width={700}>
                  <FlexGridCellTemplate cellType='Cell' template={showTdMessage} />
                </FlexGridColumn>

                <FlexGridFilter
                  filterColumns={[
                    'test_name_moved0',
                    'final_result',
                    'testing_status',
                    'newCompleteDate'
                    // 'newLocation'
                  ]}
                />
              </FlexGrid>
            </div>
          )}
          isAnimated={true} // Add this if you want to animate expansion
        />

        <FlexGridFilter
          filterColumns={[
            'reasons',
            'package_name',
            'event_status',
            'newDate'
            //   'event_id'
          ]}
        />
      </FlexGrid>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div
          style={{
            backgroundColor: '#425a81',
            color: '#fff',
            display: 'inline',
            borderRadius: '5px',
            height: '30px'
          }}
        >
          <CollectionViewNavigator
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
            headerFormat='Page {currentPage:n0} of {pageCount:n0}'
            byPage={true}
            cv={view}
          />
        </div>
        &nbsp;&nbsp;&nbsp;
        <p
          style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}
          ref={filterRefCounter}
          id='filterCounter'
        >
          {Globalize.format(filterRefCounter.current, 'n0')}
        </p>
        &nbsp;
        <p style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}>
          {' '}
          out of {occHealthData.length}
        </p>
      </div>
    </div>
  );
};

export default OccHealthEmployee;
