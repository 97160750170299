
import React, { useEffect, useState, useRef } from 'react';
import {
  Dropdown,
  Menu,
  message,
  Modal,
  Button,
  Input,
  Form,
  Space,
  Spin,
  notification
} from 'antd';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import setting from '../../../config/setting';
import logoIcon from '../../../assets/img/whiteUserIcon.png';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
const { Item } = Form;

const SettingsDropdown = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmPass, setConfirmPass] = useState('');
  const [passNew, setPassNew] = useState('');
  const [currentPass, setCurrentPass] = useState('');
  const [loader, setLoader] = useState(false);
  const [invalidCredential, setInvalidCredential] = useState('');
  const [showDropDown, setShowDropDown] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  const handleMenuClick = (e) => {
    document.querySelector('.ant-dropdown-menu').style.display = 'none';
    if (e.key === 'logout') {
      setShowDropDown(false);
      localStorage.clear();
    } else if (e.key === 'change_password') {
      setShowDropDown(false);
      setOpen(true);
    }
  };

  const accountMenu = (
    <Menu style={{ minWidth: '180px' }} onClick={handleMenuClick}>
      <Menu.Item key='change_password' className='d-flex w-100'>
        <span
          className='icofont-lock'
          style={{
            backgroundColor: '#379',
            borderRadius: 40,
            fontSize: 15,
            color: 'white',
            padding: 5,
            marginRight: 0
          }}
        />{' '}
        &nbsp; &nbsp;
        <span>Change Password</span>
      </Menu.Item>
      <Menu.Item key='logout' className='d-flex w-100'>
        <NavLink className='d-flex w-100' to='/public/sign-in' replace>
          <span
            className={`icon mr-2 icofont-logout`}
            style={{
              backgroundColor: '#379',
              borderRadius: 40,
              fontSize: 15,
              color: 'white',
              padding: 5
            }}
          />
          <span> Log Out</span>
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const onFinish = async () => {
    setEnableSubmit(true);
    setShowDropDown(false);
    setLoader(true);
    if (passNew === confirmPass) {
      setEnableSubmit(false);

      try {
        // Validate current password
        if (currentPass !== localStorage.getItem('myPass')) {
          throw new Error('Current password is incorrect!');
        }

        const response = await axios.post(setting.serverUrl + '/api/VerifyForgotOTP', {
          params: {
            email: localStorage.getItem('myData'),
            user: localStorage.getItem('myData'),
            pass: localStorage.getItem('myPass'),
            otp: 'default',
            newPass: passNew
          }
        });

        if (response.data.status === 'Done') {
          setLoader(false);
          setShowDropDown(false);
          setEnableSubmit(false);
          localStorage.setItem('myPass', passNew);
          notification.success({
            message: 'Your password has been changed successfully !'
          });
          setOpen(false);
        } else {
          throw new Error('Failed to change password due to technical error !');
        }
      } catch (error) {
        console.error(error);
        notification.error({
          message: error.message || 'Failed to change password !'
        });
        setEnableSubmit(true); // Enable submit button if an error occurs
      } finally {
        setLoader(false);
      }
    } else {
      setInvalidCredential("Password doesn't match !");
      setLoader(false);
    }
  };

  const password = (event) => {
    const password = event.target.value;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{12,}$/;
    const isValid = regex.test(password);
    setPassNew(password);
    const error = document.getElementById('passwordError');
    if (isValid) {
      error.style.display = 'none';
    } else {
      error.style.display = 'block';
    }
    setEnableSubmit(!isValid || password !== confirmPass || password === '' || currentPass === '');
  };

  const passwordConfirm = (event) => {
    setConfirmPass(event.target.value);
    if (passNew === event.target.value) {
      setInvalidCredential('');
      setEnableSubmit(passNew === '' || passNew !== confirmPass || passNew === '' || currentPass === '');
    } else {
      setEnableSubmit(true);
    }
  };

  const handleChangePass = () => {
    form.resetFields();
    setOpen(false);
    setPassNew('');
    setConfirmPass('');
    setInvalidCredential('');
    setCurrentPass('');
    document.querySelector('.ant-dropdown-menu').style.display = 'block';
    document.getElementById('passwordError').style.display = 'none';
  };

  return (
    <div ref={dropdownRef}>
      <Dropdown
        overlay={accountMenu}
        placement='bottomRight'
        visible={showDropDown}
        onClick={() => setShowDropDown(true)}
      >
        <div className='item'>
          <img src={logoIcon} style={{ height: 15, width: 15, margin: 8 }} />
          <span className='icofont-simple-down' style={{ color: 'white' }} />
          <Modal
            title='Change Password'
            centered
            visible={open}
            maskClosable={false}
            onOk={() => setOpen(false)}
            keyboard={false}
            onCancel={handleChangePass}
            footer={null}
            width={600}
          >
            <Form form={form} layout='vertical' className='mb-4' onFinish={onFinish}>
              <p id='passwordError' style={{ color: 'red', display: 'none' }}>
                Password must be at least 12 characters and contain at least one uppercase letter,
                one lowercase letter, one number, and one symbol
              </p>
              {invalidCredential && <span style={{ color: 'red', marginBottom: 10 }}>{invalidCredential}</span>}
              <p style={{ color: '#161717', paddingBottom: 0, marginBottom: 10 }}>Current Password</p>
              <Item
                name='currentpassword'
                value={currentPass}
                rules={[{ required: true, message: 'Please enter current password !' }]}
              >
                <Input.Password
                  placeholder='Current Password'
                  type='password'
                  onChange={(e) => setCurrentPass(e.target.value)}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  style={{
                    backgroundColor: 'white',
                    WebkitBoxShadow: '0 0 0 1000px white inset !important '
                  }}
                />
              </Item>
              <p style={{ color: '#161717', paddingBottom: 0, marginBottom: 10 }}>Enter new password</p>
              <Item
                name='password'
                value={passNew}
                rules={[{ required: true, message: 'Please enter password !' }]}
              >
                <Input.Password
                  placeholder='Password'
                  type='password'
                  onChange={password}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  style={{
                    backgroundColor: 'white',
                    WebkitBoxShadow: '0 0 0 1000px white inset !important '
                  }}
                />
              </Item>
              <p style={{ color: '#161717', paddingBottom: 0, marginBottom: 10 }}>Confirm password</p>
              <Item
                name='newpassword'
                value={confirmPass}
                rules={[{ required: true, message: 'Please enter password !' }]}
              >
                <Input.Password
                  placeholder='Confirm Password'
                  type='password'
                  onChange={passwordConfirm}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  style={{
                    backgroundColor: 'white',
                    WebkitBoxShadow: '0 0 0 1000px white inset !important '
                  }}
                />
              </Item>
              <Button
                block={false}
                disabled={!enableSubmit}
                type='primary'
                htmlType='submit'
              >
                Submit
              </Button>
              &nbsp;&nbsp;&nbsp;
              {loader && (
                <Space size='middle'>
                  <Spin size='large' />
                </Space>
              )}
            </Form>
          </Modal>
        </div>
      </Dropdown>
    </div>
  );
};

export default SettingsDropdown;
