import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { IMenuItem, IMenuItemSub } from '../../../interfaces/main-menu';
import classNames from '../../../utils/class-names';
import setting from '../../../config/setting';
import MenuGroupTitle from './GroupTitle';
import ItemWithSub from './ItemWithSub';
import SimpleItem from './SimpleItem';

import './Menu.scss';
import { routerMiddleware } from 'connected-react-router';

type MenuProps = {
  orientation?: 'vertical' | 'horizontal';
  data?: IMenuItem[];
  children?: any;
  opened?: boolean;
  onCloseSidebar?: () => void;
  className?: string;
};

type RouterProps = {
  location: Location;
};

type Props = (RouterProps & MenuProps) | any;

const haveActive = (sub: IMenuItemSub[], route: string) =>
  !!sub.find((item) => item.routing === route);

const Menu = ({
  data,
  orientation,
  location,
  children,
  className,
  onCloseSidebar,
  opened
}: Props) => {
  const [menu, setMenu] = useState<IMenuItem[]>([]);
  const [navBarCondition, setNavBarCondition] = useState([]);
  const hasRoutes = (route) => {
    return data ? data.filter((item) => item.routing === route).length > 0 : false;
  };
  useEffect(() => {
    setMenu(data);
  }, [data]);

  useEffect(() => {
    //const currentRoute = location.pathname.split('/')[2];
    let currentRoute = location.pathname.split('/')[2];
    if (hasRoutes(currentRoute)) {
      localStorage.setItem('selectedMenu', currentRoute);
    } else {
      currentRoute = localStorage.getItem('selectedMenu');
    }
    const updatedMenu = data
      ? data.map((item) => {
          if (item.sub) {
            return { ...item, active: haveActive(item.sub, currentRoute) };
          }

          return { ...item, active: item.routing === currentRoute };
        })
      : [];

    setMenu(updatedMenu);
  }, [location, data]);

  useEffect(() => {
    onCloseSidebar && opened && onCloseSidebar();
  }, [location]);

  useEffect(() => {
    console.log('setting in nav', setting);
    axios
      .post(setting.serverUrl + '/api/getNavbarTabs', {
        params: {
          customerId: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        console.log('response of navbar', response);
        if (response && response.status == 200) {
          const transformedArray = Object.keys(response.data[0]).map((key) => {
            return {
              navbarname: key,
              status: response.data[0][key]
            };
          });
          console.log('klsdjf', transformedArray);
          setNavBarCondition(transformedArray);
        }
      });
  }, []);

  const handleItemClick = (itemTitle: string) => {
    const updateMenu = [...menu];

    for (let item of updateMenu) {
      if (item.title !== itemTitle) {
        continue;
      }

      item.active = !item.active;
      break;
    }

    setMenu(updateMenu);
  };

  const menuClasses = classNames({
    'main-menu': true,
    horizontal: orientation === 'horizontal'
  });
  const menuItems = menu.map((item: IMenuItem, i: number) => {
    if (item.groupTitle) {
      return <MenuGroupTitle key={i} title={item.title} />;
    }

    if (item.sub) {
      return (
        <ItemWithSub
          key={i}
          layout={orientation}
          sub={item.sub}
          title={item.title}
          location={location}
          opened={item.active}
          onClick={handleItemClick}
        />
      );
    }

    const showNavBar = () => {
      // console.log('inside shownavbar', item.title);
      return(
      <SimpleItem
      key={i}
      icon={item.icon}
      layout={orientation}
      title={item.title}
      routing={item.routing}
      image={item.image}
      isActive={item.active}
    />)
    };

    if(item.title == 'Scheduled Events') {
      return showNavBar();
    } else if (item.title == 'Employees') {
      return showNavBar();
    } else if (item.title == 'Dashboard') {
      return showNavBar();
    }else if (item.title == 'Notifications') {
      return showNavBar();
    } else if (item.title == 'New Order') {
      return showNavBar();
    } 

    if (navBarCondition && navBarCondition.length > 0) {
     return navBarCondition.map((val) => {
        // console.log('item status', val.navbarname, item.title, val.status);
        if (val.navbarname == 'audiogram' && item.title == 'Audiogram' && val.status == true) {
          return showNavBar();
        } else if (
          val.navbarname == 'backgrounds' &&
          item.title == 'Backgrounds' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (val.navbarname == 'clinic' && item.title == 'Clinic' && val.status == true) {
          return showNavBar();
        } else if (
          val.navbarname == 'covid_exemptions' &&
          item.title == 'COVID Exemptions' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'covid_reports' &&
          item.title == 'COVID Reports' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'covid_test' &&
          item.title == 'COVID Testing' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'covid_vac' &&
          item.title == 'COVID Vaccinations' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'covid_vulnerable' &&
          item.title == 'COVID Vulnerable' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'driver_qualifications' &&
          item.title == "Driver's Qualification" &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'drug_and_alcohol' &&
          item.title == 'Drug & Alcohol' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'eap_program' &&
          item.title == 'EAP Program' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'occ_health' &&
          item.title == 'Health Surveillance' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'protocols' &&
          item.title == 'Protocols' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'random_pool' &&
          item.title == 'Random Pools' &&
          val.status == true
        ) {
          return showNavBar();
        }  else if (
          val.navbarname == 'fit_test' &&
          item.title == 'Fit Testing' &&
          val.status == true
        ) {
          return showNavBar();
        }else if (
          val.navbarname == 'osha_questionnaire' &&
          item.title == 'OSHA Questionnaire' &&
          val.status == true
        ) {
          return showNavBar();
        } else if (
          val.navbarname == 'pft_test' &&
          item.title == 'PFT Test' &&
          val.status == true
        ) {
          return showNavBar();
        }
      });
    }


    
  });

  return (
    <div className={`${menuClasses} ${!!className && className}`}>
      {children}
      {!!menuItems.length && (
        <nav className='main-menu-wrap'>
          <ul className='menu-ul'>{menuItems}</ul>
        </nav>
      )}
    </div>
  );
};

export default withRouter(Menu);
