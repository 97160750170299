import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Spin, Space, Row, Col, Divider, List, Avatar, Card, Modal,Input, Tooltip,Table,Button } from 'antd';
import GoogleMapReact from 'google-map-react';
import { GoogleMap, LoadScript, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
// import ReactEcharts from 'echarts-for-react';
// import { history } from '../../../redux/store';
// import AppointmentsTable from '../../../layout/components/appointmentsTable/AppointmentsTable';
// import hospitalOptions from './charts/hospital-options';
import axios from "axios";
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting'
import { useLocation } from "react-router-dom";
import { CardHeader } from '@material-ui/core';
// import { incomeInWeek, incomeInMonth } from './charts/income-options';
// import Geocode from "react-geocode";





const DrugAndAlcoholDetail = () => {

//   const [appointments] = useFetchPageData<IAppointment[]>('./data/last-appointments.json', []);
  // usePageData(pageData);
  const [ClinicList, setClinicList] = useState([]);
  const [change, setChange] = useState(false);
  const [event, setEvent] = useState([]);
  const [ret, setRet] = useState(false);
  const [compLength, setCompLength] = useState(0);
  const [testLength, setTestLength] = useState(-1);
  const [employeeLength, setEmployeeLength] = useState(0);
  const [notLength, setNotLength] = useState(0);
  const [compData, setCompData] = useState([]);
  const [Res, setRes] = useState([]);
  const [loc, setLoc] = useState([]);
  const [locNew, setLocNew] = useState([]);
  const [arr, setArr] = useState([]);
  const [zipcodeVal, setzipcodeVal] = useState('');
  
  const [zipcodeValTrue, setzipcodeValTrue] = useState(false);

  const [ch, setCh] = useState(false);
  const [selected, setSelected] = useState({});
  const [sel, setsel] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(false);
const [ClinicListFilter, setClinicListFilter] = useState([]);
const [ClinicListTrue, setClinicListTrue] = useState(false);

const location = useLocation();
  const propsData = location.state;

  useEffect(() => {

    console.log("this.props test details:::",propsData);
    //getData();
    // notifications()

  }, [])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };



//   function getData() {
//     // setClinicList([])
//     setClinicListTrue(false)

//     console.log("getData :: ", localStorage.getItem('myData'), localStorage.getItem('myPass'))
//     //console.log("setting.serverUrl",setting.serverUrl)
//     axios.get(setting.serverUrl + '/api/getClinics', {
//         // axios.get('http://localhost:3001/api/getClinics', {
//         params:
//         {
//           email: localStorage.getItem('myData'),
//           user: localStorage.getItem('myData'),
//           pass: localStorage.getItem('myPass')
//         }
//       }).then(response => {
//         console.log("Respone from getClinics:::", response.data)
  
//         if (response && response.status == 200) {
  
//           setClinicList(response.data)
//           setChange(!change)
  
//         }
//       })

//   }

//   console.log("ClinicList::",ClinicList)

const handleClinicHours =(data)=>{

console.log("data handleClinicHours::",data)

// setIsModalVisible(true);
// Modal.info({
//     title: <b>{data.clinic_name + " Open Close Hours"} </b>,
//     // title: 'Clinic Hours',
//     width: '50%',
//     content: (
//       <div style={{fontSize: 15}}>
//          <br/>
//         <Row>
//         <Col span={12}>
//         <p>Monday Open Time : {data.mon_open_time}:00 hrs</p>
//         </Col>
//         <Col span={12}>
//         <p>Monday Close Time : {data.mon_close_time}:00 hrs</p>
//         </Col>
//         </Row>
//         <br/>
//         <Row>
//         <Col span={12}>
//         <p>Tuesday Open Time : {data.tues_open_time}:00 hrs</p>
//         </Col>
//         <Col span={12}>
//         <p>Tuesday Close Time : {data.tues_close_time}:00 hrs</p>
//         </Col>
//         </Row>
//         <br/>
//         <Row>
//         <Col span={12}>
//         <p>Wednesday Open Time : {data.wed_open_time}:00 hrs</p>
//         </Col>
//         <Col span={12}>
//         <p>Wednesday Close Time : {data.wed_close_time}:00 hrs</p>
//         </Col>
//         </Row>
//         <br/>
//         <Row>
//         <Col span={12}>
//         <p>Thursday Open Time : {data.thr_open_time}:00 hrs</p>
//         </Col>
//         <Col span={12}>
//         <p>Thursday Close Time : {data.thr_close_time}:00 hrs</p>
//         </Col>
//         </Row>
//         <br/>
//         <Row>
//         <Col span={12}>
//         <p>Friday Open Time : {data.fri_open_time}:00 hrs</p>
//         </Col>
//         <Col span={12}>
//         <p>Friday Close Time : {data.fri_close_time}:00 hrs</p>
//         </Col>
//         </Row>
//         <br/>
      
//       </div>
//     ),
//     onOk() {},
//   });

}

const actions = (data) => (
    <div className='buttons-list nowrap'>
      <Tooltip title="Clinic Information">
        <Button shape='circle' 
        onClick={() => handleClinicHours(data)} 
        type='primary'
          style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            color: '#336cfb'
          }}
        >
          <span className='icofont icofont-info-circle' style={{ fontSize: 25 }} />
        </Button>
      </Tooltip>
      
    </div>
  );

const columns = [
    {
      title: 'Clinic Name',
      key: 'clinic_name',
      dataIndex: 'clinic_name',
      sorter: (a, b) => a.clinic_name.length - b.clinic_name.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (clinic_name) => (
        <span className='nowrap'>
          {clinic_name}
        </span>
      )
    },
    {
        key: 'street',
        dataIndex: 'street',
        title: 'Clinic Address',
        // sorter: (a, b) => a.street.length - b.street.length,
        // sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (text, record) => <span>{record.street  + "," + (record.street2 == false ? '' : record.street2
         + "," ) + record.city + "," + record.state_id[1]
         + "," + record.zip}</span>
      },
    {
      title: 'Phone Number',
      key: 'phone',
      dataIndex: 'phone',
      sorter: (a, b) => a.phone.length - b.phone.length,
      // title: 'ID',
      // sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (phone) => (
        <span className='nowrap'>
          {phone && <i class="icofont-phone"></i>}&nbsp;{phone}
        </span>
      )
    },
    {
        key: 'actions',
        title: 'Actions',
        render: actions
      }
    
  
    // {
    //   key: 'testing_status',
    //   dataIndex: 'testing_status',
    //   title: 'Testing Status',
    //   sorter: (a, b) => a.testing_status.length - b.testing_status.length,
    //   // sorter: (a, b) => (a.name > b.name ? 1 : -1),
    //   render: (testing_status) => <strong>{testing_status}</strong>
    // },
    // {
    //   key: 'test_date',
    //   dataIndex: 'test_date',
    //   title: 'Covid Test Date',
    //   sorter: (a, b) => moment(a.test_date).unix() - moment(b.test_date).unix(),
    //   // sorter: (a, b) => a.age - b.age,
    //   render: (test_date) => (
    //     <span className='nowrap'>
    //       <strong> {test_date ? moment(test_date).format("MM/DD/YYYY") : ''}</strong>
    //     </span>
    //   )
    // },
    
    
  
  
  ];
  
  
// let covidTestData =[{refrence_id:'WSSCOV1',first_name:'Tushar',test_name:'Molecular (RT-PCR) tests',test_status:'Pending',create_date:'11/16/2021 5:26 PM'},
// {refrence_id:'WSSCOV2',first_name:'Surender',test_name:'COVID-19 Antigen Tests',test_status:'Pending',create_date:'11/16/2021 5:26 PM'},
// {refrence_id:'WSSCOV3',first_name:'Mahesh',test_name:'COVID-19 Antibody Tests',test_status:'Approved',create_date:'11/16/2021 5:26 PM'},
// {refrence_id:'WSSCOV4',first_name:'Saurabh',test_name:'Molecular (RT-PCR) tests',test_status:'Pending',create_date:'11/14/2021 10:26 PM'},
// {refrence_id:'WSSCOV5',first_name:'Surender',test_name:'COVID-19 Antigen Tests',test_status:'Pending',create_date:'11/11/2021 9:26 AM'},
// {refrence_id:'WSSCOV6',first_name:'John',test_name:'COVID-19 Antibody Tests',test_status:'Approved',create_date:'10/19/2021 9:26 AM'},
// {refrence_id:'WSSCOV7',first_name:'Williams',test_name:'Molecular (RT-PCR) tests',test_status:'Pending',create_date:'10/9/2021 5:26 PM'},
// {refrence_id:'WSSCOV8',first_name:'Varun',test_name:'COVID-19 Antigen Tests',test_status:'Approved',create_date:'10/9/2021 5:26 PM'},
// {refrence_id:'WSSCOV9',first_name:'Joe',test_name:'COVID-19 Antibody Tests',test_status:'Pending',create_date:'10/9/2021 5:26 PM'}

// ]

// const zipcode = (e)=>{
//     console.log("zipcode:::",e.target.value.length)
//     if(e.target.value > 0){
//     setClinicListTrue(false)
//     // setClinicList([]);
//     console.log("zipcode:::",e.target.value)
//     setzipcodeVal(e.target.value)
//     setzipcodeValTrue(!zipcodeValTrue)

//     let clinicListFilterArr = ClinicList.filter(function(data) {
//         return data.zip.includes(e.target.value);
//     })

//     console.log("clinicListFilterArr::",clinicListFilterArr)
//     console.log("ClinicList::",ClinicList)

//     setClinicListFilter(clinicListFilterArr);
//     setClinicListTrue(true)
// }else{
//     setzipcodeVal(e.target.value)
//     setzipcodeValTrue(!zipcodeValTrue)
//     getData()
// }
// }

  return (
    <div style={{padding: 20}} >

     

      {/* {ClinicList.length > 0 ? */}
        <div style={{backgroundColor: "white", borderRadius:20, padding: 20}}>
          <Card 
          title={ propsData && propsData.test_name+ " Details"} 
         
          // title={propsData && propsData.clinic_name + " Details"} 
          >
  
            <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Reference ID :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.refrence_id && propsData.refrence_id}</p>
         </div>
         </div>
         </div>
         
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Order Number :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.order_no && propsData.order_no}</p>
         </div>
         </div>
         </div>
         </div>
        
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>DOT classification :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.dot_classification && propsData.dot_classification}</p>
         </div>
         </div>
         </div>
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Item Name :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.test_name && propsData.test_name}</p>
         </div>
         </div>
         </div>
         </div>
        
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Package :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.service && propsData.service}</p>
         </div>
         </div>
         </div>
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Testing Status :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.test_status && propsData.test_status}</p>
         </div>
         </div>
         </div>
         </div>
         <br/>
         <hr/>
         <br/>
         <h4><u>Customer</u></h4>
         <div className='row' style={{fontSize: 15}}>
         
              <div className='col-md-6' >
              
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Company Name :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.company && propsData.company[1]}</p>
         </div>
         </div>
         </div>
         
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Account Number :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.acc_number && propsData.acc_number}</p>
         </div>
         </div>
         </div>
         </div>
         
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Location :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.child_ids && propsData.child_ids[1]}</p>
         </div>
         </div>
         </div>
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Location Code :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.loc_id && propsData.loc_id}</p>
         </div>
         </div>
         </div>
         </div>
        
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Select Employee :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.emp_selected && propsData.emp_selected[1]}</p>
         </div>
         </div>
         </div>
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Lab Account :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.lab_account && propsData.lab_account}</p>
         </div>
         </div>
         </div>
         </div>
         <br/>
         <hr/>
         <br/>
         <h4><u>Participant</u></h4>
         <div className='row' style={{fontSize: 15}}>
         
              <div className='col-md-6' >
              
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>SSN :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.emp_ssn && propsData.emp_ssn}</p>
         </div>
         </div>
         </div>
         
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>EIN :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.emp_ein && propsData.emp_ein}</p>
         </div>
         </div>
         </div>
         </div>
        
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Alternate ID :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.alternate_id && propsData.alternate_id}</p>
         </div>
         </div>
         </div>
         {/* <div className='col-md-6' >
             
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Item Name :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.test_name}</p>
         </div>
         </div>
         </div> */}
         </div>
         
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>First Name :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.first_name && propsData.first_name}</p>
         </div>
         </div>
         </div>
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Last Name :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.last_name && propsData.last_name}</p>
         </div>
         </div>
         </div>
         </div>
         
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>DOB :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.dob && propsData.dob}</p>
         </div>
         </div>
         </div>
        
         </div>
        
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Email Address :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.email && propsData.email}</p>
         </div>
         </div>
         </div>
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Phone :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.phone && propsData.phone}</p>
         </div>
         </div>
         </div>
         </div>
        
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Phone2 :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.phone2 && propsData.phone2}</p>
         </div>
         </div>
         </div>
        
         </div>
         <br/>
         <hr/>
         <br/>
         <h4><u>Participant Address</u></h4>
         
         <h5>Collection:</h5>
        
         <div className='row' style={{fontSize: 15}}>
         
              <div className='col-md-6' >
              
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Panel :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.panel && propsData.panel[1]}</p>
         </div>
         </div>
         </div>
         
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Collection Site :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.type_of_location && propsData.type_of_location}</p>
         </div>
         </div>
         </div>
         </div>
         
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Reason :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.reasons && propsData.reasons}</p>
         </div>
         </div>
         </div>
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Order Status :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.order_status && propsData.order_status}</p>
         </div>
         </div>
         </div>
         </div>
        
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Collected :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.collected && propsData.collected}</p>
         </div>
         </div>
         </div>
         {/* <div className='col-md-6' >
              
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Testing Status :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.test_status}</p>
         </div>
         </div>
         </div> */}
         </div>

         
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>DOT Agency :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.dot_agency && propsData.dot_agency}</p>
         </div>
         </div>
         </div>
         <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Origin :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.origin && propsData.origin}</p>
         </div>
         </div>
         </div>
         </div>
         
         <div className='row' style={{fontSize: 15}}>
              <div className='col-md-6' >
              {/* <h4><u>Vital Information</u></h4> */}
        
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Fullfillment Type :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.fullfillment_type && propsData.fullfillment_type}</p>
         </div>
         </div>
         </div>
         {/* <div className='col-md-6' >
              
           
           <div className='row'>
              <div className='col-md-4' >
           <b>Testing Status :</b>
           </div>
           <div className='col-md-8' >
           <p>{propsData && propsData.test_status}</p>
         </div>
         </div>
         </div> */}
         </div>
         <br/>
         <hr/>
         <br/>
         
           {/* <div style={{fontSize: 15}}>   
          <h4><u>Day/Hours of Operation</u></h4>
          <div className='row'>
          
              <div className='col-md-6' >
         <p><b>Monday Open Time :</b> {propsData && propsData.mon_open_time}:00 hrs</p>
         </div>
         <div className='col-md-6' >
         <p><b>Monday Close Time :</b> {propsData && propsData.mon_close_time}:00 hrs</p>
         </div>
         </div>
         <br/>
         <div className='row'>
         <div className='col-md-6' >
         <p><b>Tuesday Open Time :</b> {propsData && propsData.tues_open_time}:00 hrs</p>
         </div>
         <div className='col-md-6' >
         <p><b>Tuesday Close Time :</b> {propsData && propsData.tues_close_time}:00 hrs</p>
         </div>
         </div>
         <br/>
         <div className='row'>
         <div className='col-md-6' >
         <p><b>Wednesday Open Time :</b> {propsData && propsData.wed_open_time}:00 hrs</p>
         </div>
         <div className='col-md-6' >
         <p><b>Wednesday Close Time :</b> {propsData && propsData.wed_close_time}:00 hrs</p>
         </div>
         </div>
         <br/>
         <div className='row'>
         <div className='col-md-6' >
         <p><b>Thursday Open Time :</b> {propsData && propsData.thr_open_time}:00 hrs</p>
         </div>
         <div className='col-md-6' >
         <p><b>Thursday Close Time :</b> {propsData && propsData.thr_close_time}:00 hrs</p>
         </div>
         </div>
         <br/>
         <div className='row'>
         <div className='col-md-6' >
         <p><b>Friday Open Time :</b> {propsData && propsData.fri_open_time}:00 hrs</p>
         </div>
         <div className='col-md-6' >
         <p><b>Friday Close Time :</b> {propsData && propsData.fri_close_time}:00 hrs</p>
         </div>
         </div>
         <br/>
      
       </div> */}
 
             
          </Card>

        
        </div>
        {/* :
     
        <Row>
          <Col span={10}>
          </Col>
          <Col span={8}>
            <Space size="large" style={{ marginTop: 30 }}>

              <Spin size="large" />
         
        </Space>
          </Col>
        </Row>
} */}

    </div>
  );
};



export default DrugAndAlcoholDetail;