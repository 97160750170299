import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Button, Modal } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { CheckSquareTwoTone, FolderViewOutlined,FolderOpenTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import setting from '../../../config/setting';
import styled, { css } from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';
import { format, parseISO } from 'date-fns';
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

let columnPicker = [];
let flexes = '';
let showGrid = '';
let dragOver = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'TestDetail_FitTest.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const FitTestEmp = ({
  data,
  docOccHealthPermission,
  downloadClearance,
  downloadAttachmentMultiple
}) => {
  const [view, setView] = useState();
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewData, setViewData] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [imageUrls, setImageUrls] = useState([]);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [occFitData, setOccFitData] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [typecheckValue, setTypeCheckValue] = useState('');

  const theGrid = useRef();
  const theSearch = useRef();
  const filterRefCounter = useRef(null);
  const fixedContainerRef = useRef(null);
  const handleCancel = () => {
    setIsModalVisible(false);
    setImageUrls([]);
  };

  useEffect(() => {
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
    // const ress =  data.map((k) => {
    //     return {
    //       ...k,
    //       fullName: k.first_name + ' ' + k.last_name,
    //       newLocation:
    //         k.child_ids.length > 0 && k.child_ids[1].includes('|')
    //           ? k.child_ids[1].split('|')[1]
    //           : k.child_ids[1].split(',')[1],
    //       newCollected: k.testing_date != false ? k.testing_date && moment(k.testing_date).format('MM/DD/YYYY hh:mm'): "",
    //       newCcf: k.ccf == false ? ' ' : k.ccf,
    //       fit_Model :k.fit_Model != false ? k.fit_Model : "",
    //       fit_Size :k.fit_Size != false ? k.fit_Size : "",
    //       fit_Style :k.fit_Style != false ? k.fit_Style : "",
    //       testing_status :k.testing_status != false ? k.testing_status : "",
    //       expiry_date: k.expiry_date != false ? moment(k.expiry_date).format('MM/DD/YYYY'):"",
    //       fitTestPdf: k.fitTestPdf != false ? k.fitTestPdf : '',
    //       fitTestCard: k.fitTestCard != false ? k.fitTestCard : '',
    //       fitTestType: k.fit_Test_Type != false ? k.fit_Test_Type : '',
    //       fitManufacturer: k.fit_Manufacturer != false ? k.fit_Manufacturer : '',
    //       finalResult: k.final_result != false ? k.final_result : '',
    //       program: k.emp_data[0].program != false ? k.emp_data[0].program : '',
    //       mode: k.emp_data[0].mode != false ? k.emp_data[0].mode : ''
    //     };
    //   });

    setOccFitData(data);
  }, []);
  useEffect(() => {
    if (occFitData.length > 0) {
      const collectionView1 = new CollectionView(occFitData, {
        pageSize: occFitData.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(occFitData, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      setView(collectionView2);
    }
  }, [occFitData, pageSize]);
  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    console.log('grids.hostelement', showGrid);
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  // const showDocuments = (item) => {
  //   return (
  //     <p>
  //       {' '}
  //       {docOccHealthPermission && item.item.event_id && item.item.emp_selected ? (
  //         <button onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)}>
  //           View Doc
  //         </button>
  //       ) : (
  //         item.item.event_id &&
  //         item.item.emp_selected && <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
  //       )}
  //     </p>
  //   );
  // };

  const showResult = (item) => {
    return (
      <span>
        {item.item.finalResultFit === 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'green', margin: 0 }}>{item.item.finalResultFit}</p>
            {item && item.item && item.item.fitTestPdf != null ? (
              <FolderOpenTwoTone twoToneColor="#cc7a00"
                style={{ color: 'green', fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => {
                  //  e.stopPropagation();
                  download(e, item.item.fitTestPdf);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultFit === 'POSITIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'red', margin: 0 }}>{item.item.finalResultFit}</p>
            {item && item.item && item.item.fitTestPdf != null ? (
              <FolderOpenTwoTone twoToneColor="#cc7a00"
                style={{ color: 'green', fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => {
                  // e.stopPropagation();
                  download(e, item.item.fitTestPdf);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultFit !== 'POSITIVE' && item.item.finalResultFit !== 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'orange', margin: 0 }}>{item.item.finalResultFit}</p>
            {item && item.item && item.item.fitTestPdf != null ? (
              <FolderOpenTwoTone twoToneColor="#cc7a00"
                style={{ color: 'green', fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={(e) => {
                  //e.stopPropagation();
                  download(e, item.item.fitTestPdf);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          ''
        )}
      </span>
    );
  };

  const showOshaResult = (item) => {
    return (
      <span>
        {item.item.finalResultOsha === 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'green', margin: 0 }}>{item.item.finalResultOsha}</p>
            {item && item.item && item.item.osha_doc != null ? (
              <FolderOpenTwoTone twoToneColor="#cc7a00"
                style={{ color: 'green', fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.osha_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultOsha === 'POSITIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'red', margin: 0 }}>{item.item.finalResultOsha}</p>
            {item && item.item && item.item.osha_doc != null ? (
              <FolderOpenTwoTone twoToneColor="#cc7a00"
                style={{ color: 'green', fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.osha_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultOsha !== 'POSITIVE' && item.item.finalResultOsha !== 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'orange', margin: 0 }}>{item.item.finalResultOsha}</p>
            {item && item.item && item.item.osha_doc != null ? (
              <FolderOpenTwoTone twoToneColor="#cc7a00"
                style={{ color: 'green', fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.osha_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          ''
        )}
      </span>
    );
  };
  const showPftResult = (item) => {
    return (
      <span>
        {item.item.finalResultPft === 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'green', margin: 0 }}>{item.item.finalResultPft}</p>
            {item && item.item && item.item.pft_doc != null ? (
              <FolderOpenTwoTone twoToneColor="#cc7a00"
                style={{ color: 'green', fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.pft_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultPft === 'POSITIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'red', margin: 0 }}>{item.item.finalResultPft}</p>
            {item && item.item && item.item.pft_doc != null ? (
              <FolderOpenTwoTone twoToneColor="#cc7a00"
                style={{ color: 'green', fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.pft_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : item.item.finalResultPft !== 'POSITIVE' && item.item.finalResultPft !== 'NEGATIVE' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'orange', margin: 0 }}>{item.item.finalResultPft}</p>
            {item && item.item && item.item.pft_doc != null ? (
              <FolderOpenTwoTone twoToneColor="#cc7a00"
                style={{ color: 'green', fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => downloadAttachmentMultiple(item.item.pft_doc)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          ''
        )}
      </span>
    );
  };
  // const showDoc = (item) => {
  //   return (
  //     <p>
  //       {docOccHealthPermission && item.item.urls && item.item.urls.length > 0
  //         ? item.item.urls.map((url) => {
  //             return (
  //               <>
  //                 &nbsp;
  //                 <button style={{ margin: 3 }} onClick={() => downloadAttachmentMultiple(url)}>
  //                   View Doc
  //                 </button>
  //               </>
  //             );
  //           })
  //         : item.item.urls &&
  //           item.item.urls.length > 0 && (
  //             <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
  //           )}
  //     </p>
  //   );
  // };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  // const showAttachment = (item) => {
  //   return (
  //     <span>
  //       {item && item.item && item.item.attachment && item.item.attachment.length > 0 ? (
  //         <button
  //           style={{ margin: 3 }}
  //           onClick={() => downloadAttachmentMultiple(item.item.attachment[0])}
  //         >
  //           View Doc
  //         </button>
  //       ) : (
  //         <></>
  //       )}
  //     </span>
  //   );
  // };
  const showNewLocation = (item) => {
    return item.item.newLocation;
  };
  
  const shownewCollected = (item)=>{
    return <p>{item && item.item.newCollected ? moment(item.item.newCollected).format('MM/DD/YYYY'):'' }</p>
  }

  const showExpiryDate = (item)=>{
    return <p>{item && item.item.expiry_date ? moment(item.item.expiry_date).format('MM/DD/YYYY'):''}</p>
  }
  const showSSN = (item) => {
    if (item.item.newEmpSSN !== false && item.item.newEmpSSN !== null) {
      return <>{'***-**-' + item.item.newEmpSSN?.substr(-4)}</>;
    } else {
      return <>{''}</>;
    }
  };
  const showEIN = (item) => {
    return <>{item.item.newEmpEIN != false ? item.item.newEmpEIN : ''}</>;
  };
  const showDL = (item) => {
    return <>{item.item.newDL != false ? item.item.newDL : ''}</>;
  };
  function download(e, attachment) {
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: 'https://wss-files.s3.amazonaws.com/' + attachment
        }
      })
      .then((response) => {
        console.log('Respone from getData testEventReports', response.data);
        if (response && response.data && response.data.length > 0) {
          setIsLoaderActive(false);
          setIsModalVisible(true);
          setImageUrls(response.data);
        }

        // setChange(!change)
        // window.open(response.data);
      });
  }

  const showFitTestPdf = (record) => {
    return (
      <span className='nowrap'>
        {record.item && record.item.fitTestPdf ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              download(e, record.item.fitTestPdf);
            }}
          >
            View PDF
          </button>
        ) : (
          ''
        )}
      </span>
    );
    // return (
    //   <span
    //     className='nowrap'
    //     style={{ display: 'flex', flexDirection: 'column', width: '70%', textAlign: 'center' }}
    //   >
    //     <div>
    //       {record.item && record.item.fitTestPdf ? (
    //         <button onClick={(e) => download(e, record.item.fitTestPdf)}>View PDF</button>
    //       ) : (
    //         ''
    //       )}
    //     </div>
    //   </span>
    // );
  };

  const showFitTestCard = (record) => {
    return (
      <span
        className='nowrap'
        style={{ display: 'flex', flexDirection: 'column', width: '70%', textAlign: 'center' }}
      >
        <div>
          {record.item && record.item.fitTestCard ? (
            <button onClick={(e) => download(e, record.item.fitTestCard)}>View Card</button>
          ) : (
            ''
          )}
        </div>
      </span>
    );
  };

  

  return (
    <div>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={10}>
          <FlexGridSearch
            ref={theSearch}
            placeholder='Search'
            cssMatch=''
            style={{ width: '70%', height: '40px' }}
          />
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
          <div ref={fixedContainerRef}>
            <Select
              style={{ width: 170 }}
              placeholder={`Items per page : ${pageSize}`}
              onChange={paggination}
              options={[
                { label: 'Items per page : 10', value: 10 },
                { label: 'Items per page : 20', value: 20 },
                { label: 'Items per page : 50', value: 50 }
              ]}
              getPopupContainer={() => fixedContainerRef.current}
            />
          </div>
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={10}>
          <Button
            type='primary'
            size='small'
            style={{ backgroundColor: '#3d586a', float: 'right' }}
            disabled={isExcelPreparing}
            onClick={exportToExcel}
          >
            {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
          </Button>
        </Col>
      </Row>

      {/* to export entire data of table */}
      <FlexGrid
        ref={theGrid}
        style={{ display: 'none' }}
        className='dummyWijmoData'
        itemsSource={viewData}
        initialized={gridInitializedData}
        formatItem={formatItem}
        isReadOnly={true}
      >
        <div style={{ display: 'none' }}>
          <wjInput.ListBox
            className='column-picker'
            itemsSource={listboxColumns}
            checkedMemberPath='visible'
            displayMemberPath='header'
            lostFocus={hidePicker}
            initialized={initializedListBox}
          ></wjInput.ListBox>
        </div>
        <FlexGridColumn
          binding='fullName'
          header='Employee Name'
          minWidth={200}
          width='*'
          wordWrap={true}
        />
        {typecheckValue &&
          typecheckValue.length > 0 &&
          typecheckValue.map((value) => {
            return (
              <FlexGridColumn
                binding={
                  value === 'SSN'
                    ? 'newEmpSSN'
                    : value === 'EIN'
                    ? 'newEmpEIN'
                    : value === 'Driver License'
                    ? 'newDL'
                    : ''
                }
                header={
                  value === 'SSN'
                    ? 'SSN'
                    : value === 'EIN'
                    ? 'EIN'
                    : value === 'Driver License'
                    ? 'Driver License'
                    : ''
                }
                minWidth={160}
                width='*'
              >
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={
                    value === 'SSN'
                      ? showSSN
                      : value === 'EIN'
                      ? showEIN
                      : value === 'Driver License'
                      ? showDL
                      : ''
                  }
                />
              </FlexGridColumn>
            );
          })}

        <FlexGridColumn binding='newLocation' header='Location' minWidth={180} wordWrap={true}>
          <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
        </FlexGridColumn>
        <FlexGridColumn binding='newCollectedForExcel' header='Test Date' minWidth={100} width='*' />
        <FlexGridColumn binding='expiry_dateForExcel' header='Expiry Date' minWidth={100}></FlexGridColumn>
        <FlexGridColumn binding='finalResultFit' header='Fit Test'>
          <FlexGridCellTemplate cellType='Cell' template={showResult} />
        </FlexGridColumn>
        <FlexGridColumn binding='finalResultOsha' header='OSHA'>
          <FlexGridCellTemplate cellType='Cell' template={showOshaResult} />
        </FlexGridColumn>
        <FlexGridColumn binding='finalResultPft' header='PFT'>
          <FlexGridCellTemplate cellType='Cell' template={showPftResult} />
        </FlexGridColumn>
        <FlexGridColumn binding='fitManufacturer' header='Make'></FlexGridColumn>
        <FlexGridColumn binding='fit_Model' header='Model'></FlexGridColumn>
        <FlexGridColumn binding='fit_Size' header='Size'></FlexGridColumn>
        <FlexGridColumn binding='fit_Style' header='Style'></FlexGridColumn>

        <FlexGridColumn binding='fitTestType' header='Test Type'></FlexGridColumn>
        <FlexGridColumn binding='reasons' header='Reason' minWidth={200} />

        <FlexGridColumn binding='testing_status' header='Status'></FlexGridColumn>
      </FlexGrid>

      <FlexGrid
        ref={theGrid}
        id='theGridTallRows'
        className='cliniclisttable'
        alternatingRowStep={1}
        itemsSource={view}
        initialized={gridInitialized}
        formatItem={formatItem}
        itemsPerPage={10}
        isReadOnly={true}
        scrollMode='Virtual' // Add this line for smooth scrolling
      >
        <div style={{ display: 'none' }}>
          <wjInput.ListBox
            className='column-picker'
            itemsSource={listboxColumns}
            checkedMemberPath='visible'
            displayMemberPath='header'
            lostFocus={hidePicker}
            initialized={initializedListBox}
          ></wjInput.ListBox>
        </div>

        <FlexGridColumn binding='newLocation' header='Location' width={210}>
          <FlexGridCellTemplate cellType='Cell' template={showNewLocation} />
        </FlexGridColumn>
        <FlexGridColumn binding='newCollected' header='Test Date' width={100}>
        <FlexGridCellTemplate cellType='Cell' template={shownewCollected} />
        </FlexGridColumn>
        <FlexGridColumn binding='expiry_date' header='Expiry Date' width={150}>
        <FlexGridCellTemplate cellType='Cell' template={showExpiryDate} />
        </FlexGridColumn>
        <FlexGridColumn binding='finalResultFit' header='Fit Test' width={150}>
          <FlexGridCellTemplate cellType='Cell' template={showResult} />
        </FlexGridColumn>
        <FlexGridColumn binding='finalResultOsha' header='OSHA' width={150}>
          <FlexGridCellTemplate cellType='Cell' template={showOshaResult} />
        </FlexGridColumn>
        <FlexGridColumn binding='finalResultPft' header='PFT' width={150}>
          <FlexGridCellTemplate cellType='Cell' template={showPftResult} />
        </FlexGridColumn>
        <FlexGridColumn binding='fitManufacturer' header='Make' width={95}></FlexGridColumn>
        <FlexGridColumn binding='fit_Model' header='Model' width={115}></FlexGridColumn>
        <FlexGridColumn binding='fit_Size' header='Size' width={85}></FlexGridColumn>
        <FlexGridColumn binding='fit_Style' header='Style' width={90}></FlexGridColumn>

        <FlexGridColumn binding='fitTestType' header='Test Type' width={110}></FlexGridColumn>
        <FlexGridColumn binding='reasons' header='Reason' width={150} />

        <FlexGridColumn binding='testing_status' header='Status' width={150}></FlexGridColumn>

        <FlexGridFilter
          filterColumns={[
            'fullName',
            'newLocation',
            'newCollected',
            'newEmpEIN',
            'newEmpSSN',
            'newDL',
            'reasons',
            'fitTestType',
            'fitManufacturer',
            'finalResultFit',
            'finalResultOsha',
            'finalResultPft',
            'fit_Style',
            'fit_Size',
            'fit_Model',
            'expiry_date',
            'testing_status'
          ]}
        />
      </FlexGrid>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div
          style={{
            backgroundColor: '#425a81',
            color: '#fff',
            display: 'inline',
            borderRadius: '5px',
            height: '30px'
          }}
        >
          <CollectionViewNavigator
            style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
            headerFormat='Page {currentPage:n0} of {pageCount:n0}'
            byPage={true}
            cv={view}
          />
        </div>
        &nbsp;&nbsp;&nbsp;
        <p
          style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}
          ref={filterRefCounter}
          id='filterCounter'
        >
          {Globalize.format(filterRefCounter.current, 'n0')}
        </p>
        &nbsp;
        <p style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}>
          {' '}
          out of {occFitData.length}
        </p>
        <Modal
          width={1000}
          title='Fit Test Document'
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          {imageUrls && imageUrls.length > 0 && (
            <p>
              <embed src={imageUrls} width='800px' height='900px' />
            </p>
          )}
        </Modal>
        <DarkBackground disappear={isLoaderActive}>
          <LoadingOverlay active={true} spinner text='Loading...'></LoadingOverlay>
        </DarkBackground>
      </div>
    </div>
  );
};

export default FitTestEmp;
