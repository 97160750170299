import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MaskedInput from 'antd-mask-input';
import axios from 'axios';
import _ from 'underscore';
import setting from '../../config/setting';
import { history } from '../../redux/store';
import { Button, Input, Select, Form, DatePicker, Spin, Space, Row, Col, Collapse } from 'antd';
import { emphasize } from '@material-ui/core';

const { Option } = Select;

const { Panel } = Collapse;

const FormItem = Form.Item;

const FormElementsPage = () => {
  const [form] = Form.useForm();
  const [dtProgram, setDtProgram] = useState('DOT');
  const [dtMode, setDtMode] = useState('');
  // const [suffixNew, setSuffix] = useState('');
  const [firstNameNew, setFirstName] = useState('');
  const [middleNameNew, setmiddleName] = useState('');
  const [lastNameNew, setlastName] = useState('');
  const [sexNew, setsex] = useState('');
  const [statusNew, setstatus] = useState('');
  const [dotNew, setdot] = useState('');
  const [jobTitleNew, setjobTitle] = useState('');
  const [mobileNew, setaltMobile] = useState('');
  const [emailNew, setemail] = useState('');
  const [workEmailNew, setworkEmail] = useState('');
  const [streetNew, setstreet] = useState('');
  const [street2New, setstreet2] = useState('');
  const [stateNewA, setstateNew] = useState('');
  const [cityNewA, setcityNew] = useState('');
  const [zipNew, setzip] = useState('');
  const [ssnNew, setssn] = useState('');
  const [locationNewA, setlocationNew] = useState('');
  const [dlNew, setdl] = useState('');
  const [ppNew, setPp] = useState('');
  const [countrNew, setCountr] = useState('');
  const [comNew, setCom] = useState('');
  const [dateNew, setDate] = useState('');
  const [countName, setCountrName] = useState('');
  const [stateNewId, setstateNewID] = useState('');
  const [stateLocal, setStateLocal] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [errorVal, setError] = useState(false);
  const [bool, setBool] = useState(false);
  const [boolNew, setBoolNew] = useState(false);
  const [boolN, setBoolN] = useState(false);
  const [boolNe, setBoolNe] = useState(false);

  const [age, setAge] = useState(0);
  const [mobilePhone, setMobilePhone] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [sendData, setSendData] = useState({});
  // const [change, setChange] = useState(false);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [loc, setLoc] = useState([]);
  const [locNew, setLocNew] = useState([]);
  const [comp, setcomp] = useState([]);
  const [Res, setRes] = useState([]);
  const [CompDataNew, setCompDataNew] = useState([]);
  // const [CompDataNewTrue, setCompDataNewTrue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const location = useLocation();
  const data = location.state;

  const dtProgramFields = {
    'DOT': ['FMCSA', 'USCG', 'PHMSA', 'FTA', 'FAA', 'FRA', 'HHS'],
    'NON - DOT': ['Non - DOT'],
    'Both': ['FMCSA', 'USCG', 'PHMSA', 'FTA', 'FAA', 'FRA', 'HHS']
  };
  useEffect(() => {
    console.log('this.props', data);
    let companyId = localStorage.getItem("customerId")
    getCountry();
    //getCompanyData();
    setSelectedCompany(companyId);
    getResPArt(companyId)
    form.setFieldsValue({
      company: companyId,
    });
  }, []);

  console.log('sendData', sendData);

  function getAllDataComp() {
    let custIdArr = [];
    axios
      .post(setting.serverUrl + '/api/CustomerData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass')
        }
      })
      .then((response) => {
        console.log('Respone from getData ', response.data);

        if (response && response.status == 200) {
          _.each(response.data, (res) => {
            if (res.is_company) {
              custIdArr.push(res);
            }
          });

          setTimeout(() => {
            getResPArt(custIdArr);
          }, 2000);

          setcomp(response.data);
          setBoolNew(true);
        }
      });
  }
  console.log('comp', comp);

  function getResPArt(custId) {
    setLoadingLocations(true);
    axios
      .post(setting.serverUrl + '/api/Res', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          id: custId
        }
      })
      .then((response) => {
        console.log('Respone from getResPArt ', response.data);
        setLoadingLocations(false);
        if (response && response.status == 200) {

          setRes(response.data);
          setBool(true);
        }
      });
  }

  console.log('Res', Res);

  function getAllData() {
    axios
      .post(setting.serverUrl + '/api/CustomerData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass')
        }
      })
      .then((response) => {
        console.log('Respone from res.partner', response.data);

        if (response && response.status == 200) {
          // this.setState({ allData: response.data, change: true });
          setBoolN(true);
        }
      });
  }

  function getCountry() {
    let countryArr = [];
    axios
      .post(setting.serverUrl + '/api/getCountry', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass')
        }
      })
      .then((response) => {
        console.log('Respone from getCountry', response.data);
        setCountry(response.data);
        setBool(true);
        if (response && response.status == 200) {
          setBoolNe(true);
        }
      });
  }

  function stateSelect(event) {
    console.log('event country', JSON.parse(event));

    setStateLocal(JSON.parse(event).name);

    var parseData = JSON.parse(event);

    setCountr(parseData.id);
    setCountrName(parseData.name);
    axios
      .post(setting.serverUrl + '/api/getState', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          event: parseData.id
        }
      })
      .then((response) => {
        console.log('Respone from getState', response.data);

        if (response && response.status == 200) {
          setState(response.data);
        }
      });
  }

  function locSel(event) {
    console.log('event locSel', event);

    //var parseDataNew = JSON.parse(event)

    setCom(event);

    let dataArr = [];

    _.each(Res, (ResData) => {
      console.log('ResData.commercial_partner_id', ResData.commercial_partner_id);

      if (
        ResData.commercial_partner_id &&
        ResData.commercial_partner_id.length > 0 &&
        ResData.commercial_partner_id[0] == event
      ) {
        dataArr.push(ResData);
      }
    });

    console.log('dataArrone', dataArr);
    setLoc(dataArr);
  }

  console.log('loc::::', loc);

  function onChangeDate(date, dateString) {
    console.log('dob', date, dateString);
    setDate(dateString);

    //var date = moment().format()

    var today = new Date();
    var birthDate = new Date(dateString); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log('age_now', age_now);
    setAge(age_now);
  }

  console.log('age', age);

  function mobile(event) {
    console.log('event', event.target.value);
    const reg = /^\d+$/;
    if (reg.test(event.target.value)) {
      setMobilePhone(event.target.value);
    }
  }


  console.log('mobilePhone', mobilePhone);

  function compny(event) {
    console.log('company', event);
    setRes([]);
    setSelectedCompany(event);
    getResPArt(event);
  }
  function getCompanyData() {
    axios
      .post(setting.serverUrl + '/api/allCustomerData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass')
        }
      })
      .then((response) => {
        console.log('Respone from allCustomerData ', response.data);
        if (response && response.status == 200) {
          let fetchedRecords = response.data;
          let companyRecords = [];
          fetchedRecords.forEach((element) => {
            console.log('element', element);
            let exist = companyRecords.filter((company) => company.id == element.id);
            console.log('exist recod', exist);
            if (exist.length == 0) {
              companyRecords.push({
                id: element.id,
                name: element.display_name
              });
            }
          });
          setCompDataNew(companyRecords);

          setTimeout(() => {
            getCountry();
          }, 1000);
        }
      });
  }

  function firstName(event) {
    console.log('firstName', event.target.value);
    setFirstName(event.target.value);
  }

  function middleName(event) {
    console.log('middleName', event.target.value);
    setmiddleName(event.target.value);
  }

  function lastName(event) {
    console.log('lastName', event.target.value);
    setlastName(event.target.value);
  }

  function sex(event) {
    console.log('sex', event);
    setsex(event);
  }

  function status(event) {
    console.log('status', event);
    setstatus(event);
  }

  function dot(event) {
    console.log('dot', event);
    setdot(event);
  }

  function jobTitle(event) {
    console.log('jobTitle', event.target.value);
    setjobTitle(event.target.value);
  }

  function altMobile(event) {
    console.log('altMobile', event.target.value);
    const reg = /^\d+$/;
    if (reg.test(event.target.value)) {
      setaltMobile(event.target.value);
    }

  }


  function email(event) {
    console.log('email', event.target.value);
    setemail(event.target.value);
  }

  function workEmail(event) {
    console.log('workEmail', event.target.value);
    setworkEmail(event.target.value);
  }

  function street(event) {
    console.log('street', event.target.value);
    setstreet(event.target.value);
  }

  function street2(event) {
    console.log('street2', event.target.value);
    setstreet2(event.target.value);
  }

  function stateNew(event) {
    console.log('stateNew', JSON.parse(event));
    var parseDataState = JSON.parse(event);
    setstateNew(parseDataState.name);
    setstateNewID(parseDataState.id);
  }

  console.log('state', stateNewA, stateNewId);

  function cityNew(event) {
    console.log('cityNew', event.target.value);
    setcityNew(event.target.value);
  }

  function zip(event) {
    console.log('zip', event.target.value);
    setzip(event.target.value);
  }

  function ssn(event) {
    console.log('ssn', event.target.value);
    setssn(event.target.value);
  }

  function locationNew(event) {
    console.log('locationNew', event);
    setlocationNew(event);
  }

  function dl(event) {
    console.log('dl', event.target.value);
    setdl(event.target.value);
  }
  function pp(event) {
    console.log('pp', event.target.value);
    setPp(event.target.value);
  }
  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  function onSearchState(val) {
    console.log('search:', val);
  }

  function saveEmployee() {
    console.log('stateLocal', stateLocal);

    if (firstNameNew && lastNameNew && dateNew && selectedCompany && locationNewA && sexNew) {
      console.log('saveEmployee else');
      setIsLoading(true);
      axios
        .post(setting.serverUrl + '/api/saveEmployee', {
          params: {
            email: localStorage.getItem('myData'),
            user: localStorage.getItem('myData'),
            pass: localStorage.getItem('myPass'),

            // suffixNew: suffixNew,
            firstNameNew: firstNameNew,
            middleNameNew: middleNameNew,
            lastNameNew: lastNameNew,
            sexNew: sexNew,

            statusNew: statusNew,
            dotNew: dotNew,
            jobTitleNew: jobTitleNew,
            mobileNew: mobileNew,
            emailNew: emailNew,

            workEmailNew: workEmailNew,
            streetNew: streetNew,
            street2New: street2New,
            stateNewA: [parseInt(stateNewId), stateNewA],
            cityNewA: cityNewA,

            zipNew: zipNew,
            ssnNew: ssnNew,
            locationNewA: parseInt(locationNewA),
            dlNew: dlNew,
            ppNew: ppNew,
            countrNew: [parseInt(countrNew), countName],
            comNew: parseInt(selectedCompany),
            //comNew: parseInt(CompDataNew[0].id),
            dateNew: dateNew,
            mobilePhone: parseInt(mobilePhone),
            age: age,
            dtProgram: dtProgram,
            mode: dtProgram != "NON - DOT" ? dtMode : "",
            dot_mode: dtProgram == "NON - DOT" ? dtMode : "",
            dtMode: dtMode
          }
        })
        .then((response) => {
          console.log('Respone from saveEmployee ', response.data);
          setIsLoading(false);
          if (response && response.status == 200) {
            let id = response.data.id;
            let customerData = JSON.parse(localStorage.getItem('customerData'))
            customerData.employee_multi_select.push(id);
            localStorage.setItem('customerData', JSON.stringify(customerData));
            history.push({ pathname: `/vertical/employeeList` });

            setRes(response.data);
          }
        });
    } else {
      setError(true);
    }
  }

  return (
    <div style={{ padding: 20 }}>
      <div>
        <Button
          size='small'
          style={{ backgroundColor: 'rgb(61 88 106)', color: 'white', marginTop: 0, padding: 10 }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>
      <h5 style={{ textAlign: 'left', color: '#1C3A6A' }}>Add Employee
      </h5>
      <h3 style={{ marginLeft: 25, color: '#1C3A6A' }}>
        Employee Information &nbsp;&nbsp;<b style={{ color: 'grey' }}>*Denotes Required Fields</b>
      </h3>

      {bool ? (
        //&& boolNew
        //&& boolN && boolNe
        <div>
          <Form layout='vertical' onFinish={() => saveEmployee()}>
            <div style={{ marginLeft: 25 }}>
              <div className='row'>
                <div className='col-md-6'>
                  <FormItem
                    name='company'
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                      }
                      defaultValue={localStorage.getItem("displayName")}
                      placeholder='Company Name*'
                    >
                      <Option value={localStorage.getItem("customerId")}>{localStorage.getItem("displayName")}</Option>
                    </Select>
                  </FormItem>
                </div>

                <div className='col-md-6'>
                  <FormItem>
                    <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                      placeholder='Employee Location*'
                      onChange={(event) => locationNew(event)}
                      loading={loadingLocations}
                    >
                      {Res.length > 0 &&
                        Res.map((loc, index) => <Option value={loc.id}>{loc.name}</Option>)}
                    </Select>
                  </FormItem>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-5'>
                  <FormItem
                    name='First Name'
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <Input
                      style={{ backgroundColor: 'white' }}
                      onChange={(event) => firstName(event)}
                      placeholder='First Name*'
                    />
                  </FormItem>
                </div>
                <div className='col-md-2'>
                  <FormItem>
                    <Input
                      style={{ backgroundColor: 'white' }}
                      placeholder='Middle Name'
                      onChange={(event) => middleName(event)}
                    />
                  </FormItem>
                </div>
                <div className='col-md-5'>
                  <FormItem
                    name='Last Name*'
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <Input
                      style={{ backgroundColor: 'white' }}
                      placeholder='Last Name*'
                      onChange={(event) => lastName(event)}
                    />
                  </FormItem>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <FormItem
                    name='dob'
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <DatePicker
                      style={{ width: '100%', padding: 10, borderRadius: 20 }}
                      placeholder='Date of Birth*'
                      onChange={(date, dateString) => onChangeDate(date, dateString)}
                    />
                  </FormItem>
                </div>
                <div className='col-md-4'>
                  <FormItem>
                    <Input
                      style={{ backgroundColor: 'white' }}

                      placeholder={age == 0 ? 'Age' : ""}
                      readOnly
                      disabled
                      prefix={age > 0 ? <h6> Age : {age} years</h6> : ""}

                    />
                  </FormItem>
                </div>
                <div className='col-md-4'>
                  <FormItem
                    name='Sex'
                    rules={[{ required: true, message: 'This field is required.' }]}
                  >
                    <Select 
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                    placeholder='Sex*' onChange={(event) => sex(event)}>
                      <Option value='Male'>Male</Option>
                      <Option value='Female'>Female</Option>
                    </Select>
                  </FormItem>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <FormItem>
                    <MaskedInput
                      mask='111-11-1111'
                      placeholder='SSN _ _ _/_ _/_ _ _ _'
                      onChange={(event) => ssn(event)}
                      style={{ backgroundColor: 'white' }}
                    />
                  </FormItem>
                </div>
                <div className='col-md-4'>
                  <FormItem>
                    <Input
                      style={{ backgroundColor: 'white' }}
                      placeholder='Drivers License #'
                      onChange={(event) => dl(event)}
                    />
                  </FormItem>
                </div>
                <div className='col-md-4'>
                  <FormItem>
                    <Input
                      style={{ backgroundColor: 'white' }}
                      placeholder='Passport #'
                      onChange={(event) => pp(event)}
                    />
                  </FormItem>
                </div>
              </div>
            </div>
            <br />
            <Collapse accordion>
              <Panel
                style={{ background: '#2F7197', borderRadius: '10px 10px 0px 0px', color: 'white' }}
                className='ant-collapse-header'
                header={
                  <span style={{ color: 'white', fontSize: '140%' }}>
                    Employee Contact Information
                  </span>
                }
                key='1'
              >
                {
                  <div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <FormItem>
                          <Input
                            style={{ backgroundColor: 'white' }}
                            placeholder='Street Address 1'
                            onChange={(event) => street(event)}
                          />
                        </FormItem>
                      </div>

                      <div className='col-md-6'>
                        <FormItem>
                          <Input
                            placeholder='Street Address 2'
                            style={{ backgroundColor: 'white' }}
                            onChange={(event) => street2(event)}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <FormItem>
                          <Input
                            onChange={(event) => cityNew(event)}
                            placeholder='City'
                            style={{ backgroundColor: 'white' }}
                          />
                        </FormItem>
                      </div>

                      <div className='col-md-4'>
                        <FormItem>
                          <Select

                            showSearch
                            placeholder='Country'
                            optionFilterProp='children'
                            onChange={(event) => stateSelect(event)}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                            }
                          >
                            {country.length > 0 &&
                              country.map((count, index) => (
                                <Option value={JSON.stringify(count)}>{count.name}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>
                      <div className='col-md-2'>
                        <FormItem>
                          <Select
                            showSearch
                            placeholder='State'
                            optionFilterProp='children'
                            onChange={(event) => stateNew(event)}
                            onSearch={onSearchState}
                            filterOption={(input, option) =>
                              option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {state.length > 0 &&
                              state.map((state, index) => (
                                <Option value={JSON.stringify(state)}>{state.name}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>
                      <div className='col-md-2'>
                        <FormItem>
                          <Input
                            style={{ backgroundColor: 'white' }}
                            placeholder='Zip Code'
                            onChange={(event) => zip(event)}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <FormItem>
                          <Input
                            placeholder='Mobile Number'
                            style={{ backgroundColor: 'white' }}
                            onChange={(event) => mobile(event)}
                            maxLength={10}
                            pattern='^\d+$'
                          />
                        </FormItem>
                      </div>

                      <div className='col-md-4'>
                        <FormItem>
                          <Input
                            onChange={(event) => altMobile(event)}
                            placeholder='Alternate Phone Number'
                            style={{ backgroundColor: 'white' }}
                            maxLength={10}
                            pattern='^\d+$'
                          />
                        </FormItem>
                      </div>
                      <div className='col-md-4'>
                        <FormItem name='Email'>
                          <Input
                            style={{ backgroundColor: 'white' }}
                            placeholder='Email'
                            onChange={(event) => email(event)}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <i style={{ color: 'grey' }}>
                      * By adding this Information, the employee will automatically be added to the
                      Employee Portal.
                    </i>
                  </div>
                }
              </Panel>

              <Panel
                style={{ background: '#EF6828', borderRadius: '0px 0px 10px 10px' }}
                header={
                  <span style={{ color: 'white', fontSize: '140%' }}>
                    Company Contact Information
                  </span>
                }
                key='2'
                extra={''}
              >
                {
                  <div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <FormItem>
                          <Select
                            defaultValue={dtProgram}
                            showSearch
           optionFilterProp="children"
           filterOption={(input, option) =>
             option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
           }
                            placeholder='DOT Program'
                            onChange={(event) => setDtProgram('' + event)}
                          >
                            {Object.keys(dtProgramFields).length > 0 &&
                              Object.keys(dtProgramFields).map((state, index) => (
                                <Option value={state}>{state}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>

                      <div className='col-md-6'>
                        <FormItem>
                          {/* <Select
                          //defaultValue=""
                          placeholder='DOT Allocation'
                          onChange={(event) => dot(event)}
                        >
                          <Option value='Non - DOT'>Non - DOT</Option>
                          <Option value='HHS'>HHS</Option>
                          <Option value='FMCSA'>FMCSA</Option>
                          <Option value='USCG'>USCG</Option>
                          <Option value='PHMSA'>PHMSA</Option>
                          <Option value='FTA'>FTA</Option>
                          <Option value='FAA'>FAA</Option>
                          <Option value='FRA'>FRA</Option>
                        </Select> */}

                          <Select
                            //defaultValue={dtProgram}
                            showSearch
           optionFilterProp="children"
           filterOption={(input, option) =>
             option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
           }
                            placeholder='DT Mode' onChange={(event) => setDtMode('' + event)}>
                            {dtProgramFields[dtProgram] &&
                              dtProgramFields[dtProgram].length > 0 &&
                              dtProgramFields[dtProgram].map((state, index) => (
                                <Option value={state}>{state}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <FormItem>
                          <Input
                            style={{ backgroundColor: 'white' }}
                            placeholder='Job Title'
                            onChange={(event) => jobTitle(event)}
                          />
                        </FormItem>
                      </div>

                      <div className='col-md-6'>
                        <FormItem>
                          <Input
                            onChange={(event) => workEmail(event)}
                            style={{ backgroundColor: 'white' }}
                            placeholder='Work Email Address'
                          />
                        </FormItem>
                      </div>
                    </div>
                  </div>
                }
                <div className='row'>
                  <div className='col-md-6'>
                    <FormItem>
                      <Select placeholder='Status' onChange={(event) => status(event)}>
                        <Option value='Pre Employment'>Pre Employment</Option>
                        <Option value='Active'>Active</Option>
                        <Option value='Teminated'>Terminated</Option>
                        <Option value='leave of absence'>Leave Of Absence</Option>
                        <Option value='deceased'>Deceased</Option>
                      </Select>
                    </FormItem>
                  </div>

                  <div className='col-md-6'></div>
                </div>
              </Panel>
            </Collapse>

            <div className='row' style={{ marginTop: 50 }}>
              <div className='col-md-4 col-sm-4'></div>
              <div className='col-md-5 col-sm-5'>
                <Button type='primary' style={{ width: '40%', backgroundColor:"#1b3969"}} htmlType={'submit'}>
                  Submit
                </Button>
                {isLoading &&
                  <Space size="middle" style={{ marginTop: 10, marginLeft: 10 }}>
                    <Spin size="large" />
                  </Space>
                }
              </div>

            </div>
          </Form>
        </div>
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 10 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FormElementsPage;
