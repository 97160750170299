import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import setting from '../../config/setting';
import { Spin, Space, Row, Col, Select, Button, notification, Tooltip, Modal, Input } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { history } from '../../redux/store';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import '@grapecity/wijmo.styles/wijmo.css';
import Search from 'antd/lib/input/Search';
let constContact = [];
let columnPicker = [];
let flexes = '';
let showGrid = '';
let dragOver = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
      },
      'location_list.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const LocationList = () => {
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [foundL, setFoundL] = useState(false);
  const [noRecordMessageL, setNoRecordMessageL] = useState('No Record Found !');
  const [custContacts, setCustContacts] = useState([]);
  const [custLocs, setCustLocs] = useState([]);
  const [errMsg, setErrMsg] = useState();
  const [filterData, setFilterData] = useState([]);
  const [locationBKP, setLocationBKP] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [changeL, setChangeL] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [loaderValue, setLoaderValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilterCount, setShowFilterCount] = useState([]);
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [pageLimit, setPageLimit] = useState(25);
  const [lastNameValue, setLastNameValue] = useState('');
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [locationSearch, setLocation] = useState('');
  const [contactPersonSearch, setContactPerson] = useState('');
  const [addressSearch, setAddress] = useState('');
  const [phoneSearch, setPhone] = useState('');
  const [clinicSearch, setClinic] = useState('');
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [locationSearchInput, setLocationInput] = useState('');
  const theGrid = useRef();
  const theSearch = useRef();
  const gridRef = useRef(null);
  const fixedContainerRef = useRef(null);
  const filterRefCounterLoc = useRef();

  // let theGrids = theGrid.current;
  // let theSearchs = theSearch.current;
  // if (theGrids && theSearchs) {
  //   theSearchs.control.grid = theGrids.control;
  // }

  useEffect(() => {
    setChangeL(false);
    getCustomerContacts();
    fetchCompanyData();
  }, [pageSize]);

  const fetchCompanyData = (status = false, pageNo = 1) => {
    const companyId = parseInt(localStorage.getItem('customerId'));
    getCustomerLocation([companyId], status, pageNo);
    getCustomerLocationCount([companyId]);
  };
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const getCustomerContacts = (companyIds) => {
    axios
      .post(setting.serverUrl + '/api/contactListCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyIds,
          custId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          response.data.rows.sort(function (a, b) {
            return a.locname - b.locname;
          });
          setCustContacts(response.data.rows);
          constContact = response.data.rows;
        } else if (response.status === 201) {
          setFoundL(true);
          // setNoRecordMessageL('Access permission not allowed !');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };

  const getCustomerLocation = (companyIds, forceReset = false, pageNo, advanceFilter = false) => {
    setIsLoading(true);
    if (!forceReset && !advanceFilter) {
      setLoaderValue(true);
    }

    axios
      .post(setting.serverUrl + '/api/getCustomerLocationCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyIds,
          limit: pageLimit * pageNo,
          compId: JSON.parse(localStorage.getItem('customerData')).id,
          location: forceReset ? '' : locationSearch || locationSearchInput,
          phoneNumber: forceReset ? '' : phoneSearch,
          clinic: forceReset ? '' : clinicSearch
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          
          if (response.data && response.data != 'Blocked') {
            response.data.sort(function (a, b) {
              return a.name - b.name;
            });
            let responseLoc = response.data;

            let finalCombinedResponse = [];
            finalCombinedResponse.push(constContact);
            let merged = [];

            for (let i = 0; i < responseLoc.length; i++) {
              merged.push({
                ...responseLoc[i],
                ...constContact.find(
                  (itmInner) => itmInner.contact1_emp === responseLoc[i].contact1_emp[0]
                )
              });
            }
            let completeResult = merged.map((record) => {
              return {
                ...record,
                fullAddr: record
                  ? (record.street === false || record.street.length === 0 ? '' : record.street) +
                    (record.street2 === false || record.street2.length === 0
                      ? ''
                      : ', ' + record.street2) +
                    (record.city === false || record.city.length === 0 ? '' : ', ' + record.city) +
                    (record.state_id === false || record.state_id.length == 0
                      ? ''
                      : ', ' + record.state_id[1].replace(' (US)', '')) +
                    (record.zip === false || record.zip.length === 0 ? '' : ', ' + record.zip)
                  : null
              };
            });

            let responseResult = completeResult.map((k) => {
              return {
                ...k,
                newClinic: k.clinic_name != false ? k.clinic_name[1] : '',
                name: k.name != '' ? (k.name.includes('|') ? k.name.split('|')[1] : k.name) : '',
                phone: k.phonevalue != false ? k.phonevalue : ''
              };
            });
            
            setCustLocs(responseResult);
            
            setTotalRecordCount(responseResult?.length);
            if (responseResult && responseResult.length == 0) {
              setFoundL(false);
              //  // setIsLoading(false);
              //  setNoRecordMessageL('No Record found.');
            }
            const collectionView1 = new CollectionView(responseResult, {
              pageSize: responseResult.length
            });
            setViewData(collectionView1);
            const collectionView2 = new CollectionView(responseResult, {
              pageSize: pageSize,
              collectionChanged: (s) => {
                filterRefCounterLoc.current = s.totalItemCount;
                var filterCounter = document.getElementById('filterCounterLoc');
                if (filterCounter) {
                  filterCounter.textContent = s.totalItemCount;
                }
              }
            });
            setView(collectionView2);
            setLoaderValue(false);
            //setWijmoFlexGrid(responseResult, 10);
            setFilterData(responseResult);
            setLocationBKP(responseResult);
            setFoundL(false);
          }
        } else if (response.status === 201) {
          setFoundL(true);
          setNoRecordMessageL('Access permission not allowed !');
        } else if (response.status === 202) {
          setFoundL(false);
          setNoRecordMessageL('No Record found.');
          //setWijmoFlexGrid([],10);
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
          setFoundL(true);
        }
      });
  };

  const getCustomerLocationCount = (companyIds) => {
    axios
      .post(setting.serverUrl + '/api/getCustomerLocationCount', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyIds,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          // setIsLoading(false)
          setShowFilterCount(response.data.value);
          setTotalRecords(response.data.value);
          let total = totalRecords;

          setChangeL(true);
          setFoundL(false);
          setHasPermission(true);
        } else if (response.status === 201) {
          setFoundL(true);
          setNoRecordMessageL('Access permission not allowed !');
          setErrMsg('Access permission not allowed !');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
          setFoundL(true);
        }
      });
  };
  const handleLoadMore = () => {
    i = 1;
    setCurrentPageNumber(currentPageNumber + 1);
    fetchCompanyData(false, currentPageNumber + 1);
  };

  const handleChange = (e)=>{
    setLocationInput(e.target.value)
    // setLocation(locationSearchInput);
  }

  const onSearch = (value)=>{
    if(locationSearchInput.length>0){
      fetchCompanyData();
      // setLocation('')
    }
  }

  const handleReset = () => {
    setIsLoading(true);
    setLocationInput('')
    setValue('');
    setLastNameValue('');
    setSearch('');
    setAddress('');
    setLocation('');
    setContactPerson('');
    setClinic('');
    setPhone('');
    fetchCompanyData(true);
    // setNoRecordMessageL('');
  };
  // const addLocation = () => {
  //   history.push({ pathname: `/vertical/addLocation`, state: {} });
  // };

  const shouldShowLoadMore = () => {
    return currentPageNumber * pageLimit <= totalRecords;
  };

  const showAdvanceFilterModal = () => {
    console.log('insdie advance filter');
    setIsAdvanceFilterModalOpen(true);
  };

  const handleAdvanceFilterCancel = () => {
    setIsAdvanceFilterModalOpen(false);
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterModalOpen(false);
    fetchCompanyData();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();

    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          //console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          //console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      //console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };
  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;

    setListboxColumns(ctl.columns);
  };
  const showPhone = (item) => {
    return (
      <span className='nowrap'>
        {item.item.phone && <i class='icofont-phone'></i>}&nbsp;{item.item.phone}
      </span>
    );
  };
  const showAddress = (item) => {
    return (
      <span style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>{item.item.fullAddr}</span>
    );
  };

  const showNearClinic = (item) => {
    return (
      <span className='nowrap'>{item.item.newClinic != null ? item.item.newClinic : ''} </span>
    );
  };

  return hasPermission ? (
    <div style={{ padding: 20 }}>
      <Row style={{ textAlign: 'center', alignItems: 'center' }}>
        <Col xl={{ span: 5 }} lg={{ span: 5 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 22 }}>
          <h3 style={{ textAlign: 'left', color: '#1C3A6A' }}>Location List</h3>
        </Col>
        <Col xl={{ span: 2 }} lg={{ span: 2 }} md={{ span: 2 }} sm={{ span: 2 }} xs={{ span: 2 }}>
          {/* <Tooltip placement='top' title='Add Location'>
            <PlusCircleTwoTone
              twoToneColor='#247'
              style={{ fontSize: 25, cursor: 'pointer' }}
              onClick={addLocation}
            />
          </Tooltip> */}
        </Col>
        <Col
          xl={{ span: 1 }}
          lg={{ span: 1 }}
          md={{ span: 2 }}
          sm={{ span: 0 }}
          xs={{ span: 2 }}
        ></Col>
        <Col
          xl={{ span: 16 }}
          lg={{ span: 16 }}
          md={{ span: 16 }}
          sm={{ span: 16 }}
          xs={{ span: 16 }}
          style={{ textAlign: 'right' }}
        >
          {custLocs && custLocs.length > 0 && shouldShowLoadMore() && (
            <Button
              size='small'
              style={{
                backgroundColor: '#247',
                color: 'white',
                border: 'none',
                marginRight: '15px'
              }}
              onClick={() => handleLoadMore()}
            >
              {loaderValue ? (
                <Space size='middle'>
                  <Spin size='small' />
                </Space>
              ) : (
                ' Load More'
              )}
            </Button>
          )}
          {totalRecordCount > 0 ? (
            <Button
              size='small'
              style={{
                marginTop: 35,
                backgroundColor: '#247',
                border: 'none',
                marginRight: '15px'
              }}
              onClick={showAdvanceFilterModal}
            >
              Advanced Search Filter
            </Button>
          ) : (
            ''
          )}
          <Modal
            width={1000}
            title='Advanced Search Filter'
            visible={isAdvanceFilterModalOpen}
            onCancel={handleAdvanceFilterCancel}
            footer={[
              <Button
                size='small'
                style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                onClick={() => handleAdvanceFilter()}
              >
                Search
              </Button>
            ]}
          >
            <Row gutter={[24, 24]}>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={locationSearch}
                  placeholder='Location Name :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setLocation(event.target.value)}
                />
              </Col>
              {/* <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={contactPersonSearch}
                  placeholder='Contact Person:'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setContactPerson(event.target.value.toUpperCase())}
                />
              </Col> */}
              {/* <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={addressSearch}
                  placeholder='Address :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setAddress(event.target.value.toUpperCase())}
                />
              </Col> */}

              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={phoneSearch}
                  placeholder='Phone Number :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={clinicSearch}
                  placeholder='near By Clinic :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setClinic(event.target.value)}
                />
              </Col>
            </Row>
          </Modal>
          <Button
            size='small'
            style={{ marginTop: 35, backgroundColor: '#247', border: 'none'}}
            onClick={handleReset}
          >
            Reset Filter
          </Button>
        </Col>
      </Row>

      {(isLoading || loaderValue) && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50,marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      {!foundL ? (
        custLocs.length > 0 ? (
          <>
            <Row>
              <Col style={{ fontSize: 15, fontWeight: 700, marginTop: '10px' }}>
                {totalRecords > 0 ? 'Total Records : ' + totalRecords : ''}
              </Col>
            </Row>
            <br />
            <Row gutter={[32, 32]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                {/* <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                /> */}
                <Search
                  placeholder='Search By Location'
                  enterButton
                  // loading={isLoading}
                  value={locationSearchInput}
                  onSearch={onSearch}
                  onChange={handleChange}
                  size='small'
                  // style={{ width: 304 }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div ref={fixedContainerRef}>
                  <Select
                    style={{ width: 170 }}
                    placeholder={`Items per page : ${pageSize}`}
                    onChange={paggination}
                    options={[
                      { label: 'Items per page : 10', value: 10 },
                      { label: 'Items per page : 20', value: 20 },
                      { label: 'Items per page : 50', value: 50 }
                    ]}
                    getPopupContainer={() => fixedContainerRef.current}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  size='small'
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>
            <br />

            {/* to export entire data of table */}
            {filterData.length > 0 && (
              <FlexGrid
                ref={theGrid}
                style={{ display: 'none' }}
                className='dummyWijmoData'
                itemsSource={viewData}
                initialized={gridInitializedData}
                formatItem={formatItem}
                isReadOnly={true}
              >
                <FlexGridColumn
                  binding='locname'
                  header='Contact Person'
                  minWidth={100}
                  width='*'
                  wordWrap={true}
                />
                <FlexGridColumn binding='fullAddr' header='Address' minWidth={450} width='*'>
                  <FlexGridCellTemplate
                    cellType='Cell'
                    template={showAddress}
                  ></FlexGridCellTemplate>
                </FlexGridColumn>

                <FlexGridColumn binding='phone' header='Phone Number' minWidth={140} width='*'>
                  <FlexGridCellTemplate cellType='Cell' template={showPhone} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newClinic'
                  header='Near by Clinic'
                  minWidth={400}
                  width='*'
                />
              </FlexGrid>
            )}

            {filterData.length > 0 && (
              <FlexGrid
                ref={theGrid}
                id='theGridTallRows'
                className='locationlist cliniclisttable'
                itemsSource={view}
                initialized={gridInitialized}
                formatItem={formatItem}
                itemsPerPage={10}
                isReadOnly={true}
                scrollMode='Virtual' // Add this line for smooth scrolling
              >
                <div style={{ display: 'none' }}>
                  <wjInput.ListBox
                    className='column-picker'
                    itemsSource={listboxColumns}
                    checkedMemberPath='visible'
                    displayMemberPath='header'
                    lostFocus={hidePicker}
                    initialized={initializedListBox}
                  ></wjInput.ListBox>
                </div>
                <FlexGridColumn
                  binding='name'
                  header='Location Name'
                  // minWidth={100}
                  // width='*'
                  width={200}
                  // wordWrap={true}
                />

                <FlexGridColumn
                  binding='locname'
                  header='Contact Person'
                  // minWidth={100}
                  // width='*'
                  width={150}
                  // wordWrap={true}
                />
                <FlexGridColumn
                  binding='fullAddr'
                  header='Address'
                  // minWidth={450}
                  // width='*'
                  width={430}
                  // wordWrap={true}
                />
                <FlexGridColumn
                  binding='phone'
                  header='Phone Number'
                  // minWidth={100}
                  // width='*'
                  width={170}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showPhone} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newClinic'
                  header='Near by Clinic'
                  // minWidth={350}
                  // width='*'
                  width={350}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showNearClinic} />
                </FlexGridColumn>

                <FlexGridFilter
                  showFilterIcons={true}
                  placeholder='Filter data...'
                  defaultFilterType='Value'
                  filterColumns={['name', 'locname', 'fullAddr', 'phone', 'newClinic']}
                />
              </FlexGrid>
            )}

            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                ref={filterRefCounterLoc}
                id='filterCounterLoc'
              >
                {Globalize.format(filterRefCounterLoc.current, 'n0')}
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                {' '}
                out of {filterData.length}
              </p>
            </div>
          </>
        ) : (
          <>
            {isLoading ? (
              <Row>
                <Col span={10}></Col>
                {/* <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50,marginLeft:85 }}>
                    <Spin size='large' />
                  </Space>
                </Col> */}
              </Row>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessageL}</h5>
            )}
          </>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50,marginLeft:85}}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  ) : (
    <div>
      <Row style={{ textAlign: 'center', alignItems: 'center', padding: 20 }}>
        <Col xl={{ span: 5 }} lg={{ span: 5 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 22 }}>
          <h3 style={{ textAlign: 'left', color: '#1C3A6A' }}>Location List</h3>
        </Col>
      </Row>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50,marginLeft:85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      <center style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>{errMsg}</center>
    </div>
  );
};

export default LocationList;
