import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { useLocation,useParams } from "react-router-dom";
import { useFormik } from 'formik';
import { history } from './../../redux/store';
import _ from 'underscore';
import axios from 'axios';
import setting from '../../config/setting';
import { Spin, Space, Row, Col, Divider } from 'antd';
import moment from 'moment';
import { Button, Card, Form, Input, Radio, Select,DatePicker, Timeline, Tooltip } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
// import { history } from '../../redux/store';
import {
  ExperimentOutlined,
  MonitorOutlined,
  UserAddOutlined,
  UserOutlined,
  MedicineBoxOutlined,
  CalendarOutlined,
  CheckSquareOutlined
} from '@ant-design/icons/lib';
import { Tabs } from 'antd';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import { Select } from 'antd';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}
const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',

    sorter: (a, b) => a.name.length - b.name.length,
    // sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => (
      <div
        //onClick={()=>handleShowInfo}
        style={{ cursor: 'pointer' }}
      >
        {name}
      </div>
    )
  },
  {
    title: 'Address',
    key: 'address',
    dataIndex: 'address',

    sorter: (a, b) => a.address.length - b.address.length,
    // sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (address) => (
      <div
        //onClick={()=>handleShowInfo}
        style={{ cursor: 'pointer' }}
      >
        {address}
      </div>
    )
  },
  {
    title: 'Contact Number',
    key: 'phone',
    dataIndex: 'phone',

    sorter: (a, b) => a.phone.length - b.phone.length,
    // sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (phone) => (
      <div
        //onClick={()=>handleShowInfo}
        style={{ cursor: 'pointer' }}
      >
        {phone}
      </div>
    )
  }
];

const PhysicalForm = () => {

  const { innerWidth: width, innerHeight: height } = window;
  
  const [NewChangeOneN, setNewChangeOneN] = useState(false);
  const [NewChangeOneNN, setNewChangeOneNN] = useState(false);
  
    const [DataResponse, setDataResponse] = useState([]);
    //Physical Form
    const [employerName, setEmployerName] = useState('');

    const [Name, setName] = useState('');
    const [Date, setDate] = useState('');
    const [ILAWorkNumber, setILAWorkNumber] = useState('');
    const [Telephone, setTelephone] = useState('');
    const [EmergencyContact, setEmergencyContact] = useState('');
    const [Telephone2, setTelephone2] = useState('');
    const [RelationEmergerncyContact, setRelationEmergerncyContact] = useState('');
    const [DateofLastTetanusVaccination, setDateofLastTetanusVaccination] = useState('');
    const [SignDate, setSignDate] = useState('');
    const [CompanyData, setCompanyData] = useState('');
    
    const [PastMedHistory, setPastMedHistory] = useState([
        {
          prior_illness: {reason:'',date:''},
          injury: {reason:'',date:''},
          hospitalization: {reason:'',date:''},
          surgery: {reason:'',date:''},
          trauma: {reason:'',date:''},
        }
      ]);

      
      const [MEDICALEXAMINERCOMMENTSONHEALTHHISTORY, setMEDICALEXAMINERCOMMENTSONHEALTHHISTORY] = useState([
        {
        comments:'',
        Medical_Examiners_Name:'',
         
        }
    ])

    const [MEDICALEXAMINERCOMMENTSONHEALTHHISTORYTrue, setMEDICALEXAMINERCOMMENTSONHEALTHHISTORYTrue] = useState(false)

    const [PHYSICALEXAMINATION, setPHYSICALEXAMINATION] = useState([
        {
            Height:'',
            Weight:'',
         
        }
    ])

    const [PHYSICALEXAMINATIONTrue, setPHYSICALEXAMINATIONTrue] = useState(false)

    const [URINALYSISFINDINGS, setURINALYSISFINDINGS] = useState([
        {
            SP_GR:'',
            Protein:'',
            Blood:'',
            Sugar:'',
            Ketones:'',
            other_testing_desc:'',
         
        }
    ])
    const [URINALYSISFINDINGSTrue, setURINALYSISFINDINGSTrue] = useState(false)
    const [VISION, setVISION] = useState([
        {
            Right_Eye:{Uncorrected:'',Corrected:'',Horizontal_Field_of_Vision:''},
            Left_Eye:{Uncorrected:'',Corrected:'',Horizontal_Field_of_Vision:''},
            Both_Eyes:{Uncorrected:'',Corrected:'',Horizontal_Field_of_Vision:''},

            Applicant_can_recognize_and_distinguish_among_traffic_control_signals:'',
            Applicant_meets_visual_acuity_requirement_only_when_wearing_corrective_lenses:'',
            Monocular_Vision:''
         
        }
    ])
    const [VISIONTrue, setVISIONTrue] = useState(false)
    const [HEARING, setHEARING] = useState([
        {
            // Check_if_hearing_aid_used_for_tests:'',
            // Check_if_hearing_aid_required_to_meet_standard:'',
            Check_if_hearing_aid_used_for_tests_or_meet_standard:'',
            Record_distance_from_individual:{Right_Ear:'',Left_Ear:''},
            If_audiometer_is_used:{Right_Ear:'',Left_Ear:''},
         
        }
    ])

    const [HEARINGTrue, setHEARINGTrue] = useState(false)

    const [BLOODPRESSUREPULSERATE, setBLOODPRESSUREPULSERATE] = useState([
        {
            Blood_Pressure:'',
            Temperature:'',
            Pulse_Rate:'',
            Record_Pulse_Rate:'',
         
        }
    ])

    const [BLOODPRESSUREPULSERATETrue, setBLOODPRESSUREPULSERATETrue] = useState(false)

    const [PHYSICALEXAMINATION2, setPHYSICALEXAMINATION2] = useState([
        {
            General_Appearance:'',
            Eyes:'',
            Ears:'',
            Mouth_and_Throat:'',
            Heart:'',
            Lungs_and_chest:'',
            Abdomen_and_Viscera:'',
            Vascular_System:'',
            Genito_urinary_System:'',
            Extremities:'',
            Spine_other_musculoskeletal:'',
            Neurological:'',
            
        }
    ])

    const [PHYSICALEXAMINATION2True, setPHYSICALEXAMINATION2True] = useState(false)

    const [Comments2, setComments2] = useState('');

      const [healthHistory, sethealthHistory] = useState([
        {
          injury:{option:'',desc:''},
          head_brain_injury:'',
          Seizures:'',
          Medication_for_seizures_epilepsy:'',
          Eye_disorders_or_impaired_vision:{option:'',desc:''},
          Ear_disorders_loss_of_hearing:'',
          Heart_disease:'',

          Medication_for_heart_condition:'',
          Heart_surgery:'',
          High_blood_pressure:'',
          Medication_for_high_blood_pressure:'',
          Muscular_disease:'',
          Shortness_of_breath:'',

          Lung_disease_emphysema:'',
          Kidney_disease_dialysis:'',
          Liver_disease:'',
          Digestive_problems:'',
          Diabetes_or_elevated_blood_sugar:{option:'',desc:''},
          Nervous_or_psychiatric_disorder:'',

          Medication_for_nervous_or_psychiatric_disorder:'',
          Loss_of_or_altered_consciousness:'',
          Fainting_dizziness:'',
          Sleep_disorders_pauses_in_breathing:'',
          Stroke_or_paralysis:'',
          Missing_or_impaired_hand_arm_foot:'',
          Spinal_injury_or_disease:'',
          Chronic_low_back_pain:'',
          Regular_frequent_alcohol_use:'',
          Narcotic_or_habit_forming_drug_use:'',
          diagnosis:''
         

        }
      ]);

      const [HealthHistoryTrue, setHealthHistoryTrue] = useState(false);

   
  const [medicationArrOne, setMedicationArrOne] = useState([
    {
      medication:'',
      dose:'',
      route:'',
      frequency:'',
      reason:'',
      how_long:'',
      prescribed:''
    }
  ]);
  const [newChangeOne, setNewChangeOne] = useState(false);

  const [allergyArrOne, setAllergyArrOne] = useState([
    {
      allergy_type: '',
      reaction_type:'',
    }
  ]);
  const [newChangeAllergyOne, setNewChangeAllergyOne] = useState(false);

  const [MedicationTrue, setMedicationTrue] = useState(false);
  const [AllrTrue, setAllrTrue] = useState(false);
  const [PastMEdTrue, setPastMEdTrue] = useState(false);

  ////Intake Form values

  const [patientFirstName, setPatientFirstName] = useState('');
  const [patientMiddleName, setPatientMiddleName] = useState('');
  const [patientLastName, setPatientLastName] = useState('');
  const [DateofBirth, setDateofBirth] = useState('');
  const [gender, setGender] = useState('');
  const [Nationality, setNationality] = useState('');
  const [EINSSNPassportno, setEINSSNPassportno] = useState('');
  const [JobTitle, setJobTitle] = useState('');
  const [MobileNumber, setMobileNumber] = useState('');
  const [Email, setEmail] = useState('');
  const [Alternatecontactinformation, setAlternatecontactinformation] = useState('');
  
  const [TrackVisits, setTrackVisits] = useState([
    {
        firstVisitDate:'',
        Timein:'',
      Timeout:'',
      CareProvided:'',
      Height:{feet:'',inch:''},
      Weight:'',
    }
  ]);
  const [TrackVisitsTrue, setTrackVisitsTrue] = useState(false);


  const [medicationArr, setMedicationArr] = useState([
    {
      medication:'',
      dose:'',
      route:'',
      frequency:'',
      reason:'',
      how_long:'',
      prescribed:''
    }
  ]);
  const [newChange, setNewChange] = useState(false);
  
  const [allergyArr, setAllergyArr] = useState([
    {
      allergy_type: '',
      reaction_type:'',
    }
  ]);
  const [newChangeAllergy, setNewChangeAllergy] = useState(false);


  const [PastMedicalSurgicalTraumaHistory, setPastMedicalSurgicalTraumaHistory] = useState([
    {
        Priorillness:'',
        Injury:'',
        Hospitalization:'',
        Surgery:'',
        Trauma:''
    }
  ]);
  const [PastMedicalSurgicalTraumaHistoryTrue, setPastMedicalSurgicalTraumaHistoryTrue] = useState(false);

  
  const [Howlonghowmuch, setHowlonghowmuch] = useState([
    {
        Smoking:'',
        Alcohol:'',
        Sleephours:'',
        Exercise:'',
        Weight:{lossgain:'',suddenslow:''}
    }
  ]);
  const [HowlonghowmuchTrue, setHowlonghowmuchTrue] = useState(false);

  const [VisionHearing, setVisionHearing] = useState([
    {
        Vision:{option:'',desc:''},
        Hearing:{option:'',desc:''},
        Pulse:{option1:'',option2:''},
        BloodPressure:{option1:'',option2:''},
        Edema:{option:'',desc:''},
        BloodSugar:{Fasting:'',NonFasting:'',Timesincelastmedication:''},
        Pacemaker:{Kind:'',Timeofplacement:'',Place:'',LastChecked:''},
        //BloodSugar:{option:'',desc:''},
        Respirationproblems:{option:'',desc:''},
        LungSounds:{option:'',desc:''},
        Cough:{option:'',ProductiveAmount:'',color:'',howoften:''},
    }
  ]);
  const [VisionHearingTrue, setVisionHearingTrue] = useState(false);

  const [ChangeTrueSearch, setChangeTrueSearch] = useState(false);

  

  const token = useParams();
  
  useEffect(() => {
    console.log("token params::",token);

    axios.get(setting.serverUrl + '/api/EmployeeInfo', {
        // axios.get('http://localhost:3001/api/saveTest', {
        params:
        {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          token:token.email,
       
        }
      }).then(response => {
        console.log("Respone from EmployeInfo::", response.data)
        console.log("search success")
        setDataResponse(response.data)

        setName(response.data.length>0 ? response.data[0].display_name:'')
        setTelephone(response.data.length>0 ? response.data[0].Mobile:'')
        setEmployerName(response.data.length>0 ? response.data[0].company[1]:'')
        setCompanyData(response.data.length>0 ? response.data[0].company[1]:'')
        setDate(response.data.length>0 ? response.data[0].dob:'')
        setChangeTrueSearch(true)
        if (response && response.status == 200) {
  
          //history.push({ pathname: `/vertical/testingEvents` });
  
          //setRes(response.data)
  
        }
      })
   
  }, []);

  console.log("DataResponse::",DataResponse)


  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  // console.log("locationn", loc)
  // console.log("setArr NEw", arr)

  const medication = () => {
    console.log('medication');

    // setChangeTrue(true);
  };

  const allergy = () => {
    console.log('allergy');

    // setChangeTrueallergy(true);
  };
  

  const saveFormIntake = () => {
    console.log("saveFormIntake",gender);

    axios.get(setting.serverUrl + '/api/saveFormIntake', {
        // axios.get('http://localhost:3001/api/saveTest', {
        params:
        {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          id : DataResponse.length>0?DataResponse[0].id:'',
          patientFirstName: patientFirstName,
          patientMiddleName: patientMiddleName,
          patientLastName: patientLastName,
          DateofBirth:DateofBirth,
          gender: gender,
          Nationality: Nationality,
          EINSSNPassportno:EINSSNPassportno,
          JobTitle:JobTitle,
          MobileNumber:MobileNumber,
          Email:Email,
          Alternatecontactinformation:Alternatecontactinformation,
          TrackVisits:TrackVisits,
          medicationArr:medicationArr,
          allergyArr:allergyArr,
          PastMedicalSurgicalTraumaHistory:PastMedicalSurgicalTraumaHistory,
          Howlonghowmuch:Howlonghowmuch,
          VisionHearing:VisionHearing
        }
      }).then(response => {
        console.log("Respone from saveFormIntake ", response.data)
  
        if (response && response.status == 200) {
  
          //history.push({ pathname: `/vertical/testingEvents` });
  
          //setRes(response.data)
  
        }
      })
  }

  const saveFormPhysical = () => {
   
    console.log("saveFormPhysical fields",employerName,Name,Date,ILAWorkNumber,Telephone,EmergencyContact,
    Telephone2,RelationEmergerncyContact);
    console.log("saveFormPhysical1 medication",medicationArrOne );
    console.log("saveFormPhysical2 allergy",allergyArrOne,DateofLastTetanusVaccination );
    console.log("saveFormPhysical3 pastmedi",PastMedHistory );
    console.log("saveFormPhysical4 health",healthHistory );

    console.log("saveFormPhysical5 signdate",SignDate );
    console.log("saveFormPhysical6",DateofLastTetanusVaccination );
    // console.log("comments2",Comments2);

    axios.get(setting.serverUrl + '/api/saveFormPhysical', {
        // axios.get('http://localhost:3001/api/saveTest', {
        params:
        {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          empId:DataResponse.length>0 && DataResponse[0].id,
          employerName: employerName,
          Name: Name,
          Date:Date,
          ILAWorkNumber: ILAWorkNumber,
          Telephone: Telephone,
          EmergencyContact: EmergencyContact,
          Telephone2:Telephone2,
          RelationEmergerncyContact:RelationEmergerncyContact,
          medicationArrOne:medicationArrOne,
          allergyArrOne:allergyArrOne,
          DateofLastTetanusVaccination:DateofLastTetanusVaccination,
          PastMedHistory:PastMedHistory,
          healthHistory:healthHistory,
          SignDate:SignDate



        //   assignToN: assignToN,
        }
      }).then(response => {
        console.log("Respone from saveFormPhysical ", response.data)
        console.log("Respone from saveFormPhysical error ", response)
        if (response && response.status == 200) {

          alert("Thanks for submmiting physical form!!")
          // window.location.reload();
          history.push({ pathname: `/` });
  
          //setRes(response.data)
  
        }
      })
  }

  return (
    <div style={{padding:20, overflow: 'auto', height: height}}>
        {DataResponse.length>0 ?
        <div style={{padding:20}}>
      
          <span
            style={{
              fontSize: 'calc( 16px + (24 - 20) * (100vw - 400px) / (800 - 400) )',
              marginTop: 10,
              fontWeight: 700,
              textDecoration: 'underline'
            }}
            //   style={{fontWeight:700,marginTop:10,fontSize:15,textDecoration:'underline'}}
          >
            Physical Form
          </span>
          <span style={{ fontSize: 'calc( 16px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            &nbsp;(Authorization for Release of Information)
          </span>

          {/* <Row style={{marginTop:20}}>
              <Col span={6}>
</Col>
                    <Col span={15}>

                        <span style={{fontSize:'calc( 16px + (24 - 22) * (100vw - 400px) / (800 - 400) )'}}>

                        Authorization for Release of Information
                        </span>
                   
</Col>

</Row> */}
          <br />
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Employer:
                  </span>
                </Col>
                <Col span={12}>
                  <Input size='small' 
                  readOnly
                  value={employerName}
                  onChange={(e) =>setEmployerName(e.target.value)}
                  placeholder='Employer Name' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Name:
                  </span>
                </Col>
                <Col span={12}>
                  <Input 
                  readOnly
                  value={Name}
                  onChange={(e) =>setName(e.target.value)}
                  size='small' placeholder='Name' />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Date:
                  </span>
                </Col>
                <Col span={12}>
                <DatePicker style={{width:'100%'}} 
                readOnly={true}
                value={moment(Date)}
                onChange={(date, dateString) =>setDate(dateString)} />
                  {/* <Input 
                  value={Date}
                  onChange={(e) =>setDate(e.target.value)}
                  size='small' placeholder='Date' /> */}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    ILA Work Number:
                  </span>
                </Col>
                <Col span={12}>
                  <Input 
                  value={ILAWorkNumber}
                  onChange={(e) =>setILAWorkNumber(e.target.value)}
                  size='small' placeholder='ILA Work Number' />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Telephone:
                  </span>
                </Col>
                <Col span={12}>
                  <Input 
                  readOnly
                  value={Telephone}
                  onChange={(e) =>setTelephone(e.target.value)}
                  size='small' placeholder='Telephone' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Emergency Contact:
                  </span>
                </Col>
                <Col span={12}>
                  <Input 
                  value={EmergencyContact}
                  onChange={(e) =>setEmergencyContact(e.target.value)}
                  size='small' placeholder='Emergency Contact' />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Telephone:
                  </span>
                </Col>
                <Col span={12}>
                  <Input 
                  value={Telephone2}
                  onChange={(e) =>setTelephone2(e.target.value)}
                  size='small' placeholder='Telephone' />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Relationship to Emergency Contact:
                  </span>
                </Col>
                <Col span={12}>
                  <Input 
                  value={RelationEmergerncyContact}
                  onChange={(e) =>setRelationEmergerncyContact(e.target.value)}
                  size='small' placeholder='Relationship to Emergency Contact' />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <hr style={{ height: '6px', backgroundColor: 'black' }} />
          <br />

          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            I hereby authorize the use or disclosure of my health information, as described in this
            authorization:
          </span>
          <br />
          <br />
          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>1.</b> The Organization hereby authorized to receive medical information is Workplace
            Safety Screenings, the <b>{DataResponse.length>0 && CompanyData}</b>,
            and their authorized employees and agents.
          </span>
          <br />
          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>2.</b> The type of medical information to be disclosed includes:
            <br />
            &nbsp;&nbsp;
            <i
              class='fa fa-check'
              style={{
                color: 'green',
                fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
              }}
            ></i>{' '}
            &nbsp;&nbsp;Medical examination reports and/or conclusions related to a disability or
            fitness-to-work, medical surveillance and/or biomonitoring examinations.
            <br />
            &nbsp;&nbsp;
            <i
              class='fa fa-check'
              style={{
                color: 'green',
                fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
              }}
            ></i>{' '}
            &nbsp;&nbsp;Results of drug and alcohol testing for employment-related purposes
          </span>
          <br />
          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>3.</b> The purpose of this authorization is to (complete by placing a check below):
            <br />
            &nbsp;&nbsp;
            <i
              class='fa fa-check'
              style={{
                color: 'green',
                fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
              }}
            ></i>{' '}
            &nbsp;&nbsp;determine whether I am disabled or fit for duty.
            <br />
            &nbsp;&nbsp;
            <i
              class='fa fa-check'
              style={{
                color: 'green',
                fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
              }}
            ></i>{' '}
            &nbsp;&nbsp;determine whether I have a work-related injury or illness
          </span>
          <br />
          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>4.</b> I understand that I have the right to revoke this authorization at any time by
            notifying the person authorized to provide this information, as named in Item 1, above.
            I understand that the revocation is only effective after it is received and logged by
            employer. I understand that any use or disclosure made prior to the revocation of this
            authorization will not be affected by such revocation.
          </span>
          <br />

          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>5.</b> I understand that the information that is disclosed by a health care provider
            to Workplace Safety Screenings and my employer, pursuant to this authorization will no
            longer be subject to federal healthcare privacy regulations and may be re-disclosed by
            employer. I have the right to seek written assurance from my employer that any further
            disclosures will be made in conformity with OSHA and other applicable state and federal
            regulations, and the medical confidentiality requirements of employer.
          </span>
          <br />

          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>6.</b> I understand that the examiner or health care provider will receive financial
            compensation for provision of their services.
          </span>
          <br />

          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>7.</b> I understand that my employment with my employer, or the right to receive
            benefits from my employer. (or, if I am a contractor my continued provision of services
            to my employer is subject to my agreement to this authorization, and any additional
            authorization Workplace Safety Screenings or my employer requests.{' '}
          </span>
          <br />

          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>8.</b> I understand that I am entitled to receive a copy of this authorization.
          </span>
          <br />

          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>9.</b> I understand that this authorization will expire upon termination of my
            employment; provided, however, that this authorization shall automatically renew upon
            the filing of any claim, agency charge, or suit by me against my employer following the
            termination of my employment.
          </span>
          <br />

          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>10.</b> Workplace Safety Screenings and my employer is prohibited by The Genetic
            Information Nondiscrimination Act of 2008 (GINA) from requesting or requiring genetic
            information of an individual or family member of the individual, except as specifically
            allowed by GINA. To comply with GINA, an individual will not be asked to provide any
            genetic information when responding to a request for medical information. “Genetic
            Information” as defined by GINA includes an individual’s family medical history, the
            results of an individual’s or family member’s genetic tests, the fact that an individual
            or an individual’s family member sought or received genetic services, and genetic
            information of a fetus carried by an individual or an individual’s family member, or an
            embryo lawfully held by an individual or family member receiving assistive reproductive
            services.
          </span>
          <br />

          <span style={{ fontSize: 'calc( 9px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>
            <b>11.</b> My signature below acknowledges my agreement to this authorization for the
            term of my employment.
          </span>
          <br />

          {/* <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Signature:
                  </span>
                </Col>
                <Col span={12}>
                  <Input
                    style={{
                      border: 'none',
                      borderBottom: '1px solid black',
                      borderRadius: '0px !important',
                      backgroundColor: 'white !important'
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Date:
                  </span>
                </Col>
                <Col span={12}>
                  <Input
                    style={{
                      border: 'none',
                      borderBottom: '1px solid black',
                      borderRadius: '0px !important',
                      backgroundColor: 'white !important'
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Witness Signature:
                  </span>
                </Col>
                <Col span={12}>
                  <Input
                    style={{
                      border: 'none',
                      borderBottom: '1px solid black',
                      borderRadius: '0px !important',
                      backgroundColor: 'white !important'
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Date:
                  </span>
                </Col>
                <Col span={12}>
                  <Input
                    style={{
                      border: 'none',
                      borderBottom: '1px solid black',
                      borderRadius: '0px !important',
                      backgroundColor: 'white !important'
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Witness Print Name:
                  </span>
                </Col>
                <Col span={12}>
                  <Input
                    style={{
                      border: 'none',
                      borderBottom: '1px solid black',
                      borderRadius: '0px !important',
                      backgroundColor: 'white !important'
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <br /> */}
          <hr />
<br/>
          <Row>
            <Col span={12}>
              <Button
                variant="contained"
                //  disabled={!this.state.section4}
                style={{
                  backgroundColor: "rgba(255,122,90,0.90)",
                  color: "white",
                  width: 100,
                }}
                onClick={() => {
                  setNewChangeOne(false)
                  medicationArrOne.push({
                    medication:'',
                    dose:'',
                    route:'',
                    frequency:'',
                    reason:'',
                    how_long:'',
                    prescribed:''
                  });
                  setNewChangeOne(!newChangeOne)
                  
                  console.log("medicationArrOne", medicationArrOne);
                }}
              >
                Add
                      </Button>
            </Col>
          </Row>
          
          {medicationArrOne.length > 0 &&
                          medicationArrOne.map((data, idx) => (
          <div key={idx} style={{border:'1px solid grey', borderRadius:25,padding:20,marginTop:10}}>
            <Tooltip title="Delete">
            <i className="fa fa-times" 
            style={{float:'right',padding:5,cursor:'pointer',fontSize:16}}
            onClick={() => {
              console.log("medicationArrOne before::",medicationArrOne,idx)
              console.log("NewChangeOneN be",NewChangeOneN)
              // setNewChangeOneN(false);
              medicationArrOne.splice(idx,1);
              setMedicationArrOne(medicationArrOne)
              setNewChangeOneN(!NewChangeOneN);
              console.log("medicationArrOne after::",medicationArrOne,idx)
              console.log("NewChangeOneN af",NewChangeOneN)
            }}
            ></i>
            </Tooltip>
          <Row style={{ marginTop: 0 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{
                      fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )',
                      fontWeight: 700
                    }}
                  >
                    Medications:
                  </span>
                </Col>
                <Col span={12}>
                  <Select size='small' 
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                  }
                  value={medicationArrOne[idx].medication}
                  
                  onChange={(e) => {medicationArrOne[idx].medication = e;setMedicationTrue(!MedicationTrue)}}>
                    <Option value='prescription'>prescription</Option>
                    <Option value='over the counter'>over the counter</Option>
                    <Option value='vitamins'>vitamins</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* {changeTrue ? ( */}
            <div>
              <Row style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Dose:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input 
                      value={medicationArrOne[idx].dose}
                      onChange={(e) =>{medicationArrOne[idx].dose = e.target.value;setMedicationTrue(!MedicationTrue)}}
                      size='small' placeholder='' />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Route:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input 
                       value={medicationArrOne[idx].route}
                       onChange={(e) =>{medicationArrOne[idx].route = e.target.value;setMedicationTrue(!MedicationTrue)}}
                      size='small' placeholder='' />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Frequency:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input 
                       value={medicationArrOne[idx].frequency}
                       onChange={(e) =>{medicationArrOne[idx].frequency = e.target.value;setMedicationTrue(!MedicationTrue)}}
                      size='small' placeholder='' />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Reason:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input 
                       value={medicationArrOne[idx].reason}
                       onChange={(e) =>{medicationArrOne[idx].reason = e.target.value;setMedicationTrue(!MedicationTrue)}}
                      size='small' placeholder='' />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        How long taken:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input 
                       value={medicationArrOne[idx].how_long}
                       onChange={(e) =>{medicationArrOne[idx].how_long = e.target.value;setMedicationTrue(!MedicationTrue)}}
                      size='small' placeholder='' />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                        }}
                      >
                        Prescribed by:
                      </span>
                    </Col>
                    <Col span={12}>
                      <Input
                       value={medicationArrOne[idx].prescribed}
                       onChange={(e) =>{medicationArrOne[idx].prescribed = e.target.value;setMedicationTrue(!MedicationTrue)}}
                      size='small' placeholder='' />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            </div>
                          ))}
        

          <hr style={{ marginTop: 15 }} />
          <br/>
          <Row>
            <Col span={12}>
              <Button
                variant="contained"
                //  disabled={!this.state.section4}
                style={{
                  backgroundColor: "rgba(255,122,90,0.90)",
                  color: "white",
                  width: 100,
                }}
                onClick={() => {
                    setNewChangeAllergyOne(false)
                  allergyArrOne.push({
                    allergy_type:'',
                    reaction_type:'',
                  });
                  setNewChangeAllergyOne(!newChangeAllergyOne)
                  
                  console.log("allergyArrOne", allergyArrOne);
                }}
              >
                Add
                      </Button>
            </Col>
          </Row>
          {allergyArrOne.length > 0 &&
                          allergyArrOne.map((data, idx) => (
          <div key={idx} style={{border:'1px solid grey', borderRadius:25,padding:20,marginTop:10}}>
            <Tooltip title="Delete">
            <i className="fa fa-times" 
            style={{float:'right',padding:5,cursor:'pointer',fontSize:16}}
            onClick={() => {
              console.log("allergyArrOne before::",allergyArrOne,idx)
              console.log("c be",NewChangeOneNN)
              // setNewChangeOneN(false);
              allergyArrOne.splice(idx,1);
              setAllergyArrOne(allergyArrOne)
              setNewChangeOneNN(!NewChangeOneNN);
              console.log("allergyArrOne after::",allergyArrOne,idx)
              console.log("allergyArrOne af",NewChangeOneNN)
            }}
            ></i>
            </Tooltip>
          <Row style={{ marginTop: 0 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{
                      fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )',
                      fontWeight: 700
                    }}
                  >
                    Allergies:
                  </span>
                </Col>
                <Col span={12}>
                  <Select size='small'
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                  }
                  value={allergyArrOne[idx].allergy_type}
                      onChange={(e) =>{allergyArrOne[idx].allergy_type = e;setAllrTrue(!AllrTrue)}}>
                    <Option value='Food'>Food</Option>
                    <Option value='Drug'>Drug</Option>
                    <Option value='Others'>Others</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            {/* {changeTrueallergy ? ( */}
              <Col span={12}>
                <Row>
                  <Col span={10}>
                    <span
                      style={{
                        fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                      }}
                    >
                      Reaction type:
                    </span>
                  </Col>
                  <Col span={12}>
                    <Input size='small'
                    value={allergyArrOne[idx].reaction_type}
                    onChange={(e) =>{allergyArrOne[idx].reaction_type = e.target.value;setAllrTrue(!AllrTrue)}}
                    placeholder='' />
                  </Col>
                </Row>
              </Col>
           
          </Row>
          </div>
          ))}
          <br />
          <Row>
                  <Col span={6}>
                    <span
                      style={{
                        fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                      }}
                    >
                      Date of Last Tetanus Vaccination:
                    </span>
                  </Col>
                  <Col span={6}>
                  <DatePicker onChange={(date, dateString) =>setDateofLastTetanusVaccination(dateString)} />
                    {/* <Input size='small' placeholder='' /> */}
                  </Col>
                </Row>
                <br/>
          <hr/>
          <br/>
          <span
            style={{
              fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )',
              fontWeight: 700,
              textDecoration: 'underLine'
            }}
          >
            Past Medical, Surgical & Trauma History:
          </span>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Prior illness:
                  </span>
                </Col>
                <Col span={12}>
                <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Reason:
                    </span>
                  <TextArea 
                  value={PastMedHistory[0].prior_illness.reason}
                  onChange={(e) =>{PastMedHistory[0].prior_illness.reason = e.target.value;setPastMEdTrue(!PastMEdTrue)}}
                  rows={2} />
                </Col>
              </Row>
            </Col>

            <Col span={12}>
             
                <span style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Date (Include Year):</span><br/>
                <DatePicker  
                //value={PastMedHistory[0].prior_illness.date}
                onChange={(date,dateString) =>{PastMedHistory[0].prior_illness.date = dateString;setPastMEdTrue(!PastMEdTrue)}}
                />
               
            </Col>

            {/* <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Injury:
                  </span>
                </Col>
                <Col span={12}>
                Reason:
                  <Input size='small' placeholder='' />
                </Col>
              </Row>
            </Col> */}
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Injury:
                  </span>
                </Col>
                <Col span={12}>
                <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Reason:
                    </span>
                <TextArea 
                value={PastMedHistory[0].injury.reason}
                onChange={(e) =>{PastMedHistory[0].injury.reason = e.target.value;;setPastMEdTrue(!PastMEdTrue)}}
                rows={2} />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
             
             <span style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Date (Include Year):</span><br/>
             <DatePicker  
             //value={PastMedHistory[0].injury.date}
             onChange={(date,dateString) =>{PastMedHistory[0].injury.date = dateString;setPastMEdTrue(!PastMEdTrue)}}
             />
            
         </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Hospitalization:
                  </span>
                </Col>
                <Col span={12}>
                <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Reason:
                    </span>
                <TextArea 
                value={PastMedHistory[0].hospitalization.reason}
                onChange={(e) =>{PastMedHistory[0].hospitalization.reason = e.target.value;setPastMEdTrue(!PastMEdTrue)}}
                rows={2} />
                </Col>
              </Row>
            </Col>

            <Col span={12}>
             
             <span style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Date (Include Year):</span><br/>
             <DatePicker
            //  value={PastMedHistory[0].hospitalization.date}
             onChange={(date,dateString) =>{PastMedHistory[0].hospitalization.date = dateString;setPastMEdTrue(!PastMEdTrue)}}
             />
            
         </Col>

            {/* <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Surgery:
                  </span>
                </Col>
                <Col span={12}>
                Reason:
                <TextArea rows={2} />
                </Col>
              </Row>
            </Col> */}
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Surgery:
                  </span>
                </Col>
                <Col span={12}>
                <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Reason:
                    </span>
                <TextArea 
                value={PastMedHistory[0].surgery.reason}
                onChange={(e) =>{PastMedHistory[0].surgery.reason = e.target.value;setPastMEdTrue(!PastMEdTrue)}}
                rows={2} />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
             
             <span style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Date (Include Year):</span><br/>
             <DatePicker  
            //  value={PastMedHistory[0].surgery.date}
             onChange={(date,dateString) =>{PastMedHistory[0].surgery.date = dateString;setPastMEdTrue(!PastMEdTrue)}}
             />
            
         </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <span
                    style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Trauma:
                  </span>
                </Col>
                <Col span={12}>
                <span
                    style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
                  >
                    Reason:
                    </span>
                <TextArea 
                value={PastMedHistory[0].trauma.reason}
                onChange={(e) =>{PastMedHistory[0].trauma.reason = e.target.value;setPastMEdTrue(!PastMEdTrue)}}
                rows={2} />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
             
             <span style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Date (Include Year):</span><br/>
             <DatePicker  
            //  value={PastMedHistory[0].trauma.date}
             onChange={(date,dateString) =>{PastMedHistory[0].trauma.date = dateString;setPastMEdTrue(!PastMEdTrue)}}
             />
            
         </Col>
          </Row>
          <br/>
          <hr/>
          <br/>

          <span
            style={{
              textDecoration: 'underLine',
              textAlign: 'center',
              fontSize: 'calc( 16px + (24 - 20) * (100vw - 400px) / (800 - 400) )'
            }}
          >
            HEALTH HISTORY
          </span>
          <br />
          <Row style={{ marginTop: 15 }}>
            <Col
              span={12}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].injury.option = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Any illness or injury in the last 5 years?
            </Col>
            <Col span={12}>
              <Input size='small' 
              value={healthHistory[0].injury.desc}
              onChange={(e)=>{healthHistory[0].injury.desc = e.target.value;setHealthHistoryTrue(!HealthHistoryTrue)}}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 3 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
             <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].head_brain_injury = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Head/Brain injuries, disorders or illnesses
            </Col>
            {/* <Col span={12}>
                    <Input style={{border:'none',borderBottom:'1px solid black',borderRadius:'0px !important',backgroundColor:'white !important'}}  />


                    </Col> */}
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              {/* <Radio.Group name='radiogroup' defaultValue={1}> */}
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Seizures = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              {/* </Radio.Group> */}
              &nbsp;Seizures and/or epilepsy
            </Col>
            {/* <Col span={12}>
                    <Input style={{border:'none',borderBottom:'1px solid black',borderRadius:'0px !important',backgroundColor:'white !important'}}  />


                    </Col> */}
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={6}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              Medication for seizures and/or epilepsy:
              {/* <Radio.Group name="radiogroup" defaultValue={1}>
    <Radio value="yes">Yes</Radio>
    <Radio value="no">No</Radio>
    
  </Radio.Group> */}
            </Col>

            <Col span={12}>
              <Input
              value={healthHistory[0].Medication_for_seizures_epilepsy}
              onChange={(e)=>{healthHistory[0].Medication_for_seizures_epilepsy = e.target.value;setHealthHistoryTrue(!HealthHistoryTrue)}}
              size='small' />
            </Col>
          </Row>
          <br />
          <Row style={{ marginTop: 10 }}>
            <Col
              span={12}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
               <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Eye_disorders_or_impaired_vision.option = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Eye disorders or impaired vision (except corrective lenses)
            </Col>
            <Col span={12}>
              <Input 
              value={healthHistory[0].Eye_disorders_or_impaired_vision.desc}
              onChange={(e)=>{healthHistory[0].Eye_disorders_or_impaired_vision.desc = e.target.value;setHealthHistoryTrue(!HealthHistoryTrue)}}
              size='small' />
            </Col>
          </Row>

          <Row style={{ marginTop: 3 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
               <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Ear_disorders_loss_of_hearing = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Ear disorders, loss of hearing or balance
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
               <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Heart_disease = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Heart disease or heart attack; other cardiovascular condition
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={6}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              Medication for heart condition(s):
            </Col>

            <Col span={12}>
              <Input
              value={healthHistory[0].Medication_for_heart_condition}
              onChange={(e)=>{healthHistory[0].Medication_for_heart_condition = e.target.value;setHealthHistoryTrue(!HealthHistoryTrue)}}
              size='small' />
            </Col>
          </Row>

          <br />

          <Row style={{ marginTop: 3 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Heart_surgery = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Heart surgery (valve replacement/bypass, angioplasty, pacemaker)
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].High_blood_pressure = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;High blood pressure
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={6}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              Medication for high blood pressure:
            </Col>

            <Col span={12}>
              <Input
              value={healthHistory[0].Medication_for_high_blood_pressure}
              onChange={(e)=>{healthHistory[0].Medication_for_high_blood_pressure = e.target.value;setHealthHistoryTrue(!HealthHistoryTrue)}}
              size='small' />
            </Col>
          </Row>
          <br />
          <Row style={{ marginTop: 3 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Muscular_disease = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Muscular disease
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Shortness_of_breath = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Shortness of breath
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Lung_disease_emphysema = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Lung disease, emphysema, asthma, chronic bronchitis
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Kidney_disease_dialysis = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Kidney disease, dialysis
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Liver_disease = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Liver disease
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Digestive_problems = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Digestive problems
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Diabetes_or_elevated_blood_sugar.option = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Diabetes or elevated blood sugar controlled by:
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col span={3}></Col>
            <Col
              span={12}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Diabetes_or_elevated_blood_sugar.desc = e.target.value}>
                <Radio value='Diet' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Diet</Radio>
                <Radio value='Pill' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Pill</Radio>
                <Radio value='Insulin' style={{ fontSize: 'calc( 12px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}>Insulin</Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Nervous_or_psychiatric_disorder = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Nervous or psychiatric disorder(s), e.g. severe depression
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col
              span={8}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              Medication for nervous or psychiatric disorder(s):
            </Col>

            <Col span={12}>
              <Input
              value={healthHistory[0].Medication_for_nervous_or_psychiatric_disorder}
              onChange={(e)=>{healthHistory[0].Medication_for_nervous_or_psychiatric_disorder = e.target.value;setHealthHistoryTrue(!HealthHistoryTrue)}}
              size='small' />
            </Col>
          </Row>
          <br />
          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Loss_of_or_altered_consciousness = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Loss of or altered consciousness
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Fainting_dizziness = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Fainting, dizziness
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Sleep_disorders_pauses_in_breathing = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Sleep disorders, pauses in breathing while asleep, daytime sleepiness, loud
              snoring
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Stroke_or_paralysis = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Stroke or paralysis
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Missing_or_impaired_hand_arm_foot = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Missing or impaired hand, arm, foot, leg, finger, toe
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group
                name='radiogroup'
                defaultValue={1}
                onChange={(e)=>healthHistory[0].Spinal_injury_or_disease = e.target.value}
                
              >
                <Radio value='yes' style={{ fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )' }}>Yes</Radio>
                <Radio value='no' style={{ fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )' }}>No</Radio>
              </Radio.Group>
              &nbsp;Spinal injury or disease
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Chronic_low_back_pain = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Chronic low back pain
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Regular_frequent_alcohol_use = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Regular, frequent alcohol use
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col
              span={24}
              style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
            >
              <Radio.Group name='radiogroup' defaultValue={1} onChange={(e)=>healthHistory[0].Narcotic_or_habit_forming_drug_use = e.target.value}>
                <Radio value='yes' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>Yes</Radio>
                <Radio value='no' style={{fontSize: 'calc( 12px + (24 - 20) * (100vw - 400px) / (800 - 400) )'}}>No</Radio>
              </Radio.Group>
              &nbsp;Narcotic or habit-forming drug use
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                }}
              >
                For any YES answers in the health history (pages 3-4) indicate onset date,
                diagnosis, treating physician’s name and address, and any current limitation(s).
              </span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <TextArea 
              value={healthHistory[0].diagnosis}
              onChange={(e)=>{healthHistory[0].diagnosis = e.target.value;setHealthHistoryTrue(!HealthHistoryTrue)}}
              rows={8} />
            </Col>
          </Row>
          <br />

          <Row>
            <Col span={24}>
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )'
                }}
              >
                I certify that the above information is complete and true. I understand that
                inaccurate, false or missing information may invalidate the examination and my
                authorization to work.
                <br />I agree that this medical history form on my family, personal and occupational
                health and result of my physical examination including laboratory and screening for
                job findings undertaken at the clinic, will be the property of Workplace Safety
                Screenings and West Gulf Maritime Association.{' '}
              </span>
            </Col>
          </Row>
          <br />
          <Row style={{ marginTop: 15 }}>
            <Col span={6}>
              <span
                style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
              >
                Worker’s Signature:
              </span>
              <Input placeholder='' />
            </Col>
            <Col span={6}></Col>
            <Col span={6}>
              <span
                style={{ fontSize: 'calc( 10px + (24 - 22) * (100vw - 400px) / (800 - 400) )' }}
              >
                Date:
              </span>
              <br/>
              <DatePicker style={{width:'100%'}} onChange={(date, dateString) =>setSignDate(dateString)} />
            </Col>
            <Col span={6}></Col>
          </Row>

         <br/><br/>

            <Row style={{ marginTop: 20 }}>
            <Col span={8}></Col>
            <Col span={8}>
            <Button type='primary' style={{ width: '100%', backgroundColor:"#1b3969" }} onClick={saveFormPhysical}>Save</Button>
                </Col>
                </Row>
        </div>
      :
<Row>
          <Col span={12}>
          </Col>
          <Col span={8}>
            <Space size="middle" style={{ marginTop: 50 }}>

              <Spin size="large" />
            </Space>
          </Col>
        </Row>
        }
    </div>
  );
};

export default PhysicalForm;
