import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'underscore';
import { history } from '../../redux/store';
import setting from '../../config/setting';
import { useLocation } from 'react-router-dom';
import { Spin, Space, Row, Col, Button, Card, Form, Input, Select, notification } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;

const AddLocation = () => {
  const [PatArrTrue, setPatArrTrue] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [loadingCountrys, setLoadingCountrys] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [streetNew, setstreet] = useState('');
  const [street2New, setstreet2] = useState('');
  const [locationName, setLocation] = useState('');
  const [stateNewA, setstateNew] = useState('');
  const [cityNewA, setcityNew] = useState('');
  const [zipNew, setzip] = useState('');
  const [countrNew, setCountr] = useState('');
  const [countName, setCountrName] = useState('');
  const [stateNewId, setstateNewID] = useState('');
  const [stateLocal, setStateLocal] = useState('');
  const [country, setCountry] = useState([]);
  const [CompDataNew, setCompDataNew] = useState([]);
  const [state, setState] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorVal, setError] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [bool, setBool] = useState(false);
  const locationState = useLocation();
  const propsData = locationState.state;

  useEffect(() => {
    let companyId = localStorage.getItem('customerId');
    // console.log('this.props', propsData);

    if (propsData == undefined) {
      history.goBack();
    } else {
      getCompanyData();
      setSelectedCompany(companyId);
    }
  }, []);
  function getCompanyData() {
    axios
      .post(setting.serverUrl + '/api/allCustomerData', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        //  console.log('Respone from allCustomerData ', response.data);
        if (response && response.status == 200) {
          let fetchedRecords = response.data;
          let companyRecords = [];
          fetchedRecords.forEach((element) => {
            console.log('element', element);
            let exist = companyRecords.filter((company) => company.id == element.id);
            console.log('exist recod', exist);
            if (exist.length == 0) {
              companyRecords.push({
                id: element.id,
                name: element.display_name
              });
            }
          });
          setCompDataNew(companyRecords);
          setBool(true);
          setTimeout(() => {
            getCountry();
          }, 1000);
        }
      });
  }
  function getCountry() {
    setLoadingCountrys(true);
    setPatArrTrue(false);
    let countryArr = [];
    axios
      .post(setting.serverUrl + '/api/getCountry', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass')
        }
      })
      .then((response) => {
        console.log('Respone from getCountry', response.data);
        setCountry(response.data);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          setPatArrTrue(true);
          setLoadingCountrys(false);
        }
      });
  }

  function saveTest() {
    setIsLoading(true);
    setEnableSave(true);
    if (selectedCompany && streetNew && stateNewA && countName && locationName) {
      axios
        .post(setting.serverUrl + '/api/saveLocation', {
          params: {
            email: localStorage.getItem('myData'),
            user: localStorage.getItem('myData'),
            pass: localStorage.getItem('myPass'),

            name: locationName,
            locationId: locationId,
            streetNew: streetNew,
            street2New: street2New,
            stateNewA: [parseInt(stateNewId), stateNewA],
            cityNewA: cityNewA,
            zipNew: zipNew,
            countrNew: [parseInt(countrNew), countName],
            comp_id: selectedCompany
          }
        })
        .then((response) => {
          // console.log('Respone from saveLocation::::: ', response.data);
          if (response && response.data.error) {
            alert('Some Technical Error Occurred!');
          }
          setIsLoading(false);
          setEnableSave(false);

          if (response && response.status == 200) {
            notification['success']({
              message: 'Saved Successfully'
            });
            history.push({ pathname: `/vertical/locationList` });
          }
        });
    } else {
      setError(true);
    }
  }

  function saveLocationCustomer() {
    saveTest();
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  function onSearchState(val) {
    console.log('search:', val);
  }

  function street(event) {
    console.log('street', event.target.value);
    setstreet(event.target.value);
  }
  function location(event) {
    console.log('location', event.target.value);
    setLocation(event.target.value);
  }
  function locationIds(event) {
    console.log('location', event.target.value);
    setLocationId(event.target.value);
  }
  function street2(event) {
    console.log('street2', event.target.value);
    setstreet2(event.target.value);
  }

  function stateNew(event) {
    console.log('stateNew', JSON.parse(event));
    var parseDataState = JSON.parse(event);
    setstateNew(parseDataState.name);
    setstateNewID(parseDataState.id);
  }

  function cityNew(event) {
    console.log('cityNew', event.target.value);
    setcityNew(event.target.value);
  }

  function zip(event) {
    console.log('zip', event.target.value);
    const reg = /^\d+$/;
    if (reg.test(event.target.value)) {
      setzip(event.target.value);
    }
  }

  function stateSelect(event) {
    setStateLocal(JSON.parse(event).name);

    var parseData = JSON.parse(event);
    setLoadingStates(true);
    setCountr(parseData.id);
    setCountrName(parseData.name);
    axios
      .post(setting.serverUrl + '/api/getState', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          event: parseData.id
        }
      })
      .then((response) => {
        // console.log('Respone from getState', response.data);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }

        if (response && response.status == 200) {
          setState(response.data);
          setLoadingStates(false);
        }
      });
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const filterCompanies = (event) => {
    console.log('selected company', event);
    setSelectedCompany(event);
    setSelectedCompanyName(event.name);
  };
  return (
    <>
      <div>
        <Button
          size='small'
          style={{
            backgroundColor: 'rgb(61 88 106)',
            color: 'white',
            marginTop: 5,
            marginLeft: 18,
            padding: 10
          }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>
      <div style={{ padding: 20 }}>
        <Card title='Add Location' style={{ padding: 20 }}>
          {PatArrTrue ? (
            <div
              className='row'
              style={{ borderRadius: 20, paddingLeft: 20, margin: 0, paddingBottom: 0 }}
            >
              <div className='col-md-12 col-sm-12'>
                <Card>
                  <Form
                    layout='vertical'
                    initialValues={{ remember: true }}
                    onFinish={saveLocationCustomer}
                    onFinishFailed={onFinishFailed}
                  >
                    <div className='row'>
                      <div className='col-md-4 col-sm-12'>
                        <FormItem label='Company' name='company'>
                          <Select
                            showSearch
                            optionFilterProp='children'
                            defaultValue={localStorage.getItem('displayName')}
                          >
                            <Option value={localStorage.getItem('customerId')}>
                              {localStorage.getItem('displayName')}
                            </Option>
                          </Select>
                        </FormItem>
                      </div>
                      <div className='col-md-2'></div>
                      <div className='col-md-4 col-sm-12'>
                        <FormItem
                          label='Location Name'
                          name='Location name'
                          rules={[{ required: true, message: 'This field is required.' }]}
                        >
                          <Input
                            placeholder='Location Name'
                            style={{ backgroundColor: '#fff' }}
                            onChange={(event) => location(event)}
                          />
                        </FormItem>

                        <div className='col-md-2'></div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4 col-sm-12'>
                        <FormItem
                          label='Location ID'
                          name='Location ID'
                          rules={[{ required: true, message: 'This field is required.' }]}
                        >
                          <Input
                            style={{ backgroundColor: '#fff' }}
                            placeholder='Location ID'
                            onChange={(event) => locationIds(event)}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <h4>Add Address</h4>
                    <div className='row'>
                      <div className='col-md-4 col-sm-12'>
                        <FormItem
                          label='Street'
                          name='Street'
                          rules={[{ required: true, message: 'This field is required.' }]}
                        >
                          <Input
                            style={{ backgroundColor: '#fff' }}
                            placeholder='Street'
                            onChange={(event) => street(event)}
                          />
                        </FormItem>
                      </div>
                      <div className='col-md-2'></div>
                      <div className='col-md-4 col-sm-12'>
                        <FormItem label='Street 2'>
                          <Input
                            placeholder='Street 2'
                            onChange={(event) => street2(event)}
                            style={{ backgroundColor: '#fff' }}
                          />
                        </FormItem>
                      </div>
                      <div className='col-md-2'></div>
                    </div>

                    <div className='row'>
                      <div className='col-md-4 col-sm-12'>
                        <FormItem
                          label='Country'
                          name='Country'
                          rules={[{ required: true, message: 'This field is required.' }]}
                        >
                          <Select
                            showSearch
                            optionFilterProp='children'
                            loading={loadingCountrys}
                            onChange={(event) => stateSelect(event)}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {country.length > 0 &&
                              country.map((count, index) => (
                                <Option value={JSON.stringify(count)}>{count.name}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>
                      <div className='col-md-2'></div>
                      <div className='col-md-4 col-sm-12'>
                        <FormItem
                          label='State'
                          name='State'
                          rules={[{ required: true, message: 'This field is required.' }]}
                        >
                          <Select
                            showSearch
                            optionFilterProp='children'
                            loading={loadingStates}
                            onChange={(event) => stateNew(event)}
                            onSearch={onSearchState}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {state.length > 0 &&
                              state.map((state, index) => (
                                <Option value={JSON.stringify(state)}>{state.name}</Option>
                              ))}
                          </Select>
                        </FormItem>
                      </div>
                      <div className='col-md-2'></div>
                    </div>

                    <div className='row'>
                      <div className='col-md-4 col-sm-12'>
                        <FormItem label='City'>
                          <Input
                            onChange={(event) => cityNew(event)}
                            placeholder='City'
                            style={{ backgroundColor: '#fff' }}
                          />
                        </FormItem>
                      </div>
                      <div className='col-md-2'></div>
                      <div className='col-md-4 col-sm-12'>
                        <FormItem label='Zip Code'>
                          <Input
                            style={{ backgroundColor: '#fff' }}
                            placeholder='Zip Code'
                            pattern='^\d+$'
                            onChange={(event) => zip(event)}
                          />
                        </FormItem>
                      </div>
                      <div className='col-md-2'></div>
                    </div>

                    <div className='row'>
                      <div className='col-md-4 col-sm-4'></div>
                      <div className='col-md-5 col-sm-5'>
                        <Button
                          type='primary'
                          disabled={enableSave}
                          style={{ width: '50%', backgroundColor: '#1b3969' }}
                          onclick={saveTest}
                          htmlType={'submit'}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                    {isLoading && (
                      <Row>
                        <Col span={10}></Col>
                        <Col span={8}>
                          <Space size='middle' style={{ marginTop: 10 }}>
                            <Spin size='large' />
                          </Space>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </Card>
              </div>
            </div>
          ) : (
            <Row>
              <Col span={10}></Col>
              <Col span={8}>
                <Space size='middle' style={{ marginTop: 10 }}>
                  <Spin size='large' />
                </Space>
              </Col>
            </Row>
          )}
        </Card>
      </div>
    </>
  );
};

export default AddLocation;
