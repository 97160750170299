import { IRoute } from '../interfaces/routing';

import NotFound from '../pages/sessions/404';
import InternalError from '../pages/sessions/500';
import SignIn from '../pages/sessions/Sign-in';
import SignUp from '../pages/sessions/Sign-up';
import IntakeForm from '../pages/forms/intakeForm';
import PhysicalForm from '../pages/forms/physicalForm'
import OTPlogIn from '../pages/sessions/OTPLogIn';
import AudiometricHistory from '../pages/forms/audiometricHistory';
import ForgotPassword from '../pages/sessions/forgotPassword';
import ChangePassword from '../pages/sessions/changePassword';
import Checkin from '../pages/checkin/Checkin';
import CheckinDetails from '../pages/forms/CheckinDetails';
export const sessionRoutes: IRoute[] = [
  {
    path: 'intakeform/:email',
    component: IntakeForm
  },
  {
    path: 'audiometrichistory/:email',
    component: AudiometricHistory
  },
  {
    path: 'physicalform/:email',
    component: PhysicalForm
  },
  {
    path: 'page-404',
    component: NotFound
  },
  {
    path: 'page-500',
    component: InternalError
  },
  {
    path: 'sign-in',
    component: SignIn
  },
  {
    path: 'OTPlogin',
    component: OTPlogIn
  },
  {
    path: 'sign-up',
    component: SignUp
  },
  {
    path: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: 'change-password',
    component: ChangePassword
  },
  {
    path:'check-in',
    component:Checkin
  },
  {
    path:'checkdetails',
    component:CheckinDetails
  }
];
