import React, { useEffect } from 'react';
import { Form } from 'antd';
import _ from 'underscore';
import { useHistory } from 'react-router-dom';

const ClinicHourTab = (propsData) => {
  let propsValues = propsData && propsData.propsData;
  const history = useHistory();
  useEffect(() => {
    if (propsData == undefined) {
      history.goBack();
    }
  }, []);
  return (
    <>
      <Form layout='vertical' style={{ borderRadius: 20, backgroundColor: '#fff' }}>
        <h5 style={{ color: '#247', padding: 20, paddingBottom: 0, marginTop: '-15px' }}>
          Clinic Open Close Timings
        </h5>

        <div className='row' style={{ paddingLeft: '5px' }}>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div
            className='col-md-6 col-sm-5'
            style={{ borderRight: 'solid #abc 2px', paddingLeft: 30 }}
          >
            <p>
              <span style={{ width: '64.5%', display: 'inline-block', fontWeight: 900 }}>
                Mon Open Time
              </span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.mon_open_time ? propsValues.mon_open_time : ''}
              </span>
            </p>
            <p>
              <span style={{ width: '65%', display: 'inline-block', fontWeight: 900 }}>
                Tues Open Time
              </span>
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.tues_open_time ? propsValues.tues_open_time : ''}
              </span>
            </p>
            <p>
              <span style={{ width: '65%', display: 'inline-block', fontWeight: 900 }}>
                Wed Open Time
              </span>
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.wed_open_time ? propsValues.wed_open_time : ''}
              </span>
            </p>
            <p>
              <span style={{ width: '65%', display: 'inline-block', fontWeight: 900 }}>
                Thur Open Time
              </span>
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.thr_open_time ? propsValues.thr_open_time : ''}
              </span>
            </p>
            <p>
              <span style={{ width: '65%', display: 'inline-block', fontWeight: 900 }}>
                Fri Open Time
              </span>
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.fri_open_time ? propsValues.fri_open_time : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-5 col-sm-5' style={{ paddingLeft: '35px' }}>
            {/* <h6>PERSONAL INFORMATION</h6> */}
            <p>
              <span style={{ width: '70.5%', display: 'inline-block', fontWeight: 900 }}>
                Mon Close Time
              </span>{' '}
              <span style={{ width: '28%', display: 'inline-block' }}>
                {propsValues && propsValues.mon_close_time ? propsValues.mon_close_time : ''}
              </span>
            </p>
            <p>
              <span style={{ width: '71%', display: 'inline-block', fontWeight: 900 }}>
                Tues Close Time
              </span>
              <span style={{ width: '28%', display: 'inline-block' }}>
                {propsValues && propsValues.tues_close_time ? propsValues.tues_close_time : ''}
              </span>
            </p>
            <p>
              <span style={{ width: '71%', display: 'inline-block', fontWeight: 900 }}>
                Wed Close Time
              </span>
              <span style={{ width: '28%', display: 'inline-block' }}>
                {propsValues && propsValues.wed_close_time ? propsValues.wed_close_time : ''}
              </span>
            </p>
            <p>
              <span style={{ width: '71%', display: 'inline-block', fontWeight: 900 }}>
                Thur Close Time
              </span>
              <span style={{ width: '28%', display: 'inline-block' }}>
                {propsValues && propsValues.thr_close_time ? propsValues.thr_close_time : ''}
              </span>
            </p>
            <p>
              <span style={{ width: '71%', display: 'inline-block', fontWeight: 900 }}>
                Fri Close Time
              </span>
              <span style={{ width: '28%', display: 'inline-block' }}>
                {propsValues && propsValues.fri_close_time ? propsValues.fri_close_time : ''}
              </span>
            </p>
          </div>
        </div>

        <h5 style={{ color: '#247', paddingLeft: 20, paddingTop: 10 }}>Weekend (Open/Close)</h5>
        <div className='row' style={{ paddingLeft: '5px' }}>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div
            className='col-md-6 col-sm-5'
            style={{ borderRight: 'solid #abc 2px', paddingLeft: '30px' }}
          >
            <p>
              <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                Saturday
              </span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.sat_open_close ? propsValues.sat_open_close : ''}
              </span>
            </p>
            {propsValues.sat_open_close != 'close' ? (
              <>
                <p>
                  <span style={{ width: '30%', display: 'inline-block', fontWeight: 900 }}>
                    Sat Open Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.sat_open_time ? propsValues.sat_open_time : ''}
                  </span>
                </p>
                <p>
                  <span style={{ width: '30%', display: 'inline-block', fontWeight: 900 }}>
                    Sat Close Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.sat_close_time ? propsValues.sat_close_time : ''}
                  </span>
                </p>
              </>
            ) : (
              ''
            )}
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-5 col-sm-5' style={{ paddingLeft: '37px' }}>
            <p>
              <span style={{ width: '68%', display: 'inline-block', fontWeight: 900 }}>Sunday</span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.sun_open_close ? propsValues.sun_open_close : ''}
              </span>
            </p>
            {propsValues.sun_open_close != 'close' ? (
              <>
                <p>
                  <span style={{ width: '30%', display: 'inline-block', fontWeight: 900 }}>
                    Sun Open Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.sun_open_time ? propsValues.sun_open_time : ''}
                  </span>
                </p>
                <p>
                  <span style={{ width: '30%', display: 'inline-block', fontWeight: 900 }}>
                    Sun Close Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.sun_close_time ? propsValues.sun_close_time : ''}
                  </span>
                </p>
              </>
            ) : (
              ''
            )}
          </div>
        </div>

        <h5 style={{ color: '#247', paddingLeft: 20, paddingTop: 10 }}>Lunch Timing</h5>
        <div className='row' style={{ paddingLeft: 5 }}>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div
            className='col-md-6 col-sm-5'
            style={{ borderRight: 'solid #abc 2px', paddingLeft: 32 }}
          >
            <p>
              <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                Mon Lunch
              </span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.mon_lunch_hours ? propsValues.mon_lunch_hours : ''}
              </span>
            </p>
            {propsValues.mon_lunch_hours != 'open' ? (
              <>
                <p>
                  <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                    Mon Lunch Open Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.mon_lunch_open ? propsValues.mon_lunch_open : ''}
                  </span>
                </p>
                <p>
                  <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                    Mon Lunch Close Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.mon_lunch_close ? propsValues.mon_lunch_close : ''}
                  </span>
                </p>
              </>
            ) : (
              ''
            )}
            <p>
              <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                Tues Lunch
              </span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.tues_lunch_hours ? propsValues.tues_lunch_hours : ''}
              </span>
            </p>
            {propsValues.tues_lunch_hours != 'open' ? (
              <>
                <p>
                  <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                    Tues Lunch Open Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.tues_lunch_open ? propsValues.tues_lunch_open : ''}
                  </span>
                </p>
                <p>
                  <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                    Tues Lunch Close Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.tues_lunch_close
                      ? propsValues.tues_lunch_close
                      : ''}
                  </span>
                </p>
              </>
            ) : (
              ''
            )}
            <p>
              <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                Wed Lunch
              </span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.wed_lunch_hours ? propsValues.wed_lunch_hours : ''}
              </span>
            </p>
            {propsValues.wed_lunch_hours != 'open' ? (
              <>
                <p>
                  <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                    Wed Lunch Open Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.wed_lunch_open ? propsValues.wed_lunch_open : ''}
                  </span>
                </p>
                <p>
                  <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                    Wed Lunch Close Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.wed_lunch_close ? propsValues.wed_lunch_close : ''}
                  </span>
                </p>
              </>
            ) : (
              ''
            )}
            <p>
              <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                Thur Lunch
              </span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.thr_lunch_hours ? propsValues.thr_lunch_hours : ''}
              </span>
            </p>
            {propsValues.thr_lunch_hours != 'open' ? (
              <>
                <p>
                  <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                    Thur Lunch Open Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.thr_lunch_open ? propsValues.thr_lunch_open : ''}
                  </span>
                </p>
                <p>
                  <span style={{ width: '63%', display: 'inline-block', fontWeight: 900 }}>
                    Thur Lunch Close Time
                  </span>{' '}
                  <span style={{ width: '63%', display: 'inline-block' }}>
                    {propsValues && propsValues.thr_lunch_close ? propsValues.thr_lunch_close : ''}
                  </span>
                </p>
              </>
            ) : (
              ''
            )}
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{paddingLeft:'37px'}}>
            <p>
              <span style={{ width: '56%', display: 'inline-block', fontWeight: 900 }}>
                Fri Lunch
              </span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.fri_lunch_hours ? propsValues.fri_lunch_hours : ''}
              </span>
            </p>
            {propsValues.fri_lunch_hours != 'open' ? (
              <>
                <p>
                  <span style={{ width: '56%', display: 'inline-block', fontWeight: 900 }}>
                    Fri Lunch Open Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.fri_lunch_open ? propsValues.fri_lunch_open : ''}
                  </span>
                </p>
                <p>
                  <span style={{ width: '56%', display: 'inline-block', fontWeight: 900 }}>
                    Fri Lunch Close Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.fri_lunch_close ? propsValues.fri_lunch_close : ''}
                  </span>
                </p>
              </>
            ) : (
              ''
            )}
            <p>
              <span style={{ width: '56%', display: 'inline-block', fontWeight: 900 }}>
                Sat Lunch
              </span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.sat_lunch_hours ? propsValues.sat_lunch_hours : ''}
              </span>
            </p>
            {propsValues.sat_lunch_hours != 'open' ? (
              <>
                <p>
                  <span style={{ width: '56%', display: 'inline-block', fontWeight: 900 }}>
                    Sat Lunch Open Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.sat_lunch_open ? propsValues.sat_lunch_open : ''}
                  </span>
                </p>
                <p>
                  <span style={{ width: '56%', display: 'inline-block', fontWeight: 900 }}>
                    Sat Lunch Close Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.sat_lunch_close ? propsValues.sat_lunch_close : ''}
                  </span>
                </p>
              </>
            ) : (
              ''
            )}

            <p>
              <span style={{ width: '56%', display: 'inline-block', fontWeight: 900 }}>
                Sun Lunch
              </span>{' '}
              <span style={{ width: '30%', display: 'inline-block' }}>
                {propsValues && propsValues.sun_lunch_hours ? propsValues.sun_lunch_hours : ''}
              </span>
            </p>
            {propsValues.sat_lunch_hours != 'open' ? (
              <>
                <p>
                  <span style={{ width: '56%', display: 'inline-block', fontWeight: 900 }}>
                    Sun Lunch Open Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.sun_lunch_open ? propsValues.sun_lunch_open : ''}
                  </span>
                </p>
                <p>
                  <span style={{ width: '56%', display: 'inline-block', fontWeight: 900 }}>
                    Sun Lunch Close Time
                  </span>{' '}
                  <span style={{ width: '30%', display: 'inline-block' }}>
                    {propsValues && propsValues.sun_lunch_close ? propsValues.sun_lunch_close : ''}
                  </span>
                </p>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <h5 style={{ color: '#247', paddingLeft: 20, paddingTop: 10 }}>More Important Services</h5>
        <div className='row' style={{paddingLeft:'5px'}}>
          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{ borderRight: 'solid #abc 2px', paddingLeft:30 }}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '65%', fontWeight: 900 }}>
                24/7 onsite collection scheduled
              </span>{' '}
              <span style={{ flexBasis: '25%' }}>
                {propsValues && propsValues.onsite_schedule_selection
                  ? propsValues.onsite_schedule_selection
                  : ''}
              </span>
            </p>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>
                Does the Clinic provide full Occupational medical services on weekend
              </span>
              <span style={{ paddingLeft:'25%' }}>
                {propsValues && propsValues.weekend_service ? propsValues.weekend_service : ''}
              </span>
            </p>

            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '40%', fontWeight: 900 }}>Detail Weekend Services</span>{' '}
              <span style={{ flexBasis: '25%' }}>
                {propsValues && propsValues.detail_service ? propsValues.detail_service : ''}
              </span>
            </p>
          </div>

          {/* <div className='col-md-1 col-sm-1'></div> */}
          <div className='col-md-6 col-sm-5' style={{paddingLeft:'37px'}}>
            <p style={{ display: 'flex' }}>
              <span style={{ flexBasis: '83%', fontWeight: 900 }}>
                24/7 onsite-collection immediate
              </span>{' '}
              <span style={{ flexBasis: '60%' }}>
                {propsValues && propsValues.onsite_collection_immediate_selection
                  ? propsValues.onsite_collection_immediate_selection
                  : ''}
              </span>
            </p>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ClinicHourTab;
