import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import settings from '../../config/setting';
import { Spin, Space, Row, Col, message, Select, Button, Modal, DatePicker } from 'antd';
import moment from 'moment';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import '@grapecity/wijmo.styles/wijmo.css';
import { FolderOpenTwoTone } from '@ant-design/icons';

const { RangePicker } = DatePicker;
let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'notifications.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  // exportToPdf(flex, options) {
  //   wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
  //     maxPages: 100,
  //     exportMode: wjcGridPdf.ExportMode.All,
  //     scaleMode: wjcGridPdf.ScaleMode.ActualSize,
  //     documentOptions: {
  //       pageSettings: {
  //         layout: wjcPdf.PdfPageOrientation.Landscape
  //       },
  //       header: {
  //         declarative: {
  //           text: '\t&[Page]\\&[Pages]'
  //         }
  //       },
  //       footer: {
  //         declarative: {
  //           text: '\t&[Page]\\&[Pages]'
  //         }
  //       }
  //     },
  //     styles: {
  //       cellStyle: {
  //         backgroundColor: '#ffffff',
  //         borderColor: '#c6c6c6'
  //       },
  //       altCellStyle: {
  //         backgroundColor: '#f9f9f9'
  //       },
  //       groupCellStyle: {
  //         backgroundColor: '#dddddd'
  //       },
  //       headerCellStyle: {
  //         backgroundColor: '#eaeaea'
  //       },
  //       // Highlight Invalid Cells
  //       errorCellStyle: {
  //         backgroundColor: 'rgba(255, 0, 0, 0.3)'
  //       }
  //     },
  //     customCellContent: false
  //     // formatItem: (e) => this._formatPdfItem(e, options)
  //   });
  // }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}
const Notifications = () => {
  const [notification, setNotification] = useState([]);
  const [nofound, setNoFound] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  //const [filterText, setFilterText] = useState('');
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [messageValue, setMessageValue] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [noRecordMessage, setNoRecordMessage] = useState('No Notification Found !');
  const theGrid = useRef();
  const filterRefCounter = useRef();
  const theSearch = useRef();

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;

  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
  }

  const getNotifications = () => {
    setHasPermission(false);
    setIsLoading(true);
    setNoFound(true);
    axios
      .post(settings.serverUrl + '/api/getNotification', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          company: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response && response.status == 200) {
          let result = response && response.data;
          result.sort((a,b)=>{
            const dateA = new Date(a.create_date);
            const dateB = new Date(b.create_date);
            return dateB - dateA;
          });
          let createDateNew =
            result &&
            result.length > 0 &&
            result.map((k) => {
              return {
                //...k,
                createDate: k.create_date && moment(k.create_date).format('MM/DD/YYYY HH:mm:ss'),
                newLocation:
                  k.location &&
                  k.location.length > 0 &&
                  !k.location[1].includes('|') &&
                  !k.location[1].includes(',')
                    ? k.location[1]
                    : k.location[1]?.split('|')[1] || k.location[1]?.split(',')[1],
                notificationType: k.notification_type !== false ? k.notification_type : '',
                newSubject: k.subject !== false ? k.subject : '',
                newStatus: k.status !== false ? k.status : '',
                from_email: k.from_email !== false ? k.from_email : '',
                html_body: k.html_body !== false ? k.html_body : '',
                newEmpName:
                  k.emp_selected.length > 0 &&
                  (k.emp_selected[1] != false || k.emp_selected[1] != '')
                    ? k.emp_selected[1]
                    : ''
              };
            });
          setNotification(createDateNew);
          setHasPermission(true);
          setNoFound(false);
        } else if (response.status == 202) {
          setHasPermission(true);
          setNoFound(false);
          setErrMsg('No Record Found');
        } else {
          setHasPermission(false);
          setErrMsg('Access permission not allowed for Notifications!');
        }
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (notification.length > 0) {
      const collectionView1 = new CollectionView(notification, {
        pageSize: notification.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(notification, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      collectionView2.filters.push((item) => {
        const startDate = dateRange && dateRange.length > 0 && moment(dateRange[0]).startOf('day');
        const endDate = dateRange && dateRange.length > 0 && moment(dateRange[1]).endOf('day');

        return (
          (!startDate || moment(item.createDate, 'MM-DD-YYYY').isSameOrAfter(startDate)) &&
          (!endDate || moment(item.createDate, 'MM-DD-YYYY').isSameOrBefore(endDate))
        );
      });

      setView(collectionView2);
    }
  }, [notification, dateRange, pageSize]);

  /* Filter to set date range */
  const setDateRangeFilter = (dates) => {
    console.log('dates----', dates);
    if (dates !== null) {
      setDateRange(dates.map((date) => date.format('MM-DD-YYYY')));
    } else {
      setDateRange([]);
    }
  };

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  /* method to export excel */
  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
    } else {
      exportService.cancelExcelExport((progress) => {
        resetState();
      });
    }
  };

  /* wijmo picker functionality */
  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showMessage = (item) => {
    return (
      <span className='nowrap'>
        {item.item.html_body && (
          // <button
          //   classname='doclink'
          //   onClick={(e) => showMessageModal(e, item.item.html_body)}
          //   style={{
          //     border: 'none',
          //     textAlign: 'center',
          //     borderRadius: 5,
          //     backgroundColor: '#abc',
          //     fontweight: 'bold'
          //   }}
          // >
          //   View
          // </button>
          // <p style={{color:'blue', fontSize:14}}>
          //   View
            <FolderOpenTwoTone
              twoToneColor='#cc7a00'
              style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
              onClick={(e) => showMessageModal(e, item.item.html_body)}
            />
          // </p>
        )}
      </span>
    );
  };

  function showMessageModal(event, value) {
    setShowModal(true);
    setMessageValue(value);
    console.log(
      'notification html body',
      value.split(/\s{4,}/)[0].split('\n\n')[1] &&
        value
          .split(/\s{4,}/)[0]
          .split('\n\n')[1]
          .split('✔')
    );
  }
  const generateGridColumn = (
    binding,
    header,
    width = '*',
    wordWrap = true,
    minWidth = 150,
    template = null
  ) => (
    console.log(binding, header, width, wordWrap, minWidth, template, 'generategrid'),
    (
      <FlexGridColumn
        key={binding}
        binding={binding}
        header={header}
        width={width}
        wordWrap={wordWrap}
        minWidth={minWidth}
      >
        {template && <FlexGridCellTemplate cellType='Cell' template={template} />}
      </FlexGridColumn>
    )
  );
  const columns = [
    // generateGridColumn('newLocation', 'Location','*', true, 250),
    // generateGridColumn('newEmpName', 'Receiver','*', true, 200),
    // generateGridColumn('from_email', 'Sender', '*', true, 300),
    generateGridColumn('newSubject', 'Subject', '*', true, 240),
    generateGridColumn('html_body', 'Message', '*', true, 80, showMessage),
    generateGridColumn('createDate', 'Date Sent', '*', true, 170)
  ];
  const filterColumns = [
    // 'newLocation',
    'createDate',
    'newSubject',
    'newEmpName',
    'from_email',
    'to_email'
  ];

  return hasPermission ? (
    <div style={{ padding: 20 }}>
      <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Notifications</h3>

      {!nofound ? (
        notification.length > 0 ? (
          <>
            <Row>
              <Col span={8}>
                <RangePicker
                  placeholder={['Start Create Date', 'End Create Date']}
                  allowClear={true}
                  style={{ borderRadius: 5, width: '100%', height: 40 }}
                  format='MM-DD-YYYY'
                  onChange={(dates) => {
                    setDateRangeFilter(dates);
                  }}
                />
              </Col>
            </Row>
            <br />

            <Row gutter={[32, 32]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div>
                  <Select
                    style={{ width: 160 }}
                    placeholder={`Items per page : ${pageSize}`}
                    onChange={paggination}
                    options={[
                      { label: 'Items per page : 10', value: 10 },
                      { label: 'Items per page : 20', value: 20 },
                      { label: 'Items per page : 50', value: 50 }
                    ]}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                  size='small'
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>

            {/* to export entire data of table */}
            <FlexGrid
              ref={theGrid}
              style={{ display: 'none' }}
              className='dummyWijmoData'
              itemsSource={viewData}
              initialized={gridInitializedData}
              formatItem={formatItem}
              isReadOnly={true}
            >
              {columns}
            </FlexGrid>

            {/* end of to export entire data of table */}

            <div className='container-fluid' style={{ minWidth: '100%' }}>
              <FlexGrid
                ref={theGrid}
                id='theGridTallRows'
                className='cliniclisttable'
                alternatingRowStep={1}
                itemsSource={view}
                initialized={gridInitialized}
                formatItem={formatItem}
                itemsPerPage={10}
                isReadOnly={true}
                scrollMode='Virtual' // Add this line for smooth scrolling
                // loadedRows={(s) => s.autoSizeColumns()}
                // rowEditEnded={(s) => s.autoSizeColumns()}
                // cellEditEnded={(s, e) => s.autoSizeColumn(e.col)}
                style={{ minwidth: '100%' }}
              >
                <div style={{ display: 'none' }}>
                  <wjInput.ListBox
                    className='column-picker'
                    itemsSource={listboxColumns}
                    checkedMemberPath='visible'
                    displayMemberPath='header'
                    lostFocus={hidePicker}
                    initialized={initializedListBox}
                  ></wjInput.ListBox>
                </div>

                {columns}
                <FlexGridFilter
                  showFilterIcons={true}
                  placeholder='Filter data...'
                  defaultFilterType='Value'
                  filterColumns={filterColumns}
                />
              </FlexGrid>
            </div>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                ref={filterRefCounter}
                id='filterCounter'
              >
                {Globalize.format(filterRefCounter.current, 'n0')}
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                {' '}
                out of {notification.length}
              </p>
            </div>
            <Modal
              width={1000}
              centered
              zIndex={1000}
              title='Message Body'
              visible={showModal}
              onOk={() => setShowModal(false)}
              onCancel={() => setShowModal(false)}
              footer={null}
            >
              <div dangerouslySetInnerHTML={{ __html: messageValue }} />
            </Modal>
          </>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 75 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red', textAlign: 'center' }}>
        {errMsg}
      </p>
    </div>
  );
};

export default Notifications;
