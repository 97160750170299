import React, { Component } from 'react';
import axios from 'axios';
import _ from 'underscore';
import setting from '../../config/setting';
import { Spin, Space, Row, Col, Input, Table, Tooltip, Button, notification } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { history } from '../../redux/store';
import PackageList from './packageList';
import ProtocolAttachment from './protocolAttachment';
import LocationList from './locationList';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
const config = require('../keyFront');
class policy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      custLocs: [],
      selectedCustomerList: ['All'],
      changeL: false,
      filterData: [],
      Loader: false,
      Count: [],
      companies: [],
      // isLoading: true,
      isLoadingProtocol: false,
      selectedCompany: '',
      loaderVal: false,
      locationBKP: [],
      totalRecords: 0,
      currentPageNumber: 1,
      companyIds: [],
      errMsg: '',
      hasPermission: false,
      pageLimit: 25,
      searchText: '',
      noRecordMessage: 'No Record Found !',
      noRecordMessageL: 'No Record Found !',
      noRecordMessageP: 'No Record Found !',
      list: [],
      allData: [],
      change: true,
      Loader: false,
      Count: [],
      CountNew: [],
      LocData: [],
      Dat: [],
      //imageUrls: [],
      protocolAttachTrue: false,
      loaderActive: false,
      imageUrls: [],
      EventPolicy: [],
      searchFieldVal: '',
      packages: [],
      found: false,
      foundL: false,
      foundP: false,
      changeP: false,
      custContacts: [],
      pkgNameFilter: {},
      locationFilter: {},
      phoneFilter: {},
      addressFilter: {},
      contactFilter: {},
      attachNameFilter: {},
      docPermission: false
    };
  }

  componentDidMount() {
    // this.getProtocolAttachments();
    // this.getPackages();
    // this.setState({ changeL: false });
    // this.fetchCompanyData();
    // this.getCustomerContacts();
    // this.getDocPermission();
  }
  fetchCompanyData = () => {
    const companyId = parseInt(localStorage.getItem('customerId'));
    this.getCustomerLocation([companyId]);
    this.getCustomerLocationCount([companyId]);
  };

  getCustomerContacts = (companyIds) => {
    console.log('inside contactListCustomerPortal:::');
    axios
      .post(setting.serverUrl + '/api/contactListCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyIds,
          custId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          //console.log('Respone from contactListCustomerPortal ', response.data);

          response.data.rows.sort(function (a, b) { return a.locname - b.locname })
          console.log('Respone from contactListCustomerPortal ', response.data.rows);
          this.setState({
            custContacts: response.data.rows,

          });
        } else if (response.status === 201) {
          this.setState({ foundL: true, noRecordMessageL: 'Access permission not allowed.' });
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };

  getCustomerLocation = (companyIds) => {
    console.log('inside getCustomerLocation:::');
    axios
      .post(setting.serverUrl + '/api/getCustomerLocationCustomerPortal', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyIds, //this.state.companyIds,
          limit: this.state.pageLimit * this.state.currentPageNumber,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          console.log('Respone from getCustomerLocation ', response.data);
          response.data.sort(function (a, b) { return a.name - b.name })
          let responseLoc = response.data;
          // response.data.sort(function (a, b) {
          //   if (a.name < b.name) {
          //     return -1;
          //   }
          //   if (a.name > b.name) {
          //     return 1;
          //   }
          //   return 0;
          // });
          let finalCombinedResponse = [];
          finalCombinedResponse.push(this.state.custContacts);
          console.log("this.state.custContacts", this.state.custContacts);
          let merged = [];

          for (let i = 0; i < responseLoc.length; i++) {
            merged.push({
              ...responseLoc[i],
              ...(this.state.custContacts.find((itmInner) => itmInner.contact1_emp === responseLoc[i].contact1_emp[0]))
            }
            );
          }
          let completeResult = merged.map((record) => {
            return {
              ...record, "fullAddr":
                record ?
                  (record.street === false || record.street.length === 0 ? "" : (record.street))
                  +
                  (record.street2 === false || record.street2.length === 0 ? "" : (", " + record.street2)) +
                  (record.city === false || record.city.length === 0 ? "" : (", " + record.city)) +
                  (record.state_id === false || record.state_id.length == 0 ? "" : (", " + record.state_id[1].replace(" (US)", ""))) +

                  (record.zip === false || record.zip.length === 0 ? "" : (", " + record.zip))
                  : null
            }
          });
          //console.log("addddddddddddd",completeResult )
          console.log("merged::::::", completeResult);

          let locationNames = {};
          completeResult.filter((x) => x.name && x.name.length > 0 && x.name != false).map((data) => (locationNames[data.name] = 'here'));
          let contactNames = {};
          completeResult.filter((x) => x.locname && x.locname.length > 0 && x.locname != false).map((data) => (contactNames[data.locname] = 'here'));
          let phoneValues = {};
          completeResult.filter((x) => x.phonevalue && x.phonevalue.length > 0 && x.phonevalue != false).map((data) => (phoneValues[data.phonevalue] = 'here'));

          let addressValues = {};
          completeResult.filter((x) => x.fullAddr && x.fullAddr.length > 0 && x.fullAddr != false).map((data) => (addressValues[data.fullAddr] = 'here'));
         
          this.setState({
            custLocs: completeResult,
            filterData: completeResult,
            locationBKP: completeResult,
            loaderVal: false,
            changeL: true,
            locationFilter: locationNames,
            contactFilter: contactNames,
            phoneFilter: phoneValues,
            addressFilter: addressValues

          });

        } else if (response.status === 201) {
          this.setState({ foundL: true, noRecordMessageL: 'Access permission not allowed.' });
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };

  getCustomerLocationCount = (companyIds) => {
    console.log('inside getCustomerLocationCount:::');
    axios
      .post(setting.serverUrl + '/api/getCustomerLocationCount', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: companyIds,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          console.log('Respone from getCustomerLocationCount::::::', response.data);

          this.setState({ totalRecords: response.data.value });
          let total = this.state.totalRecords;
          console.log('totalRecords-----', total);

          this.setState({
            changeL: true,
            found: false,
            //errMsg: 'Module not activated',
            hasPermission: true
          });
        } else if (response.status === 201) {
          this.setState({
            found: true,
            noRecordMessage: 'Access permission not allowed.',
            errMsg: 'Access permission not allowed.'
          });
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };

  search = (value) => {
    if (value.length > 0) {
      console.log('e', value, this.state.custLocs);

      let datArr = this.state.custLocs.filter(
        (val) =>
          (val.name && val.name.toLowerCase().includes(value.toLowerCase())) ||
          (val.commercial_company_name &&
            val.commercial_company_name.toLowerCase().includes(value.toLowerCase())) ||
          (val.phonevalue && val.phonevalue.includes(value)) ||
          (val.contact_address && val.contact_address.toLowerCase().includes(value.toLowerCase())) ||
          (val.hospital_url && val.hospital_url.toLowerCase().includes(value.toLowerCase())) ||
          (val.hospital_name && val.hospital_name.toLowerCase().includes(value.toLowerCase()))
      );
      console.log('datArr', datArr);

      if(datArr.length === 0){
        this.setState({foundL:true})
      }else{
        this.setState({foundL:false})
      }
      this.setState({ filterData: datArr, searchText: value });
    } else {
      this.setState({ filterData: this.state.custLocs, searchText: value });
    }
  };

  showTotal = (total) => {
    return <p>Total {total} items</p>;
  };

  handleLoadMore = () => {
    this.setState(
      { currentPageNumber: this.state.currentPageNumber + 1, loaderVal: true },
      function () {
        this.fetchCompanyData();
      }
    );
  };

  handleReset() {
    this.setState(
      {
        Value: '',
        lastNameValue: '',
        //currentPageNumber: 1
      },
      () => {
        this.search('');
      }
    );
  }
  addLocation = () => {
    console.log('addLocation');

    history.push({ pathname: `/vertical/addLocation`, state: {} });
  };

  shouldShowLoadMore = () => {
    return this.state.currentPageNumber * this.state.pageLimit <= this.state.totalRecords;
  };
  download(e, url) {
    this.setState({ loaderActive: true });
    axios
      .post(setting.serverUrl + '/api/downloadTestingCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_url: url
        }
      })
      .then((response) => {
        console.log('Respone from download images', response.data);

        this.setState({ loaderActive: false });
        if (Array.isArray(response.data)) {
          this.setState({ modalVisible: true, imageUrls: response.data });
        } else {
          window.open(response.data);
        }
      });
  }
  async getProtocolAttachments() {
    this.setState({ isLoadingProtocol: true });
    axios
      .post(setting.serverUrl + '/api/protocolAttachments', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId')
        }
      })
      .then(async (response) => {
        this.setState({ isLoadingProtocol: false });
        console.log('protocolAttachments response ::::: ', response);
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.data.error) {
          alert('Some Technical Error Occurred!');
        }
        if (response && response.status == 200) {
          let attachNames = {};
          response && response.data && response.data.rows.filter((x) => x.area && x.area.length > 0 && x.area != false).map((data) => attachNames[data.area] = "here")
          this.setState({
            allData: response.data.rows,
            change: true,
            EventPolicy: response.data.rows,
            found: false,
            // errMsg: 'Module not activated',
            attachNameFilter: attachNames,
            hasPermission: true
          });
        } else if (response.status === 201) {
          this.setState({
            found: true,
            noRecordMessage: 'Access permission not allowed.',
            errMsg: 'Access permission not allowed.'
          });
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  }

  getPackages = () => {
    axios
      .post(setting.serverUrl + '/api/hospital_customers_lines', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          compId: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        console.log('Respone from hospital_customers_lines ', response.data);
        if (response && response.status == 200) {
          let pkgNames = {};
          response && response.data.filter((x) => x.display_name && x.display_name.length > 0 && x.display_name != false).map((data) => pkgNames[data.display_name] = "here");
          this.setState({
            packages: response.data,
            kgNameFilter: pkgNames,
            changeP: true,
            foundP: false,
            pkgNameFilter: pkgNames,
            //errMsg: 'Module not activated.',
            hasPermission: true
          });
        } else if (response.status === 201) {
          this.setState({
            foundP: true,
            noRecordMessageP: 'Access permission not allowed.',
            errMsg: 'Access permission not allowed.'
          });
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  };

  expandedRowRender = (i) => {
    console.log('key', i.test_name.split('\n'));
    let dataArr = i.test_name.split('\n').map((element) => {
      return {
        test_name: element
      };
    });
    const columns = [
      {
        title: 'Test Name',
        key: 'test_name',
        dataIndex: 'test_name',
        render: (test_name) => <span className='nowrap'>{test_name.replace(" - Non-DOT", " ")}</span>
      }
    ];

    return (
      <div
        style={{
          padding: 20,
          backgroundColor: '#dce1e1'
        }}
      >

        {dataArr.length > 0 ? (
          <Table columns={columns} dataSource={dataArr} pagination={false} />
        ) : (
          <Row>
            <Col span={7}></Col>
            <Col span={12}>
              <p style={{ fontSize: 16, padding: 20, color: 'red' }}>
                Currently there is no test history for this Employee
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  };
  getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.ProtocolDocs
        }
      })
      .then((response) => {
        console.log('response pritocol  docs', response);
        if (response.status === 200) {
          this.setState({docPermission : true})
        }
      });
  };
  render() {
    console.log('this.state', this.state);
    const columns = [
      {
        title: 'Attachment Name',
        key: 'area',
        dataIndex: 'area',
        render: (area) => <span className='nowrap'>{area}</span>,
        filters: Object.keys(this.state.attachNameFilter).map((element) => {
          return { text: element, value: element };
        }),
        onFilter: (value, record) => record.area === value,
        filterSearch: true
      },
      // {
      //   key: 'area',
      //   dataIndex: 'area',
      //   title: 'Category',
      //   render: (area) => <span className='nowrap'>{area}</span>
      // },

      {
        key: 'url',
        dataIndex: 'url',
        title: 'View Docs',

        render: (url) => (
          <p>
            {this.state.docPermission && url ? (
              <button
                className='doclink'
                onClick={(e) => this.download(e, url)}
                style={{
                  border: 'none',
                  textAlign: 'center',
                  borderRadius: 5,
                  backgroundColor: '#abc',
                  fontweight: 'bold'
                }}
              >
                View Docs
              </button>
            ) : (
              url && <CheckSquareTwoTone style={{ color: 'green', fontSize: 20 }} />
            )}
          </p>
        )
      }
    ];
    const packageColumns = [
      {
        title: 'Name',
        key: 'display_name',
        dataIndex: 'display_name',
        render: (display_name) => <span className='nowrap'>{display_name}</span>,
        filters: Object.keys(this.state.pkgNameFilter).map((element) => {
          return { text: element, value: element };
        }),
        onFilter: (value, record) => record.display_name === value,
        filterSearch: true
      }
    ];
    const columnsLocation = [

      {
        title: 'Location Name',
        key: 'name',
        dataIndex: 'name',
        render: (name) => <span className='nowrap'>{name}</span>,
        filters: Object.keys(this.state.locationFilter).map((element) => {
          console.log("elt---", element)
          return { text: element, value: element };
        }),
        onFilter: (value, record) => record.name === value,
        filterSearch: true
      },
      {
        title: 'Contact Person',
        key: 'locname',
        dataIndex: 'locname',
        render: (locname) => (
          <span className='nowrap'>
            {locname ? locname : ''}
          </span>
        ),
        filters: Object.keys(this.state.contactFilter).map((element) => {
          return { text: element, value: element };
        }),
        onFilter: (value, record) => record.locname === value,
        filterSearch: true

      },
      {
        key: 'fullAddr',
        dataIndex: 'fullAddr',
        title: 'Address',
        //render: (contact_address) => <span className='nowrap'>{contact_address}</span>
        render: (fullAddr) => (<p>{fullAddr}</p>
        ),
        // <span className='nowrap'>
        //   {
        //     record ?

        //       (record.street === false || record.street.length === 0 ? "" : (record.street))
        //       +
        //       (record.street2 === false || record.street2.length === 0 ? "" : (", " + record.street2)) +
        //       (record.city === false || record.city.length === 0 ? "" : (", " + record.city)) +
        //       (record.state_id === false || record.state_id.length == 0 ? "" : (", " + record.state_id[1].replace(" (US)", ""))) +

        //       (record.zip === false || record.zip.length === 0 ? "" : (", " + record.zip))
        //       : null
        //   }
        //   {/* {fullAddr} */}
        // </span >

        filters: Object.keys(this.state.addressFilter).map((element) => {
          return { text: element, value: element };
        }),
        onFilter: (value, record) => record.fullAddr === value,
        filterSearch: true

      },

      {
        title: 'Phone Number',
        key: 'phonevalue',
        dataIndex: 'phonevalue',
        render: (phonevalue) => (
          <span className='nowrap'>
            {phonevalue && <i class='icofont-phone'></i>}&nbsp;{phonevalue}

          </span>
        ),
        filters: Object.keys(this.state.phoneFilter).map((element) => {
          return { text: element, value: element };
        }),
        onFilter: (value, record) => record.phonevalue === value,
        filterSearch: true
      },

      // {

      //   title: 'Near by Clinic',
      //   key: ['hospital_url']['hospital_name'],
      //   dataIndex: ['hospital_url']['hospital_name'],
      //     render: (e) => (
      //     <a target='_blank' href={e.hospital_url}>
      //       {e.hospital_name}
      //     </a>
      //   )
      // },
      {

        title: 'Near by Clinic',
        key: 'clinic_name',
        dataIndex: 'clinic_name',
        render: (clinic_name) => (
          <p>{clinic_name && clinic_name[1].length > 0 ? clinic_name[1] : ""}</p>
        )
      },

    ];

    return  (
      <>
        <div>
        <ProtocolAttachment/>
          {/* <Row>
            <Col span={8}>
              <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>
                Protocol Attachments
              </h3>
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
              <Input
                style={{ backgroundColor: 'white', marginTop: 25 }}
                suffix={<span className='icofont icofont-search' />}
                placeholder='Type to search...'
                value={this.state.searchFieldVal}
                onChange={(e) => {
                  this.setState({ searchFieldVal: e.target.value });
                  if (e.target.value.length > 0) {
                    let datArr = this.state.EventPolicy.filter(
                      (val) =>
                        // val.authorisation && val.authorisation.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        // val.package_name.length > 0 && val.package_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        (val.name &&
                          val.name.toLowerCase().includes(e.target.value.toLowerCase())) ||
                        (val.area && val.area.toLowerCase().includes(e.target.value.toLowerCase()))
                    );
                    console.log('datArr', datArr);
                    this.setState({ allData: datArr });
                  } else {
                    console.log('e.target.value.length > 0', e.target.value.length);
                    console.log('eventPolicy:', this.state.EventPolicy);
                    this.setState({ allData: this.state.EventPolicy });
                  }
                }}
              />
            </Col>

          </Row> */}

          {/* {this.state.allData.length > 0 && this.state.change ? ( */}
          {/* {!this.state.isLoadingProtocol ? (
            this.state.allData.length > 0 ? (
              <div className='row'>
                <div className='col-12 col-md-12 col-xl-12'>
                  <Table dataSource={this.state.allData} columns={columns} />
               
                </div>
              </div>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>
                {this.state.noRecordMessage}
              </h5>
            )
          ) : (
            <Row>
              <Col span={10}></Col>
              <Col span={8}>
                <Space size='middle' style={{ marginTop: 50 }}>
                  <Spin size='large' />
                </Space>
              </Col>
            </Row>
          )} */}
          <br />
          <div style={{ padding: 10 }}>
          <LocationList/>
            {/* <Row>
              <Col xl={{span:5}} lg={{span:5}} md={{span:5}} sm={{span:6}} xs={{span:18}}>
                <h3 style={{ textAlign: 'left', color: '#1C3A6A' }}>Location List</h3>
              </Col>
              <Col xl={{span:1}} lg={{span:1}} md={{span:1}} sm={{span:3}} xs={{span:4}}>
                <Tooltip placement='top' title='Add Location'>
                  <PlusCircleTwoTone
                    twoToneColor='#247'
                    style={{ fontSize: 25, marginTop: 29, cursor: 'pointer' }}
                    onClick={this.addLocation}
                  />
                </Tooltip>
              </Col>
              <Col xl={{span:6}} lg={{span:7}} md={{span:5}} sm={{span:1}} xs={{span:0}}></Col>
              <Col xl={{span:7}} lg={{span:7}} md={{span:7}} sm={{span:7}} xs={{span:24}}>
                <Input
                  placeholder='Type to search'
                  value={this.state.searchText}
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '90%', marginTop: 29, backgroundColor: 'white' }}
                  onChange={(event) => this.search(event.target.value)}
                />
              </Col>
              <Col xl={{span:2}} lg={{span:2}} md={{span:2}} sm={{span:2}} xs={{span:12}}>
                {this.shouldShowLoadMore() && (
                  <Button
                    size='small'
                    style={{
                      marginTop: 29,
                      backgroundColor: '#247',
                      color: 'white',
                      border: 'none'
                    }}
                    onClick={() => this.handleLoadMore()}
                  >
                    Load More
                  </Button>
                )}
              </Col>
              <Col xl={{span:1}}lg={{span:0}} md={{span:1}} sm={{span:2}} xs={{span:0}}></Col>
              <Col  xl={{span:2}} lg={{span:2}} md={{span:2}} sm={{span:2}} xs={{span:12}}>
                <Button
                  size='small'
                  style={{ marginTop: 29, backgroundColor: '#247', color: 'white', border: 'none' }}
                  onClick={() => this.handleReset()}
                >
                  Reset Filter
                </Button>
              </Col>
            </Row>
          
            {!this.state.foundL ? (
              this.state.custLocs.length > 0 && this.state.change ? (
                <>
                  <Row>
                    <Col style={{ fontSize: 15, fontWeight: 700,marginTop:"10px" }}>
                      {this.state.totalRecords > 0
                        ? 'Total Records : ' + this.state.totalRecords
                        : ''}
                    </Col>
                  </Row>
                  <br />
                  <div className='row'>
                    <div className='col-12 col-md-12 col-xl-12'>
                      {this.state.filterData.length > 0 && 
                      <>
                        <Table
                          loading={this.state.loaderVal}
                          dataSource={this.state.filterData}
                          columns={columnsLocation}
                          //pageSize={this.pageLimit}
                          pagination={{
                            showSizeChanger: false,
                            pageSize: 25,
                            total: this.state.filterData.length,
                            showTotal: this.showTotal
                          }}
                        />
                     
                        </>
                      }
                      <br />
                      {this.shouldShowLoadMore() && (
                        <Button
                          style={{ backgroundColor: '#247', color: 'white', border: 'none' }}
                          onClick={this.handleLoadMore}
                        >
                          Load More
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <Row>
                  <Col span={10}></Col>
                  <Col span={8}>
                    <Space size='middle' style={{ marginTop: 50 }}>
                      <Spin size='large' />
                    </Space>
                  </Col>
                </Row>
              )
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>
                {this.state.noRecordMessageL}  
              </h5>
            )} */}
          </div>
          <PackageList/>
          {/* <Row>
            <Col span={8}>
              <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Packages</h3>
            </Col>
          </Row>
          {!this.state.foundP && this.state.changeP ? (
            this.state.packages.length > 0  ? (
              <div className='row'>
                <div className='col-12 col-md-12 col-xl-12'>
                  <Table
                    dataSource={this.state.packages}
                    columns={packageColumns}
                    rowKey='id'
                    expandable={{
                      expandedRowRender: (record) => this.expandedRowRender(record)
                    }}
                  />
               
                </div>
              </div>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{this.state.noRecordMessageP}</h5>
            
            )
          ) : (
            <>
            { !this.state.foundP ?
                  (
                    <Row>
                      <Col span={10}></Col>
                      <Col span={8}>
                        <Space size='middle' style={{ marginTop: 50 }}>
                          <Spin size='large' />
                        </Space>
                      </Col>
                    </Row>
                  ) : 
                  (<h5 style={{ color: '#ef6727', textAlign: 'center' }}>{this.state.noRecordMessageP}</h5>)
            }
            </>
              
              )} */}
        </div>
        
      </>
     ) 
     
    // : (
    //   <div>
    //     {!this.state.errMsg ? (
    //       <Row>
    //         <Col span={10}></Col>
    //         <Col span={8}>
    //           <Space size='middle' style={{ marginTop: 50 }}>
    //             <Spin size='large' />
    //           </Space>
    //         </Col>
    //       </Row>
    //     ):
    //     <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
    //     {/* <i className="icofont-exclamation-tringle" style={{color:'red',fontSize:20}}></i>&nbsp;&nbsp; */}
    //     {this.state.errMsg} 
    //   </p>}
        
    //   </div>
    // );
  }
  
}

export default policy;
